import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { CollapsibleSection } from '@webOrganisms/CollapsibleSection/CollapsibleSection';
import { GradingLabel } from '@webOrganisms/GradingLabel/GradingLabel';
import { Finding } from '@entities/Finding';
import { ComparisonFindingHeader } from './ComparisonFindingHeader';
import styles from './comparison.scss';
import { ComparisonFindingDetail } from './ComparisonFindingDetail';

export interface ComparisonItemProps {
  finding: Finding;
  isExpanded: boolean;
  isDraggable?: boolean;
  isPrevious?: boolean;
  rowIndex?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ComparisonItem = React.forwardRef<
  HTMLElement,
  ComparisonItemProps
>(
  (
    {
      finding,
      isExpanded,
      isPrevious,
      isDraggable,
      rowIndex,
      onMouseEnter,
      onMouseLeave,
    },
    ref
  ) => {
    return (
      <Box className={styles.comparisonItem} key={finding.id}>
        <Box className={styles.comparisonItemStripe}>
          <GradingLabel grading={finding.formatted.grading} hideText />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            ref={ref}
            className={isDraggable ? styles.dragArea : undefined}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <ComparisonFindingHeader
              finding={finding}
              isExpanded={isExpanded}
              isPrevious={isPrevious || false}
              rowIndex={rowIndex}
            />
          </Box>
          <CollapsibleSection open={isExpanded}>
            <ComparisonFindingDetail finding={finding} />
          </CollapsibleSection>
        </Box>
      </Box>
    );
  }
);

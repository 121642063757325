import { CanvasObjectType as APICanvasObjectType } from '@api/schema/generated';
import { CanvasObjectType } from '@appCanvas/interfaces/types';
import {
  CanvasObjectData,
  CanvasObject,
  NonFindingCanvasObjectType,
  CanvasCustomTextData,
  CanvasFreeDrawingPathData,
  CanvasSymbolData,
  PositionConfig,
  Perforator,
  FreeDrawPath,
  CustomText,
} from '@entities/CanvasObject';
import { schemas } from '@api/schema/client';
import { mapEnumByValue } from './Enum';

export const encodeCanvasObjectData = (
  canvasObject: CanvasObjectData
): schemas['CanvasObjectInput'] => {
  switch (canvasObject.key) {
    case CanvasObjectType.CustomText:
      return encodeCustomText(canvasObject);
    case CanvasObjectType.FreeDrawingPath:
      return encodeFreeDrawingPath(canvasObject);
    case CanvasObjectType.Symbol:
      return encodeSymbol(canvasObject);
    default:
      throw new Error('Invalid canvas object type');
  }
};

export const encodeCustomText = (
  canvasObject: CanvasCustomTextData
): schemas['CanvasObjectInput'] => {
  return {
    key: mapEnumByValue(
      canvasObject.key,
      APICanvasObjectType
    ) as APICanvasObjectType,
    value: {
      drawing_data: canvasObject.value.drawingData,
      position_config: encodePositionConfig(canvasObject.value.positionConfig),
    },
  };
};

export const encodeFreeDrawingPath = (
  canvasObject: CanvasFreeDrawingPathData
): schemas['CanvasObjectInput'] => {
  return {
    key: mapEnumByValue(
      canvasObject.key,
      APICanvasObjectType
    ) as APICanvasObjectType,
    value: {
      drawing_data: canvasObject.value.drawingData,
      position_config: encodePositionConfig(canvasObject.value.positionConfig),
    },
  };
};

export const encodeSymbol = (
  canvasObject: CanvasSymbolData
): schemas['CanvasObjectInput'] => {
  return {
    key: mapEnumByValue(
      canvasObject.key,
      APICanvasObjectType
    ) as APICanvasObjectType,
    value: {
      drawing_data: canvasObject.value.drawingData,
      position_config: encodePositionConfig(canvasObject.value.positionConfig),
    },
  };
};

export const encodePositionConfig = (
  positionConfig: PositionConfig
): schemas['PositionConfig'] => {
  return {
    custom_x_scale: positionConfig.customXScale ?? null,
    left: positionConfig.left ?? null,
    top: positionConfig.top ?? null,
    canvas_offset: positionConfig.canvasOffset ?? null,
  };
};

export const decodeCanvasObject = (
  canvasObject: schemas['CanvasObjectData-Output']
): CanvasObject => {
  const canvasObjectType = mapEnumByValue(canvasObject.key, CanvasObjectType);

  switch (canvasObjectType) {
    case CanvasObjectType.CustomText:
      return {
        id: canvasObject.canvas_object_id,
        key: canvasObjectType,
        value: decodeCustomTextData(
          canvasObject.data as schemas['CanvasCustomTextData-Output']
        ),
      };
    case CanvasObjectType.FreeDrawingPath:
      return {
        id: canvasObject.canvas_object_id,
        key: canvasObjectType,
        value: decodeFreeDrawingPathData(
          canvasObject.data as schemas['CanvasFreeDrawingPathData-Output']
        ),
      };
    case CanvasObjectType.Symbol:
      return {
        id: canvasObject.canvas_object_id,
        key: canvasObjectType,
        value: decodeSymbolData(
          canvasObject.data as schemas['CanvasSymbolData-Output']
        ),
      };

    default:
      throw new Error('Invalid canvas object type');
  }
};

export const decodeCanvasObjectData = (
  canvasObjectData:
    | schemas['CanvasCustomTextData-Output']
    | schemas['CanvasFreeDrawingPathData-Output']
    | schemas['CanvasSymbolData-Output'],
  canvasObjectType: NonFindingCanvasObjectType
): CustomText | FreeDrawPath | Perforator => {
  switch (canvasObjectType) {
    case CanvasObjectType.CustomText:
      return decodeCustomTextData(
        canvasObjectData as schemas['CanvasCustomTextData-Output']
      );
    case CanvasObjectType.FreeDrawingPath:
      return decodeFreeDrawingPathData(
        canvasObjectData as schemas['CanvasFreeDrawingPathData-Output']
      );
    case CanvasObjectType.Symbol:
      return decodeSymbolData(
        canvasObjectData as schemas['CanvasSymbolData-Output']
      );
  }
};

export const decodeCustomTextData = (
  canvasObjectData: schemas['CanvasCustomTextData-Output']
): CustomText => {
  return {
    drawingData: canvasObjectData.drawing_data,
    positionConfig: decodePositionConfig(canvasObjectData.position_config),
  };
};

export const decodeFreeDrawingPathData = (
  canvasObjectData: schemas['CanvasFreeDrawingPathData-Output']
): FreeDrawPath => {
  return {
    drawingData: canvasObjectData.drawing_data,
    positionConfig: decodePositionConfig(canvasObjectData.position_config),
  };
};

export const decodeSymbolData = (
  canvasObjectData: schemas['CanvasSymbolData-Output']
): Perforator => {
  return {
    drawingData: canvasObjectData.drawing_data,
    positionConfig: decodePositionConfig(canvasObjectData.position_config),
  };
};

export const decodePositionConfig = (
  positionConfig: schemas['PositionConfig']
): PositionConfig => {
  return {
    left: positionConfig.left ?? undefined,
    top: positionConfig.top ?? undefined,
    customXScale: positionConfig.custom_x_scale ?? undefined,
    canvasOffset: positionConfig.canvas_offset ?? undefined,
  };
};

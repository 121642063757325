import {
  BreastFinding,
  BreastFindingCharacteristics,
  CanvasPosition,
  Clockface,
  Finding,
  FindingSizes,
  FindingType,
  Size,
  ThyroidFinding,
  ThyroidFindingCharacteristics,
} from '@entities/Finding';
import { schemas } from '@api/schema/client';
import {
  BiradsScore,
  BodySide,
  BreastFindingArchitecture,
  BreastFindingCalcification,
  BreastFindingEchoPattern,
  BreastFindingElasticity,
  BreastFindingMargin,
  BreastFindingOrientation,
  BreastFindingPosition,
  BreastFindingPosteriorFeature,
  BreastFindingShape,
  BreastFindingSkinChanges,
  BreastFindingSpecialCase,
  BreastFindingType,
  BreastFindingVascularity,
  Composition,
  EchogenicFoci,
  Echogenicity,
  Margin,
  Shape,
  ThyroidPole,
} from '@api/schema/generated';
import {
  DistanceUnitMap,
  encodeEnum,
  mapEnumArrayByValue,
  mapEnumByValue,
} from './Enum';
import { decodeFinding } from './Analysis';

export const encodeFindingInput = (
  finding: Finding
): schemas['ThyroidFindingInput'] | schemas['BreastFindingInput'] => {
  switch (finding.type) {
    case FindingType.Thyroid:
      return encodeThyroidFinding(finding);
    case FindingType.Breast:
      return encodeBreastFinding(finding);
    default:
      throw new Error('Invalid finding type');
  }
};

export const encodeThyroidFinding = (
  finding: ThyroidFinding
): schemas['ThyroidFindingInput'] => {
  return {
    included: finding.included,
    characteristics: encodeThyroidFindingCharacteristics(
      finding.characteristics
    ),
    size: encodeFindingSizes(finding.sizes),
    canvas_position: encodeThyroidFindingCanvasPosition(finding.canvasPosition),
    notes: finding.notes ?? null,
  };
};

export const encodeBreastFinding = (
  finding: BreastFinding
): schemas['BreastFindingInput'] => {
  return {
    included: finding.included,
    characteristics: encodeBreastFindingCharacteristics(
      finding.characteristics
    ),
    size: encodeFindingSizes(finding.sizes),
    canvas_position: encodeThyroidFindingCanvasPosition(finding.canvasPosition),
    notes: finding.notes ?? null,
  };
};

export const encodeThyroidFindingCharacteristics = (
  data: ThyroidFindingCharacteristics
): schemas['ThyroidFindingCharacteristicsInput'] => {
  return {
    side: mapEnumByValue(data.side, BodySide),
    pole: mapEnumByValue(data.pole, ThyroidPole),
    composition: mapEnumByValue(data.composition, Composition),
    echogenicity: mapEnumByValue(data.echogenicity, Echogenicity),
    margin: mapEnumByValue(data.margin, Margin),
    shape: mapEnumByValue(data.shape, Shape),
    echogenic_foci: mapEnumArrayByValue(data.echogenicFoci, EchogenicFoci),
  };
};

export const encodeBreastFindingCharacteristics = (
  data: BreastFindingCharacteristics
): schemas['BreastFindingCharacteristicsData-Input'] => {
  return {
    side: mapEnumByValue(data.side, BodySide),
    position: mapEnumByValue(data.position, BreastFindingPosition),
    clockface: data.clockface && encodeClockface(data.clockface),
    distance_from_nipple: encodeSize(data.distanceFromNipple),
    special_case: mapEnumByValue(data.specialCase, BreastFindingSpecialCase),
    posterior_features: mapEnumByValue(
      data.posteriorFeatures,
      BreastFindingPosteriorFeature
    ),
    shape: mapEnumByValue(data.shape, BreastFindingShape),
    orientation: mapEnumByValue(data.orientation, BreastFindingOrientation),
    margin: mapEnumByValue(data.margin, BreastFindingMargin),
    echo_pattern: mapEnumByValue(data.echoPattern, BreastFindingEchoPattern),
    calcifications: mapEnumByValue(
      data.calcifications,
      BreastFindingCalcification
    ),
    type: mapEnumByValue(data.type, BreastFindingType),
    vascularity: mapEnumByValue(data.vascularity, BreastFindingVascularity),
    elasticity: mapEnumByValue(data.elasticity, BreastFindingElasticity),
    skin_changes: mapEnumByValue(data.skinChanges, BreastFindingSkinChanges),
    architecture: mapEnumArrayByValue(
      data.architecture,
      BreastFindingArchitecture
    ),
    birads_score: mapEnumByValue(data.biradsScore, BiradsScore),
  };
};

export const encodeClockface = (data: Clockface): schemas['ClockfaceData'] => {
  return {
    hour: data.hour,
    minute: data.minute,
  };
};

export const encodeFindingSizes = (
  data: FindingSizes
): schemas['FindingSizeInput'] => {
  return {
    max: encodeSize(data.max),
    length: encodeSize(data.length),
    width: encodeSize(data.width),
    height: encodeSize(data.height),
  };
};

export const encodeSize = (data: Size | null): schemas['SizeData'] | null => {
  if (!data) {
    return null;
  }
  return {
    value: data.value,
    unit: encodeEnum(data.unit, DistanceUnitMap),
    reference_id: data.referenceId,
  };
};

export const encodeThyroidFindingCanvasPosition = (
  data?: CanvasPosition
): schemas['CanvasPositionInput'] | null => {
  if (!data) {
    return null;
  }

  return {
    x_coordinate: data.xCoordinate || null,
    y_coordinate: data.yCoordinate || null,
    custom_width: data.customWidth || null,
    custom_height: data.customHeight || null,
  };
};

export const decodeUpdateFindingResponse = (
  data: schemas['UpdateFindingResponse']
) => {
  const findings = data.findings.map(decodeFinding);

  return {
    findings,
    impressions: data.impressions ?? undefined,
  };
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Popover } from '@webMolecules/Popover/Popover';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import {
  selectDecryptedPhi,
  selectSessionDetails,
} from '@selectors/SessionSelectors';
import { studyTypeToDescription } from '@interfaces/StudyType';
import StaticPatientInfo from '../StaticPatientInfo';

export const PatientDetailsPopover: React.FC = () => {
  const sessionDetails = useSelector(selectSessionDetails);
  const decryptedPhi = useSelector(selectDecryptedPhi);

  if (!sessionDetails || !decryptedPhi) return null;

  const accessionNum = sessionDetails.accessionNumber;
  const studyTypeDescription = studyTypeToDescription(sessionDetails.studyType);

  return (
    <Popover
      trigger={
        <Button
          iconBefore={<Icon name="info" color="var(--ds-text-color-muted)" />}
          size="small"
          circle
        />
      }
      position="bottom-start"
      size="extra-large"
      padding="l"
      showArrow
    >
      <StaticPatientInfo
        studyTypeDescription={studyTypeDescription}
        accessionNum={accessionNum}
        decryptedPhi={decryptedPhi}
      />
    </Popover>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Authenticate/CreateAccount';
import * as CreateAccountStory from '@webPages/Authenticate/CreateAccount';
import { Layout } from '@webPages/Authenticate/Layout';
import { t } from '@webInterfaces/I18n';

const CreateAccount: React.FC<ViewModel.CreateAccount> = ({
  dispatchCreateAccount,
  latestMessage,
  loading,
  email,
  password,
}) => {
  const onSubmit: CreateAccountStory.CreateAccountProps['onSubmit'] = (
    credentials,
    profile
  ) => {
    dispatchCreateAccount(
      credentials.email,
      credentials.newPassword,
      credentials.currentPassword,
      profile.firstName,
      profile.lastName
    );
  };
  const messages = latestMessage ? [latestMessage] : [];
  return (
    <Layout
      title={t('pages.auth.create_account.title')}
      showHelp
      messages={messages}
    >
      <CreateAccountStory.CreateAccount
        email={email}
        password={password}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Layout>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(CreateAccount);

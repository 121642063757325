import React, { useContext } from 'react';
import { Container } from '@webMolecules/Container/Container';
import { ThemeContext } from '@webAtoms/Theme/Theme';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Card } from '@webMolecules/Card/Card';
import { Icon } from '@webMolecules/Icon/Icon';
import { Logo } from '@webMolecules/Logo/Logo';
import { Stack } from '@webMolecules/Stack/Stack';
import { Text } from '@webMolecules/Text/Text';
import {
  FormHint,
  formHintIntentFromString,
} from '@webOrganisms/FormHint/FormHint';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import { t } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import styles from './layout.scss';

export interface LayoutProps {
  messages?: {
    intent: string;
    key: string;
  }[];
  showBack?: boolean;
  showForgotPassword?: boolean;
  showHelp?: boolean;
  title: string;
  showContainer?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  messages = [],
  showBack = false,
  showForgotPassword = false,
  showHelp = true,
  title,
  showContainer = true,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Container
        size="xxs"
        flex="1"
        paddingY="xxxl"
        paddingX="xl"
        className={styles.container}
      >
        <Box
          paddingBottom="xxl"
          marginTop="xxl"
          display="flex"
          justifyContent="center"
        >
          <a href="/" title="Go to sign in">
            <Logo width={80} variant={theme === 'dark' ? 'light' : 'dark'} />
          </a>
        </Box>

        {/* TO-DO componitise this*/}
        {showBack && (
          <Box
            style={{ position: 'absolute', transform: 'translateY(-100%)' }}
            paddingBottom="m"
          >
            <RouteLink
              iconBefore={<Icon name="arrow-left" />}
              size="small"
              goBack
            >
              {t('pages.auth.layout.back')}
            </RouteLink>
          </Box>
        )}
        {/* END componitise this*/}

        {messages.map(({ key, intent }) => (
          <Box key={key + intent} marginBottom="l">
            <FormHint
              data-testid="sign-in-intent"
              intent={formHintIntentFromString(intent)}
            >
              {t(key)}
            </FormHint>
          </Box>
        ))}
        {showContainer ? (
          <Box className={styles.formContainer}>
            <Card elevation="low" padding="xl">
              <Box marginBottom="l">
                <Text type="display2" display="block">
                  {title}
                </Text>
              </Box>

              {children}
            </Card>
            <Box marginTop="m">
              <Stack spacing="xs">
                {showHelp && (
                  <Button
                    href="https://www.see-mode.com/contact"
                    target="blank"
                    iconBefore={<Icon name="lifebuoy" />}
                    size="small"
                  >
                    {t('pages.auth.layout.help')}
                  </Button>
                )}
                {showForgotPassword && (
                  <RouteLink
                    to={RouteHelper.authForgotPassword}
                    iconBefore={<Icon name="help-circle" />}
                    size="small"
                  >
                    {t('pages.auth.layout.forgot_password')}
                  </RouteLink>
                )}
              </Stack>
            </Box>
          </Box>
        ) : (
          <Box>{children}</Box>
        )}
      </Container>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toError = (e: any): Error => {
  if (e instanceof Error) {
    return e;
  }

  const str = (() => {
    if (typeof e === 'string') {
      return e;
    } else if (e?.errors?.length) {
      return e.errors.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (str: string, a: any) =>
          [str, a?.path ? JSON.stringify(a.path) : null, a.message]
            .filter(Boolean)
            .join(' '),
        ''
      );
    } else if (typeof e?.error === 'string') {
      return e.error;
    } else if (typeof e?.error?.message === 'string') {
      return e.error.message;
    } else if (typeof e?.message === 'string') {
      return e.message;
    }
  })();

  return new Error(str);
};

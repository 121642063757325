import { drawDOM, DrawOptions, exportPDF } from '@progress/kendo-drawing';
import { PageMargin } from '@progress/kendo-drawing/pdf';
import { PDF as ContractPDF } from '@contracts/PDF';

export class PDF implements ContractPDF {
  async FromHtml(
    htmlElement: HTMLElement,
    useSinglePage?: boolean
  ): Promise<string> {
    const options = {
      paperSize: useSinglePage ? 'auto' : 'A4',
      margin: '1cm' as PageMargin,
      scale: 0.5,
      keepTogether: '[data-pdf-keep-together]',
      forcePageBreak: '[data-pdf-page-break]',
    };

    return drawDOM(htmlElement, options as DrawOptions).then(a =>
      exportPDF(a, options)
    );
  }
}

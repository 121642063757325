import { sessionUseCase } from '@useCases/Session';
import { Actions } from '@webInterfaces/StoreTypes';

export const downloadEncryptedPdf = (url: string, fileName?: string): Actions =>
  sessionUseCase.DownloadEncryptedPDF({
    encryptedPdfUrl: url,
    fileName,
  });

export const downloadStudyArchive = (sessionId: string): Actions =>
  sessionUseCase.DownloadStudyArchive({ sessionId });

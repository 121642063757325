import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Toasts/Uploads';
import {
  ToastifyUpdateOptions,
  ToastifyOptions,
  CloseButton,
  openToast,
  updateToast,
  closeToast,
} from '@webMolecules/Toast/Toast';
import ToastUpload from './ToastUpload';
import { ToastUploadErrorBackend } from './ToastUploadError';

interface UploadToastRef {
  uploadId: string;
  toastId: React.ReactText;
}

const AnalysisUploads: React.FC<ViewModel.Uploads> = ({
  analysisUploadsLineItems,
}) => {
  const [uploadToasts, setUploadToasts] = React.useState<UploadToastRef[]>([]);

  React.useEffect(() => {
    analysisUploadsLineItems.forEach(analysisUploadsLineItem => {
      const uploadToast = uploadToasts.find(
        uploadToastRef =>
          uploadToastRef.uploadId === analysisUploadsLineItem.localBatchId
      );

      const dismissible =
        analysisUploadsLineItem.failed || !!analysisUploadsLineItem.sessionId;
      const successDismissible = !!analysisUploadsLineItem.sessionId;

      if (uploadToast) {
        const render = analysisUploadsLineItem.failed ? (
          <ToastUploadErrorBackend />
        ) : (
          <ToastUpload {...analysisUploadsLineItem} />
        );

        const updateToastOptions: ToastifyUpdateOptions = { render };

        if (dismissible) {
          updateToastOptions.onClick = () => closeToast(uploadToast.toastId);
          updateToastOptions.closeButton = CloseButton;
        }
        if (successDismissible) {
          updateToastOptions.onClick = () => closeToast(uploadToast.toastId);
          updateToastOptions.closeButton = CloseButton;
          updateToastOptions.autoClose = 5000;
        }
        updateToast(uploadToast.toastId, updateToastOptions);
      } else {
        const toastOptions: ToastifyOptions = {};
        if (dismissible) {
          toastOptions.onClick = () => {
            const uploadToast = uploadToasts.find(
              a => a.uploadId === analysisUploadsLineItem.localBatchId
            );
            if (uploadToast) {
              closeToast(uploadToast.toastId);
            }
          };
          toastOptions.closeButton = CloseButton;
        }

        const newToast = openToast(
          <ToastUpload {...analysisUploadsLineItem} />,
          toastOptions
        );

        setUploadToasts([
          {
            toastId: newToast,
            uploadId: analysisUploadsLineItem.localBatchId,
          },
          ...uploadToasts,
        ]);
      }
    });

    // This will clear any toasts that are no longer referenced in the analysisUploads entity
    uploadToasts.forEach(uploadToastRef => {
      const analysisUploadsLineItem = analysisUploadsLineItems.find(
        analysisUploadsLineItem =>
          uploadToastRef.uploadId === analysisUploadsLineItem.localBatchId
      );
      if (!analysisUploadsLineItem) {
        setUploadToasts(
          uploadToasts.filter(u => u.uploadId !== uploadToastRef.uploadId)
        );
      }
    });
  }, [analysisUploadsLineItems]);

  return null;
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(AnalysisUploads);

import React from 'react';
import isEqual from 'lodash/isEqual';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Image } from '@webMolecules/Image/Image';
import { useImageContext } from '@hooks/useImageContext';

export interface FindingSummaryRowThumbnailProps {
  id: string;
}

export const FindingSummaryRowThumbnail = ({
  id,
}: FindingSummaryRowThumbnailProps) => {
  const { getImage } = useImageContext();
  const imageSrc = getImage(id);

  if (!imageSrc) {
    return null;
  }

  return (
    <Box display="flex" gap="l">
      <Card padding="none" flex="1">
        <Image
          data-testid={id}
          src={imageSrc}
          roundedCorners={true}
          fluid
          ratio="landscape"
        />
      </Card>
    </Box>
  );
};

export const MemoizedFindingSummaryRowThumbnail = React.memo(
  FindingSummaryRowThumbnail,
  isEqual
);

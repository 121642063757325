import React, { SyntheticEvent } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { cnames } from '@helpers/cnames';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import styles from '../video.scss';
import sliderStyles from '../../Slider/slider.scss';

export interface VideoControlsProps {
  playing: boolean;
  videoFrame: number;
  currentFrame: number;
  loading: boolean;
  loadingText: string;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  roundedCorners?: boolean;
  videoHandler: (control: string) => void;
  onSliderUpdate: (value: number) => void;
}

export const VideoControls: React.FC<VideoControlsProps> = ({
  playing,
  videoFrame,
  currentFrame,
  loading,
  loadingText,
  containerRef,
  roundedCorners = false,
  videoHandler,
  onSliderUpdate,
}) => {
  const controlsClassNames = cnames(styles.controlsContainer, {
    [styles.roundedBottom]: roundedCorners,
  });

  const onSliderChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    onSliderUpdate(parseInt(value));
  };

  const stepForward = () => {
    if (currentFrame < videoFrame) {
      onSliderUpdate(currentFrame + 1);
    }
  };

  const stepBack = () => {
    if (currentFrame > 0) {
      onSliderUpdate(currentFrame - 1);
    }
  };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      if (containerRef.current && containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      }
    }
  };

  if (loading) {
    return (
      <Box
        className={controlsClassNames}
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingY="xs"
      >
        <LoadingIcon />
        <Text marginLeft="xs">{loadingText}</Text>
      </Box>
    );
  }

  return (
    <Box className={controlsClassNames} display="flex">
      <Box
        className={styles.controlsBtn}
        onClick={() => videoHandler(playing ? 'pause' : 'play')}
      >
        <Icon name={playing ? 'pause' : 'play'} />
      </Box>

      <Box
        fullWidth
        display="flex"
        alignItems="center"
        className={cnames(sliderStyles.slider)}
        style={
          {
            '--ds-slider-value': currentFrame,
            '--ds-slider-min': 0,
            '--ds-slider-max': videoFrame,
          } as React.CSSProperties
        }
      >
        <input
          className={sliderStyles.sliderInput}
          type="range"
          min={0}
          max={videoFrame}
          value={currentFrame}
          step={1}
          onChange={onSliderChange}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        marginRight="xxs"
        flex="4rem 0 0"
      >
        <Text type="caption" muted>
          {currentFrame}/{videoFrame}
        </Text>
      </Box>

      <Box className={styles.controlsBtn} onClick={stepBack}>
        <Icon name="step-back" />
      </Box>

      <Box className={styles.controlsBtn} onClick={stepForward}>
        <Icon name="step-forward" />
      </Box>

      <Box
        className={styles.controlsBtn}
        onClick={toggleFullScreen}
        paddingLeft="xxs"
      >
        <Icon name={document.fullscreenElement ? 'minimize' : 'maximize'} />
      </Box>
    </Box>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';
import { ListDivider } from '@webMolecules/ListDivider/ListDivider';
import {
  selectComparisonFindings,
  selectIncludedFindings,
} from '@selectors/FindingSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import styles from './comparison.scss';
import { UnassignedComparisonRow } from './UnassignedComparisonRow';
import { ComparisonDropArea } from './ComparisonDragAndDrop';

export interface UnassignedFindingSectionProps {
  isExpanded: boolean;
}

export const UnassignedFindingSection: React.FC<
  UnassignedFindingSectionProps
> = ({ isExpanded }) => {
  const findings = useSelector(selectIncludedFindings);
  const comparisonFindings = useSelector(selectComparisonFindings);
  const dispatchUnassignComparisonFinding = useDispatcher(
    findingUseCase.UnassignComparisonFinding
  );

  const assignedIds = findings
    .map(f => f.comparisonFindingId)
    .filter(Boolean) as string[];
  const unassignedFindings = comparisonFindings.filter(
    f => !assignedIds.includes(f.id)
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      paddingTop="xs"
      className={styles.unassignedFindingSection}
    >
      <Box display="flex" marginBottom="s" className={styles.unassignedLabel}>
        <ListDivider>
          {t('pages.study.comparison.unassigned.finding.label')}
        </ListDivider>
      </Box>

      <ComparisonDropArea
        onDropFinding={findingId =>
          dispatchUnassignComparisonFinding({ comparisonFindingId: findingId })
        }
      >
        <Box style={{ minHeight: calculateMinHeight(findings.length) }}>
          {unassignedFindings.map(finding => {
            return (
              <UnassignedComparisonRow
                key={finding.id}
                finding={finding}
                isExpand={isExpanded}
                totalItems={unassignedFindings.length}
              />
            );
          })}
        </Box>
      </ComparisonDropArea>
    </Box>
  );
};

function calculateMinHeight(items: number) {
  return `${110 * items}px`;
}

import React from 'react';
import debounce from 'lodash/debounce';
import { useController } from 'react-hook-form';
import { TextArea } from '@webMolecules/TextArea/TextArea';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { Text } from '@webMolecules/Text/Text';
import { DEBOUNCE_TIME } from '@webViews/constant';
import { t, tPrint } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';
import { ResultTableSharedProps } from '../ResultTableSharedProps';

interface ResultTableCellTextAreaProps extends ResultTableSharedProps {
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  isDebounce?: boolean;
}

export const ResultTableCellTextArea: React.FC<
  ResultTableCellTextAreaProps
> = ({ path, placeholder, maxLength, isDebounce = true }) => {
  const { theme } = React.useContext(ResultTableContext);
  const { field } = useController({
    name: path ?? '',
  });

  const changeHandler = (value: string) => {
    field.onChange(value);
  };

  const onChangeHandler = debounce(
    (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
      const trimmedValue = (e.target as HTMLInputElement).value;
      if (trimmedValue.trim().length === 0) {
        // Passing an empty string in the changeHandler to set it as an input Value for text description
        changeHandler('');
      }
      changeHandler(trimmedValue);
    },
    isDebounce ? DEBOUNCE_TIME : 0
  );

  if (theme == ResultTableStyle.preview) {
    return field.value ? (
      <Box
        style={{
          backgroundColor: 'var(--ds-background-color-ui)',
        }}
      >
        <Text>{field.value}</Text>
      </Box>
    ) : null;
  }

  return (
    <Box display="flex" flexDirection="column" fullWidth>
      <TextArea
        proxiedKey={`textarea-${path}`}
        fluid
        rows={3}
        size="tiny"
        defaultValue={field.value}
        onKeyUp={onChangeHandler}
        onChange={onChangeHandler}
        autoSize
        maxLength={maxLength}
        placeholder={placeholder && t(placeholder)}
      />
      {maxLength && (
        <Text type="caption" muted>
          {tPrint('textarea.footnotes', maxLength - (field.value?.length || 0))}
        </Text>
      )}
    </Box>
  );
};

import { FieldPath, FieldValues } from 'react-hook-form';
import { Workflow } from '@entities/Workflow';
import { AnyUnit } from '@entities/Unit';
import {
  NormalAbnormalValue,
  YesNoNIValue,
  YesNoValue,
} from '@entities/Characteristics';
import {
  ResultTableToggleConfig,
  ResultTableSelectOptions,
  ResultTableToggleOption,
  ResultTableConfigColumn,
  FeatureFlagConfigFunction,
  InputConfigType,
} from './ResultTable';

export interface FindingFieldConfig<T extends FieldValues = FieldValues>
  extends ResultTableConfigColumn {
  isDisabled?(data: T): boolean;
  note?: string;
  showNote?(data: T): boolean;
  mergedCellFields?: {
    type: MergedCellFieldsType;
    config: MergedCellFieldConfig<T>[];
  };
  workflow?: Workflow;
  showPill?: boolean;
  path?: FieldPath<T>;
}

export type MergedCellFieldsType = 'thyroidSVGSelection';

export interface MergedCellFieldConfig<T extends FieldValues = FieldValues> {
  key: string;
  label: string;
  hideLabel?: boolean;
  unit?: AnyUnit;
  isDisabled?(finding: T): boolean;
  featureEnabled?: FeatureFlagConfigFunction;
  inputConfig?: InputConfigType;
  boldLabel?: boolean;
  labelCaption?: string;
}

export interface FindingSectionConfig<T extends FieldValues = FieldValues> {
  index: number;
  title: string;
  titleSeparator?: boolean;
  fields: FindingFieldConfig<T>[];
  separator: boolean;
  featureEnabled?: boolean;
}

export interface FindingConfig<T extends FieldValues = FieldValues> {
  sections: FindingSectionConfig<T>[];
}

export const mbtPhaseOptions: ResultTableSelectOptions = [
  {
    value: 'monophasic',
    editableI18NKey:
      'config_key.result_table.phase-mbt-options.monophasic.short',
    previewI18NKey: 'config_key.result_table.phase-options.monophasic.full',
  },
  {
    value: 'biphasic',
    editableI18NKey: 'config_key.result_table.phase-mbt-options.biphasic.short',
    previewI18NKey: 'config_key.result_table.phase-options.biphasic.full',
  },
  {
    value: 'triphasic',
    editableI18NKey:
      'config_key.result_table.phase-mbt-options.triphasic.short',
    previewI18NKey: 'config_key.result_table.phase-options.triphasic.full',
  },
];

export const multiPhaseOptions: ResultTableSelectOptions = [
  {
    value: 'monophasic',
    editableI18NKey:
      'config_key.result_table.phase-multi-options.monophasic.short',
    previewI18NKey: 'config_key.result_table.phase-options.monophasic.full',
  },
  {
    value: 'multiphasic',
    editableI18NKey:
      'config_key.result_table.phase-multi-options.multiphasic.short',
    previewI18NKey: 'config_key.result_table.phase-options.multiphasic.full',
  },
];

export const yesNoSelectOptions: ResultTableSelectOptions = [
  {
    value: YesNoValue.No,
    editableI18NKey: 'result_table.shared.options.no',
    previewI18NKey: 'result_table.shared.options.no',
  },
  {
    value: YesNoValue.Yes,
    editableI18NKey: 'result_table.shared.options.yes',
    previewI18NKey: 'result_table.shared.options.yes',
  },
];

export const yesNoNIOptions: ResultTableToggleOption[] = [
  {
    value: YesNoNIValue.No,
    editableI18NKey: 'result_table.shared.options.no',
    previewI18NKey: 'result_table.shared.options.no',
  },
  {
    value: YesNoNIValue.Yes,
    editableI18NKey: 'result_table.shared.options.yes',
    previewI18NKey: 'result_table.shared.options.yes',
  },
  {
    value: YesNoNIValue.NI,
    editableI18NKey: 'result_table.shared.options.not_imaged.short',
    previewI18NKey: 'result_table.shared.options.not_imaged.full',
    tooltipI18NKey: 'result_table.shared.options.not_imaged.full',
  },
];

export const normalAbnormalSelectOptions: ResultTableSelectOptions = [
  {
    value: NormalAbnormalValue.Normal,
    editableI18NKey: 'result_table.shared.options.normal',
    previewI18NKey: 'result_table.shared.options.normal',
  },
  {
    value: NormalAbnormalValue.Abnormal,
    editableI18NKey: 'result_table.shared.options.abnormal',
    previewI18NKey: 'result_table.shared.options.abnormal',
  },
];

export const yesNoToggleOptions: ResultTableToggleConfig = {
  size: 'medium',
  options: [...yesNoSelectOptions],
};

export const normalAbnormalToggleOptions: ResultTableToggleConfig = {
  size: 'medium',
  options: [...normalAbnormalSelectOptions],
};

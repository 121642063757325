import React from 'react';
import { cnames } from '@helpers/cnames';
import styles from './grid.scss';
import { Box } from './../Box/Box';

export interface IGridProps {
  className?: string;
  children?: React.ReactNode;
  columns?: number | string;
  condensed?: boolean;
}

export const Grid: React.FC<IGridProps> = ({
  className,
  condensed,
  columns = 4,
  children,
}) => {
  const classNames = cnames(
    styles.grid,
    { [styles.condensed]: condensed },
    className
  );

  const gridTemplateColumns =
    typeof columns === 'number'
      ? `repeat(${columns}, minmax(0, 1fr))`
      : columns;

  return (
    <Box
      data-testid="grid-test-id"
      className={classNames}
      fullWidth
      style={{ gridTemplateColumns }}
    >
      {children}
    </Box>
  );
};

import { WorkspaceStatus } from '@interfaces/WorkspaceStatus';
import { WorkspaceGroup } from '@interfaces/WorkspaceGroup';
import { WorklistGroup, components } from '@api/schema/generated';

export const decode = (
  data: components['schemas']['WorklistResponse']
): WorkspaceStatus => {
  return {
    id: data.worklist_id,
    group: decodeWorklistGroup(data.group),
    totalAmountOfNewItems: data.count,
  };
};

export const decodeWorklistGroup = (
  worklistGroup: components['schemas']['WorklistGroup']
) => {
  switch (worklistGroup) {
    case WorklistGroup.USER:
      return WorkspaceGroup.User;
    default:
    case WorklistGroup.FILTER_CONFIGURATION:
      return WorkspaceGroup.Workspace;
  }
};

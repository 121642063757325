import {
  AccelerationUnit,
  AnyUnit,
  DistanceUnit,
  PressureUnit,
  TimeUnit,
  UnitEntity,
  VelocityUnit,
  VolumeUnit,
} from '@entities/Unit';

export const roundValueByUnit = (value: number, unit: AnyUnit) => {
  switch (unit) {
    case DistanceUnit.CM:
      return parseFloat(value.toFixed(1));
    case DistanceUnit.MM:
      return parseFloat(value.toFixed(0));
    case VolumeUnit.ML:
      // Three significant figures
      return parseFloat(value.toPrecision(3));
    default:
      return value;
  }
};

export const Conversions: Record<UnitEntity, Record<string, number>> = {
  [UnitEntity.Velocity]: {
    [VelocityUnit.CMS]: 1, // use cm/s as our base unit
    [VelocityUnit.MS]: 100,
  },
  [UnitEntity.Distance]: {
    [DistanceUnit.MM]: 0.1,
    [DistanceUnit.CM]: 1, // use centimeter as our base unit
    [DistanceUnit.M]: 100,
  },
  [UnitEntity.Time]: {
    [TimeUnit.MS]: 1, // use millisecond as our base unit
    [TimeUnit.S]: 1000,
    [TimeUnit.M]: 6000,
    [TimeUnit.H]: 3600000,
    [TimeUnit.D]: 86400000,
  },
  [UnitEntity.Volume]: {
    [VolumeUnit.ML]: 1, // use milliliter as our base unit
    [VolumeUnit.CL]: 10,
    [VolumeUnit.L]: 1000,
  },
  [UnitEntity.Acceleration]: {
    [AccelerationUnit.MMS2]: 0.1,
    [AccelerationUnit.CMS2]: 1, // use centimeter/square second as our base unit
  },
  [UnitEntity.Pressure]: {
    [PressureUnit.MMHG]: 1,
  },
};

export const convertUnit = <T extends AnyUnit>(
  unit: T,
  targetUnit: T,
  value: number
): number => {
  const unitEntity = getEntityByUnit(unit);
  const targetUnitEntity = getEntityByUnit(targetUnit);

  if (!unitEntity || !targetUnitEntity) {
    console.error(`Can not get ${unit} or ${targetUnit} in Conversions map. `);
    return value;
  }

  if (unitEntity != targetUnitEntity) {
    console.error(`Can not convert ${unit} to ${targetUnit}. `);
    return value;
  }

  const conversions = Conversions[unitEntity];
  if (conversions) {
    if (unit == targetUnit) return value;

    return value / (conversions[targetUnit] / conversions[unit]);
  }

  return value;
};

export const convertAndRoundByUnit = <T extends AnyUnit>(
  value: number,
  unit: T | undefined,
  targetUnit: T | undefined
): number => {
  if (unit && targetUnit) {
    return roundValueByUnit(convertUnit(unit, targetUnit, value), targetUnit);
  }
  return value;
};

export const getEntityByUnit = (unit: AnyUnit): UnitEntity | null => {
  for (const unitEntityString in Conversions) {
    const unitEntity = unitEntityString as UnitEntity;
    const unitEntityKeys = Object.keys(Conversions[unitEntity]);
    if (unitEntityKeys.includes(unit)) {
      return unitEntity;
    }
  }

  return null;
};

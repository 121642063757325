import { schemas } from '@api/schema/client';
import { StudyBatchStatusEnum } from '@api/schema/generated';
import {
  PACSStudyStatus,
  PACSStudyLineItem,
} from '@interfaces/PACSStudyLineItem';

const MAX_NUM_OF_PACS_STUDY = 10;

export const decode = (data: schemas['PACSStudy']): PACSStudyLineItem => {
  const id = data.sm_study_instance_uid;
  const date = data.scan_date;
  const operatorCode = data.operator_code;
  const accession = data.accession_id;
  const status = (() => {
    switch (data.status) {
      case StudyBatchStatusEnum.IN_PROGRESS:
      case StudyBatchStatusEnum.RETRIEVED:
      case StudyBatchStatusEnum.RECEIVING:
        return PACSStudyStatus.Retrieving;
      case StudyBatchStatusEnum.ANALYSING:
        return PACSStudyStatus.Analysing;
      case StudyBatchStatusEnum.ANALYSED:
        return PACSStudyStatus.Analysed;
      case StudyBatchStatusEnum.DISCARDED:
      case StudyBatchStatusEnum.ERROR:
        return PACSStudyStatus.Error;
    }
  })();

  return {
    id,
    date,
    accession,
    operatorCode,
    status,
  };
};

export const getFilteredPACSStudies = (
  totalPACSStudyLineItems: PACSStudyLineItem[]
) => {
  /*
   * PACS Modal displaying logic
   * - All studies in "Processing", "Receiving", or "Error" states
   * - If that makes <10 studies, show enough "Ready" studies to make the list up to 10
   * - Take the most recent studies, enough to make the total up to 10.
   */

  let PACSStudyLineItems = totalPACSStudyLineItems;

  const nonReadyPACSStudies = totalPACSStudyLineItems.filter(
    item => item.status !== PACSStudyStatus.Analysed
  );

  if (nonReadyPACSStudies.length >= MAX_NUM_OF_PACS_STUDY) {
    PACSStudyLineItems = nonReadyPACSStudies;
  } else {
    const readyPACSStudies = totalPACSStudyLineItems.filter(
      item => item.status === PACSStudyStatus.Analysed
    );

    const numOfReadyPACSStudiesNeeded =
      MAX_NUM_OF_PACS_STUDY - nonReadyPACSStudies.length;

    PACSStudyLineItems = [
      ...nonReadyPACSStudies,
      ...readyPACSStudies.slice(0, numOfReadyPACSStudiesNeeded),
    ];
  }

  return PACSStudyLineItems;
};

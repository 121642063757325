export * from '@entities/Profile';

export const userStatusToString = (userStatus: boolean): string => {
  return userStatus ? 'Active' : 'Pending';
};

export const userPermissionToString = (userPermission: boolean): string => {
  return userPermission ? 'Admin' : 'User';
};

export const stringToUserPermission = (input: string): boolean =>
  input == 'Admin';

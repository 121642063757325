import React, { SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { userColourPaletteActive } from '@appCanvas/interfaces/types/ColourPalette.type';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import {
  GalleryFilter,
  getDefaultGalleryFilter,
} from '@interfaces/GalleryFilter';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { useDispatcher } from '@helpers/useDispatcher';
import {
  selectActiveFinding,
  selectFilterId,
} from '@selectors/FindingInteractionSelectors';
import {
  selectSessionDetails,
  selectSessionViewed,
  selectStudyType,
} from '@selectors/SessionSelectors';
import { GeneralCharacteristicsTable } from '@webOrganisms/GeneralCharacteristicsTable/GeneralCharacteristicsTable';
import { Drawer } from '@webMolecules/Drawer/Drawer';
import { StudyType } from '@entities/StudyType';
import { sessionUseCase } from '@useCases/Session';
import styles from './study.scss';
import FindingsTableWrapper from './FindingsTableWrapper';
import GalleryWrapper from './GalleryWrapper';
import { MiniCanvasProvider } from './MiniCanvasContext';
import ReportDiagramWrapper from './Diagram/ReportDiagramWrapper';
import FindingFilterBar from './FindingFilterBar/FindingFilterBar';

export const SmallPartsStudyFindingLayout: React.FC = () => {
  return (
    <MiniCanvasProvider>
      <SmallPartsStudyFindingLayoutInner />
    </MiniCanvasProvider>
  );
};

const SmallPartsStudyFindingLayoutInner: React.FC = () => {
  const sessionDetails = useSelector(selectSessionDetails);
  const sessionViewed = useSelector(selectSessionViewed);
  const loading = sessionDetails == null;
  const activeFinding = useSelector(selectActiveFinding);
  const filterId = useSelector(selectFilterId);
  const studyType = useSelector(selectStudyType);

  const resetInteractions = useDispatcher(findingInteractionUseCase.Reset);
  const deselectActiveFinding = useDispatcher(
    findingInteractionUseCase.Deselect
  );

  const setSessionViewed = useDispatcher(sessionUseCase.SetSessionViewed);
  const removeFilter = useDispatcher(findingInteractionUseCase.RemoveFilter);
  const [galleryFilter, setGalleryFilter] = React.useState<GalleryFilter>(
    getDefaultGalleryFilter(studyType, true)
  );

  const [showGeneralCharacteristicsTable, setShowGeneralCharacteristicsTable] =
    React.useState(!sessionViewed ?? false);

  React.useEffect(() => {
    return () => {
      resetInteractions();
      setSessionViewed();
    };
  }, []);

  const galleryFilterWhenSelected = !!filterId
    ? { configKeys: [], findingIds: [filterId] }
    : galleryFilter;

  const setGalleryFilterWhenSelected: React.Dispatch<
    React.SetStateAction<GalleryFilter>
  > = (filter: SetStateAction<GalleryFilter>) => {
    if (!!filterId) {
      removeFilter();
    } else {
      setGalleryFilter(filter);
    }
  };

  return (
    <Box display="flex" onClick={deselectActiveFinding} fullWidth>
      <Box className={styles.studyPanel}>
        <GalleryWrapper
          loading={loading}
          filters={galleryFilterWhenSelected}
          setGalleryFilter={setGalleryFilterWhenSelected}
          useFindingGalleryThumbnail
        />
      </Box>

      <Box
        className={styles.studyPanel}
        display="flex"
        style={{
          pointerEvents: activeFinding ? 'none' : 'auto',
          maxWidth: '50%',
        }}
        flexDirection="column"
      >
        <Box paddingX="xl">
          <FindingFilterBar />
        </Box>
        <Box paddingTop="s" scrollable="y" paddingX="xl">
          <Box paddingBottom="xs">
            <ReportDiagramWrapper
              canvasColorPalette={userColourPaletteActive}
              showCanvasOnly={false}
              setShowGeneralTable={setShowGeneralCharacteristicsTable}
            />
          </Box>
          <Box paddingBottom="xl">
            <FindingsTableWrapper
              applyFindingFilter={false}
              resultTableStyle={ResultTableStyle.editable}
            />
          </Box>
        </Box>
        {studyType === StudyType.SmallPartsThyroid && (
          <Drawer isOpen={showGeneralCharacteristicsTable} size="medium">
            <GeneralCharacteristicsTable
              setShowTable={setShowGeneralCharacteristicsTable}
              filters={galleryFilterWhenSelected}
              setGalleryFilter={setGalleryFilterWhenSelected}
            />
          </Drawer>
        )}
      </Box>
    </Box>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Authenticate/SignIn';
import * as SignInStory from '@webPages/Authenticate/SignIn';
import * as FederatedSignInStory from '@webPages/Authenticate/FederatedSignIn';
import { Layout } from '@webPages/Authenticate/Layout';
import { t } from '@webInterfaces/I18n';
import { getSearchParam } from '@webInterfaces/ReactRouter';
import { useAuthenticationMode } from '@interfaces/Authentication';
import { FederatedSignIn } from '@webPages/Authenticate/FederatedSignIn';

const SignIn: React.FC<ViewModel.SignIn> = ({
  dispatchSignIn,
  dispatchSignInWithMFACode,
  dispatchCancelMFA,
  dispatchFederatedSignIn,
  latestMessage,
  loading,
  hasMFAEnabled,
}) => {
  const onSubmit: SignInStory.SignInProps['onSubmit'] = ({
    email,
    password,
  }) => {
    dispatchSignIn(email, password);
  };

  const onSubmitWithMFA: SignInStory.SignInProps['onSubmitWithMFA'] = (
    otp: string
  ) => {
    dispatchSignInWithMFACode(otp);
  };

  const onCancelMFA: SignInStory.SignInProps['onCancelMFA'] = () => {
    dispatchCancelMFA();
  };

  const onFederatedSubmit: FederatedSignInStory.FederatedSignInProps['onSubmit'] =
    () => {
      dispatchFederatedSignIn();
    };

  let messages = latestMessage ? [latestMessage] : [];
  const reason = getSearchParam('reason');
  if (reason && messages.length === 0 && !hasMFAEnabled) {
    window.history.replaceState(null, '', window.location.pathname);
    messages = [{ intent: 'info', key: `pages.auth.sign_in.${reason}` }];
  }
  const { isFederatedIdentity, institutionCopyText } = useAuthenticationMode();
  return (
    <Layout
      title={t('pages.auth.sign_in.title')}
      showForgotPassword={!isFederatedIdentity}
      showHelp
      messages={messages}
    >
      {isFederatedIdentity ? (
        <FederatedSignIn
          onSubmit={onFederatedSubmit}
          institutionCopyText={institutionCopyText}
        />
      ) : (
        <SignInStory.SignIn
          onSubmit={onSubmit}
          onSubmitWithMFA={onSubmitWithMFA}
          onCancelMFA={onCancelMFA}
          loading={loading}
          hasMFAEnabled={hasMFAEnabled}
        />
      )}
    </Layout>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(SignIn);

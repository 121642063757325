import React, { ReactNode } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { cnames } from '@helpers/cnames';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import { Text } from '../Text/Text';
import styles from './toggleGroup.scss';

export interface ToggleGroupOption {
  value: string;
  label: React.ReactNode;
  tooltip?: string;
}

export interface ToggleGroupProps {
  key: string;
  options: Array<ToggleGroupOption>;
  value: string | undefined | null;
  onChange: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  fluid?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const ToggleGroup = ({
  key,
  options,
  value,
  onChange,
  disabled,
  readOnly,
  fluid,
  size = 'medium',
}: ToggleGroupProps) => {
  const groupClassNames = cnames(styles.toggleGroup, styles[size]);

  return (
    <Box
      className={groupClassNames}
      display={fluid ? 'flex' : 'inline-flex'}
      padding="xxxs"
    >
      {options.map(option => (
        <ToggleGroupItem
          key={`key-${key}-${option.value}`}
          value={option.value}
          label={option.label}
          checked={value === option.value}
          tooltip={option.tooltip}
          disabled={disabled}
          readOnly={readOnly}
          onChange={e => onChange(e.target.value)}
        />
      ))}
    </Box>
  );
};

export interface ToggleGroupItemProps {
  value: string;
  label: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  tooltip?: string;
  width?: string;
}

const ToggleGroupItem = (props: ToggleGroupItemProps) => {
  const {
    value,
    label,
    onChange,
    checked,
    disabled,
    readOnly,
    tooltip,
    width,
  } = props;

  const wrapperClassNames = cnames(styles.toggleItemWrapper, {
    [styles.disabled]: disabled,
    [styles.readOnly]: readOnly,
  });

  const toggleItemClassNames = cnames(styles.toggleItem);
  return (
    <OptionalTooltip tooltip={tooltip}>
      <Box
        element="label"
        display="flex"
        flex="1 0 auto"
        className={wrapperClassNames}
      >
        <input
          type="radio"
          value={value}
          onChange={onChange}
          checked={checked}
          className={styles.toggleInput}
          disabled={disabled}
        />
        <Box
          className={toggleItemClassNames}
          display="flex"
          flex="1 0 auto"
          alignItems="center"
          justifyContent="center"
          onChange={onChange}
          style={{ width }}
        >
          <Text data-testid="checkmarkText" type="body2">
            {label}
          </Text>
        </Box>
      </Box>
    </OptionalTooltip>
  );
};

interface OptionalTooltipProps {
  tooltip?: string;
  children: ReactNode;
}

const OptionalTooltip: React.FC<OptionalTooltipProps> = ({
  tooltip,
  children,
}: OptionalTooltipProps) => {
  if (tooltip) {
    return (
      <Tooltip label={tooltip} position={TooltipPosition.Top}>
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { produce } from 'immer';
import * as ViewModel from '@webViewModels/Pages/Study/ReportDiagram';
import { CanvasObjectType, ICanvasItem } from '@appCanvas/interfaces/types';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { canvasObjectUseCase } from '@useCases/CanvasObject';
import {
  selectCanvasObjects,
  selectMaxThyroidLobeSize,
} from '@selectors/SessionSelectors';
import {
  selectComparisonFindings,
  selectIncludedFindings,
} from '@selectors/FindingSelectors';
import { Finding } from '@entities/Finding';
import { Annotations } from '@entities/Dicom';
import {
  DiagramContextProvider,
  TOOLBAR_ACTION_DELETE_CANVAS_OBJECT,
  ToolbarAction,
} from './DiagramContext';
import { ReportDiagram } from './ReportDiagram';
import {
  decodeCanvasObjectsToCanvasItems,
  getCanvasObjectsFromFindings,
} from './ReportCanvas/Helper';

export interface MenuType {
  show: boolean;
  annotations: Annotations;
}

const ReportDiagramWrapper: React.FC<ViewModel.ReportDiagram> = ({
  canvasColorPalette,
  dispatchUpdateConfiguration,
  loading,
  sessionConfiguration,
  showCanvasOnly,
  disableInteractions,
  isPrevious,
  onlyShowMatchedLesion,
  setShowGeneralTable,
}) => {
  const maxLobeSize = useSelector(selectMaxThyroidLobeSize);
  const allFindings = useSelector(selectIncludedFindings);
  const comparisonFindings = useSelector(selectComparisonFindings);
  const canvasObjects = useSelector(selectCanvasObjects);

  const deleteFindingFromCanvas = useDispatcher(findingUseCase.DeleteFinding);
  const deleteCanvasObject = useDispatcher(
    canvasObjectUseCase.DeleteCanvasObject
  );

  const diagramFindings = isPrevious
    ? setComparisonFindingIndexes(
        comparisonFindings,
        allFindings,
        !!onlyShowMatchedLesion
      )
    : allFindings;

  const findings = getCanvasObjectsFromFindings(
    diagramFindings,
    maxLobeSize,
    isPrevious
  );

  const canvasItems = decodeCanvasObjectsToCanvasItems(canvasObjects);

  const allCanvasItems = [...findings, ...canvasItems];

  const onDeleteCanvasObject = (canvasItem: ICanvasItem) => {
    if (
      [
        CanvasObjectType.ThyroidFinding,
        CanvasObjectType.BreastFinding,
      ].includes(canvasItem.type)
    ) {
      deleteFindingFromCanvas({ findingId: canvasItem.id });
    } else {
      deleteCanvasObject({ canvasObjectId: canvasItem.id });
    }
  };

  const onToolbarAction = (action: ToolbarAction) => {
    switch (action.type) {
      case TOOLBAR_ACTION_DELETE_CANVAS_OBJECT: {
        const canvasItem = allCanvasItems.find(item => item.id === action.id);
        if (canvasItem) {
          onDeleteCanvasObject(canvasItem);
          break;
        }
      }
    }
  };

  return (
    <DiagramContextProvider onToolbarAction={onToolbarAction}>
      <ReportDiagram
        sessionConfiguration={sessionConfiguration}
        canvasColorPalette={canvasColorPalette}
        onDeleteCanvasObject={onDeleteCanvasObject}
        dispatchUpdateConfiguration={dispatchUpdateConfiguration}
        loading={loading}
        canvasItems={allCanvasItems}
        isPreviousCanvas={isPrevious ?? false}
        showCanvasOnly={showCanvasOnly}
        disableInteractions={disableInteractions}
        setShowGeneralTable={setShowGeneralTable}
      />
    </DiagramContextProvider>
  );
};

function setComparisonFindingIndexes(
  comparisonFindings: Finding[],
  findings: Finding[],
  onlyShowMatchedLesion: boolean
): Finding[] {
  // Set the index of the comparison finding to the index of the matched finding
  const updatedComparisonFindings = produce(comparisonFindings, items => {
    items.forEach(comparisonFinding => {
      const associatedFinding = findings.find(
        f => f.comparisonFindingId === comparisonFinding.id
      );
      if (associatedFinding) {
        comparisonFinding.index = associatedFinding.index;
      } else {
        comparisonFinding.index = 0;
      }
    });
  });

  if (onlyShowMatchedLesion) {
    return updatedComparisonFindings.filter(f => f.index !== 0);
  }

  return updatedComparisonFindings;
}

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(ReportDiagramWrapper);

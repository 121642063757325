import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import {
  forgotPasswordRequest,
  forgotPasswordReset,
} from '@webViewInterfaces/Pages/Authenticate/ForgotPassword';
import { Message } from '@interfaces/Message';
import { toAuth, toLatestMessage } from '@interfaces/Root';
import {
  isBusy,
  isDomainBlocked,
  isResettingTheirPassword,
  toEmail,
} from '@interfaces/Authentication';

export type ForgotPassword = ForgotPasswordProps & ForgotPasswordDispatchers;

export interface ForgotPasswordProps {
  latestMessage?: Message;
  loading: boolean;
  blocked: boolean;
  step: 'request' | 'reset';
  email?: string;
}

export interface ForgotPasswordDispatchers {
  dispatchForgotPasswordRequest: (email: string) => Dispatch<Actions>;
  dispatchForgotPasswordReset: (
    email: string,
    code: string,
    newPassword: string
  ) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  dispatchForgotPasswordRequest: (email: string) =>
    dispatch(forgotPasswordRequest(email)),
  dispatchForgotPasswordReset: (
    email: string,
    code: string,
    newPassword: string
  ) => dispatch(forgotPasswordReset(email, code, newPassword)),
});

export const fromState = (state: State): ForgotPasswordProps => {
  const auth = toAuth(state);
  const blocked = isDomainBlocked(auth);
  const latestMessage = toLatestMessage(state);
  const email = toEmail(auth);
  const loading = isBusy(auth);
  const step = isResettingTheirPassword(auth) ? 'reset' : 'request';
  return {
    latestMessage,
    loading,
    blocked,
    step,
    email,
  };
};

import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Stack } from '@webMolecules/Stack/Stack';
import { t } from '@webInterfaces/I18n';
import { PASSWORD_REGEX } from '@webViews/constant';

export interface ForgotPasswordResetProps {
  /**
   * Current email
   */
  email: string;
  /**
   * onSubmit forgot password callback
   */
  onSubmit: (email: string, code: string, newPassword: string) => void;
  /**
   * Form is loading
   */
  loading?: boolean;
}

export const ForgotPasswordReset = ({
  onSubmit,
  loading = false,
  email,
}: ForgotPasswordResetProps) => {
  const ForgotPasswordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('generic.validation.invalid.email'))
      .required(t('generic.validation.required.email')),
    code: Yup.string().required(
      t('generic.validation.required.verification_code')
    ),
    newPassword: Yup.string()
      .required(t('generic.validation.required.new_password'))
      .matches(PASSWORD_REGEX, t('generic.validation.invalid.password')),
  });

  const initialFormValues = {
    email,
    code: '',
    newPassword: '',
    showPassword: false,
  };

  return (
    <Formik
      onSubmit={({ email, code, newPassword }) => {
        onSubmit(email, code, newPassword);
      }}
      initialValues={initialFormValues}
      validationSchema={ForgotPasswordResetSchema}
    >
      {formProps => (
        <Form>
          <Stack spacing="m" direction="vertical">
            <Box>
              <FormInput
                error={formProps.errors.email}
                data-testid="forgotpassword-email"
                name="email"
                placeholder={t('pages.auth.sign_in.placeholder.email')}
                size="large"
                fluid
                touched={formProps.touched.email}
                type={FormInputTypes.Text}
              />
              <ErrorMessage
                name="email"
                render={msg => (
                  <FormFieldHint intent="error" marginTop="xs">
                    {msg}
                  </FormFieldHint>
                )}
              />
            </Box>

            <Box>
              <FormInput
                error={formProps.errors.code}
                name="code"
                placeholder={t(
                  'pages.auth.forgot_password.placeholder.verification_code'
                )}
                size="large"
                fluid
                data-testid="forgotpassword-code"
                touched={formProps.touched.code}
                type={FormInputTypes.Text}
              />
              {formProps.touched.code && formProps.errors.code ? (
                <ErrorMessage
                  name="code"
                  render={msg => (
                    <FormFieldHint intent="error" marginTop="xs">
                      {msg}
                    </FormFieldHint>
                  )}
                />
              ) : (
                <FormFieldHint intent="neutral" marginTop="xs">
                  {t('pages.auth.forgot_password.hint.code')}
                </FormFieldHint>
              )}
            </Box>

            <Box>
              <FormInput
                autoComplete="new-password"
                error={formProps.errors.newPassword}
                name="newPassword"
                data-testid="forgotpassword-newpassword"
                placeholder={t(
                  'pages.auth.forgot_password.placeholder.new_password'
                )}
                size="large"
                fluid
                touched={formProps.touched.newPassword}
                type={
                  formProps.values.showPassword
                    ? FormInputTypes.Text
                    : FormInputTypes.Password
                }
              />
              <ErrorMessage
                name="newPassword"
                render={msg => (
                  <FormFieldHint intent="error" marginTop="xs">
                    {msg}
                  </FormFieldHint>
                )}
              />
            </Box>

            <FormInput name="showPassword" type={FormInputTypes.Checkbox}>
              {t('pages.auth.sign_in.show_password')}
            </FormInput>

            <Button
              loading={loading}
              type="submit"
              intent="primary"
              strong
              fluid
              size="large"
              data-testid="forgotpassword-submit"
            >
              {t('pages.auth.forgot_password.submit_reset')}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

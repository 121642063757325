import { SessionStatus } from '@entities/SessionLineItem';
export * from '@entities/SessionLineItem';

export const sessionStatusArrayToString = (
  status: SessionStatus[],
  isBookmarked?: boolean
): string => {
  if (isBookmarked) {
    return 'bookmarked';
  } else if (status.includes(SessionStatus.Archived)) {
    return 'archived';
  } else if (status.includes(SessionStatus.Completed)) {
    return 'completed';
  }
  return 'active';
};

export const stringToSessionStatusArray = (str: string): SessionStatus[] => {
  switch (str.toLowerCase()) {
    default:
    case 'active':
      return [SessionStatus.New, SessionStatus.InProgress];
    case 'completed':
      return [SessionStatus.Completed];
    case 'archived':
      return [SessionStatus.Archived];
    case 'bookmarked':
      return [SessionStatus.New, SessionStatus.InProgress];
  }
};

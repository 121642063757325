import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BodySide } from '@entities/Characteristics';
import { selectConfig } from '@selectors/BaseSelectors';
import { ThyroidPoleVariant } from '@entities/InstitutionConfiguration';
import { ThyroidThreePolesSVG } from './svgs/ThyroidThreePolesSVG';
import { ThyroidFivePolesSVG } from './svgs/ThyroidFivePolesSVG';
import styles from './thyroidInteractiveSVG.scss';

export interface ThyroidInteractiveSVGProps {
  selectedBodySide?: string;
  selectedPole?: string;
  onChange?: (bodySide: string, pole: string | undefined) => void;
}

export const ThyroidInteractiveSVG = ({
  selectedBodySide,
  selectedPole,
  onChange,
}: ThyroidInteractiveSVGProps) => {
  const [region, setRegion] = useState<string | null>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const config = useSelector(selectConfig);

  const setPathClass = (target: SVGPathElement, className: string) => {
    target.removeAttribute('class');
    target.classList.add(className);
  };

  const regionRef = useRef(region);

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      const target = e.target as SVGPathElement;
      const id = target.id;
      if (id === regionRef.current) return;
      if (id) {
        setRegion(id);
      }
    };

    const handleMouseOver = (e: MouseEvent) => {
      const target = e.target as SVGPathElement;
      const id = target.id;
      if (id === regionRef.current) return;
      if (id) {
        setPathClass(target, styles.hover);
      }
    };

    const handleMouseOut = (e: MouseEvent) => {
      const target = e.target as SVGPathElement;
      const id = target.id;
      if (id === regionRef.current) return;

      if (id) {
        setPathClass(target, styles.idle);
      }
    };

    if (svgRef.current) {
      const paths = svgRef.current.querySelectorAll('path');

      paths.forEach((path: SVGPathElement) => {
        if (path.id) {
          setPathClass(path, styles.idle);
        }
      });

      svgRef.current.addEventListener('mouseup', handleMouseUp);
      svgRef.current.addEventListener('mouseover', handleMouseOver);
      svgRef.current.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      if (svgRef.current) {
        svgRef.current.removeEventListener('mouseup', handleMouseUp);
        svgRef.current.removeEventListener('mouseover', handleMouseOver);
        svgRef.current.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [svgRef]);

  useEffect(() => {
    if (region) {
      regionRef.current = region;

      if (svgRef.current) {
        const paths = svgRef.current.querySelectorAll('path');

        paths.forEach((path: SVGPathElement) => {
          if (path.id && path.id == region) {
            setPathClass(path, styles.selected);
          } else if (path.id) {
            setPathClass(path, styles.idle);
          }
        });
      }

      const split = region.split('_');
      const pole = split.slice(1).join('_') || undefined;
      const bodySide = split[0];
      onChange && onChange(bodySide, pole);
    }
  }, [region, svgRef]);

  useEffect(() => {
    if (selectedBodySide || region) {
      const selectedRegion =
        selectedBodySide === BodySide.Median
          ? selectedBodySide
          : `${selectedBodySide}_${selectedPole}`;

      if (selectedRegion !== region) {
        setRegion(selectedRegion);
      }
    }
  }, [selectedBodySide, selectedPole]);

  return (
    <>
      {config.thyroidPoleVariant === ThyroidPoleVariant.ThreePoles && (
        <ThyroidThreePolesSVG ref={svgRef} />
      )}
      {config.thyroidPoleVariant === ThyroidPoleVariant.FivePoles && (
        <ThyroidFivePolesSVG ref={svgRef} />
      )}
    </>
  );
};

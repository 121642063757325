import {
  EchotextureValue,
  NormalAbnormalValue,
  ThyroidVascularityValue,
  YesNoValue,
} from '@entities/Characteristics';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { AnyUnit, DistanceUnit, VolumeUnit } from '@entities/Unit';

export interface ObservationFeatureConfig {
  configKey: string;
  path: string;
  valueType: 'dimensions' | 'float' | 'string';
  unit?: AnyUnit;
  formattedFunction?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    generalCharacteristics?: ThyroidGeneralCharacteristics
  ) => string | { i18nKey: string; data: string } | undefined;
}

export interface ObservationConfig {
  displayI18NKey: string;
  volume?: ObservationFeatureConfig;
  size: ObservationFeatureConfig;
  characteristics: ObservationFeatureConfig[];
}

const vascularityValueToI18Nkey = (value: ThyroidVascularityValue) => {
  if (value === ThyroidVascularityValue.Normal) {
    return 'study.summary_tab.thyroid.vascularity.value.normal';
  } else if (value === ThyroidVascularityValue.Increased) {
    return 'study.summary_tab.thyroid.vascularity.value.increased';
  }
  return undefined;
};

const echotextureValueToI18Nkey = (value: EchotextureValue) => {
  if (value === EchotextureValue.Homogeneous) {
    return 'study.summary_tab.thyroid.echotexture.value.homogeneous';
  } else if (value === EchotextureValue.Heterogeneous) {
    return 'study.summary_tab.thyroid.echotexture.value.heterogeneous';
  }

  return undefined;
};

const retrosternalExtToI18Nkey = (value: YesNoValue) => {
  if (value === YesNoValue.Yes) {
    return 'study.summary_tab.thyroid.retrosternal_ext.value.yes';
  } else if (value === YesNoValue.No) {
    return 'study.summary_tab.thyroid.retrosternal_ext.value.no';
  }
  return undefined;
};

const yesNoValueToI18Nkey = (value: YesNoValue) => {
  if (value === YesNoValue.Yes) {
    return 'result_table.shared.options.yes';
  } else if (value === YesNoValue.No) {
    return 'result_table.shared.options.no';
  }
  return undefined;
};

export const rightThyroidObservationsConfig: ObservationConfig = {
  displayI18NKey: 'result_table.heading.key.right',
  volume: {
    configKey: 'right.thyroid.thyroid',
    valueType: 'float',
    path: 'rightLobe.volume',
    unit: VolumeUnit.ML,
  },
  size: {
    configKey: 'right.thyroid.thyroid',
    valueType: 'dimensions',
    path: 'rightLobe',
    unit: DistanceUnit.MM,
  },
  characteristics: [
    {
      configKey: 'right.thyroid.thyroid',
      valueType: 'string',
      path: 'rightLobe.vascularity',
      formattedFunction: vascularityValueToI18Nkey,
    },
    {
      configKey: 'right.thyroid.thyroid',
      valueType: 'string',
      path: 'rightLobe.echotexture',
      formattedFunction: echotextureValueToI18Nkey,
    },
    {
      configKey: 'right.thyroid.thyroid',
      valueType: 'string',
      path: 'rightLobe.retrosternal',
      formattedFunction: retrosternalExtToI18Nkey,
    },
  ],
};

export const thyroidIsthmusObservationsConfig: ObservationConfig = {
  displayI18NKey: 'config_key.title.median.thyroid.isthmus',
  size: {
    path: 'isthmusThickness',
    configKey: 'left.thyroid.thyroid',
    valueType: 'float',
    unit: DistanceUnit.MM,
  },
  characteristics: [],
};

export const leftThyroidObservationsConfig: ObservationConfig = {
  displayI18NKey: 'result_table.heading.key.left',
  volume: {
    path: 'leftLobe.volume',
    configKey: 'left.thyroid.thyroid',
    valueType: 'float',
    unit: VolumeUnit.ML,
  },
  size: {
    path: 'leftLobe',
    configKey: 'left.thyroid.thyroid',
    valueType: 'dimensions',
    unit: DistanceUnit.MM,
  },
  characteristics: [
    {
      configKey: 'left.thyroid.thyroid',
      valueType: 'string',
      path: 'leftLobe.vascularity',
      formattedFunction: vascularityValueToI18Nkey,
    },
    {
      configKey: 'left.thyroid.thyroid',
      valueType: 'string',
      path: 'leftLobe.echotexture',
      formattedFunction: echotextureValueToI18Nkey,
    },
    {
      configKey: 'left.thyroid.thyroid',
      valueType: 'string',
      path: 'leftLobe.retrosternal',
      formattedFunction: retrosternalExtToI18Nkey,
    },
  ],
};

export interface InlineFeatureConfig extends ObservationFeatureConfig {
  displayI18NKey: string;
}

export const thyroidInlineFeaturesConfig: InlineFeatureConfig[] = [
  {
    displayI18NKey: 'study.result_table.tracheal_deviation',
    configKey: 'generic.thyroid.tracheal_deviation',
    valueType: 'string',
    formattedFunction: yesNoValueToI18Nkey,
    path: 'trachealDeviation',
  },
  {
    displayI18NKey: 'study.result_table.lymph_nodes_normality',
    configKey: 'generic.thyroid.lymph_nodes',
    path: 'lymphNodes',
    valueType: 'string',
    formattedFunction: (
      value: NormalAbnormalValue,
      generalCharacteristics?: ThyroidGeneralCharacteristics
    ) => {
      const isNormal = value === NormalAbnormalValue.Normal;
      const comment = generalCharacteristics?.abnormalLymphNodesText;
      if (comment) {
        const i18nKey = isNormal
          ? 'study.summary_tab.thyroid.lymph_nodes_normality.value.normal.with.comment'
          : 'study.summary_tab.thyroid.lymph_nodes_normality.value.abnormal.with.comment';
        return {
          i18nKey,
          data: comment,
        };
      }

      return isNormal
        ? 'result_table.shared.options.normal'
        : 'result_table.shared.options.abnormal';
    },
  },
];

export const ThyroidObservationsConfig = {
  size: { unit: DistanceUnit.MM },
  volume: { unit: VolumeUnit.ML },
  isthmusThickness: { unit: DistanceUnit.MM },
};

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import {
  setUpMFA,
  verifyMFA,
  disableMFA,
  update,
  changePassword,
} from '@webViewInterfaces/Settings/User';
import { toSearch, toPACS, toProfile, toAppSettings } from '@interfaces/App';
import { toAuth, toI18n, toApp, toLatestMessage } from '@interfaces/Root';
import * as ViewModelLayout from '@webViewModels/Pages/Settings/Layout';
import { Message } from '@interfaces/Message';
import { Profile } from '@interfaces/Profile';
import {
  SecurityConfiguration,
  toSecurityConfiguration,
} from '@interfaces/Authentication';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';

export type User = UserProps & UserDispatchers & ViewModelLayout.Layout;

export interface UserProps {
  profile: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  availableLocales: string[];
  currentLocale: string;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  security: SecurityConfiguration;
  latestMessage: Message | undefined;
}

export interface UserDispatchers {
  dispatchChangePassword: (oldPassword: string, newPassword: string) => void;
  dispatchUpdateProfile: (profile: Profile) => Dispatch<Actions>;
  dispatchSetUpMFA: () => Dispatch<Actions>;
  dispatchVerifyMFA: (otp: string) => Dispatch<Actions>;
  dispatchDisableMFA: (otp: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  ...ViewModelLayout.dispatchers(dispatch),
  dispatchChangePassword: (oldPassword: string, newPassword: string) =>
    dispatch(changePassword(oldPassword, newPassword)),
  dispatchUpdateProfile: (profile: Profile) => dispatch(update(profile)),
  dispatchSetUpMFA: () => dispatch(setUpMFA()),
  dispatchVerifyMFA: (otp: string) => dispatch(verifyMFA(otp)),
  dispatchDisableMFA: (otp: string) => dispatch(disableMFA(otp)),
});

export const fromState = (state: State): UserProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }
  const auth = toAuth(state);
  const i18n = toI18n(state);
  const pacs = toPACS(app);
  const search = toSearch(app);
  const profile = toProfile(app);

  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const latestMessage = toLatestMessage(state);
  const searchQuery = search.query;
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;
  const security = toSecurityConfiguration(auth) || {
    hasMFAEnabled: false,
  };
  return {
    profile,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    availableLocales: i18n.available,
    currentLocale: i18n.locale,
    security,
    searchQuery,
    pacsStudyLineItems,
    pacsSystemStatus,
    latestMessage,
  };
};

import { CanvasObjectData } from '@entities/CanvasObject';
import { post } from '@api/schema/client';
import {
  decodeCanvasObject,
  encodeCanvasObjectData,
} from './decoders/CanvasObject';

export const CanvasObjectAPI = {
  deleteCanvasObject: async (canvasObjectId: string) => {
    const data = await post('/delete-canvas-object', {
      body: {
        canvas_object_id: canvasObjectId,
      },
    });

    return {
      canvasObject: data.canvas_object,
    };
  },

  updateCanvasObject: async (
    canvasObjectId: string,
    canvasObjectData: CanvasObjectData
  ) => {
    const data = await post('/update-canvas-object', {
      body: {
        canvas_object_id: canvasObjectId,
        data: encodeCanvasObjectData(canvasObjectData),
      },
    });

    return {
      canvasObject: decodeCanvasObject(data.canvas_object),
    };
  },

  createCanvasObject: async (
    sessionId: string,
    canvasObject: CanvasObjectData
  ) => {
    const data = await post('/create-canvas-object', {
      body: {
        session_id: sessionId,
        data: encodeCanvasObjectData(canvasObject),
      },
    });

    return {
      canvasObject: decodeCanvasObject(data.canvas_object),
    };
  },
};

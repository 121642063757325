import { I18n, initial } from '@entities/I18n';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { effect, batch } from '@library/Effect';
import { Translations } from '@interfaces/I18n';
import { I18n as ContractI18n } from '@contracts/I18n';
import { createUseCase } from '@helpers/createUseCase';

export const i18nUseCase = {
  /**
   * Init translations
   */
  Init: createUseCase('I18N_USE_CASE_INIT').noPayload(),

  /**
   * Load translations
   */
  LoadTranslations: createUseCase(
    'I18N_USE_CASE_LOAD_TRANSLATIONS'
  ).withPayload<{
    locale?: string;
  }>(),

  /**
   * Receive translations
   */
  ReceiveTranslations: createUseCase(
    'I18N_USE_CASE_RECEIVE_TRANSLATIONS'
  ).withPayload<{
    locale: string;
    translations: Translations;
  }>(),
  /**
   * Receive available locales
   */
  ReceiveAvailableLocales: createUseCase(
    'I18N_RECEIVE_AVAILABLE_LOCALES'
  ).withPayload<{
    available: string[];
  }>(),
  /**
   * No Operation
   */

  NoOp: createUseCase('I18N_NO_OP').noPayload(),
};

/**
 *  All UseCases
 */
export type I18nUseCases = ReturnType<
  | typeof i18nUseCase.Init
  | typeof i18nUseCase.LoadTranslations
  | typeof i18nUseCase.ReceiveTranslations
  | typeof i18nUseCase.ReceiveAvailableLocales
  | typeof i18nUseCase.NoOp
>;

/*
 * Reducer
 *
 * The I18nReducer takes I18nUseCases and the current I18n (state)
 * and returns a new state and possible side effects.
 */
export class I18nReducer extends EffectReducer<I18n> {
  private apiI18n: ContractI18n;

  constructor(apiI18n: ContractI18n) {
    super();
    this.apiI18n = apiI18n;
  }

  Perform(
    i18n: I18n = initial(),
    { type, payload: useCase }: I18nUseCases
  ): ReducerResult<I18n> {
    switch (type) {
      case i18nUseCase.Init.type:
        return this.Result(
          i18n,
          batch([
            effectLoadAvailableLocales(this.apiI18n),
            effectLoadTranslations(this.apiI18n, this.apiI18n.GetDefault()),
          ])
        );

      case i18nUseCase.LoadTranslations.type:
        return this.Result(
          i18n,
          effectLoadTranslations(
            this.apiI18n,
            useCase.locale || this.apiI18n.GetDefault()
          )
        );

      case i18nUseCase.ReceiveTranslations.type:
        return this.Result({
          ...i18n,
          locale: useCase.locale,
          translations: useCase.translations,
        });

      case i18nUseCase.ReceiveAvailableLocales.type:
        return this.Result({
          ...i18n,
          available: useCase.available,
        });

      case i18nUseCase.NoOp.type:
        return this.Result({ ...i18n });
    }
  }
}

/**
 * Load available translation locales
 */
const effectLoadAvailableLocales = (apiI18n: ContractI18n): Effect =>
  effect(async () => {
    const available = await apiI18n.GetAvailable();
    return i18nUseCase.ReceiveAvailableLocales({ available });
  }, 'effect-i18n-load-default-translations').then(result =>
    result.recoverError(() => i18nUseCase.NoOp())
  );

/**
 * Load translations
 */
const effectLoadTranslations = (
  apiI18n: ContractI18n,
  locale: string
): Effect =>
  effect(async () => {
    const translations = await apiI18n.Load(locale);
    if (translations) {
      apiI18n.SetDefault(locale);
    }
    return i18nUseCase.ReceiveTranslations({ locale, translations });
  }, 'effect-i18n-load-translations').then(result =>
    result.recoverError(() => i18nUseCase.NoOp())
  );

import { Extras } from '@sentry/types';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  sentryDSN,
  sentryEnvironment,
  sentryEnabled,
  sentryTraceSampleRate,
  isDevelopment,
} from '@helpers/env';
import store from '@webInterfaces/Store';
import { notificationUseCase } from '@useCases/Notifications';
import { State, Actions } from './StoreTypes';

if (sentryEnabled) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: sentryEnvironment,
    tracesSampleRate: sentryTraceSampleRate,
    ignoreErrors: ['No Current User', 'Error: No Current User'],
  });
}

export const logError = (e: Error): void => {
  if (isDevelopment) {
    console.error(e);
  }
  try {
    Sentry.captureException(e);
  } catch (_) {
    Sentry.captureException(String(e));
  }
};

/**
 *
 * @param msg The warning message
 * @param extra An optional object of any additional information to send to sentry
 */
export const logWarning = (msg: string, extra?: Extras): void => {
  if (isDevelopment) {
    console.warn(msg);
  }
  try {
    Sentry.captureMessage(msg, extra ? { extra } : undefined);
  } catch (_) {
    Sentry.captureException(String(msg));
  }
};

export const onError = (e: Error) => {
  let state: null | State = null;
  try {
    state = {
      ...store.getState(),
      i18n: { locale: '', translations: new Map(), available: [] },
    };
  } catch (e) {}

  const profile = state && state.app.map(a => a.profile).lift();
  if (profile) {
    Sentry.setUser({
      id: profile.id,
      email: profile.email,
      username: profile.email,
    });
  }

  Sentry.captureException(e);
};

export const addCriticalErrorNotification = (): Actions =>
  notificationUseCase.Add({
    notification: {
      intent: 'error',
      titleI18nKey: 'generic.error.critical.title',
      bodyI18nKey: 'generic.error.critical.body',
      cta: {
        labelI18nKey: 'generic.error.critical.cta_label',
        onClick: () => {
          window.location.reload();
        },
      },
    },
    deduplicate: true,
  });

export const addOfflineErrorNotification = (): Actions =>
  notificationUseCase.Add({
    notification: {
      intent: 'error',
      titleI18nKey: 'generic.error.offline.title',
      bodyI18nKey: 'generic.error.offline.body',
      cta: {
        labelI18nKey: 'generic.error.offline.cta_label',
        onClick: () => {
          window.location.reload();
        },
      },
    },
    deduplicate: true,
  });

import { t } from '@webInterfaces/I18n';
import { ThyroidFinding } from '@entities/Finding';
import { BodySide, ThyroidPole } from '@entities/Characteristics';

export const generateThyroidLocation = (finding: ThyroidFinding): string => {
  return getFormattedLocation(
    finding.characteristics.side ?? undefined,
    finding.characteristics.pole ?? undefined
  );
};

export const getFormattedLocation = (
  bodySide: BodySide | undefined,
  findingPole: ThyroidPole | undefined
) => {
  const formattedBodySide = t(
    `config_key.result_table.thyroid_finding.options.body_side_${bodySide}`,
    ''
  );

  if (bodySide === 'median') {
    return formattedBodySide;
  }

  const formattedFindingPole = findingPole
    ? t(`config_key.result_table.thyroid_finding.options.${findingPole}`, '')
    : '';

  return [formattedBodySide, formattedFindingPole].filter(Boolean).join('\n');
};

import React, { SyntheticEvent, useEffect } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { WithRatioProps } from '@webMolecules/Image/Image';
import { cnames } from '@helpers/cnames';
import { VideoControls } from './VideoControls/VideoControls';
import styles from './video.scss';

export interface VideoProps {
  circle?: boolean;
  fluid?: boolean;
  src?: string;
  width?: number;
  height?: number;
  'data-testid'?: string;
  poster?: string;
  crossOrigin?: '' | 'anonymous' | 'use-credentials';
  muted?: boolean;
  roundedCorners?: boolean;
  size?: WithRatioProps['size'];
  frameRate?: number;
}

export const Video: React.FC<VideoProps> = ({
  roundedCorners = true,
  fluid,
  circle,
  width,
  height,
  src,
  size,
  poster,
  crossOrigin,
  muted = false,
  frameRate = 30,
  ...props
}) => {
  const videoContainerClasses = cnames(
    styles.videoContainer,
    size && styles[size]
  );
  const videoClassNames = cnames(styles.video, {
    [styles.rounded]: roundedCorners,
    [styles.fluid]: fluid,
    [styles.circle]: circle,
  });

  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = React.useState(false);
  const [videoFrame, setVideoFrame] = React.useState(0);
  const [currentFrame, setCurrentFrame] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const videoHandler = (control: string) => {
    if (videoRef.current) {
      if (control === 'play') {
        videoRef.current.play();
        setPlaying(true);
      } else if (control === 'pause') {
        videoRef.current.pause();
        setPlaying(false);
      }
    }
  };

  const onVideoLoadedMetadata = (event: SyntheticEvent<HTMLVideoElement>) => {
    const target = event.currentTarget;
    setVideoFrame(Math.round(target.duration * frameRate));
    setLoading(false);
  };

  const syncVideoToFrame = () => {
    if (videoRef.current) {
      setCurrentFrame(Math.round(videoRef.current.currentTime * frameRate));
    }
  };

  const onSliderUpdate = (value: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = value / frameRate;
      setCurrentFrame(value);
    }
  };

  useEffect(() => {
    if (videoRef.current && playing) {
      const frameDuration = (videoRef.current.duration * 1000) / frameRate;
      const syncInterval = setInterval(syncVideoToFrame, frameDuration);
      return () => {
        syncVideoToFrame();
        clearInterval(syncInterval);
      };
    }
  }, [playing]);

  return (
    <Box ref={containerRef} className={videoContainerClasses}>
      <Box
        className={styles.videoWrapper}
        onClick={() => videoHandler(playing ? 'pause' : 'play')}
        display="flex"
      >
        <video
          ref={videoRef}
          crossOrigin={crossOrigin}
          data-testid={props['data-testid']}
          preload="none"
          poster={poster}
          className={videoClassNames}
          src={src}
          width={width}
          height={height}
          muted={muted}
          onEnded={() => setPlaying(false)}
          onLoadedMetadata={onVideoLoadedMetadata}
        >
          <p>Your browser does not support HTML5 video.</p>
        </video>
      </Box>

      <VideoControls
        playing={playing}
        videoFrame={videoFrame}
        currentFrame={currentFrame}
        roundedCorners={roundedCorners}
        loading={loading}
        loadingText={'Loading cine loop...'}
        videoHandler={videoHandler}
        onSliderUpdate={onSliderUpdate}
        containerRef={containerRef}
      />
    </Box>
  );
};

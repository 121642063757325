import { I18n as ContractI18n } from '@contracts/I18n';
import { Translations } from '@interfaces/I18n';
import { browserStorage } from '@web/BrowserStorage';

const DEFAULT_LOCALE = 'en-gb';
const AVAILABLE_LOCALES = ['en-gb', 'en-au', 'en-us', 'en-ca', 'nl-nl'];
export class I18n implements ContractI18n {
  GetDefault(): string {
    try {
      const sessionLocale = new URLSearchParams(
        window.location.search.substring(1)
      ).get('locale');
      if (sessionLocale) {
        return sessionLocale;
      }

      const userLocale = browserStorage.getItem('i18n.locale');
      if (userLocale) {
        return userLocale;
      }

      const browserLocale =
        navigator.languages[0].toLowerCase() ||
        navigator.language.toLowerCase();

      if (AVAILABLE_LOCALES.includes(browserLocale)) {
        return browserLocale;
      }
    } catch (e) {}

    return DEFAULT_LOCALE;
  }

  SetDefault(locale: string) {
    browserStorage.setItem('i18n.locale', locale);
  }

  async GetAvailable(): Promise<string[]> {
    const FetchLocaleIndex = async () =>
      await fetch('/i18n/index.json').then(response => response.json());

    return (await FetchLocaleIndex()) || [];
  }

  async Load(
    locale: string,
    mergeWithTranslations: Translations = new Map()
  ): Promise<Translations> {
    const FetchLocaleFile = async (path: string) =>
      await fetch(path)
        .then(response => response.json())
        .then(data => {
          const keys = Object.keys(data);
          keys.forEach(key => {
            if (!mergeWithTranslations.get(key) || key == '$extends') {
              mergeWithTranslations.set(key, data[key]);
            }
          });
          return mergeWithTranslations;
        });

    const translations = await FetchLocaleFile(`/i18n/${locale}.json`);
    const $extends = translations.get('$extends');
    if ($extends && $extends !== locale) {
      return await this.Load($extends, translations);
    }

    return translations;
  }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { selectWorkflow } from '@selectors/BaseSelectors';
import { Workflow } from '@entities/Workflow';
import { Box } from '@webMolecules/Box/Box';
import ResultTableContext, { ResultTableStyle } from '../ResultTableContext';
import CreateFinding from './CreateFinding';
import FindingSummary from './FindingSummary';

interface SmallPartsStudyFindingOverviewProps {}

const SmallPartsStudyFindingOverview: React.FC<
  SmallPartsStudyFindingOverviewProps
> = () => {
  const { theme } = React.useContext(ResultTableContext) || {
    theme: ResultTableStyle.editable,
  };

  const workflow = useSelector(selectWorkflow);

  return (
    <>
      <Box>
        <FindingSummary />
        {theme === ResultTableStyle.editable &&
          workflow !== Workflow.Radiologist && <CreateFinding />}
      </Box>
    </>
  );
};

export default SmallPartsStudyFindingOverview;

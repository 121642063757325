import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export const timeFromNow = (
  date: string | Date | undefined | null
): string | null => {
  if (!date) {
    return null;
  }

  dayjs.extend(relativeTime);

  return dayjs(date).fromNow();
};

export const timeFromX = (
  oldDate: string | Date | undefined | null,
  newDate?: string | Date | undefined | null
): string | null => {
  dayjs.extend(relativeTime);
  if (!oldDate) {
    return null;
  }
  if (!newDate) {
    return dayjs(oldDate).fromNow(true);
  }
  return dayjs(oldDate).from(newDate, true);
};

export const formatDate = (
  date: string | Date | undefined | null,
  format = 'YYYY-MM-DD'
): string => {
  if (!date) {
    return '';
  }

  const formattedDate = dayjs(date).format(format);
  // Return blank if Invalid
  return formattedDate === 'Invalid Date'
    ? ''
    : formattedDate.toLocaleUpperCase();
};

export const formatTime = (
  date: string | number | Date | undefined,
  format = 'HH:mm:ss.SSS'
): string | null => dayjs(date).format(format);

export const formatAndRoundDecimalTime = (
  timeInHour: number,
  roundingMins: number,
  format = 'hh:mm'
) => {
  const date = new Date(0, 0);
  date.setMinutes(+timeInHour * 60);

  return dayjs(roundingDateTime(date, roundingMins)).format(format);
};

export const formatAndRoundHourAndMinute = (
  hour: number,
  minute: number,
  roundingMins: number,
  format = 'hh:mm'
) => {
  const date = new Date(0, 0);
  date.setHours(hour);
  date.setMinutes(minute);

  return dayjs(roundingDateTime(date, roundingMins)).format(format);
};

export const formatDecimalTime = (decimalTime: number, format = 'hh:mm') => {
  const date = new Date(0, 0);
  date.setMinutes(+decimalTime * 60);

  return dayjs(date).format(format);
};

const roundingDateTime = (date: Date, roundingMins: number) => {
  const ms = 1000 * 60 * roundingMins;
  const newDate = new Date(Math.round(date.getTime() / ms) * ms);

  return newDate;
};

export const formatTodayDateAsFileName = (): string => {
  return formatDate(new Date(), 'DD-MMM-YYYY');
};

export const getDateObjectFromValues = (
  day: number,
  month: number,
  year: number
) => {
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(day);

  return date.toString() === 'Invalid Date' ? undefined : date;
};

import { AdminUsers, initial } from '@entities/Admin/Users';
import { Location as ContractLocation } from '@contracts/Location';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { Storage as ContractStorage } from '@contracts/Storage';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { effect } from '@library/Effect';
import { fromFalsy, just, nothing } from '@monads/Maybe';
import { notificationUseCase } from '@useCases/Notifications';
import { Message } from '@entities/Message';
import { ApiStatus } from '@entities/Status';
import * as RouteHelper from '@helpers/routes';
import { pair } from '@monads/Tuple';
import { Profile } from '@entities/Profile';
import { createUseCase } from '@helpers/createUseCase';
export const adminUsersUseCase = {
  /**
   * Load Users
   */
  Load: createUseCase('ADMIN_USERS_LOAD').withPayload<{
    userId?: string;
  }>(),

  /**
   * On Unload
   */
  Unload: createUseCase('ADMIN_USERS_UNLOAD').withPayload<{
    targetOnly?: boolean;
  }>(),

  /**
   * Set Users
   */
  SetUsers: createUseCase('ADMIN_USERS_SET_USERS').withPayload<{
    userLineItems: Profile[];
  }>(),

  /**
   * Allow admin can change create users, prepare an empty user
   */
  CreateUserEmpty: createUseCase('ADMIN_USERS_CREATE_USER_EMPTY').noPayload(),

  /**
   * Allow admin can change create users
   */
  CreateUser: createUseCase('ADMIN_USERS_CREATE_USER').withPayload<{
    userLineItem: Profile;
  }>(),

  /**
   * Allow admin can update user
   */
  CreateUserSuccess: createUseCase(
    'ADMIN_USERS_CREATE_USER_SUCCESS'
  ).noPayload(),

  /**
   * Allow admin can update user
   */ CreateUserError: createUseCase(
    'ADMIN_USERS_CREATE_USER_ERROR'
  ).noPayload(),

  /**
   * Allow admin can update user
   */
  UpdateUser: createUseCase('ADMIN_USERS_UPDATE_USER').withPayload<{
    userLineItem: Profile;
  }>(),

  /**
   * Allow admin can update user
   */
  UpdateUserSuccess: createUseCase(
    'ADMIN_USERS_UPDATE_USER_SUCCESS'
  ).noPayload(),

  /**
   * Allow admin can update user
   */ UpdateUserError: createUseCase(
    'ADMIN_USERS_UPDATE_USER_ERROR'
  ).noPayload(),

  /**
   * Allow admin can change user permission
   */
  DeleteUser: createUseCase('ADMIN_USERS_DELETE_USER').withPayload<{
    email: string;
  }>(),

  /**
   * Allow admin can update user
   */
  DeleteUserSuccess: createUseCase(
    'ADMIN_USERS_DELETE_USER_SUCCESS'
  ).noPayload(),

  /**
   * Allow admin can update user
   */ DeleteUserError: createUseCase(
    'ADMIN_USERS_DELETE_USER_ERROR'
  ).noPayload(),

  /**
   * The application can set a message
   */
  SetMessage: createUseCase('ADMIN_USERS_SET_MESSAGE').withPayload<{
    message: Message;
    clearOthers: boolean;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('ADMIN_USERS_NO_OP').noPayload(),
};

/**
 *  All UseCases
 * */
export type AdminUsersUseCases = ReturnType<
  | typeof adminUsersUseCase.Load
  | typeof adminUsersUseCase.Unload
  | typeof adminUsersUseCase.SetUsers
  | typeof adminUsersUseCase.CreateUserEmpty
  | typeof adminUsersUseCase.CreateUser
  | typeof adminUsersUseCase.CreateUserSuccess
  | typeof adminUsersUseCase.CreateUserError
  | typeof adminUsersUseCase.UpdateUser
  | typeof adminUsersUseCase.UpdateUserSuccess
  | typeof adminUsersUseCase.UpdateUserError
  | typeof adminUsersUseCase.DeleteUser
  | typeof adminUsersUseCase.DeleteUserSuccess
  | typeof adminUsersUseCase.DeleteUserError
  | typeof adminUsersUseCase.NoOp
>;

export class AdminUsersReducer extends EffectReducer<AdminUsers> {
  private apiStorage: ContractStorage;
  private apiProfiles: ContractProfiles;
  private apiLocation: ContractLocation;

  constructor(
    apiStorage: ContractStorage,
    apiLocation: ContractLocation,
    profiles: ContractProfiles
  ) {
    super();
    this.apiProfiles = profiles;
    this.apiLocation = apiLocation;
    this.apiStorage = apiStorage;
  }

  Perform(
    adminUsers: AdminUsers = initial(),
    { type, payload: useCase }: AdminUsersUseCases
  ): ReducerResult<AdminUsers> {
    switch (type) {
      case adminUsersUseCase.Load.type: {
        if (useCase.userId) {
          const foundUserLineItem = fromFalsy(
            adminUsers.userLineItems
              .second()
              .find(user => user.id === useCase.userId)
          );
          const targetUserLineItem = just(
            pair(useCase.userId, foundUserLineItem)
          );

          const effect = foundUserLineItem.isNothing()
            ? effectListUserAdmin(this.apiProfiles)
            : undefined;

          return this.Result(
            {
              ...adminUsers,
              targetUserLineItem,
              userLineItems: adminUsers.userLineItems.mapFirst(a =>
                effect ? ApiStatus.Busy : a
              ),
            },
            effect
          );
        }
        return this.Result(
          {
            ...adminUsers,
            targetUserLineItem: nothing(),
            userLineItems: pair(
              ApiStatus.Busy,
              adminUsers.userLineItems.second()
            ),
          },
          effectListUserAdmin(this.apiProfiles)
        );
      }

      case adminUsersUseCase.Unload.type: {
        if (useCase.targetOnly) {
          return this.Result(
            {
              ...adminUsers,
              targetUserLineItem: nothing(),
              newTargetUserLineItem: nothing(),
            },
            effectUpdateLocation(this.apiLocation)
          );
        }
        this.CancelLastEffect();
        return this.Result(initial());
      }

      case adminUsersUseCase.SetUsers.type:
        return this.Result({
          ...adminUsers,
          userLineItems: pair(ApiStatus.Idle, useCase.userLineItems),
          targetUserLineItem: adminUsers.targetUserLineItem.map(a =>
            pair(
              a.first(),
              fromFalsy(
                useCase.userLineItems.find(user => user.id === a.first())
              )
            )
          ),
        });

      case adminUsersUseCase.CreateUserEmpty.type:
        return this.Result({
          ...adminUsers,
          newTargetUserLineItem: just({
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            operatorMapping: '',
            operatorMappingProperties: [{ column: 'operator_code', value: '' }],
            userProfileId: '',
            isAdmin: false,
            institutionId: 'See-Mode',
            logo: '',
            kmsKeyArn: '',
            nativeRegion: '',
            swatchColour: '',
            configuration: {},
            preferences: {},
            isActive: false,
          }),
        });

      case adminUsersUseCase.CreateUser.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectCreateUser(this.apiProfiles, useCase.userLineItem)
        );

      case adminUsersUseCase.CreateUserSuccess.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectListUserAdmin(this.apiProfiles),
          [
            notificationUseCase.Add({
              notification: {
                intent: 'success',
                titleI18nKey: 'admin.users.message.user.created',
              },
            }),
          ]
        );

      case adminUsersUseCase.CreateUserError.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectListUserAdmin(this.apiProfiles),
          [
            notificationUseCase.Add({
              notification: {
                intent: 'error',
                titleI18nKey: 'admin.users.message.user.created_error',
              },
            }),
          ]
        );

      case adminUsersUseCase.UpdateUser.type:
        return this.Result(
          {
            ...adminUsers,
            targetUserLineItem: nothing(),
            userLineItems: adminUsers.userLineItems
              .mapFirst(() => ApiStatus.Busy)
              .mapSecond(arr =>
                arr.map(i =>
                  i.id === useCase.userLineItem.id
                    ? { ...useCase.userLineItem }
                    : i
                )
              ),
          },
          effectUpdateUser(this.apiProfiles, useCase.userLineItem)
        );

      case adminUsersUseCase.UpdateUserSuccess.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectListUserAdmin(this.apiProfiles),
          [
            notificationUseCase.Add({
              notification: {
                intent: 'success',
                titleI18nKey: 'admin.users.message.user.updated',
              },
            }),
          ]
        );

      case adminUsersUseCase.UpdateUserError.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectListUserAdmin(this.apiProfiles),
          [
            notificationUseCase.Add({
              notification: {
                intent: 'error',
                titleI18nKey: 'admin.users.message.user.updated_error',
              },
            }),
          ]
        );

      case adminUsersUseCase.DeleteUser.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectDeleteUser(this.apiProfiles, useCase.email)
        );

      case adminUsersUseCase.DeleteUserSuccess.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectListUserAdmin(this.apiProfiles),
          [
            notificationUseCase.Add({
              notification: {
                intent: 'success',
                titleI18nKey: 'admin.users.message.user.deleted',
              },
            }),
          ]
        );

      case adminUsersUseCase.DeleteUserError.type:
        return this.Result(
          {
            ...adminUsers,
            userLineItems: adminUsers.userLineItems.mapFirst(
              () => ApiStatus.Busy
            ),
          },
          effectListUserAdmin(this.apiProfiles),
          [
            notificationUseCase.Add({
              notification: {
                intent: 'error',
                titleI18nKey: 'admin.users.message.user.deleted_error',
              },
            }),
          ]
        );

      case adminUsersUseCase.NoOp.type:
        return this.Result({ ...adminUsers });
    }
  }
}

/**
 * Calling effects to list the users, if having admin privileges
 */
const effectListUserAdmin = (apiProfiles: ContractProfiles): Effect =>
  effect(async () => {
    const userLineItems = await apiProfiles.List();
    return adminUsersUseCase.SetUsers({ userLineItems });
  }, 'effect-admin-list-users');

/**
 * Calling effects to delete the user, if having admin privileges
 */
const effectDeleteUser = (
  apiProfiles: ContractProfiles,
  email: string
): Effect =>
  effect(async () => {
    try {
      await apiProfiles.Destroy(email);
      return adminUsersUseCase.DeleteUserSuccess();
    } catch {
      return adminUsersUseCase.DeleteUserError();
    }
  }, 'effect-admin-delete-user');

/**
 * Calling effects to change user permission,if having admin privileges
 */
const effectCreateUser = (
  apiProfiles: ContractProfiles,
  userLineItem: Profile
): Effect =>
  effect(async () => {
    try {
      await apiProfiles.Create(userLineItem);
      return adminUsersUseCase.CreateUserSuccess();
    } catch {
      return adminUsersUseCase.CreateUserError();
    }
  }, 'effect-admin-create-user');

/**
 * Calling effects to change user permission,if having admin privileges
 */
const effectUpdateUser = (
  apiProfiles: ContractProfiles,
  userLineItem: Profile
): Effect =>
  effect(async () => {
    try {
      await apiProfiles.Update(userLineItem);
      return adminUsersUseCase.UpdateUserSuccess();
    } catch {
      return adminUsersUseCase.UpdateUserError();
    }
  }, 'effect-admin-update-user');

const effectUpdateLocation = (
  apiLocation: ContractLocation,
  userId?: string
): Effect =>
  effect(async () => {
    const pathname = RouteHelper.adminUsers(userId);
    if (window.location.pathname !== pathname) {
      apiLocation.Change(pathname, true);
    }
    return adminUsersUseCase.NoOp();
  }, 'effect-admin-users-update-location');

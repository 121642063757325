import React, { ReactNode } from 'react';
import ReachTooltip, { Position as CalculatePosition } from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import styles from './tooltip.scss';

export enum TooltipPosition {
  Top = 'tooltip_position--top',
  Bottom = 'tooltip_position--bottom',
  Left = 'tooltip_position--left',
  Right = 'tooltip_position--right',
}

export interface TooltipProperty {
  label: string;
  children: ReactNode;
  position?: TooltipPosition;
  disabled?: boolean;
}

export const Tooltip: React.FC<TooltipProperty> = ({
  children,
  label,
  disabled = false,
  position = TooltipPosition.Bottom,
}) => {
  const calculatePosition: CalculatePosition = (triggerRect, tooltipRect) => {
    let left = window.scrollX;
    let top = window.scrollY;

    if (triggerRect && tooltipRect) {
      const spacing = 8;
      const centerX = window.scrollX + triggerRect.left + triggerRect.width / 2;
      const centerY = window.scrollY + triggerRect.top + triggerRect.height / 2;

      if (position === TooltipPosition.Top) {
        left = centerX - tooltipRect.width / 2;
        top = centerY - triggerRect.height / 2 - spacing - tooltipRect.height;
      } else if (position === TooltipPosition.Right) {
        left = centerX + triggerRect.width / 2 + spacing;
        top = centerY - tooltipRect.height / 2;
      } else if (position === TooltipPosition.Bottom) {
        left = centerX - tooltipRect.width / 2;
        top = centerY + triggerRect.height / 2 + spacing;
      } else if (position === TooltipPosition.Left) {
        left = centerX - triggerRect.width / 2 - spacing - tooltipRect.width;
        top = centerY - tooltipRect.height / 2;
      }
    }
    return { left, top };
  };

  if (disabled) return <>{children}</>;

  return (
    <ReachTooltip
      data-testid="tooltip-test-id"
      label={label}
      className={styles.tooltip}
      position={calculatePosition}
    >
      {/* Tooltip needs to wrap children in a component that forwards refs */}
      <span style={{ cursor: 'pointer' }}>{children}</span>
    </ReachTooltip>
  );
};

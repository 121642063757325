import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Container } from '@webMolecules/Container/Container';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import { Stack } from '@webMolecules/Stack/Stack';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Button } from '@webMolecules/Button/Button';
import { Label } from '@webMolecules/Label/Label';
import { t } from '@webInterfaces/I18n';
import { PASSWORD_REGEX } from '@webViews/constant';
import {
  FormHint,
  formHintIntentFromString,
} from '@webOrganisms/FormHint/FormHint';
import { Message } from '@interfaces/Message';

export interface ChangePasswordProps {
  loading?: boolean;
  message: Message | undefined;
  onUpdate: (oldPassword: string, newPassword: string) => void;
}

export interface ChangePasswordFormProps {
  oldPassword: string;
  newPassword: string;
  showOldPassword: boolean;
  showNewPassword: boolean;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  loading = false,
  onUpdate,
  message,
}) => {
  const [formSaved, setFormSaved] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState<boolean>(false);

  const ProfileSettingsSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .matches(PASSWORD_REGEX, t('generic.validation.invalid.password')),
  });

  const formProps = {
    oldPassword: '',
    newPassword: '',
    showNewPassword: false,
    showOldPassword: false,
  } as ChangePasswordFormProps;

  useEffect(() => {
    if (formLoading && message) {
      setFormLoading(false);
    }
  }, [message]);

  const handleFormSubmit = (form: ChangePasswordFormProps) => {
    setFormSaved(true);
    setFormLoading(true);
    onUpdate(form.oldPassword, form.newPassword);
  };

  const handleFormChange = () => {
    formSaved ? setFormSaved(false) : null;
  };

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom="m">
        <Text type="display3" display="block" marginRight="l">
          {t('change.password.form.title')}
        </Text>
        {loading ? <LoadingIcon /> : null}
      </Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={formProps}
        validationSchema={ProfileSettingsSchema}
      >
        {formProps => (
          <Form onChange={handleFormChange}>
            <Container centered={false} size="xxxs">
              <Stack spacing="l" direction="vertical">
                <Box>
                  <Label htmlFor="oldPassword" marginBottom="xs">
                    {t('change.password.form.old_password')}
                  </Label>
                  <FormInput
                    data-testid="change-password-old-password"
                    error={formProps.errors.oldPassword}
                    type={
                      formProps.values.showOldPassword
                        ? FormInputTypes.Text
                        : FormInputTypes.Password
                    }
                    id="oldPassword"
                    name="oldPassword"
                    touched={formProps.touched.oldPassword}
                    maxLength="64"
                    fluid
                  />
                  {formProps.errors.oldPassword && (
                    <ErrorMessage
                      render={msg => (
                        <FormFieldHint
                          intent="error"
                          marginTop="xs"
                          marginBottom="xs"
                        >
                          {msg}
                        </FormFieldHint>
                      )}
                      name="oldPassword"
                    />
                  )}
                  <Box marginTop="s">
                    <FormInput
                      data-testid="change-password-old-password-check"
                      name="showOldPassword"
                      type={FormInputTypes.Checkbox}
                    >
                      {t('generic.form.show')}
                    </FormInput>
                  </Box>
                </Box>
                <Box>
                  <Label htmlFor="newPassword" marginBottom="xs">
                    {t('change.password.form.new_password')}
                  </Label>
                  <FormInput
                    data-testid="change-password-new-password"
                    error={formProps.errors.newPassword}
                    type={
                      formProps.values.showNewPassword
                        ? FormInputTypes.Text
                        : FormInputTypes.Password
                    }
                    id="newPassword"
                    name="newPassword"
                    maxLength="64"
                    touched={formProps.touched.newPassword}
                    fluid
                  />
                  {formProps.errors.newPassword && (
                    <ErrorMessage
                      render={msg => (
                        <FormFieldHint
                          intent="error"
                          marginTop="xs"
                          marginBottom="xs"
                        >
                          {msg}
                        </FormFieldHint>
                      )}
                      name="newPassword"
                    />
                  )}
                  <Box marginTop="s">
                    <FormInput
                      data-testid="change-password-new-password-check"
                      name="showNewPassword"
                      type={FormInputTypes.Checkbox}
                    >
                      {t('generic.form.show')}
                    </FormInput>
                  </Box>
                </Box>
                <Box>
                  <Button
                    data-testid="change-password-submit"
                    loading={formLoading}
                    type="submit"
                    intent="primary"
                    strong
                    pill
                  >
                    {t('change.password.form.button.update')}
                  </Button>
                  {formSaved && (
                    <Box data-testid="change-password-intent">
                      {message && message.source === 'ChangePassword' && (
                        <Box marginTop="l">
                          <FormHint
                            intent={formHintIntentFromString(message.intent)}
                          >
                            {t(message.key)}
                          </FormHint>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Stack>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;

import Maybe from '@monads/Maybe';

export const unique = <T>(arr: Array<T>): Array<T> =>
  arr.filter((v, i, a) => a.indexOf(v) === i);

export const filterMap = <A, B>(
  arr: Array<A>,
  f: (a: A) => Maybe<B>
): Array<B> =>
  arr.map(a => f(a).lift()).filter(a => a !== undefined) as Array<B>;

export const concat = <T>(arr: Array<Array<T>>): Array<T> =>
  arr.reduce((acc, a) => [...acc, ...a], []);

/**
 * Extract a random value from a given array
 */
export const randomValue = <T>(arr: Array<T>): T | undefined =>
  arr[randomInt(0, arr.length)];

/**
 * Generate a random int between lower and the upper values
 */
export const randomInt = (lower = 0, upper: number): number =>
  Math.floor(Math.random() * (upper - lower)) + lower;

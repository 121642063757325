import React from 'react';
import * as BlockedDomainStory from '@webPages/Authenticate/BlockedDomain';
import { Layout } from '@webPages/Authenticate/Layout';

const BlockedDomain: React.FC = ({}) => {
  return (
    <Layout showContainer={false} title={''} showHelp={false}>
      <BlockedDomainStory.BlockedDomain />
    </Layout>
  );
};

export default BlockedDomain;

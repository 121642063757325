import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@webMolecules/Button/Button';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { FinaliseStatus } from '@entities/Status';
import { selectSession } from '@selectors/SessionSelectors';
import { selectLastWorklistPath } from '@selectors/BaseSelectors';
import { Icon } from '@webMolecules/Icon/Icon';
import { useDispatcher } from '@helpers/useDispatcher';
import { sessionUseCase } from '@useCases/Session';
import { PDFTriggerAction } from '@entities/Session';
import { MissingSizeDialog } from '../MissingSizeDialog';

export const FinaliseButton: React.FC = () => {
  const dispatchUpdateFinaliseStatus = useDispatcher(
    sessionUseCase.UpdateFinaliseStatus
  );
  const dispatchInitGeneratePDF = useDispatcher(sessionUseCase.InitGeneratePDF);

  const { finaliseStatus } = useSelector(selectSession);
  const lastWorklistPath = useSelector(selectLastWorklistPath);

  const [showMissingSizeDialog, setShowMissingSizeDialog] = useState(false);
  const [showFinaliseDialog, setShowFinaliseDialog] = useState(false);
  const [finaliseConfirmBtnClicked, setFinaliseConfirmBtnClicked] =
    React.useState(false);
  const [confirmFinalisedDialogContent, setConfirmFinalisedDialogContent] =
    React.useState({
      title: 'pages.study.worksheet.finalise.dialog.title',
      body1: 'pages.study.worksheet.finalise.confirm',
      confirm: 'pages.study.worksheet.finalise.confirm_button',
      cancel: 'pages.study.worksheet.finalise.cancel',
    });

  //const findingData = useSelector(selectFindingOverviewData);
  //const hasFindingMissingSize = checkIfAnySizeMissing(findingData);
  const hasFindingMissingSize = false;

  const handleFinalised = () => {
    if (hasFindingMissingSize) {
      setShowMissingSizeDialog(true);
    } else {
      setShowFinaliseDialog(true);
      setFinaliseConfirmBtnClicked(false);
    }
  };

  const onFinaliseConfirm = () => {
    if (!finaliseConfirmBtnClicked) {
      dispatchUpdateFinaliseStatus({
        finaliseStatus: FinaliseStatus.InProgress,
      });
      dispatchInitGeneratePDF({ pdfTriggerAction: PDFTriggerAction.Finalise });
      setFinaliseConfirmBtnClicked(true);
    }
  };

  const onCloseFinalisedDialog = () => {
    setShowFinaliseDialog(false);
    setFinaliseConfirmBtnClicked(false);
    setConfirmFinalisedDialogContent({
      title: 'pages.study.worksheet.finalise.dialog.title',
      body1: 'pages.study.worksheet.finalise.confirm',
      confirm: 'pages.study.worksheet.finalise.confirm_button',
      cancel: 'pages.study.worksheet.finalise.cancel',
    });
  };

  React.useEffect(() => {
    if (finaliseStatus == FinaliseStatus.Success) {
      setConfirmFinalisedDialogContent({
        title: 'pages.study.worksheet.finalise.dialog.title',
        body1: 'pages.study.worksheet.finalise.success',
        confirm: 'pages.study.worksheet.finalise.back_to_worklist',
        cancel: 'pages.study.worksheet.close',
      });
    } else if (finaliseStatus == FinaliseStatus.Error) {
      setConfirmFinalisedDialogContent({
        title: 'pages.study.worksheet.finalise.dialog.title',
        body1: 'pages.study.worksheet.finalise.error',
        confirm: 'pages.study.worksheet.finalise.confirm_button',
        cancel: 'pages.study.worksheet.finalise.cancel',
      });
    }
  }, [finaliseStatus]);

  return (
    <>
      <Button
        data-testid="test-button-preview"
        strong
        pill
        intent={hasFindingMissingSize ? 'deactivated' : 'primary'}
        subtle={hasFindingMissingSize}
        onClick={handleFinalised}
        iconBefore={<Icon name="check-circle" />}
        fluid
      >
        {t('pages.study.worksheet.finalise')}
      </Button>
      <MissingSizeDialog
        isOpen={showMissingSizeDialog}
        onCancel={() => setShowMissingSizeDialog(false)}
        onConfirm={() => setShowMissingSizeDialog(false)}
        triggerBtnText={t('pages.study.worksheet.finalise')}
      />
      <DialogConfirm
        data-testid="finalise-study-dialog"
        isOpen={showFinaliseDialog}
        intent="primary"
        labelOk={t(confirmFinalisedDialogContent.confirm)}
        labelCancel={t(confirmFinalisedDialogContent.cancel)}
        onCancel={onCloseFinalisedDialog}
        onConfirm={onFinaliseConfirm}
        confirmButtonLoading={finaliseStatus === FinaliseStatus.InProgress}
        conformButtonRouteTo={
          finaliseConfirmBtnClicked ? lastWorklistPath : undefined
        }
      >
        <Text type="display2" display="block" marginBottom="m">
          {t(confirmFinalisedDialogContent.title)}
        </Text>

        <Text>{t(confirmFinalisedDialogContent.body1)}</Text>
      </DialogConfirm>
    </>
  );
};

import { AuthenticateError } from '@contracts/Authenticate';
import * as Authentication from '@entities/Authentication';
import { isSAMLBypass } from '@helpers/routes';
import { browserStorage } from '@web/BrowserStorage';

export { SecurityConfiguration } from '@entities/Authentication';

export interface JWTPayload {
  auth_time: number;
  client_id: string;
  event_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
}

export const SAML_EMAIL_PREFIX = 'customersamlprovider_';

export const isBusy = (auth: Authentication.Authentication): boolean => {
  switch (auth.status.type) {
    case Authentication.AUTHENTICATION_STATUS_AUTHENTICATING:
    case Authentication.AUTHENTICATION_STATUS_AUTHENTICATING_FROM_SESSION:
    case Authentication.AUTHENTICATION_STATUS_MFA_AUTHENTICATING:
      return true;
    default:
      return false;
  }
};

export const authenticateErrorToI18nKey = (
  error: AuthenticateError
): string => {
  switch (error) {
    case AuthenticateError.AuthenticateErrorIncorrectEmail:
      return 'auth.error.incorrect_email';
    case AuthenticateError.AuthenticateErrorIncorrectEmailOrPassword:
      return 'auth.error.incorrect_email_or_password';
    case AuthenticateError.AuthenticateErrorLimitExceededError:
      return 'auth.error.attempt_limit_exceeded';
    case AuthenticateError.AuthenticateErrorPasswordResetRequired:
      return 'auth.error.password_reset_required';
    case AuthenticateError.AuthenticateErrorAccountCreateRequired:
      return 'auth.error.account_create_required';
    case AuthenticateError.AuthenticateErrorAccountAlreadyCreated:
      return 'auth.error.account_already_created';
    case AuthenticateError.AuthenticateErrorGeneric:
      return 'auth.error.generic';
  }
};

export const isResettingTheirPassword = (
  auth: Authentication.Authentication
): boolean =>
  auth.status.type ===
  Authentication.AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET;

export const toHasMFAEnabled = (
  auth: Authentication.Authentication
): boolean => {
  switch (auth.status.type) {
    case Authentication.AUTHENTICATION_STATUS_MFA_REQUIRED:
    case Authentication.AUTHENTICATION_STATUS_MFA_AUTHENTICATING:
    case Authentication.AUTHENTICATION_STATUS_AUTHENTICATED:
      return auth.status.security.hasMFAEnabled;
    default:
      return false;
  }
};

export const isDomainBlocked = (auth: Authentication.Authentication): boolean =>
  auth.status.type === Authentication.AUTHENTICATION_STATUS_BLOCKED;

export const isAuthenticatingFromSession = (
  auth: Authentication.Authentication
): boolean =>
  auth.status.type ===
  Authentication.AUTHENTICATION_STATUS_AUTHENTICATING_FROM_SESSION;

export const toEmail = (
  auth: Authentication.Authentication
): string | undefined => {
  switch (auth.status.type) {
    case Authentication.AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET:
    case Authentication.AUTHENTICATION_STATUS_AUTHENTICATED:
      return auth.status.email;
    default:
      return undefined;
  }
};

export const toSecurityConfiguration = (
  auth: Authentication.Authentication
): undefined | Authentication.SecurityConfiguration => {
  switch (auth.status.type) {
    case Authentication.AUTHENTICATION_STATUS_AUTHENTICATED:
      return auth.status.security;
    default:
      return undefined;
  }
};

export const institutionUsesFederatedSignInKey =
  'institutionUsesFederatedSignIn';
export const federatedIdentityProviderSessionKey = 'federatedIdentityProvider';
export const isSeeModeAdminDomainKey = 'isSeeModeAdminDomain';
export const isSAMLBypassKey = 'isSAMLBypass';

export const useAuthenticationMode = () => {
  const federatedIdentityDetailsString = browserStorage.getItem(
    federatedIdentityProviderSessionKey
  );
  const isAdminDomain =
    browserStorage.getItem(isSeeModeAdminDomainKey) === 'true';
  const isSAMLBypass = browserStorage.getItem(isSAMLBypassKey) === 'true';
  if (federatedIdentityDetailsString) {
    const federatedIdentityDetails = JSON.parse(federatedIdentityDetailsString);
    return {
      ...federatedIdentityDetails,
      isFederatedIdentity: true,
      isAdminDomain,
      isSAMLBypass,
    };
  }
  return {
    isFederatedIdentity: false,
    isAdminDomain,
    isSAMLBypass,
  };
};

export const setSessionFlags = (
  institutionUsersFederatedSignIn?: boolean,
  identityProvider?: string,
  institutionCopyText?: string
) => {
  //Determine if institution SHOULD use federated signin
  institutionUsersFederatedSignIn
    ? browserStorage.setItem(institutionUsesFederatedSignInKey, 'true')
    : browserStorage.removeItem(institutionUsesFederatedSignInKey);

  //Determines if user IS using federated signin
  const useFederation = !isSAMLBypass() && institutionUsersFederatedSignIn;
  useFederation && identityProvider
    ? browserStorage.setItem(
        federatedIdentityProviderSessionKey,
        JSON.stringify({ identityProvider, institutionCopyText })
      )
    : browserStorage.removeItem(federatedIdentityProviderSessionKey);
};

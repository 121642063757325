import * as React from 'react';
import { Button } from '@webMolecules/Button/Button';
import { Icon, IconProps } from '@webMolecules/Icon/Icon';
import { Popover } from '@webMolecules/Popover/Popover';

interface PopoverInfoButtonProps {
  type: 'popover';
  icon?: IconProps['name'];
  component: () => React.ReactElement;
}

interface ModalInfoButtonProps {
  type: 'modal';
  icon?: IconProps['name'];
  label?: string;
  component: (props: {
    isOpen: boolean;
    onClose: () => void;
  }) => React.ReactElement;
}

export type InfoButtonProps = PopoverInfoButtonProps | ModalInfoButtonProps;

export const InfoButton: React.FC<InfoButtonProps> = props => {
  return props.type === 'popover' ? (
    <PopoverInfoButton {...props} />
  ) : (
    <ModalInfoButton {...props} />
  );
};

export const PopoverInfoButton: React.FC<PopoverInfoButtonProps> = ({
  icon = 'help-circle',
  component,
}) => {
  return (
    <Popover
      trigger={
        <Button
          iconBefore={<Icon name={icon} color="var(--ds-text-color-muted)" />}
          size="small"
          circle
        />
      }
      position="right-start"
      size="medium"
    >
      {component()}
    </Popover>
  );
};

export const ModalInfoButton: React.FC<ModalInfoButtonProps> = ({
  icon = 'info',
  label,
  component,
}) => {
  const [open, setOpen] = React.useState(false);
  const hasLabel = Boolean(label);
  return (
    <>
      <Button
        iconBefore={<Icon name={icon} color="var(--ds-text-color-muted)" />}
        size="small"
        strong={hasLabel}
        pill={hasLabel}
        circle={!hasLabel}
        onClick={() => setOpen(true)}
      >
        {label}
      </Button>

      {component({ isOpen: open, onClose: () => setOpen(false) })}
    </>
  );
};

import React from 'react';
import isEqual from 'lodash/isEqual';
import { TableCell, TableRow } from '@webMolecules/Table/Table';
import { LineItemProps } from '@webMolecules/LineItem/LineItem';
import { Text } from '@webMolecules/LineItem/Cell/Text';
import {
  PACSStudyLineItem,
  PACSStudyStatusToString,
  mapPACSStudyStatusToColour,
} from '@interfaces/PACSStudyLineItem';
import { Status } from '@webMolecules/Status/Status';
import { t } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';

export interface PACSStudyLineItemProps {
  pacsStudyLineItem: PACSStudyLineItem;
}

const PACSStudyLineItemList = ({
  pacsStudyLineItem,
}: PACSStudyLineItemProps): React.ReactElement<LineItemProps> => {
  return (
    <TableRow>
      <TableCell width="200px">
        <Box display="flex" alignItems="center">
          <Status
            intent={mapPACSStudyStatusToColour(pacsStudyLineItem.status)}
          />
          <Text type="body2" marginLeft="xs">
            {t(PACSStudyStatusToString(pacsStudyLineItem.status))}
          </Text>
        </Box>
      </TableCell>
      <TableCell>
        <Text type="body2">{pacsStudyLineItem.accession}</Text>
      </TableCell>
      <TableCell>
        <Text type="body2">{pacsStudyLineItem.operatorCode}</Text>
      </TableCell>
    </TableRow>
  );
};

export const MemoizedPACSStudyLineItem = React.memo(
  PACSStudyLineItemList,
  isEqual
);

export enum ApiStatus {
  Busy = 'api_status--busy',
  Idle = 'api_status--idle',
  Error = 'api_status--error',
}

export enum FinaliseStatus {
  Idle = 'finalise_status--idle',
  InProgress = 'finalise_status--in-progress',
  Success = 'finalise_status--success',
  Error = 'finalise_status--error',
}

export type Status = ApiStatus;

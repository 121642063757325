import Maybe, { just, nothing } from '@monads/Maybe';
import * as Contract from '@contracts/Session';
import {
  SessionStatus,
  SessionMetadata,
  SessionConfiguration,
} from '@interfaces/SessionLineItem';
import { WorkspaceStatus } from '@interfaces/WorkspaceStatus';
import { WorkspaceGroup } from '@interfaces/WorkspaceGroup';
import { FilterOptions } from '@interfaces/FilterOptions';
import { ReanalyseSessionResponse } from '@entities/ReanalyseSessionResponse';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { Storage as ContractStorage } from '@contracts/Storage';
import { post } from '@api/schema/client';
import { components } from '@api/schema/generated';
import { WorklistLineItem } from '@entities/Worklist';
import { Analysis } from '@entities/Analysis';
import { encodeMetadata } from './decoders/SessionLineItem';
import { decode as decodeWorkspaceStatus } from './decoders/WorkspaceStatus';
import { decodeWorklistLineItem } from './decoders/WorklistLineItem';
import { SessionStatusMap, encodeEnum } from './decoders/Enum';
import {
  decodeThyroidGeneralCharacteristics,
  encodeThyroidGeneralCharacteristics,
} from './decoders/ThyroidGeneralCharacteristics';
import { decode as decodeAnalysis } from './decoders/Analysis';

export class Session implements Contract.Session {
  async GetWorkspaceStatus(
    userFilters: string[],
    workspaceFilters: string[]
  ): Promise<WorkspaceStatus[]> {
    const data = await post('/get-worklist-counts', {
      body: {
        filter_configuration_ids: workspaceFilters,
        user_profile_ids: userFilters,
      },
    });

    const workspaceStatus: WorkspaceStatus[] = data.worklists.map(item =>
      decodeWorkspaceStatus(item)
    );
    return workspaceStatus;
  }

  async Lookup(analysisId: string, userId: string): Promise<Maybe<string>> {
    const data = await post('/list-sessions', {
      body: {
        filters: {
          analysis_id: analysisId,
          user_profile_id: userId,
        },
      },
    });

    const first = data.sessions.pop();
    if (first) {
      return just(first.session_id);
    }
    return nothing();
  }

  private async ListSessions(
    storage: ContractStorage,
    filterOptions: FilterOptions
  ) {
    const filterStatus = filterOptions.status.map(sessionStatus =>
      encodeEnum(sessionStatus, SessionStatusMap)
    );

    const filters: components['schemas']['ListSessionsInputFilters'] = {
      status: filterStatus,
      is_bookmarked: filterOptions.isBookmarked,
    };

    switch (filterOptions.group) {
      case WorkspaceGroup.User:
        filters.user_profile_id = filterOptions.id;
        break;
      case WorkspaceGroup.Workspace:
        filters.filter_configuration_id = filterOptions.id;
        break;
    }

    const listSessionsQueryVariables = {
      filters,
      limit: filterOptions.limit,
      offset: filterOptions.offset,
    };

    const data = await post('/list-sessions', {
      body: listSessionsQueryVariables,
    });

    const items = data.sessions;
    const worklistLineItems: WorklistLineItem[] = await Promise.all(
      items.map(s => decodeWorklistLineItem(storage, s))
    );

    const totalItems = data.total_items;

    return {
      worklistLineItems,
      totalItems,
      filterOptions,
    };
  }

  async List(
    storage: ContractStorage,
    filterOptions: FilterOptions
  ): Promise<Contract.FilteredSessionLineItems> {
    return this.ListSessions(storage, filterOptions);
  }

  UpdateStatus(
    sessionId: string,
    sessionStatus: SessionStatus
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            status: encodeEnum(sessionStatus, SessionStatusMap),
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateBookmarked(
    sessionId: string,
    isBookmarked: boolean
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            is_bookmarked: isBookmarked,
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateMetadata(
    sessionId: string,
    metadata: SessionMetadata
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            encrypted_metadata: encodeMetadata(metadata),
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateConfiguration(
    sessionId: string,
    _configuration: SessionConfiguration
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            // configuration: JSON.stringify(configuration),
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateCollaborators(
    sessionId: string,
    collaborators: string[]
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            collaborator_user_profile_ids: collaborators,
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  DownloadArchive(sessionId: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post('/download-session-dicoms', {
          body: {
            session_id: sessionId,
          },
        });

        resolve(data.s3_key);
      } catch (e) {
        reject(e);
      }
    });
  }

  DownloadAlmondResult(sessionId: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post('/download-session-analysis-data', {
          body: {
            session_id: sessionId,
          },
        });

        resolve(data.s3_key);
      } catch (e) {
        reject(e);
      }
    });
  }

  Reanalyse(sessionId: string): Promise<ReanalyseSessionResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post('/reset-session', {
          body: {
            session_id: sessionId,
          },
        });

        resolve({ batchId: data.batch_uid, sessionId: data.session_id });
      } catch (e) {
        reject(e);
      }
    });
  }

  SetSessionOwner(
    sessionId: string,
    userProfileId: string,
    collaborators: string[]
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            collaborator_user_profile_ids: collaborators,
            user_profile_id: userProfileId,
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateComparisonSessionId(
    storage: ContractStorage,
    sessionId: string,
    comparisonSessionId: string
  ): Promise<Analysis> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await post('/update-comparison-session', {
          body: {
            session_id: sessionId,
            comparison_session_id: comparisonSessionId,
          },
        });

        resolve(decodeAnalysis(storage, response));
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateAssignedWorkspaces(
    sessionId: string,
    assignedWorkspaces: string[]
  ): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/update-session', {
          body: {
            session_id: sessionId,
            assigned_workspaces: assignedWorkspaces,
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  UpdateThyroidGeneralCharacteristics(
    sessionId: string,
    generalCharacteristics: ThyroidGeneralCharacteristics
  ): Promise<Contract.ThyroidGeneralCharacteristicsResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post('/update-thyroid-general-characteristics', {
          body: {
            session_id: sessionId,
            general_characteristics: encodeThyroidGeneralCharacteristics(
              generalCharacteristics
            ),
          },
        });

        resolve({
          thyroidGeneralCharacteristics: decodeThyroidGeneralCharacteristics(
            data.general_characteristics
          ),
          impressions: data.impression ?? undefined,
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}

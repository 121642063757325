import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Tooltip } from '@webMolecules/Tooltip/Tooltip';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';

interface GalleryControlsProps {
  onNext: () => void;
  onPrevious: () => void;
}

export const GalleryControls = (props: GalleryControlsProps) => {
  const { onNext, onPrevious } = props;
  return (
    <Box display="flex" gap="xs">
      <Tooltip label={t('pages.study.gallery.controls.previous')}>
        <Button
          iconBefore={<Icon name="chevron-left" />}
          onClick={onPrevious}
          circle
          size="small"
          strong
        />
      </Tooltip>
      <Tooltip label={t('pages.study.gallery.controls.next')}>
        <Button
          iconBefore={<Icon name="chevron-right" />}
          circle
          onClick={onNext}
          size="small"
          strong
        />
      </Tooltip>
    </Box>
  );
};

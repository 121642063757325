import LDClient from 'launchdarkly-js-client-sdk';
import camelCase from 'lodash/camelCase';
import * as Contracts from '@contracts/FeatureFlags';
import { launchDarklyClientId } from '@helpers/env';
import { Profile } from '@interfaces/Profile';
import {
  CYPRESS_FEATURE_FLAGS,
  DEFAULT_FEATURE_FLAGS,
  FeatureFlags,
} from '@entities/FeatureFlag';

const LAUNCH_DARKLY_INITIALIZE_TIMEOUT = 5_000;

export class LaunchDarkly implements Contracts.FeatureFlags {
  GetFeatureFlagsForUser(profile: Profile): Promise<FeatureFlags> {
    return new Promise(resolve => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).Cypress) {
        resolve(CYPRESS_FEATURE_FLAGS);
      }

      const userContext = {
        kind: 'user',
        key: profile.email,
        email: profile.email,
        institutionId: profile.institutionId,
      };

      const client = LDClient.initialize(launchDarklyClientId, userContext);

      const timeout = setTimeout(() => {
        resolve(DEFAULT_FEATURE_FLAGS);
      }, LAUNCH_DARKLY_INITIALIZE_TIMEOUT);

      client.on('ready', () => {
        clearTimeout(timeout);
        const camelCasedFeatureFlags = camelCaseKeys(
          client.allFlags() as FeatureFlags
        );

        resolve(camelCasedFeatureFlags);
      });
    });
  }
}

// snippet from launchdarkly react-client-sdk
// https://github.com/launchdarkly/react-client-sdk/blob/4c11ca2835e32d735c9ca3b9097b4c4780b9acbd/src/utils.ts#L12
export const camelCaseKeys = (rawFlags: FeatureFlags) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flags: any = {} as FeatureFlags;

  for (const rawFlag in rawFlags) {
    // Exclude system keys
    if (rawFlag.indexOf('$') !== 0) {
      flags[camelCase(rawFlag)] = rawFlags[rawFlag as keyof FeatureFlags];
    }
  }

  return flags;
};

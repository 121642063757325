import { institutionIdOverride } from './env';

export const authSignIn = '/a/sign-in';
export const authSignInReasonSessionTimedOut =
  '/a/sign-in?reason=session_timed_out';
export const authSignInReasonSignedInUserChanged =
  '/a/sign-in?reason=session_signed_in_user_changed';
export const authSignInReasonPasswordWasReset =
  '/a/sign-in?reason=password_was_reset';
export const authForgotPassword = '/a/forgot-password';
export const authCreateAccount = '/a/create-account';

export const settingsProfile = '/settings/profile';
export const settingsSecurity = '/settings/security';
export const blockedDomain = '/a/blocked-domain';

export const settingsApplication = '/settings/admin/application';

export const adminUsers = (userId?: string, unsafe?: true) =>
  absolute([
    'settings',
    'admin',
    'users',
    userId && !unsafe ? encodeURIComponent(userId) : userId,
  ]);

export const adminWorkspaces = (filterConfigurationId?: string): string =>
  absolute(['settings', 'admin', 'workspaces', filterConfigurationId]);

export const adminInstitutions = (
  institutionId?: string,
  userId?: string
): string =>
  absolute(['settings', 'admin', 'institutions', institutionId, userId]);

export const worklist = (group?: string, id?: string, status?: string) =>
  absolute(['worklist', group, id, status]);

export const search = (query: string) =>
  absolute(['worklist', 'search', query]);

export const study = (
  analysisId: string,
  sessionId?: string,
  tab?: string,
  preview?: true | string
) =>
  absolute([
    'study',
    analysisId,
    sessionId,
    tab,
    preview === true ? 'preview' : preview,
  ]);

export const accession = (accessionNum: string) =>
  absolute(['accession', accessionNum]);

export const studyArchive = (analysisId: string, sessionId: string) =>
  absolute(['study', 'archive', analysisId, sessionId]);

export const absolute = (params: (string | number | undefined)[]): string =>
  `/${params.filter(a => !!a).join('/')}`;

export const lastWorklistPath = (): string =>
  window.sessionStorage.getItem('WORKLIST_LOCATION') || `/worklist`;

export const isSeeModeAuthUrl = () => {
  return (
    window.location.pathname.includes(authSignIn) ||
    window.location.pathname.includes(authSignInReasonSessionTimedOut) ||
    window.location.pathname.includes(authSignInReasonSignedInUserChanged) ||
    window.location.pathname.includes(authSignInReasonPasswordWasReset) ||
    window.location.pathname.includes(authForgotPassword) ||
    window.location.pathname.includes(authCreateAccount)
  );
};

export const isSAMLBypass = (): boolean => {
  //No react router to leverage, have to get from basic js api
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isSAMLBypassUrl = params.get('see-mode-admin') !== null;
  return isSAMLBypassUrl;
};

export const getIsAdminDomain = (): boolean => {
  const hostName = institutionIdOverride || getHostName();
  return hostName.includes('-admin');
};

export const getHostName = (): string => {
  const hostnameComponents = window.location.host.split('.');
  return hostnameComponents.length >= 2 ? hostnameComponents[0] : '';
};

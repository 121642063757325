import isEqual from 'lodash/isEqual';
import { Search, initial } from '@entities/Search';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import { Search as ContractSearch } from '@contracts/Search';
import { Session as ContractSession } from '@contracts/Session';
import { Authenticate as ContractAuthenticate } from '@contracts/Authenticate';
import { Encryption as ContractEncryption } from '@contracts/Encryption';
import { Storage as ContractStorage } from '@contracts/Storage';
import Effect, { effect } from '@library/Effect';
import { ApiStatus } from '@entities/Status';
import { decryptPatientHealthInfo } from '@useCases/Session';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { Profile } from '@entities/Profile';
import { FilterOptions } from '@interfaces/FilterOptions';
import { just } from '@monads/Maybe';
import { WorkspaceGroup } from '@interfaces/WorkspaceGroup';
import { createUseCase } from '@helpers/createUseCase';
import { WorklistLineItem } from '@entities/Worklist';
import { DEFAULT_FEATURE_FLAGS, FeatureFlags } from '@entities/FeatureFlag';

export const searchUseCase = {
  /**
   * Update the Search Query
   */
  UpdateQuery: createUseCase('SEARCH_UPDATE_QUERY').withPayload<{
    query: string;
  }>(),

  /**
   * Perform Search
   */
  Perform: createUseCase('SEARCH_PERFORM').withPayload<{
    query: string;
  }>(),

  /**
   * List next set of Search Results with respect to the filterOptions in place.
   */
  NextSearchResult: createUseCase(
    'SEARCH_LIST_NEXT_SEARCH_RESULT'
  ).withPayload<{
    featureFlags: FeatureFlags;
  }>(),

  /**
   * Receive Search Result
   */
  ReceiveResult: createUseCase('SEARCH_RECEIVE_RESULT').withPayload<{
    searchResultItems: WorklistLineItem[];
    totalItems: number;
    filterOptions: FilterOptions;
  }>(),

  /**
   *
   * Set decrypt Search Result
   */
  SetDecryptedSearchResult: createUseCase(
    'SEARCH_SET_DECRYPTED_SEARCH_RESULT'
  ).withPayload<{
    searchResultItems: WorklistLineItem[];
  }>(),

  /**
   * Set Search LineItem status
   */
  SetSearchLineItemStatus: createUseCase(
    'SESSION_SET_SEARCH_LINE_ITEM_STATUS'
  ).withPayload<{
    analysisId: string;
    sessionId: string;
    sessionStatus: SessionStatus;
    featureFlags: FeatureFlags;
  }>(),

  /**
   * Clear all Search
   */
  Clear: createUseCase('SEARCH_CLEAR').noPayload(),

  /**
   * No Operation
   */
  NoOp: createUseCase('SEARCH_NO_OP').noPayload(),
};

/**
 *  All UseCases
 */
export type SearchUseCases = ReturnType<
  | typeof searchUseCase.UpdateQuery
  | typeof searchUseCase.Perform
  | typeof searchUseCase.NextSearchResult
  | typeof searchUseCase.ReceiveResult
  | typeof searchUseCase.SetDecryptedSearchResult
  | typeof searchUseCase.SetSearchLineItemStatus
  | typeof searchUseCase.Clear
  | typeof searchUseCase.NoOp
>;

const SEARCH_LIST_LIMIT = 25;

/*
 * Reducer
 *
 * The SearchReducer takes SearchUseCases and the current Search (state)
 * and returns a new state and possible side effects.
 */
export class SearchReducer extends EffectReducer<Search> {
  private apiSearch: ContractSearch;
  private apiSession: ContractSession;
  private apiAuthenticate: ContractAuthenticate;
  private apiEncryption: ContractEncryption;
  private apiStorage: ContractStorage;
  private profile: Profile;

  constructor(
    apiSearch: ContractSearch,
    apiSession: ContractSession,
    apiAuthenticate: ContractAuthenticate,
    apiEncryption: ContractEncryption,
    apiStorage: ContractStorage,
    profile: Profile
  ) {
    super();
    this.apiSearch = apiSearch;
    this.apiSession = apiSession;
    this.apiAuthenticate = apiAuthenticate;
    this.apiEncryption = apiEncryption;
    this.apiStorage = apiStorage;
    this.profile = profile;
  }

  Perform(
    search: Search = initial(),
    { type, payload: useCase }: SearchUseCases
  ): ReducerResult<Search> {
    switch (type) {
      case searchUseCase.UpdateQuery.type: {
        return this.Result({
          ...search,
          query: useCase.query,
        });
      }

      case searchUseCase.Perform.type: {
        const useFilterOptions = {
          group: WorkspaceGroup.User,
          id: '',
          status: [],
          offset: 0,
          limit: SEARCH_LIST_LIMIT,
          featureFlags: DEFAULT_FEATURE_FLAGS,
        } as FilterOptions;
        return this.Result(
          {
            ...search,
            query: useCase.query,
            searchResultItems: [],
            totalAvailableItems: 0,
            status: ApiStatus.Busy,
            filterOptions: just(useFilterOptions),
          },
          effectPerformSearch(
            this.apiSearch,
            this.apiStorage,
            useCase.query,
            useFilterOptions
          )
        );
      }
      case searchUseCase.SetSearchLineItemStatus.type:
        return this.Result(
          {
            ...search,
            searchResultItems: search.searchResultItems
              .map(item => {
                if (item.id === useCase.sessionId) {
                  item.status = useCase.sessionStatus;
                }
                return item;
              })
              .filter(a => a.status != SessionStatus.Archived),
          },
          effectSetSearchLineItemStatus(
            this.apiSession,
            search.query,
            useCase.sessionId,
            useCase.sessionStatus
          )
        );
      case searchUseCase.NextSearchResult.type: {
        const filterOptions = search.filterOptions.lift();
        if (!filterOptions) {
          return this.Result(search);
        }
        const newFilterOptions = {
          ...filterOptions,
          offset: search.searchResultItems.length,
        };
        return this.Result(
          {
            ...search,
            filterOptions: just(newFilterOptions),
            status: ApiStatus.Busy,
          },
          effectPerformSearch(
            this.apiSearch,
            this.apiStorage,
            search.query,
            newFilterOptions
          )
        );
      }
      case searchUseCase.ReceiveResult.type: {
        if (isEqual(search.filterOptions.lift(), useCase.filterOptions)) {
          const searchResultItems = [
            ...search.searchResultItems,
            ...useCase.searchResultItems,
          ];
          return this.Result(
            {
              ...search,
              status: ApiStatus.Idle,
              searchResultItems: searchResultItems,
              totalAvailableItems: useCase.totalItems,
            },
            effectDecryptSearchResult(
              searchResultItems,
              this.apiEncryption,
              this.profile
            )
          );
        }
        return this.Result({ ...search });
      }

      case searchUseCase.SetDecryptedSearchResult.type: {
        return this.Result({
          ...search,
          searchResultItems: useCase.searchResultItems,
        });
      }

      case searchUseCase.Clear.type:
        return this.Result({
          ...search,
          status: ApiStatus.Idle,
          searchResultItems: [],
        });
      case searchUseCase.NoOp.type:
        return this.Result({ ...search });
    }
  }
}

/**
 * Decrypt search result
 */
const effectDecryptSearchResult = (
  searchResultItems: WorklistLineItem[],
  apiEncryption: ContractEncryption,
  profile: Profile
): Effect =>
  effect(async () => {
    const decryptedSearchItems: WorklistLineItem[] = (await Promise.all(
      searchResultItems.map(item =>
        decryptPatientHealthInfo(apiEncryption, profile, item)
      )
    )) as WorklistLineItem[];
    return searchUseCase.SetDecryptedSearchResult({
      searchResultItems: decryptedSearchItems,
    });
  }, 'effect-decrypt-search-result');

/**
 * Perform a search operation
 */
const effectPerformSearch = (
  apiSearch: ContractSearch,
  apiStorage: ContractStorage,
  query: string,
  params: FilterOptions
): Effect =>
  effect(async () => {
    const { searchResultItems, totalItems, filterOptions } =
      await apiSearch.Perform(apiStorage, query, params);

    return searchUseCase.ReceiveResult({
      searchResultItems,
      totalItems,
      filterOptions,
    });
  }, 'effect-search-perform-search');

/**
 * Change the status of a search line item
 */
const effectSetSearchLineItemStatus = (
  apiSession: ContractSession,
  query: string,
  sessionId: string,
  sessionStatus: SessionStatus
): Effect =>
  effect(async () => {
    await apiSession.UpdateStatus(sessionId, sessionStatus);
    return searchUseCase.Perform({ query });
  }, 'effect-search-set-session-line-item-status') as Effect;

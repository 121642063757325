import React from 'react';
import { Text as TextA, TextProps } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';

export const TextMuted: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text muted {...rest}>
    {children}
  </Text>
);

export const TextDisplay: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text muted type="display5" {...rest}>
    {children}
  </Text>
);

export interface TextMutedWithIconProps extends TextProps {
  icon: React.ReactNode;
}

export const TextMutedWithIcon: React.FC<TextMutedWithIconProps> = ({
  children,
  icon,
  ...rest
}) => (
  <Box display="flex" alignItems="center">
    <Box marginRight="xs">{icon}</Box>
    <TextMuted {...rest}>{children}</TextMuted>
  </Box>
);

export const Text: React.FC<TextProps> = ({ children, ...rest }) => (
  <TextA type="body2" lineClamp={1} marginRight="s" {...rest}>
    {children}
  </TextA>
);

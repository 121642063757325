import React from 'react';
import { FilterListLineItem } from '@webOrganisms/FilterList/FilterList';
import { t } from '@webInterfaces/I18n';
import FilterModal from '@webOrganisms/FilterModal/FilterModal';

export interface WorkSpaceFilterModalProps {
  isOpen: boolean;
  subtitle: string;
  items: FilterListLineItem[];
  closeModal: () => void;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

const WorkSpaceFilterModal: React.FC<WorkSpaceFilterModalProps> = ({
  isOpen,
  items,
  subtitle,
  closeModal,
  onAdd,
  onRemove,
}) => {
  return (
    <FilterModal
      isOpen={isOpen}
      title={t('workspacefilter.modal.title')}
      subtitle={subtitle}
      showAvatar={false}
      placeholder={t('workspacefilter.modal.placeholder')}
      items={items}
      closeModal={closeModal}
      onAdd={onAdd}
      onRemove={onRemove}
    ></FilterModal>
  );
};

export default WorkSpaceFilterModal;

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import styles from '../comparison.scss';
interface FindingNoduleUnitProps {
  unit: string;
  showUnit?: boolean;
}
export const FindingNoduleUnit = ({
  unit,
  showUnit,
}: FindingNoduleUnitProps) => {
  return (
    <Box className={showUnit ? '' : styles.unit} marginRight="xs">
      <Text display="block" type="body">
        {unit}
      </Text>
    </Box>
  );
};

import { Amplify } from 'aws-amplify';
import { institutionIdOverride, amplifyAppRegion } from '@helpers/env';
import { browserStorage } from '@web/BrowserStorage';
import { setSessionFlags } from '@interfaces/Authentication';
import { getHostName } from '@helpers/routes';
import { post } from '@api/schema/client';

let __amplify_initialized = false;
interface GuestAPIResponse {
  cognito_identity_pool_id?: string;
  cognito_user_pool_id?: string;
  cognito_client_id?: string;
  native_region?: string;
  data_bucket_name?: string;
  cognito_federation_enabled?: boolean;
  cognito_federation_identity_provider?: string;
  cognito_login_domain?: string;
  institution_login_copy?: string;
}

const validInstitutionResponse = (res: GuestAPIResponse) => {
  return (
    res.cognito_identity_pool_id !== undefined &&
    res.cognito_user_pool_id !== undefined &&
    res.cognito_client_id !== undefined
  );
};

export const getHostNameFromURL = () => {
  const hostName = getHostName();
  const endpointSlug = institutionIdOverride || hostName;
  return {
    endpointSlug: endpointSlug,
  };
};

export const initAmplify = (): Promise<boolean> => {
  return new Promise<boolean>((resolve, _reject) => {
    if (__amplify_initialized) {
      resolve(true);
    }

    const hostnameComponents = window.location.host.split('.');
    if (institutionIdOverride || hostnameComponents.length >= 2) {
      const { endpointSlug } = getHostNameFromURL();
      post(
        '/anon/get-application-config',
        {
          body: {
            institution_id: endpointSlug,
          },
        },
        true
      )
        .then(res => {
          setSessionFlags(
            res.cognito_federation_enabled,
            res.cognito_federation_identity_provider,
            res.institution_login_copy
          );
          if (!validInstitutionResponse(res)) {
            resolve(false);
          }
          const amplify_config = {
            Auth: {
              region: amplifyAppRegion,
              identityPoolId: res.cognito_identity_pool_id,
              userPoolId: res.cognito_user_pool_id,
              userPoolWebClientId: res.cognito_client_id,
              mandatorySignIn: true,
              storage: browserStorage,
              oauth: {
                domain: res.cognito_login_domain,
                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin,
                responseType: 'code',
                scope: ['phone', 'email', 'profile', 'openid'],
              },
            },
            Storage: {
              // We will hand-craft the analysis structure of our assets to be prefixed by the customer name
              customPrefix: {
                public: '',
                private: '',
                protected: '',
              },
              AWSS3: {
                bucket: res.data_bucket_name,
                region: res.native_region,
              },
            },
          };

          Amplify.configure(amplify_config);

          __amplify_initialized = true;
          resolve(true);
        })
        .catch(_ => {
          // Catch because the post function throws a 404 as an error
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
};

import React from 'react';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import { Button } from '@webMolecules/Button/Button';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import { t, tPrint } from '@webInterfaces/I18n';
import { ResultTableFilterButtonOptions } from '@config/ResultTable/ResultTable';
import { GalleryFilter } from '@interfaces/GalleryFilter';
import {
  getSpecificGalleryFilterLabel,
  SpecificGalleryFilter,
} from '@config/Gallery/SpecificGalleryFilter';
import { ResultTableSharedProps } from '../ResultTableSharedProps';

interface ResultTableCellFilterButtonProps extends ResultTableSharedProps {
  options?: ResultTableFilterButtonOptions;
  disabled?: boolean;
  specificGalleryFilter: SpecificGalleryFilter;
  filters?: GalleryFilter;
  setGalleryFilter?: React.Dispatch<React.SetStateAction<GalleryFilter>>;
}

export const ResultTableCellFilterButton: React.FC<
  ResultTableCellFilterButtonProps
> = props => {
  const { options, specificGalleryFilter } = props;

  const { galleryFilter, setGalleryFilter, theme } = {
    galleryFilter: props.filters,
    setGalleryFilter: props.setGalleryFilter,
    theme: ResultTableStyle.editable,
  };

  const onClick = () => {
    const specificFilter =
      galleryFilter?.specificFilter == specificGalleryFilter
        ? undefined
        : specificGalleryFilter;

    setGalleryFilter &&
      setGalleryFilter({
        findingIds: [],
        specificFilter,
      });
  };

  if (theme == ResultTableStyle.preview) {
    return null;
  }

  const title = t(getSpecificGalleryFilterLabel(specificGalleryFilter) ?? '');
  const label = tPrint('gallery-filter-button', title);

  return (
    <Box data-testid="test-filterButton">
      <Tooltip
        label={label}
        disabled={options?.disabled}
        position={TooltipPosition.Top}
      >
        <Button
          disabled={options?.disabled}
          iconBefore={<Icon name="image" />}
          circle
          size="small"
          onClick={onClick}
          intent={
            galleryFilter?.specificFilter == specificGalleryFilter
              ? 'primary'
              : 'neutral'
          }
        />
      </Tooltip>
    </Box>
  );
};

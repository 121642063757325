import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Worklist/Sidebar';
import * as StorySidebar from '@webPages/Worklist/Sidebar';

const Sidebar: React.FC<ViewModel.Sidebar> = ({
  me,
  availableWorkspaces,
  availableProfiles,
  workspaceStatus,
  filterOptions,
  dispatchUpdateUserProfilePreferences,
}) => (
  <StorySidebar.Sidebar
    me={me}
    availableWorkspaces={availableWorkspaces}
    availableProfiles={availableProfiles}
    onUpdateUserProfilePreferences={dispatchUpdateUserProfilePreferences}
    workspaceStatus={workspaceStatus}
    filterOptions={filterOptions}
  />
);

export default connect(ViewModel.fromState, ViewModel.dispatchers)(Sidebar);

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { Text } from '@webMolecules/Text/Text';
import { GalleryFilter as IGalleryFilter } from '@interfaces/GalleryFilter';
import { SpecificGalleryFilter } from '@config/Gallery/SpecificGalleryFilter';
import { selectWorkflow } from '@selectors/BaseSelectors';
import { Workflow } from '@entities/Workflow';
import { GalleryFilterPills } from './GalleryFilterPills';
import styles from './gallery.scss';

export type GalleryFilterSelectOption = {
  label: string;
  value: SpecificGalleryFilter;
  isDefault: (workflow: Workflow) => boolean;
};

interface GalleryFilterProps {
  filters: IGalleryFilter;
  thumbnailsNum: number;
  handleClearFilter: (filter: string) => void;
  setGalleryFilter: React.Dispatch<React.SetStateAction<IGalleryFilter>>;
  selectOptions: GalleryFilterSelectOption[];
  hasFilterPill: boolean;
}

export const GalleryFilter = (props: GalleryFilterProps) => {
  const {
    filters,
    thumbnailsNum,
    handleClearFilter,
    setGalleryFilter,
    selectOptions,
    hasFilterPill,
  } = props;

  if (selectOptions.length === 0) {
    return hasFilterPill ? (
      <GalleryFilterPills
        filters={filters}
        thumbnailsNum={thumbnailsNum}
        handleClearFilter={handleClearFilter}
        setGalleryFilter={setGalleryFilter}
      />
    ) : null;
  }

  const workflow = useSelector(selectWorkflow);

  const defaultSelectValue = selectOptions.find(option =>
    option.isDefault(workflow)
  )?.value;

  const onChangeSelectFilter = (value: string) => {
    setGalleryFilter({
      ...filters,
      specificFilter: value as SpecificGalleryFilter,
    });
  };

  return (
    <>
      <Box className={styles.toolbarLabel}>
        <Text display="inline" type="display5" marginRight="xxs">
          {t('gallery.finding.filter.all_capture')}
        </Text>
        <Text type="display6" marginRight="m">
          :
        </Text>
      </Box>

      <Box display="flex" alignItems="center" className={styles.filterSection}>
        <Text type="display6" marginRight="xs">
          {t('gallery.finding.filter.show')}
        </Text>

        {hasFilterPill && (
          <GalleryFilterPills
            filters={filters}
            thumbnailsNum={thumbnailsNum}
            handleClearFilter={handleClearFilter}
            setGalleryFilter={setGalleryFilter}
          />
        )}

        {!hasFilterPill && (
          <Box className={styles.select}>
            <MenuSelect
              data-testid="finding-gallery-filter-select-item"
              value={filters.specificFilter || defaultSelectValue}
              onChange={onChangeSelectFilter}
              options={selectOptions}
              placeholder={''}
              size="small"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

import React from 'react';
import { FilterListLineItem } from '@webOrganisms/FilterList/FilterList';
import { t } from '@webInterfaces/I18n';
import FilterModal from '@webOrganisms/FilterModal/FilterModal';

export interface UserFilterModalProps {
  title?: string;
  isOpen: boolean;
  subtitle: string;
  items: FilterListLineItem[];
  closeModal: () => void;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

const UserFilterModal: React.FC<UserFilterModalProps> = ({
  isOpen,
  items,
  title,
  subtitle,
  closeModal,
  onAdd,
  onRemove,
}) => {
  return (
    <FilterModal
      isOpen={isOpen}
      title={title ? title : t('userfilter.modal.title')}
      subtitle={subtitle}
      placeholder={t('userfilter.modal.placeholder')}
      items={items}
      showAvatar={true}
      closeModal={closeModal}
      onAdd={onAdd}
      onRemove={onRemove}
    ></FilterModal>
  );
};

export default UserFilterModal;

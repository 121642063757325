import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Settings/Admin/Institutions';
import { Institutions as StoryInstitution } from '@webPages/Settings/Admin/Institutions';
import StoryInstitutionNew from '@webPages/Settings/Admin/InstitutionNew';
import StoryInstitutionDetail from '@webPages/Settings/Admin/InstitutionDetail';
import * as StoryLayout from '@webPages/Settings/Layout';
import StoryUserDetails from '@webPages/Settings/Admin/UserDetails';

const Institutions: React.FC<ViewModel.Institutions> = ({
  messages,
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  targetInstitutionLineItem,
  targetInstitutionUser,
  loading,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  institutionLineItems,
  mode,
  targetInstitution,
  dispatchOnSignOut,
  dispatchCreateInstitution,
  dispatchCreateInstitutionUser,
  dispatchDeleteInstitutionUser,
  dispatchUnloadTargetInstitution,
  dispatchUnloadTargetInstitutionUser,
  dispatchUpdateUser,
}) => {
  return (
    <StoryLayout.Layout
      profile={profile}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      onSignOut={dispatchOnSignOut}
      searchQuery={searchQuery}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
    >
      {mode === 'list' && (
        <>
          {targetInstitutionLineItem
            .map(targetInstitution => (
              <StoryInstitutionNew
                key={targetInstitution.id}
                institutionLineItem={targetInstitution}
                onClose={dispatchUnloadTargetInstitution}
                onCreateInstitution={dispatchCreateInstitution}
              />
            ))
            .lift()}
          <StoryInstitution
            loading={loading}
            messages={messages}
            institutionLineItems={institutionLineItems}
          />
        </>
      )}
      {mode === 'detail' && (
        <>
          {targetInstitutionUser
            .map(targetInstitutionUser => (
              <StoryUserDetails
                key={targetInstitutionUser.id}
                profile={profile}
                userLineItem={targetInstitutionUser}
                emailsInUse={targetInstitution.mapOr(
                  a => a.users.map(b => b.email),
                  []
                )}
                onClose={() => dispatchUnloadTargetInstitutionUser()}
                onCreateUser={dispatchCreateInstitutionUser}
                onUpdateUser={dispatchUpdateUser}
              />
            ))
            .lift()}
          {targetInstitution
            .map(tI => (
              <StoryInstitutionDetail
                key="story-institution-detail"
                profile={profile}
                loading={loading}
                messages={messages}
                targetInstitution={tI}
                onDeleteUser={dispatchDeleteInstitutionUser}
              />
            ))
            .lift()}
        </>
      )}
    </StoryLayout.Layout>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(Institutions);

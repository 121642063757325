import React, { forwardRef, HTMLAttributes } from 'react';
import { cnames } from '@helpers/cnames';
import { LoadingIcon } from '../LoadingIcon/LoadingIcon';
import { Box } from '../Box/Box';
import styles from './button.scss';

export interface ButtonProps
  // Extend all the native HTML Button props except size, because we have our own size prop
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The Button label/children
   */
  children?: React.ReactNode;
  /**
   * Visually state the intent this button carries.
   */
  intent?: 'neutral' | 'primary' | 'secondary' | 'destructive' | 'deactivated';
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /*
   * Strong
   */
  strong?: boolean;
  /**
   * Use fluid to let the button fill up the entire horizontal space.
   */
  fluid?: boolean;
  /**
   * Align text
   */
  textAlign?: 'left' | 'center' | 'right';
  /**
   * Is the button disabled
   */
  disabled?: boolean;
  /**
   * Is the Button or the Button's action in a loading state?
   */
  loading?: boolean;
  /**
   * Should the button be rendered as a circle?
   */
  circle?: boolean;
  /**
   * Should the button be rendered as a pill?
   */
  pill?: boolean;
  /*
   * Visually mute the color state that intent carries
   */
  subtle?: boolean;
  /**
   * Show given icon before the label.
   */
  iconBefore?: JSX.Element;
  /**
   * Show given icon after the label.
   */
  iconAfter?: JSX.Element;
  /**
   * The Button links to given href
   * __Note__ Settings an href will set the Button`type` to `'anchor'`;
   */
  href?: string;
  /**
   * When using `href` should the link open in a new window ("blank") or in the same window ("self")?
   */
  target?: 'blank' | 'self';
  /**
   * Add an optional onClick Event
   */
  onClick?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onMouseDown?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  /**
   * navigate
   */
  navigate?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      circle = false,
      disabled = false,
      fluid = false,
      textAlign,
      href,
      iconAfter = null,
      iconBefore = null,
      intent = 'neutral',
      loading = false,
      onClick,
      size = 'medium',
      strong = false,
      subtle = false,
      target,
      type = 'button',
      pill,
      navigate,
      onMouseDown,
      onKeyDown,
      ...rest
    },
    ref
  ) => {
    const classNames = cnames(
      { [styles.circle]: circle },
      { [styles.disabled]: disabled },
      { [styles.fluid]: fluid },
      { [styles.hasIconBefore]: iconBefore ? true : false },
      { [styles.hasIconAfter]: iconAfter ? true : false },
      { [styles.loading]: loading },
      { [styles.pill]: pill },
      textAlign ? styles[`text-${textAlign}`] : {},
      styles[size],
      { [styles.strong]: strong },
      { [styles.subtle]: subtle },
      styles.button,
      styles[intent]
    );

    const loadingState = () => (
      <Box display="flex" className={styles.loadingState}>
        <LoadingIcon />
      </Box>
    );

    const anchorProps = href
      ? {
          href,
          target: target === 'blank' ? '_blank' : null,
          relattribute: target === 'blank' ? 'noopener noreferrer' : null,
        }
      : null;

    const onClickHandler = (
      event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
    ) => {
      if (navigate || disabled || href === '') {
        event.preventDefault();
        if (navigate) {
          return navigate();
        }
      }

      if (disabled) {
        return;
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
    };

    const inner = (
      <>
        {loading && loadingState()}
        {iconBefore && <div className={styles.iconBefore}>{iconBefore}</div>}
        {children && <Box>{children}</Box>}
        {iconAfter && <div className={styles.iconAfter}>{iconAfter}</div>}
      </>
    );

    if (anchorProps) {
      return (
        <a
          onClick={onClickHandler}
          className={classNames}
          {...(anchorProps as React.AnchorHTMLAttributes<unknown>)}
          ref={ref as React.LegacyRef<HTMLAnchorElement>}
          {...(rest as HTMLAttributes<HTMLAnchorElement>)}
        >
          {inner}
        </a>
      );
    }

    return (
      <button
        type={type}
        onClick={onClickHandler}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        className={classNames}
        ref={ref as React.LegacyRef<HTMLButtonElement>}
        {...rest}
      >
        {inner}
      </button>
    );
  }
);

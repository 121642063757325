import { Finding } from './Finding';

export interface FindingInteraction {
  selectedId?: string;
  filterId?: string;
  activeFinding?: Finding;
  hoverMeasurementId?: string;
}

export const initial = (): FindingInteraction => ({});

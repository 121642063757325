import React from 'react';
import { Menu } from '@reach/menu-button';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import ReportDiagramWrapper from '@webViews/Pages/Study/Diagram/ReportDiagramWrapper';
import {
  userColourPaletteActive,
  userColourPaletteLight,
} from '@appCanvas/interfaces/types/ColourPalette.type';
import { Text } from '@webMolecules/Text/Text';
import { t, tDate } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { timeFromX } from '@helpers/datetime';
import { cnames } from '@helpers/cnames';
import styles from './comparison.scss';

interface ComparisonDiagramProps {
  isPrevious?: boolean;
  date: Date | null;
  onlyShowMatchedLesion?: boolean;
  editable?: boolean;
}

export const ComparisonDiagram: React.FC<ComparisonDiagramProps> = ({
  isPrevious,
  date,
  onlyShowMatchedLesion,
  editable,
}) => {
  const comparisonDiagram = cnames(styles.comparisonDiagram, {
    [styles.canvasBorder]: !editable,
  });

  return (
    <Box className={comparisonDiagram}>
      <Card
        padding="none"
        display="flex"
        flexDirection="column"
        transparent={isPrevious || editable}
      >
        <Box className={styles.labelContainer}>
          {isPrevious ? (
            <Box>
              <Menu>
                <ComparisonDiagramLabel
                  editable={editable}
                  isPrevious={isPrevious}
                  date={date}
                />
              </Menu>
            </Box>
          ) : (
            <ComparisonDiagramLabel isPrevious={isPrevious} date={date} />
          )}
        </Box>

        <ReportDiagramWrapper
          canvasColorPalette={
            editable ? userColourPaletteActive : userColourPaletteLight
          }
          showCanvasOnly={true}
          isPrevious={isPrevious}
          onlyShowMatchedLesion={onlyShowMatchedLesion}
        />
      </Card>
    </Box>
  );
};

interface ComparisonDiagramLabelProps {
  editable?: boolean;
  isPrevious?: boolean;
  date: Date | null;
}

const ComparisonDiagramLabel: React.FC<ComparisonDiagramLabelProps> = ({
  editable = false,
  isPrevious,
  date,
}) => {
  const label = isPrevious
    ? t('pages.study.comparison.previous')
    : t('pages.study.comparison.current');
  const labelPrevious = cnames(styles.labelPrevious, {
    [styles.pdfLabel]: !editable,
  });
  return (
    <Box className={styles.comparisonDiagramLabel} gap="xs">
      <Box
        paddingY="xxs"
        paddingX="m"
        display="flex"
        gap="xs"
        alignItems="center"
        className={!isPrevious ? styles.labelCurrent : labelPrevious}
      >
        <Text type="body2">{label}</Text>
        <Text>|</Text>
        <Text type="display6">{date ? tDate(date) : ''}</Text>
      </Box>
    </Box>
  );
};

interface ComparisonDiagramSectionProps {
  previousDate: Date | null;
  currentDate: Date | null;
  onlyShowMatchedLesion?: boolean;
  fullPageWidth?: boolean;
  lightTheme?: boolean;
}

export const ComparisonDiagramSection: React.FC<
  ComparisonDiagramSectionProps
> = ({
  previousDate,
  currentDate,
  onlyShowMatchedLesion = false,
  fullPageWidth = true,
  lightTheme = false,
}) => {
  const duration = timeFromX(previousDate, currentDate);
  const durationClass = cnames(styles.comparisonDuration, {
    [styles.topBorder]: lightTheme,
  });
  return (
    <Box className={lightTheme ? '' : styles.topBorder}>
      <Box
        display="flex"
        className={
          fullPageWidth
            ? styles.comparisonDiagramSection
            : styles.comparisonDiagramContainer
        }
      >
        <ComparisonDiagram
          date={previousDate}
          isPrevious
          onlyShowMatchedLesion={onlyShowMatchedLesion}
          editable={!lightTheme}
        />
        {duration && (
          <Box
            display="flex"
            justifyContent="center"
            className={durationClass}
            paddingX="m"
            alignItems="center"
            gap="xs"
          >
            <Icon name="timer" />
            <Text muted type="body2">
              {duration}
            </Text>
          </Box>
        )}
        <ComparisonDiagram date={currentDate} editable={!lightTheme} />
      </Box>
    </Box>
  );
};

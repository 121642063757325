import { searchUseCase } from '@useCases/Search';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { Actions } from '@webInterfaces/StoreTypes';
import { PACSStudyUseCase } from '@useCases/PACSStudy';
import { FeatureFlags } from '@entities/FeatureFlag';
import { listPACSStudies } from './PACS';

export const perform = (query: string): Actions[] => [
  listPACSStudies(),
  searchUseCase.Perform({ query }),
];

export const setSessionStatus = (
  analysisId: string,
  sessionId: string,
  status: SessionStatus,
  featureFlags: FeatureFlags
): Actions =>
  searchUseCase.SetSearchLineItemStatus({
    analysisId,
    sessionId,
    sessionStatus: status,
    featureFlags,
  });

export const listNextSearchResults = (featureFlags: FeatureFlags): Actions =>
  searchUseCase.NextSearchResult({ featureFlags });

export const unload = (): Actions[] => [
  searchUseCase.Clear(),
  PACSStudyUseCase.CancelPoll(),
];

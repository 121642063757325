import React from 'react';
import { Box, BoxProps } from '../Box/Box';
import { omit } from '../../helpers/omit';
import styles from './interactive-box.scss';

export interface InteractiveBoxProps extends BoxProps {
  active?: boolean;
  isAnimated?: boolean;
  pill?: boolean;
  transparent?: boolean;
  className?: string;
}

export const InteractiveBox: React.FC<InteractiveBoxProps> = ({
  active,
  children,
  isAnimated = true,
  pill,
  transparent,
  className,
  display = 'block',
  ...rest
}) => {
  const outerClassnames = [
    active ? styles.active : '',
    !isAnimated ? styles.notAnimated : '',
    pill ? styles.pill : '',
    transparent ? styles.transparent : '',
    styles.outer,
  ].join(' ');
  const innerClassnames = [styles.inner, className].join(' ');
  const filteredRestProps = omit({ ...rest }, ['navigate']);
  return (
    <Box className={outerClassnames} {...filteredRestProps} display={display}>
      <Box className={innerClassnames} display={display}>
        {children}
      </Box>
    </Box>
  );
};

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import * as ViewModel from '@webViewModels/Pages/Study/SmallPartsSummaryTab';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { Popover } from '@webMolecules/Popover/Popover';
import { Button } from '@webMolecules/Button/Button';
import { TiRadModal } from '@webOrganisms/TiRadModal/TiRadModal';
import { InfoButton } from '@webMolecules/InfoButton/InfoButton';
import {
  selectFindingTableConfig,
  selectImpressions,
  selectStudyType,
} from '@selectors/SessionSelectors';
import { ThemeContainer } from '@webAtoms/Theme/ThemeContainer';
import { selectIncludedFindings } from '@selectors/FindingSelectors';
import { StudyType } from '@entities/StudyType';
import { selectConfig } from '@selectors/BaseSelectors';
import SmallPartsStudyWorkSheet from '../OffScreenWorkSheetPDFGenerator/SmallPartsStudyWorkSheet';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import { FinaliseButton } from '../Sonographer/FinaliseButton';
import styles from './SmallPartsSummaryTab.scss';
import { formatFindings } from './summaryHelper';

const SmallPartsSummaryTab: React.FC<ViewModel.SmallPartsSummaryTab> = ({
  metadata,
}) => {
  const studyImpressions = useSelector(selectImpressions);
  const includedFindings = useSelector(selectIncludedFindings);
  const institutionConfig = useSelector(selectConfig);
  const findingConfig = useSelector(selectFindingTableConfig);
  const studyType = useSelector(selectStudyType);

  if (!findingConfig) return null;
  const findings = formatFindings(
    includedFindings,
    findingConfig.config,
    institutionConfig
  );

  return (
    <MiniCanvasProvider>
      <Box display="flex" fullWidth>
        <Box
          paddingX="xl"
          paddingY="m"
          className={styles.findingPanel}
          scrollable="y"
        >
          <Box display="flex" flexDirection="column" fullWidth>
            {studyType === StudyType.SmallPartsThyroid && (
              <Box className={styles.tiradModalBtn} marginTop="xl">
                <TiRadModalButton />
              </Box>
            )}

            <Box margin="auto">
              <ThemeContainer name={'light'}>
                <SmallPartsStudyWorkSheet />
              </ThemeContainer>
            </Box>
          </Box>
        </Box>

        <Box
          flex="1"
          className={styles.findingPanel}
          paddingLeft="s"
          paddingRight="xl"
          paddingTop="m"
          scrollable="y"
        >
          <Box
            display="flex"
            flexDirection="column"
            className={styles.fullHeight}
          >
            <Box flex="1">
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Box marginRight="s">
                    <Icon
                      name="stethoscope"
                      size="large"
                      color="var(--ds-text-color-muted)"
                    />
                  </Box>
                  <Text display="block" type="display2">
                    {t('pages.study.worksheet.heading.radiologist_report')}
                  </Text>
                  <Box display="flex" flex="1" justifyContent="flex-end">
                    <Popover
                      trigger={
                        <Button
                          iconBefore={
                            <Icon
                              name="help-circle"
                              color="var(--ds-text-color-muted)"
                            />
                          }
                          size="small"
                          circle
                        />
                      }
                      position="right-start"
                      size="medium"
                      showArrow
                    >
                      <Box display="flex" flexDirection="column" gap="xs">
                        <Text type="body2">
                          {t(
                            'study.summary_tab.notes.impression.message.line1'
                          )}
                        </Text>
                      </Box>
                    </Popover>
                  </Box>
                </Box>

                <Box className={styles.radiologistReportSection}>
                  <Text display="block" type="display3">
                    {t('pages.study.worksheet.heading.clinical_findings')}
                  </Text>
                  <Text>{metadata.decryptedClinicalFindings || ''}</Text>
                </Box>

                <Box className={styles.radiologistReportSection}>
                  <Text display="block" type="display3">
                    {t('pages.study.worksheet.label.findings')}
                  </Text>
                  <Box element="ul">
                    {findings.map((finding, i) => {
                      return (
                        <Box element="li" key={i}>
                          <Text display="block" type="body2">
                            {finding}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                <Box className={styles.impressionCalloutBody}>
                  <Text display="block" type="display3">
                    {t('pages.study.worksheet.label.study_impressions')}
                  </Text>
                  <Text display="block" type="body2">
                    {studyImpressions}
                  </Text>
                </Box>
              </Box>
            </Box>

            <Box className={styles.finaliseBtnBox}>
              <Box className={styles.finaliseBtnWidth}>
                <FinaliseButton />
              </Box>
              <Text type="caption" textColor="low">
                {t('pages.study.worksheet.quality_control_msg')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </MiniCanvasProvider>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(SmallPartsSummaryTab);

const TiRadModalButton = ({}) => {
  const [showModal, setShowModal] = React.useState(false);

  <TiRadModal isOpen={showModal} onClose={() => setShowModal(false)} />;

  return (
    <Box display="flex" justifyContent="space-between" gap="s">
      <InfoButton
        type="modal"
        icon="info"
        component={TiRadModal}
        label={t(`tirad.modal.heading`)}
      />
    </Box>
  );
};

import * as AppSettings from './AppSettings';
import * as AnalysisUploads from './AnalysisUploads';
import * as Notifications from './Notifications';
import * as Search from './Search';
import * as Session from './Session';
import * as Admin from './Admin';
import * as Worklist from './Worklist';
import * as PACS from './PACS';
import { Profile } from './Profile';

export interface App {
  profile: Profile;
  appSettings: AppSettings.AppSettings;
  analysisUploads: AnalysisUploads.AnalysisUploads;
  notifications: Notifications.Notifications;
  search: Search.Search;
  session: Session.Session;
  admin: Admin.Admin;
  worklist: Worklist.Worklist;
  pacs: PACS.PACS;
  debug: { dicom: boolean; showBoundingBoxConfidence: boolean };
}

export const initial = (profile: Profile): App => ({
  profile,
  appSettings: AppSettings.initial(),
  analysisUploads: AnalysisUploads.initial(),
  admin: Admin.initial(),
  notifications: Notifications.initial(),
  search: Search.initial(),
  session: Session.initial(),
  worklist: Worklist.initial(),
  pacs: PACS.initial(),
  debug: { dicom: false, showBoundingBoxConfidence: false },
});

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { titleCase } from '@helpers/string';
import {
  getI18NKeyForCharacteristics,
  ThyroidGeneralCharacteristics,
  ThyroidGeneralCharacteristicsType,
  ThyroidLobeCharacteristicsData,
} from '@entities/ThyroidGeneralCharacteristics';
import { Size, Volume } from '@entities/Finding';
import { GeneralCharacteristicsConfig } from '@config/ResultTable/Thyroid';
import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';
import { getUnitI18nKey } from '@entities/Unit';
import { NormalAbnormalValue } from '@entities/Characteristics';
import styles from './GeneralCharacteristicsOverlay.scss';

export interface GeneralCharacteristicsOverlayProps {
  generalCharacteristics?: ThyroidGeneralCharacteristics;
}

const getLobeSize = (lobe: ThyroidLobeCharacteristicsData) => {
  const configUnit = GeneralCharacteristicsConfig.size.unit;
  const validSizes = [lobe.width, lobe.height, lobe.length].filter(
    size => size && size.value
  ) as Size[];

  const previewValue = validSizes.length
    ? [lobe.width, lobe.height, lobe.length]
        .map(size =>
          size && size.value
            ? roundValueByUnit(
                convertUnit(size.unit, configUnit, size.value),
                configUnit
              )
            : ' - '
        )
        .join(' x ')
    : '-';

  return Boolean(previewValue) ? (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Text type="body2" marginRight="s">
        {previewValue}
      </Text>
      <Text type="body2" muted>
        {t(getUnitI18nKey(configUnit))}
      </Text>
    </Box>
  ) : null;
};

const getVolumeText = (volume: Volume | null) => {
  const configUnit = GeneralCharacteristicsConfig.volume.unit;

  const previewValue = volume
    ? roundValueByUnit(
        convertUnit(volume.unit, configUnit, volume.value),
        configUnit
      )
    : '-';

  return (
    <>
      <Text type="body2" marginRight="s">
        {previewValue}
      </Text>
      <Text type="body2" muted>
        {t(getUnitI18nKey(configUnit))}
      </Text>
    </>
  );
};

const getSizeText = (size: Size | null) => {
  const configUnit = GeneralCharacteristicsConfig.size.unit;

  const previewValue =
    size && size.value
      ? roundValueByUnit(
          convertUnit(size.unit, configUnit, size.value),
          configUnit
        )
      : '-';

  return (
    <>
      <Text type="body2" marginRight="s">
        {previewValue}
      </Text>
      <Text type="body2" muted>
        {t(getUnitI18nKey(configUnit))}
      </Text>
    </>
  );
};

const getCharacteristicsText = (
  characteristics: ThyroidGeneralCharacteristicsType | null
) => {
  if (!characteristics) return '-';
  const i18NKey = getI18NKeyForCharacteristics(characteristics);

  const previewValue = i18NKey ? t(i18NKey) : '-';
  return titleCase(previewValue);
};

export const GeneralCharacteristicsOverlay: React.FC<
  GeneralCharacteristicsOverlayProps
> = ({ children, generalCharacteristics }) => {
  if (!generalCharacteristics) return <>{children}</>;

  return (
    <Box
      onClick={e => e.stopPropagation()}
      paddingX="xs"
      paddingTop="xs"
      fullWidth
    >
      <Box className={styles.grid}>
        <Box></Box>
        <Text type="display3">
          {t('config_key.result_table.thyroid_finding.options.body_side_right')}
        </Text>
        <Box display="flex" justifyContent="center">
          <Text type="display3">
            {t(
              'config_key.result_table.thyroid_finding.options.body_side_median'
            )}
          </Text>
        </Box>
        <Text type="display3">
          {t('config_key.result_table.thyroid_finding.options.body_side_left')}
        </Text>

        <Text type="display6">{t('result_table.heading.key.size')}</Text>
        <Box>{getLobeSize(generalCharacteristics.rightLobe)}</Box>
        <Box
          display="flex"
          justifyContent="center"
          className={styles.isthmusColumn}
        >
          <Box className={styles.isthmusSize}>
            {getSizeText(generalCharacteristics.isthmusThickness)}
          </Box>

          {children}
        </Box>
        <Box>{getLobeSize(generalCharacteristics.leftLobe)}</Box>

        <Text type="display6">{t('result_table.heading.key.volume')}</Text>
        <Box>{getVolumeText(generalCharacteristics.rightLobe.volume)}</Box>
        <Box>{getVolumeText(generalCharacteristics.leftLobe.volume)}</Box>

        <Text type="display6">{t('result_table.heading.key.vascularity')}</Text>
        {[
          generalCharacteristics.rightLobe,
          generalCharacteristics.leftLobe,
        ].map((lobe, i) => {
          return (
            <Text type="body2" key={i}>
              {getCharacteristicsText(lobe.vascularity)}
            </Text>
          );
        })}

        <Text type="display6">{t('result_table.heading.key.echotexture')}</Text>
        {[
          generalCharacteristics.rightLobe,
          generalCharacteristics.leftLobe,
        ].map((lobe, i) => {
          return (
            <Text type="body2" key={i}>
              {getCharacteristicsText(lobe.echotexture)}
            </Text>
          );
        })}

        <Text type="display6">{t('study.result_table.retrosternal_ext')}</Text>
        {[
          generalCharacteristics.rightLobe,
          generalCharacteristics.leftLobe,
        ].map((lobe, i) => {
          return (
            <Text type="body2" key={i}>
              {getCharacteristicsText(lobe.retrosternal)}
            </Text>
          );
        })}
      </Box>

      <Box
        marginTop="s"
        paddingY="s"
        className={styles.bottomSection}
        display="flex"
        gapColumn="m"
      >
        <Box className={styles.rightBordered} paddingRight="m">
          <Text type="display6" marginRight="s">
            {t('study.result_table.tracheal_deviation')}
          </Text>
          <Text type="body2">
            {getCharacteristicsText(generalCharacteristics.trachealDeviation)}
          </Text>
        </Box>

        <Box className={styles.overflow}>
          <Text type="display6" marginRight="s">
            {t('study.result_table.lymph_nodes_normality')}
          </Text>
          <Text type="body2">
            {getCharacteristicsText(generalCharacteristics.lymphNodes)}
          </Text>
          {generalCharacteristics.lymphNodes == NormalAbnormalValue.Abnormal &&
            generalCharacteristics.abnormalLymphNodesText && (
              <Text type="body2">
                {`, ${generalCharacteristics.abnormalLymphNodesText}`}
              </Text>
            )}
        </Box>
      </Box>
    </Box>
  );
};

import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Field, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Container } from '@webMolecules/Container/Container';
import { Text } from '@webMolecules/Text/Text';
import { Profile } from '@interfaces/Profile';
import { Stack } from '@webMolecules/Stack/Stack';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Button } from '@webMolecules/Button/Button';
import { Label } from '@webMolecules/Label/Label';
import { t, tPrint } from '@webInterfaces/I18n';
import {
  FormHint,
  formHintIntentFromString,
} from '@webOrganisms/FormHint/FormHint';
import { Message } from '@interfaces/Message';
import styles from './profile.scss';

export interface ProfileProps {
  availableLocales: string[];
  currentLocale: string;
  profile: Profile;
  onUpdate: (profile: Profile) => void;
  message: Message | undefined;
}

export interface ProfileFormProps {
  firstName: string;
  lastName: string;
  swatchColour: string;
  dopplerMethod: string;
  locale: string;
}

export const UserProfile: React.FC<ProfileProps> = ({
  profile,
  availableLocales,
  currentLocale,
  onUpdate,
  message,
}) => {
  const [formSaved, setFormSaved] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState<boolean>(false);

  const colorInputRef = React.useRef<HTMLInputElement>(null);
  const ProfileSettingsSchema = Yup.object().shape({
    firstName: Yup.string().required(
      tPrint(
        'generic.validation.required.field',
        t('pages.settings.profile.form.first_name')
      )
    ),
    lastName: Yup.string().required(
      tPrint(
        'generic.validation.required.field',
        t('pages.settings.profile.form.last_name')
      )
    ),
  });
  const formProps = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    swatchColour: profile.swatchColour,
    locale: profile.preferences.locale || currentLocale,
  } as ProfileFormProps;

  useEffect(() => {
    if (formLoading && message) {
      setFormLoading(false);
    }
  }, [message]);

  const handleFormSubmit = (form: ProfileFormProps) => {
    const updatedProfile = {
      ...profile,
      firstName: form.firstName,
      lastName: form.lastName,
      swatchColour: form.swatchColour,
      preferences: {
        ...profile.preferences,
        locale: form.locale,
      },
    };
    setFormSaved(true);
    setFormLoading(true);
    onUpdate(updatedProfile);
  };

  const handleFormChange = () => {
    formSaved ? setFormSaved(false) : null;
  };

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom="xl">
        <Text type="display1" display="block" marginRight="l">
          Profile
        </Text>
      </Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={formProps}
        validationSchema={ProfileSettingsSchema}
      >
        {formProps => (
          <Form onChange={handleFormChange}>
            <Container centered={false} size="xxxs">
              <Stack spacing="l" direction="vertical">
                <Box>
                  <Label htmlFor="firstName" marginBottom="xs">
                    {t('pages.settings.profile.form.first_name')}
                  </Label>
                  <FormInput
                    type={FormInputTypes.Text}
                    error={formProps.errors.firstName}
                    id="firstName"
                    name="firstName"
                    data-testid="settingsprofile-firstname"
                    placeholder={t('pages.settings.profile.form.first_name')}
                    touched={formProps.touched.firstName}
                    maxLength="64"
                    fluid
                  />
                  <ErrorMessage
                    name="firstName"
                    render={msg => (
                      <FormFieldHint intent="error" marginTop="xs">
                        {msg}
                      </FormFieldHint>
                    )}
                  />
                </Box>

                <Box>
                  <Label htmlFor="lastName" marginBottom="xs">
                    {t('pages.settings.profile.form.last_name')}
                  </Label>
                  <FormInput
                    type={FormInputTypes.Text}
                    error={formProps.errors.lastName}
                    id="lastName"
                    name="lastName"
                    data-testid="settingsprofile-lastname"
                    placeholder={t('pages.settings.profile.form.last_name')}
                    maxLength="64"
                    touched={formProps.touched.lastName}
                    fluid
                  />
                  <ErrorMessage
                    name="lastName"
                    render={msg => (
                      <FormFieldHint intent="error" marginTop="xs">
                        {msg}
                      </FormFieldHint>
                    )}
                  />
                </Box>

                <Box data-testid="settingsprofile-swatchcolour-wrapper">
                  <Label htmlFor="swatchColour" marginBottom="xs">
                    {t('pages.settings.profile.form.avatar_colour')}
                  </Label>
                  <button
                    type="button"
                    className={styles.swatch}
                    style={{ backgroundColor: formProps.values.swatchColour }}
                    onClick={() => {
                      colorInputRef.current?.click();
                    }}
                  />
                  <Field
                    className={styles.hiddenColorInput}
                    innerRef={colorInputRef}
                    type="color"
                    name="swatchColour"
                    id="swatchColour"
                    data-testid="settingsprofile-swatchcolour"
                  />
                </Box>

                <Box>
                  <Label htmlFor="locale" marginBottom="xs">
                    {t('pages.settings.profile.form.locale')}
                  </Label>
                  <FormInput
                    type={FormInputTypes.Select}
                    error={formProps.errors.locale}
                    id="locale"
                    name="locale"
                    options={availableLocales.map(a => ({
                      value: a,
                      label: t(`i18n.locales.${a}`),
                    }))}
                    fluid
                    data-testid="settingsprofile-locale"
                  />
                </Box>

                <Box>
                  <Button
                    loading={formLoading}
                    type="submit"
                    data-testid="settingsprofile-submit"
                    intent="primary"
                    strong
                    pill
                  >
                    {t('pages.settings.profile.form.save')}
                  </Button>
                  {formSaved && message && (
                    <Box marginTop="l" data-testid="settingsprofile-success">
                      <FormHint
                        intent={formHintIntentFromString(message.intent)}
                      >
                        {t(message.key)}
                      </FormHint>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserProfile;

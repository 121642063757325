import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Avatar } from '@webMolecules/Avatar/Avatar';
import { Text } from '@webMolecules/Text/Text';
import { Input } from '@webMolecules/Input/Input';
import { Icon } from '@webMolecules/Icon/Icon';
import FilterListItem from '@webOrganisms/FilterListItem/FilterListItem';
import { tPrint } from '@webInterfaces/I18n';
import styles from './styles.scss';

export interface FilterListLineItem {
  id: string;
  title: string;
  matchString: string;
  swatchColour: string;
  added: boolean;
}

export interface FilterListProps {
  placeholder: string;
  subtitle: string;
  showAvatar: boolean;
  items: FilterListLineItem[];
  onClose?: () => void;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

export const FilterList: React.FC<FilterListProps> = ({
  placeholder,
  subtitle,
  showAvatar,
  items,
  onAdd,
  onRemove,
}: FilterListProps) => {
  const [filterString, setFilterString] = React.useState<string>('');

  const filterStrings = filterString.toLowerCase().split(' ');

  const filteredItems = items.reduce(
    (acc: FilterListLineItem[], item: FilterListLineItem) => {
      const matchStrings = item.matchString.toLowerCase().split(' ');
      for (const filterString of filterStrings) {
        for (const matchString of matchStrings) {
          if (matchString.includes(filterString)) {
            acc.push(item);
            return acc;
          }
        }
      }
      return acc;
    },
    [] as FilterListLineItem[]
  );

  return (
    <Box>
      <Input
        data-testid="filter-modal-input"
        id="filter"
        name="filter"
        defaultValue={''}
        fluid
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFilterString(event.target.value);
        }}
        iconBefore={<Icon name="search" />}
      />
      <Box marginTop="xs">
        <Text data-testid="filter-modal-subtitles" muted type="caption">
          {subtitle}
        </Text>
      </Box>
      <Box className={styles.scroll} marginTop="l" padding="m" scrollable="y">
        {filteredItems.length > 0 ? (
          filteredItems.map(item => (
            <FilterListItem
              key={item.id}
              icon={
                showAvatar ? (
                  <Avatar
                    src=""
                    name={item.title}
                    color={item.swatchColour}
                    size="small"
                  />
                ) : undefined
              }
              id={item.id}
              title={item.title}
              added={item.added}
              onAdd={onAdd}
              onRemove={onRemove}
            ></FilterListItem>
          ))
        ) : (
          <Box alignItems="center" display="flex" justifyContent="center">
            <Text type="body" muted>
              {tPrint('filter.modal.no.result.found', {
                '%query': filterString,
              })}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FilterList;

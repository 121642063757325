import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as ViewModel from '@webViewModels/Pages/Study/FindingTable';
import { FindingsTable } from '@webOrganisms/ResultTable/FindingsTable/FindingsTable';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Box } from '@webMolecules/Box/Box';
import { defaultGalleryFilter } from '@interfaces/GalleryFilter';

const FindingsTableWrapper: React.FC<ViewModel.FindingTable> = ({
  loading,
  resultTableStyle,
  applyFindingFilter,
  featureFlags,
}) => {
  return (
    <ResultTableContext.Provider
      value={{
        galleryFilter: defaultGalleryFilter,
        setGalleryFilter: () => null,
        theme: resultTableStyle,
        applyFindingFilter,
      }}
    >
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '900px' }}
        >
          <LoadingIcon />
        </Box>
      ) : (
        <Box>
          <FindingsTable
            featureFlags={featureFlags}
            applyFindingFilter={applyFindingFilter}
            disableInteractions={resultTableStyle === ResultTableStyle.preview}
          />
        </Box>
      )}
    </ResultTableContext.Provider>
  );
};

const MemoizedFindingsTableWrapper = React.memo(FindingsTableWrapper, isEqual);

export default connect(ViewModel.fromState)(MemoizedFindingsTableWrapper);

import React from 'react';
import tinycolor from 'tinycolor2';
import { cnames } from '@helpers/cnames';
import { getFirstChar } from '@helpers/string';
import styles from './avatar.scss';
import { Box } from './../Box/Box';
import { Image } from './../Image/Image';
import { Text } from './../Text/Text';

export interface AvatarProps {
  size?: 'small' | 'medium' | 'large';
  name?: string;
  color?: string;
  src?: string;
  borderColor?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  size = 'medium',
  color,
  src,
  name,
  borderColor,
}) => {
  const lightColor = tinycolor(color).isLight();
  const classNames = cnames(styles[size], styles.avatar, {
    [styles.light]: lightColor,
  });

  const displayName = name ? getFirstChar(name) : null;
  const backgroundColor = src
    ? undefined
    : color
      ? color
      : 'var(--ds-background-color-alt-200)';

  const border = borderColor ? `0 0 0 2px ${borderColor}` : undefined;

  return (
    <Box
      data-testid="avatar-test-id"
      className={classNames}
      display="flex"
      alignItems="center"
      justifyContent="center"
      aria-label="avatar-name"
      style={{ backgroundColor: backgroundColor, boxShadow: border }}
    >
      {src ? (
        <Image
          data-testid="descendant-img"
          circle
          src={src}
          role="presentation"
        />
      ) : (
        <Text
          data-testid="descendant-text"
          type={
            size === 'small'
              ? 'caption'
              : size === 'medium'
                ? 'display6'
                : 'display5'
          }
        >
          {displayName}
        </Text>
      )}
    </Box>
  );
};

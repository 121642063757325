import React from 'react';
import isEqual from 'lodash/isEqual';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import {
  leftThyroidLobe,
  otherTable,
  rightThyroidLobe,
} from '@config/ResultTable/Thyroid';
import { Icon } from '@webMolecules/Icon/Icon';
import { Button } from '@webMolecules/Button/Button';
import { Text } from '@webMolecules/Text/Text';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { selectThyroidGeneralCharacteristics } from '@selectors/SessionSelectors';
import { t } from '@webInterfaces/I18n';
import { sessionUseCase } from '@useCases/Session';
import { useDispatcher } from '@helpers/useDispatcher';
import { DiscardChangesDialog } from '@webOrganisms/ResultTable/FindingsTable/DetailedFindingsTableDialogs';
import { GalleryFilter } from '@interfaces/GalleryFilter';
import styles from './GeneralCharacteristicsTable.scss';
import { GeneralCharacteristicsTableSection } from './GeneralCharacteristicsTableSection';

export interface GeneralCharacteristicsTableProps {
  setShowTable: (show: boolean) => void;
  filters: GalleryFilter;
  setGalleryFilter: React.Dispatch<React.SetStateAction<GalleryFilter>>;
}

export const GeneralCharacteristicsTable: React.FC<
  GeneralCharacteristicsTableProps
> = ({ setShowTable, filters, setGalleryFilter }) => {
  const thyroidGeneralCharacteristics = useSelector(
    selectThyroidGeneralCharacteristics
  );

  if (!thyroidGeneralCharacteristics) {
    return null;
  }

  const tableConfigs = [rightThyroidLobe, leftThyroidLobe, otherTable];

  const updateThyroidGeneralCharac = useDispatcher(
    sessionUseCase.UpdateThyroidGeneralCharacteristics
  );
  const [showWarning, setShowWarning] = React.useState(false);
  const methods = useForm<ThyroidGeneralCharacteristics>({
    defaultValues: thyroidGeneralCharacteristics,
  });

  const handleSave = () => {
    const updatedGeneralCharacteristic = methods.getValues();
    updateThyroidGeneralCharac({
      thyroidGeneralCharacteristic: updatedGeneralCharacteristic,
    });

    setShowTable(false);
  };

  const handleClose = () => {
    const isDirty = !isEqual(
      methods.getValues(),
      methods.formState.defaultValues
    );

    if (isDirty) {
      setShowWarning(true);
    } else {
      setShowTable(false);
    }
  };

  const handleDiscardConfirm = () => {
    setShowTable(false);
    setShowWarning(false);
  };

  return (
    <FormProvider {...methods}>
      <Box className={styles.tableBox}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          fullWidth
          padding="m"
          className={styles.tableTitle}
        >
          <Button
            circle
            strong
            size="medium"
            onClick={handleClose}
            iconBefore={<Icon name="x" />}
          />
          <Text type="display2" marginLeft="m">
            {t('general_characteristic_table.heading.edit')}
          </Text>
          <Box
            display="inline-block"
            marginLeft="xs"
            className={styles.headingText}
          >
            {t('general_characteristic_table.heading.thyroid_measurements')}
          </Box>
        </Box>
        <Box flex="1" scrollable="y">
          {tableConfigs.map((table, i) => {
            return table.sections.map(section => {
              return (
                <Box
                  key={i}
                  marginX="m"
                  marginY="s"
                  className={styles.sectionTable}
                >
                  <GeneralCharacteristicsTableSection
                    config={section}
                    filters={filters}
                    setGalleryFilter={setGalleryFilter}
                  />
                </Box>
              );
            });
          })}
        </Box>

        <Box display="flex" padding="m" className={styles.footer} gap="m">
          <Button strong pill onClick={handleClose}>
            {t('generic.submit.cancel')}
          </Button>
          <Button strong pill onClick={handleSave} intent="primary">
            {t('detailed_finding_table.button.save')}
          </Button>
        </Box>

        <DiscardChangesDialog
          isOpen={showWarning}
          onConfirm={handleDiscardConfirm}
          onCancel={() => setShowWarning(false)}
        />
      </Box>
    </FormProvider>
  );
};

import { Profile } from '@interfaces/Profile';
import { Storage } from './Storage';

export enum AuthenticateResultType {
  AuthenticateResultSuccess = 'authenticate_result_type--success',
  AuthenticateResultNewPasswordRequired = 'authenticate_result_type--new_password_required',
  AuthenticateResultMFACodeRequired = 'authenticate_result_type--mfa_code_required',
  AuthenticateResultError = 'authenticate_result_type--error',
}

export type AuthenticateResult =
  | AuthenticateResultSuccess
  | AuthenticateResultNewPasswordRequired
  | AuthenticateResultMFACodeRequired
  | AuthenticateResultError;

export enum AuthenticateError {
  AuthenticateErrorIncorrectEmail = 'authenticate_error--incorrect_email',
  AuthenticateErrorLimitExceededError = 'authenticate_error--attempt_limit_exceeded',
  AuthenticateErrorIncorrectEmailOrPassword = 'authenticate_error--incorrect_email_or_password',
  AuthenticateErrorPasswordResetRequired = 'authenticate_error--password_reset_required',
  AuthenticateErrorAccountCreateRequired = 'authenticate_error--account_create_required',
  AuthenticateErrorAccountAlreadyCreated = 'authenticate_error--account_already_created',
  AuthenticateErrorGeneric = 'authenticate_error--generic',
}

export interface AuthenticateResultSuccess {
  type: AuthenticateResultType.AuthenticateResultSuccess;
  token: string;
  email: string;
  profile: Profile;
  security: {
    hasMFAEnabled: boolean;
  };
}

export const makeAuthenticateResultSuccess = (
  token: string,
  email: string,
  profile: Profile,
  hasMFAEnabled: boolean
): AuthenticateResultSuccess => ({
  type: AuthenticateResultType.AuthenticateResultSuccess,
  token,
  email,
  profile,
  security: {
    hasMFAEnabled,
  },
});

export interface AuthenticateResultNewPasswordRequired {
  type: AuthenticateResultType.AuthenticateResultNewPasswordRequired;
  currentPassword: string;
  token: unknown;
}

export const makeAuthenticateResultNewPasswordRequired = (
  token: unknown,
  currentPassword: string
): AuthenticateResultNewPasswordRequired => ({
  type: AuthenticateResultType.AuthenticateResultNewPasswordRequired,
  currentPassword,
  token,
});

export interface AuthenticateResultMFACodeRequired {
  type: AuthenticateResultType.AuthenticateResultMFACodeRequired;
  token: unknown;
}

export const makeAuthenticateResultMFACodeRequired = (
  token: unknown
): AuthenticateResultMFACodeRequired => ({
  type: AuthenticateResultType.AuthenticateResultMFACodeRequired,
  token,
});

export interface AuthenticateResultError {
  type: AuthenticateResultType.AuthenticateResultError;
  error: AuthenticateError;
}

export const makeAuthenticateResultError = (
  error: AuthenticateError
): AuthenticateResultError => ({
  type: AuthenticateResultType.AuthenticateResultError,
  error,
});

export interface Authenticate {
  InitAmplify(): Promise<boolean>;
  CreateAccount(args: {
    storage: Storage;
    email: string;
    newPassword: string;
    currentPassword: string;
    details: Record<string, string>;
  }): Promise<AuthenticateResult>;
  ResumeSession(storage: Storage): Promise<undefined | AuthenticateResult>;
  SignIn(
    storage: Storage,
    email: string,
    password: string
  ): Promise<AuthenticateResult>;
  FederatedSignIn(storage: Storage): Promise<AuthenticateResult>;
  SignOut(): void;
  ForgotPassword(email: string): Promise<'success'>;
  ForgotPasswordReset(
    email: string,
    code: string,
    newPassword: string
  ): Promise<'success'>;
  ChangePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<AuthenticateResult>;
  UpdateProfile(
    storage: Storage,
    profile: Profile
  ): Promise<AuthenticateResult>;
  GetCurrentSession(): Promise<string | undefined>;
  // GetCurrentUserId(): undefined | string;
  // GetProfile(): Profile | undefined;
  // GetInstitutionId(): string;
  SetUpMFA(): Promise<MFASetupResult>;
  SignInWithMFA(storage: Storage, otp: string): Promise<AuthenticateResult>;
  EnableMFA(token: string): Promise<boolean>;
  DisableMFA(token: string): Promise<boolean>;
}

export enum MFASetupType {
  MFASetupInitialize = 'mfa_process_type--initialize',
  MFASetupSuccess = 'mfa_process_type--success',
  MFASetupFailed = 'mfa_process_type--failed',
}

export type MFASetupResult =
  | MFAProcessInitialize
  | MFAProcessSuccess
  | MFAProcessFailed;

export interface MFAProcessInitialize {
  type: MFASetupType.MFASetupInitialize;
  secretKey: string;
}

export const MFAProcessInitialize = (
  secretKey: string
): MFAProcessInitialize => ({
  type: MFASetupType.MFASetupInitialize,
  secretKey,
});

export interface MFAProcessSuccess {
  type: MFASetupType.MFASetupSuccess;
  message: string;
}

export const MFAProcessSuccess = (message: string): MFAProcessSuccess => ({
  type: MFASetupType.MFASetupSuccess,
  message: message,
});

export interface MFAProcessFailed {
  type: MFASetupType.MFASetupFailed;
  message: string;
}

export const MFAProcessFailed = (message: string): MFAProcessFailed => ({
  type: MFASetupType.MFASetupFailed,
  message: message,
});

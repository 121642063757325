import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { updateUserProfilePreferences } from '@webViewInterfaces/UseCase/Authenticate';
import { toWorklist, toProfile } from '@interfaces/App';
import { toApp } from '@interfaces/Root';
import { Profile, ProfilePreferences } from '@interfaces/Profile';
import { WorkspaceStatus } from '@interfaces/WorkspaceStatus';
import { Workspace } from '@interfaces/Workspace';
import { FilterOptions } from '@interfaces/FilterOptions';
import Maybe from '@monads/Maybe';

export type Sidebar = SidebarProps & SidebarDispatchers;

export interface SidebarProps {
  me: Profile;
  availableWorkspaces: Maybe<Workspace[]>;
  availableProfiles: Profile[];
  workspaceStatus: WorkspaceStatus[];
  filterOptions?: FilterOptions;
}

export interface SidebarDispatchers {
  dispatchUpdateUserProfilePreferences: (
    profilePreferences: ProfilePreferences
  ) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  dispatchUpdateUserProfilePreferences: (
    profilePreferences: ProfilePreferences
  ) => dispatch(updateUserProfilePreferences(profilePreferences)),
});

export const fromState = (state: State): SidebarProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }
  const worklist = toWorklist(app);
  const profile = toProfile(app);

  const { availableWorkspaces, workspaceStatus, profiles, filterOptions } =
    worklist;

  return {
    me: profile,
    availableWorkspaces: availableWorkspaces.second(),
    availableProfiles: profiles.second().filter(a => a.isActive),
    workspaceStatus: workspaceStatus.second(),
    filterOptions: filterOptions.lift(),
  };
};

import {
  BreastGalleryFilter,
  SpecificGalleryFilter,
  ThyroidGalleryFilter,
} from '@config/Gallery/SpecificGalleryFilter';
import { StudyType } from '@entities/StudyType';

export interface GalleryFilter {
  findingIds: string[];
  specificFilter?: SpecificGalleryFilter;
}

export const defaultGalleryFilter: GalleryFilter = {
  findingIds: [],
};

export const getDefaultGalleryFilter = (
  studyType: StudyType | undefined,
  isRadiologistWorkflow: boolean
): GalleryFilter => {
  if (studyType == StudyType.SmallPartsThyroid) {
    return {
      ...defaultGalleryFilter,
      specificFilter: isRadiologistWorkflow
        ? ThyroidGalleryFilter.IncludingDetections
        : ThyroidGalleryFilter.All,
    };
  } else if (studyType == StudyType.SmallPartsBreast) {
    return {
      ...defaultGalleryFilter,
      specificFilter: isRadiologistWorkflow
        ? BreastGalleryFilter.IncludingDetections
        : BreastGalleryFilter.All,
    };
  }

  return defaultGalleryFilter;
};

export const omit = (
  obj: Record<string, unknown>,
  filterKeys: Array<string>
): Record<string, unknown> => {
  const keys = Object.keys(obj);
  return keys.reduce(
    (
      prev: Record<string, unknown>, //
      k: string
    ): Record<string, unknown> => {
      if (filterKeys.includes(k) === false) {
        prev[k] = obj[k];
      }
      return prev;
    },
    {}
  );
};

export default omit;

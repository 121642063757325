import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Modal } from '@webMolecules/Modal/Modal';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import { Table, TableCell, TableRow } from '@webMolecules/Table/Table';
import styles from './tiRadModal.scss';

interface TiRadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TiRadTable = () => {
  const columns = [
    { label: '' },
    { label: t('tirad.column.header.one') },
    { label: t('tirad.column.header.two') },
    { label: t('tirad.column.header.three') },
    { label: t('tirad.column.header.four') },
    { label: t('tirad.column.header.five') },
  ];
  const rows: Record<string, string>[] = [
    {
      colZero: t('tirad.row.title.one'),
      colOne: '0',
      colTwo: '1-2',
      colThree: '3',
      colFour: '4-6',
      colFive: '7+',
    },
    {
      colZero: t('tirad.row.title.two'),
      colOne: t('tirad.row.description.one'),
      colTwo: t('tirad.row.description.two'),
      colThree: t('tirad.row.description.three'),
      colFour: t('tirad.row.description.four'),
      colFive: t('tirad.row.description.five'),
    },
    {
      colZero: t('tirad.row.title.three'),
      colOne: t('tirad.row.message.no'),
      colTwo: t('tirad.row.message.no'),
      colThree: '≥2.5cm',
      colFour: '≥1.5cm',
      colFive: '≥1.0cm',
    },
    {
      colZero: t('tirad.row.title.four'),
      colOne: t('tirad.row.message.no'),
      colTwo: t('tirad.row.message.no'),
      colThree: '≥1.5cm',
      colFour: '≥1.0cm',
      colFive: '≥0.5cm',
    },
  ];
  return (
    <Table bordered padded={true}>
      <TableRow>
        {columns.map((heading, colIndex) => (
          <TableCell key={heading.label} bordered shaded={colIndex == 0}>
            <Text lineClamp={1} display="block" element="h1" type="display5">
              {t(heading.label)}&nbsp;
            </Text>
          </TableCell>
        ))}
      </TableRow>
      {rows.map((row, rowIndex) => (
        <TableRow key={`ti-rad-table-${rowIndex}`}>
          {Object.keys(row).map((col, colIndex) => (
            <TableCell
              key={`ti-rad-table-${rowIndex}-${colIndex}`}
              bordered
              shaded={colIndex == 0}
            >
              <Text
                muted
                lineClamp={1}
                display="block"
                element={colIndex == 0 ? 'h1' : 'span'}
                type={colIndex == 0 ? 'display5' : 'body2'}
              >
                {row[col]}&nbsp;
              </Text>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

export const TiRadModal = ({ isOpen, onClose }: TiRadModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">
      <Card padding="none" elevation="medium">
        <Box
          data-testid="tirad-modal"
          className={styles.modalBody}
          padding="xl"
        >
          <Box className={styles.modalClose} padding="s">
            <Button
              data-testid="test-tirad-modal-close"
              onClick={onClose}
              iconBefore={<Icon name="x" />}
              circle
              strong
            />
          </Box>
          <Stack spacing="l">
            <Text type="display2" display="block">
              {t('tirad.modal.heading')}
            </Text>
            <Text type="body2">{t('tirad.modal.body')}</Text>
            <Text element="h1" type="display2">
              {t('tirad.modal.grading-title')}
            </Text>
            <TiRadTable />
          </Stack>
        </Box>
        <Box
          padding="m"
          display="flex"
          gap="m"
          justifyContent="flex-end"
          flexDirection="column"
          className={styles.dialogFooter}
        >
          <Box display="flex" gap="m" justifyContent="flex-end">
            <Button onClick={onClose} strong>
              {t('generic.submit.close')}
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

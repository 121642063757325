import { v4 as uuid } from 'uuid';
import {
  AnalysisUploadsLineItem,
  DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT,
  DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS,
} from '@entities/AnalysisUploadsLineItem';
export {
  AnalysisUploadsLineItem,
  AnalysisUploadsLineItemFileError,
  DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT,
  DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS,
} from '@entities/AnalysisUploadsLineItem';

export const makeAnalysisUploadsLineItem = (
  filesTotal: number
): AnalysisUploadsLineItem => ({
  type: DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT,
  localBatchId: uuid(),
  filesTotal,
  filesUploaded: 0,
  filesAnonymised: 0,
  filesProcessed: 0,
  failed: false,
  errors: [],
  autoRedirect: false,
});

export const makeAnalysisReanalyseLineItem = (
  batchId: string,
  autoRedirect: boolean
): AnalysisUploadsLineItem => ({
  type: DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS,
  localBatchId: batchId,
  filesTotal: 1,
  failed: false,
  filesProcessed: 0,
  autoRedirect,
});

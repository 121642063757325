import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Stack } from '@webMolecules/Stack/Stack';
import { t } from '@webInterfaces/I18n';
import { PASSWORD_REGEX } from '@webViews/constant';

export interface SignInProps {
  /**
   * onSubmit sign in callback
   */
  onSubmit: (credentials: { email: string; password: string }) => void;
  onSubmitWithMFA: (otp: string) => void;
  onCancelMFA: () => void;
  /**
   * Form is loading
   */
  loading?: boolean;
  hasMFAEnabled?: boolean;
}

export const SignIn = ({
  onSubmit,
  onSubmitWithMFA,
  loading = false,
  hasMFAEnabled,
  onCancelMFA,
}: SignInProps) => {
  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('generic.validation.invalid.email'))
      .required(t('generic.validation.required.email')),
    password: Yup.string()
      .required(t('generic.validation.required.password'))
      .matches(PASSWORD_REGEX, t('generic.validation.invalid.password')),
    mfaCode: Yup.string()
      .min(6, t('generic.validation.invalid.mfa'))
      .max(6, t('generic.validation.invalid.mfa')),
  });

  const initialFormValues = {
    email: '',
    password: '',
    showPassword: false,
    mfaCode: '',
  };

  return (
    <Formik
      onSubmit={
        hasMFAEnabled
          ? ({ mfaCode }) => {
              onSubmitWithMFA(mfaCode);
            }
          : ({ email, password }) => {
              onSubmit({ email, password });
            }
      }
      onReset={() => onCancelMFA()}
      initialValues={initialFormValues}
      validationSchema={SignInSchema}
    >
      {formProps => (
        <Form>
          <Stack spacing="m" direction="vertical">
            <Box>
              <FormInput
                name="email"
                autoComplete="email"
                error={formProps.errors.email}
                placeholder={t('pages.auth.sign_in.placeholder.email')}
                size="large"
                fluid
                disabled={hasMFAEnabled}
                touched={formProps.touched.email}
                type={FormInputTypes.Text}
                data-testid="signin-email"
              />
              <ErrorMessage
                name="email"
                render={msg => (
                  <FormFieldHint intent="error" marginTop="xs">
                    {msg}
                  </FormFieldHint>
                )}
              />
            </Box>

            <Box>
              <FormInput
                name="password"
                autoComplete="current-password"
                error={formProps.errors.password}
                placeholder={t('pages.auth.sign_in.placeholder.password')}
                size="large"
                fluid
                disabled={hasMFAEnabled}
                touched={formProps.touched.password}
                type={
                  formProps.values.showPassword && !hasMFAEnabled
                    ? FormInputTypes.Text
                    : FormInputTypes.Password
                }
                data-testid="signin-password"
              />
              <ErrorMessage
                name="password"
                render={msg => (
                  <FormFieldHint intent="error" marginTop="xs">
                    {msg}
                  </FormFieldHint>
                )}
              />
            </Box>

            {hasMFAEnabled ? (
              <Box>
                <FormInput
                  name="mfaCode"
                  autoComplete="one-time-code"
                  error={formProps.errors.mfaCode}
                  placeholder={t('pages.auth.sign_in.placeholder.mfaCode')}
                  size="large"
                  fluid
                  disabled={loading}
                  touched={formProps.touched.mfaCode}
                  type={FormInputTypes.Text}
                  autoFocus
                />
                <ErrorMessage
                  name="mfaCode"
                  render={msg => (
                    <FormFieldHint intent="error" marginTop="xs">
                      {msg}
                    </FormFieldHint>
                  )}
                />
              </Box>
            ) : null}

            {hasMFAEnabled ? null : (
              <FormInput name="showPassword" type={FormInputTypes.Checkbox}>
                {t('pages.auth.sign_in.show_password')}
              </FormInput>
            )}

            <Box>
              <Button
                loading={loading}
                type="submit"
                intent="primary"
                strong
                fluid
                size="large"
                data-testid="signin-submit"
              >
                {hasMFAEnabled
                  ? t('pages.auth.sign_in.authenticate')
                  : t('pages.auth.sign_in.submit')}
              </Button>

              {!loading && hasMFAEnabled && (
                <Box marginTop="xs">
                  <Button type="reset" fluid>
                    Cancel
                  </Button>
                </Box>
              )}
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

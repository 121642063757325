import { createSelector } from '@reduxjs/toolkit';
import { sortDicomByScanTime } from '@webViewModels/Pages/Study/GalleryHelper';
import { Dicom } from '@entities/Dicom';
import { Finding } from '@entities/Finding';
import { selectSession } from './SessionSelectors';

export const selectDicoms = createSelector(selectSession, session => {
  const dicoms = session.analysis.mapOr(a => a.dicoms, []);
  const sortedDicoms = sortDicomByScanTime(dicoms);
  return sortedDicoms.map(dicom =>
    attachAssignedFindingToDetections(dicom, session.data.findings)
  );
});

export const selectDetections = createSelector(selectDicoms, dicoms =>
  dicoms.flatMap(d => d.detections)
);

export const selectComparisonDicoms = createSelector(selectSession, session => {
  const comparisonSession = session.comparisonSession.lift();
  if (!comparisonSession) {
    return [];
  }
  const sortedDicoms = sortDicomByScanTime(comparisonSession.dicoms);
  return sortedDicoms.map(dicom =>
    attachAssignedFindingToDetections(dicom, comparisonSession.data.findings)
  );
});

function attachAssignedFindingToDetections(dicom: Dicom, findings: Finding[]) {
  return {
    ...dicom,
    detections: dicom.detections.map(detection => {
      return {
        ...detection,
        assignedFinding: findings.find(f =>
          f.detectionIds.includes(detection.id)
        ),
      };
    }),
  };
}

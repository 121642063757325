import get from 'lodash/get';
import { FindingConfig, FindingFieldConfig } from '@config/ResultTable/Shared';
import { Finding } from '@entities/Finding';
import { PillProps } from '@webMolecules/Pill/Pill';
import { getFeatureFlags } from '@entities/FeatureFlag';
import {
  ThyroidFindingComposition,
  ThyroidFindingEchogenicity,
  ThyroidFindingMargin,
} from '@entities/Characteristics';
import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';

interface CharacteristicConfig {
  options: { editableI18NKey: string; value: string; separator?: boolean }[];
  label: string;
  onToggle?(oldValues: string[], newValues: string[]): string[];
}

export type Characteristic = {
  data: string | string[];
  path: string;
  config: CharacteristicConfig;
  dotIntent?: PillProps['dotIntent'];
  displayValue?: string;
};

function getPillFields(
  config: FindingConfig,
  institutionConfig: Required<InstitutionConfiguration>
) {
  const featureFlags = getFeatureFlags();

  return config.sections
    .flatMap(section => section.fields)
    .filter(field => field.showPill && field.path)
    .filter(
      field =>
        field.featureEnabled === undefined ||
        field.featureEnabled(featureFlags, institutionConfig)
    );
}

function getCharacteristicConfig(
  field: FindingFieldConfig
): CharacteristicConfig | null {
  const { inputConfig, label, onToggle } = field;
  if (!inputConfig || typeof inputConfig !== 'object') {
    return null;
  } else if ('options' in inputConfig) {
    return {
      options: inputConfig.options,
      label: label ?? '',
      onToggle,
    };
  } else if (Array.isArray(inputConfig)) {
    return {
      options: inputConfig,
      label: label ?? '',
      onToggle,
    };
  }
  return null;
}

const IndeterminateValues = [
  ThyroidFindingComposition.Indeterminate,
  ThyroidFindingEchogenicity.Indeterminate,
  ThyroidFindingMargin.Indeterminate,
] as const;

function isIndeterminate(value: string) {
  return IndeterminateValues.some(v => v === value);
}

export function getCharacteristicsList(
  finding: Finding,
  findingConfig: FindingConfig,
  institutionConfig: Required<InstitutionConfiguration>
): {
  characteristics: Characteristic[];
  indeterminateCharacteristics: Characteristic[];
} {
  const characteristics: Characteristic[] = [];
  const indeterminateCharacteristics: Characteristic[] = [];

  const fields = getPillFields(findingConfig, institutionConfig);
  for (const field of fields) {
    const path = field.path!;
    const config = getCharacteristicConfig(field);
    const data = get(finding, path);

    if (config && data) {
      if (typeof data === 'string') {
        const dotIntent = getCharacteristicsPillDotIntent(field, data, finding);
        if (isIndeterminate(data)) {
          indeterminateCharacteristics.push({
            data,
            path,
            config,
            dotIntent: undefined,
          });
        } else {
          characteristics.push({
            data,
            path,
            config,
            dotIntent: dotIntent,
          });
        }
      } else if (Array.isArray(data)) {
        data.forEach(value => {
          const option = config.options.find(o => o.value === value);
          if (option) {
            const dotIntent = getCharacteristicsPillDotIntent(
              field,
              option.value,
              finding
            );
            characteristics.push({
              data,
              path,
              config,
              dotIntent: dotIntent,
              displayValue: option.editableI18NKey,
            });
          }
        });
      }
    }
  }

  return {
    characteristics,
    indeterminateCharacteristics,
  };
}

export const getCharacteristicsPillDotIntent = (
  field: FindingFieldConfig,
  value: string,
  finding: Finding
): PillProps['dotIntent'] => {
  const scoreConfig =
    field.inputConfig &&
    'scoreConfig' in field.inputConfig &&
    field.inputConfig.scoreConfig;

  if (!finding.comparedFinding || !field.path || !scoreConfig) {
    return undefined;
  }

  const comparedValue = get(finding.comparedFinding, field.path);

  const currentValueScore = scoreConfig[value].score;
  if (!comparedValue) {
    if (currentValueScore > 0) {
      return 'destructive';
    }
    return 'neutral';
  } else {
    if (Array.isArray(comparedValue)) {
      if (comparedValue.includes(value)) {
        return undefined;
      }
      return 'neutral';
    } else if (typeof comparedValue === 'string') {
      const oldValueScore = scoreConfig[comparedValue].score;

      if (comparedValue === value) return undefined;

      if (currentValueScore > oldValueScore) {
        return 'destructive';
      } else if (currentValueScore < oldValueScore) {
        return 'primary';
      } else if (currentValueScore == oldValueScore) {
        return 'neutral';
      }
    }
  }
  return undefined;
};

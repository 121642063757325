import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { cnames } from '@helpers/cnames';
import styles from './drawer.scss';

type DrawerSize = 'small' | 'medium' | 'large';

interface DrawerProps {
  isOpen: boolean;
  size?: DrawerSize;
  width?: string;
  animationDuration?: number;
  onAnimationEnd?: () => void;
}

export const Drawer: React.FC<DrawerProps> = ({
  children,
  isOpen,
  size,
  width,
  animationDuration,
  onAnimationEnd,
}) => {
  const el = document.getElementById('drawer-container');

  return el
    ? createPortal(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              role="dialog"
              className={cnames({
                [styles.container]: true,
                [styles[`size-${size}`]]: !!size,
              })}
              onAnimationComplete={onAnimationEnd}
              {...getWidthProps(width)}
              {...getAnimationProps(animationDuration)}
              onClick={e => e.stopPropagation()}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>,
        el
      )
    : null;
};

function getWidthProps(customWidth: string | undefined) {
  if (!!customWidth) {
    return {
      style: { width: customWidth },
    };
  } else {
    return null;
  }
}

function getAnimationProps(animationDuration: number | undefined) {
  return {
    initial: { x: '100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: animationDuration ?? 0.6,
    },
  };
}

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { signOut } from '@webViewInterfaces/Pages/General';
import { upload } from '@webViewInterfaces/UseCase/AnalysisUploads';
import { updateLocation } from '@webViewInterfaces/UseCase/Location';
import {
  listNextSessions,
  markAsBookmarked,
  setSessionStatus,
  updateSessionAssignedWorkspaces,
} from '@webViewInterfaces/UseCase/Worklist';
import { downloadEncryptedPdf } from '@webViewInterfaces/UseCase/Session';
import { toApp } from '@interfaces/Root';
import { toProfile, toPACS, toWorklist, toAppSettings } from '@interfaces/App';
import { Profile } from '@interfaces/Profile';
import { FilterOptions } from '@interfaces/FilterOptions';
import { WorklistLineItemData } from '@webOrganisms/WorklistLineItem/WorklistLineItem';
import { ApiStatus } from '@interfaces/Status';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { stringFallback } from '@helpers/string';
import * as RouteHelper from '@helpers/routes';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import { studyTypeToDescription } from '@interfaces/StudyType';
import { Workspace } from '@interfaces/Workspace';
import { WorklistLineItem } from '@entities/Worklist';
import { getFeatureFlags } from '@entities/FeatureFlag';
import { getReportURL } from './WorklistHelper';

export type Worklist = WorklistProps & WorklistDispatchers;

export interface WorklistProps {
  me: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  searchQuery: string;
  activeFilters?: FilterOptions;
  loading: boolean;
  worklistLineItems: WorklistLineItemData[];
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  totalAvailableItems: number;
  failedToLoadWorklist: boolean;
  customWorkspaces: Workspace[];
}

export interface WorklistDispatchers {
  dispatchOnSignOut: () => Dispatch<Actions>;
  dispatchUpload: (fileList: File[]) => Dispatch<Actions>;
  dispatchLoadNext: () => Dispatch<Actions>;
  dispatchSetSessionStatus: (
    analysisId: string,
    sessionId: string,
    sessionStatus: SessionStatus
  ) => Dispatch<Actions>;
  dispatchOpenStudy: (
    analysisId: string,
    sessionId: string
  ) => Dispatch<Actions>;
  dispatchDownloadEncryptedPdf: (
    encryptedPdfUrl: string,
    fileName: string
  ) => Dispatch<Actions>;
  dispatchMarkAsBookmarked: (
    sessionId: string,
    isBookmarked: boolean
  ) => Dispatch<Actions>;
  dispatchUpdateSessionAssignedWorkspaces: (
    sessionId: string,
    workspaces: string[]
  ) => Dispatch<Actions>;
}

export const dispatchers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any
) => ({
  dispatchOnSignOut: () => dispatch(signOut()),
  dispatchUpload: (fileList: File[]) => dispatch(upload(fileList)),
  dispatchLoadNext: () => dispatch(listNextSessions()),
  dispatchSetSessionStatus: (
    analysisId: string,
    sessionId: string,
    sessionStatus: SessionStatus
  ) => dispatch(setSessionStatus(analysisId, sessionId, sessionStatus)),
  dispatchOpenStudy: (analysisId: string, sessionId: string) =>
    dispatch(updateLocation(RouteHelper.study(analysisId, sessionId))),
  dispatchDownloadEncryptedPdf: (encryptedPdfUrl: string, fileName: string) =>
    dispatch(downloadEncryptedPdf(encryptedPdfUrl, fileName)),
  dispatchMarkAsBookmarked: (sessionId: string, isBookmarked: boolean) =>
    dispatch(markAsBookmarked(sessionId, isBookmarked)),
  dispatchUpdateSessionAssignedWorkspaces: (
    sessionId: string,
    workspaces: string[]
  ) => dispatch(updateSessionAssignedWorkspaces(sessionId, workspaces)),
});

export const fromState = (state: State): WorklistProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const worklist = toWorklist(app);
  const me = toProfile(app);
  const failedToLoadWorklist = worklist.status === ApiStatus.Error;
  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const activeFilters = worklist.filterOptions.lift();
  const loading = worklist.worklistLineItems.first() === ApiStatus.Busy;
  const profiles = worklist.profiles.second();
  const featureFlags = getFeatureFlags();
  const worklistLineItems = worklist.worklistLineItems
    .second()
    .map(
      mapSessionLineItemToWorklistLineItem(profiles, featureFlags.bookmarks)
    );
  const totalAvailableItems = worklist.totalAvailableItems;
  const pacs = toPACS(app);
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;

  const customWorkspaces = worklist.availableWorkspaces
    .second()
    .withDefault([])
    .filter(workspace => workspace.isCustomWorkspace);

  return {
    me,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    searchQuery: '',
    activeFilters,
    loading,
    worklistLineItems,
    pacsStudyLineItems,
    pacsSystemStatus,
    totalAvailableItems,
    failedToLoadWorklist,
    customWorkspaces,
  };
};

const mapSessionLineItemToWorklistLineItem =
  (profiles: Profile[], isBookmarkedEnabled: boolean) =>
  (sessionLineItem: WorklistLineItem): WorklistLineItemData => {
    const {
      accessionNumber,
      date,
      analysisId,
      generatedPDF,
      id,
      patientName,
      status,
      studyType,
      userId,
      decryptedPhi,
      collaborators,
      animated,
      decrypted,
      isBookmarked,
      assignedWorkspaces = [],
    } = sessionLineItem;
    const studyTypeDescription = studyTypeToDescription(studyType);

    const userProfiles =
      profiles.length > 0
        ? profiles
            .filter(profile => collaborators.includes(profile.id))
            .sort(a => (a.id === userId ? -1 : 1))
            .map(profile => {
              return {
                color: profile.swatchColour,
                name: `${profile.firstName} ${profile.lastName}`,
                initial: `${profile.firstName}`,
              };
            })
        : [
            {
              color: 'var(--ds-background-color-alt-200)',
              name: '',
              initial: '',
            },
          ];
    const isBookmark = isBookmarkedEnabled;

    return {
      accessionNumber,
      date,
      pdfUrl: getReportURL(generatedPDF),
      analysisId,
      sessionId: id,
      patientName: stringFallback(decryptedPhi?.patient_name, patientName),
      status,
      userId,
      userProfiles,
      animated,
      studyTypeDescription,
      hasDecryptedPhi: decrypted,
      isBookmarked,
      isBookmarkEnabled: isBookmark,
      assignedWorkspaces,
    };
  };

import React, { createContext } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './theme.scss';

export type ThemeNames = 'dark' | 'print' | 'light';

export interface ThemeProps {
  name: ThemeNames;
}

export const ThemeContext = createContext<ThemeNames>('dark');

export const Theme: React.FC<ThemeProps> = ({ name, children }) => {
  return (
    <ThemeContext.Provider value={name}>
      <Helmet>
        <html data-ds-theme={name} className={styles.theme} />;
      </Helmet>
      {children}
    </ThemeContext.Provider>
  );
};

import { Admin } from '@entities/Admin';
import { AdminInstitutions } from '@entities/Admin/Institutions';
import { AdminUsers } from '@entities/Admin/Users';
import { AdminWorkspaces } from '@entities/Admin/Workspaces';
import { AdminApplication } from '@entities/Admin/Application';

export const toUsers = (admin: Admin): AdminUsers => admin.adminUsers;

export const toWorkspaces = (admin: Admin): AdminWorkspaces =>
  admin.adminWorkspaces;

export const toInstitutions = (admin: Admin): AdminInstitutions =>
  admin.adminInstitutions;

export const toApplication = (admin: Admin): AdminApplication =>
  admin.adminApplication;

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Text } from '@webMolecules/Text/Text';
import { t, tPrint } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { FindingConfig } from '@config/ResultTable/Shared';
import {
  eventFindingActionDeletedFinding,
  findingAnalyticsEvent,
} from '@webInterfaces/Analytics';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { useDispatcher } from '@helpers/useDispatcher';
import { Finding } from '@entities/Finding';
import { findingUseCase } from '@useCases/Finding';
import { FeatureFlags } from '@entities/FeatureFlag';
import styles from './DetailedFindingsTable.scss';
import { DetailedFindingsTableSection } from './DetailedFindingsTableSection';
import {
  DeleteFindingDialog,
  DiscardChangesDialog,
} from './DetailedFindingsTableDialogs';

interface DetailedFindingsTableProps {
  activeFinding: Finding;
  config: FindingConfig | null;
  featureFlags: FeatureFlags;
}

type WarningDialog = 'delete' | 'discard' | null;

const DetailedFindingsTable = (props: DetailedFindingsTableProps) => {
  const closeFinding = useDispatcher(findingInteractionUseCase.Close);
  const deleteFinding = useDispatcher(findingUseCase.DeleteFinding);
  const updateFinding = useDispatcher(findingUseCase.UpdateFinding);
  const createFinding = useDispatcher(findingUseCase.CreateFinding);

  const [showWarning, setShowWarning] = React.useState<WarningDialog>(null);

  const { activeFinding, config, featureFlags } = props;

  const methods = useForm<Finding>({ defaultValues: activeFinding });

  if (config === null) {
    return null;
  }
  const isExistingFinding = activeFinding.id !== '';

  const handleSave = () => {
    const updatedFinding = methods.getValues();
    if (isExistingFinding) {
      const originalFinding = methods.formState.defaultValues as Finding;
      findingAnalyticsEvent(updatedFinding, originalFinding);

      updateFinding(updatedFinding);
    } else {
      createFinding(updatedFinding);
    }
    closeFinding();
  };

  const handleClose = () => {
    const isDirty = !isEqual(
      methods.getValues(),
      methods.formState.defaultValues
    );

    if (isDirty) {
      setShowWarning('discard');
    } else {
      closeFinding();
    }
  };

  const hideWarning = () => {
    setShowWarning(null);
  };

  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Box className={styles.detailedTable}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          fullWidth
          padding="m"
          className={styles.detailedTitle}
        >
          <Button
            circle
            strong
            size="medium"
            onClick={handleClose}
            iconBefore={<Icon name="x" />}
          />

          {isExistingFinding && (
            <>
              <Text type="display2" marginLeft="m">
                {t('detailed_finding_table.heading.edit')}
              </Text>
              <Box
                display="inline-block"
                marginLeft="xs"
                className={styles.headingFinding}
              >
                {t('detailed_finding_table.heading.finding')}
              </Box>
              <Box
                display="inline-block"
                marginLeft="xs"
                className={styles.headingFindingNum}
              >
                {tPrint(
                  'detailed_finding_table.heading.finding_num',
                  activeFinding.index
                )}
              </Box>
            </>
          )}

          {!isExistingFinding && (
            <Text type="display2" display="block" marginLeft="m">
              {t('detailed_finding_table.heading.add')}
            </Text>
          )}
        </Box>
        <Box
          padding="xl"
          gap="l"
          className={styles.detailedContent}
          scrollable="y"
        >
          {config.sections.map(section => (
            <DetailedFindingsTableSection
              key={`key-${section.index}`}
              section={section}
              featureFlags={featureFlags}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          padding="m"
          className={styles.footer}
          gap="m"
        >
          {isExistingFinding && (
            <Button
              pill
              intent="destructive"
              onClick={() => setShowWarning('delete')}
            >
              {t('generic.submit.delete')}
            </Button>
          )}
          <Box marginLeft="auto" display="flex" gap="m">
            <Button strong pill onClick={handleClose}>
              {t('generic.submit.cancel')}
            </Button>
            <Button strong pill onClick={handleSave} intent="primary">
              {t('detailed_finding_table.button.save')}
            </Button>
          </Box>
        </Box>

        <DeleteFindingDialog
          isOpen={showWarning === 'delete'}
          onConfirm={() => {
            hideWarning();
            eventFindingActionDeletedFinding(activeFinding.autoGenerated);
            deleteFinding({ findingId: activeFinding.id });
          }}
          onCancel={hideWarning}
        />

        <DiscardChangesDialog
          isOpen={showWarning === 'discard'}
          onConfirm={() => {
            hideWarning();
            closeFinding();
          }}
          onCancel={hideWarning}
        />
      </Box>
    </FormProvider>
  );
};

export default DetailedFindingsTable;

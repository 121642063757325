/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Study/DebugBar';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { Dropdown, DropdownItem } from '@webMolecules/Dropdown/Dropdown';
import { Dicom } from '@interfaces/Dicom';
import { Text } from '@webMolecules/Text/Text';
import { Modal } from '@webMolecules/Modal/Modal';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import { Input } from '@webMolecules/Input/Input';
import { TextArea } from '@webMolecules/TextArea/TextArea';
import {
  initFeatureFlags,
  updateFeatureFlags,
} from '@webViewInterfaces/Pages/General';
import { unique } from '@helpers/array';
import { Workspace } from '@interfaces/Workspace';
import { useDispatcher } from '@helpers/useDispatcher';
import { profileUseCase } from '@useCases/Profile';
import { Workflow } from '@entities/Workflow';
import { selectConfig, selectWorkflow } from '@selectors/BaseSelectors';
import { appUseCase } from '@useCases/App';
import { FeatureFlags, getFeatureFlags } from '@entities/FeatureFlag';
import { Session } from '@entities/Session';
import { selectIsSize3D, selectSession } from '@selectors/SessionSelectors';
import { sessionUseCase } from '@useCases/Session';
import styles from './debugBar.scss';

declare global {
  interface Window {
    dicoms: Dicom[];
    sessionData: Session['data'];
  }
}

const DebugBar: React.FC<ViewModel.DebugBar> = ({
  dispatchDebugDicom,
  dispatchDownloadArchive,
  debugAssetsS3Key,
  debugDicom,
  isDebugMode,
  sessionId,
  dicoms,
  versionNumber,
  customWorkspaces,
  dispatchUpdateSessionsAssignedWorkspaces,
}) => {
  if (!isDebugMode) {
    return null;
  }
  const dispatch = useDispatch();
  const isWorklist = sessionId === undefined;
  const [overrideFeatureFlags, setOverrideFeatureFlags] = React.useState(false);
  const [openWorkspaceModal, setOpenWorkspaceModal] = React.useState(false);
  const workflow = useSelector(selectWorkflow);
  const isSize3D = useSelector(selectIsSize3D);
  const config = useSelector(selectConfig);
  const { data: sessionData } = useSelector(selectSession);
  const overrideConfiguration = useDispatcher(
    profileUseCase.OverrideConfiguration
  );
  const overrideSize3D = useDispatcher(sessionUseCase.OverrideSize3D);
  const downloadAlmondResult = useDispatcher(
    sessionUseCase.DownloadStudyAlmondResult
  );
  const toggleBoundingBoxConfidence = useDispatcher(
    appUseCase.DebugToggleShowConfidence
  );

  const s3Dropdown = [
    {
      key: 's3-dropdown-1',
      label: 'Almond results',
      action: () =>
        sessionId ? downloadAlmondResult({ sessionId }) : undefined,
    },
    {
      key: 's3-dropdown-2',
      label: 'Debug assets',
      action: () => window.open(debugAssetsS3Key, '_blank'),
    },
    {
      key: 's3-dropdown-5',
      label: 'Download archive',
      action: () =>
        sessionId ? dispatchDownloadArchive(sessionId) : undefined,
    },
  ];

  return (
    <Box
      display="flex"
      style={{ background: 'var(--ds-color-information-500)' }}
    >
      <Box
        display="flex"
        alignItems="center"
        padding="xs"
        paddingX="m"
        style={{ background: 'var(--ds-color-information-600)' }}
      >
        <Icon name="bug" />
      </Box>

      <Box padding="xs" display="flex" alignItems="center">
        {!isWorklist && (
          <>
            <Box marginRight="xs">
              <Dropdown
                trigger={
                  <Button
                    size="small"
                    iconAfter={<Icon name="chevron-down-small" />}
                  >
                    S3 Links
                  </Button>
                }
              >
                {s3Dropdown.map(({ key, label, action }) => (
                  <DropdownItem key={key} onSelect={action} condensed>
                    {label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </Box>
            <Dropdown
              trigger={
                <Button
                  size="small"
                  iconAfter={<Icon name="chevron-down-small" />}
                >
                  Data
                </Button>
              }
            >
              {sessionId && (
                <>
                  <DropdownItem
                    key={`session-data-${sessionId}`}
                    onSelect={() => {
                      window.sessionData = sessionData;
                      console.log(window.sessionData);
                    }}
                    condensed
                  >
                    Session
                  </DropdownItem>
                  <DropdownItem
                    key={`dicoms-${sessionId}`}
                    onSelect={() => {
                      window.dicoms = dicoms;
                      console.log(window.dicoms);
                    }}
                    condensed
                  >
                    Dicoms
                  </DropdownItem>
                </>
              )}
            </Dropdown>
            <Box marginRight="xs">
              <Button size="small" onClick={() => dispatchDebugDicom()}>
                {`Debug Dicom: ${debugDicom}`}
              </Button>
            </Box>

            <Dropdown
              trigger={
                <Button
                  size="small"
                  iconAfter={<Icon name="chevron-down-small" />}
                >
                  Gallery
                </Button>
              }
            >
              <DropdownItem
                key={`gallery-bbox-confidence`}
                onSelect={toggleBoundingBoxConfidence}
                condensed
              >
                Toggle the bbox confidence
              </DropdownItem>
            </Dropdown>
          </>
        )}

        {isWorklist && (
          <>
            <Box marginRight="xs">
              <Button size="small" onClick={() => setOpenWorkspaceModal(true)}>
                Add Sessions to Workspace
              </Button>
            </Box>
            <SessionsToWorkspaceModal
              isOpen={openWorkspaceModal}
              customWorkspaces={customWorkspaces}
              onClose={() => setOpenWorkspaceModal(false)}
              onSubmit={dispatchUpdateSessionsAssignedWorkspaces}
            />
          </>
        )}

        <Dropdown
          trigger={
            <Button size="small" iconAfter={<Icon name="chevron-down-small" />}>
              Feature Flags
            </Button>
          }
        >
          <DropdownItem
            onSelect={() => setOverrideFeatureFlags(true)}
            condensed
          >
            Override
          </DropdownItem>
          <DropdownItem
            onSelect={() => {
              dispatch(initFeatureFlags());
            }}
            condensed
          >
            Reset
          </DropdownItem>
        </Dropdown>
        <OverrideFeatureFlagsModal
          isOpen={overrideFeatureFlags}
          onClose={() => setOverrideFeatureFlags(false)}
        />

        <Dropdown
          trigger={
            <Button size="small" iconAfter={<Icon name="chevron-down-small" />}>
              Workflow: {workflow}
            </Button>
          }
        >
          <DropdownItem
            onSelect={() =>
              overrideConfiguration({ workflow: Workflow.Sonographer })
            }
            condensed
          >
            Sonographer
          </DropdownItem>
          <DropdownItem
            onSelect={() =>
              overrideConfiguration({ workflow: Workflow.Radiologist })
            }
            condensed
          >
            Radiologist
          </DropdownItem>
        </Dropdown>

        {sessionId && (
          <>
            <Box marginRight="xs">
              <Button
                size="small"
                onClick={() => overrideSize3D({ isSize3D: !isSize3D })}
              >
                {`3D Size: ${isSize3D}`}
              </Button>
            </Box>
            <Box marginRight="xs">
              <Button
                size="small"
                onClick={() =>
                  overrideConfiguration({ autosizing: !config.autosizing })
                }
              >
                {`Autosizing: ${config.autosizing}`}
              </Button>
            </Box>
          </>
        )}

        {versionNumber && (
          <Box>
            <Text
              marginRight="xs"
              type="display6"
            >{`Version: ${versionNumber} `}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(DebugBar);

interface OverrideFeatureFlagsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const OverrideFeatureFlagsModal: React.FC<OverrideFeatureFlagsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  const handleSave = () => {
    const newFeatureFlags = JSON.parse(value) as FeatureFlags;
    dispatch(updateFeatureFlags(newFeatureFlags));
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setValue(JSON.stringify(getFeatureFlags(), null, 2));
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card padding="none" elevation="medium">
        <Box className={styles.modalBody} padding="xl">
          <Box className={styles.modalClose} padding="l">
            <Button
              data-testid="test-feature-flags-modal-close"
              onClick={onClose}
              iconBefore={<Icon name="x" />}
              circle
              strong
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="m">
            <Text type="display2" display="block">
              Override Feature Flags
            </Text>
            <TextArea
              fluid
              rows={15}
              proxiedKey="featureFlags"
              defaultValue={value}
              onChange={event => setValue(event.target.value)}
            />
            <Box marginLeft="auto">
              <Button intent="primary" pill strong onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

interface SessionsToWorkspaceModalProps {
  isOpen: boolean;
  customWorkspaces: Workspace[];
  onClose: () => void;
  onSubmit: (sessionIds: string[], workspace: string) => void;
}
const SessionsToWorkspaceModal = ({
  isOpen,
  customWorkspaces,
  onClose,
  onSubmit,
}: SessionsToWorkspaceModalProps) => {
  const [sessionIds, setSessionIds] = React.useState('');
  const [workspace, setWorkspace] = React.useState('');
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Card padding="none" elevation="medium">
        <Box className={styles.modalBody} padding="xl">
          <Box className={styles.modalClose} padding="s">
            <Button
              data-testid="test-about-modal-close"
              onClick={onClose}
              iconBefore={<Icon name="x" />}
              circle
              strong
            />
          </Box>
          <Stack spacing="l">
            <Text type="display2" display="block">
              Assign Sessions To Workspace
            </Text>
            <Box>
              <Text type="body2" display="block">
                Session IDs
              </Text>
              <Box display="flex" justifyContent="space-between" gap="s">
                <Input
                  placeholder="Enter Session Ids separated by comma"
                  fluid
                  onChange={ev => {
                    setSessionIds(ev.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Text type="body2" display="block">
                Workspaces
              </Text>
              <Box display="flex" justifyContent="space-between" gap="s">
                <select
                  title="custom_workspaces"
                  autoComplete="off"
                  onChange={ev => {
                    setWorkspace(ev.target.value);
                  }}
                >
                  <option value=""></option>
                  {customWorkspaces.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <Button
                  intent="primary"
                  onClick={() => {
                    const sessionIdArray = unique(
                      sessionIds.split(',').map(s => s.trim())
                    );
                    onSubmit(sessionIdArray, workspace);
                    onClose();
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
};

import { PatientReportInfo } from '@entities/PatientInfo';
import Maybe from '@monads/Maybe';
import { StudyType } from './StudyType';

export interface SessionConfiguration {}

export interface PatientHealthInfo {
  patient_id: string;
  patient_name: string;
  patient_dob: string;
  patient_sex: string;
  date: string;
  time: string;
  operator_code: string;
  study_type: string;
  indications: string;
  registration_num: string;
  accession_num: string;
}

export type WorksheetExtraInfoObject = Record<string, unknown>;

export interface SessionMetadata {
  encryptedComments?: string;
  decryptedComments?: string;
  encryptedWorksheetExtraInfo?: string;
  encryptedClinicalFindings?: string;
  decryptedClinicalFindings?: string;
  encryptedOtherHistory?: string;
  decryptedOtherHistory?: string;
  decryptedWorksheetExtraInfo?: WorksheetExtraInfoObject;
}

export interface SessionLineItem {
  id: string;
  studyId?: string;
  studyUId?: string;
  analysisId: string;
  userId: string;
  date: Date | null;
  patientName: string;
  accessionNumber: string;
  patientInfo: PatientReportInfo;
  status: SessionStatus;
  studyType: StudyType;
  generatedPDF: Maybe<string>;
  phiCipher?: string;
  decryptedPhi: PatientHealthInfo | undefined;
  decrypted: boolean;
  configuration: SessionConfiguration;
  live: boolean;
  institutionId: string;
  collaborators: string[];
  institutionName: string;
  animated: boolean;
  metadata: SessionMetadata;
  isBookmarked: boolean;
  comparisonSessionId?: string | null;
  comparisonAnalysisId?: string | null;
  assignedWorkspaces?: string[];
}

export enum SessionStatus {
  New = 'session_status--new',
  InProgress = 'session_status--in-progress',
  Completed = 'session_status--completed',
  Archived = 'session_status--archived',
  Error = 'session_status--error',
  Processing = 'session_status--processing',
}

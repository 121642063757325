import React from 'react';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import {
  SearchResultLineItem,
  SearchResultLineItemData,
} from '@webOrganisms/SearchResultLineItem/SearchResultLineItem';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { t, tPrint } from '@webInterfaces/I18n';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';

export interface SearchProps {
  loading: boolean;
  searchQuery: string;
  amountOfResults: number;
  totalAvailableItems: number;
  searchResult: SearchResultLineItemData[];
  onSetAsArchived: (analysisId: string, sessionId: string) => void;
  onOpenStudy: (analysisId: string, sessionId: string) => void;
  onDownloadEncryptedPdf: (encryptedPdfUrl: string, fileName: string) => void;
  onLoadNext: () => void;
}

export const Search: React.FC<SearchProps> = ({
  loading,
  searchQuery,
  searchResult,
  totalAvailableItems,
  amountOfResults,
  onSetAsArchived,
  onOpenStudy,
  onDownloadEncryptedPdf,
  onLoadNext,
}) => {
  const [claimStudy, setClaimStudy] = React.useState<
    { analysisId: string; sessionId: string; accessionNumber: string } | false
  >(false);
  const onOpenUnclaimed = (
    analysisId: string,
    sessionId: string,
    accessionNumber: string
  ) => {
    setClaimStudy({ analysisId, sessionId, accessionNumber });
  };
  const columns = [
    {
      label: t('pages.study.worksheet.heading.name'),
      width: 'minmax(200px, 2fr)',
    },
    {
      label: t('pages.study.worksheet.heading.completed_date'),
      width: 'minmax(100px, 1fr)',
    },
    {
      label: t('pages.study.worksheet.heading.accession'),
      width: 'minmax(100px, 1fr)',
    },
    {
      label: t('pages.study.worksheet.heading.type'),
      width: 'minmax(160px, 1fr)',
    },
    { label: t('pages.study.worksheet.heading.operator') },
    { label: '' },
  ];

  return (
    <>
      {claimStudy !== false ? (
        <DialogConfirm
          data-testid="search-dialog-confirm"
          isOpen={true}
          intent="primary"
          labelOk={t('pages.worklist.dialog.claim_study.ok')}
          labelCancel={t('pages.worklist.dialog.claim_study.cancel')}
          onConfirm={() => {
            onOpenStudy(claimStudy.analysisId, claimStudy.sessionId);
            setClaimStudy(false);
          }}
          onCancel={() => {
            setClaimStudy(false);
          }}
        >
          <Text display="block" type="display2" marginBottom="m">
            {t('pages.worklist.dialog.claim_study.title')}
          </Text>
          <Text display="block" marginBottom="s">
            {tPrint(
              'pages.worklist.dialog.claim_study.blurb_1',
              claimStudy.accessionNumber
            )}
          </Text>
          <Text display="block">
            {t('pages.worklist.dialog.claim_study.blurb_2')}
          </Text>
        </DialogConfirm>
      ) : null}
      <Box>
        <Box display="flex" alignItems="center">
          <Text type="display1">{t('pages.search.heading.title')}</Text>
          {loading && (
            <Box marginLeft="m">
              <LoadingIcon />
            </Box>
          )}
        </Box>
        {!loading && (
          <Box paddingTop="xs" paddingBottom="m">
            <Text type="body2" data-testid="search-result-count">
              <strong>
                {tPrint(
                  totalAvailableItems === 1
                    ? 'pages.search.heading.amount_of_results.single'
                    : 'pages.search.heading.amount_of_results.multiple',
                  totalAvailableItems
                )}
              </strong>
              &nbsp;
            </Text>
            <Text type="body2" muted>
              {tPrint('pages.search.heading.for_query', searchQuery)}
            </Text>
          </Box>
        )}
      </Box>
      {amountOfResults > 0 && (
        <Schedule
          columns={columns}
          hasContextMenu={true}
          onInfiniteScroll={
            !loading && totalAvailableItems > searchResult.length
              ? onLoadNext
              : undefined
          }
          items={searchResult.map(props => (
            <Box
              key={props.sessionId}
              data-testid={`search-line-item:${props.sessionId}`}
            >
              <SearchResultLineItem
                onOpenUnclaimed={onOpenUnclaimed}
                onSetAsArchived={onSetAsArchived}
                downloadPdf={onDownloadEncryptedPdf}
                {...props}
              />
            </Box>
          ))}
        />
      )}
      {loading && amountOfResults > 0 && (
        <Box marginTop="l" display="flex" justifyContent="center">
          <LoadingIcon />
        </Box>
      )}
    </>
  );
};

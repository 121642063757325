import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import { t } from '@webInterfaces/I18n';
import styles from './findingSummary.scss';

interface FindingMissingSizeProps {
  size?: 'small' | 'medium';
}

export const FindingMissingSize: React.FC<FindingMissingSizeProps> = ({
  size = 'medium',
}) => {
  const textType = size === 'medium' ? 'display4' : 'body2';
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={styles.warningIconContainer}
    >
      <Text type={textType} marginRight="xs">
        {t('pages.study.finding.summary.size.missing')}
      </Text>
      <Tooltip
        label={t('detailed_finding_table.recommendation.warning')}
        position={TooltipPosition.Left}
      >
        <Icon
          name="alert-triangle"
          color="var(--ds-color-warning-500)"
          size={size}
        />
      </Tooltip>
    </Box>
  );
};

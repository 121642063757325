import React, { useEffect } from 'react';
import { browserStorage } from '@web/BrowserStorage';

export interface IdleTimerProps {
  timeoutInMinutes: number;
  onTimeout: (showMsg: boolean) => void;
  children?: React.ReactNode;
}

export const SEEMODE_EXPIRED_TIME = 'seemode_expiredTime';

export const IdleTimer = ({
  timeoutInMinutes,
  onTimeout,
  children,
}: IdleTimerProps) => {
  const timeout = timeoutInMinutes;
  let timeoutTracker: number;
  let interval: number;
  const trackerIntervalInMS = 500;
  const intervalInMS = 1000;

  const events = ['keydown', 'mousedown', 'scroll'];

  const handleOnTimeout = (showMsg: boolean) => {
    cleanUp();
    onTimeout(showMsg);
  };

  useEffect(() => {
    if (checkExpiredTime()) {
      // do not show the timed out message if we are signing out user
      // from checking existing expiredTime
      handleOnTimeout(false);
    } else {
      tracker();
      startInterval();
    }

    return () => {
      cleanUp();
    };
  });

  const tracker = () => {
    for (const i in events) {
      window.addEventListener(events[i], updateExpiredTime);
    }
  };

  const checkExpiredTime = (): boolean => {
    const expiredTime = browserStorage.getItem(SEEMODE_EXPIRED_TIME) || '';
    return Boolean(expiredTime) && parseInt(expiredTime, 10) < Date.now();
  };

  const startInterval = () => {
    updateExpiredTime();
    interval = window.setInterval(() => {
      if (checkExpiredTime()) {
        handleOnTimeout(true);
      }
    }, intervalInMS);
  };

  const updateExpiredTime = () => {
    if (timeoutTracker) {
      clearTimeout(timeoutTracker);
    }

    timeoutTracker = window.setTimeout(() => {
      browserStorage.setItem(
        SEEMODE_EXPIRED_TIME,
        (Date.now() + timeout * 1000 * 60).toString()
      );
    }, trackerIntervalInMS);
  };

  const cleanUp = () => {
    try {
      clearTimeout(timeoutTracker);
      browserStorage.removeItem(SEEMODE_EXPIRED_TIME);
      clearInterval(interval);

      for (const i in events) {
        window.removeEventListener(events[i], updateExpiredTime);
      }
    } catch (e) {}
  };

  return <>{children}</>;
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Dropdown, DropdownItem } from '@webMolecules/Dropdown/Dropdown';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import { selectSessionDetails } from '@selectors/SessionSelectors';
import { SessionStatus } from '@entities/SessionLineItem';
import { getFeatureFlags } from '@entities/FeatureFlag';
import {
  eventStudyActionCompareStudy,
  eventStudyActionResetStudy,
} from '@webInterfaces/Analytics';
import { useDispatcher } from '@helpers/useDispatcher';
import { sessionUseCase } from '@useCases/Session';
import StudyComparisonModal from '@webOrganisms/StudyComparisonModal/StudyComparisonModal';
import { openToast } from '@webMolecules/Toast/Toast';
import { ToastBookmarked } from '@webViews/Toasts/ToastBookmarked';
import { PDFTriggerAction } from '@entities/Session';
import { ResetSessionDialog } from '../SessionDialogs';

export const HeaderDropdownMenu: React.FC = () => {
  const featureFlags = getFeatureFlags();

  const dispatchInitGeneratePDF = useDispatcher(sessionUseCase.InitGeneratePDF);

  const sessionDetails = useSelector(selectSessionDetails);

  const dispatchResetSession = useDispatcher(sessionUseCase.Reset);
  const dispatchUpdateComparisonSession = useDispatcher(
    sessionUseCase.UpdateComparisonSession
  );
  const dispatchMarkAsBookmarked = useDispatcher(
    sessionUseCase.MarkAsBookmarked
  );

  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showCompareDialog, setShowCompareDialog] = useState(false);

  const onConfirmReset = () => {
    eventStudyActionResetStudy();
    dispatchResetSession();
    setShowResetDialog(false);
  };

  const onConfirmCompare = (comparisonSessionId: string) => {
    eventStudyActionCompareStudy();
    dispatchUpdateComparisonSession({ comparisonSessionId });
    setShowCompareDialog(false);
  };

  if (!sessionDetails || sessionDetails.status === SessionStatus.Completed) {
    return null;
  }

  const isBookmarked = sessionDetails.isBookmarked;

  return (
    <>
      <Box data-testid="menu-bar-drop-down">
        <Dropdown
          size="small"
          trigger={
            <Button
              circle
              size="small"
              iconBefore={<Icon name="more-vertical" />}
            />
          }
        >
          {featureFlags.resetStudy && (
            <DropdownItem onSelect={() => setShowResetDialog(true)} condensed>
              {t('generic.dropdown.reset')}
            </DropdownItem>
          )}
          {featureFlags.comparisonStudiesManual ? (
            <DropdownItem onSelect={() => setShowCompareDialog(true)} condensed>
              {t('generic.dropdown.compare_study')}
            </DropdownItem>
          ) : null}
          <DropdownItem
            onSelect={() =>
              dispatchInitGeneratePDF({
                pdfTriggerAction: PDFTriggerAction.Download,
              })
            }
            condensed
          >
            {t('generic.dropdown.download_pdf')}
          </DropdownItem>

          {featureFlags.bookmarks && (
            <DropdownItem
              onSelect={() => {
                dispatchMarkAsBookmarked({
                  sessionId: sessionDetails.id,
                  isBookmarked: !isBookmarked,
                });
                openToast(<ToastBookmarked isBookmarked={!isBookmarked} />, {
                  autoClose: 500,
                  closeButton: true,
                });
              }}
              condensed
            >
              {isBookmarked
                ? t('generic.dropdown.remove_bookmarked')
                : t('generic.dropdown.bookmarked')}
            </DropdownItem>
          )}
        </Dropdown>
      </Box>

      <ResetSessionDialog
        isOpen={showResetDialog}
        onConfirm={onConfirmReset}
        onCancel={() => setShowResetDialog(false)}
      />

      <StudyComparisonModal
        onCompare={onConfirmCompare}
        isOpen={showCompareDialog}
        onClose={() => setShowCompareDialog(false)}
      />
    </>
  );
};

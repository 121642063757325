import React, { forwardRef } from 'react';

// interface ThyroidFivePolesSVGProps {
// }

export const ThyroidFivePolesSVG = forwardRef<SVGSVGElement>((_props, ref) => {
  return (
    <svg
      viewBox="0 0 118 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <defs>
        <clipPath id="clip-0">
          <path
            d="M99.9021 3.93137C102.902 3.43144 104.735 7.35165 107.902 15.4313C109.337 19.0909 110.402 22.4258 111.196 25.3942C103.493 24.5647 97.4088 22.8344 93.6069 21.3377C93.7395 20.9785 93.8404 20.6732 93.9021 20.4314C94.0388 19.8945 94.321 18.9469 94.652 17.8353C95.279 15.7297 96.0812 13.0358 96.4021 11.4313C96.497 10.9569 96.5676 10.3998 96.6428 9.80694C96.956 7.33622 97.3479 4.24466 99.9021 3.93137Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-1">
          <path
            d="M 93.055 22.732 C 97.09 24.321 103.507 26.126 111.594 26.944 C 112.702 31.431 113.175 34.982 113.402 37.431 C 113.422 37.641 113.444 37.868 113.468 38.111 C 113.589 39.334 113.752 40.989 113.804 43.125 C 102.299 41.236 93.157 37.379 87.737 34.326 C 88.645 32.76 89.419 31.353 89.902 30.431 C 90.668 28.365 91.536 26.321 92.273 24.586 C 92.558 23.917 92.823 23.293 93.055 22.732 Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-2">
          <path
            d="M86.9762 35.6186C92.5922 38.786 102.006 42.7436 113.82 44.6479C113.808 48.6425 113.376 54.0531 111.772 61.1215C95.7622 58.4084 83.6306 51.7059 77.7585 47.342C79.3928 45.7113 81.1257 43.963 82.4021 42.4314C83.7717 40.7879 85.4843 38.1204 86.9762 35.6186Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-3">
          <path
            d="M76.6856 48.4123C82.6741 52.9093 95.0694 59.7943 111.429 62.5846C110.571 66.129 109.424 70.0687 107.902 74.4314C107.411 75.8403 106.866 77.1533 106.276 78.374C88.4263 74.6511 75.1031 65.8866 69.1667 60.6528C69.9057 56.1744 71.6804 54.031 72.8474 52.6215C73.0546 52.3713 73.2426 52.1442 73.4023 51.9314C74.0401 51.0809 75.2717 49.8253 76.6856 48.4123Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-4">
          <path
            d="M68.9418 62.4389C75.3369 67.8349 88.3698 76.0854 105.567 79.7574C98.9422 91.9623 87.5926 93.9108 81.4022 89.9314C74.402 85.4314 68.4022 69.9314 68.9022 62.9314C68.9141 62.7646 68.9273 62.6005 68.9418 62.4389Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-6">
          <path
            d="M17.9175 3.93137C14.9175 3.43144 13.0843 7.35165 9.9173 15.4313C8.48286 19.0909 7.41783 22.4258 6.62401 25.3942C14.3268 24.5647 20.4108 22.8344 24.2126 21.3377C24.0801 20.9785 23.9791 20.6732 23.9175 20.4314C23.7807 19.8945 23.4986 18.9469 23.1676 17.8353C22.5406 15.7297 21.7384 13.0358 21.4175 11.4313C21.3226 10.9569 21.252 10.3998 21.1768 9.80694C20.8636 7.33622 20.4717 4.24466 17.9175 3.93137Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-7">
          <path
            d="M24.7648 22.732C20.73 24.3208 14.3129 26.1265 6.22528 26.9438C5.11733 31.431 4.64428 34.9822 4.41737 37.4313C4.39793 37.6411 4.37559 37.8676 4.35156 38.1112C4.23092 39.3341 4.06767 40.989 4.01602 43.1255C15.5202 41.2364 24.6631 37.3786 30.0828 34.3257C29.1743 32.7605 28.4008 31.3534 27.9174 30.4314C27.1515 28.3647 26.2834 26.3214 25.5463 24.5865C25.2618 23.9169 24.9968 23.2933 24.7648 22.732Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-8">
          <path
            d="M30.8434 35.6186C25.2274 38.786 15.8136 42.7436 4.00003 44.6479C4.0113 48.6425 4.44332 54.0531 6.04712 61.1215C22.0574 58.4084 34.189 51.7059 40.061 47.342C38.4268 45.7113 36.6939 43.963 35.4175 42.4314C34.0479 40.7879 32.3353 38.1204 30.8434 35.6186Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-9">
          <path
            d="M41.134 48.4123C35.1455 52.9093 22.7502 59.7943 6.39017 62.5846C7.24847 66.129 8.39548 70.0687 9.91733 74.4314C10.4088 75.8403 10.9537 77.1533 11.5437 78.374C29.3933 74.6511 42.7165 65.8866 48.6528 60.6528C47.9138 56.1744 46.1392 54.031 44.9721 52.6215C44.765 52.3713 44.577 52.1442 44.4173 51.9314C43.7795 51.0809 42.5479 49.8253 41.134 48.4123Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-10">
          <path
            d="M48.8778 62.4389C42.4827 67.8349 29.4498 76.0854 12.2526 79.7574C18.8774 91.9623 30.227 93.9108 36.4174 89.9314C43.4175 85.4314 49.4174 69.9314 48.9174 62.9314C48.9055 62.7646 48.8922 62.6005 48.8778 62.4389Z"
            fill="#0A161D"
          ></path>
        </clipPath>
        <clipPath id="clip-5">
          <path
            d="M52.873 64.9314C53.273 59.7314 50.7064 54.2647 49.373 52.4314C51.873 53.9314 55.473 54.4314 59.873 54.4314C64.273 54.4314 67.373 53.4314 68.873 52.4314C67.373 54.9314 65.373 60.1314 65.373 64.9314C65.373 69.7314 67.7064 75.2647 68.873 77.4314C66.373 74.9314 62.873 74.4314 58.873 74.4314C54.873 74.4314 51.873 74.9314 48.873 77.4314C50.0397 75.2647 52.473 70.1314 52.873 64.9314Z"
            fill="#0A161D"
          ></path>
        </clipPath>
      </defs>
      <path
        d="M58.9286 77.0207C47.9439 77.0207 48.6642 83.5513 38.4103 91.95C29.9592 98.872 14.5693 94.0602 7.89927 77.0207C1.22928 59.9813 0.26992 46.0628 2.93588 31.868C5.60185 17.6733 8.40163 12.4998 11.7444 5.49639C15.0872 -1.50707 22.211 0.321499 22.9849 6.51597C23.7588 12.7104 32.598 51.6944 58.9286 51.6944"
        stroke="#3A4E5A"
        strokeWidth="2"
      />
      <path
        d="M58.9287 77.0207C69.9134 77.0207 69.1931 83.5513 79.447 91.95C87.8981 98.872 103.288 94.0602 109.958 77.0207C116.628 59.9813 117.587 46.0628 114.921 31.868C112.255 17.6733 109.456 12.4998 106.113 5.49639C102.77 -1.50707 95.6463 0.321499 94.8724 6.51597C94.0985 12.7104 85.2593 51.6944 58.9287 51.6944"
        stroke="#3A4E5A"
        strokeWidth="2"
      />
      <path
        d="M99.9021 3.93137C102.902 3.43144 104.735 7.35165 107.902 15.4313C109.337 19.0909 110.402 22.4258 111.196 25.3942C103.493 24.5647 97.4088 22.8344 93.6069 21.3377C93.7395 20.9785 93.8404 20.6732 93.9021 20.4314C94.0388 19.8945 94.321 18.9469 94.652 17.8353C95.279 15.7297 96.0812 13.0358 96.4021 11.4313C96.497 10.9569 96.5676 10.3998 96.6428 9.80694C96.956 7.33622 97.3479 4.24466 99.9021 3.93137Z"
        clipPath="url('#clip-0')"
        strokeWidth="2"
        id="left_upper"
      ></path>
      <path
        d="M93.0548 22.732C97.0896 24.3208 103.507 26.1265 111.594 26.9438C112.702 31.431 113.175 34.9822 113.402 37.4313C113.422 37.6411 113.444 37.8676 113.468 38.1112C113.589 39.3341 113.752 40.989 113.804 43.1255C102.299 41.2364 93.1565 37.3786 87.7368 34.3257C88.6453 32.7605 89.4188 31.3534 89.9022 30.4314C90.6681 28.3647 91.5362 26.3214 92.2733 24.5865C92.5578 23.9169 92.8227 23.2933 93.0548 22.732Z"
        clipPath="url('#clip-1')"
        strokeWidth="2"
        id="left_upper_mid"
      ></path>
      <path
        d="M86.9762 35.6186C92.5922 38.786 102.006 42.7436 113.82 44.6479C113.808 48.6425 113.376 54.0531 111.772 61.1215C95.7622 58.4084 83.6306 51.7059 77.7585 47.342C79.3928 45.7113 81.1257 43.963 82.4021 42.4314C83.7717 40.7879 85.4843 38.1204 86.9762 35.6186Z"
        clipPath="url('#clip-2')"
        strokeWidth="2"
        id="left_mid"
      ></path>
      <path
        d="M76.6856 48.4123C82.6741 52.9093 95.0694 59.7943 111.429 62.5846C110.571 66.129 109.424 70.0687 107.902 74.4314C107.411 75.8403 106.866 77.1533 106.276 78.374C88.4263 74.6511 75.1031 65.8866 69.1667 60.6528C69.9057 56.1744 71.6804 54.031 72.8474 52.6215C73.0546 52.3713 73.2426 52.1442 73.4023 51.9314C74.0401 51.0809 75.2717 49.8253 76.6856 48.4123Z"
        clipPath="url('#clip-3')"
        strokeWidth="2"
        id="left_lower_mid"
      ></path>
      <path
        d="M68.9418 62.4389C75.3369 67.8349 88.3698 76.0854 105.567 79.7574C98.9422 91.9623 87.5926 93.9108 81.4022 89.9314C74.402 85.4314 68.4022 69.9314 68.9022 62.9314C68.9141 62.7646 68.9273 62.6005 68.9418 62.4389Z"
        clipPath="url('#clip-4')"
        strokeWidth="2"
        id="left_lower"
      ></path>
      <path
        d="M17.9175 3.93137C14.9175 3.43144 13.0843 7.35165 9.9173 15.4313C8.48286 19.0909 7.41783 22.4258 6.62401 25.3942C14.3268 24.5647 20.4108 22.8344 24.2126 21.3377C24.0801 20.9785 23.9791 20.6732 23.9175 20.4314C23.7807 19.8945 23.4986 18.9469 23.1676 17.8353C22.5406 15.7297 21.7384 13.0358 21.4175 11.4313C21.3226 10.9569 21.252 10.3998 21.1768 9.80694C20.8636 7.33622 20.4717 4.24466 17.9175 3.93137Z"
        clipPath="url('#clip-6')"
        strokeWidth="2"
        id="right_upper"
      ></path>
      <path
        d="M24.7648 22.732C20.73 24.3208 14.3129 26.1265 6.22528 26.9438C5.11733 31.431 4.64428 34.9822 4.41737 37.4313C4.39793 37.6411 4.37559 37.8676 4.35156 38.1112C4.23092 39.3341 4.06767 40.989 4.01602 43.1255C15.5202 41.2364 24.6631 37.3786 30.0828 34.3257C29.1743 32.7605 28.4008 31.3534 27.9174 30.4314C27.1515 28.3647 26.2834 26.3214 25.5463 24.5865C25.2618 23.9169 24.9968 23.2933 24.7648 22.732Z"
        clipPath="url('#clip-7')"
        strokeWidth="2"
        id="right_upper_mid"
      ></path>
      <path
        d="M30.8434 35.6186C25.2274 38.786 15.8136 42.7436 4.00003 44.6479C4.0113 48.6425 4.44332 54.0531 6.04712 61.1215C22.0574 58.4084 34.189 51.7059 40.061 47.342C38.4268 45.7113 36.6939 43.963 35.4175 42.4314C34.0479 40.7879 32.3353 38.1204 30.8434 35.6186Z"
        clipPath="url('#clip-8')"
        strokeWidth="2"
        id="right_mid"
      ></path>
      <path
        d="M41.134 48.4123C35.1455 52.9093 22.7502 59.7943 6.39017 62.5846C7.24847 66.129 8.39548 70.0687 9.91733 74.4314C10.4088 75.8403 10.9537 77.1533 11.5437 78.374C29.3933 74.6511 42.7165 65.8866 48.6528 60.6528C47.9138 56.1744 46.1392 54.031 44.9721 52.6215C44.765 52.3713 44.577 52.1442 44.4173 51.9314C43.7795 51.0809 42.5479 49.8253 41.134 48.4123Z"
        clipPath="url('#clip-9')"
        strokeWidth="2"
        id="right_lower_mid"
      ></path>
      <path
        d="M48.8778 62.4389C42.4827 67.8349 29.4498 76.0854 12.2526 79.7574C18.8774 91.9623 30.227 93.9108 36.4174 89.9314C43.4175 85.4314 49.4174 69.9314 48.9174 62.9314C48.9055 62.7646 48.8922 62.6005 48.8778 62.4389Z"
        clipPath="url('#clip-10')"
        strokeWidth="2"
        id="right_lower"
      ></path>
      <path
        d="M52.873 64.9314C53.273 59.7314 50.7064 54.2647 49.373 52.4314C51.873 53.9314 55.473 54.4314 59.873 54.4314C64.273 54.4314 67.373 53.4314 68.873 52.4314C67.373 54.9314 65.373 60.1314 65.373 64.9314C65.373 69.7314 67.7064 75.2647 68.873 77.4314C66.373 74.9314 62.873 74.4314 58.873 74.4314C54.873 74.4314 51.873 74.9314 48.873 77.4314C50.0397 75.2647 52.473 70.1314 52.873 64.9314Z"
        clipPath="url('#clip-5')"
        strokeWidth="2"
        id="median"
      ></path>
    </svg>
  );
});

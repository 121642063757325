import React from 'react';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './filterPill.scss';

export interface FilterPillProps {
  selected?: boolean;
  onSelect?: () => void;
}

export const FilterPill: React.FC<FilterPillProps> = ({
  selected,
  onSelect,
  children,
}) => {
  const className = cnames(styles.filterPill, {
    [styles.selected]: selected,
  });

  return (
    <button className={className} type="button" onClick={onSelect}>
      <Text display="block" type="caption">
        {children}
      </Text>
    </button>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Tag } from '@webMolecules/Tag/Tag';
import { Icon } from '@webMolecules/Icon/Icon';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import { selectWorkflow } from '@selectors/BaseSelectors';
import { t } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';
import { Workflow } from '@entities/Workflow';
import { Finding, FindingType } from '@entities/Finding';
import { clinicalActionToString, clinicalActionToTagIntent } from './Shared';
import styles from './findingSummary.scss';

interface ClinicalRecommendationTagProps {
  finding: Finding;
  showNextPageTooltip?: boolean;
  outline?: boolean;
}

export const ClinicalRecommendationTag: React.FC<
  ClinicalRecommendationTagProps
> = ({ finding, showNextPageTooltip, outline = true }) => {
  if (finding.type !== FindingType.Thyroid) return null;

  const workflow = useSelector(selectWorkflow);

  const clinicalAction = finding.characteristics.clinicalAction ?? undefined;
  const clinicalActionLabel = clinicalActionToString(clinicalAction);

  if (showNextPageTooltip && workflow == Workflow.Radiologist) {
    return (
      <Box marginLeft="m" marginRight="xl">
        <Tooltip
          label={t('pages.study.comparison.recommendation.tooltip.label')}
          position={TooltipPosition.Left}
        >
          <Icon name="info" color="var(--ds-color-primary)" />
        </Tooltip>
      </Box>
    );
  }

  const _warningIcon = (
    <Box
      display="flex"
      justifyContent="center"
      className={styles.warningIconContainer}
    >
      <Tooltip
        label={t('detailed_finding_table.recommendation.warning')}
        position={TooltipPosition.Left}
      >
        <Icon name="alert-triangle" color="var(--ds-color-warning-500)" />
      </Tooltip>
    </Box>
  );

  const radiologistContent = clinicalActionLabel ? (
    <Tag
      label={clinicalActionLabel}
      intent={clinicalActionToTagIntent(clinicalAction)}
      strong={outline}
    />
  ) : null;

  return workflow == Workflow.Radiologist ? radiologistContent : null;
};

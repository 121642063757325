export enum StudyType {
  SmallPartsBreast = 'study_type--small_parts_breast',
  SmallPartsThyroid = 'study_type--small_parts_thyroid',
  SmallPartsScrotum = 'study_type--small_parts_scrotum',
  SmallPartsNeck = 'study_type--small_parts_neck',
  AbdoRenal = 'study_type--abdo_renal',
  AbdoUpperAbdomen = 'study_type--abdo_upper_abdomen',
  AbdoPelvic = 'study_type--abdo_pelvic',
  AbdoLiver = 'study_type--abdo_liver',
  Unknown = 'study_type--unknown',
}

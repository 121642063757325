import { FilterConfigurationLineItem } from '@entities/Admin/FilterConfigurationLineItem';
import { FilterConfiguration } from '@entities/FilterConfiguration';
import { ApiStatus } from '@entities/Status';
import { Message } from '@entities/Message';
import Maybe, { nothing } from '@monads/Maybe';

export interface AdminWorkspaces {
  apiStatus: ApiStatus;
  filterConfigurationLineItems: FilterConfigurationLineItem[];
  targetFilterConfiguration: Maybe<FilterConfiguration>;
  messages: Message[];
}

export const initial = (): AdminWorkspaces => ({
  apiStatus: ApiStatus.Idle,
  filterConfigurationLineItems: [],
  targetFilterConfiguration: nothing(),
  messages: [],
});

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './pill.scss';

export interface PillProps {
  content: string | string[];
  variant?: 'filled' | 'hollow';
  /**
   * Visually state the intent this pill carries.
   */
  intent?: 'neutral' | 'primary' | 'secondary';
  dotBefore?: boolean;
  /**
   * Visually state the intent this dot carries.
   */
  dotIntent?: 'neutral' | 'primary' | 'destructive';
}

export const Pill: React.FC<PillProps> = ({
  content,
  variant = 'filled',
  intent = 'neutral',
  dotBefore = false,
  dotIntent,
}) => {
  return typeof content === 'string' ? (
    <InnerPill
      content={content}
      variant={variant}
      intent={intent}
      dotBefore={dotBefore}
      dotIntent={dotIntent}
    ></InnerPill>
  ) : (
    <Box>
      {content.map((innerText, index) => (
        <InnerPill
          key={`inner-pill=${index}`}
          content={innerText}
          variant={variant}
          intent={intent}
          dotBefore={dotBefore}
          dotIntent={dotIntent}
        ></InnerPill>
      ))}
    </Box>
  );
};

export interface InnerPillProps {
  content: string;
  variant?: 'filled' | 'hollow';
  intent?: 'neutral' | 'primary' | 'secondary';
  dotBefore?: boolean;
  /**
   * Visually state the intent this dot carries.
   */
  dotIntent?: 'neutral' | 'primary' | 'destructive';
}

const InnerPill: React.FC<InnerPillProps> = ({
  content,
  variant,
  intent = 'neutral',
  dotBefore = false,
  dotIntent = 'neutral',
}) => {
  // Check to handle the  case where text description was left with whitespace on onChange was leading to an empty Pill element
  const isNonEmptyValue = content?.trim().length > 0;
  return (
    <>
      {isNonEmptyValue && (
        <Box
          data-testid="test-pill"
          className={cnames(styles[intent], styles.filterPill, {
            [styles.hollow]: variant === 'hollow',
          })}
          display="inline-flex"
          alignItems="center"
          marginBottom="xs"
          marginRight="xs"
          paddingX="s"
          paddingY="xxxs"
        >
          {dotBefore && (
            <span className={cnames(styles.dot, styles[`dot-${dotIntent}`])} />
          )}
          <Text display="block" type="caption" muted={variant === 'hollow'}>
            {content}
          </Text>
        </Box>
      )}
    </>
  );
};

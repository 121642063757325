import Maybe, { nothing } from '@monads/Maybe';
import { SessionLineItem } from '@entities/SessionLineItem';
import { Profile } from '@entities/Profile';
import { Message } from '@entities/Message';
import { defaultLayout } from '@config/Gallery/LayoutConfig';
import { ApiStatus, FinaliseStatus } from './Status';
import { Analysis } from './Analysis';
import { FindingInteraction } from './FindingInteraction';
import { Finding } from './Finding';
import { ThyroidGeneralCharacteristics } from './ThyroidGeneralCharacteristics';
import { Dicom } from './Dicom';
import { CanvasObject } from './CanvasObject';

export interface StudyLayout {
  gallery: boolean;
  canvas: boolean;
  hasCanvas: boolean;
  resultTable: boolean;
}

export interface Session {
  status: ApiStatus;
  analysis: Maybe<Analysis>;
  details: Maybe<SessionLineItem>;
  searchStudies?: Maybe<SessionLineItem[]>;
  owner: Maybe<Profile>;
  collaborators: Maybe<string[]>;
  comparisonSession: Maybe<ComparisonSession>;
  studyLayoutConfig: StudyLayout;
  _version?: number;
  patientId?: string;
  patientName?: string;
  finaliseStatus: FinaliseStatus;
  messages: Message[];
  isPdfDownloading: boolean;
  ignoreComparison: boolean;
  findingInteraction: FindingInteraction;
  isSize3D: boolean;
  data: ThyroidSessionData | BreastSessionData | UnknownSessionData;
  viewed: boolean;
  pdfTriggerAction: PDFTriggerAction;
}

export enum PDFTriggerAction {
  Download = 'PDF_Trigger--download',
  Initial = 'PDF_Trigger--initial',
  Finalise = 'PDF_Trigger--finalise',
  None = 'PDF_Trigger--none',
}

export interface ComparisonSession {
  date: Date | null;
  data: ThyroidSessionData | BreastSessionData;
  dicoms: Dicom[];
}

export interface ThyroidSessionData {
  findings: Finding[];
  impressions?: string;
  thyroidGeneralCharacteristics: ThyroidGeneralCharacteristics;
  canvasObjects: CanvasObject[];
}

export interface BreastSessionData {
  findings: Finding[];
  impressions?: string;
  canvasObjects: CanvasObject[];
}

export interface UnknownSessionData {
  findings: never[];
  impressions?: never;
  canvasObjects: never[];
}

export const initial = (): Session => ({
  status: ApiStatus.Idle,
  analysis: nothing(),
  details: nothing(),
  searchStudies: nothing(),
  owner: nothing(),
  collaborators: nothing(),
  comparisonSession: nothing(),
  studyLayoutConfig: { ...defaultLayout },
  finaliseStatus: FinaliseStatus.Idle,
  messages: [],
  isPdfDownloading: false,
  ignoreComparison: false,
  findingInteraction: {},
  isSize3D: false,
  data: {
    findings: [],
    canvasObjects: [],
  },
  viewed: false,
  pdfTriggerAction: PDFTriggerAction.None,
});

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { toApp } from '@interfaces/Root';
import { toProfile, toSession } from '@interfaces/App';
import { SessionMetadata } from '@interfaces/SessionLineItem';
import { studyTypeToI18NKey } from '@interfaces/StudyType';
import { Profile } from '@interfaces/Profile';
import { ComparisonStatus, fetchComparisonStatus } from './Shared';

export type OffScreenWorksheet = OffScreenWorksheetProps;

export interface findingRowDicom {
  id: string;
}

export interface OffScreenWorksheetProps {
  metadata: SessionMetadata;
  accessionNum: string;
  studyTypeI18NKey: string;
  me: Profile;
  isComparisonAvailable: boolean;
  sessionDate: Date | null;
  comparisonSessionDate: Date | null;
}

export const dispatchers = (_dispatch: Dispatch<Actions>) => ({});

export const fromState = (state: State): OffScreenWorksheetProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const session = toSession(app);
  const profile = toProfile(app);
  const { details, comparisonSession } = session;
  const studyTypeI18NKey = session.analysis.mapOr(
    a => studyTypeToI18NKey(a.studyType),
    ''
  );

  const comparisonStatus = fetchComparisonStatus(session);

  const isComparisonAvailable =
    comparisonStatus !== ComparisonStatus.Unavailable;
  const sessionDate = details.mapOr(a => a.date, null);

  return {
    me: profile,
    isComparisonAvailable,
    metadata: details.mapOr(a => a.metadata, {}),
    accessionNum: details.mapOr(a => a.accessionNumber, ''),
    studyTypeI18NKey,
    sessionDate,
    comparisonSessionDate: comparisonSession.mapOr(a => a.date, null),
  };
};

import { createSelector } from '@reduxjs/toolkit';
import unionWith from 'lodash/unionWith';
import { isLoading } from '@interfaces/Session';
import { getTitle } from '@webViewModels/Pages/Study/SessionHelper';
import { getFindingTableConfig } from '@config/ResultTable/FindingTable';
import {
  ComparisonStatus,
  fetchComparisonStatus,
} from '@webViewModels/Pages/Study/Shared';
import { formatDate, timeFromX } from '@helpers/datetime';
import { CanvasObject } from '@entities/CanvasObject';
import { ThyroidSessionData } from '@entities/Session';
import { Size } from '@entities/Finding';
import { convertUnit } from '@handlers/UnitHandler';
import { StudyType } from '@entities/StudyType';
import { selectApp, selectConfig } from './BaseSelectors';

export const selectSession = createSelector(selectApp, app => app.session);

export const selectSessionDetails = createSelector(selectSession, session =>
  session.details.mapOr(details => details, undefined)
);

export const selectSessionViewed = createSelector(
  selectSession,
  session => session.viewed
);

export const selectIsSessionLoading = createSelector(selectSession, session =>
  isLoading(session)
);

export const selectSessionTitle = createSelector(
  selectSessionDetails,
  details => {
    if (details) {
      return getTitle(details, details.decryptedPhi);
    }
    return undefined;
  }
);

export const selectSessionDate = createSelector(
  selectSessionDetails,
  details => {
    if (details) {
      return details.date;
    }
    return undefined;
  }
);

export const selectStudyType = createSelector(selectSession, session =>
  session.analysis.mapOr(a => a.studyType, undefined)
);

export const selectFindingTableConfig = createSelector(
  selectStudyType,
  selectConfig,
  (studyType, config) => {
    return getFindingTableConfig(studyType, config);
  }
);

export const selectIsSize3D = createSelector(
  selectSession,
  session => session.isSize3D
);

export const selectAnalysis = createSelector(
  selectSession,
  session => session.analysis
);

export const selectComparisonSession = createSelector(
  selectSession,
  session => session.comparisonSession
);

export const selectComparisonStatus = createSelector(selectSession, session =>
  fetchComparisonStatus(session)
);

export const selectComparisonAvailable = createSelector(
  selectComparisonStatus,
  status => status !== ComparisonStatus.Unavailable
);

export const selectDicoms = createSelector(selectAnalysis, analysis =>
  analysis.mapOr(a => a.dicoms, [])
);

export const selectComparisonDicoms = createSelector(
  selectComparisonSession,
  comparisonSession => comparisonSession.mapOr(a => a.dicoms, [])
);

export const selectComparisonDateAndDuration = createSelector(
  selectSession,
  selectComparisonSession,
  (session, comparisonSession) => {
    const sessionDate = session.details.mapOr(a => a.date, null);
    const comparisonSessionDate = comparisonSession.mapOr(a => a.date, null);

    return {
      date: formatDate(comparisonSessionDate, 'DD-MMM-YYYY'),
      duration: timeFromX(comparisonSessionDate, sessionDate) ?? '',
    };
  }
);

export const selectImpressions = createSelector(
  selectSession,
  session => session.data.impressions
);

export const selectAllDicoms = createSelector(
  selectDicoms,
  selectComparisonDicoms,
  (dicoms, comparisonDicoms) => {
    return unionWith(dicoms, comparisonDicoms, (a, b) => {
      return a.id === b.id;
    });
  }
);

export const selectThyroidSessionData = createSelector(
  selectSession,
  selectStudyType,
  (session, studyType): ThyroidSessionData | undefined => {
    if (
      studyType === StudyType.SmallPartsThyroid &&
      'thyroidGeneralCharacteristics' in session.data
    ) {
      return session.data;
    }
    return undefined;
  }
);

export const selectThyroidGeneralCharacteristics = createSelector(
  selectThyroidSessionData,
  sessionData => {
    return sessionData ? sessionData.thyroidGeneralCharacteristics : undefined;
  }
);

export const selectMaxThyroidLobeSize = createSelector(
  selectThyroidGeneralCharacteristics,
  thyroidGeneralCharacteristics => {
    if (thyroidGeneralCharacteristics) {
      const sizes = [
        thyroidGeneralCharacteristics.rightLobe,
        thyroidGeneralCharacteristics.leftLobe,
      ]
        .map(lobe => {
          return [lobe.width, lobe.height, lobe.length].filter(
            size => size && size.value != null
          );
        })
        .flat() as Size[];

      return [...sizes]
        .sort((a, b) => {
          if (a.unit != b.unit) {
            const convertedA = convertUnit(a.unit, b.unit, a.value);
            return convertedA - b.value;
          }
          return a.value - b.value;
        })
        .pop();
    }
    return undefined;
  }
);

export const selectCanvasObjects = createSelector(
  selectSession,
  (session): CanvasObject[] => {
    return session.data.canvasObjects;
  }
);

export const selectDecryptedPhi = createSelector(
  selectSessionDetails,
  details => details?.decryptedPhi
);

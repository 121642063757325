import { StudyLayout } from '@entities/Session';
import { StudyType } from '@entities/StudyType';

type StudyLayoutMapping = Record<StudyType, StudyLayout>;

export const defaultLayout: StudyLayout = {
  gallery: true,
  canvas: true,
  hasCanvas: true,
  resultTable: true,
};
const noCanvasLayout: StudyLayout = {
  gallery: true,
  canvas: false,
  hasCanvas: false,
  resultTable: true,
};

const layoutConfig: StudyLayoutMapping = {
  [StudyType.SmallPartsBreast]: defaultLayout,
  [StudyType.SmallPartsThyroid]: defaultLayout,
  [StudyType.SmallPartsScrotum]: noCanvasLayout,
  [StudyType.SmallPartsNeck]: noCanvasLayout,
  [StudyType.AbdoRenal]: noCanvasLayout,
  [StudyType.AbdoUpperAbdomen]: noCanvasLayout,
  [StudyType.AbdoPelvic]: noCanvasLayout,
  [StudyType.AbdoLiver]: noCanvasLayout,
  [StudyType.Unknown]: defaultLayout,
};

export const getLayoutFromStudyType = (studyType: StudyType): StudyLayout => {
  const layout = layoutConfig[studyType]
    ? { ...layoutConfig[studyType] }
    : { ...defaultLayout };
  return layout;
};

import React from 'react';
import { Box, BoxProps } from '../Box/Box';
import styles from './container.scss';

export interface IContainerProps extends BoxProps {
  backgroundColor?: string;
  size?: 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
  fluid?: boolean;
  centered?: boolean;
}

export const Container: React.FC<IContainerProps> = ({
  backgroundColor,
  children,
  className,
  fluid,
  size,
  centered = true,
  ...rest
}) => {
  const wrapperClassNames = `${styles.wrapper} ${className}`;
  const containerClassNames = [
    styles.container,
    size ? styles[size] : '',
    fluid ? styles.fluid : '',
  ].join(' ');

  return (
    <Box
      data-testid="container-test-id"
      display="flex"
      justifyContent={centered ? 'center' : 'flex-start'}
      style={{ backgroundColor: backgroundColor }}
      className={wrapperClassNames}
    >
      <Box
        data-testid="inner-container-test-id"
        className={containerClassNames}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { t, tPrint } from '@webInterfaces/I18n';
import { Profile } from '@interfaces/Profile';
import * as RouteHelper from '@helpers/routes';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import { useAuthenticationMode } from '@interfaces/Authentication';
import { MemoizedUserlistLineItem } from '../UserLineItem';

export interface UsersProps {
  loading: boolean;
  profile: Profile;
  userLineItems: Profile[];
  targetUserLineItem?: Profile;
  onDeleteUser: (email: string) => void;
}

export const Users: React.FC<UsersProps> = ({
  loading,
  profile,
  userLineItems,
  targetUserLineItem,
  onDeleteUser,
}) => {
  const { isFederatedIdentity, isAdminDomain } = useAuthenticationMode();

  const [deleteUserDialogUser, setDeleteUserDialogUser] = React.useState<
    false | Profile
  >(false);

  const columns = [
    {
      label: t('pages.settings.users.heading.email'),
      width: 'minmax(300px, 1.5fr)',
    },
    {
      label: t('pages.settings.users.heading.account_status'),
      width: 'minmax(100px, 1fr)',
    },
    {
      label: t('pages.settings.users.heading.name'),
      width: 'minmax(200px, 1fr)',
    },
    {
      label: t('pages.settings.users.heading.permission'),
      width: 'minmax(100px, 3fr)',
    },
  ];
  return (
    <>
      {deleteUserDialogUser && (
        <DialogConfirm
          isOpen={true}
          intent="destructive"
          labelOk={t('generic.submit.delete')}
          labelCancel={t('generic.submit.cancel')}
          onCancel={() => {
            setDeleteUserDialogUser(false);
          }}
          onConfirm={() => {
            onDeleteUser(deleteUserDialogUser.id);
            setDeleteUserDialogUser(false);
          }}
        >
          <Text type="display2" display="block" marginBottom="m">
            {t('pages.settings.users.dialog.delete.title')}
          </Text>
          <Text>
            {tPrint(
              'pages.settings.users.dialog.delete.blurb1',
              deleteUserDialogUser.email
            )}
          </Text>
        </DialogConfirm>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="xl"
      >
        <Box display="flex" alignItems="center">
          <Text type="display1" display="block" marginRight="m">
            {t('pages.settings.users.title')}
          </Text>
          {loading && <LoadingIcon />}
        </Box>
        {isAdminDomain || !isFederatedIdentity ? (
          <RouteLink
            to={RouteHelper.adminUsers('new')}
            component={props => (
              <Button
                intent="primary"
                strong
                iconAfter={<Icon name="plus" />}
                circle
                size="small"
                {...props}
              />
            )}
          />
        ) : null}
      </Box>
      {targetUserLineItem}
      <Box data-testid="admin-users-list">
        <Schedule
          columns={columns}
          hasContextMenu={true}
          items={userLineItems.map((item: Profile) => (
            <MemoizedUserlistLineItem
              href={RouteHelper.adminUsers(item.id)}
              onDeleteUser={setDeleteUserDialogUser}
              profile={profile}
              key={item.email}
              userLineItem={item}
              {...item}
            />
          ))}
        />
      </Box>
    </>
  );
};

export default Users;

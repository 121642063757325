import { WorkspaceGroup } from '@entities/WorkspaceGroup';

export * from '@entities/WorkspaceGroup';

export const fromString = (input: string): WorkspaceGroup => {
  switch (input.toLowerCase()) {
    case 'user':
      return WorkspaceGroup.User;
    default:
    case 'workspace':
      return WorkspaceGroup.Workspace;
  }
};

export const toString = (workspace: WorkspaceGroup): string => {
  switch (workspace) {
    case WorkspaceGroup.User:
      return 'user';
    case WorkspaceGroup.Workspace:
      return 'workspace';
  }
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Box } from '@webMolecules/Box/Box';
import { Header } from '@webOrganisms/Header/Header';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { selectProfile } from '@selectors/BaseSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { authenticateUseCase } from '@useCases/Authentication';
import { selectIsSessionLoading } from '@selectors/SessionSelectors';
import { LoadingLayout } from '@webPages/Study/LoadingLayout';
import { locationUseCase } from '@useCases/Location';
import * as RouteHelper from '@helpers/routes';
import { ImageProvider } from '@hooks/useImageContext';
import { eventStudyActionOpenedStudy } from '@webInterfaces/Analytics';
import DebugBar from '../DebugBar';
import { ReportPDFContextProvider } from '../ReportPDFContext';
import { SonographerNavBar } from './SonographerNavBar';
import { SonographerSession } from './SonographerSession';
import { HeaderUserFilter } from './HeaderUserFilter';
import styles from './sonographer.scss';
import { HeaderDropdownMenu } from './HeaderDropdownMenu';
import { StudyTitle } from './StudyTitle';

export const SonographerLayout: React.FC = () => {
  const { analysisId, sessionId, tab } = useParams<{
    analysisId: string;
    sessionId: string;
    tab?: string;
  }>();
  const me = useSelector(selectProfile);
  const isLoading = useSelector(selectIsSessionLoading);

  const onSignOut = useDispatcher(authenticateUseCase.SignOut);
  const dispatchUpdateLocation = useDispatcher(locationUseCase.Update);

  const [currentTab, setCurrentTab] = useState(tab);

  const updateTab = (tab?: string) => {
    setCurrentTab(tab);
    dispatchUpdateLocation({
      pathname: RouteHelper.study(analysisId, sessionId, tab),
      replace: false,
      alwaysUseWindowHistory: true,
    });
  };

  React.useEffect(() => {
    if (!isLoading) {
      eventStudyActionOpenedStudy();
    }
  }, [isLoading]);

  return (
    <ImageProvider>
      <ReportPDFContextProvider>
        <Box
          display="flex"
          flexDirection="column"
          className={styles.fullHeight}
        >
          <DebugBar />
          <Header
            profile={me}
            sticky={false}
            onSignOut={onSignOut}
            showSearchBar={false}
          >
            {isLoading ? (
              <Box display="flex" alignItems="center">
                <LoadingIcon />
              </Box>
            ) : (
              <>
                <StudyTitle />
                <SonographerNavBar tab={currentTab} updateTab={updateTab} />
                <Box flex="1 0 auto">{/* Spacer */}</Box>
                <Box className={styles.borderSection} paddingX="m">
                  <HeaderUserFilter />
                  <HeaderDropdownMenu />
                </Box>
              </>
            )}
          </Header>
          {isLoading ? (
            <LoadingLayout />
          ) : (
            <>
              <Box data-testid="page-loaded-indicator" display="inline" />
              <SonographerSession tab={currentTab} />
            </>
          )}
        </Box>
      </ReportPDFContextProvider>
    </ImageProvider>
  );
};

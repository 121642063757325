import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Stack } from '@webMolecules/Stack/Stack';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { t } from '@webInterfaces/I18n';
import styles from './layout.scss';

export const BlockedDomain = () => {
  return (
    <Box className={styles.formContainer}>
      <Stack spacing="none" direction="vertical">
        <Box paddingBottom="m" className={styles.textAlign}>
          <Text type="display2">{t(`blocked_domain.modal.line.one`)}</Text>
        </Box>
        <Box className={styles.textAlign} paddingBottom="m">
          <Text muted type="body">
            {t('blocked_domain.modal.line.two')}
          </Text>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            strong
            intent="neutral"
            iconBefore={<Icon size="small" name="mail" />}
          >
            <Text type="body2">{t('blocked_domain.modal.button.email')} </Text>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
  createAction,
} from '@reduxjs/toolkit';

type ActionCreators<T extends string> = {
  noPayload: () => ActionCreatorWithoutPayload<T>;
  withPayload: <P = void>() => ActionCreatorWithPayload<P, T>;
};

/*
 * A wrapper around RTK createAction with necessary typings to integrate
 * with existing code
 */
export function createUseCase<T extends string>(type: T): ActionCreators<T> {
  return {
    noPayload: () => createAction(type) as ActionCreatorWithoutPayload<T>,
    withPayload: <P>() => createAction(type) as ActionCreatorWithPayload<P, T>,
  };
}

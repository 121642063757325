import { Workflow } from '@entities/Workflow';
import { DistanceUnit } from './Unit';

export enum ThyroidPoleVariant {
  ThreePoles = 'three_poles',
  FivePoles = 'five_poles',
}

export enum SizeVariant {
  Size1D = 'size_1d',
  Size3D = 'size_3d',
}

export enum FindingSortType {
  Side = 'side',
  Severity = 'severity',
}

export interface InstitutionConfiguration {
  workflow?: Workflow;
  thyroidSizeVariant?: SizeVariant;
  breastSizeVariant?: SizeVariant;
  autosizing?: boolean;
  noduleSizeDisplayUnit?: DistanceUnit.CM | DistanceUnit.MM;
  minimumNoduleSizeThreshold?: string;
  showBiradsScore?: boolean;
  simplifiedBreastReportingWorkflow?: boolean;
  thyroidAutoGrouping?: boolean;
  thyroidPoleVariant?: ThyroidPoleVariant;
  thyroidSortType?: FindingSortType;
  breastSortType?: FindingSortType;
}

export const withDefaultConfigs = (
  config: InstitutionConfiguration
): Required<InstitutionConfiguration> => {
  return {
    workflow: config.workflow ?? Workflow.Sonographer,
    thyroidSizeVariant: config.thyroidSizeVariant ?? SizeVariant.Size1D,
    breastSizeVariant: config.breastSizeVariant ?? SizeVariant.Size3D,
    autosizing: !!config.autosizing,
    noduleSizeDisplayUnit: config.noduleSizeDisplayUnit ?? DistanceUnit.CM,
    minimumNoduleSizeThreshold: config.minimumNoduleSizeThreshold ?? '',
    showBiradsScore: !!config.showBiradsScore,
    simplifiedBreastReportingWorkflow:
      !!config.simplifiedBreastReportingWorkflow,
    thyroidAutoGrouping: !!config.thyroidAutoGrouping,
    thyroidPoleVariant:
      config.thyroidPoleVariant ?? ThyroidPoleVariant.FivePoles,
    thyroidSortType: config.thyroidSortType ?? FindingSortType.Side,
    breastSortType: config.breastSortType ?? FindingSortType.Side,
  };
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Modal } from '@webMolecules/Modal/Modal';
import { Card } from '@webMolecules/Card/Card';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import { MemoizedPACSStudyLineItem } from '@webPages/Worklist/PACSStudyLineItem';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { PACSStudyLineItem } from '@interfaces/PACSStudyLineItem';
import { Table, TableCell, TableRow } from '@webMolecules/Table/Table';
import styles from './pacsModal.scss';

interface PACSModalProps {
  pacsStudyLineItems: PACSStudyLineItem[];
  isOpen: boolean;
  loading: boolean;
  isEmpty: boolean;
  onClose: () => void;
}

export const PACSModal = ({
  pacsStudyLineItems,
  isOpen,
  loading,
  isEmpty,
  onClose,
}: PACSModalProps) => {
  const columns = [
    { label: t('pages.worklists.pacs_study.heading.status') },
    { label: t('pages.worklists.pacs_study.heading.accession') },
    { label: t('pages.worklists.pacs_study.heading.operator_code') },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium">
      <Card padding="none" elevation="medium">
        <Box className={styles.modalBody} padding="xl">
          <Box className={styles.modalClose} padding="s">
            <Button
              onClick={onClose}
              iconBefore={<Icon name="x" />}
              circle
              strong
            />
          </Box>

          <Text type="display2" display="block">
            {t('pages.worklists.pacs_study.modal.heading')}
          </Text>
          <Box marginTop="l">
            <Table spacing="condensed">
              <TableRow>
                {columns.map(heading => (
                  <TableCell key={heading.label}>
                    <Text muted lineClamp={1} display="block" type="body2">
                      {t(heading.label)}&nbsp;
                    </Text>
                  </TableCell>
                ))}
              </TableRow>

              {pacsStudyLineItems.map((item: PACSStudyLineItem) => (
                <MemoizedPACSStudyLineItem
                  key={item.id}
                  pacsStudyLineItem={item}
                  {...item}
                />
              ))}
            </Table>
          </Box>

          {loading && (
            <Box marginTop="l" display="flex" justifyContent="center">
              <LoadingIcon />
            </Box>
          )}
          {isEmpty && (
            <Box margin="l" display="flex" justifyContent="center">
              <Text type="body2" muted display="block">
                {t(`pages.worklists.pacs_study.no_pacs_studies`)}
              </Text>
            </Box>
          )}
        </Box>
      </Card>
    </Modal>
  );
};

import Effect, { effect, none } from '@library/Effect';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Result, { err, ok } from '@monads/Result';
import { pair } from '@monads/Tuple';
import { nothing, just, fromFalsy } from '@monads/Maybe';
import { AdminInstitutions, initial } from '@entities/Admin/Institutions';
import { ApiStatus } from '@entities/Status';
import {
  AWS_REGION_KEYS,
  Institutions as ContractInstitutions,
} from '@contracts/Admin/Institutions';
import { Location as ContractLocation } from '@contracts/Location';
import {
  InstitutionLineItem,
  InstitutionStatus,
} from '@entities/Admin/InstitutionLineItem';
import { Institution } from '@entities/Admin/Institution';
import { notificationUseCase } from '@useCases/Notifications';
import * as RouteHelper from '@helpers/routes';
import { Profile } from '@entities/Profile';
import { createUseCase } from '@helpers/createUseCase';

export const institutionUseCase = {
  /**
   * Load Institutions
   */
  Load: createUseCase('ADMIN_CUSTOMERS_LOAD').noPayload(),

  /**
   * Load Institutions - Result
   */
  LoadResult: createUseCase('ADMIN_CUSTOMERS_LOAD_RESULT').withPayload<{
    institutionLineItems: InstitutionLineItem[];
  }>(),

  /**
   * On Unload
   * - optionally only unloads targetinstitution
   */
  Unload: createUseCase('ADMIN_CUSTOMERS_UNLOAD').withPayload<{
    target: 'all' | 'institution' | 'user';
  }>(),

  /**
   * Prepare a new institution
   */
  CreateEmpty: createUseCase('ADMIN_CUSTOMERS_CREATE_EMPTY').noPayload(),

  /**
   * Prepare a new institution users
   */
  CreateEmptyUser: createUseCase(
    'ADMIN_CUSTOMERS_CREATE_EMPTY_USER'
  ).noPayload(),

  /**
   * Set Users
   */
  SetUsers: createUseCase('ADMIN_INSTITUTIONS_SET_USERS').withPayload<{
    userLineItems: Profile[];
  }>(),

  /**
   * Allow admin can change create users
   */
  CreateUser: createUseCase('ADMIN_INSTITUTION_CREATE_USER').withPayload<{
    userLineItem: Profile;
  }>(),

  /**
   * Allow admin can update user
   */
  CreateUserResult: createUseCase(
    'ADMIN_INSTITUTIONS_CREATE_USER_RESULT'
  ).withPayload<{
    result: Result<string>;
  }>(),

  /**
   * Allow admin can update user
   */
  CreateUserError: createUseCase(
    'ADMIN_INSTITUTIONS_CREATE_USER_ERROR'
  ).noPayload(),

  /**
   * Store a newly created institution
   */
  Create: createUseCase('ADMIN_CUSTOMERS_CREATE').withPayload<{
    institution: InstitutionLineItem;
  }>(),

  /**
   * Store a newly created institution - Result
   */
  CreateResult: createUseCase('ADMIN_CUSTOMERS_CREATE_RESULT').withPayload<{
    institution: Result<InstitutionLineItem>;
  }>(),

  /**
   * Allow admin can delete users
   */
  DeleteUser: createUseCase('ADMIN_INSTITUTION_DELETE_USER').withPayload<{
    id: string;
  }>(),

  /**
   * Allow admin can update user
   */
  DeleteUserResult: createUseCase(
    'ADMIN_INSTITUTIONS_DELETE_USER_RESULT'
  ).withPayload<{
    result: Result<string>;
  }>(),

  /**
   * Get an institution
   */
  Get: createUseCase('ADMIN_CUSTOMERS_GET').withPayload<{
    id: string;
    userId?: string;
  }>(),

  /**
   * Get an institution - Result
   */
  GetResult: createUseCase('ADMIN_CUSTOMERS_GET_RESULT').withPayload<{
    institution: Result<Institution>;
    userId?: string;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('ADMIN_CUSTOMERS_NO_OP').noPayload(),
};

export type AdminInstitutionsUseCases = ReturnType<
  | typeof institutionUseCase.Load
  | typeof institutionUseCase.LoadResult
  | typeof institutionUseCase.Unload
  | typeof institutionUseCase.SetUsers
  | typeof institutionUseCase.CreateEmpty
  | typeof institutionUseCase.CreateEmptyUser
  | typeof institutionUseCase.Create
  | typeof institutionUseCase.CreateResult
  | typeof institutionUseCase.CreateUser
  | typeof institutionUseCase.CreateUserResult
  | typeof institutionUseCase.DeleteUser
  | typeof institutionUseCase.DeleteUserResult
  | typeof institutionUseCase.Get
  | typeof institutionUseCase.GetResult
  | typeof institutionUseCase.NoOp
>;

export class AdminInstitutionsReducer extends EffectReducer<AdminInstitutions> {
  private apiInstitution: ContractInstitutions;
  private apiLocation: ContractLocation;

  constructor(
    apiInstitution: ContractInstitutions,
    apiLocation: ContractLocation
  ) {
    super();
    this.apiInstitution = apiInstitution;
    this.apiLocation = apiLocation;
  }

  Perform(
    adminInstitutions: AdminInstitutions = initial(),
    { type, payload: useCase }: AdminInstitutionsUseCases
  ): ReducerResult<AdminInstitutions> {
    switch (type) {
      case institutionUseCase.Load.type: {
        return this.Result(
          {
            ...adminInstitutions,
            targetInstitution: pair(ApiStatus.Idle, nothing()),
            institutionLineItems:
              adminInstitutions.institutionLineItems.setFirst(ApiStatus.Busy),
          },
          effectInstitutionList(this.apiInstitution)
        );
      }

      case institutionUseCase.LoadResult.type:
        return this.Result({
          ...adminInstitutions,
          targetInstitution: pair(ApiStatus.Idle, nothing()),
          institutionLineItems: adminInstitutions.institutionLineItems
            .setFirst(ApiStatus.Idle)
            .setSecond(useCase.institutionLineItems),
        });

      case institutionUseCase.Unload.type: {
        switch (useCase.target) {
          case 'all': {
            this.CancelLastEffect();
            return this.Result(initial());
          }
          case 'institution': {
            return this.Result(
              {
                ...adminInstitutions,
                targetInstitutionLineItem: nothing(),
                targetInstitution: pair(ApiStatus.Idle, nothing()),
                targetUser: nothing(),
              },
              effectUpdateLocation(this.apiLocation)
            );
          }

          case 'user': {
            return this.Result(
              {
                ...adminInstitutions,
                targetUser: nothing(),
              },
              effectUpdateLocation(
                this.apiLocation,
                adminInstitutions.targetInstitution
                  .second()
                  .map(a => a.id)
                  .lift()
              )
            );
          }
        }
      }

      case institutionUseCase.SetUsers.type:
        return this.Result({
          ...adminInstitutions,
          institutionUserLineItems: pair(ApiStatus.Idle, useCase.userLineItems),
        });

      case institutionUseCase.CreateEmpty.type:
        return this.Result({
          ...adminInstitutions,
          targetInstitutionLineItem: just({
            id: '',
            createdAt: new Date(),
            institutionCopyText: '',
            region: AWS_REGION_KEYS[0],
            status: InstitutionStatus.NotDeployed,
            syncing: nothing<Date>(),
          }),
        });

      case institutionUseCase.CreateEmptyUser.type:
        return this.Result({
          ...adminInstitutions,
          targetUser: just({
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            operatorMapping: '',
            operatorMappingProperties: [{ column: 'operator_code', value: '' }],
            userProfileId: '',
            isAdmin: false,
            institutionId: 'See-Mode',
            logo: '',
            kmsKeyArn: '',
            nativeRegion: '',
            swatchColour: '',
            configuration: {},
            preferences: {},
            isActive: false,
          }),
        });

      case institutionUseCase.Create.type:
        return this.Result(
          {
            ...adminInstitutions,
            institutionLineItems:
              adminInstitutions.institutionLineItems.setFirst(ApiStatus.Busy),
          },
          effectInstitutionCreate(this.apiInstitution, useCase.institution)
        );

      case institutionUseCase.CreateResult.type: {
        const intent = useCase.institution.isOk() ? 'success' : 'error';
        const message = notificationUseCase.Add({
          notification: {
            intent,
            titleI18nKey: `admin.institution.create.${intent}`,
          },
        });

        if (intent === 'success') {
          const institution = useCase.institution.lift() as InstitutionLineItem;
          return this.Result(
            {
              ...adminInstitutions,
              institutionLineItems: adminInstitutions.institutionLineItems
                .setFirst(ApiStatus.Busy)
                .mapSecond(i => [...i, institution]),
            },
            none(),
            [message]
          );
        }
        return this.Result(
          {
            ...adminInstitutions,
          },
          none(),
          [institutionUseCase.Load(), message]
        );
      }
      case institutionUseCase.CreateUser.type:
        return this.Result(
          {
            ...adminInstitutions,
            institutionUserLineItems:
              adminInstitutions.institutionUserLineItems.mapFirst(
                () => ApiStatus.Busy
              ),
          },
          adminInstitutions.targetInstitution
            .second()
            .mapOr(
              a =>
                effectCreateUser(
                  this.apiInstitution,
                  a.id,
                  useCase.userLineItem
                ),
              none()
            )
        );

      case institutionUseCase.CreateUserResult.type:
        return this.Result(
          {
            ...adminInstitutions,
            institutionUserLineItems:
              adminInstitutions.institutionUserLineItems.mapFirst(
                () => ApiStatus.Busy
              ),
          },
          adminInstitutions.targetInstitution
            .second()
            .mapOr(
              a => effectInstitutionGet(this.apiInstitution, a.id),
              none()
            ),
          [
            useCase.result.isOk()
              ? notificationUseCase.Add({
                  notification: {
                    intent: 'success',
                    titleI18nKey:
                      'admin.institutions.message.user.created.success',
                  },
                })
              : notificationUseCase.Add({
                  notification: {
                    intent: 'error',
                    titleI18nKey:
                      'admin.institutions.message.user.created.error',
                  },
                }),
          ]
        );
      case institutionUseCase.DeleteUser.type:
        return this.Result(
          {
            ...adminInstitutions,
            institutionUserLineItems:
              adminInstitutions.institutionUserLineItems.mapFirst(
                () => ApiStatus.Busy
              ),
          },
          adminInstitutions.targetInstitution
            .second()
            .mapOr(
              a => effectDeleteUser(this.apiInstitution, a.id, useCase.id),
              none()
            )
        );

      case institutionUseCase.DeleteUserResult.type:
        return this.Result(
          {
            ...adminInstitutions,
            institutionUserLineItems:
              adminInstitutions.institutionUserLineItems.mapFirst(
                () => ApiStatus.Busy
              ),
          },
          adminInstitutions.targetInstitution
            .second()
            .mapOr(
              a => effectInstitutionGet(this.apiInstitution, a.id),
              none()
            ),
          [
            useCase.result.isOk()
              ? notificationUseCase.Add({
                  notification: {
                    intent: 'success',
                    titleI18nKey:
                      'admin.institutions.message.user.deleted.success',
                  },
                })
              : notificationUseCase.Add({
                  notification: {
                    intent: 'error',
                    titleI18nKey:
                      'admin.institutions.message.user.deleted.error',
                  },
                }),
          ]
        );
      case institutionUseCase.Get.type:
        return this.Result(
          {
            ...adminInstitutions,
            targetInstitution: pair(ApiStatus.Busy, nothing()),
          },
          effectInstitutionGet(this.apiInstitution, useCase.id, useCase.userId)
        );

      case institutionUseCase.GetResult.type: {
        const maybeInstitution = useCase.institution.toMaybe();
        const targetUser = useCase.userId
          ? maybeInstitution.andThen(i =>
              fromFalsy(i.users.find(u => u.id === useCase.userId))
            )
          : nothing<Profile>();

        return this.Result(
          {
            ...adminInstitutions,
            targetInstitution: pair(ApiStatus.Idle, maybeInstitution),
            targetUser,
          },
          none(),
          useCase.institution.isOk()
            ? []
            : [
                notificationUseCase.Add({
                  notification: {
                    intent: 'error',
                    titleI18nKey: 'admin.institution.get.error',
                  },
                }),
              ]
        );
      }

      case institutionUseCase.NoOp.type:
        return this.Result({ ...adminInstitutions });
    }
  }
}

const effectCreateUser = (
  apiInstitutionList: ContractInstitutions,
  institutionId: string,
  userLineItem: Profile
): Effect =>
  effect(async () => {
    try {
      const res = await apiInstitutionList.CreateUser(
        institutionId,
        userLineItem
      );
      return institutionUseCase.CreateUserResult({
        result: ok(res),
      });
    } catch (e) {
      return institutionUseCase.CreateUserResult({
        result: err(new Error(String(e))),
      });
    }
  }, 'effect-admin-institution-create-user');

const effectDeleteUser = (
  apiInstitutionList: ContractInstitutions,
  institutionId: string,
  id: string
): Effect =>
  effect(async () => {
    try {
      const res = await apiInstitutionList.DeleteUser(institutionId, id);
      return institutionUseCase.DeleteUserResult({
        result: ok(res),
      });
    } catch (e) {
      return institutionUseCase.DeleteUserResult({
        result: err(new Error(String(e))),
      });
    }
  }, 'effect-admin-institution-create-user');

const effectInstitutionList = (
  apiInstitutionList: ContractInstitutions
): Effect =>
  effect(
    async () =>
      institutionUseCase.LoadResult({
        institutionLineItems: await apiInstitutionList.List(),
      }),
    'effect-admin-institutions-list'
  );

const effectInstitutionGet = (
  apiInstitutionList: ContractInstitutions,
  id: string,
  userId?: string
): Effect =>
  effect(async () => {
    try {
      const institution = await apiInstitutionList.Get(id);
      return institutionUseCase.GetResult({
        institution: ok(institution),
        userId,
      });
    } catch (e) {
      return institutionUseCase.NoOp();
    }
  }, 'effect-admin-institutions-get');

const effectInstitutionCreate = (
  apiInstitutionLineItem: ContractInstitutions,
  institutionLineItem: InstitutionLineItem
): Effect =>
  effect(async () => {
    try {
      const id = await apiInstitutionLineItem.Create(institutionLineItem);
      return institutionUseCase.CreateResult({
        institution: ok({ ...institutionLineItem, id }),
      });
    } catch (e) {
      return institutionUseCase.CreateResult({
        institution: err(new Error(String(e))),
      });
    }
  }, 'effect-admin-institutions-create');

const effectUpdateLocation = (
  apiLocation: ContractLocation,
  institutionId?: string,
  userId?: string
): Effect =>
  effect(async () => {
    const pathname = RouteHelper.adminInstitutions(institutionId, userId);
    if (window.location.pathname !== pathname) {
      apiLocation.Change(pathname, true);
    }
    return institutionUseCase.NoOp();
  }, 'effect-admin-institutions-update-location');

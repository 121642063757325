import React, { useState } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { AboutModal } from '@webOrganisms/AboutModal/AboutModal';
import { PACSModal } from '@webOrganisms/PACSModal/PACSModal';
import { t } from '@webInterfaces/I18n';
import {
  mapPacsSystemStatusToColour,
  PACSStudyLineItem,
  PACSSystemStatusToString,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import { Status } from '@webMolecules/Status/Status';
import { Divider } from '@webMolecules/Divider/Divider';
import { SidebarGroup } from '@webOrganisms/SidebarGroup/SidebarGroup';
import { eventWorklistCheckedPacsStatus } from '@webInterfaces/Analytics';
import styles from './sidebar.scss';

export interface SidebarProps {
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  children?: React.ReactNode;
}

export const Sidebar = ({
  pacsStudyLineItems,
  pacsSystemStatus,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  children,
}: SidebarProps) => {
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isPACSModalOpen, setIsPACSModalOpen] = useState(false);

  const onClickPacsStatus = () => {
    eventWorklistCheckedPacsStatus();
    setIsPACSModalOpen(true);
  };

  const pacsLoading = false;
  const isEmpty = pacsStudyLineItems.length === 0 && pacsLoading === false;
  let pacsSystemStatusIntent:
    | 'neutral'
    | 'success'
    | 'error'
    | 'information'
    | 'warning';
  const pacsSystemStatusColour: typeof pacsSystemStatusIntent =
    mapPacsSystemStatusToColour(pacsSystemStatus);

  return (
    <Box
      role="nav"
      className={styles.sidebar}
      display="flex"
      flexDirection="column"
    >
      <Box flex="1" paddingTop="xl" scrollable="y">
        {children}
      </Box>

      <Box className={styles.sidebarFooter}>
        <SidebarGroup>
          <button onClick={onClickPacsStatus} className={styles.buttonLink}>
            <Box display="flex" alignItems="center" marginY="m">
              <Status intent={pacsSystemStatusColour} />
              <Text marginLeft="xs" type="caption" display="block">
                {t(PACSSystemStatusToString(pacsSystemStatus))}
              </Text>
            </Box>
          </button>
        </SidebarGroup>
        <Divider color="var(--ds-border-color-weak)" />
        <SidebarGroup>
          <Box>
            <Box display="flex" alignItems="center" marginY="m">
              <button
                onClick={() => setIsAboutModalOpen(true)}
                className={styles.buttonLink}
              >
                <Text
                  data-testid="test-open-about-modal"
                  type="caption"
                  display="block"
                >
                  {t('sidebar.about')}
                </Text>
              </button>
              <Box paddingX="s">
                <Text type="caption" display="block">
                  •
                </Text>
              </Box>
              <a
                href="https://www.see-mode.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                <Text type="caption" display="block">
                  {t('sidebar.privacy_policy')}
                </Text>
              </a>
              <AboutModal
                isOpen={isAboutModalOpen}
                regulatoryVersion={regulatoryVersion}
                regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
                onClose={() => setIsAboutModalOpen(false)}
              />
              <PACSModal
                pacsStudyLineItems={pacsStudyLineItems}
                isOpen={isPACSModalOpen}
                loading={pacsLoading}
                isEmpty={isEmpty}
                onClose={() => setIsPACSModalOpen(false)}
              />
            </Box>
          </Box>
        </SidebarGroup>
      </Box>
    </Box>
  );
};

import { schemas } from '@api/schema/client';
import {
  YesNoValue as APIYesNo,
  NormalAbnormalValue as APINormalAbnormal,
  ThyroidVascularityValue,
  EchotextureValue as APIEchotextureValue,
} from '@api/schema/generated';
import { Volume } from '@entities/Finding';
import {
  ThyroidGeneralCharacteristics,
  ThyroidLobeCharacteristicsData,
} from '@entities/ThyroidGeneralCharacteristics';
import {
  EchotextureValue,
  NormalAbnormalValue,
  NormalIncreasedValue,
  YesNoValue,
} from '@entities/Characteristics';
import { decodeSize, decodeVolume } from './Analysis';
import { VolumeUnitMap, encodeEnum, mapEnumByValue } from './Enum';
import { encodeSize } from './Finding';

export function decodeThyroidGeneralCharacteristics(
  data: schemas['ThyroidGeneralCharacteristicsData-Output']
): ThyroidGeneralCharacteristics {
  return {
    rightLobe: decodeThyroidLobeCharacteristics(data.right_lobe),
    leftLobe: decodeThyroidLobeCharacteristics(data.left_lobe),
    isthmusThickness: decodeSize(data.isthmus_thickness),
    trachealDeviation: mapEnumByValue(data.tracheal_deviation, YesNoValue),
    lymphNodes: mapEnumByValue(data.lymph_nodes, NormalAbnormalValue),
    abnormalLymphNodesText: data.abnormal_lymph_nodes_text,
  };
}

export function decodeThyroidLobeCharacteristics(
  data: schemas['ThyroidLobeCharacteristicsData-Output']
): ThyroidLobeCharacteristicsData {
  return {
    length: decodeSize(data.length),
    width: decodeSize(data.width),
    height: decodeSize(data.height),
    volume: decodeVolume(data.volume),
    vascularity: mapEnumByValue(data.vascularity, NormalIncreasedValue),
    echotexture: mapEnumByValue(data.echotexture, EchotextureValue),
    retrosternal: mapEnumByValue(data.retrosternal, YesNoValue),
  };
}

export function encodeThyroidGeneralCharacteristics(
  generalCharacteristics: ThyroidGeneralCharacteristics
): schemas['ThyroidGeneralCharacteristicsInput'] {
  return {
    right_thyroid_side_characteristics: encodeThyroidLobeCharacteristics(
      generalCharacteristics.rightLobe
    ),
    left_thyroid_side_characteristics: encodeThyroidLobeCharacteristics(
      generalCharacteristics.leftLobe
    ),
    isthmus_thickness: encodeSize(generalCharacteristics.isthmusThickness),
    tracheal_deviation: mapEnumByValue(
      generalCharacteristics.trachealDeviation,
      APIYesNo
    ),
    lymph_nodes: mapEnumByValue(
      generalCharacteristics.lymphNodes,
      APINormalAbnormal
    ),
    abnormal_lymph_nodes_text: generalCharacteristics.abnormalLymphNodesText,
  };
}

export function encodeThyroidLobeCharacteristics(
  lobe: ThyroidLobeCharacteristicsData
): schemas['ThyroidLobeCharacteristicsData-Input'] {
  return {
    length: encodeSize(lobe.length),
    width: encodeSize(lobe.width),
    height: encodeSize(lobe.height),
    volume: encodeVolume(lobe.volume),
    vascularity: mapEnumByValue(lobe.vascularity, ThyroidVascularityValue),
    echotexture: mapEnumByValue(lobe.echotexture, APIEchotextureValue),
    retrosternal: mapEnumByValue(lobe.retrosternal, APIYesNo),
  };
}

export function encodeVolume(
  data: Volume | null
): schemas['VolumeData'] | null {
  if (!data) {
    return null;
  }
  return {
    value: data.value,
    unit: encodeEnum(data.unit, VolumeUnitMap),
  };
}

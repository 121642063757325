import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import {
  cancel2fa,
  federatedSignIn,
  signIn,
  signInWithMFACode,
} from '@webViewInterfaces/Pages/Authenticate/SignIn';
import { toAuth, toLatestMessage } from '@interfaces/Root';
import {
  isBusy,
  isDomainBlocked,
  toHasMFAEnabled,
} from '@interfaces/Authentication';
import { Message } from '@interfaces/Message';

export type SignIn = SignInProps & SignInDispatchers;

export interface SignInProps {
  loading?: boolean;
  blocked: boolean;
  latestMessage?: Message;
  hasMFAEnabled?: boolean;
}

export interface SignInDispatchers {
  dispatchSignIn: (email: string, password: string) => Dispatch<Actions>;
  dispatchSignInWithMFACode: (otp: string) => Dispatch<Actions>;
  dispatchCancelMFA: () => Dispatch<Actions>;
  dispatchFederatedSignIn: () => Dispatch<Actions>;
}

export const dispatchers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any
) => ({
  dispatchSignIn: (email: string, password: string) =>
    dispatch(signIn(email, password)),
  dispatchSignInWithMFACode: (otp: string) => dispatch(signInWithMFACode(otp)),
  dispatchCancelMFA: () => dispatch(cancel2fa()),
  dispatchFederatedSignIn: () => dispatch(federatedSignIn()),
});

export const fromState = (state: State): SignInProps => {
  const auth = toAuth(state);
  const blocked = isDomainBlocked(auth);
  const latestMessage = toLatestMessage(state);
  const mfaEnabled = toHasMFAEnabled(auth);
  const loading = isBusy(auth);

  return {
    loading,
    blocked,
    latestMessage,
    hasMFAEnabled: mfaEnabled,
  };
};

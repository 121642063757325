import { authenticateUseCase } from '@useCases/Authentication';
import { Actions } from '@webInterfaces/StoreTypes';

export const forgotPasswordRequest = (email: string): Actions =>
  authenticateUseCase.ForgotPassword({ email });

export const forgotPasswordReset = (
  email: string,
  code: string,
  newPassword: string
): Actions =>
  authenticateUseCase.ForgotPasswordReset({ email, code, newPassword });

/* eslint-disable @typescript-eslint/no-explicit-any */
import Result, { err, ok } from '@monads/Result';

export interface QueueSettings {
  retries: number;
  retryJumpsQueue: boolean;
  retryTimeout: number;
  maxConcurrent: number;
  pauseOnDisconnect: boolean;
}

export const defaultQueueSettings: QueueSettings = {
  retries: 3,
  retryJumpsQueue: true,
  retryTimeout: 500,
  maxConcurrent: 20,
  pauseOnDisconnect: true,
};

interface QueuedItem<T> {
  f: () => Promise<T>;
  onResult?: (result: Result<T>) => void;
  retriesLeft: number;
}

export class Queue {
  private _settings: QueueSettings;
  private _status = 0;
  private _queue: Array<QueuedItem<any>> = [];
  private isOnline: boolean = window.navigator.onLine;

  constructor(settings: QueueSettings = defaultQueueSettings) {
    this._settings = settings;

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });

    window.addEventListener('online', () => {
      this.isOnline = true;
    });
  }

  public status() {
    return this._status;
  }

  public add<T>(
    f: () => Promise<T>,
    onResult?: (result: Result<T>) => void
  ): void {
    this._queue.push({
      f,
      onResult,
      retriesLeft: this._settings.retries,
    });

    this._start();
  }

  private _start(): void {
    for (
      let i = 0 + this._status;
      i < Math.max(this._settings.maxConcurrent, 1);
      i++
    ) {
      this._next();
    }
  }

  private _next(): void {
    const queueItem = this._queue.shift();
    if (!queueItem) {
      return;
    }
    this._status++;

    this._run(queueItem);
  }

  private _run(queueItem: QueuedItem<any>): void {
    if (this.isOnline === false && this._settings.pauseOnDisconnect === true) {
      setTimeout(
        () => this._run(queueItem),
        Math.min(500, this._settings.retryTimeout)
      );
      return;
    }

    (async () => {
      try {
        const result = await queueItem.f();
        if (queueItem.onResult) {
          this._status--;
          queueItem.onResult(ok(result));
          this._next();
        }
      } catch (e) {
        if (queueItem.retriesLeft > 0) {
          queueItem.retriesLeft--;
          if (this._settings.retryTimeout > 0) {
            setTimeout(() => {
              if (this._settings.retryJumpsQueue) {
                this._run(queueItem);
              } else {
                this.add(queueItem.f, queueItem.onResult);
                this._next();
              }
            }, this._settings.retryTimeout);
          } else {
            if (this._settings.retryJumpsQueue) {
              this._run(queueItem);
            } else {
              this.add(queueItem.f, queueItem.onResult);
              this._next();
            }
          }
        } else {
          this._status--;
          if (queueItem.onResult) {
            queueItem.onResult(err(new Error(String(e))));
            this._next();
          }
        }
      }
    })();
  }
}

export default Queue;

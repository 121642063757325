import React from 'react';
import { Box } from '@webMolecules/Box/Box';

import styles from './footer.scss';

export interface FooterProps {
  children?: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    // TODO: consider what and how to spread props to Label component
    <Box
      paddingX="s"
      paddingY="xs"
      alignItems="center"
      justifyContent="center"
      className={styles.outerContainer}
      display="flex"
      gap="xxl"
    >
      {children}
    </Box>
  );
};

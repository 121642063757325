import React, { CSSProperties } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { cnames } from '@helpers/cnames';
import styles from './table.module.scss';

export interface TableProps {
  striped?: boolean;
  spacing?: 'condensed' | 'relaxed';
  bordered?: boolean;
  children: React.ReactNode;
  className?: string;
  overflow?: boolean;
  padded?: boolean;
}

export const Table: React.FC<TableProps> = ({
  children,
  striped,
  bordered,
  spacing,
  className,
  overflow,
  padded = false,
}) => {
  const classnames = cnames(
    styles.table,
    {
      [styles.striped]: striped,
      [styles.condensed]: spacing === 'condensed',
      [styles.relaxed]: spacing === 'relaxed',
      [styles.overflow]: overflow,
      [styles.padded]: padded,
      [styles.bordered]: bordered,
      [styles.rounded]: bordered,
    },
    className
  );
  return (
    <Box element="table" className={classnames}>
      <Box element="tbody" className={styles.tableBody}>
        {children}
      </Box>
    </Box>
  );
};

export interface TableRowProps {
  className?: string;
}

export const TableRow: React.FC<TableRowProps> = ({ children, className }) => {
  const classnames = cnames(styles.tableRow, className);

  return (
    <Box element="tr" className={classnames}>
      {children}
    </Box>
  );
};

export interface TableCellProps {
  width?: string;
  minWidth?: string;
  bordered?: boolean;
  shaded?: boolean;
  verticalAlign?: CSSProperties['verticalAlign'];
}

export const TableCell: React.FC<TableCellProps> = ({
  width,
  minWidth,
  children,
  bordered,
  shaded,
  verticalAlign = 'middle',
}) => {
  const classnames = cnames(styles.tableCell, {
    [styles.shaded]: shaded,
    [styles.bordered]: bordered,
  });
  return (
    <Box
      element="td"
      className={classnames}
      style={{ width, minWidth, verticalAlign }}
    >
      {children}
    </Box>
  );
};

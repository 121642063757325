import * as React from 'react';
import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import { useSelect } from 'downshift';
import {
  size,
  useFloating,
  FloatingPortal,
  flip,
  useDismiss,
  useInteractions,
} from '@floating-ui/react';
import { useState } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { cnames } from '@helpers/cnames';
import { Skeleton } from '@webMolecules/Skeleton/Skeleton';
import { Field, FieldProps } from '@webMolecules/Field/Field';
import { Input } from '@webMolecules/Input/Input';
import { Divider } from '@webMolecules/Divider/Divider';
import { Button, ButtonProps } from '@webMolecules/Button/Button';
import { t } from '@webInterfaces/I18n';
import { Checkbox } from '@webMolecules/Checkbox/Checkbox';
import styles from './menuSelect.scss';

interface SelectOption {
  label: string;
  value: string | undefined;
  separator?: boolean;
}

interface BaseMenuSelectProps {
  disabled?: boolean;
  fluid?: boolean;
  intent?: 'success' | 'neutral' | 'error' | 'required' | 'warning';
  variant?: 'normal' | 'pill' | 'pill-outline';
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  options: SelectOption[];
  skeleton?: boolean;
  controlled?: boolean;
  'data-testid'?: string;
  placeholderDisabled?: boolean;
  showInput?: boolean;
  buttonLabel?: string;
  buttonIntent?: ButtonProps['intent'];
  icon?: 'chevron-down' | 'pen';
  overrideLabel?: string;
}

interface SingleSelectProps {
  multiSelect?: false;
  value?: string;
  onChange: (value: string) => void;
  onInputChange?: (value: string) => void;
}

interface MultiSelectProps {
  multiSelect: true;
  header: string;
  value: string[];
  onChange: (value: string[]) => void;
  onToggle?: (oldValues: string[], newValues: string[]) => string[];
}

type SingleOrMultiSelectProps = BaseMenuSelectProps &
  (SingleSelectProps | MultiSelectProps);

export type MenuSelectProps = BaseMenuSelectProps & SingleSelectProps;

interface MenuSelectInputProps {
  value: string | undefined;
  closeMenu(): void;
  onChange(value: string | undefined): void;
  buttonLabel?: string;
}

export const MenuSelectInput: React.FC<MenuSelectInputProps> = ({
  value,
  closeMenu,
  onChange,
  buttonLabel,
}) => {
  const [inputValue, setInputValue] = React.useState(value);
  const valueRef = React.useRef(inputValue);

  React.useEffect(() => {
    valueRef.current = inputValue;
  }, [inputValue]);

  const handleClick = () => {
    closeMenu();
    onChange(valueRef.current);
  };

  React.useEffect(() => {
    const upHandler = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleClick();
      }
    };

    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="s"
      marginTop="xs"
      marginBottom="xxs"
      marginX="xs"
    >
      <Input
        size="small"
        type="text"
        strong
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        intent={value && inputValue == value ? 'primary' : undefined}
      />
      <Button size="small" intent="neutral" strong subtle onClick={handleClick}>
        {buttonLabel || t('gallery.finding.menu.done')}
      </Button>
    </Box>
  );
};

interface MultiSelectListProps {
  selectedValues: string[];
  options: SelectOption[];
  header: string;
  onChange(values: string[]): void;
  onToggle?(oldValues: string[], newValues: string[]): string[];
}

const MultiSelectList: React.FC<MultiSelectListProps> = ({
  selectedValues,
  options,
  header,
  onChange,
  onToggle,
}) => {
  const [currentValues, setCurrentValues] = useState(selectedValues);

  const handleToggle = (values: string[]) => {
    const modifiedValues = onToggle ? onToggle(currentValues, values) : values;
    setCurrentValues(modifiedValues);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX="s"
        paddingBottom="xs"
      >
        <Text type="display5">{header}</Text>
        <Button strong intent="primary" onClick={() => onChange(currentValues)}>
          {t('generic.submit.done')}
        </Button>
      </Box>
      <Divider className={styles.separator} />
      <Box paddingTop="xs" element="ul" data-testid="select-option-list">
        {options.map(option => {
          const { value } = option;
          if (!value) return null;

          const checked = currentValues.includes(value);
          const toggleOption = () => {
            if (checked) {
              handleToggle(currentValues.filter(v => v !== value));
            } else {
              handleToggle([...currentValues, value]);
            }
          };

          return (
            <>
              <Box
                fullWidth
                display="flex"
                gap="s"
                alignItems="center"
                justifyContent="start"
                element="li"
                key={option.value}
                className={styles.checkboxOption}
              >
                <Box className={styles.optionIconBox}>
                  <Checkbox checked={checked} onChange={toggleOption} />
                </Box>
                <Box onClick={toggleOption}>
                  <Text type="body2">{option.label}</Text>
                </Box>
              </Box>
              {option.separator && <Divider key="divider" />}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export const MenuSelect: React.FC<SingleOrMultiSelectProps> = menuProps => {
  const {
    placeholder,
    disabled,
    controlled = true,
    showInput,
    placeholderDisabled,
    value,
    buttonLabel,
    variant = 'normal',
    icon = 'chevron-down',
    overrideLabel,
  } = menuProps;
  const isPill = variant === 'pill' || variant === 'pill-outline';

  const options: SelectOption[] = [
    ...(placeholder && !placeholderDisabled
      ? [{ value: '', label: placeholder }]
      : []),
    ...menuProps.options,
  ];
  const optionsWithInputValue: SelectOption[] = [
    ...options,
    ...(showInput &&
    !menuProps.multiSelect &&
    menuProps.value &&
    !menuProps.options.find(o => o.value === menuProps.value)
      ? [{ value: menuProps.value, label: menuProps.value }]
      : []),
  ];
  const {
    isOpen,
    closeMenu,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: optionsWithInputValue,
    selectedItem: controlled
      ? optionsWithInputValue.find(
          o => !isUndefined(menuProps.value) && o.value === menuProps.value
        ) ?? null
      : undefined,
    initialSelectedItem: optionsWithInputValue.find(
      o => o.value === menuProps.value
    ),
    onSelectedItemChange: ({ selectedItem }) => {
      if (!menuProps.multiSelect) {
        menuProps.onChange(selectedItem?.value ?? '');
      }
    },

    stateReducer: (state, changes) => {
      // this prevents the menu from being closed when the user

      switch (changes.type) {
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          return {
            ...changes,
            isOpen: true,
            selectedItem: state.selectedItem,
          };
        case useSelect.stateChangeTypes.FunctionCloseMenu:
          return {
            ...changes,
            isOpen: false,
            selectedItem: state.selectedItem,
          };

        default:
          return { ...state, ...changes.changes };
      }
    },
  });

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      if (!open) closeMenu();
    },
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
          });
        },
      }),
      flip({ mainAxis: true, crossAxis: true }),
    ],
  });

  const dismiss = useDismiss(context, { ancestorScroll: true });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const selectClassNames = cnames(styles.select, {
    [styles[menuProps.size || 'medium']]: true,
    [styles.disabled]: disabled,
    [styles.pill]: isPill,
    [styles.pillOutline]: variant === 'pill-outline',
    [styles.isOpen]: isOpen,
  });

  const fieldProps: FieldProps = pick(
    menuProps,
    'disabled',
    'fluid',
    'size',
    'intent'
  );

  const listClasses = cnames(styles.list, {
    [styles[`list-${menuProps.size}`]]: showInput && menuProps.size == 'small',
  });

  return (
    <Field {...fieldProps} pill={isPill}>
      {menuProps.skeleton ? (
        <Skeleton size={menuProps.size} />
      ) : (
        <Box
          className={styles.selectWrapper}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <Box
            {...getToggleButtonProps({
              disabled,
              type: 'button',
            })}
            element="button"
            className={selectClassNames}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            data-testid={menuProps['data-testid']}
          >
            <Text type={isPill ? 'caption' : 'body2'}>
              {overrideLabel ??
                (selectedItem && selectedItem.value
                  ? selectedItem.label
                  : showInput
                    ? value
                    : placeholderDisabled
                      ? placeholder
                      : '')}
            </Text>
            <Icon
              name={isOpen ? 'chevron-down' : icon}
              size={icon === 'pen' ? 'medium' : 'small'}
            />
          </Box>
          <Box {...getMenuProps()}>
            {isOpen && (
              <FloatingPortal>
                <Box
                  className={styles.popup}
                  ref={refs.setFloating}
                  style={floatingStyles}
                  {...getFloatingProps()}
                >
                  <Box className={listClasses} scrollable="y">
                    {menuProps.multiSelect ? (
                      <MultiSelectList
                        selectedValues={menuProps.value}
                        header={menuProps.header}
                        options={options}
                        onChange={values => {
                          menuProps.onChange(values);
                          closeMenu();
                        }}
                        onToggle={menuProps.onToggle}
                      />
                    ) : (
                      <Box element="ul" data-testid="select-option-list">
                        {options.map((menuOption, index) => (
                          <Box
                            fullWidth
                            display="flex"
                            gap="xxs"
                            alignItems="center"
                            justifyContent="space-between"
                            element="li"
                            key={menuOption.value || menuOption.label}
                            className={cnames(styles.option, {
                              [styles.highlighted]:
                                highlightedIndex === index ? true : false,
                            })}
                            {...getItemProps({ item: menuOption, index })}
                          >
                            <Text type="body2">{menuOption.label}</Text>
                            <Box className={styles.optionIconBox}>
                              {!isUndefined(selectedItem?.value) &&
                                menuOption.value === selectedItem?.value && (
                                  <Icon
                                    size="medium"
                                    name="check"
                                    color="var(--ds-color-primary)"
                                  />
                                )}
                            </Box>
                          </Box>
                        ))}
                        {showInput && (
                          <>
                            <Divider className={styles.separator} />
                            <Box display="flex" justifyContent="center">
                              <MenuSelectInput
                                value={
                                  !menuProps.options.find(
                                    o => o.value === menuProps.value
                                  ) && menuProps.value
                                    ? menuProps.value
                                    : undefined
                                }
                                closeMenu={closeMenu}
                                onChange={value =>
                                  menuProps.onInputChange?.(value ?? '')
                                }
                                buttonLabel={buttonLabel}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </FloatingPortal>
            )}
          </Box>
        </Box>
      )}
    </Field>
  );
};

/**
 * Encodes the given institutionId and patientId using SHA-256 algorithm.
 * @param institutionId - The institution ID.
 * @param patientId - The patient ID.
 * @returns A promise that resolves to the encoded SHA-256 string.
 */
export const encodeSha256 = async (
  institutionId: string,
  patientId: string
): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(institutionId + patientId);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashedString = hashArray
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
  return hashedString;
};

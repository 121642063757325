import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './tag.scss';

export interface TagProps {
  label: string;
  strong?: boolean;
  intent?: 'primary' | 'secondary' | 'destructive';
}

export const Tag: React.FC<TagProps> = ({
  label,
  intent = 'primary',
  strong = false,
}) => {
  const classNames = cnames(styles.tagContainer, styles[intent], {
    [styles.strong]: strong,
  });
  return (
    <Box className={classNames} display="flex" justifyContent="center">
      <Text type="display6">{label}</Text>
    </Box>
  );
};

import { FilterConfigurationPropertyOperator } from '@entities/FilterConfiguration';
export * from '@entities/FilterConfiguration';

export const filterOperatorToString = (
  filterOperator: FilterConfigurationPropertyOperator
): string => {
  switch (filterOperator) {
    case FilterConfigurationPropertyOperator.Contains:
      return 'contains';
    case FilterConfigurationPropertyOperator.Matches:
      return 'matches';
  }
};

import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  arrow,
  Coords,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  Placement,
  Side,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { Card } from '@webMolecules/Card/Card';
import { cnames } from '@helpers/cnames';
import styles from './popover.scss';

const ARROW_WIDTH = 24;
const ARROW_HEIGHT = 12;

export interface PopoverProperty {
  trigger: ReactElement;
  keepOpen?: boolean;
  children: ReactNode;
  position?: Placement;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  padding?:
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  onOpen?: () => void;
  showArrow?: boolean;
}

interface PopoverContextProps {
  close(): void;
}

export const PopoverContext = createContext<PopoverContextProps>({
  close: () => null,
});

export const usePopoverContext = () => useContext(PopoverContext);

export const Popover: React.FC<PopoverProperty> = ({
  trigger,
  children,
  position = 'bottom-start',
  size,
  padding = 'm',
  keepOpen = false,
  onOpen,
  showArrow = false,
}) => {
  const arrowRef = useRef(null);

  const [open, setOpen] = useState(false);

  const classNames = cnames(styles.popover, {
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.extraLarge]: size === 'extra-large',
  });

  const onOpenChange = (o: boolean) => {
    setOpen(o);
    if (o) {
      onOpen?.();
    }
  };

  /* Positioning */

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open,
    onOpenChange,
    placement: position,
    strategy: 'fixed',
    middleware: [
      offset({
        mainAxis: 8,
        alignmentAxis: showArrow ? -16 : 0,
      }),
      flip({ mainAxis: true, crossAxis: true }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  /* Animations */

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 150,
    initial: {
      opacity: 0,
      transform: 'scale(0.95)',
    },
    common: ({ side }) => ({
      transitionTimingFunction: 'ease-out',
      willChange: 'transform',
      transformOrigin: getArrowTransformOrigin(middlewareData.arrow, side),
    }),
  });

  /* Interactions */

  const click = useClick(context, { toggle: true, event: 'mousedown' });
  const dismiss = useDismiss(context, {
    outsidePress: !keepOpen,
    ancestorScroll: !keepOpen,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const clonedTrigger = React.cloneElement(trigger, {
    ...getReferenceProps(),
    ref: refs.setReference,
  });

  return (
    <>
      {clonedTrigger}
      {isMounted && (
        <PopoverContext.Provider value={{ close: () => setOpen(false) }}>
          <FloatingPortal>
            <div
              className={classNames}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div style={transitionStyles}>
                {children && (
                  <Card elevation="medium" padding={padding}>
                    {children}
                  </Card>
                )}
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  fill={showArrow ? '#213846' : 'transparent'}
                  width={ARROW_WIDTH}
                  height={ARROW_HEIGHT}
                />
              </div>
            </div>
          </FloatingPortal>
        </PopoverContext.Provider>
      )}
    </>
  );
};

function getArrowTransformOrigin(
  arrow: Partial<Coords> | undefined,
  side: Side
) {
  // Use the arrow position to set the transform origin which affects scaling
  const arrowX = arrow?.x ?? 0;
  const arrowY = arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;

  switch (side) {
    case 'top':
      return `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`;
    case 'bottom':
      return `${transformX}px ${-ARROW_HEIGHT}px`;
    case 'left':
      return `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`;
    case 'right':
      `${-ARROW_HEIGHT}px ${transformY}px`;
  }
}

import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { effect } from '@library/Effect';
import { AdminApplication, initial } from '@entities/Admin/Application';
import { just } from '@monads/Maybe';
import { Institution as ContractInstitution } from '@contracts/Admin/Institution';
import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';
import { ApiStatus } from '@entities/Status';
import { appUseCase } from '@useCases/App';
import { createUseCase } from '@helpers/createUseCase';
import { profileUseCase } from '@useCases/Profile';
import { dispatch } from '@webInterfaces/Store';
import { notificationUseCase } from '@useCases/Notifications';

export const adminApplicationUseCase = {
  /**
   * Load Application settings page
   */
  Load: createUseCase('ADMIN_APPLICATION_LOAD').noPayload(),

  /**
   * On Unload
   */
  Unload: createUseCase('ADMIN_APPLICATION_UNLOAD').noPayload(),

  /**
   * Receive Institution Configuration Result
   */
  ReceiveConfigurationResult: createUseCase(
    'ADMIN_APPLICATION_RECEIVE_CONFIGURATION_RESULT'
  ).withPayload<{
    configuration: InstitutionConfiguration;
  }>(),

  /**
   * Update Institution Configuration
   */
  UpdateInstitutionConfiguration: createUseCase(
    'ADMIN_APPLICATION_UPDATE_INSTITUTION_CONFIGURATION'
  ).withPayload<InstitutionConfiguration>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('ADMIN_APPLICATION_NO_OP').noPayload(),
};

/**
 *  All UseCases
 * */
export type AdminApplicationUseCases = ReturnType<
  | typeof adminApplicationUseCase.Load
  | typeof adminApplicationUseCase.Unload
  | typeof adminApplicationUseCase.ReceiveConfigurationResult
  | typeof adminApplicationUseCase.UpdateInstitutionConfiguration
  | typeof adminApplicationUseCase.NoOp
>;

export class AdminApplicationReducer extends EffectReducer<AdminApplication> {
  private apiInstitution: ContractInstitution;
  constructor(apiInstitution: ContractInstitution) {
    super();
    this.apiInstitution = apiInstitution;
  }

  Perform(
    adminApplication: AdminApplication = initial(),
    { type, payload: useCase }: AdminApplicationUseCases
  ): ReducerResult<AdminApplication> {
    switch (type) {
      case adminApplicationUseCase.Load.type:
        return this.Result(
          {
            ...adminApplication,
            institutionConfiguration:
              adminApplication.institutionConfiguration.setFirst(
                ApiStatus.Busy
              ),
          },
          effectGetInstitution(this.apiInstitution)
        );

      case adminApplicationUseCase.ReceiveConfigurationResult.type:
        return this.Result({
          ...adminApplication,
          institutionConfiguration: adminApplication.institutionConfiguration
            .setFirst(ApiStatus.Idle)
            .setSecond(just(useCase.configuration)),
        });

      case adminApplicationUseCase.UpdateInstitutionConfiguration.type:
        return this.Result(
          {
            ...adminApplication,
            institutionConfiguration: adminApplication.institutionConfiguration
              .setFirst(ApiStatus.Busy)
              .setSecond(just(useCase)),
          },
          effectUpdateInstitutionConfiguration(this.apiInstitution, useCase),
          [
            appUseCase.ClearMessages(),
            profileUseCase.OverrideConfiguration(useCase),
          ]
        );

      case adminApplicationUseCase.Unload.type:
        this.CancelLastEffect();
        return this.Result(initial());

      case adminApplicationUseCase.NoOp.type:
        return this.Result({ ...adminApplication });
    }
  }
}

const effectGetInstitution = (apiInstitution: ContractInstitution): Effect =>
  effect(async () => {
    try {
      const institutionConfiguration =
        await apiInstitution.GetInstitutionConfiguration();

      return adminApplicationUseCase.ReceiveConfigurationResult({
        configuration: institutionConfiguration,
      });
    } catch (e) {
      return adminApplicationUseCase.NoOp();
    }
  }, 'effect-admin-application-get-institutions-configuration');

const effectUpdateInstitutionConfiguration = (
  apiInstitution: ContractInstitution,
  configuration: InstitutionConfiguration
): Effect =>
  effect(async () => {
    try {
      const institutionConfiguration =
        await apiInstitution.UpdateInstitutionConfiguration(configuration);

      dispatch(
        notificationUseCase.Add({
          notification: {
            intent: 'success',
            titleI18nKey: 'generic.form.save_success',
          },
        })
      );

      return adminApplicationUseCase.ReceiveConfigurationResult({
        configuration: institutionConfiguration,
      });
    } catch (e) {
      return adminApplicationUseCase.NoOp();
    }
  }, 'effect-admin-application-update-institutions-configuration');

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import { Finding } from '@entities/Finding';
import styles from '../comparison.scss';
import { FindingNoduleSizeDifferenceValues } from './FindingNoduleSizeDifferenceValues';
import { FindingNoduleSizeHeaderValues } from './FindingNoduleSizeHeaderValues';

interface SummarySizeNoduleProps {
  finding: Finding;
  showUnitInSummary: boolean;
}

export const SummarySizeNodule = ({
  finding,
  showUnitInSummary,
}: SummarySizeNoduleProps) => {
  return (
    <Box display="flex" fullWidth justifyContent="space-between">
      <Box display="flex" flexDirection="column" fullWidth>
        <Box gap="xs" className={styles.summarySizeDisplay}>
          {finding.comparedFinding && (
            <Box
              display="flex"
              flexDirection="column"
              className={styles.summaryLabel}
              gap="xxxs"
            >
              <>
                <Box gap="xs" className={styles.noduleSizePreviousLabel}>
                  <Icon name="previous-icon" size="small" />
                  <Text type="caption">
                    {t('study.summary.title.previous')}
                  </Text>
                </Box>
                <Box gap="xs" className={styles.changeLabel}>
                  <Icon name="current-icon" size="small" />
                  <Text type="caption">{t('study.summary.title.current')}</Text>
                </Box>
                <Box gap="xs" className={styles.changeLabel}>
                  <Icon name="change-icon" size="small" />
                  <Text type="caption">{t('study.summary.title.change')}</Text>
                </Box>
              </>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap="xs">
            <Box
              className={
                finding.formatted.isSize3D
                  ? styles.findingSizeDisplay
                  : styles.findingDimensionDisplay
              }
            >
              {finding.comparedFinding ? (
                <>
                  <FindingNoduleSizeHeaderValues
                    finding={finding.comparedFinding}
                    showUnitInSummary={showUnitInSummary}
                    muteSizeValue
                  />
                  <FindingNoduleSizeHeaderValues
                    finding={finding}
                    showUnitInSummary={showUnitInSummary}
                  />
                  <FindingNoduleSizeDifferenceValues
                    finding={finding}
                    comparedFinding={finding.comparedFinding}
                  />
                </>
              ) : (
                <FindingNoduleSizeHeaderValues
                  finding={finding}
                  showUnitInSummary={showUnitInSummary}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

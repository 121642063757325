import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Authenticate/ForgotPassword';
import * as ForgotPasswordRequestStory from '@webPages/Authenticate/ForgotPasswordRequest';
import * as ForgotPasswordResetStory from '@webPages/Authenticate/ForgotPasswordReset';
import { Layout } from '@webPages/Authenticate/Layout';
import { t } from '@webInterfaces/I18n';

const ForgotPassword: React.FC<ViewModel.ForgotPassword> = ({
  dispatchForgotPasswordRequest,
  dispatchForgotPasswordReset,
  latestMessage,
  loading,
  step,
  email = '',
}) => {
  const messages = latestMessage ? [latestMessage] : [];
  return (
    <Layout
      title={t('pages.auth.forgot_password.title')}
      showHelp
      showBack
      messages={messages}
    >
      {(() => {
        switch (step) {
          case 'request':
            return (
              <ForgotPasswordRequestStory.ForgotPasswordRequest
                loading={loading}
                onSubmit={dispatchForgotPasswordRequest}
              />
            );

          case 'reset':
            return (
              <ForgotPasswordResetStory.ForgotPasswordReset
                loading={loading}
                onSubmit={dispatchForgotPasswordReset}
                email={email}
              />
            );
        }
      })()}
    </Layout>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(ForgotPassword);

import React from 'react';
import { cnames } from '@helpers/cnames';
import styles from './badge.scss';
import { Text } from './../Text/Text';
import { Box } from './../Box/Box';

export interface BadgeProps {
  children?: React.ReactNode | null;
  /**
   * Visually state the intent this button carries.
   */
  intent?: 'neutral' | 'success' | 'error' | 'information' | 'warning';
  /**
   * Visually mute the color state that intent carries.
   */
  subtle?: boolean;
  circle?: boolean;
  styles?: string;
  title?: React.ReactNode | string;
}

export const Badge: React.FC<BadgeProps> = ({
  subtle = false,
  intent = 'neutral',
  children,
  circle,
}) => {
  const classNames = cnames(styles[intent], styles.badge, {
    [styles.subtle]: subtle,
    [styles.circle]: circle,
  });

  return (
    <Box
      data-testid="badge-test-id"
      className={classNames}
      title={children}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
    >
      {children && (
        <Text data-testid="descendant-text" lineClamp={1} type="caption">
          {children}
        </Text>
      )}
    </Box>
  );
};

import React, { forwardRef } from 'react';

// interface ThyroidThreePolesSVGProps {
// }

export const ThyroidThreePolesSVG = forwardRef<SVGSVGElement>((_props, ref) => {
  return (
    <svg
      viewBox="0 0 118 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <defs>
        <clipPath id="clip-0">
          <path
            d="m 48.2279,58.6842 c -7.2979,3.8113 -22.7743,7.4309 -40.34538,9.44 0.58969,1.9962 1.26435,4.0974 2.03518,6.3072 5.9022,16.9196 19.4997,20 26.4999,15.5 7.0002,-4.5 13,-20.0001 12.5,-27.0001 -0.1183,-1.6565 -0.3654,-3.0554 -0.6897,-4.2471 z"
            fill="rgb(10, 18, 29)"
            strokeDasharray="2 4"
            strokeDashoffset="1"
          ></path>
        </clipPath>
        <clipPath id="clip-1">
          <path
            d="M 31.424,36.5813 C 24.5647,38.5321 15.1608,40.0775 4.13535,40.5934 3.80271,45.3823 3.8103,53.7178 7.46053,66.6626 25.2218,64.6516 40.8405,61.0154 47.7684,57.251 46.9178,54.9709 45.7945,53.6142 44.9725,52.6215 44.7654,52.3713 44.5774,52.1442 44.4177,51.9313 43.6844,50.9536 42.1664,49.4405 40.4888,47.7683 38.7347,46.0198 36.806,44.0974 35.4177,42.4314 34.2228,40.9974 32.7667,38.7841 31.424,36.5813 Z"
            fill="rgb(10, 18, 29)"
            strokeDasharray="2 4"
            strokeDashoffset="1"
          ></path>
        </clipPath>
        <clipPath id="clip-2">
          <path
            d="m 17.9177,3.93137 c -3,-0.49993 -4.8332,3.42028 -8.00021,11.49993 -3.9588,10.0997 -5.1039,17.7271 -5.49978,22 -0.01943,0.2098 -0.04177,0.4363 -0.06581,0.6799 -0.0295,0.299 -0.06154,0.6239 -0.09389,0.9753 10.64669,-0.5084 19.72889,-1.9788 26.37329,-3.8253 -1.1426,-1.9309 -2.1355,-3.7272 -2.7136,-4.8298 -0.7659,-2.0667 -1.634,-4.11 -2.3711,-5.8449 -0.7869,-1.8522 -1.4245,-3.3528 -1.6289,-4.1551 -0.1368,-0.5369 -0.4189,-1.4845 -0.7499,-2.5961 -0.627,-2.1056 -1.4292,-4.7995 -1.7501,-6.404 C 21.3228,10.9569 21.2522,10.3998 21.177,9.80694 20.8638,7.33622 20.4719,4.24466 17.9177,3.93137 Z"
            fill="rgb(10, 18, 29)"
            strokeDasharray="2 4"
            strokeDashoffset="1"
          ></path>
        </clipPath>
        <clipPath id="clip-3">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 86.3956 36.5813 C 93.2549 38.5321 102.659 40.0775 113.684 40.5934 C 114.017 45.3823 114.009 53.717800000000004 110.359 66.6626 C 92.59779999999999 64.6516 76.97909999999999 61.0154 70.05109999999999 57.251 C 70.9018 54.9709 72.0251 53.6142 72.8471 52.6215 C 73.0542 52.3713 73.2422 52.1442 73.4019 51.9313 C 74.1352 50.9536 75.6532 49.4405 77.3308 47.768299999999996 C 79.08489999999999 46.0198 81.0135 44.09739999999999 82.4019 42.4314 C 83.5968 40.9974 85.0529 38.784099999999995 86.3956 36.5813 Z"
            fill="#0a161d"
          ></path>
        </clipPath>
        <clipPath id="clip-4">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 69.5916 58.684200000000004 C 76.8896 62.49550000000001 92.366 66.1151 109.937 68.1242 C 109.347 70.1204 108.673 72.2216 107.902 74.4314 C 102 91.351 88.4021 94.4314 81.4019 89.9314 C 74.4018 85.4314 68.4019 69.9313 68.9019 62.93129999999999 C 69.02029999999999 61.27479999999999 69.2674 59.875899999999994 69.5916 58.68419999999999 L 69.5916 58.684200000000004 Z"
            fill="#0a161d"
          ></path>
        </clipPath>
        <clipPath id="clip-5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 99.9019 3.9313700000000003 C 102.902 3.4314400000000003 104.735 7.35165 107.902 15.4313 C 111.861 25.531 113.006 33.1584 113.402 37.4313 C 113.421 37.6411 113.444 37.8676 113.468 38.1112 C 113.497 38.410199999999996 113.52900000000001 38.735099999999996 113.562 39.086499999999994 C 102.91499999999999 38.57809999999999 93.8327 37.107699999999994 87.1882 35.261199999999995 C 88.3309 33.330299999999994 89.32369999999999 31.533999999999995 89.9019 30.431399999999996 C 90.6677 28.364699999999996 91.5359 26.321399999999997 92.273 24.586499999999997 C 93.0599 22.734299999999998 93.6974 21.2337 93.9019 20.431399999999996 C 94.0386 19.894499999999997 94.32079999999999 18.946899999999996 94.6518 17.835299999999997 C 95.27879999999999 15.729699999999998 96.08099999999999 13.035799999999997 96.4019 11.431299999999997 C 96.4968 10.956899999999997 96.56739999999999 10.399799999999997 96.6426 9.806939999999997 C 96.9558 7.336219999999997 97.3477 4.244659999999997 99.9019 3.9313699999999976 L 99.9019 3.9313700000000003 Z"
            fill="#0a161d"
          ></path>
        </clipPath>
        <clipPath id="clip-6">
          <path
            d="M 52.86 64.795 C 53.26 59.595 50.694 54.129 49.36 52.295 C 51.86 53.795 55.46 54.295 59.86 54.295 C 64.26 54.295 67.36 53.295 68.86 52.295 C 67.36 54.795 65.36 59.995 65.36 64.795 C 65.36 69.595 67.694 75.129 68.86 77.295 C 66.36 74.795 62.86 74.295 58.86 74.295 C 54.86 74.295 51.86 74.795 48.86 77.295 C 50.027 75.129 52.46 69.995 52.86 64.795 Z"
            fill="#0A161D"
            strokeWidth="2"
            strokeDasharray="1 1"
          ></path>
        </clipPath>
      </defs>
      <path
        d="M 58.9286,77.0207 C 47.9439,77.0207 48.6642,83.5513 38.4103,91.95 29.9592,98.872 14.5693,94.0602 7.89927,77.0207 1.22928,59.9813 0.26992,46.0628 2.93588,31.868 5.60185,17.6733 8.40163,12.4998 11.7444,5.49639 15.0872,-1.50707 22.211,0.321499 22.9849,6.51597 23.7588,12.7104 32.598,51.6944 58.9286,51.6944"
        stroke="#3a4e5a"
        strokeWidth="2"
      />
      <path
        d="m 58.9287,77.0207 c 10.9847,0 10.2644,6.5306 20.5183,14.9293 8.4511,6.922 23.841,2.1102 30.511,-14.9293 6.67,-17.0394 7.629,-30.9579 4.963,-45.1527 C 112.255,17.6733 109.456,12.4998 106.113,5.49639 102.77,-1.50707 95.6463,0.321499 94.8724,6.51597 94.0985,12.7104 85.2593,51.6944 58.9287,51.6944"
        stroke="#3a4e5a"
        strokeWidth="2"
      />
      <path
        d="m 48.2279,58.6842 c -7.2979,3.8113 -22.7743,7.4309 -40.34538,9.44 0.58969,1.9962 1.26435,4.0974 2.03518,6.3072 5.9022,16.9196 19.4997,20 26.4999,15.5 7.0002,-4.5 13,-20.0001 12.5,-27.0001 -0.1183,-1.6565 -0.3654,-3.0554 -0.6897,-4.2471 z"
        clipPath="url('#clip-0')"
        strokeWidth="2"
        id="right_lower"
      ></path>
      <path
        d="M 31.424,36.5813 C 24.5647,38.5321 15.1608,40.0775 4.13535,40.5934 3.80271,45.3823 3.8103,53.7178 7.46053,66.6626 25.2218,64.6516 40.8405,61.0154 47.7684,57.251 46.9178,54.9709 45.7945,53.6142 44.9725,52.6215 44.7654,52.3713 44.5774,52.1442 44.4177,51.9313 43.6844,50.9536 42.1664,49.4405 40.4888,47.7683 38.7347,46.0198 36.806,44.0974 35.4177,42.4314 34.2228,40.9974 32.7667,38.7841 31.424,36.5813 Z"
        clipPath="url('#clip-1')"
        strokeWidth="2"
        id="right_mid"
      ></path>
      <path
        d="m 17.9177,3.93137 c -3,-0.49993 -4.8332,3.42028 -8.00021,11.49993 -3.9588,10.0997 -5.1039,17.7271 -5.49978,22 -0.01943,0.2098 -0.04177,0.4363 -0.06581,0.6799 -0.0295,0.299 -0.06154,0.6239 -0.09389,0.9753 10.64669,-0.5084 19.72889,-1.9788 26.37329,-3.8253 -1.1426,-1.9309 -2.1355,-3.7272 -2.7136,-4.8298 -0.7659,-2.0667 -1.634,-4.11 -2.3711,-5.8449 -0.7869,-1.8522 -1.4245,-3.3528 -1.6289,-4.1551 -0.1368,-0.5369 -0.4189,-1.4845 -0.7499,-2.5961 -0.627,-2.1056 -1.4292,-4.7995 -1.7501,-6.404 C 21.3228,10.9569 21.2522,10.3998 21.177,9.80694 20.8638,7.33622 20.4719,4.24466 17.9177,3.93137 Z"
        clipPath="url('#clip-2')"
        strokeWidth="2"
        id="right_upper"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 86.3956 36.5813 C 93.2549 38.5321 102.659 40.0775 113.684 40.5934 C 114.017 45.3823 114.009 53.717800000000004 110.359 66.6626 C 92.59779999999999 64.6516 76.97909999999999 61.0154 70.05109999999999 57.251 C 70.9018 54.9709 72.0251 53.6142 72.8471 52.6215 C 73.0542 52.3713 73.2422 52.1442 73.4019 51.9313 C 74.1352 50.9536 75.6532 49.4405 77.3308 47.768299999999996 C 79.08489999999999 46.0198 81.0135 44.09739999999999 82.4019 42.4314 C 83.5968 40.9974 85.0529 38.784099999999995 86.3956 36.5813 Z"
        clipPath="url('#clip-3')"
        strokeWidth="2"
        id="left_mid"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 69.5916 58.684200000000004 C 76.8896 62.49550000000001 92.366 66.1151 109.937 68.1242 C 109.347 70.1204 108.673 72.2216 107.902 74.4314 C 102 91.351 88.4021 94.4314 81.4019 89.9314 C 74.4018 85.4314 68.4019 69.9313 68.9019 62.93129999999999 C 69.02029999999999 61.27479999999999 69.2674 59.875899999999994 69.5916 58.68419999999999 L 69.5916 58.684200000000004 Z"
        clipPath="url('#clip-4')"
        strokeWidth="2"
        id="left_lower"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 99.9019 3.9313700000000003 C 102.902 3.4314400000000003 104.735 7.35165 107.902 15.4313 C 111.861 25.531 113.006 33.1584 113.402 37.4313 C 113.421 37.6411 113.444 37.8676 113.468 38.1112 C 113.497 38.410199999999996 113.52900000000001 38.735099999999996 113.562 39.086499999999994 C 102.91499999999999 38.57809999999999 93.8327 37.107699999999994 87.1882 35.261199999999995 C 88.3309 33.330299999999994 89.32369999999999 31.533999999999995 89.9019 30.431399999999996 C 90.6677 28.364699999999996 91.5359 26.321399999999997 92.273 24.586499999999997 C 93.0599 22.734299999999998 93.6974 21.2337 93.9019 20.431399999999996 C 94.0386 19.894499999999997 94.32079999999999 18.946899999999996 94.6518 17.835299999999997 C 95.27879999999999 15.729699999999998 96.08099999999999 13.035799999999997 96.4019 11.431299999999997 C 96.4968 10.956899999999997 96.56739999999999 10.399799999999997 96.6426 9.806939999999997 C 96.9558 7.336219999999997 97.3477 4.244659999999997 99.9019 3.9313699999999976 L 99.9019 3.9313700000000003 Z"
        clipPath="url('#clip-5')"
        strokeWidth="2"
        id="left_upper"
      ></path>
      <path
        d="M 52.86 64.795 C 53.26 59.595 50.694 54.129 49.36 52.295 C 51.86 53.795 55.46 54.295 59.86 54.295 C 64.26 54.295 67.36 53.295 68.86 52.295 C 67.36 54.795 65.36 59.995 65.36 64.795 C 65.36 69.595 67.694 75.129 68.86 77.295 C 66.36 74.795 62.86 74.295 58.86 74.295 C 54.86 74.295 51.86 74.795 48.86 77.295 C 50.027 75.129 52.46 69.995 52.86 64.795 Z"
        clipPath="url('#clip-6')"
        strokeWidth="2"
        id="median"
      ></path>
    </svg>
  );
});

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Settings/User';
import * as StorySettingsSecurity from '@webPages/Settings/Security';
import * as StoryLayout from '@webPages/Settings/Layout';

const SecuritySettings: React.FC<ViewModel.User> = ({
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  security,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  latestMessage,
  dispatchOnSignOut,
  dispatchSetUpMFA,
  dispatchVerifyMFA,
  dispatchDisableMFA,
  dispatchChangePassword,
}) => {
  return (
    <StoryLayout.Layout
      profile={profile}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
      onSignOut={dispatchOnSignOut}
      searchQuery={searchQuery}
    >
      <StorySettingsSecurity.Security
        dispatchChangePassword={dispatchChangePassword}
        onSetUpMFA={dispatchSetUpMFA}
        onVerifyMFA={dispatchVerifyMFA}
        onDisableMFA={dispatchDisableMFA}
        securityProfile={{
          otpAuthUrl: security.otpAuthUrl,
          hasMFAenabled: security.hasMFAEnabled,
        }}
        message={latestMessage}
      />
    </StoryLayout.Layout>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(SecuritySettings);

import React from 'react';
import { Box, BoxProps } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './form-field-hint.scss';

export interface FormFieldHintProps extends BoxProps {
  intent: 'neutral' | 'success' | 'error';
  children?: string;
}

export const FormFieldHint = ({
  intent,
  children,
  ...boxProps
}: FormFieldHintProps) => {
  const classNames = cnames(styles.hint, styles[intent]);

  return (
    <Box className={classNames} {...boxProps}>
      <Text type="caption" display="block">
        {children}
      </Text>
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import styles from './loadingIcon.scss';

export const LoadingIcon = () => {
  return (
    <Box
      display="inline-block"
      className={styles.loadingIcon}
      data-testid="loading-icon"
    >
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
      </svg>
      <Box className={styles.front}>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <path
            d="M22 12C22 6.47715 17.5228 2 12 2"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    </Box>
  );
};

import React from 'react';
import { cnames } from '@helpers/cnames';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import styles from './switch.scss';

export interface SwitchProps {
  disabled?: boolean;
  name?: string;
  readOnly?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  align?: 'left' | 'right';
  onChange?: (checked: boolean) => void;
  label?: string | ((checked: boolean) => string);
}

export const Switch: React.FC<SwitchProps> = ({
  disabled,
  name = '',
  readOnly,
  checked,
  defaultChecked,
  align,
  onChange,
  label,
}) => {
  const classNames = cnames(styles.switchOuter, {
    [styles.disabled]: disabled,
    [styles.readOnly]: readOnly,
  });

  return (
    <Box
      data-testid="outerContainer"
      element="label"
      display="inline-flex"
      alignItems="center"
      flexDirection={align === 'right' ? 'row-reverse' : 'row'}
      className={classNames}
      gap="xs"
    >
      <input
        data-testid="switchHiddenInput"
        defaultChecked={defaultChecked}
        checked={checked}
        className={styles.hiddenInput}
        disabled={disabled}
        name={name}
        onChange={e => onChange?.(e.target.checked)}
        type="checkbox"
      />

      <Box
        data-testid="switch"
        className={styles.switch}
        display="flex"
        alignItems="center"
        padding="xxxs"
      >
        <Box data-testid="pill" className={styles.pill} />
      </Box>
      {label && (
        <Text data-testid="labelText" type="body2">
          {typeof label === 'string' ? label : label(!!checked)}
        </Text>
      )}
    </Box>
  );
};

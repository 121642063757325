import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from '@webInterfaces/Store';
import App from '@webViews/App';
import ErrorBoundary from '@webViews/ErrorBoundary/ErrorBoundary';
import { onError } from '@webInterfaces/Error';
import './tailwind.css';

render(
  <ErrorBoundary onError={onError}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.querySelector('main')
);

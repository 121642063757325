import {
  ThyroidFindingComposition,
  ThyroidFindingEchogenicFoci,
  ThyroidFindingEchogenicity,
  ThyroidFindingMargin,
  ThyroidFindingShape,
} from '@entities/Characteristics';
import { ThyroidFinding } from '@entities/Finding';
import { TiRadsGrading } from '@entities/Grading';

export const scorePointToGrading = (scorePoint: number): TiRadsGrading => {
  if (scorePoint < 2) {
    return TiRadsGrading.TR1;
  } else if (scorePoint === 2) {
    return TiRadsGrading.TR2;
  } else if (scorePoint === 3) {
    return TiRadsGrading.TR3;
  } else if (scorePoint < 7) {
    return TiRadsGrading.TR4;
  } else if (scorePoint >= 7) {
    return TiRadsGrading.TR5;
  } else {
    // NaN
    return TiRadsGrading.TR1;
  }
};

export const getCharacteristicScore = (
  value: string | string[] | undefined | null,
  config: ScoreObject
): number => {
  if (!value) {
    return 0;
  } else if (Array.isArray(value)) {
    return value
      .map(v => config[v]?.score ?? 0)
      .reduce((total, item) => total + item, 0);
  } else {
    return config[value]?.score ?? 0;
  }
};

export const computeFindingScorePoints = ({
  characteristics,
}: ThyroidFinding) => {
  if (characteristics.composition === ThyroidFindingComposition.Spongiform) {
    // When Spongiform, don't include the other characteristics
    return getCharacteristicScore(
      characteristics.composition,
      CompositionScore
    );
  }
  return (
    getCharacteristicScore(characteristics.composition, CompositionScore) +
    getCharacteristicScore(characteristics.echogenicFoci, EchogenicFociScore) +
    getCharacteristicScore(characteristics.echogenicity, EchogenicityScore) +
    getCharacteristicScore(characteristics.margin, MarginScore) +
    getCharacteristicScore(characteristics.shape, ShapeScore)
  );
};

export type ScoreObject = Record<string, { priority: number; score: number }>;

export const CompositionScore: ScoreObject = {
  [ThyroidFindingComposition.CysticOrAlmostCompletelyCystic]: {
    priority: 10,
    score: 0,
  },
  [ThyroidFindingComposition.Spongiform]: {
    priority: 20,
    score: 0,
  },
  [ThyroidFindingComposition.MixedCysticAndSolid]: {
    priority: 30,
    score: 1,
  },
  [ThyroidFindingComposition.SolidOrAlmostCompletelySolid]: {
    priority: 40,
    score: 2,
  },
  [ThyroidFindingComposition.Indeterminate]: {
    priority: 50,
    score: 2,
  },
};

export const EchogenicityScore: ScoreObject = {
  [ThyroidFindingEchogenicity.Anechoic]: {
    priority: 10,
    score: 0,
  },
  [ThyroidFindingEchogenicity.HyperechoicOrIsoechoic]: {
    priority: 20,
    score: 1,
  },
  [ThyroidFindingEchogenicity.Hypoechoic]: {
    priority: 30,
    score: 2,
  },
  [ThyroidFindingEchogenicity.VeryHypoechoic]: {
    priority: 40,
    score: 3,
  },
  [ThyroidFindingEchogenicity.Indeterminate]: {
    priority: 50,
    score: 1,
  },
};

export const MarginScore: ScoreObject = {
  [ThyroidFindingMargin.Smooth]: {
    priority: 10,
    score: 0,
  },
  [ThyroidFindingMargin.IllDefined]: {
    priority: 20,
    score: 0,
  },
  [ThyroidFindingMargin.LobulatedOrIrregular]: {
    priority: 30,
    score: 2,
  },
  [ThyroidFindingMargin.ExtraThyroidalExtension]: {
    priority: 40,
    score: 3,
  },
  [ThyroidFindingMargin.Indeterminate]: {
    priority: 50,
    score: 0,
  },
};

export const ShapeScore: ScoreObject = {
  [ThyroidFindingShape.WiderThanTall]: {
    priority: 10,
    score: 0,
  },
  [ThyroidFindingShape.TallerThanWide]: {
    priority: 20,
    score: 3,
  },
};

export const EchogenicFociScore: ScoreObject = {
  [ThyroidFindingEchogenicFoci.NoneOrLargeCometTailArtefacts]: {
    priority: 10,
    score: 0,
  },
  [ThyroidFindingEchogenicFoci.Macrocalcifications]: {
    priority: 20,
    score: 1,
  },
  [ThyroidFindingEchogenicFoci.Peripheral]: {
    priority: 30,
    score: 2,
  },
  [ThyroidFindingEchogenicFoci.PunctateEchogenicFoci]: {
    priority: 40,
    score: 3,
  },
};

type Argument = undefined | string | { [key: string]: boolean | undefined };

/**
 * A helper function to concatentate multiple class names together into a single
 * string. e.g.
 * - cnames('hello', 'world') returns 'hello world'
 * - cnames(styles.base, { [styles.big]: showBig }) returns 'base big'
 * Inspired by https://github.com/JedWatson/classnames.
 * But decided to write our own implementation because simple and lightweight and fun
 *
 * @param args Either a string or an object with the classname with a boolean condition.
 * @returns A single concatenated string
 */
export const cnames = (...args: Array<Argument>): string => {
  const result: string[] = [];
  args.map(arg => {
    if (typeof arg === 'string') {
      result.push(arg);
    } else if (typeof arg === 'object') {
      Object.keys(arg).map(key => {
        if (arg[key]) {
          result.push(key);
        }
      });
    }
  });

  return result.join(' ');
};

import { authenticateUseCase } from '@useCases/Authentication';
import { Actions } from '@webInterfaces/StoreTypes';

export const createAccount = (
  email: string,
  newPassword: string,
  currentPassword: string,
  firstName: string,
  lastName: string
): Actions =>
  authenticateUseCase.CreateAccount({
    email,
    newPassword,
    currentPassword,
    firstName,
    lastName,
  });

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Stack } from '@webMolecules/Stack/Stack';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import styles from './sidebarGroup.scss';

export interface SidebarGroupProps {
  children?: React.ReactNode;
  heading?: string;
  onToggleModal?: () => void;
}

export interface SidebarGroupFilterOption {
  label: string;
  value: string;
  disabled: boolean;
  selected: boolean;
}

export const SidebarGroup = ({
  heading,
  children,
  onToggleModal,
}: SidebarGroupProps) => {
  return (
    <Box className={styles.group} paddingX="xl">
      {heading && (
        <Box
          marginBottom="m"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text type="body2" muted display="block">
            {heading}
          </Text>
          {onToggleModal && (
            <Button
              data-testid="filter-modal-button"
              iconBefore={<Icon name="plus-small" />}
              circle
              size="small"
              onClick={onToggleModal}
            ></Button>
          )}
        </Box>
      )}
      <Stack spacing="s">{children}</Stack>
    </Box>
  );
};

export enum TiRadsGrading {
  TR1 = 'tr1',
  TR2 = 'tr2',
  TR3 = 'tr3',
  TR4 = 'tr4',
  TR5 = 'tr5',
}

export enum BiradsScore {
  BIRADS_0 = 'BIRADS_0',
  BIRADS_1 = 'BIRADS_1',
  BIRADS_2 = 'BIRADS_2',
  BIRADS_3 = 'BIRADS_3',
  BIRADS_4A = 'BIRADS_4A',
  BIRADS_4B = 'BIRADS_4B',
  BIRADS_4C = 'BIRADS_4C',
  BIRADS_5 = 'BIRADS_5',
  BIRADS_6 = 'BIRADS_6',
}

import { CSSProperties } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { InfoButtonProps } from '@webMolecules/InfoButton/InfoButton';
import { BoxProps } from '@webMolecules/Box/Box';
import { Workflow } from '@entities/Workflow';
import { AnyUnit } from '@entities/Unit';
import { FeatureFlags } from '@entities/FeatureFlag';
import { SpecificGalleryFilter } from '@config/Gallery/SpecificGalleryFilter';
import { ScoreObject } from '@handlers/TiRadsHandler';
import {
  InstitutionConfiguration,
  SizeVariant,
  ThyroidPoleVariant,
} from '@entities/InstitutionConfiguration';

export interface ResultTableConfig {
  sections: ResultTableConfigSection[];
}

export interface ResultTableConfigSection {
  columns: ResultTableConfigColumn[];
  rows: ResultTableConfigRow[];
  heading?: string;
  index?: number;
  appearance?: ResultTableConfigSectionAppearance;
  featureEnabled?: FeatureFlagConfigFunction;
}

export interface InlineResultTableConfigColumn extends ResultTableConfigColumn {
  flexDirection?: 'row' | 'column';
}

export interface ResultTableConfigSectionAppearance {
  hideHeading?: boolean;
  elevate?: boolean;
  removeMargin?: boolean;
}

export type ResultTableSelectOptions = Array<{
  editableI18NKey: string;
  previewI18NKey: string;
  value: string;
  excludeFromToggleAll?: boolean;
}>;

export interface ResultTableSelectConfig {
  options: ResultTableSelectOptions;
  scoreConfig?: ScoreObject;
}

export interface ResultTableToggleOption {
  editableI18NKey: string;
  previewI18NKey: string;
  captionI18NKey?: string;
  value: string;
  tooltipI18NKey?: string;
  excludeFromToggleAll?: boolean;
  separator?: boolean;
}

export interface ResultTableToggleConfig {
  options: ResultTableToggleOption[];
  size?: 'small' | 'medium' | 'large';
}

export interface ResultTableRadioConfig {
  options: ResultTableToggleOption[];
  columns?: number;
  scoreConfig?: ScoreObject;
}

export type ResultTableFilterButtonOptions = {
  disabled: boolean;
};

export type ResultTableSizeConfig = {
  showWidth?: boolean;
  showHeight?: boolean;
  showLength?: boolean;
  showVolume?: boolean;
  showSizeOptions?: boolean;
  readOnly?: boolean;
};
export type ResultTableNumberConfig = {
  readOnly?: boolean;
  showSizeWarning?: boolean;
};

export type InputConfigType =
  | ResultTableSelectConfig
  | ResultTableFilterButtonOptions
  | ResultTableToggleConfig
  | ResultTableRadioConfig
  | ResultTableNumberConfig
  | ResultTableSizeConfig;

export type ResultTableInputType =
  | 'label'
  | 'float'
  | 'integer'
  | 'string'
  | 'filterButton'
  | 'toggleGroup'
  | 'textArea'
  | 'check'
  | 'select'
  | 'radio'
  | 'spacer'
  | 'dimensions'
  | 'clockface'
  | 'score'
  | 'mergedCell'
  | 'findingInclude'
  | 'findingRecommendation';

export type Footnote = AnyUnit;
export type UnitPosition = 'inline' | 'footnote' | 'header';

export interface ResultTableConfigColumn {
  key: string;
  label?: string;
  boldLabel?: boolean;
  input: ResultTableInputType;
  caption?: string;
  readOnly?: boolean;
  inputConfig?: InputConfigType;
  width?: string;
  placeholder?: string;
  minWidth?: string;
  verticalAlign?: CSSProperties['verticalAlign'];
  confidenceThreshold?: number;
  separator?: boolean;
  separatorLabel?: string;
  maxLength?: number;
  isDebounce?: boolean;
  hideLabel?: boolean;
  unit?: AnyUnit;
  unitPosition?: UnitPosition;
  featureEnabled?: FeatureFlagConfigFunction;
  infoButton?: InfoButtonProps;
  toggleAll?: boolean;
  labelBoxMargin?: BoxProps['margin'];
  labelCaption?: string;
  onToggle?(oldValues: string[], newValues: string[]): string[];
}

export interface ResultTableConfigRow {
  displayI18NKey?: string;
  index?: number;
  columns?: ResultTableConfigColumn[];
  children?: ResultTableConfigRow[];
  expanded?: boolean;
  unit?: AnyUnit;
  unitPosition?: UnitPosition;
  featureEnabled?: FeatureFlagConfigFunction;
}

export interface GeneralCharacteristicTableConfig<
  T extends FieldValues = FieldValues,
> {
  sections: GeneralCharacteristicTableConfigSection<T>[];
}

export interface GeneralCharacteristicTableConfigSection<
  T extends FieldValues = FieldValues,
> {
  rows: GeneralCharacteristicFieldConfig<T>[];
  labelWidth?: string;
  index?: number;
  appearance?: ResultTableConfigSectionAppearance;
  heading?: string;
  featureEnabled?: FeatureFlagConfigFunction;
  galleryFilter?: SpecificGalleryFilter;
}

export interface GeneralCharacteristicFieldConfig<
  T extends FieldValues = FieldValues,
> extends InlineResultTableConfigColumn {
  path?: FieldPath<T>;
  isShown?(data: T): boolean;
  galleryFilter?: SpecificGalleryFilter;
}

export interface SizeFlags {
  isSize3D?: boolean;
  isAutosizing?: boolean;
}

// This takes an array of any type of item with a `featureEnabled` property
// and filters based on feature flags
export function filterConfigItemsByFeatureFlag<
  T extends { featureEnabled?: FeatureFlagConfigFunction },
>(
  configItems: T[],
  featureFlags: FeatureFlags,
  config: Required<InstitutionConfiguration>
): T[] {
  return configItems
    .filter(
      item =>
        item.featureEnabled === undefined ||
        item.featureEnabled(featureFlags, config)
    )
    .map(({ featureEnabled: _, ...rest }) => rest as T); // Omit `featureEnabled` from the result
}

// This takes an array of any type of item with a `workflow` property
// and filters based on current workflow
export function filterConfigItemsByWorkflow<T extends { workflow?: Workflow }>(
  configItems: T[],
  workflow: Workflow
): T[] {
  return configItems
    .filter(item => item.workflow === undefined || item.workflow === workflow)
    .map(({ workflow: _, ...rest }) => rest as T); // Omit `workflow` from the result
}

export type FeatureFlagConfigFunction = (
  featureFlags: FeatureFlags,
  config: Required<InstitutionConfiguration>
) => boolean;

export const is1DFindingSizeBreast: FeatureFlagConfigFunction = (_, config) => {
  return config.breastSizeVariant === SizeVariant.Size1D;
};

export const is1DFindingSizeThyroid: FeatureFlagConfigFunction = (
  _,
  config
) => {
  return config.thyroidSizeVariant === SizeVariant.Size1D;
};

export const is3DFindingSizeBreast: FeatureFlagConfigFunction = (_, config) => {
  return config.breastSizeVariant === SizeVariant.Size3D;
};

export const is3DFindingSizeThyroid: FeatureFlagConfigFunction = (
  _,
  config
) => {
  return config.thyroidSizeVariant === SizeVariant.Size3D;
};

export const isSimplifiedBreastReportingWorkflow: FeatureFlagConfigFunction = (
  _,
  config
) => {
  return config.simplifiedBreastReportingWorkflow;
};

export const isNotSimplifiedBreastReportingWorkflow: FeatureFlagConfigFunction =
  (_, config) => {
    return !config.simplifiedBreastReportingWorkflow;
  };

export const isBiradsScore: FeatureFlagConfigFunction = (_, config) => {
  return config.showBiradsScore;
};

export const isThreePolesVariant: FeatureFlagConfigFunction = (_, config) => {
  return config.thyroidPoleVariant === ThyroidPoleVariant.ThreePoles;
};

export const isFivePolesVariant: FeatureFlagConfigFunction = (_, config) => {
  return config.thyroidPoleVariant === ThyroidPoleVariant.FivePoles;
};

import { yesNoSelectOptions } from '@config/ResultTable/Shared';
import {
  normalAbnormalOptions,
  normalIncreasedOptions,
  echotextureOptions,
} from '@config/ResultTable/Thyroid';
import { Size, Volume } from './Finding';
import {
  EchotextureValue,
  NormalAbnormalValue,
  NormalIncreasedValue,
  YesNoValue,
} from './Characteristics';

export interface ThyroidGeneralCharacteristics {
  rightLobe: ThyroidLobeCharacteristicsData;
  leftLobe: ThyroidLobeCharacteristicsData;
  isthmusThickness: Size | null;
  trachealDeviation: YesNoValue | null;
  lymphNodes: NormalAbnormalValue | null;
  abnormalLymphNodesText: string | null;
}

export interface ThyroidLobeCharacteristicsData {
  length: Size | null;
  width: Size | null;
  height: Size | null;
  volume: Volume | null;
  vascularity: NormalIncreasedValue | null;
  echotexture: EchotextureValue | null;
  retrosternal: YesNoValue | null;
}

export type ThyroidGeneralCharacteristicsType =
  | NormalIncreasedValue
  | EchotextureValue
  | YesNoValue
  | NormalAbnormalValue;

const ThyroidGeneralCharacteristicsOptions = [
  ...yesNoSelectOptions,
  ...normalAbnormalOptions.options,
  ...normalIncreasedOptions.options,
  ...echotextureOptions.options,
];

export const getI18NKeyForCharacteristics = (
  value: ThyroidGeneralCharacteristicsType
) => {
  const option = ThyroidGeneralCharacteristicsOptions.find(
    option => option.value === value
  );
  return option?.previewI18NKey;
};

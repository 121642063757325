import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import { selectLastWorklistPath } from '@selectors/BaseSelectors';
import {
  selectSessionDate,
  selectSessionTitle,
} from '@selectors/SessionSelectors';
import { Icon } from '@webMolecules/Icon/Icon';
import { Button } from '@webMolecules/Button/Button';
import { t, tDate } from '@webInterfaces/I18n';
import { PatientSensitive } from '@webMolecules/PatientSensitive/PatientSensitive';
import { isAnonymousName } from '@helpers/string';
import { PatientDetailsPopover } from './PatientDetailsPopover';

export const StudyTitle: React.FC = () => {
  const lastWorklistPath = useSelector(selectLastWorklistPath);
  const sessionDate = useSelector(selectSessionDate);
  const title = useSelector(selectSessionTitle);

  return (
    <Box display="flex" alignItems="center" marginRight="m">
      <RouteLink
        data-testid="back-to-worklist"
        to={lastWorklistPath}
        component={Button}
        iconBefore={<Icon name="arrow-left" />}
        padding="xs"
      >
        <Box display="flex" gap="s">
          <PatientSensitive>
            {isAnonymousName(title)
              ? t('pages.worklist.worklist_item.no_patient_name')
              : title}
          </PatientSensitive>
        </Box>
      </RouteLink>
      <PatientDetailsPopover />
      {sessionDate && (
        <Text marginLeft="s" type="body">{`[${tDate(sessionDate)}]`}</Text>
      )}
    </Box>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Settings/Admin/Users';
import * as StorySettingsUsers from '@webPages/Settings/Admin/Users';
import * as StoryLayout from '@webPages/Settings/Layout';
import StoryUserDetails from '@webPages/Settings/Admin/UserDetails';

const User: React.FC<ViewModel.Users> = ({
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  loading,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  userLineItems,
  targetUserLineItem,
  dispatchOnSignOut,
  dispatchCreateUser,
  dispatchUpdateUser,
  dispatchDeleteUser,
  dispatchUnloadTargetLineItem,
}) => (
  <StoryLayout.Layout
    profile={profile}
    regulatoryVersion={regulatoryVersion}
    regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
    onSignOut={dispatchOnSignOut}
    searchQuery={searchQuery}
    pacsStudyLineItems={pacsStudyLineItems}
    pacsSystemStatus={pacsSystemStatus}
  >
    {targetUserLineItem && (
      <StoryUserDetails
        profile={profile}
        userLineItem={targetUserLineItem}
        emailsInUse={userLineItems.map(({ email }) => email)}
        onClose={() => dispatchUnloadTargetLineItem()}
        onCreateUser={dispatchCreateUser}
        onUpdateUser={dispatchUpdateUser}
      />
    )}
    <StorySettingsUsers.Users
      loading={loading}
      profile={profile}
      userLineItems={userLineItems}
      onDeleteUser={dispatchDeleteUser}
    />
  </StoryLayout.Layout>
);

export default connect(ViewModel.fromState, ViewModel.dispatchers)(User);

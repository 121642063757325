import React from 'react';
import { useSelector } from 'react-redux';
import { DrawerMotionDirection } from '@webOrganisms/DrawerMenu/DrawerMenu';
import { MultilevelDrawerMenu } from '@webOrganisms/DrawerMenu/MultilevelDrawerMenu/MultilevelDrawerMenu';
import { Icon } from '@webMolecules/Icon/Icon';
import { Button } from '@webMolecules/Button/Button';
import { t, tPrint } from '@webInterfaces/I18n';
import { cnames } from '@helpers/cnames';
import {
  Position,
  PositionedBox,
} from '@webMolecules/PositionedBox/PositionedBox';
import { eventFindingActionCreatedFinding } from '@webInterfaces/Analytics';
import { Detection } from '@entities/Detection';
import { selectFindings } from '@selectors/FindingSelectors';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Finding } from '@entities/Finding';
import { DistanceUnit, getUnitI18nKey } from '@entities/Unit';
import { roundValueByUnit } from '@handlers/UnitHandler';
import styles from './gallery.scss';

interface FindingMenuProps {
  showMenu: boolean;
  menuPosition: Position;
  activeDetection: Detection;
  setShowMenu: (visible: boolean) => void;
  handleUpdateFinding: (finding: Finding) => void;
  handleAddFinding: (detection: Detection) => void;
  handleUnassignFromFinding: () => void;
  setLastUnassignedDetection: (value: boolean) => void;
}

export const formatFindingNumber = (index: number) => {
  return tPrint('gallery.finding.menu.add_to_finding', index);
};

export const FindingMenu = (props: FindingMenuProps) => {
  const {
    showMenu,
    menuPosition,
    setShowMenu,
    activeDetection,
    handleUpdateFinding,
    handleAddFinding,
    handleUnassignFromFinding,
    setLastUnassignedDetection,
  } = props;
  const findings = useSelector(selectFindings);
  const matchingFindingIndex = activeDetection.assignedFinding?.index;

  const classNames = cnames(styles.drawerMenu);
  const [proposedFindingIndex, setProposedFindingIndex] = React.useState<
    number | undefined
  >(undefined);

  React.useEffect(() => {
    if (matchingFindingIndex && matchingFindingIndex == proposedFindingIndex) {
      setProposedFindingIndex(undefined);
      // close finding menu after 0.3 second
      setTimeout(() => setShowMenu(false), 300);
    }
  }, [matchingFindingIndex]);

  const drawerMenuItems = findings.map(finding => {
    const isMatchingFinding =
      finding.index === (proposedFindingIndex || matchingFindingIndex);

    const iconAfter = isMatchingFinding ? (
      proposedFindingIndex !== undefined ? (
        <LoadingIcon />
      ) : (
        <Icon name="check" color="var(--ds-color-primary)" />
      )
    ) : undefined;
    return {
      label: formatFindingNumber(finding.index),
      strong: isMatchingFinding,
      iconAfter,
      scrollTo: isMatchingFinding,
      onSelect: () => {
        setProposedFindingIndex(finding.index);
        handleUpdateFinding(finding);
      },
    };
  });

  let isLastDetectionFromFinding = false;
  for (const finding of findings) {
    {
      if (
        finding.detectionIds?.includes(activeDetection.id) &&
        finding.detectionIds.length === 1
      ) {
        isLastDetectionFromFinding = true;
      }
    }
  }
  const menuFooter = matchingFindingIndex ? (
    <Button
      intent="destructive"
      iconBefore={<Icon name="x" />}
      onClick={
        isLastDetectionFromFinding
          ? () => setLastUnassignedDetection(true)
          : handleUnassignFromFinding
      }
      fluid
    >
      {t('gallery.finding.menu.unassign.from.finding')}
    </Button>
  ) : (
    <Button
      iconBefore={<Icon name="plus-circle" />}
      onClick={() => {
        eventFindingActionCreatedFinding();
        handleAddFinding(activeDetection);
      }}
      fluid
    >
      {t('gallery.finding.menu.create.finding')}
    </Button>
  );

  return (
    menuPosition && (
      <PositionedBox
        referencePosition={menuPosition}
        placement="bottom-start"
        onClear={() => setShowMenu(false)}
        className={classNames}
      >
        <MultilevelDrawerMenu
          isOpen={showMenu}
          motionDirection={DrawerMotionDirection.UpShort}
          title={
            findings.length > 0
              ? t('gallery.finding.menu.title.assign_to')
              : undefined
          }
          titleAfter={
            <Button
              onClick={() => setShowMenu(false)}
              iconBefore={<Icon name="x" />}
              circle
              strong
              size="small"
            />
          }
          menuFooter={menuFooter}
          drawerMenuItems={drawerMenuItems}
        />
      </PositionedBox>
    )
  );
};

export const formatFindingSize = (value: number, sizeUnit: DistanceUnit) => {
  const roundedValue = roundValueByUnit(value, sizeUnit);
  return `${roundedValue} ${t(getUnitI18nKey(sizeUnit))}`;
};

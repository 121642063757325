import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Settings/User';
import * as StorySettingsProfile from '@webPages/Settings/Profile';
import * as StoryLayout from '@webPages/Settings/Layout';

const Profile: React.FC<ViewModel.User> = ({
  latestMessage,
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  availableLocales,
  currentLocale,
  dispatchOnSignOut,
  dispatchUpdateProfile,
}) => {
  return (
    <StoryLayout.Layout
      profile={profile}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
      onSignOut={dispatchOnSignOut}
      searchQuery={searchQuery}
    >
      <StorySettingsProfile.UserProfile
        availableLocales={availableLocales}
        currentLocale={currentLocale}
        message={latestMessage}
        profile={profile}
        onUpdate={dispatchUpdateProfile}
      />
    </StoryLayout.Layout>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(Profile);

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type PayloadType<AC, T extends string> =
  AC extends ActionCreatorWithPayload<infer P, T> ? P : never;

type DispatcherReturnType<
  T extends string,
  AC extends ActionCreatorWithPayload<any, T> | ActionCreatorWithoutPayload<T>,
> =
  AC extends ActionCreatorWithoutPayload<T>
    ? () => void
    : (payload: PayloadType<AC, T>) => void;

/**
 * This hook is a wrapper around the Redux dispatch function.
 * It takes in an action creator and returns a function that
 * dispatches that action with more concise syntax.
 */
export function useDispatcher<
  T extends string,
  AC extends ActionCreatorWithPayload<any, T> | ActionCreatorWithoutPayload<T>,
>(fn: AC): DispatcherReturnType<T, AC> {
  const dispatch = useDispatch();

  return useCallback(
    (payload: PayloadType<AC, T>) => dispatch(fn(payload)),
    [dispatch, fn]
  ) as unknown as DispatcherReturnType<T, AC>;
}

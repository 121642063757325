import React from 'react';
import { ResultTableConfigColumn } from '@config/ResultTable/ResultTable';
import { t } from '@webInterfaces/I18n';
import DarkThemeColors from '@webAtoms/Theme/theme.dark.scss';
import { TagProps } from '@webMolecules/Tag/Tag';
import { Finding } from '@entities/Finding';
import { AnyUnit } from '@entities/Unit';
import { Detection } from '@entities/Detection';
import { getUniqueDetectionSizes } from '@webViews/Pages/Study/helper';
import { ClinicalAction } from '@entities/Characteristics';
import { ScoreObject } from '@handlers/TiRadsHandler';

export const FINDING_SIZE_WIDTH = 'width';
export const FINDING_SIZE_LENGTH = 'length';
export const FINDING_VOLUME = 'volume';
export const FINDING_SIZE_DIMENSION = 'size';

export const getFieldStyle = ({
  width,
  minWidth,
}: ResultTableConfigColumn): React.CSSProperties | undefined => {
  if (!width && !minWidth) {
    return;
  }
  return { flex: 'none', width, minWidth };
};

export const labelColor = (defaultDifferenceValue: number) => {
  if (defaultDifferenceValue < 0) {
    return 'negative';
  } else if (defaultDifferenceValue > 0) {
    return 'positive';
  } else return 'default';
};

export const getColorFromLabel = (
  label: 'default' | 'positive' | 'negative'
) => {
  switch (label) {
    case 'positive':
      return DarkThemeColors.textDestructiveColor;
    case 'negative':
      return DarkThemeColors.textSuccessColor;
    default:
      return undefined;
  }
};

export const getIconByIconStyle = (
  label: 'default' | 'positive' | 'negative'
) => {
  switch (label) {
    case 'positive':
      return 'arrow-up';
    case 'negative':
      return 'arrow-down';
    default:
      return 'non-change';
  }
};

export const evaluateFieldFunction = (
  fieldFunction: ((finding: Finding) => boolean) | undefined,
  finding: Finding
) => {
  return fieldFunction?.(finding) ?? false;
};

export function getDisplayedOptionLabel(
  optionValue: string,
  optionLabel: string,
  scoreConfig?: ScoreObject
) {
  // If the option has a TiRad score point value, append it to the displayed label
  if (scoreConfig) {
    const optionScore = scoreConfig[optionValue].score;
    return `${optionLabel} (${optionScore})`;
  }
  return optionLabel;
}
export const clinicalActionToString = (
  input: ClinicalAction | undefined
): string | undefined => {
  switch (input) {
    case ClinicalAction.FNA:
      return t('tag.label.clinical_action.fna');
    case ClinicalAction.FollowUp:
      return t('tag.label.clinical_action.follow_up');
    case ClinicalAction.NoFurtherFollowUp:
      return t('tag.label.clinical_action.no_further_follow_up');
    default:
      return undefined;
  }
};
export const clinicalActionToTagIntent = (
  input: ClinicalAction | undefined
): TagProps['intent'] => {
  switch (input) {
    case ClinicalAction.FNA:
      return 'destructive';
    case ClinicalAction.FollowUp:
      return 'secondary';
    case ClinicalAction.NoFurtherFollowUp:
    default:
      return 'primary';
  }
};

export const generateSizeOptionsFromDetections = (
  detections: Detection[],
  findingId: string,
  unit: AnyUnit
) => {
  const detectionSizes = detections
    .filter(d => d.assignedFinding?.id === findingId)
    .flatMap(d => d.detectionSizes);

  const options: {
    label: string;
    value: string | undefined;
    id: string | undefined;
  }[] = [];

  const sizeObjects = getUniqueDetectionSizes(detectionSizes, unit);

  sizeObjects.map(obj => {
    options.push({
      label: obj.size.toString(),
      value: obj.size.toString(),
      id: obj.id,
    });
  });

  options.push({
    label: t('gallery.finding.menu.not_available'),
    value: undefined,
    id: undefined,
  });

  return options;
};

import { Profile } from '@entities/Profile';
import { ApiStatus } from '@entities/Status';
import Maybe, { nothing } from '@monads/Maybe';
import Tuple, { pair } from '@monads/Tuple';

export interface AdminUsers {
  userLineItems: Tuple<ApiStatus, Profile[]>;
  targetUserLineItem: TargetUserLineItem;
  newTargetUserLineItem: Maybe<Profile>;
}

export type TargetUserLineItem = Maybe<TargetUserLineItemTuple>;
export type TargetUserLineItemTuple = Tuple<string, Maybe<Profile>>;

export const initial = (): AdminUsers => ({
  userLineItems: pair(ApiStatus.Idle, []),
  targetUserLineItem: nothing(),
  newTargetUserLineItem: nothing(),
});

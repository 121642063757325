import { Action } from 'redux';
import Result from '@monads/Result';
import * as Authentication from '@entities/Authentication';
import { Message } from '@entities/Message';
import * as ContractAuthenticate from '@contracts/Authenticate';
import * as ContractStorage from '@contracts/Storage';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { effect, none } from '@library/Effect';
import { Profile } from '@interfaces/Profile';
import { rootUseCase } from '@useCases/Root';
import { authenticateErrorToI18nKey } from '@interfaces/Authentication';
import * as RouteHelper from '@helpers/routes';
import { createUseCase } from '@helpers/createUseCase';

export const authenticateUseCase = {
  /**
   * On init
   */
  Init: createUseCase('AUTH_INIT').noPayload(),

  /**
   * A user can create an account
   *
   * A user can't actually create a new account;
   * A user will receive an email with a temporary password and has to provide
   * this password while creating the "new" account.
   */
  CreateAccount: createUseCase('AUTH_CREATE_ACCOUNT').withPayload<{
    email: string;
    currentPassword: string;
    newPassword: string;
    firstName: string;
    lastName: string;
  }>(),

  /**
   * A user can sign in to the application using a email and password.
   * - Make an attempt using email and password.
   */
  SignInAttempt: createUseCase('AUTH_SIGN_IN_ATTEMPT').withPayload<{
    email: string;
    password: string;
  }>(),

  /**
   * A user can use federated sign in to the application using a email and password.
   * - Make an attempt using email and password.
   */
  FederatedSignInAttempt: createUseCase(
    'AUTH_FEDERATED_SIGN_IN_ATTEMPT'
  ).noPayload(),

  /**
   * A user can sign out from the application
   */
  SignOut: createUseCase('AUTH_SIGN_OUT').noPayload(),

  /**
   * User session can be timed out
   */
  SessionTimedOut: createUseCase('AUTH_SESSION_TIMED_OUT').withPayload<{
    showMessage: boolean;
  }>(),

  /**
   * A user can sign in to the application using an existing session
   * - Make an attempt using existing session data
   */
  SignInSession: createUseCase('AUTH_SIGN_IN_SESSION').noPayload(),

  /**
   * Initialise amplify before session is resumed
   */
  InitAmplify: createUseCase('AUTH_INIT_AMPLIFY').noPayload(),

  /**
   * A user can sign in to the application using an existing session
   * - The sign in failed (silently).
   */
  SignInSessionFailed: createUseCase('AUTH_SIGN_IN_SESSION_FAILED').noPayload(),

  /**
   * A user can sign in to the application using a email and password.
   * - The sign in failed.
   */
  SignInFailed: createUseCase('AUTH_SIGN_IN_FAILED').withPayload<{
    error: ContractAuthenticate.AuthenticateError;
  }>(),

  /**
   * A user can sign in to the application using a email and password.
   * - The sign in succeeded.
   */
  SignInSucceed: createUseCase('AUTH_SIGN_IN_SUCCEED').withPayload<{
    token: string;
    email: string;
    profile: Profile;
    security: Authentication.SecurityConfiguration;
  }>(),

  /**
   * A user can sign in to the application using a email and password.
   * - The sign in blocked when in wrong domain.
   */
  SignInBlocked: createUseCase('AUTH_SIGN_IN_BLOCKED').noPayload(),

  /**
   * A user can request a new password
   */
  ForgotPassword: createUseCase('AUTH_FORGOT_PASSWORD').withPayload<{
    email: string;
  }>(),

  /**
   * A user can request a new password
   * - A request was made (A confirmation code was sent)
   */
  ForgotPasswordRequested: createUseCase(
    'AUTH_FORGOT_PASSWORD_REQUESTED'
  ).withPayload<{
    email: string;
  }>(),

  /**
   * A user can reset a forgotten password using a verification code and a new password
   */
  ForgotPasswordReset: createUseCase('AUTH_FORGOT_PASSWORD_RESET').withPayload<{
    email: string;
    code: string;
    newPassword: string;
  }>(),

  /**
   * A user can reset a forgotten password using a verification code and a new password
   * - Succeeded
   */
  ForgotPasswordResetSuccess: createUseCase(
    'AUTH_FORGOT_PASSWORD_RESET_SUCCESS'
  ).noPayload(),

  /**
   * A user can change their current password using old and a new password
   * - Succeeded
   */
  ChangePasswordSuccess: createUseCase(
    'AUTH_CHANGE_PASSWORD_SUCCESS'
  ).noPayload(),

  /**
   * A user can update their password
   */
  ChangePassword: createUseCase('AUTH_CHANGE_PASSWORD').withPayload<{
    oldPassword: string;
    newPassword: string;
  }>(),

  /**
   * The application can set a message
   */
  SetMessage: createUseCase('AUTH_SET_MESSAGE').withPayload<{
    message: Message;
    clearOthers: boolean;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('AUTH_NO_OP').noPayload(),

  /**
   * A user can set up MFA
   */
  SetupMFA: createUseCase('AUTH_SETUP_MFA').noPayload(),

  /**
   * A user can set up MFA
   */
  SetUpMFASuccess: createUseCase('AUTH_SETUP_MFA_SUCCESS').withPayload<{
    secretKey: string;
  }>(),

  /**
   * A user can enable MFA by verifying an OTP
   */
  EnableMFA: createUseCase('AUTH_ENABLE_MFA').withPayload<{
    token: string;
  }>(),

  /**
   * A user has successfully setup MFA
   */
  EnableMFASuccess: createUseCase('AUTH_ENABLE_MFA_SUCCESS').noPayload(),

  /**
   * A user has failed to setup MFA
   */
  EnableMFAFailed: createUseCase('AUTH_ENABLE_MFA_FAILED').noPayload(),

  /**
   * A user can enable MFA
   */
  MFACodeRequired: createUseCase('AUTH_MFA_OTP_REQUIRED').noPayload(),

  /**
   * A user can log in with MFA code
   */
  LoginWithMFA: createUseCase('AUTH_LOGIN_WITH_MFA').withPayload<{
    otp: string;
  }>(),

  /**
   * A user can disable MFA
   */
  DisableMFA: createUseCase('AUTH_DISABLE_MFA').withPayload<{
    token: string;
  }>(),

  /**
   * A user has successfully disabled MFA on their account
   */
  DisableMFASuccess: createUseCase('AUTH_DISABLE_MFA_SUCCESS').noPayload(),

  /**
   * A user has failed to setup MFA
   */
  DisableMFAFailed: createUseCase('AUTH_DISABLE_MFA_FAILED').noPayload(),

  /**
   * A user can verify MFA token
   */
  VerifyMFAToken: createUseCase('AUTH_VERIFY_MFA_TOKEN').withPayload<{
    token: string;
  }>(),
};

/**
 *  All UseCases
 * */
export type AuthUseCases = ReturnType<
  | typeof authenticateUseCase.Init
  | typeof authenticateUseCase.CreateAccount
  | typeof authenticateUseCase.SignInSession
  | typeof authenticateUseCase.FederatedSignInAttempt
  | typeof authenticateUseCase.SignInSessionFailed
  | typeof authenticateUseCase.SignInAttempt
  | typeof authenticateUseCase.SignOut
  | typeof authenticateUseCase.ChangePassword
  | typeof authenticateUseCase.ChangePasswordSuccess
  | typeof authenticateUseCase.SignInFailed
  | typeof authenticateUseCase.SignInSucceed
  | typeof authenticateUseCase.ForgotPassword
  | typeof authenticateUseCase.ForgotPasswordRequested
  | typeof authenticateUseCase.ForgotPasswordReset
  | typeof authenticateUseCase.ForgotPasswordResetSuccess
  | typeof authenticateUseCase.SetMessage
  | typeof authenticateUseCase.SessionTimedOut
  | typeof authenticateUseCase.NoOp
  | typeof authenticateUseCase.SetupMFA
  | typeof authenticateUseCase.SetUpMFASuccess
  | typeof authenticateUseCase.MFACodeRequired
  | typeof authenticateUseCase.EnableMFA
  | typeof authenticateUseCase.EnableMFASuccess
  | typeof authenticateUseCase.EnableMFAFailed
  | typeof authenticateUseCase.DisableMFA
  | typeof authenticateUseCase.DisableMFASuccess
  | typeof authenticateUseCase.DisableMFAFailed
  | typeof authenticateUseCase.LoginWithMFA
  | typeof authenticateUseCase.InitAmplify
  | typeof authenticateUseCase.SignInBlocked
>;

/**
 * Reducer
 *
 * The UserReducer takes authenticateUseCases and the current User (state)
 * and returns a new state and possible side effects.
 */
export class AuthReducer extends EffectReducer<Authentication.Authentication> {
  private apiAuthentication: ContractAuthenticate.Authenticate;
  private apiStorage: ContractStorage.Storage;

  constructor(
    authenticate: ContractAuthenticate.Authenticate,
    apiStorage: ContractStorage.Storage
  ) {
    super();
    this.apiAuthentication = authenticate;
    this.apiStorage = apiStorage;
  }

  Perform(
    auth: Authentication.Authentication = Authentication.initial(),
    { type, payload: useCase }: AuthUseCases
  ): ReducerResult<Authentication.Authentication> {
    switch (type) {
      case authenticateUseCase.Init.type:
        return this.Perform(auth, authenticateUseCase.InitAmplify());

      case authenticateUseCase.CreateAccount.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusAuthenticating(),
          },
          effectCreateAccount(
            this.apiAuthentication,
            this.apiStorage,
            useCase.email,
            useCase.currentPassword,
            useCase.newPassword,
            useCase.firstName,
            useCase.lastName
          )
        );

      case authenticateUseCase.SignInAttempt.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusAuthenticating(),
          },
          effectSignIn(
            this.apiAuthentication,
            this.apiStorage,
            useCase.email,
            useCase.password
          )
        );

      case authenticateUseCase.FederatedSignInAttempt.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusAuthenticating(),
          },
          effectFederatedSignIn(this.apiAuthentication, this.apiStorage)
        );

      case authenticateUseCase.SessionTimedOut.type:
        return this.Result(
          Authentication.initial(),
          effectSignOut(this.apiAuthentication, useCase.showMessage),
          [rootUseCase.Clear()]
        );

      case authenticateUseCase.SignOut.type:
        return this.Result(
          Authentication.initial(),
          effectSignOut(this.apiAuthentication, false),
          [rootUseCase.Clear()]
        );
      case authenticateUseCase.InitAmplify.type:
        return this.Result(
          {
            status:
              Authentication.makeAuthenticationStatusAuthenticatingFromSession(),
          },
          effectInitAmplify(this.apiAuthentication)
        );
      case authenticateUseCase.SignInSession.type:
        return this.Result(
          {
            status:
              Authentication.makeAuthenticationStatusAuthenticatingFromSession(),
          },
          effectResumeSession(this.apiAuthentication, this.apiStorage)
        );

      case authenticateUseCase.SignInSessionFailed.type:
        return this.Result({
          status: Authentication.makeAuthenticationStatusNone(),
        });

      case authenticateUseCase.SignInBlocked.type: {
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusBlocked(),
          },
          none(),
          [rootUseCase.Redirect({ pathname: RouteHelper.blockedDomain })]
        );
      }
      case authenticateUseCase.SignInSucceed.type: {
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusAuthenticated({
              email: useCase.email,
              userId: useCase.profile.id,
              security: useCase.security,
              token: useCase.token,
            }),
          },
          none(),
          [
            rootUseCase.ReloadLocale({
              locale: useCase.profile.preferences.locale,
            }),
            rootUseCase.InitApp({ profile: useCase.profile }),
          ]
        );
      }

      case authenticateUseCase.SignInFailed.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusNone(),
          },
          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'error',
                key: authenticateErrorToI18nKey(useCase.error),
              },
            }),
          ]
        );

      case authenticateUseCase.ForgotPassword.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusAuthenticating(),
          },
          effectRequestForgotPassword(this.apiAuthentication, useCase.email)
        );

      case authenticateUseCase.ForgotPasswordRequested.type:
        return this.Result(
          {
            status:
              Authentication.makeAuthenticationStatusAwaitingPasswordReset({
                email: useCase.email,
              }),
          },
          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'success',
                key: 'auth.success.forgot_password_code_sent',
              },
            }),
          ]
        );

      case authenticateUseCase.ForgotPasswordReset.type:
        return this.Result(
          {
            ...auth,
            // userDetails: UserDetails.Authenticating(), // @TODO handle loading differently
          },
          effectSubmitForgotPassword(
            this.apiAuthentication,
            useCase.email,
            useCase.code,
            useCase.newPassword
          ),
          [rootUseCase.ClearMessages()]
        );

      case authenticateUseCase.ForgotPasswordResetSuccess.type:
        return this.Result(
          {
            status:
              Authentication.makeAuthenticationStatusAwaitingPasswordResetLogin(),
          },
          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'success',
                key: 'auth.success.forgot_password_completed',
              },
            }),
            rootUseCase.Redirect({
              pathname: RouteHelper.authSignInReasonPasswordWasReset,
            }),
          ]
        );

      case authenticateUseCase.ChangePassword.type:
        return this.Result(
          auth,
          effectUserChangePassword(
            this.apiAuthentication,
            useCase.oldPassword,
            useCase.newPassword
          ),
          [rootUseCase.ClearMessages()]
        );

      case authenticateUseCase.ChangePasswordSuccess.type:
        return this.Result(auth, none(), [
          rootUseCase.AddMessage({
            message: {
              intent: 'success',
              key: 'auth.success.change_password_completed',
              source: 'ChangePassword',
            },
          }),
        ]);

      case authenticateUseCase.SetupMFA.type:
        return this.Result(auth, effectSetUpMFA(this.apiAuthentication));

      case authenticateUseCase.SetUpMFASuccess.type: {
        if (
          auth.status.type ===
          Authentication.AUTHENTICATION_STATUS_AUTHENTICATED
        ) {
          const { email } = auth.status;
          const { secretKey } = useCase;
          const issuer = 'See-Mode';
          const authUrl = `otpauth://totp/SeeMode:${email}?secret=${secretKey}&issuer=${issuer}`;
          const security = {
            hasMFAEnabled: false,
            otpAuthUrl: authUrl,
          };

          return this.Result({
            ...auth,
            status: {
              ...auth.status,
              security,
            },
          });
        }

        return this.Result(auth);
      }

      case authenticateUseCase.MFACodeRequired.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusMFARequired(),
          },
          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'info',
                source: 'MFA',
                key: 'auth.info.mfa_required',
              },
            }),
          ]
        );

      case authenticateUseCase.LoginWithMFA.type:
        return this.Result(
          {
            status: Authentication.makeAuthenticationStatusMFAAuthenticating(),
          },
          effectLoginWithMFA(
            this.apiAuthentication,
            this.apiStorage,
            useCase.otp
          )
        );

      case authenticateUseCase.EnableMFA.type:
        return this.Result(
          auth,
          effectEnableMFA(this.apiAuthentication, useCase.token)
        );

      case authenticateUseCase.EnableMFASuccess.type: {
        if (
          auth.status.type !==
          Authentication.AUTHENTICATION_STATUS_AUTHENTICATED
        ) {
          return this.Result(auth);
        }

        const security = {
          ...auth.status.security,
          hasMFAEnabled: true,
        };

        return this.Result(
          {
            status: {
              ...auth.status,
              security,
            },
          },
          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'success',
                source: 'MFA',
                key: 'auth.success.mfa_enabled',
              },
            }),
          ]
        );
      }

      case authenticateUseCase.EnableMFAFailed.type:
        return this.Result(
          auth,

          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'error',
                source: 'MFA',
                key: 'auth.error.mfa_code',
              },
            }),
          ]
        );

      case authenticateUseCase.DisableMFA.type:
        return this.Result(
          auth,
          effectDisableMFA(this.apiAuthentication, useCase.token)
        );

      case authenticateUseCase.DisableMFASuccess.type: {
        if (
          auth.status.type !==
          Authentication.AUTHENTICATION_STATUS_AUTHENTICATED
        ) {
          return this.Result(auth);
        }
        const security = {
          ...auth.status.security,
          hasMFAEnabled: false,
        };

        return this.Result(
          {
            status: {
              ...auth.status,
              security,
            },
          },
          none(),
          [
            rootUseCase.AddMessage({
              message: {
                intent: 'success',
                source: 'MFA',
                key: 'auth.success.mfa_disabled',
              },
            }),
          ]
        );
      }

      case authenticateUseCase.DisableMFAFailed.type:
        return this.Result(auth, none(), [
          rootUseCase.AddMessage({
            message: {
              intent: 'error',
              source: 'MFA',
              key: 'auth.error.mfa_code',
            },
          }),
        ]);

      case authenticateUseCase.SetMessage.type: {
        return this.Result(auth, none(), [
          rootUseCase.AddMessage({ message: useCase.message }),
        ]);
      }

      case authenticateUseCase.NoOp.type:
        return this.Result(auth);
    }
  }
}

/**
 * InitAmplify
 */
const effectInitAmplify = (
  apiAuthentication: ContractAuthenticate.Authenticate
): Effect =>
  effect(async () => {
    const initResult = await apiAuthentication.InitAmplify();
    if (initResult) {
      return authenticateUseCase.SignInSession();
    }
    return authenticateUseCase.SignInBlocked();
  }, 'effect-user-init-amplify').then(result =>
    result.recoverError(() =>
      authenticateUseCase.SignInFailed({
        error: ContractAuthenticate.AuthenticateError.AuthenticateErrorGeneric,
      })
    )
  );

/**
 * Create an account
 */
const effectCreateAccount = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  apiStorage: ContractStorage.Storage,
  email: string,
  currentPassword: string,
  newPassword: string,
  firstName: string,
  lastName: string
): Effect =>
  effect(async () => {
    const authResult = await apiAuthentication.CreateAccount({
      storage: apiStorage,
      email,
      currentPassword: currentPassword,
      newPassword: newPassword,
      details: {
        firstName: firstName,
        lastName: lastName,
      },
    });

    switch (authResult.type) {
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultSuccess:
        return authenticateUseCase.SignInSucceed(authResult);
      case ContractAuthenticate.AuthenticateResultType.AuthenticateResultError:
        return authenticateUseCase.SignInFailed({ error: authResult.error });
      default:
        return authenticateUseCase.SignInFailed({
          error:
            ContractAuthenticate.AuthenticateError.AuthenticateErrorGeneric,
        });
    }
  }, 'effect-user-create-account').then(result =>
    result.recoverError(() =>
      authenticateUseCase.SignInFailed({
        error: ContractAuthenticate.AuthenticateError.AuthenticateErrorGeneric,
      })
    )
  );

/**
 * Sign In using email and password
 */
const effectSignIn = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  apiStorage: ContractStorage.Storage,
  email: string,
  password: string
): Effect =>
  effect(async () => {
    const authResult = await apiAuthentication.SignIn(
      apiStorage,
      email,
      password
    );
    switch (authResult.type) {
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultSuccess:
        return authenticateUseCase.SignInSucceed(authResult);
      case ContractAuthenticate.AuthenticateResultType.AuthenticateResultError:
        return authenticateUseCase.SignInFailed({ error: authResult.error });
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultMFACodeRequired:
        return authenticateUseCase.MFACodeRequired();
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultNewPasswordRequired:
        return authenticateUseCase.SignInFailed({
          error:
            ContractAuthenticate.AuthenticateError
              .AuthenticateErrorAccountCreateRequired,
        });
    }
  }, 'effect-user-sign-in').then(result =>
    result.recoverError(() =>
      authenticateUseCase.SignInFailed({
        error: ContractAuthenticate.AuthenticateError.AuthenticateErrorGeneric,
      })
    )
  );

/**
 * Federated Sign In
 */
const effectFederatedSignIn = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  apiStorage: ContractStorage.Storage
): Effect =>
  effect(async () => {
    await apiAuthentication.FederatedSignIn(apiStorage);
    return authenticateUseCase.NoOp();
  }, 'effect-user-sign-in').then(result =>
    result.recoverError(() => {
      return authenticateUseCase.SignInFailed({
        error: ContractAuthenticate.AuthenticateError.AuthenticateErrorGeneric,
      });
    })
  );

/**
 * Resume session (sign in using existing session data)
 */
const effectResumeSession = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  apiStorage: ContractStorage.Storage
): Effect =>
  effect(async () => {
    try {
      const authResult = await apiAuthentication.ResumeSession(apiStorage);
      if (
        authResult &&
        authResult.type ===
          ContractAuthenticate.AuthenticateResultType.AuthenticateResultSuccess
      ) {
        return authenticateUseCase.SignInSucceed(authResult);
      }
    } catch (e) {}
    return authenticateUseCase.SignInSessionFailed();
  }, 'effect-user-resume-session');

/**
 * Request a new password (request a verification flow)
 */
const effectRequestForgotPassword = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  email: string
): Effect =>
  effect(async () => {
    await apiAuthentication.ForgotPassword(email);
    return authenticateUseCase.ForgotPasswordRequested({ email });
  }, 'user-request-forgot-password').then(result =>
    result.recoverError(() =>
      authenticateUseCase.SetMessage({
        message: {
          intent: 'error',
          key: 'auth.error.forgot_password_incorrect_email',
        },
        clearOthers: true,
      })
    )
  );

/**
 * Reset a forgotten password by supplying a verification code and new password for the given email
 */
const effectSubmitForgotPassword = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  email: string,
  code: string,
  newPassword: string
): Effect =>
  effect(async () => {
    await apiAuthentication.ForgotPasswordReset(email, code, newPassword);
    return authenticateUseCase.ForgotPasswordResetSuccess();
  }, 'effect-user-submit-forgot-password').then(result =>
    result.recoverError(() =>
      authenticateUseCase.SetMessage({
        message: {
          intent: 'error',
          key: 'auth.error.forgot_password_incorrect_code',
        },
        clearOthers: true,
      })
    )
  );

/**
 * Change Password
 */
const effectUserChangePassword = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  oldPassword: string,
  newPassword: string
): Effect =>
  effect(async () => {
    const authType = await apiAuthentication.ChangePassword(
      oldPassword,
      newPassword
    );
    switch (authType.type) {
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultSuccess:
        return authenticateUseCase.ChangePasswordSuccess();
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultError: {
        return authenticateUseCase.SetMessage({
          message: {
            intent: 'error',
            source: 'ChangePassword',
            key: (() => {
              switch (authType.error) {
                case ContractAuthenticate.AuthenticateError
                  .AuthenticateErrorLimitExceededError:
                  return 'auth.error.attempt_limit_exceeded';
                case ContractAuthenticate.AuthenticateError
                  .AuthenticateErrorIncorrectEmailOrPassword:
                  return 'auth.error.incorrect_email_or_password';
                default:
                  return 'auth.error.change_password_different_password';
              }
            })(),
          },
          clearOthers: true,
        });
      }
      default:
        return authenticateUseCase.SetMessage({
          message: {
            intent: 'error',
            source: 'ChangePassword',
            key: 'auth.error.change_password_different_password',
          },
          clearOthers: true,
        });
    }
  }, 'effect-user-change-password').then(result =>
    result.recoverError(() => {
      return authenticateUseCase.SetMessage({
        message: {
          intent: 'error',
          source: 'ChangePassword',
          key: 'auth.error.generic',
        },
        clearOthers: true,
      });
    })
  );

/**
 * Sign Out
 */
const effectSignOut = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  showMessage: boolean
): Effect =>
  effect(async () => {
    await apiAuthentication.SignOut();
    if (showMessage) {
      window.location.href = RouteHelper.authSignInReasonSessionTimedOut;
    } else {
      window.location.href = RouteHelper.authSignIn;
    }
    return authenticateUseCase.NoOp();
  }, 'effect-user-sign-out');

/**
 *  Set up MFA effect
 */
const effectSetUpMFA = (
  apiAuthentication: ContractAuthenticate.Authenticate
): Effect =>
  effect(async () => {
    const mfaSetupResult = await apiAuthentication.SetUpMFA();

    switch (mfaSetupResult.type) {
      case ContractAuthenticate.MFASetupType.MFASetupInitialize:
        return authenticateUseCase.SetUpMFASuccess({
          secretKey: mfaSetupResult.secretKey,
        });
      default:
        return authenticateUseCase.SignInSessionFailed();
    }
  }, 'effect-user-set-up-mfa').then(recoverErrorWithGenericErrorMessage);

/**
 * Enable MFA by supplying a one time password
 */
const effectEnableMFA = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  otp: string
): Effect =>
  effect(async () => {
    const status = await apiAuthentication.EnableMFA(otp);
    if (status) {
      return authenticateUseCase.EnableMFASuccess();
    } else {
      return authenticateUseCase.EnableMFAFailed();
    }
  }, 'effect-user-enable-mfa').then(recoverErrorWithGenericErrorMessage);

/**
 * Disable MFA by supplying a one time password
 */
const effectDisableMFA = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  otp: string
): Effect =>
  effect(async () => {
    const status = await apiAuthentication.DisableMFA(otp);
    if (status) {
      return authenticateUseCase.DisableMFASuccess();
    } else {
      return authenticateUseCase.DisableMFAFailed();
    }
  }, 'effect-user-disable-mfa').then(recoverErrorWithGenericErrorMessage);

/**
 * Login with one time password
 */
const effectLoginWithMFA = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  apiStorage: ContractStorage.Storage,
  otp: string
): Effect =>
  effect(async () => {
    const authResult = await apiAuthentication.SignInWithMFA(apiStorage, otp);

    switch (authResult.type) {
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultSuccess:
        return authenticateUseCase.SignInSucceed(authResult);
      case ContractAuthenticate.AuthenticateResultType.AuthenticateResultError:
        return authenticateUseCase.SignInFailed({ error: authResult.error });
      default:
        return authenticateUseCase.SetMessage({
          message: {
            intent: 'error',
            key: 'auth.error.generic',
          },
          clearOthers: true,
        });
    }
  }, 'effect-user-login-with-mfa').then(recoverErrorWithGenericErrorMessage);

const recoverErrorWithGenericErrorMessage = (result: Result<Action>) =>
  result.recoverError(() =>
    authenticateUseCase.SetMessage({
      message: {
        intent: 'error',
        key: 'auth.error.generic',
      },
      clearOthers: true,
    })
  );

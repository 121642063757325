import { createSelector } from '@reduxjs/toolkit';
import { selectSession } from './SessionSelectors';

export const selectFindingInteraction = createSelector(
  selectSession,
  session => session.findingInteraction
);

export const selectSelectedId = createSelector(
  selectFindingInteraction,
  findingInteraction => findingInteraction.selectedId
);

export const selectFilterId = createSelector(
  selectFindingInteraction,
  findingInteraction => findingInteraction.filterId
);

export const selectActiveFinding = createSelector(
  selectFindingInteraction,
  findingInteraction => findingInteraction.activeFinding
);

export const selectHoveredMeasurement = createSelector(
  selectFindingInteraction,
  findingInteraction => findingInteraction.hoverMeasurementId
);

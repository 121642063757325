import { Action } from 'redux';
import { produce } from 'immer';
import { v4 as uuid } from 'uuid';
import { AnalysisUploads, initial } from '@entities/AnalysisUploads';
import * as ContractDicomAnonymiser from '@contracts/DicomAnonymiser';
import { Analyses as ContractAnalyses } from '@contracts/Analyses';
import { Storage as ContractStorage } from '@contracts/Storage';
import { Encryption as ContractEncryption } from '@contracts/Encryption';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { effect, none } from '@library/Effect';
import {
  DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS,
  DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT,
  makeAnalysisUploadsLineItem,
  makeAnalysisReanalyseLineItem,
  AnalysisUploadsLineItem,
} from '@interfaces/AnalysisUploadsLineItem';
import { ExceptionUploadFailed } from '@interfaces/Errors';
import { sleep } from '@helpers/async';
import { toError } from '@helpers/error';
import { DicomUploadErrorInfo } from '@contracts/DicomAnonymiser';
import { Profile } from '@entities/Profile';
import { createUseCase } from '@helpers/createUseCase';
import {
  BatchStatus,
  StudyBatchStatusItem,
} from '@interfaces/StudyBatchStatusItem';
import * as RouteHelper from '@helpers/routes';
import { worklistUseCase } from './Worklist';
import { locationUseCase } from './Location';

export const analysisUploadsUseCase = {
  /**
   * Upload a new analysis
   */
  NewUpload: createUseCase('DIRECTORY_UPLOADS_NEW_UPLOAD').withPayload<{
    fileList: File[];
    onUpdate: (useCase: Action) => void;
    onError: (e: Error) => void;
  }>(),

  NewReanalyse: createUseCase('DIRECTORY_UPLOADS_NEW_REANALYSE').withPayload<{
    batchId: string;
    targetSessionId: string;
    autoRedirect: boolean;
  }>(),

  /**
   * Upload a new analysis - Progress
   */
  Progress: createUseCase('DIRECTORY_UPLOADS_NEW_UPLOAD_PROGRESS').withPayload<{
    localId: string;
    event: 'file_anonymised' | 'file_uploaded';
  }>(),

  /**
   * Upload a new analysis - Error
   */
  Error: createUseCase('DIRECTORY_UPLOADS_NEW_UPLOAD_ERROR').withPayload<{
    localId: string;
    fileName: string;
    errorMessage: string;
  }>(),

  /**
   * Upload a new analysis - Client side finished uploading
   */
  FinishedClientSide: createUseCase(
    'DIRECTORY_UPLOADS_NEW_UPLOAD_CLIENT_SIDE_FINISHED'
  ).withPayload<{
    batchId: string;
  }>(),

  /**
   * Upload a new analysis - Client side finished without result
   */
  ClientSideFinishedWithNoResult: createUseCase(
    'DIRECTORY_UPLOADS_NEW_UPLOAD_CLIENT_SIDE_FINISHED_NO_RESULT'
  ).withPayload<{
    localId: string;
  }>(),
  /**
   * Receive analysis status from backend
   */
  ReceivedBackendStatus: createUseCase(
    'DIRECTORY_UPLOADS_RECEIVE_BACKEND_STATUS'
  ).withPayload<{
    batchId: string;
    targetSessionId?: string;
    batchStatus: StudyBatchStatusItem;
  }>(),

  /**
   * Receive ready status
   */
  ReceiveReadyStatus: createUseCase(
    'DIRECTORY_UPLOADS_RECEIVE_SESSION_ID'
  ).withPayload<{
    batchId: string;
    analysisId: string;
    sessionId: string;
  }>(),

  ClearItem: createUseCase('DIRECTORY_UPLOAD_CLEAR_ITEM').withPayload<{
    batchId: string;
  }>(),

  /**
   * Clear all uploads
   */
  Clear: createUseCase('DIRECTORY_UPLOAD_CLEAR').noPayload(),

  /**
   * No Operation
   */
  NoOp: createUseCase('DIRECTORY_UPLOAD_NO_OP').noPayload(),

  /**
   * Halt reducer
   */
  Halt: createUseCase('DIRECTORY_UPLOAD_HALT').withPayload<{
    error: Error;
  }>(),
};

/**
 *  All UseCases
 */
export type AnalysisUploadsUseCases = ReturnType<
  | typeof analysisUploadsUseCase.NewUpload
  | typeof analysisUploadsUseCase.NewReanalyse
  | typeof analysisUploadsUseCase.Progress
  | typeof analysisUploadsUseCase.Error
  | typeof analysisUploadsUseCase.FinishedClientSide
  | typeof analysisUploadsUseCase.ClientSideFinishedWithNoResult
  | typeof analysisUploadsUseCase.ReceivedBackendStatus
  | typeof analysisUploadsUseCase.ReceiveReadyStatus
  | typeof analysisUploadsUseCase.Clear
  | typeof analysisUploadsUseCase.ClearItem
  | typeof analysisUploadsUseCase.NoOp
  | typeof analysisUploadsUseCase.Halt
>;

/*
 * Reducer
 *
 * The AnalysisUploadsReducer takes AnalysisUploadsUseCases and the current AnalysisUploads (state)
 * and returns a new state and possible side effects.
 */
export class AnalysisUploadsReducer extends EffectReducer<AnalysisUploads> {
  private apiDicomAnonymiser: ContractDicomAnonymiser.DicomAnonymiser;
  private apiAnalyses: ContractAnalyses;
  private apiStorage: ContractStorage;
  private apiEncryption: ContractEncryption;
  private profile: Profile;

  constructor(
    apiDicomAnonymiser: ContractDicomAnonymiser.DicomAnonymiser,
    apiAnalyses: ContractAnalyses,
    apiStorage: ContractStorage,
    apiEncryption: ContractEncryption,
    profile: Profile
  ) {
    super();
    this.apiDicomAnonymiser = apiDicomAnonymiser;
    this.apiAnalyses = apiAnalyses;
    this.apiStorage = apiStorage;
    this.apiEncryption = apiEncryption;
    this.profile = profile;
  }

  Perform(
    analysisUploads: AnalysisUploads = initial(),
    { type, payload: useCase }: AnalysisUploadsUseCases
  ): ReducerResult<AnalysisUploads> {
    switch (type) {
      case analysisUploadsUseCase.NewUpload.type:
        const amountOfFiles = useCase.fileList.length;
        if (amountOfFiles <= 0) {
          return this.Result(analysisUploads);
        }

        const newAnalysisUploadLineItem =
          makeAnalysisUploadsLineItem(amountOfFiles);
        return this.Result(
          {
            ...analysisUploads,
            list: [newAnalysisUploadLineItem, ...analysisUploads.list],
          },
          effectAnonymiseAndUploadFileList(
            this.apiDicomAnonymiser,
            this.apiAnalyses,
            this.apiStorage,
            this.apiEncryption,
            this.profile,
            uuid(),
            newAnalysisUploadLineItem.localBatchId,
            useCase.fileList,
            useCase.onUpdate,
            useCase.onError
          )
        );

      case analysisUploadsUseCase.NewReanalyse.type: {
        const { batchId, targetSessionId, autoRedirect } = useCase;
        const newAnalysisUploadLineItem = makeAnalysisReanalyseLineItem(
          batchId,
          autoRedirect
        );

        return this.Result(
          {
            ...analysisUploads,
            list: [newAnalysisUploadLineItem, ...analysisUploads.list],
          },
          effectGetAnalysisStatus(
            this.apiAnalyses,
            batchId,
            targetSessionId,
            1500
          )
        );
      }

      case analysisUploadsUseCase.Progress.type: {
        return this.Result(
          updateBatchStatusItem(analysisUploads, useCase.localId, a => {
            switch (a.type) {
              case DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT:
                switch (useCase.event) {
                  case 'file_anonymised':
                    a.filesAnonymised++;
                    break;
                  case 'file_uploaded':
                    a.filesUploaded++;
                    break;
                }
              case DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS:
                break;
            }
          })
        );
      }

      case analysisUploadsUseCase.Error.type: {
        return this.Result(
          updateBatchStatusItem(analysisUploads, useCase.localId, a => {
            switch (a.type) {
              case DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT:
                a.filesTotal--;
                a.errors.push({
                  fileName: useCase.fileName,
                  errorMessage: useCase.errorMessage,
                });
                break;
              case DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS:
                break;
            }
          })
        );
      }

      case analysisUploadsUseCase.FinishedClientSide.type:
        const newStudyBatchId = useCase.batchId;

        return this.Result(
          {
            ...analysisUploads,
          },
          effectGetAnalysisStatus(
            this.apiAnalyses,
            newStudyBatchId,
            undefined,
            1500
          )
        );

      case analysisUploadsUseCase.ClientSideFinishedWithNoResult.type:
        return this.Result({
          ...analysisUploads,
          list: analysisUploads.list.filter(
            a => a.localBatchId !== useCase.localId
          ),
        });

      case analysisUploadsUseCase.ReceivedBackendStatus.type: {
        const { batchId, targetSessionId, batchStatus } = useCase;

        const filteredAnalysis = extractAnalysisAndSessionId(
          batchStatus.analyses,
          targetSessionId
        );
        const isReady = filteredAnalysis !== null;
        const isError = batchStatus.status === BatchStatus.Error;

        if (isError) {
          return this.Result(
            updateBatchStatusItem(analysisUploads, batchId, a => {
              a.failed = true;
            })
          );
        }

        if (isReady) {
          return this.Result(
            updateBatchStatusItem(analysisUploads, batchId, a => {
              a.filesProcessed = batchStatus.filesProcessed;
              a.filesTotal = batchStatus.filesTotal;
            }),
            none(),
            [
              analysisUploadsUseCase.ReceiveReadyStatus({
                batchId,
                analysisId: filteredAnalysis.analysisId,
                sessionId: filteredAnalysis.sessionId,
              }),
            ]
          );
        } else {
          // Poll for the next status
          return this.Result(
            updateBatchStatusItem(analysisUploads, batchId, a => {
              a.filesProcessed = batchStatus.filesProcessed;
              a.filesTotal = batchStatus.filesTotal;
            }),
            effectGetAnalysisStatus(
              this.apiAnalyses,
              useCase.batchId,
              targetSessionId,
              1500
            )
          );
        }
      }

      case analysisUploadsUseCase.ReceiveReadyStatus.type:
        const { batchId, analysisId, sessionId } = useCase;

        const autoRedirect =
          analysisUploads.list.find(a => a.localBatchId === batchId)
            ?.autoRedirect ?? false;

        return this.Result(
          updateBatchStatusItem(analysisUploads, batchId, a => {
            a.localAnalysisId = analysisId;
            a.sessionId = sessionId;
          }),
          none(),
          [
            analysisUploadsUseCase.ClearItem({ batchId }),
            autoRedirect
              ? locationUseCase.Update({
                  pathname: RouteHelper.study(analysisId, sessionId),
                })
              : worklistUseCase.ListSessions({}),
          ]
        );

      case analysisUploadsUseCase.ClearItem.type: {
        return this.Result({
          list: analysisUploads.list.filter(
            a => a.localBatchId !== useCase.batchId
          ),
        });
      }

      case analysisUploadsUseCase.Clear.type:
        this.CancelLastEffect();
        return this.Result(initial());

      case analysisUploadsUseCase.NoOp.type:
        return this.Result(analysisUploads);

      case analysisUploadsUseCase.Halt.type:
        return this.Error(useCase.error);
    }
  }
}

const effectAnonymiseAndUploadFileList = (
  apiDicomAnonymiser: ContractDicomAnonymiser.DicomAnonymiser,
  apiAnalyses: ContractAnalyses,
  apiStorage: ContractStorage,
  apiEncryption: ContractEncryption,
  profile: Profile,
  localStudyId: string,
  localBatchId: string,
  fileList: File[],
  onUpdate: (useCase: AnalysisUploadsUseCases) => void,
  onError: (e: Error) => void
): Effect =>
  effect(async () => {
    const kmsKeyArn = profile.kmsKeyArn;
    const nativeRegion = profile.nativeRegion;
    const institutionId = profile.institutionId;
    const operatorCodes = profile.operatorMappingProperties
      .filter(o => o.column == 'operator_code')
      .map(o => o.value);
    await apiEncryption.configure(kmsKeyArn, nativeRegion);

    let accessionNumber = new Date().toLocaleString().replace(/\W/g, '-');
    let studyDescription = '';
    let stationName = '';
    let pacsStudyDate = '';
    let pacsStudyTime = '';
    let institutionName: string | undefined;
    const onProgress = (event: 'file_anonymised' | 'file_uploaded') =>
      onUpdate(
        analysisUploadsUseCase.Progress({
          localId: localBatchId,
          event,
        })
      );

    const decryptedPhiCollection: Array<string | undefined> = [];
    const smPatientIdCollection: Array<string | undefined> = [];

    const uploadPromises = [];
    const errorInfo: DicomUploadErrorInfo = {};

    const operatorCode =
      operatorCodes && operatorCodes.length > 0 ? operatorCodes[0] : '';

    let setMetaDataFlag = false;
    for (let i = 0; i < fileList.length; i++) {
      const fileName = fileList[i].name;

      try {
        const dicomFile = await apiDicomAnonymiser.ToDicomFile(
          institutionId,
          fileList[i]
        );
        decryptedPhiCollection.push(dicomFile.decryptedPhi);
        smPatientIdCollection.push(dicomFile.smPatientId);
        onProgress('file_anonymised');

        if (!setMetaDataFlag) {
          accessionNumber = dicomFile.dir || accessionNumber;
          studyDescription = dicomFile.studyDescription || studyDescription;
          institutionName = dicomFile.institutionName || institutionName;
          stationName = dicomFile.stationName || stationName;
          pacsStudyDate = dicomFile.dicomStudyDate || pacsStudyDate;
          pacsStudyTime = dicomFile.dicomStudyTime || pacsStudyTime;

          setMetaDataFlag = true;
        }

        // Note that the apiStorage has a queue set up so we are not going to halt our
        // for loop here on waiting for the upload to complete
        uploadPromises.push(
          apiAnalyses
            .UploadDicom(
              apiStorage,
              localStudyId,
              localBatchId,
              institutionId,
              dicomFile
            )
            .then(() => {
              onProgress('file_uploaded');
            })
        );
      } catch (e) {
        const error = toError(e);
        errorInfo[fileName] = ExceptionUploadFailed;
        onError(error);
        onUpdate(
          analysisUploadsUseCase.Error({
            localId: localBatchId,
            fileName,
            errorMessage: error.message,
          })
        );
      }
    }

    if (uploadPromises.length === 0) {
      await sleep(100);
      return analysisUploadsUseCase.ClientSideFinishedWithNoResult({
        localId: localBatchId,
      });
    }

    await Promise.all(uploadPromises)
      .then(() => {
        // Intentionally only using and encrypting the first non-null PHI.
        const decryptedPhi = decryptedPhiCollection.find(pI => pI);
        if (decryptedPhi) {
          return apiEncryption.encryptString(decryptedPhi);
        }
        return decryptedPhi;
      })
      .then(encryptedPhi => {
        const smPatientId = smPatientIdCollection.find(pI => pI) || '';
        apiAnalyses.NotifyUploadCompleted({
          batchUid: localBatchId,
          institutionId,
          userProfileId: [profile.id],
          studyDescription,
          stationName,
          encryptedPhi,
          smPatientId,
          institutionName,
          studyInstanceId: localStudyId,
          accessionId: accessionNumber,
          operatorCode: operatorCode,
          pacsScanDate: pacsStudyDate,
          pacsScanTime: pacsStudyTime,
          recievedFileUids: [],
          discardedFileUids: [],
          failedFileUids: [],
        });
      });

    return analysisUploadsUseCase.FinishedClientSide({
      batchId: localBatchId,
    });
  }, 'effect-analysis-uploads-anonymise-and-upload-file-list');

const effectGetAnalysisStatus = (
  apiAnalyses: ContractAnalyses,
  studyBatchId: string,
  targetSessionId: string | undefined,
  delay: number
): Effect =>
  effect(async () => {
    if (delay) {
      await sleep(delay);
    }
    const batchStatus = await apiAnalyses.GetStudyBatchStatus(studyBatchId);

    return analysisUploadsUseCase.ReceivedBackendStatus({
      batchId: studyBatchId,
      targetSessionId,
      batchStatus,
    });
  }, 'effect-analysis-uploads-get-analysis-status');

/**
 * Extract the first analysis and session id from the analyses list
 * Filters by targetSessionId if specified
 */
const extractAnalysisAndSessionId = (
  analyses: StudyBatchStatusItem['analyses'],
  targetSessionId?: string
): { analysisId: string; sessionId: string } | null => {
  let analysisId: string | undefined = undefined;
  let sessionId: string | undefined = undefined;

  analyses
    .filter(a => !targetSessionId || a.sessionIds.includes(targetSessionId))
    .forEach(a => {
      analysisId = a.analysisId;
      a.sessionIds
        .filter(s => !targetSessionId || s === targetSessionId)
        .forEach(s => {
          sessionId = s;
        });
    });

  if (analysisId && sessionId) {
    return {
      analysisId,
      sessionId,
    };
  }
  return null;
};

/**
 * Use the callback function to mutate the item with the specified batch id
 */
const updateBatchStatusItem = (
  analysisUploads: AnalysisUploads,
  batchId: string,
  f: (item: AnalysisUploadsLineItem) => void
) => {
  return produce(analysisUploads, draft => {
    const matchingItem = draft.list.find(a => a.localBatchId === batchId);
    if (matchingItem) {
      f(matchingItem);
    }
  });
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UserFilterModal from '@webOrganisms/UserFilterModal/UserFilterModal';
import { selectSession } from '@selectors/SessionSelectors';
import { selectWorklistProfiles } from '@selectors/BaseSelectors';
import { FilterListLineItem } from '@webOrganisms/FilterList/FilterList';
import { t } from '@webInterfaces/I18n';
import {
  eventStudyActionAddedCollaborator,
  eventStudyActionRemovedCollaborator,
} from '@webInterfaces/Analytics';
import { useDispatcher } from '@helpers/useDispatcher';
import { sessionUseCase } from '@useCases/Session';
import {
  AvatarGroup,
  AvatarGroupVariant,
} from '@webOrganisms/AvatarGroup/AvatarGroup';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';

interface HeaderUserFilterProps {}

export const HeaderUserFilter: React.FC<HeaderUserFilterProps> = ({}) => {
  const session = useSelector(selectSession);
  const profiles = useSelector(selectWorklistProfiles);
  const dispatchSetCollaborators = useDispatcher(
    sessionUseCase.SetCollaborators
  );

  const [isOpen, setOpen] = useState(false);

  const owner = session.owner.lift();
  const collaborators = session.collaborators.lift();
  const userList = profiles
    .filter(profile => !!profile.firstName && profile.isActive)
    .sort(a => (a.id === owner?.id ? -1 : 1))
    .map(profile => ({
      id: profile.id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      swatchColour: profile.swatchColour,
      isOwner: profile.id === owner?.id,
      value: profile.id,
      label: `${profile.firstName} ${profile.lastName}`,
      isCollaborator: !!collaborators?.find(id => profile.id === id),
      isActive: profile.isActive,
    }));

  const items = userList
    .filter(profile => !!profile.firstName && profile.isActive)
    .filter(profile => profile.id !== owner?.id)
    .map(profile => {
      return {
        id: profile.id,
        title: `${profile.firstName} ${profile.lastName}`,
        matchString: `${profile.firstName} ${profile.lastName} ${profile.email}`,
        swatchColour: profile.swatchColour,
        added: collaborators && !!collaborators.find(id => profile.id === id),
      } as FilterListLineItem;
    });

  const onSetCollaborators = (updatedCollaborators: string[]) => {
    if ((collaborators || []).length > updatedCollaborators.length) {
      eventStudyActionRemovedCollaborator();
    } else {
      eventStudyActionAddedCollaborator();
    }

    dispatchSetCollaborators({ collaborators: updatedCollaborators });
  };

  return (
    <>
      <AvatarGroup
        data={userList
          ?.filter(user => user.isCollaborator)
          .map(user => {
            return {
              color: user.swatchColour,
              name: user.label,
              initial: user.firstName,
            };
          })}
        size="medium"
        borderColor="var(--ds-background-color-ui)"
        variant={AvatarGroupVariant.Stack}
        actionSlot={() => (
          <Button
            data-testid="button-add-collaborator"
            iconAfter={<Icon name="plus-small" />}
            circle
            size="small"
            onClick={() => {
              setOpen(true);
            }}
          />
        )}
      />
      <UserFilterModal
        title={t('userfilter.modal.title.collaborators')}
        isOpen={isOpen}
        subtitle={t('userfilter.modal.subtitle.collaborators')}
        closeModal={() => setOpen(false)}
        onAdd={itemId => {
          const mutatedCollaborators = collaborators ? [...collaborators] : [];
          mutatedCollaborators.push(itemId);
          onSetCollaborators(mutatedCollaborators);
        }}
        onRemove={itemId => {
          let mutatedCollaborators = collaborators ? [...collaborators] : [];
          mutatedCollaborators = mutatedCollaborators.filter(
            id => id !== itemId
          );
          onSetCollaborators(mutatedCollaborators);
        }}
        items={items}
      />
    </>
  );
};

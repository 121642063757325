export enum ObjectControl {
  TopLeft = 'tl',
  TopRight = 'tr',
  BottomLeft = 'bl',
  BottomRight = 'br',
  MiddleTopRotate = 'mtr',
  MiddleLeft = 'ml',
  MiddleRight = 'mr',
  MiddleTop = 'mt',
  MiddleBottom = 'mb',
}

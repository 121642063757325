import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Finding } from '@entities/Finding';
import styles from './findingSummary.scss';

interface PreviewIconProps {
  icon: React.ReactNode;
}
export const PreviewIcon = ({ icon }: PreviewIconProps) => {
  return (
    <Box display="flex" justifyContent="center" className={styles.separator}>
      <Text display="block">{icon}</Text>
    </Box>
  );
};

export const checkIfSizeMissing = (finding: Finding) => {
  if (finding.formatted.isSize3D) {
    return (
      finding.formatted.width == null &&
      finding.formatted.height == null &&
      finding.formatted.length == null
    );
  } else {
    return finding.formatted.max == null;
  }
};

export const checkIfAnySizeMissing = (findings: Finding[]) => {
  return findings.some(finding => checkIfSizeMissing(finding));
};

import { Notifications, Notification, initial } from '@entities/Notifications';
import { createUseCase } from '@helpers/createUseCase';
import { EffectReducer, ReducerResult } from '@library/Reducer';

export const notificationUseCase = {
  /**
   * Add a Notification
   */
  Add: createUseCase('NOTIFICATIONS_ADD').withPayload<{
    notification: Omit<Notification, 'id'>;
    deduplicate?: boolean;
  }>(),

  /**
   * Update a Notification
   */
  Update: createUseCase('NOTIFICATIONS_UPDATE').withPayload<{
    id: number;
    update: Partial<Notification>;
  }>(),

  /**
   * Delete a Notification
   */
  Delete: createUseCase('NOTIFICATIONS_DELETE').withPayload<{
    id: number;
  }>(),

  /**
   * Clear all Notifications
   */
  Clear: createUseCase('NOTIFICATIONS_CLEAR').noPayload(),
};

/**
 *  All UseCases
 */
export type NotificationsUseCases = ReturnType<
  | typeof notificationUseCase.Add
  | typeof notificationUseCase.Update
  | typeof notificationUseCase.Delete
  | typeof notificationUseCase.Clear
>;

/*
 * Reducer
 *
 * The NotificationsReducer takes NotificationsUseCases and the current Notifications (state)
 * and returns a new state and possible side effects.
 */
export class NotificationsReducer extends EffectReducer<Notifications> {
  private lastId = 0;

  Perform(
    notifications: Notifications = initial(),
    { type, payload: useCase }: NotificationsUseCases
  ): ReducerResult<Notifications> {
    switch (type) {
      case notificationUseCase.Add.type: {
        const newNotification = {
          id: ++this.lastId,
          ...useCase.notification,
          autoClose:
            useCase.notification.autoClose ||
            useCase.notification.intent === 'success'
              ? 2000
              : false,
        } as Notification;

        const notificationShouldBeAdded = useCase.deduplicate
          ? Boolean(
              notifications.list.find(
                a =>
                  a.titleI18nKey === newNotification.titleI18nKey &&
                  a.bodyI18nKey === newNotification.bodyI18nKey
              )
            )
          : false;

        return this.Result({
          ...notifications,
          list: [
            ...(notificationShouldBeAdded ? [] : [newNotification]),
            ...notifications.list,
          ],
        });
      }

      case notificationUseCase.Update.type:
        return this.Result({
          ...notifications,
          list: notifications.list.map(a =>
            a.id === useCase.id
              ? {
                  ...a,
                  ...useCase.update,
                }
              : a
          ),
        });

      case notificationUseCase.Delete.type:
        return this.Result({
          ...notifications,
          list: notifications.list.filter(a => a.id !== useCase.id),
        });

      case notificationUseCase.Clear.type:
        return this.Result(initial());
    }
  }
}

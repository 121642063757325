import * as I from '@interfaces/FilterConfiguration';
import { adminWorkspacesUseCase as UC } from '@useCases/Admin/Workspaces';

export const load = () => UC.Load();

export const unload = (targetOnly?: boolean) => UC.Unload({ targetOnly });

export const empty = () => UC.CreateEmpty();

export const create = (filterConfiguration: I.FilterConfiguration) =>
  UC.Create({ filterConfiguration });

export const get = (id: string) => UC.Get({ id });

export const update = (filterConfiguration: I.FilterConfiguration) =>
  UC.Update({ filterConfiguration });

export const destroy = (id: string) => UC.Destroy({ id });

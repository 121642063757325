export interface Notifications {
  list: Notification[];
}

export interface Notification {
  id: number;
  intent: 'success' | 'error';
  titleI18nKey: string;
  bodyI18nKey?: string;
  cta?: {
    labelI18nKey: string;
    onClick: () => void;
  };
  autoClose?: number | false;
}

export const initial = (): Notifications => ({
  list: [],
});

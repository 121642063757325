/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { userColourPaletteActive } from '@appCanvas/interfaces/types/ColourPalette.type';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import {
  GalleryFilter,
  getDefaultGalleryFilter,
} from '@interfaces/GalleryFilter';
import { selectFilterId } from '@selectors/FindingInteractionSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { Workflow } from '@entities/Workflow';
import { selectStudyType } from '@selectors/SessionSelectors';
import { cnames } from '@helpers/cnames';
import GalleryWrapper from '../GalleryWrapper';
import ReportDiagramWrapper from '../Diagram/ReportDiagramWrapper';
import FindingsTableWrapper from '../FindingsTableWrapper';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import FindingFilterBar from '../FindingFilterBar/FindingFilterBar';
import styles from './radiology.scss';

export const RadiologySession: React.FC = () => {
  const removeFilter = useDispatcher(findingInteractionUseCase.RemoveFilter);
  const deselectActiveFinding = useDispatcher(
    findingInteractionUseCase.Deselect
  );
  const filterId = useSelector(selectFilterId);
  const studyType = useSelector(selectStudyType);

  const [galleryFilter, setGalleryFilter] = React.useState<GalleryFilter>(
    getDefaultGalleryFilter(studyType, true)
  );

  const setGalleryFilterWhenSelected: React.Dispatch<
    React.SetStateAction<GalleryFilter>
  > = (filter: SetStateAction<GalleryFilter>) => {
    if (!!filterId) {
      removeFilter();
    } else {
      setGalleryFilter(filter);
    }
  };

  const galleryFilterWhenSelected = !!filterId
    ? { configKeys: [], findingIds: [filterId] }
    : galleryFilter;

  return (
    <Box display="flex" onClick={deselectActiveFinding} fullWidth>
      <Box className={cnames(styles.scrollingPadding, styles.studyPanel)}>
        <GalleryWrapper
          loading={false}
          setGalleryFilter={setGalleryFilterWhenSelected}
          filters={galleryFilterWhenSelected}
          includeAllDicoms
          workflow={Workflow.Radiologist}
          useFindingGalleryThumbnail
        />
      </Box>

      <Box className={styles.studyPanel} display="flex" flexDirection="column">
        <Box paddingX="xl">
          <FindingFilterBar />
        </Box>
        <Box
          scrollable="y"
          className={styles.scrollingPadding}
          paddingX="xl"
          paddingTop="s"
        >
          <MiniCanvasProvider>
            <Box marginTop="xs" scrollable="x" paddingBottom="m">
              <ReportDiagramWrapper
                canvasColorPalette={userColourPaletteActive}
                showCanvasOnly={true}
                disableInteractions={false}
              />
            </Box>
            <Box marginTop="s">
              <FindingsTableWrapper
                applyFindingFilter={false}
                resultTableStyle={ResultTableStyle.editable}
              />
            </Box>
          </MiniCanvasProvider>
        </Box>
      </Box>
    </Box>
  );
};

import React from 'react';
import { Modal } from '@webMolecules/Modal/Modal';
import { Text } from '@webMolecules/Text/Text';
import FilterList, {
  FilterListLineItem,
} from '@webOrganisms/FilterList/FilterList';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import styles from './styles.scss';

export interface FilterModalProps {
  isOpen: boolean;
  title: string;
  subtitle: string;
  placeholder: string;
  showAvatar: boolean;
  items: FilterListLineItem[];
  closeModal: () => void;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}
const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  items,
  title,
  subtitle,
  placeholder,
  showAvatar,
  closeModal,
  onAdd,
  onRemove,
}) => {
  const filterList = (
    <FilterList
      placeholder={placeholder}
      subtitle={subtitle}
      items={items}
      showAvatar={showAvatar}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} ariaLabel="Users" size="medium">
      <Card elevation="medium" padding="none">
        <Box className={styles.modalBody} padding="xl">
          <Box className={styles.closeButton} padding="xl">
            <Button
              data-testid="filter-modal-close"
              circle
              intent="neutral"
              onClick={closeModal}
              iconBefore={<Icon name="x" />}
            />
          </Box>
          <Stack spacing="m">
            <Text type="display1" display="block" marginBottom="m">
              {title}
            </Text>

            <Box flex="5">{filterList}</Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
};

export default FilterModal;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { userColourPaletteActive } from '@appCanvas/interfaces/types/ColourPalette.type';
import { tiRadsGradingToNoduleColor } from '@webViews/Pages/Study/helper';
import { t } from '@webInterfaces/I18n';
import { TiRadModal } from '@webOrganisms/TiRadModal/TiRadModal';
import { InfoButton } from '@webMolecules/InfoButton/InfoButton';
import { ThyroidFinding } from '@entities/Finding';
import {
  computeFindingScorePoints,
  scorePointToGrading,
} from '@handlers/TiRadsHandler';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import styles from './score.scss';

export const ResultTableCellScore: React.FC<ResultTableSharedProps> = () => {
  const { watch } = useFormContext<ThyroidFinding>();

  const finding = watch();
  const score = computeFindingScorePoints(finding);
  const grading = scorePointToGrading(score);
  const labelText = t(
    `config_key.result_table.thyroid_finding.options.${grading}`
  );
  const color = tiRadsGradingToNoduleColor(grading, userColourPaletteActive);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="l">
      <Box
        data-testid="score-value"
        className={styles.score}
        style={{ backgroundColor: color }}
      >
        {labelText}
      </Box>
      <Text type="body2" marginLeft="s">
        {getScoreLabel(score)}
      </Text>
      <InfoButton type="modal" icon="info" component={TiRadModal} />
    </Box>
  );
};

function getScoreLabel(score: number) {
  return `${score} ${score === 1 ? 'point' : 'points'} total`;
}

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Stack } from '@webMolecules/Stack/Stack';
import { t } from '@webInterfaces/I18n';

export interface FederatedSignInProps {
  onSubmit: () => void;
  institutionCopyText: string | null;
  loading?: boolean;
}

export const FederatedSignIn = ({
  onSubmit,
  institutionCopyText,
  loading = false,
}: FederatedSignInProps) => {
  return (
    <Stack spacing="m" direction="vertical">
      <Box>
        <Button
          loading={loading}
          onClick={onSubmit}
          type="submit"
          intent="primary"
          strong
          fluid
          size="large"
          data-testid="federated-signin-submit"
        >
          {institutionCopyText}
        </Button>
      </Box>
      <Box>
        <Text muted element="sub" type="body2">
          {t('pages.auth.sign_in_federated.info')}
        </Text>
      </Box>
    </Stack>
  );
};

import React from 'react';
import { useController } from 'react-hook-form';
import { Switch, SwitchProps } from '@webMolecules/Switch/Switch';
import {
  ToggleGroup,
  ToggleGroupProps,
} from '@webMolecules/ToggleGroup/ToggleGroup';
import { Input, InputProps } from '@webMolecules/Input/Input';

interface FormSwitchProps extends Omit<SwitchProps, 'checked' | 'onChange'> {
  name: string;
}

export const FormSwitch: React.FC<FormSwitchProps> = ({ name, ...rest }) => {
  const { field } = useController({
    name,
  });

  return <Switch checked={field.value} onChange={field.onChange} {...rest} />;
};

interface FormToggleProps
  extends Omit<ToggleGroupProps, 'key' | 'value' | 'onChange'> {
  name: string;
}

export const FormToggle: React.FC<FormToggleProps> = ({ name, ...rest }) => {
  const { field } = useController({
    name,
  });

  const value =
    typeof field.value === 'boolean' ? field.value.toString() : field.value;

  return (
    <ToggleGroup key={name} value={value} onChange={field.onChange} {...rest} />
  );
};

interface FormInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  name: string;
}

export const FormInput: React.FC<FormInputProps> = ({ name, ...rest }) => {
  const { field } = useController({
    name,
  });
  const value = field.value == null ? '' : field.value;
  return <Input value={value} onChange={field.onChange} {...rest} />;
};

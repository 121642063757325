import React from 'react';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';
import { Footnote } from '@config/ResultTable/ResultTable';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import styles from './label.scss';

export interface ResultTableCellLabelProps extends ResultTableSharedProps {
  footnote?: Footnote;
}

export const ResultTableCellLabel: React.FC<ResultTableCellLabelProps> = ({
  isExpanded,
  toggleExpanded,
  displayI18NKey,
  footnote,
  footnotes,
}) => {
  const labelText = t(displayI18NKey ?? '');

  const footNoteIndex = footnote ? (
    <Text element="sup" marginLeft="xxs" muted>
      {footnotes?.get(footnote)}
    </Text>
  ) : null;

  const label = (
    <Text
      data-testid="test-label-data"
      display="block"
      type="body2"
      lineClamp={2}
    >
      {labelText}
      {footNoteIndex}
    </Text>
  );

  if (toggleExpanded) {
    return (
      <Box className={styles.toggler} onClick={toggleExpanded} display="flex">
        <Icon size="small" name={isExpanded ? 'minus-small' : 'plus-small'} />
        {label}
      </Box>
    );
  }

  return label;
};

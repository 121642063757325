import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import { Message } from '@interfaces/Message';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import * as RouteHelper from '@helpers/routes';
import { Institution } from '@entities/Admin/Institution';
import { Profile } from '@interfaces/Profile';
import { t, tPrint } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { MemoizedUserlistLineItem } from '../UserLineItem';

export interface InstitutionDetailProps {
  loading: boolean;
  profile: Profile;
  messages: Message[];
  targetInstitution: Institution;
  onDeleteUser: (id: string) => void;
}

export const InstitutionDetail: React.FC<InstitutionDetailProps> = ({
  loading,
  profile,
  targetInstitution,
  onDeleteUser,
}) => {
  const [deleteUserDialogUser, setDeleteUserDialogUser] = React.useState<
    false | Profile
  >(false);
  return (
    <>
      {deleteUserDialogUser && (
        <DialogConfirm
          isOpen={true}
          intent="destructive"
          labelOk={t('generic.submit.delete')}
          labelCancel={t('generic.submit.cancel')}
          onCancel={() => {
            setDeleteUserDialogUser(false);
          }}
          onConfirm={() => {
            onDeleteUser(deleteUserDialogUser.id);
            setDeleteUserDialogUser(false);
          }}
        >
          <Text type="display2" display="block" marginBottom="m">
            {t('pages.settings.users.dialog.delete.title')}
          </Text>
          <Text>
            {tPrint(
              'pages.settings.users.dialog.delete.blurb1',
              deleteUserDialogUser.email
            )}
          </Text>
        </DialogConfirm>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="xl"
      >
        <Box display="flex" alignItems="center">
          <Text type="display1" display="block" marginRight="m">
            {targetInstitution.id}
          </Text>
          {loading && <LoadingIcon />}
        </Box>

        <RouteLink
          to={RouteHelper.adminInstitutions(targetInstitution.id, 'new')}
          component={props => (
            <Button
              intent="primary"
              strong
              iconAfter={<Icon name="plus" />}
              circle
              size="small"
              {...props}
            />
          )}
        />
      </Box>
      <Box>
        <Schedule
          columns={[
            {
              label: t('pages.settings.users.heading.email'),
              width: '300px',
            },
            {
              label: t('pages.settings.users.heading.account_status'),
            },

            {
              label: t('pages.settings.users.heading.name'),
            },
            {
              label: t('pages.settings.users.heading.permission'),
            },
          ]}
          hasContextMenu={true}
          items={targetInstitution.users.map(user => (
            <MemoizedUserlistLineItem
              href={RouteHelper.adminInstitutions(
                targetInstitution.id,
                user.id
              )}
              onDeleteUser={setDeleteUserDialogUser}
              profile={profile}
              key={user.email}
              userLineItem={user}
              {...user}
            />
          ))}
        />
      </Box>
    </>
  );
};

export default InstitutionDetail;

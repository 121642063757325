import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';

export const ToastUploadErrorBackend: React.FC = () => {
  return (
    <Box display="flex">
      <Box paddingRight="m" paddingTop="xxxs">
        <Icon name="alert-triangle" color="var(--ds-color-destructive-500)" />
      </Box>
      <Box flex="1">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="s"
        >
          <Box display="flex">
            <Box>
              <Text display="block" type="display3">
                {t('uploads.status.error')}
              </Text>

              <Text display="block" marginTop="xxs">
                {t('uploads.status.error_processing')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { OperatorMappingProperty, Profile } from '@interfaces/Profile';
import { Storage } from '@contracts/Storage';
import { filterMap, concat } from '@helpers/array';
import { just, nothing, fromFalsy } from '@monads/Maybe';
import { SAML_EMAIL_PREFIX } from '@interfaces/Authentication';
import { schemas } from '@api/schema/client';
import { decodeInstitutionConfig } from './Institution';

export type UserProfileResponse = schemas['UserProfileResponse'];

const formatEmailAddress = (email: string | null): string => {
  return email?.replace(SAML_EMAIL_PREFIX, '') || '';
};

export const decode = async (
  storage: Storage,
  data: UserProfileResponse
): Promise<Profile> => {
  const parsedConfig = decodeInstitutionConfig(data.configuration);
  const parsedPreferences = data?.preferences || {};

  const logo = data?.logo
    ? await storage.GetEncodedImage(data.logo)
    : 'https://uploads-ssl.webflow.com/5f322e0386a545f3aa708bcd/5f35110db4462e4cf3265e64_logo.png';
  const institutionId = data?.institution_id || '';
  const operatorMappingProperties = decodeOperatorMappingProperties(
    data.operator_mapping ?? {}
  );

  const formattedEmail = formatEmailAddress(data.email);
  return {
    id: data?.user_profile_id || '',
    email: formattedEmail,
    institutionId,
    firstName: data?.firstname || '',
    lastName: data?.lastname || '',
    kmsKeyArn: data?.kms_key_arn || '',
    nativeRegion: data?.native_region || '',
    logo,
    swatchColour: data?.swatch || '',
    configuration: parsedConfig,
    preferences: parsedPreferences || '',
    isActive: data?.is_activated || false,
    isAdmin: data?.is_institution_owner === true || false,
    operatorMappingProperties,
  };
};

export const decodeSync = (data: UserProfileResponse): Profile => {
  const parsedConfig = decodeInstitutionConfig(data.configuration);
  const parsedPreferences = data.preferences || {};
  const institutionId = data?.institution_id || '';
  const operatorMappingProperties = decodeOperatorMappingProperties(
    data.operator_mapping ?? {}
  );

  const formattedEmail = formatEmailAddress(data.email);
  return {
    id: data?.user_profile_id || '',
    email: formattedEmail,
    institutionId,
    firstName: data?.firstname || '',
    lastName: data?.lastname || '',
    logo: '',
    kmsKeyArn: data?.kms_key_arn || '',
    nativeRegion: data?.native_region || '',
    swatchColour: data?.swatch || '',
    configuration: parsedConfig,
    preferences: parsedPreferences || '',
    isActive: data?.is_activated || false,
    isAdmin: data?.is_institution_owner === true || false,
    operatorMappingProperties,
  };
};

/*eslint-disable @typescript-eslint/no-explicit-any */
export const decodeOperatorMappingProperties = (
  data: Record<string, never>
): OperatorMappingProperty[] =>
  filterMap(
    filterMap(data.rules || [], (rawConfigObj: any) =>
      fromFalsy(rawConfigObj?.all)
    )
      .map(
        (allRules: any) =>
          allRules.map((rule: any) =>
            Object.keys(rule).map(k => ({ column: k, value: rule[k] }))
          ) as OperatorMappingProperty[][]
      )
      .map(concat),
    props => {
      const first = props.shift();
      return first
        ? just({
            ...(first as OperatorMappingProperty),
            childProperties: props.length > 0 ? props : undefined,
          })
        : nothing<OperatorMappingProperty>();
    }
  );
/*eslint-enable @typescript-eslint/no-explicit-any */

export const encodeOperatorMappingProperties = (
  operatorMappingProperties: OperatorMappingProperty[]
): Record<string, never> => {
  return {
    rules: operatorMappingProperties.map(operatorMappingProperty => ({
      all: [
        encodeProperty(operatorMappingProperty),
        ...(operatorMappingProperty.childProperties || []).map(encodeProperty),
      ],
    })) as never,
  };
};

const encodeProperty = (
  property: OperatorMappingProperty
): Record<string, string> => ({
  [property.column]: property.value,
});

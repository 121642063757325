import { Profile } from '@interfaces/Profile';
import { ApiStatus } from '@interfaces/Status';
import Tuple, { pair } from '@monads/Tuple';
import { Workspace } from '@entities/Workspace';
import { WorkspaceStatus } from '@entities/WorkspaceStatus';
import { FilterOptions } from '@interfaces/FilterOptions';
import Maybe, { nothing } from '@monads/Maybe';
import { StudyType } from './StudyType';
import { PatientHealthInfo, SessionStatus } from './SessionLineItem';

export interface Worklist {
  profiles: Tuple<ApiStatus, Profile[]>;
  availableWorkspaces: Tuple<ApiStatus, Maybe<Workspace[]>>;
  workspaceStatus: Tuple<ApiStatus, WorkspaceStatus[]>;
  worklistLineItems: Tuple<ApiStatus, WorklistLineItem[]>;
  totalAvailableItems: number;
  filterOptions: Maybe<FilterOptions>;
  status: ApiStatus;
  lastWorklistPath?: string | undefined;
}

export interface WorklistLineItem {
  id: string;
  analysisId: string;
  userId: string;
  date: Date | null;
  patientName: string;
  accessionNumber: string;
  status: SessionStatus;
  studyType: StudyType;
  generatedPDF: Maybe<string>;
  phiCipher?: string;
  decryptedPhi: PatientHealthInfo | undefined;
  decrypted: boolean;
  live: boolean;
  collaborators: string[];
  animated: boolean;
  isBookmarked: boolean;
  assignedWorkspaces?: string[];
  completedDate?: Date;
}

export const initial = (): Worklist => ({
  profiles: pair(ApiStatus.Idle, []),
  availableWorkspaces: pair(ApiStatus.Idle, nothing()),
  workspaceStatus: pair(ApiStatus.Idle, []),
  worklistLineItems: pair(ApiStatus.Idle, []),
  totalAvailableItems: 0,
  filterOptions: nothing(),
  status: ApiStatus.Idle,
});

import { createStore } from 'redux';
import { devToolsEnhancerDevelopmentOnly } from '@redux-devtools/extension';
import { Search } from '@api/Search';
import { Analyses } from '@api/Analyses';
import { Institutions } from '@api/Admin/Institutions';
import { Institution } from '@api/Admin/Institution';
import { Session } from '@api/Session';
import { Profiles } from '@api/Profiles';
import { FilterConfiguration } from '@api/Admin/FilterConfiguration';
import { Workspaces } from '@api/Workspaces';
import { PACSStudy } from '@api/PACSStudy';
import { AppConfiguration } from '@api/AppConfiguration';
import Result, { Err } from '@monads/Result';
import Effect from '@library/Effect';
import { RootReducer, rootUseCase } from '@useCases/Root';
import { initial } from '@entities/Root';
import { Authenticate } from '@amplify/Authenticate';
import { Encryption } from '@amplify/Encryption';
import { Storage } from '@amplify/Storage';
import { I18n } from '@web/I18n';
import { Location } from '@web/Location';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { PDF } from '@libraries/PDF';
import { ReducerResult } from '@library/Reducer';
import { onError } from '@webInterfaces/Error';
import { LaunchDarkly } from '@libraries/LaunchDarkly';
import Tuple from '@monads/Tuple';
import { DicomAnonymiser } from '../../data/impl/generic/DicomAnonymiser';

const defaultState = (): State => initial();

const location = new Location();

const rootReducer = new RootReducer({
  dicomAnonymiser: new DicomAnonymiser(),
  authenticate: new Authenticate(onError),
  institutions: new Institutions(),
  analyses: new Analyses(),
  i18n: new I18n(),
  session: new Session(),
  storage: new Storage(),
  pdf: new PDF(),
  profiles: new Profiles(),
  userEncryption: new Encryption(),
  search: new Search(),
  location,
  users: new Profiles(),
  filterConfiguration: new FilterConfiguration(),
  workspaces: new Workspaces(),
  pacsStudy: new PACSStudy(),
  appConfiguration: new AppConfiguration(),
  featureFlags: new LaunchDarkly(),
  institution: new Institution(),
});

const reducer = (state: State = defaultState(), action: Actions): State => {
  const result: ReducerResult<State> = rootReducer.Perform(state, action);

  if (result.isError()) {
    onError((result as Err).lift());
    location.Reload('/');
    return state;
  }

  const resultTuple = result.lift() as Tuple<State, Effect>;
  const resultEffect = resultTuple.second();
  resultEffect
    .recover((error: Error) => {
      onError(error);
      return rootUseCase.OnErrorEffect({
        state,
        label: resultEffect.label,
        error,
      });
    })
    .done((result: Result<Actions>) => {
      result.mapOk(dispatch);
    })
    .run();

  return resultTuple.first();
};

export const store = createStore(reducer, devToolsEnhancerDevelopmentOnly());
store.dispatch(rootUseCase.Init({ dispatch: store.dispatch }));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__store = store;

export const dispatch = (action: Actions) => {
  store.dispatch(action);
};

export const getState = (): State => store.getState();

export default store;

import { Action } from 'redux';
import * as Entity from '@entities/Notifications';
import { notificationUseCase } from '@useCases/Notifications';

export type Notification = Entity.Notification;

export const addCriticalErrorNotification = (): Action =>
  notificationUseCase.Add({
    notification: {
      intent: 'error',
      titleI18nKey: 'generic.error.critical.title',
      bodyI18nKey: 'generic.error.critical.body',
      cta: {
        labelI18nKey: 'generic.error.critical.cta_label',
        onClick: () => {
          window.location.reload();
        },
      },
    },
    deduplicate: true,
  });

export const addOfflineErrorNotification = (): Action =>
  notificationUseCase.Add({
    notification: {
      intent: 'error',
      titleI18nKey: 'generic.error.offline.title',
      bodyI18nKey: 'generic.error.offline.body',
      cta: {
        labelI18nKey: 'generic.error.offline.cta_label',
        onClick: () => {
          window.location.reload();
        },
      },
    },
    deduplicate: true,
  });

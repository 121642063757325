/* eslint-disable no-console */
import { verboseLevel } from './env';

export type LogLevel = 'system' | 'analytics' | 'effect' | 'api';

export const log = (
  level: LogLevel,
  label: string,
  ...args: unknown[]
): void => {
  if (!verboseLevel.includes('all') && !verboseLevel.includes(level)) {
    return;
  }
  console.log(`%c ${level} `, logLevelToStyle(level), label, ...args);
};

const logLevelToStyle = (level: LogLevel): string => {
  const baseStyle = ['color: #fff', 'padding: 2px 4px', 'border-radius: 2px'];
  switch (level) {
    case 'system':
    default:
      return [...baseStyle, 'background-color: #444'].join(';');
    case 'analytics':
      return [...baseStyle, 'background-color: #00f'].join(';');
    case 'api':
      return [...baseStyle, 'background-color: #f00'].join(';');
    case 'effect':
      return [...baseStyle, 'background-color: #0c0'].join(';');
  }
};

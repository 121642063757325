import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import {
  toProfile,
  toSearch,
  toAdmin,
  toPACS,
  toAppSettings,
} from '@interfaces/App';
import { toApp } from '@interfaces/Root';
import { toWorkspaces } from '@interfaces/Admin';
import * as ViewModelLayout from '@webViewModels/Pages/Settings/Layout';
import { Profile } from '@interfaces/Profile';
import { ApiStatus } from '@interfaces/Status';
import { Message } from '@interfaces/Message';
import { FilterConfigurationLineItem } from '@interfaces/Admin/FilterConfigurationLineItem';
import * as I from '@interfaces/FilterConfiguration';
import {
  create,
  get,
  update,
  destroy,
  unload,
} from '@webViewInterfaces/Settings/Admin/Workspaces';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import { FeatureFlags, getFeatureFlags } from '@entities/FeatureFlag';

export type Workspaces = WorkspacesProps &
  WorkspacesDispatchers &
  ViewModelLayout.Layout;

export interface WorkspacesProps {
  profile: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  loading: boolean;
  messages: Message[];
  filterConfigurationLineItems: FilterConfigurationLineItem[];
  targetFilterConfiguration?: I.FilterConfiguration;
  featureFlags: FeatureFlags;
}

export interface WorkspacesDispatchers {
  dispatchCreateFilterConfiguration: (
    filterConfiguration: I.FilterConfiguration
  ) => Dispatch<Actions>;
  dispatchGetFilterConfiguration: (id: string) => Dispatch<Actions>;
  dispatchUpdateFilterConfiguration: (
    filterConfiguration: I.FilterConfiguration
  ) => Dispatch<Actions>;
  dispatchDestroyFilterConfiguration: (id: string) => Dispatch<Actions>;
  dispatchUnloadTargetFilterConfiguration: () => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  ...ViewModelLayout.dispatchers(dispatch),
  dispatchCreateFilterConfiguration: (
    filterConfiguration: I.FilterConfiguration
  ) => dispatch(create(filterConfiguration)),
  dispatchGetFilterConfiguration: (id: string) => dispatch(get(id)),
  dispatchUpdateFilterConfiguration: (
    filterConfiguration: I.FilterConfiguration
  ) => dispatch(update(filterConfiguration)),
  dispatchDestroyFilterConfiguration: (id: string) => dispatch(destroy(id)),
  dispatchUnloadTargetFilterConfiguration: () => dispatch(unload(true)),
});

export const fromState = (state: State): WorkspacesProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }
  const profile = toProfile(app);

  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const search = toSearch(app);
  const searchQuery = search.query;
  const admin = toAdmin(app);
  const { apiStatus, filterConfigurationLineItems, targetFilterConfiguration } =
    toWorkspaces(admin);
  const loading = apiStatus === ApiStatus.Busy;
  const messages = [] as Message[];
  const pacs = toPACS(app);
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;

  const featureFlags = getFeatureFlags();

  return {
    loading,
    profile,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    searchQuery,
    pacsStudyLineItems,
    pacsSystemStatus,
    messages,
    filterConfigurationLineItems,
    targetFilterConfiguration: targetFilterConfiguration.lift(),
    featureFlags,
  };
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import {
  MemoizedWorklistLineItem,
  WorklistLineItemData,
} from '@webOrganisms/WorklistLineItem/WorklistLineItem';
import { FilterOptions } from '@interfaces/FilterOptions';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { t, tPrint } from '@webInterfaces/I18n';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Workspace } from '@interfaces/Workspace';
import { getFeatureFlags } from '@entities/FeatureFlag';
import { StatusFilter } from './StatusFilter';
import styles from './worklist.scss';
import { Unzip } from './Unzip';

export interface WorklistProps {
  activeFilters?: FilterOptions;
  loading: boolean;
  isBookmarkEnabled: boolean;
  worklistLineItems: WorklistLineItemData[];
  totalAvailableItems: number;
  onLoadNext: () => void;
  onSetAsNew: (analysisId: string, sessionId: string) => void;
  onUpload: (fileList: File[]) => void;
  onOpenStudy: (analysisId: string, sessionId: string) => void;
  downloadPdf: (url: string, fileName: string) => void;
  failedToLoadWorklist: boolean;
  setBookmarked: (sessionId: string, isBookmarked: boolean) => void;
  customWorkspaces: Workspace[];
  isCustomWorkspacesEnabled: boolean;
  updateSessionAssignedWorkspaces: (
    sessionId: string,
    workspaces: string[]
  ) => void;
}

export const Worklist: React.FC<WorklistProps> = ({
  activeFilters,
  loading,
  isBookmarkEnabled,
  worklistLineItems,
  totalAvailableItems,
  onSetAsNew,
  onUpload,
  onOpenStudy,
  onLoadNext,
  downloadPdf,
  failedToLoadWorklist,
  setBookmarked,
  customWorkspaces,
  isCustomWorkspacesEnabled,
  updateSessionAssignedWorkspaces,
}) => {
  const featureFlags = getFeatureFlags();
  const [animated, setAnimated] = React.useState<string[]>([]);
  const [curItems, setCurItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    const newItemCount = worklistLineItems.length;
    const ids = worklistLineItems.map(a => a.sessionId);
    const curItemCount = curItems.length;
    if (curItemCount == 0) {
      setAnimated([]);
    }
    if (curItemCount > 0 && newItemCount > curItemCount) {
      setAnimated(ids.filter(a => !curItems.includes(a)));
    }
    setCurItems(ids);
  }, [worklistLineItems]);

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const isEmpty = worklistLineItems.length === 0 && loading === false;

  const onInputFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].type === 'application/zip') {
        const unzippedFiles = await Unzip(event);
        onUpload(unzippedFiles);
      } else {
        onUpload(Array.from(event.target.files));
      }
    }
  };

  const [claimStudy, setClaimStudy] = React.useState<
    { analysisId: string; sessionId: string; accessionNumber: string } | false
  >(false);
  const onOpenUnclaimed = (
    analysisId: string,
    sessionId: string,
    accessionNumber: string
  ) => {
    setClaimStudy({ analysisId, sessionId, accessionNumber });
  };

  const columns = [
    {
      label: t('pages.study.worksheet.heading.name'),
      width: 'minmax(200px, 1.5fr)',
    },
    {
      label: t('pages.study.worksheet.heading.date'),
      width: 'minmax(100px, 1fr)',
    },
    {
      label: t('pages.study.worksheet.heading.accession'),
      width: 'minmax(100px, 1fr)',
    },
    {
      label: t('pages.study.worksheet.heading.type'),
      width: 'minmax(80px, 1.5fr)',
    },
    {
      label: t('pages.study.worksheet.heading.operator'),
      width: 'minmax(30px, 1fr)',
    },
    { label: '' },
  ];

  return (
    <>
      {claimStudy !== false ? (
        <DialogConfirm
          isOpen={true}
          intent="primary"
          labelOk={t('pages.worklist.dialog.claim_study.ok')}
          labelCancel={t('pages.worklist.dialog.claim_study.cancel')}
          onConfirm={() => {
            onOpenStudy(claimStudy.analysisId, claimStudy.sessionId);
            setClaimStudy(false);
          }}
          onCancel={() => {
            setClaimStudy(false);
          }}
        >
          <Text display="block" type="display2" marginBottom="m">
            {t('pages.worklist.dialog.claim_study.title')}
          </Text>
          <Text display="block" marginBottom="s">
            {tPrint(
              'pages.worklist.dialog.claim_study.blurb_1',
              claimStudy.accessionNumber
            )}
          </Text>
          <Text display="block">
            {t('pages.worklist.dialog.claim_study.blurb_2')}
          </Text>
        </DialogConfirm>
      ) : null}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="l"
        data-testid="worklist-page"
      >
        <Box display="flex" alignItems="center">
          {activeFilters && (
            <StatusFilter
              isBookmarkEnabled={isBookmarkEnabled}
              activeFilters={activeFilters}
            />
          )}
        </Box>
        {featureFlags.manualUpload && (
          <Box>
            <input
              ref={fileInputRef}
              type="file"
              multiple
              onChange={event => onInputFile(event)}
              style={{ display: 'none' }}
              data-testid="upload-files"
            />
            <Button
              iconAfter={<Icon name="plus" />}
              circle
              onClick={() => {
                fileInputRef.current?.click();
              }}
            />
          </Box>
        )}
      </Box>
      <Schedule
        columns={columns}
        hasContextMenu={true}
        onInfiniteScroll={
          !loading && totalAvailableItems > worklistLineItems.length
            ? onLoadNext
            : undefined
        }
        items={
          !isEmpty
            ? worklistLineItems
                .filter(item => activeFilters?.status.includes(item.status))
                .map(item => (
                  <MemoizedWorklistLineItem
                    key={item.sessionId}
                    {...item}
                    animated={
                      !!animated.includes(item.sessionId) && item.animated
                    }
                    onSetAsNew={onSetAsNew}
                    onOpenUnclaimed={onOpenUnclaimed}
                    downloadPdf={downloadPdf}
                    setBookmarked={setBookmarked}
                    isBookmarkEnabled={isBookmarkEnabled}
                    isCustomWorkspacesEnabled={isCustomWorkspacesEnabled}
                    customWorkspaces={customWorkspaces}
                    updateSessionAssignedWorkspaces={
                      updateSessionAssignedWorkspaces
                    }
                  />
                ))
            : []
        }
      />
      {isEmpty && <WorklistEmpty />}
      {failedToLoadWorklist && <WorklistError />}
      {loading && (
        <Box marginTop="l" display="flex" justifyContent="center">
          <LoadingIcon />
        </Box>
      )}
    </>
  );
};

const WorklistEmpty = () => (
  <Box marginTop="xxxl" display="flex" justifyContent="center">
    <Text type="body2" muted display="block">
      {t(`pages.worklist.no_worklist_item_message`)}
    </Text>
  </Box>
);

const WorklistError = () => (
  <Box
    marginTop="xxxl"
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Box className={styles.iconContainer}>
      <Icon fluid name="alert-triangle" color="var(--ds-text-color-muted)" />
    </Box>
    <Text muted display="block" marginTop="m">
      {t(`pages.worklist.error.message`)}
    </Text>
    <Box marginTop="xl" display="flex">
      <Button onClick={() => window.location.reload()} strong size="small">
        {t(`pages.worklist.reload`)}
      </Button>
      <Box marginRight="s" />
    </Box>
  </Box>
);

export default Worklist;

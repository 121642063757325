import React from 'react';
import { cnames } from '@helpers/cnames';
import { Input } from '@webMolecules/Input/Input';
import { Divider } from '@webMolecules/Divider/Divider';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import styles from './checkbox.scss';

export interface CheckboxProps {
  key?: string;
  children?: React.ReactNode;
  isAffected?: boolean;
  disabled?: boolean;
  field?: string;
  form?: string;
  name?: string;
  readOnly?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  separator?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  isAffected,
  disabled,
  name = '',
  readOnly,
  checked,
  defaultChecked,
  onChange = () => null,
  ...rest
}) => {
  const classNames = cnames(styles.checkboxOuter, {
    [styles.disabled]: disabled || isAffected,
    [styles.readOnly]: readOnly,
  });

  return (
    <Box
      data-testid="outerContainer"
      element="label"
      display="inline-flex"
      className={classNames}
    >
      <Box data-testid="checkInputOuter" className={styles.checkInputOuter}>
        <input
          {...rest}
          data-testid="checkInput"
          defaultChecked={defaultChecked}
          checked={checked}
          className={styles.checkInput}
          disabled={disabled || isAffected}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <Box
          data-testid="fakeCheckInput"
          className={styles.fakeCheckInput}
          marginRight="s"
        />
        <Box
          data-testid="checkmarkIconOuter"
          className={styles.checkmarkIconOuter}
        >
          <Box data-testid="checkmarkIcon" className={styles.checkmarkIcon} />
        </Box>
      </Box>
      <Text data-testid="checkmarkText" type="body2" loading={isAffected}>
        {children}
      </Text>
    </Box>
  );
};

export interface CheckboxGroupProps {
  entries: CheckboxProps[];
  containerStyles?: React.CSSProperties;
}

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { entries, containerStyles } = props;
  return (
    <>
      {entries.map(({ key, separator, ...entry }) => (
        <Box key={key} marginRight="xs" style={containerStyles}>
          <Checkbox {...entry} />
          {separator && <Divider marginTop="xxs" marginBottom="xs" />}
        </Box>
      ))}
    </>
  );
};

export interface CheckboxWithCommentProps extends CheckboxProps {
  commentValue: string;
  commentConfig?: {
    key: string;
    suffix: string;
    inputWidth: string;
  };
  onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface CheckboxGroupWithCommentProps {
  entries: CheckboxWithCommentProps[];
  containerStyles?: React.CSSProperties;
}

export const CheckboxWithCommentGroup = (
  props: CheckboxGroupWithCommentProps
) => {
  const { entries, containerStyles } = props;

  return (
    <>
      {entries.map(entry => {
        const {
          commentValue = undefined,
          commentConfig = undefined,
          onCommentChange = () => null,
          ...checkBoxEntry
        } = entry;

        return (
          <Box key={entry.key} marginRight="xs" style={containerStyles}>
            <Checkbox {...checkBoxEntry} />
            {commentConfig && (
              <Box display="inline-flex" marginLeft="xs">
                <Input
                  width={commentConfig?.inputWidth}
                  fluid
                  type="text"
                  size="small"
                  defaultValue={commentValue}
                  disabled={!entry.checked}
                  iconAfter={
                    <Text type="body2" marginRight="xs">
                      {commentConfig?.suffix}
                    </Text>
                  }
                  onChange={onCommentChange}
                  onBlur={onCommentChange}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

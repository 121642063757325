import { set } from 'lodash/fp';
import { produce } from 'immer';
import { FindingAPI } from '@api/Finding';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import { createUseCase } from '@helpers/createUseCase';
import { Session, initial } from '@entities/Session';
import { Finding, FindingType } from '@entities/Finding';
import Effect, { effect, none } from '@library/Effect';
import {
  computeFindingClinicalAction,
  computeFindingVolume,
} from '@webViews/Pages/Study/helper';
import {
  ThyroidFindingUpdatedEventData,
  UpdatedObjectEventData,
} from '@appCanvas/interfaces/types';
import { computeFindingScorePoints } from '@handlers/TiRadsHandler';
import { FindingFilterType } from '@config/ResultTable/FindingTable';
import { BreastFindingUpdatedEventData } from '@appCanvas/interfaces/types/UpdatedObjectEventData.type';
import {
  BodySide,
  BreastFindingPosition,
  ThyroidFindingComposition,
  ThyroidPole,
} from '@entities/Characteristics';
import { FindingSortType } from '@entities/InstitutionConfiguration';
import { findingInteractionUseCase } from './FindingInteraction';

export const findingUseCase = {
  ReceiveUpdatedFindings: createUseCase('RECEIVE_FINDINGS').withPayload<{
    findings: Finding[];
    impressions?: string;
  }>(),

  UpdateFinding: createUseCase('UPDATE_FINDING').withPayload<Finding>(),

  UpdateFindingCharacteristic: createUseCase(
    'UPDATE_FINDING_CHARACTERISTIC'
  ).withPayload<{
    findingId: string;
    path: string;
    value: string | string[];
  }>(),

  UpdateFindingFromCanvas: createUseCase(
    'UPDATE_FINDING_FROM_CANVAS'
  ).withPayload<{
    findingId: string;
    updatedEventData: UpdatedObjectEventData;
  }>(),

  ReorderFindings: createUseCase('REORDER_FINDINGS').withPayload<{
    includedIds: string[];
    excludedIds: string[];
  }>(),

  SortFindings: createUseCase('SORT_FINDINGS').withPayload<{
    sortType: FindingSortType;
  }>(),

  AssignDetection: createUseCase('ASSIGN_DETECTION').withPayload<{
    findingId: string;
    detectionId: string;
  }>(),

  UnassignDetection: createUseCase('UNASSIGN_DETECTION').withPayload<{
    findingId: string;
    detectionId: string;
  }>(),

  CreateFindingFromDetection: createUseCase(
    'CREATE_FINDING_FROM_DETECTION'
  ).withPayload<{
    detectionId: string;
  }>(),

  CreateFinding: createUseCase('CREATE_FINDING').withPayload<Finding>(),

  DeleteFinding: createUseCase('DELETE_FINDING').withPayload<{
    findingId: string;
  }>(),

  FilterFindings: createUseCase('FILTER_FINDINGS').withPayload<{
    filterType: FindingFilterType;
    selectedIds?: string[];
  }>(),

  AssignComparisonFinding: createUseCase(
    'ASSIGN_COMPARISON_FINDING'
  ).withPayload<{ findingId: string; comparisonFindingId: string }>(),

  UnassignComparisonFinding: createUseCase(
    'UNASSIGN_COMPARISON_FINDING'
  ).withPayload<{ comparisonFindingId: string }>(),

  NoOp: createUseCase('NO_OP').noPayload(),
};

export type FindingUseCases = ReturnType<
  | typeof findingUseCase.ReceiveUpdatedFindings
  | typeof findingUseCase.UpdateFinding
  | typeof findingUseCase.UpdateFindingCharacteristic
  | typeof findingUseCase.UpdateFindingFromCanvas
  | typeof findingUseCase.ReorderFindings
  | typeof findingUseCase.SortFindings
  | typeof findingUseCase.AssignDetection
  | typeof findingUseCase.UnassignDetection
  | typeof findingUseCase.CreateFindingFromDetection
  | typeof findingUseCase.CreateFinding
  | typeof findingUseCase.DeleteFinding
  | typeof findingUseCase.FilterFindings
  | typeof findingUseCase.AssignComparisonFinding
  | typeof findingUseCase.UnassignComparisonFinding
  | typeof findingUseCase.NoOp
>;

export class FindingReducer extends EffectReducer<Session> {
  constructor() {
    super();
  }

  Perform(
    session: Session = initial(),
    { type, payload: useCase }: FindingUseCases
  ): ReducerResult<Session> {
    switch (type) {
      case findingUseCase.ReceiveUpdatedFindings.type: {
        return this.Result(
          {
            ...session,
            data: {
              ...session.data,
              findings: useCase.findings,
              impressions: useCase.impressions,
            },
          },
          none(),
          [
            findingInteractionUseCase.SyncToFindings({
              findings: useCase.findings,
            }),
          ]
        );
      }

      case findingUseCase.UpdateFinding.type: {
        const originalFinding = session.data.findings.find(
          f => f.id === useCase.id
        );
        const updatedFinding = updateAffectedProperties(
          useCase,
          originalFinding ?? useCase,
          session.isSize3D
        );
        const updatedFindings = session.data.findings.map(f =>
          f.id === updatedFinding.id ? updatedFinding : f
        );

        return this.Result(
          {
            ...session,
            data: {
              ...session.data,
              findings: reindexFindings(updatedFindings),
            },
          },
          effectUpdateFinding(updatedFinding)
        );
      }

      case findingUseCase.AssignDetection.type: {
        return this.Result(
          {
            ...session,
          },
          effectAssignDetection(useCase.findingId, useCase.detectionId)
        );
      }

      case findingUseCase.UnassignDetection.type: {
        return this.Result(
          {
            ...session,
          },
          effectUnassignDetection(useCase.findingId, useCase.detectionId)
        );
      }

      case findingUseCase.CreateFinding.type: {
        const sessionId = session.details.mapOr(a => a.id, undefined);
        return this.Result(
          {
            ...session,
          },
          sessionId ? effectCreateFinding(sessionId, useCase) : none()
        );
      }

      case findingUseCase.DeleteFinding.type: {
        const remainingFindings = session.data.findings.filter(
          f => f.id !== useCase.findingId
        );
        return this.Result(
          {
            ...session,
            data: {
              ...session.data,
              findings: remainingFindings,
            },
          },
          effectDeleteFinding(useCase.findingId),
          [findingInteractionUseCase.Reset()]
        );
      }

      case findingUseCase.CreateFindingFromDetection.type: {
        const sessionId = session.details.mapOr(a => a.id, undefined);
        return this.Result(
          {
            ...session,
          },
          sessionId
            ? effectCreateFindingFromDetection(sessionId, useCase.detectionId)
            : none()
        );
      }

      case findingUseCase.FilterFindings.type: {
        const sessionId = session.details.mapOr(a => a.id, undefined);
        return this.Result(
          {
            ...session,
          },
          sessionId
            ? effectFilterFindings(
                sessionId,
                useCase.filterType,
                useCase.selectedIds
              )
            : none()
        );
      }

      case findingUseCase.UpdateFindingCharacteristic.type: {
        const { findingId, path, value } = useCase;
        const findingToUpdate = session.data.findings.find(
          f => f.id === findingId
        );
        if (!findingToUpdate) {
          return this.Result(session);
        }
        const updatedFinding = set(path, value, findingToUpdate);
        return this.Result(session, none(), [
          findingUseCase.UpdateFinding(updatedFinding),
        ]);
      }

      case findingUseCase.UpdateFindingFromCanvas.type: {
        const { findingId, updatedEventData } = useCase;
        const findingToUpdate = session.data.findings.find(
          f => f.id === findingId
        );
        if (!findingToUpdate) {
          return this.Result(session);
        }

        const updatedFinding = updateFindingCanvasRelatedProperties(
          findingToUpdate,
          updatedEventData
        );

        return this.Result(session, none(), [
          findingUseCase.UpdateFinding(updatedFinding),
        ]);
      }

      case findingUseCase.ReorderFindings.type: {
        const updatedFindings = produce(session.data.findings, findings => {
          // Update index and included flag
          let currentIndex = 1;
          const handleIds = (ids: string[], included: boolean) => {
            for (const id of ids) {
              const finding = findings.find(f => f.id === id);
              if (finding) {
                finding.index = currentIndex++;
                finding.included = included;
              }
            }
          };
          handleIds(useCase.includedIds, true);
          handleIds(useCase.excludedIds, false);
        });

        return this.Result(
          {
            ...session,
            data: {
              ...session.data,
              findings: updatedFindings,
            },
          },
          effectReorderFindings(updatedFindings)
        );
      }

      case findingUseCase.SortFindings.type: {
        const sessionId = session.details.mapOr(a => a.id, undefined);
        return this.Result(
          {
            ...session,
          },
          sessionId ? effectSortFindings(sessionId, useCase.sortType) : none()
        );
      }

      case findingUseCase.AssignComparisonFinding.type: {
        const { findingId, comparisonFindingId } = useCase;

        // Optimistically update comparison finding id
        const updatedSession = produce(session, draftSession => {
          const finding = draftSession.data.findings.find(
            f => f.id === findingId
          );
          const previousFinding = draftSession.data.findings.find(
            f => f.comparisonFindingId === comparisonFindingId
          );
          if (previousFinding) {
            previousFinding.comparisonFindingId = undefined;
          }
          if (finding) {
            finding.comparisonFindingId = comparisonFindingId;
          }
        });
        return this.Result(
          updatedSession,
          effectUpdateComparisonFinding(findingId, comparisonFindingId)
        );
      }

      case findingUseCase.UnassignComparisonFinding.type: {
        const { comparisonFindingId } = useCase;

        const finding = session.data.findings.find(
          f => f.comparisonFindingId === comparisonFindingId
        );

        const updatedSession = produce(session, draftSession => {
          const updatedFinding = draftSession.data.findings.find(
            f => f.comparisonFindingId === comparisonFindingId
          );
          if (updatedFinding) {
            // Optimistically unset comparison finding id
            updatedFinding.comparisonFindingId = undefined;
          }
        });

        return this.Result(
          updatedSession,
          finding ? effectUpdateComparisonFinding(finding.id, null) : none()
        );
      }

      case findingUseCase.NoOp.type: {
        return this.Result(session);
      }
    }
  }
}

const effectUpdateFinding = (finding: Finding): Effect =>
  effect(async () => {
    const result = await FindingAPI.updateFinding(finding);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-update-finding');

const effectAssignDetection = (
  findingId: string,
  detectionId: string
): Effect =>
  effect(async () => {
    const result = await FindingAPI.assignDetection(findingId, detectionId);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-assign-detection');

const effectUnassignDetection = (
  findingId: string,
  detectionId: string
): Effect =>
  effect(async () => {
    const result = await FindingAPI.unassignDetection(findingId, detectionId);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-unassign-detection');

const effectDeleteFinding = (findingId: string): Effect =>
  effect(async () => {
    const result = await FindingAPI.deleteFinding(findingId);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-delete-finding');

const effectCreateFinding = (sessionId: string, finding: Finding): Effect =>
  effect(async () => {
    const result = await FindingAPI.createFinding(sessionId, finding);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-create-finding');

const effectCreateFindingFromDetection = (
  sessionId: string,
  detectionId: string
): Effect =>
  effect(async () => {
    const result = await FindingAPI.createFindingFromDetection(
      sessionId,
      detectionId
    );

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-create-finding-from-detection');

const effectReorderFindings = (findings: Finding[]): Effect =>
  effect(async () => {
    const result = await FindingAPI.reorderFindings(findings);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-reorder-findings');

const effectSortFindings = (
  sessionId: string,
  sortType: FindingSortType
): Effect =>
  effect(async () => {
    const result = await FindingAPI.sortFindings(sessionId, sortType);

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-sort-findings');

const effectFilterFindings = (
  sessionId: string,
  filterType: FindingFilterType,
  selectedIds?: string[]
): Effect =>
  effect(async () => {
    const result = await FindingAPI.filterFindings(
      sessionId,
      filterType,
      selectedIds
    );

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-filter-findings');

const effectUpdateComparisonFinding = (
  findingId: string,
  comparisonFindingId: string | null
): Effect =>
  effect(async () => {
    const result = await FindingAPI.updateComparisonFinding(
      findingId,
      comparisonFindingId
    );

    return findingUseCase.ReceiveUpdatedFindings(result);
  }, 'effect-update-comparison-finding');

/**
 * These finding properties are calculated as an optimistic update to the store.
 * The API repeats these calculations on the backend.
 * - Volume
 * - Score points
 * - Clinical action
 * - Unsetting other thyroid characteristics if Spongiform
 * - Unsetting other breast characteristics if a special case is selected
 * - Canvas position based on side/pole
 */
function updateAffectedProperties(
  updatedFinding: Finding,
  originalFinding: Finding,
  isSize3D: boolean
): Finding {
  return produce(updatedFinding, finding => {
    if (isSize3D) {
      // Calculate volume
      finding.sizes.volume = computeFindingVolume(finding.sizes) ?? null;
    }

    if (
      finding.type === FindingType.Thyroid &&
      originalFinding.type === FindingType.Thyroid
    ) {
      // Score points
      finding.characteristics.scorePoints = computeFindingScorePoints(finding);

      // Clinical action
      finding.characteristics.clinicalAction =
        computeFindingClinicalAction(finding, isSize3D) ?? null;

      // Unset if Spongiform
      if (
        finding.characteristics.composition ===
        ThyroidFindingComposition.Spongiform
      ) {
        finding.characteristics.echogenicFoci = null;
        finding.characteristics.echogenicity = null;
        finding.characteristics.margin = null;
        finding.characteristics.shape = null;
      }

      // Canvas position
      if (
        finding.characteristics.side !== originalFinding.characteristics.side ||
        finding.characteristics.pole !== originalFinding.characteristics.pole
      ) {
        // only reset the finding canvas position when the side/pole are changing from the edit table
        // moving the finding in canvas across the side/pole should keep the updated canvas position
        if (
          finding.canvasPosition &&
          finding.canvasPosition.xCoordinate ==
            originalFinding.canvasPosition?.xCoordinate &&
          finding.canvasPosition.yCoordinate ==
            originalFinding.canvasPosition?.yCoordinate
        ) {
          finding.canvasPosition.xCoordinate = null;
          finding.canvasPosition.yCoordinate = null;
        }
      }
    }

    if (finding.type === FindingType.Breast) {
      // Unset if any special case selected
      if (finding.characteristics.specialCase) {
        finding.characteristics.posteriorFeatures = null;
        finding.characteristics.shape = null;
        finding.characteristics.orientation = null;
        finding.characteristics.margin = null;
        finding.characteristics.echoPattern = null;
        finding.characteristics.calcifications = null;
        finding.characteristics.vascularity = null;
        finding.characteristics.elasticity = null;
        finding.characteristics.skinChanges = null;
        finding.characteristics.architecture = null;
      }
    }
  });
}

export function reindexFindings(findings: Finding[]): Finding[] {
  const sortedFindings = [...findings].sort((a, b) => {
    if (a.included !== b.included) {
      return a.included ? -1 : 1;
    }

    return a.index - b.index;
  });
  return produce(sortedFindings, updatedFindings => {
    let currentIndex = 1;
    updatedFindings.forEach(finding => (finding.index = currentIndex++));
  });
}

export const updateFindingCanvasRelatedProperties = (
  originalFinding: Finding,
  updatedEventData: UpdatedObjectEventData
) => {
  if (originalFinding.type == FindingType.Thyroid) {
    const data = updatedEventData as ThyroidFindingUpdatedEventData;

    return produce(originalFinding, finding => {
      finding.characteristics.side = data.side as BodySide;
      finding.characteristics.pole = data.pole as ThyroidPole;
      finding.canvasPosition = {
        ...finding.canvasPosition,
        ...data.canvasPosition,
      };
    });
  } else if (originalFinding.type == FindingType.Breast) {
    const data = updatedEventData as BreastFindingUpdatedEventData;

    return produce(originalFinding, finding => {
      finding.characteristics.side = data.side as BodySide;
      finding.characteristics.position = data.position as BreastFindingPosition;
      finding.characteristics.distanceFromNipple = {
        value: data.distanceFromNipple,
        unit: data.distanceFromNippleUnit,
      };
      finding.characteristics.clockface = {
        hour: data.clockPositionHour.toString(),
        minute: data.clockPositionMin.toString(),
      };

      finding.canvasPosition = {
        ...finding.canvasPosition,
        ...data.canvasPosition,
      };
    });
  }
  return originalFinding;
};

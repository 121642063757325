import React from 'react';
import debounce from 'lodash/debounce';
import { useController } from 'react-hook-form';
import { Input } from '@webMolecules/Input/Input';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { DEBOUNCE_TIME } from '@webViews/constant';
import { ResultTableSharedProps } from '../ResultTableSharedProps';

export interface ResultTableCellStringProps extends ResultTableSharedProps {
  disabled?: boolean;
  readOnly?: boolean;
}

export const ResultTableCellString: React.FC<ResultTableCellStringProps> = ({
  path,
  disabled,
  readOnly,
}) => {
  const { theme } = React.useContext(ResultTableContext);
  const { field } = useController({
    name: path ?? '',
  });

  const changeHandler = (value: string) => {
    // Only trigger a change if there is a change.
    // We need onBlur for deletes, but it does mean this handler runs more frequently
    if (
      (typeof field.value === 'undefined' && value === '') ||
      value === field.value
    ) {
      return;
    }
    field.onChange(value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange.cancel();
    changeHandler(e.target.value);
  };

  const onChange = debounce(onBlur, DEBOUNCE_TIME);

  if (theme == ResultTableStyle.preview) {
    return Boolean(field.value) ? (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Text type="body2">{field.value}</Text>
      </Box>
    ) : null;
  }

  return (
    <Box
      data-testid="test-result-table-string-cell-parent"
      flexDirection="row"
      display="flex"
    >
      <Input
        data-testid="test-result-table-string-cell"
        disabled={disabled}
        fluid
        type="text"
        size="small"
        defaultValue={field.value}
        onChange={onChange}
        onBlur={onChange}
        readOnly={readOnly}
      />
    </Box>
  );
};

export enum ThyroidFindingComposition {
  CysticOrAlmostCompletelyCystic = 'cystic_or_almost_completely_cystic',
  Spongiform = 'spongiform',
  MixedCysticAndSolid = 'mixed_cystic_and_solid',
  SolidOrAlmostCompletelySolid = 'solid_or_almost_completely_solid',
  Indeterminate = 'indeterminate',
}

export enum ThyroidFindingEchogenicity {
  Anechoic = 'anechoic',
  HyperechoicOrIsoechoic = 'hyperechoic_or_isoechoic',
  Hypoechoic = 'hypoechoic',
  VeryHypoechoic = 'very_hypoechoic',
  Indeterminate = 'indeterminate',
}

export enum ThyroidFindingShape {
  WiderThanTall = 'wider_than_tall',
  TallerThanWide = 'taller_than_wide',
}

export enum ThyroidFindingMargin {
  Smooth = 'smooth',
  IllDefined = 'ill_defined_or_smooth',
  LobulatedOrIrregular = 'lobulated_or_irregular',
  ExtraThyroidalExtension = 'extra_thyroidal_extension',
  Indeterminate = 'indeterminate',
}

export enum ThyroidFindingEchogenicFoci {
  NoneOrLargeCometTailArtefacts = 'none_or_large_comet_tail_artefacts',
  Macrocalcifications = 'macrocalcification',
  Peripheral = 'peripheral_rim_calcification',
  PunctateEchogenicFoci = 'punctate_echogenic_foci',
}

export enum BreastFindingPosition {
  Breast = 'breast',
  Nipple = 'nipple',
  Axilla = 'axilla',
}

export enum BreastFindingType {
  Cyst = 'cyst',
  Mixed = 'mixed',
  Lesion = 'lesion',
}

export enum BreastFindingShape {
  Oval = 'oval',
  Round = 'round',
  Irregular = 'irregular',
}

export enum Malignancy {
  Malignant = 'malignant',
  Benign = 'benign',
}

export enum BreastFindingOrientation {
  Parallel = 'parallel',
  NotParallel = 'not_parallel',
}

export enum BreastFindingMargin {
  Circumscribed = 'circumscribed',
  Indistinct = 'indistinct',
  Angular = 'angular',
  Microlobulated = 'microlobulated',
  Spiculated = 'spiculated',
}

export enum BreastFindingEchoPattern {
  Anechoic = 'anechoic',
  Hyperechoic = 'hyperechoic',
  ComplexCysticAndSolid = 'complex_cystic_and_solid',
  Hypoechoic = 'hypoechoic',
  Isoechoic = 'isoechoic',
  Heterogeneous = 'heterogeneous',
}

export enum BreastFindingPosteriorFeature {
  NoPosteriorFeatures = 'no_posterior_features',
  Enhancement = 'enhancement',
  Shadowing = 'shadowing',
  CombinedPattern = 'combined_pattern',
}

export enum BreastFindingCalcification {
  InMass = 'in_mass',
  OutsideMass = 'outside_mass',
  Intraductal = 'intraductal',
}

export enum BreastFindingVascularity {
  VascularityAbsent = 'vascularity_absent',
  InternalVascularity = 'internal_vascularity',
  VesselsInRim = 'vessels_in_rim',
}
export enum BreastFindingSkinChanges {
  SkinThickening = 'skin_thickening',
  SkinRetraction = 'skin_retraction',
}

export enum BreastFindingArchitecture {
  ArchitecturalDistortion = 'architectural_distortion',
  DuctChanges = 'duct_changes',
  Edema = 'edema',
}

export enum BreastFindingElasticity {
  Soft = 'soft',
  Intermediate = 'intermediate',
  Hard = 'hard',
}

export enum BreastFindingSpecialCase {
  SimpleCyst = 'simple_cyst',
  ClusteredMicrocysts = 'clustered_microcysts',
  ComplicatedCyst = 'complicated_cyst',
  MassInOrOnSkin = 'mass_in_or_on_skin',
  ForeignBody = 'foreign_body',
  IntramammaryLymphNode = 'intramammary_lymph_node',
  AxillaryLymphNode = 'axillary_lymph_node',
  VascularAbnormalities = 'vascular_abnormalities',
  PostsurgicalFluid = 'postsurgical_fluid',
  FatNecrosis = 'fat_necrosis',
}

export enum ThyroidVascularityValue {
  Normal = 'normal',
  Increased = 'increased',
}

export enum EchotextureValue {
  Homogeneous = 'homogeneous',
  Heterogeneous = 'heterogeneous',
}

export enum YesNoValue {
  Yes = 'yes',
  No = 'no',
}

export enum YesNoNIValue {
  Yes = 'yes',
  No = 'no',
  NI = 'not_imaged',
}

export enum SizeValue {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum NormalAbnormalValue {
  Normal = 'normal',
  Abnormal = 'abnormal',
}

export enum NormalIncreasedValue {
  Normal = 'normal',
  Increased = 'increased',
}

export enum BodySide {
  Left = 'left',
  Right = 'right',
  Median = 'median',
  Unknown = 'unknown',
}

export enum ThyroidPole {
  Upper = 'upper',
  UpperMid = 'upper_mid',
  Mid = 'mid',
  LowerMid = 'lower_mid',
  Lower = 'lower',
}

export enum ClinicalAction {
  FNA = 'FNA',
  FollowUp = 'follow-up',
  NoFurtherFollowUp = 'no further follow-up',
}

type DefaultType<T, D> = D extends undefined
  ? T | undefined
  : D extends null
    ? T | null
    : T;

/** A generic function to decode any enum without repetition of the mapping */
export const stringToEnumValue = <
  T,
  D extends T | null | undefined = undefined,
>(
  value: string,
  enumType: { [key: string]: T },
  defaultValue?: D
): DefaultType<T, D> => {
  const enumValues = Object.values(enumType);

  return (
    enumValues.includes(value as T) ? (value as T) : defaultValue
  ) as DefaultType<T, D>;
};

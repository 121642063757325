import React from 'react';
import { cnames } from '@helpers/cnames';
import { Box } from '@webMolecules/Box/Box';
import styles from './status.scss';

export interface StatusProps {
  intent?: 'neutral' | 'success' | 'error' | 'information' | 'warning';
  pulse?: boolean;
}

export const Status: React.FC<StatusProps> = ({
  intent = 'neutral',
  pulse = false,
}) => {
  const classNames = cnames(styles.statusIndicator, styles[intent]);
  return (
    <Box className={classNames}>
      {pulse && (
        <>
          <Box className={styles.pulseOne} />
          <Box className={styles.pulseTwo} />
        </>
      )}
    </Box>
  );
};

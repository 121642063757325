/* eslint-disable no-console */
import React from 'react';
import { Dicom } from '@interfaces/Dicom';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Detection } from '@entities/Detection';

interface DebugOverlayProps {
  dicom: Dicom;
  debugDicom: boolean;
}

export const DebugOverlay = ({ dicom, debugDicom }: DebugOverlayProps) => {
  if (!debugDicom) {
    return null;
  }

  const graylogTimeframe = 96 * 60 * 60;
  const graylogUrl = `https://graylog.see-mode.xyz/search?q=dicom_id%3A%22${dicom.id}%22&rangetype=relative&relative=${graylogTimeframe}`;

  const detections = dicom.detections;

  const formatDetectionValue = (detection: Detection) => {
    const data = JSON.stringify(detection);

    return <Box>{data}</Box>;
  };
  return (
    <Box
      style={{
        fontSize: '12px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.5)',
        overflow: 'auto',
      }}
    >
      {`ID: ${dicom.id}`} <br />
      {`SOP INSTANCE UID: ${dicom.sopInstanceUid}`} <br />
      {`Scan time: ${dicom.scanTime}`} <br />
      {`Image type: ${dicom.imageType}`} <br />
      {`Annotations: ${JSON.stringify(dicom.annotations)}`} <br />
      <Button href={graylogUrl} target="blank" strong>
        Search Graylog
      </Button>
      <Button
        onClick={() => console.log(detections)}
        strong
        disabled={detections.length === 0}
      >
        Detections
      </Button>
      {detections.length > 0 && (
        <Box
          style={{
            fontSize: '12px',
            overflow: 'auto',
          }}
        >
          {detections.map(detection => (
            <Box key={`dicom-detection-${detection.id}`}>
              Detection {detection.id}
              <br />
              {formatDetectionValue(detection)}
              <hr />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

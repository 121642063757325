import { Action } from 'redux';
import { rootUseCase } from '@useCases/Root';
import { App } from '@entities/App';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import { none } from '@library/Effect';
import { Authenticate as ContractAuthenticate } from '@contracts/Authenticate';
import { Institutions as ContractInstitutions } from '@contracts/Admin/Institutions';
import { DicomAnonymiser as ContractDicomAnonymiser } from '@contracts/DicomAnonymiser';
import { Analyses as ContractAnalyses } from '@contracts/Analyses';
import { Encryption as ContractEncryption } from '@contracts/Encryption';
import { FilterConfiguration as ContractFilterConfiguration } from '@contracts/Admin/FilterConfiguration';
import { Location as ContractLocation } from '@contracts/Location';
import { PDF as ContractPDF } from '@contracts/PDF';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { Search as ContractSearch } from '@contracts/Search';
import { Session as ContractSession } from '@contracts/Session';
import { Storage as ContractStorage } from '@contracts/Storage';
import { Workspaces as ContractWorkspaces } from '@contracts/Workspaces';
import { PACSStudy as ContractPACSStudy } from '@contracts/PACSStudy';
import { AppConfiguration as ContractAppConfiguration } from '@contracts/AppConfiguration';
import { FeatureFlags as ContractFeatureFlags } from '@contracts/FeatureFlags';
import { Institution as ContractInstitution } from '@contracts/Admin/Institution';
import { Message } from '@entities/Message';
import { Profile } from '@entities/Profile';
import { createUseCase } from '@helpers/createUseCase';
import { PACSStudyUseCase, PACSStudyReducer } from './PACSStudy';
import { AppSettingsReducer, appSettingsUseCase } from './AppSettings';
import { ProfileReducer } from './Profile';
import { AdminReducer } from './Admin';
import { NotificationsReducer } from './Notifications';
import {
  AnalysisUploadsReducer,
  analysisUploadsUseCase,
} from './AnalysisUploads';
import { SearchReducer } from './Search';
import { SessionReducer } from './Session';
import { worklistUseCase, WorklistReducer } from './Worklist';

export const appUseCase = {
  /*
   * Init
   */
  Init: createUseCase('APP_INIT').withPayload<{
    dispatch: (action: Action) => void;
  }>(),

  /*
   * Clear
   */
  Clear: createUseCase('APP_CLEAR').noPayload(),

  /*
   * Clear messages
   */
  ClearMessages: createUseCase('APP_CLEAR_MESSAGES').noPayload(),

  /*
   * Redirect to path
   */
  Redirect: createUseCase('APP_REDIRECT').withPayload<{
    pathname: string;
    replace?: boolean;
  }>(),

  /*
   * Load path
   */
  Reload: createUseCase('APP_RELOAD').withPayload<{
    path?: string;
  }>(),

  /*
   * Reload i18n locale
   */
  ReloadLocale: createUseCase('APP_RELOAD_LOCALE').withPayload<{
    locale?: string;
  }>(),

  /**
   * Add a new Message
   */
  AddMessage: createUseCase('APP_ADD_MESSAGE').withPayload<{
    message: Message;
    clearOthers?: boolean;
  }>(),

  DebugDicom: createUseCase('APP_DEBUG_DICOM').noPayload(),

  DebugToggleShowConfidence: createUseCase(
    'APP_DEBUG_TOGGLE_SHOW_CONFIDENCE'
  ).noPayload(),
};

/**
 * All UseCases
 */

export type AppUseCases = ReturnType<
  | typeof appUseCase.Init
  | typeof appUseCase.AddMessage
  | typeof appUseCase.Clear
  | typeof appUseCase.ClearMessages
  | typeof appUseCase.Reload
  | typeof appUseCase.ReloadLocale
  | typeof appUseCase.Redirect
  | typeof appUseCase.DebugDicom
  | typeof appUseCase.DebugToggleShowConfidence
>;

/**
 * Reducer
 */
export class AppReducer extends EffectReducer<App> {
  private profileReducer: ProfileReducer;
  private adminReducer: AdminReducer;
  private analysisUploadsReducer: AnalysisUploadsReducer;
  private notificationsReducer: NotificationsReducer;
  private searchReducer: SearchReducer;
  private sessionReducer: SessionReducer;
  private worklistReducer: WorklistReducer;
  private pacsStudyReducer: PACSStudyReducer;
  private appSettingsReducer: AppSettingsReducer;

  constructor(
    implementations: {
      authenticate: ContractAuthenticate;
      dicomAnonymiser: ContractDicomAnonymiser;
      institutions: ContractInstitutions;
      analyses: ContractAnalyses;
      filterConfiguration: ContractFilterConfiguration;
      location: ContractLocation;
      pdf: ContractPDF;
      profiles: ContractProfiles;
      search: ContractSearch;
      session: ContractSession;
      storage: ContractStorage;
      users: ContractProfiles;
      userEncryption: ContractEncryption;
      workspaces: ContractWorkspaces;
      pacsStudy: ContractPACSStudy;
      appConfiguration: ContractAppConfiguration;
      featureFlags: ContractFeatureFlags;
      institution: ContractInstitution;
    },
    profile: Profile
  ) {
    super();

    this.profileReducer = new ProfileReducer(
      implementations.authenticate,
      implementations.storage
    );

    this.worklistReducer = new WorklistReducer(
      implementations.session,
      implementations.storage,
      implementations.authenticate,
      implementations.profiles,
      implementations.userEncryption,
      implementations.workspaces,
      profile
    );

    this.pacsStudyReducer = new PACSStudyReducer(
      implementations.pacsStudy,
      implementations.authenticate
    );

    this.sessionReducer = new SessionReducer(
      implementations.analyses,
      implementations.session,
      implementations.storage,
      implementations.pdf,
      implementations.profiles,
      implementations.search,
      implementations.userEncryption,
      profile
    );

    this.analysisUploadsReducer = new AnalysisUploadsReducer(
      implementations.dicomAnonymiser,
      implementations.analyses,
      implementations.storage,
      implementations.userEncryption,
      profile
    );

    this.notificationsReducer = new NotificationsReducer();

    this.searchReducer = new SearchReducer(
      implementations.search,
      implementations.session,
      implementations.authenticate,
      implementations.userEncryption,
      implementations.storage,
      profile
    );

    this.adminReducer = new AdminReducer(
      implementations.filterConfiguration,
      implementations.location,
      implementations.institutions,
      implementations.storage,
      implementations.profiles,
      implementations.institution
    );

    this.appSettingsReducer = new AppSettingsReducer(
      implementations.appConfiguration,
      implementations.featureFlags,
      profile
    );

    this.CreateSubReducer<App['profile']>(
      this.profileReducer,
      app => app.profile,
      (app, profile) => ({ ...app, profile })
    );

    this.CreateSubReducer<App['worklist']>(
      this.worklistReducer,
      app => app.worklist,
      (app, worklist) => ({ ...app, worklist })
    );

    this.CreateSubReducer<App['pacs']>(
      this.pacsStudyReducer,
      app => app.pacs,
      (app, pacs) => ({ ...app, pacs })
    );

    this.CreateSubReducer<App['search']>(
      this.searchReducer,
      app => app.search,
      (app, search) => ({ ...app, search })
    );

    this.CreateSubReducer<App['session']>(
      this.sessionReducer,
      app => app.session,
      (app, session) => ({ ...app, session })
    );

    this.CreateSubReducer<App['admin']>(
      this.adminReducer,
      app => app.admin,
      (app, admin) => ({ ...app, admin })
    );

    this.CreateSubReducer<App['analysisUploads']>(
      this.analysisUploadsReducer,
      app => app.analysisUploads,
      (app, analysisUploads) => ({ ...app, analysisUploads })
    );

    this.CreateSubReducer<App['notifications']>(
      this.notificationsReducer,
      app => app.notifications,
      (app, notifications) => ({ ...app, notifications })
    );

    this.CreateSubReducer<App['appSettings']>(
      this.appSettingsReducer,
      app => app.appSettings,
      (app, appSettings) => ({ ...app, appSettings })
    );
  }

  Perform(app: App, action: AppUseCases): ReducerResult<App> {
    const { type, payload: useCase } = action;
    switch (type) {
      case appUseCase.Init.type:
        return this.Result(app, none(), [
          worklistUseCase.Init({
            dispatch: useCase.dispatch,
          }),

          PACSStudyUseCase.Init({
            dispatch: useCase.dispatch,
          }),
          appSettingsUseCase.Init(),
        ]);

      case appUseCase.Clear.type:
        return this.Result(app, none(), [analysisUploadsUseCase.Clear()]);

      case appUseCase.ClearMessages.type:
        return this.Result(app, none(), [rootUseCase.ClearMessages()]);

      case appUseCase.ReloadLocale.type:
        return this.Result(app, none(), [
          rootUseCase.ReloadLocale({ locale: useCase.locale }),
        ]);

      case appUseCase.AddMessage.type:
        return this.Result(app, none(), [
          rootUseCase.AddMessage({
            message: useCase.message,
            clearOthers: useCase.clearOthers,
          }),
        ]);

      case appUseCase.Redirect.type:
        return this.Result(app, none(), [
          rootUseCase.Redirect({
            pathname: useCase.pathname,
            replace: useCase.replace,
          }),
        ]);

      case appUseCase.Reload.type:
        return this.Result(app, none(), [
          rootUseCase.Reload({ path: useCase.path }),
        ]);

      case appUseCase.DebugDicom.type:
        return this.Result({
          ...app,
          debug: { ...app.debug, dicom: !app.debug.dicom },
        });

      case appUseCase.DebugToggleShowConfidence.type:
        return this.Result({
          ...app,
          debug: {
            ...app.debug,
            showBoundingBoxConfidence: !app.debug.showBoundingBoxConfidence,
          },
        });

      default:
        return this.SubReduce(app, action);
    }
  }
}

import React from 'react';
import { Image } from '../Image/Image';
import dark from './img/seeModeLogoDark.webp';
import light from './img/seeModeLogoLight.webp';
import mark from './img/logoMark.svg';

export interface LogoProps {
  variant?: 'mark' | 'dark' | 'light';
  height?: number;
  width?: number;
  fluid?: boolean;
}

const logoSrc = (variant: LogoProps['variant']) => {
  switch (variant) {
    case 'mark':
      return mark;
    case 'dark':
      return dark;
    default:
    case 'light':
      return light;
  }
};

export const Logo: React.FC<LogoProps> = ({
  variant = 'light',
  height,
  width,
  fluid,
}) => {
  return (
    <Image
      width={width}
      height={height}
      fluid={fluid}
      src={logoSrc(variant)}
      roundedCorners={false}
      loading="lazy"
      alt="See-Mode Logo"
    />
  );
};

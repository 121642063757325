import * as React from 'react';
import { useSelector } from 'react-redux';
import { Dicom } from '@interfaces/Dicom';
import { selectAllDicoms } from '@selectors/SessionSelectors';
import placeholderThumbnail from './img/thumbPlaceholder.jpg';

type ImageContextProps = {
  isLoadingImages: boolean;
  getImage: (id: string) => string;
  getVideo: (id: string) => string;
};

export const ImageContext = React.createContext<ImageContextProps>({
  isLoadingImages: false,
  getImage: () => 'Not implemented',
  getVideo: () => 'Not implemented',
});

type ImageDataMap = Record<string, { imageSrc: string; videoSrc?: string }>;

const ImageProvider: React.FC = ({ children }) => {
  const dicoms = useSelector(selectAllDicoms);

  const [imageData, setImageData] = React.useState<ImageDataMap>({});

  const getImage = (id: string) => {
    return (imageData[id] && imageData[id].imageSrc) ?? placeholderThumbnail;
  };

  const getVideo = (id: string) => {
    return (imageData[id] && imageData[id].videoSrc) ?? placeholderThumbnail;
  };

  const isLoadingImages = dicoms.length > Object.keys(imageData).length;

  const onLoadImage = (
    id: string,
    imageSrc: string | null,
    videoSrc?: string
  ) => {
    setImageData((imageData: ImageDataMap) => {
      if (imageSrc) {
        return { ...imageData, [id]: { imageSrc, videoSrc } };
      } else {
        const { [id]: _, ...rest } = imageData; // Removes the id if src is null
        return rest;
      }
    });
  };

  return (
    <ImageContext.Provider value={{ isLoadingImages, getImage, getVideo }}>
      {dicoms.map(dicom => (
        <ImageProviderDicom
          key={dicom.id}
          dicom={dicom}
          onLoadImage={onLoadImage}
        />
      ))}
      {children}
    </ImageContext.Provider>
  );
};

interface ImageProviderDicomProps {
  dicom: Dicom;
  onLoadImage: (id: string, src: string | null, videoSrc?: string) => void;
}

const ImageProviderDicom: React.FC<ImageProviderDicomProps> = ({
  dicom,
  onLoadImage,
}) => {
  // Each dicom has its own useEffect so each one can be loaded independently
  // without having to reload them all every time the dicom array state changes.
  React.useEffect(() => {
    let isAborted = false;
    let imageSrc: string | undefined;
    let videoSrc: string | undefined;

    // Capture the image data to avoid cache expiry issues.
    const getCanvasImage = (img: HTMLImageElement) => {
      // Create an empty canvas element
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      // Copy the image contents to the canvas
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
      }
      return canvas;
    };

    // Convert the image to an object URL
    const loadImgDataAsync = async (src: string) =>
      new Promise<string | undefined>(resolve => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = src;
        img.onload = () => {
          const canvas = getCanvasImage(img);
          canvas.toBlob(blob => {
            if (blob) {
              resolve(URL.createObjectURL(blob));
            } else {
              resolve(undefined);
            }
          });
        };
        img.onerror = e => {
          console.error('Image loading error', e);
          resolve(undefined);
        };
      });

    // Convert the video to an object URL
    const loadVideoAsync = async (src?: string) =>
      new Promise<string | undefined>(resolve => {
        if (!src) {
          resolve(undefined);
        } else {
          // fetch the video file
          fetch(src)
            .then(resp => resp.blob())
            .then(blob => {
              const blobURI = URL.createObjectURL(blob);
              resolve(blobURI);
            })
            .catch(e => {
              console.error('Video loading error', e);
              resolve(undefined);
            });
        }
      });

    const loadDicomImage = async () => {
      const imageData = await dicom.GetImage();
      const videoURL = await dicom.GetVideo();
      if (isAborted) return;

      if (videoURL && dicom.hasVideo) {
        videoSrc = await loadVideoAsync(videoURL);
      }

      imageSrc = await loadImgDataAsync(imageData);
      if (imageSrc && !isAborted) {
        onLoadImage(dicom.id, imageSrc, videoSrc);
      }
    };
    loadDicomImage();

    return () => {
      // Cleanup object URLs and abort async functions to avoid memory leaks
      isAborted = true;
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }

      if (videoSrc) {
        URL.revokeObjectURL(videoSrc);
      }
      onLoadImage(dicom.id, null);
    };
  }, []);

  return null;
};

const useImageContext = () => React.useContext(ImageContext);

export { ImageProvider, useImageContext };

import { authenticateUseCase } from '@useCases/Authentication';
import { Actions } from '@webInterfaces/StoreTypes';

export const signIn = (username: string, password: string): Actions =>
  authenticateUseCase.SignInAttempt({ email: username, password });

export const federatedSignIn = () =>
  authenticateUseCase.FederatedSignInAttempt();

export const signInWithMFACode = (otp: string) =>
  authenticateUseCase.LoginWithMFA({ otp });

export const cancel2fa = () => authenticateUseCase.SignOut();

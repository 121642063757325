import React from 'react';

export interface MiniCanvasContextProps {
  miniCanvasURLs: Record<string, string>;
  setMiniCanvasURLs(data: Record<string, string>): void;
}

const MiniCanvasContext = React.createContext<MiniCanvasContextProps>({
  miniCanvasURLs: {},
  setMiniCanvasURLs: () => null,
});

export const MiniCanvasProvider: React.FC = ({ children }) => {
  const [miniCanvasURLs, setMiniCanvasURLs] = React.useState<
    Record<string, string>
  >({});

  const mergeMiniCanvasURLs = (data: Record<string, string>) => {
    setMiniCanvasURLs(prev => {
      return { ...prev, ...data };
    });
  };

  return (
    <MiniCanvasContext.Provider
      value={{
        miniCanvasURLs,
        setMiniCanvasURLs: mergeMiniCanvasURLs,
      }}
    >
      {children}
    </MiniCanvasContext.Provider>
  );
};

export default MiniCanvasContext;

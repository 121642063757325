import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import styles from './listDivider.scss';

export const ListDivider: React.FC = ({ children }) => {
  return (
    <Box display="flex" className={styles.listDivider} fullWidth>
      <Box>
        <Text type="body2" muted>
          {children}
        </Text>
      </Box>
    </Box>
  );
};

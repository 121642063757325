import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Stack } from '@webMolecules/Stack/Stack';
import { t } from '@webInterfaces/I18n';

export interface ForgotPasswordRequestProps {
  /**
   * onSubmit forgot password callback
   */
  onSubmit: (email: string) => void;
  /**
   * Form is loading
   */
  loading?: boolean;
}

export const ForgotPasswordRequest = ({
  onSubmit,
  loading = false,
}: ForgotPasswordRequestProps) => {
  const ForgotPasswordRequestSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('generic.validation.invalid.email'))
      .required(t('generic.validation.required.email')),
  });

  const initialFormValues = {
    email: '',
  };

  return (
    <Formik
      onSubmit={({ email }) => {
        onSubmit(email);
      }}
      initialValues={initialFormValues}
      validationSchema={ForgotPasswordRequestSchema}
    >
      {formProps => (
        <Form>
          <Stack spacing="m" direction="vertical">
            <Box>
              <FormInput
                name="email"
                data-testid="forgotpassword-email"
                autoComplete="email"
                error={formProps.errors.email}
                placeholder={t('pages.auth.sign_in.placeholder.email')}
                size="large"
                fluid
                touched={formProps.touched.email}
                type={FormInputTypes.Text}
              />
              {formProps.touched.email && formProps.errors.email ? (
                <ErrorMessage
                  name="email"
                  render={msg => (
                    <FormFieldHint intent="error" marginTop="xs">
                      {msg}
                    </FormFieldHint>
                  )}
                />
              ) : (
                <FormFieldHint intent="neutral" marginTop="xs">
                  {t('pages.auth.forgot_password.blurb')}
                </FormFieldHint>
              )}
            </Box>
            <Button
              loading={loading}
              type="submit"
              intent="primary"
              strong
              fluid
              size="large"
              data-testid="forgotpassword-submit"
            >
              {t('pages.auth.forgot_password.submit_request')}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

import { ClinicalAction } from '@entities/Characteristics';
import { TiRadsGrading } from '@entities/Grading';
import { DistanceUnit } from '@entities/Unit';
import { convertUnit } from '@handlers/UnitHandler';

export const unitUsedInCriteria = DistanceUnit.MM;
const GradingToClinicalActionCriteria: Record<
  TiRadsGrading,
  { [key in ClinicalAction]?: number }
> = {
  [TiRadsGrading.TR1]: {},
  [TiRadsGrading.TR2]: {},
  [TiRadsGrading.TR3]: {
    [ClinicalAction.FNA]: 25,
    [ClinicalAction.FollowUp]: 15,
  },
  [TiRadsGrading.TR4]: {
    [ClinicalAction.FNA]: 15,
    [ClinicalAction.FollowUp]: 10,
  },
  [TiRadsGrading.TR5]: {
    [ClinicalAction.FNA]: 10,
    [ClinicalAction.FollowUp]: 5,
  },
};

export const getClinicalActionByNoduleSizeAndTiRadGrading = (
  grading: TiRadsGrading,
  size: number,
  sizeUnit: DistanceUnit
): ClinicalAction | undefined => {
  if (isNaN(size)) return undefined;

  const criteria = GradingToClinicalActionCriteria[grading];

  let noduleSize = size;

  if (sizeUnit !== unitUsedInCriteria) {
    noduleSize = convertUnit(sizeUnit, unitUsedInCriteria, size);
  }

  for (const [clinicalAction, thresholdValue] of Object.entries(criteria)) {
    if (noduleSize >= thresholdValue) {
      return clinicalAction as ClinicalAction;
    }
  }

  return ClinicalAction.NoFurtherFollowUp;
};

import intersection from 'lodash/intersection';
import { Dicom } from '@interfaces/Dicom';
import { GalleryFilter } from '@interfaces/GalleryFilter';
import { t, tPrint } from '@webInterfaces/I18n';
import { Workflow } from '@entities/Workflow';
import {
  BreastGalleryFilter,
  BreastGalleryFilters,
  SpecificGalleryFilter,
  SpecificGalleryFilterConfig,
  ThyroidGalleryFilter,
  ThyroidGalleryFilters,
  breastGalleryFilterToAnnotations,
  getWorkflowDefaultFilter,
  thyroidGalleryFilterToAnnotations,
} from '@config/Gallery/SpecificGalleryFilter';
import { Finding } from '@entities/Finding';
import { StudyType } from '@entities/StudyType';
import { Annotations } from '@entities/Dicom';

export enum GridType {
  grid = 'grid_type--grid',
  single = 'grid_type--single',
  none = 'grid_type--none',
}

export const sortDicomByScanTime = (dicoms: Dicom[]): Dicom[] => {
  return dicoms.sort((a: Dicom, b: Dicom) => {
    if (a.scanTime && b.scanTime) {
      return parseFloat(a.scanTime) - parseFloat(b.scanTime);
    }

    return 0;
  });
};

export const filterDicomsByFindingId = (
  dicoms: Dicom[],
  findingIds: string[]
) => {
  return dicoms.filter(dicom => {
    const assignedFindingIds = dicom.detections
      .map(d => d.assignedFinding?.id)
      .filter(Boolean) as string[];
    return intersection(assignedFindingIds, findingIds).length > 0;
  });
};

export const filterDicomsByAnnotations = (
  dicoms: Dicom[],
  annotations: Annotations[] | null
) => {
  if (!annotations) return dicoms;

  return dicoms.filter(dicom => {
    const { bodyLocation, bodySide, vessel, region } = dicom.annotations;

    // Checks whether dicom meet any one annotations criteria
    return annotations.some(annotation => {
      return (
        (!annotation.bodyLocation || bodyLocation == annotation.bodyLocation) &&
        (!annotation.bodySide || bodySide == annotation.bodySide) &&
        (!annotation.vessel || vessel == annotation.vessel) &&
        (!annotation.region || region == annotation.region)
      );
    });
  });
};

export const filterDicomsByExcludedAnnotations = (
  dicoms: Dicom[],
  annotations: Annotations[] | null
) => {
  if (!annotations) return dicoms;

  const includedDicomIds = filterDicomsByAnnotations(dicoms, annotations).map(
    dicom => dicom.id
  );

  return dicoms.filter(dicom => !includedDicomIds.includes(dicom.id));
};

export const filterDicoms = (
  dicoms: Dicom[],
  filters: GalleryFilter
): Dicom[] => {
  if (filters.findingIds.length > 0) {
    return filterDicomsByFindingId(dicoms, filters.findingIds);
  } else if (filters.specificFilter) {
    return filterDicomsBySpecificGalleryFilter(dicoms, filters.specificFilter);
  }
  return dicoms;
};

export const filterDicomsBySpecificGalleryFilter = (
  dicoms: Dicom[],
  galleryFilter: SpecificGalleryFilter
) => {
  switch (galleryFilter) {
    case ThyroidGalleryFilter.IncludingDetections:
      return dicoms.filter(d => d.detections.length > 0);

    case ThyroidGalleryFilter.Thyroid:
    case ThyroidGalleryFilter.Isthmus:
    case ThyroidGalleryFilter.LeftLobe:
    case ThyroidGalleryFilter.RightLobe:
    case ThyroidGalleryFilter.NeckHead: {
      const annotations = thyroidGalleryFilterToAnnotations(galleryFilter);
      return filterDicomsByAnnotations(dicoms, annotations);
    }

    case ThyroidGalleryFilter.Other: {
      const annotations = [
        ThyroidGalleryFilter.Isthmus,
        ThyroidGalleryFilter.LeftLobe,
        ThyroidGalleryFilter.RightLobe,
        ThyroidGalleryFilter.NeckHead,
        ThyroidGalleryFilter.Thyroid,
      ]
        .map(thyroidGalleryFilterToAnnotations)
        .filter(Boolean)
        .flat() as Annotations[];

      return filterDicomsByExcludedAnnotations(dicoms, annotations);
    }

    case BreastGalleryFilter.IncludingDetections:
      return dicoms.filter(d => d.detections.length > 0);

    case BreastGalleryFilter.LeftBreast:
    case BreastGalleryFilter.RightBreast:
      const annotations = breastGalleryFilterToAnnotations(galleryFilter);
      return filterDicomsByAnnotations(dicoms, annotations);

    case BreastGalleryFilter.Other: {
      const annotations = [
        BreastGalleryFilter.LeftBreast,
        BreastGalleryFilter.RightBreast,
      ]
        .map(breastGalleryFilterToAnnotations)
        .filter(Boolean)
        .flat() as Annotations[];

      return filterDicomsByExcludedAnnotations(dicoms, annotations);
    }

    case BreastGalleryFilter.All:
    case ThyroidGalleryFilter.All:
    default:
      return dicoms;
  }
};

export const makeFilterTitleFromFindingId = (
  findings: Finding[],
  findingId: string
): string => {
  const finding = findings.find(f => f.id === findingId);
  return finding ? tPrint('finding_number.summary', finding.index) : '';
};

export const getGalleryFilterSelectOptions = (
  studyType: StudyType | undefined,
  dicoms: Dicom[]
) => {
  switch (studyType) {
    case StudyType.SmallPartsBreast: {
      return convertGalleryFilterToSelectOptions(BreastGalleryFilters, dicoms);
    }
    case StudyType.SmallPartsThyroid:
      return convertGalleryFilterToSelectOptions(ThyroidGalleryFilters, dicoms);
    default:
      return [];
  }
};

export const convertGalleryFilterToSelectOptions = (
  filterConfigs: SpecificGalleryFilterConfig[],
  dicoms: Dicom[]
) => {
  return filterConfigs.map(config => {
    const filterDicoms = filterDicomsBySpecificGalleryFilter(
      dicoms,
      config.filterType
    );
    return {
      label: t(config.labelKey) + ` (${filterDicoms.length})`,
      value: config.filterType,
      isDefault: (workflow: Workflow) => {
        return getWorkflowDefaultFilter(workflow).includes(config.filterType);
      },
    };
  });
};

import { institutionUseCase as UC } from '@useCases/Admin/Institutions';
import * as I from '@interfaces/Admin/InstitutionLineItem';
import { Profile } from '@entities/Profile';

export const load = () => UC.Load();

export const unload = (target: 'all' | 'institution' | 'user') =>
  UC.Unload({ target });

export const empty = () => UC.CreateEmpty();
export const emptyUser = () => UC.CreateEmptyUser();

export const create = (institutionLineItem: I.InstitutionLineItem) =>
  UC.Create({ institution: institutionLineItem });

export const createInstitutionUser = (profileLineItem: Profile) =>
  UC.CreateUser({ userLineItem: profileLineItem });

export const deleteInstitutionUser = (id: string) => UC.DeleteUser({ id });

export const get = (id: string, userId?: string) => UC.Get({ id, userId });

import { EffectReducer, ReducerResult } from '@library/Reducer';
import * as ContractAuthenticate from '@contracts/Authenticate';
import * as ContractStorage from '@contracts/Storage';
import { appUseCase } from '@useCases/App';
import { Profile, ProfilePreferences } from '@entities/Profile';
import Effect, { effect, none } from '@library/Effect';
import Result, { ok, err } from '@monads/Result';
import { createUseCase } from '@helpers/createUseCase';
import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';
export const profileUseCase = {
  /**
   * A user can update their profile
   */
  Update: createUseCase('PROFILE_UPDATE_PROFILE').withPayload<{
    profile: Profile;
  }>(),

  /**
   * A user can update their profile preferences
   */
  PreferenceUpdate: createUseCase(
    'PROFILE_UPDATE_PROFILE_PREFERENCES'
  ).withPayload<{
    profilePreferences: ProfilePreferences;
  }>(),

  /**
   * A user can update their profile
   * - Result
   */
  ResultUpdate: createUseCase('PROFILE_UPDATE_PROFILE_RESULT').withPayload<{
    result: Result<Profile>;
  }>(),

  OverrideConfiguration: createUseCase(
    'OVERRIDE_CONFIGURATION'
  ).withPayload<InstitutionConfiguration>(),
};

// UseCases
export type ProfileUseCases = ReturnType<
  | typeof profileUseCase.Update
  | typeof profileUseCase.PreferenceUpdate
  | typeof profileUseCase.ResultUpdate
  | typeof profileUseCase.OverrideConfiguration
>;

/**
 * Reducer
 *
 * The ProfileReducer takes ProfileUseCases and the current App Settings (state)
 * and returns a new state and possible side effects.
 */
// eslint-disable-next-line prettier/prettier
export class ProfileReducer extends EffectReducer<Profile> {
  private apiAuthentication: ContractAuthenticate.Authenticate;
  private apiStorage: ContractStorage.Storage;

  constructor(
    authenticate: ContractAuthenticate.Authenticate,
    apiStorage: ContractStorage.Storage
  ) {
    super();
    this.apiAuthentication = authenticate;
    this.apiStorage = apiStorage;
  }

  Perform(
    profile: Profile,
    { type, payload: useCase }: ProfileUseCases
  ): ReducerResult<Profile> {
    switch (type) {
      case profileUseCase.Update.type:
        return this.Result(
          profile,
          effectUpdateProfile(
            this.apiAuthentication,
            this.apiStorage,
            useCase.profile
          ),
          [appUseCase.ClearMessages()]
        );

      case profileUseCase.PreferenceUpdate.type:
        return this.Result(
          profile,
          effectUpdateProfile(this.apiAuthentication, this.apiStorage, {
            ...profile,
            preferences: useCase.profilePreferences,
          }),
          [appUseCase.ClearMessages()]
        );

      case profileUseCase.ResultUpdate.type: {
        return useCase.result.mapLift(
          profile =>
            this.Result(profile, none(), [
              appUseCase.ReloadLocale({ locale: profile.preferences.locale }),
              appUseCase.AddMessage({
                message: {
                  intent: 'success',
                  key: 'generic.form.save_success',
                },
              }),
            ]),
          error => this.Error(error)
        );
      }
      case profileUseCase.OverrideConfiguration.type: {
        return this.Result({
          ...profile,
          configuration: {
            ...profile.configuration,
            ...useCase,
          },
        });
      }
    }
  }
}

/**
 * Update profile details
 */
const effectUpdateProfile = (
  apiAuthentication: ContractAuthenticate.Authenticate,
  storage: ContractStorage.Storage,
  profile: Profile
): Effect =>
  effect(async () => {
    const authResult = await apiAuthentication.UpdateProfile(storage, profile);

    switch (authResult.type) {
      case ContractAuthenticate.AuthenticateResultType
        .AuthenticateResultSuccess:
        return profileUseCase.ResultUpdate({
          result: ok(authResult.profile),
        });
      default:
        return profileUseCase.ResultUpdate({
          result: err(new Error('Failed to update profile')),
        });
    }
  }, 'effect-profile-update-profile');

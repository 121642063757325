import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import { decode } from '@api/decoders/Profile';
import { post } from '@api/schema/client';
import { components } from '@api/schema/generated';
import * as Contract from '@contracts/Authenticate';
import { Storage } from '@contracts/Storage';
import { Profile } from '@interfaces/Profile';
import { browserStorage } from '@web/BrowserStorage';
import * as RouteHelper from '@helpers/routes';
import {
  JWTPayload,
  SAML_EMAIL_PREFIX,
  institutionUsesFederatedSignInKey,
  isSAMLBypassKey,
  isSeeModeAdminDomainKey,
  useAuthenticationMode,
} from '@interfaces/Authentication';
import { SEEMODE_EXPIRED_TIME } from '@webOrganisms/IdleTimer/IdleTimer';
import { initAmplify } from './Init';

enum AuthenticateChallenge {
  NONE = 'NONE',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

enum MFAMethod {
  NOMFA = 'NOMFA',
  TOTP = 'TOTP',
  SMS = 'SMS',
}

// We shouldn't have to do this, see this issue for details: https://github.com/aws-amplify/amplify-js/issues/3733
export type AuthUser = CognitoUser & {
  challengeName?: AuthenticateChallenge;
  preferredMFA?: AuthenticateChallenge;
};

interface CognitoUserAndProfileComposite {
  cognitoUser: AuthUser;
  profile: Profile;
}

/*
 * [Amplify Auth class docs](https://aws-amplify.github.io/amplify-js/api/classes/authclass.html)
 */
export class Authenticate implements Contract.Authenticate {
  private currentCognitoUser?: AuthUser;
  private currentProfile?: Profile;
  private _username?: string;
  private onError: (error: Error) => void;

  constructor(onError: (error: Error) => void) {
    this.onError = onError;
  }

  private makeAuthenticateResultError = (error: Error) => {
    this.onError(error as Error);

    switch (error?.message) {
      case 'UserAlreadyCreatedException':
        return Contract.makeAuthenticateResultError(
          Contract.AuthenticateError.AuthenticateErrorAccountAlreadyCreated
        );
    }

    switch (error?.name) {
      case 'UserNotFoundException':
        return Contract.makeAuthenticateResultError(
          Contract.AuthenticateError.AuthenticateErrorIncorrectEmail
        );
      case 'PasswordResetRequiredException':
        return Contract.makeAuthenticateResultError(
          Contract.AuthenticateError.AuthenticateErrorPasswordResetRequired
        );
      case 'NotAuthorizedException':
        return Contract.makeAuthenticateResultError(
          Contract.AuthenticateError.AuthenticateErrorIncorrectEmailOrPassword
        );
      case 'LimitExceededException':
        return Contract.makeAuthenticateResultError(
          Contract.AuthenticateError.AuthenticateErrorLimitExceededError
        );
      default:
        return Contract.makeAuthenticateResultError(
          Contract.AuthenticateError.AuthenticateErrorGeneric
        );
    }
  };

  InitAmplify(): Promise<boolean> {
    return initAmplify();
  }

  CreateAccount({
    storage,
    email,
    currentPassword,
    newPassword,
    details,
  }: {
    storage: Storage;
    email: string;
    currentPassword: string;
    newPassword: string;
    details: Record<string, string>;
  }): Promise<Contract.AuthenticateResult> {
    return Auth.signIn(email, currentPassword)
      .then((user: AuthUser) => {
        if (typeof user.challengeName === 'undefined') {
          Auth.signOut();
          throw new Error('UserAlreadyCreatedException');
        }

        return Auth.completeNewPassword(user, newPassword);
      })
      .then((user: AuthUser) =>
        this.handleCreateProfile(storage, user, details)
      )
      .then((user: AuthUser) => this.handleCognitoUser(user, storage))
      .catch(err => this.makeAuthenticateResultError(err));
  }

  async ResumeSession(
    storage: Storage
  ): Promise<undefined | Contract.AuthenticateResult> {
    const expiredTime =
      parseInt(browserStorage.getItem(SEEMODE_EXPIRED_TIME) || '', 10) || 0;

    if (
      expiredTime &&
      new Date().getTime() >= new Date(expiredTime).getTime()
    ) {
      browserStorage.removeItem(SEEMODE_EXPIRED_TIME);
      return undefined;
    }

    browserStorage.getItem(SEEMODE_EXPIRED_TIME);

    return (
      Auth.currentAuthenticatedUser()
        //
        .then((user: AuthUser) =>
          this.setCurrentCognitoUserAndProfile(user, storage)
        )
    );
  }

  SignIn(
    storage: Storage,
    username: string,
    password: string
  ): Promise<Contract.AuthenticateResult> {
    return Auth.signIn(username, password)
      .then(async (user: AuthUser) => {
        return this.handleCognitoUser(user, storage);
      })
      .catch(err => this.makeAuthenticateResultError(err));
  }

  FederatedSignIn(): Promise<Contract.AuthenticateResult> {
    //FederatedSignInOptions not exposed by aws-amplify
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { identityProvider } = useAuthenticationMode();
    return Auth.federatedSignIn({ provider: identityProvider } as any)
      .then(() => {
        return Auth.currentAuthenticatedUser();
      })
      .catch(err => this.makeAuthenticateResultError(err));
  }

  private getUserId(cognitoUser: AuthUser) {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error('User not configured correctly.');
    }
    const subId = payload['sub'];
    if (!subId) {
      throw new Error('User sub id not available');
    }
    return subId;
  }

  async handleCreateProfile(
    storage: Storage,
    cognitoUser: AuthUser,
    details: Record<string, string>
  ) {
    const data = await post('/get-user-profile', {
      body: {
        user_profile_id: this.getUserId(cognitoUser),
      },
    });

    const profile = await decode(storage, data);
    profile.firstName = details.firstName;
    profile.lastName = details.lastName;
    profile.isActive = true;
    await this.handleUpdateProfile(storage, profile);

    //TODO: Check the query worked ok??
    return cognitoUser;
  }

  async handleGetProfile(storage: Storage, cognitoUser: AuthUser) {
    const data = await post('/get-user-profile', {
      body: {
        user_profile_id: this.getUserId(cognitoUser),
      },
    });

    const composite = {
      cognitoUser,
      profile: await decode(storage, data),
    } as CognitoUserAndProfileComposite;

    return composite;
  }

  async handleUpdateProfile(
    storage: Storage,
    profile: Profile
  ): Promise<Contract.AuthenticateResult> {
    this.currentProfile = profile;

    const data = await post('/update-user-profile', {
      body: encodeProfileUser(profile),
    });

    const updateProfile = await decode(storage, data);

    if (this.currentCognitoUser) {
      updateProfile.email = this.GetEmail();
      updateProfile.isAdmin = this.IsAdmin();
    }

    return Contract.makeAuthenticateResultSuccess(
      this.GetToken(),
      this.currentCognitoUser?.getUsername() || '',
      updateProfile,
      toMFAEnabled(this.currentCognitoUser)
    );
  }

  async handleCognitoUser(
    cognitoUser: AuthUser,
    storage: Storage
  ): Promise<Contract.AuthenticateResult> {
    const mfaMethod = cognitoUser.challengeName || AuthenticateChallenge.NONE;

    switch (mfaMethod as AuthenticateChallenge) {
      default:
      case AuthenticateChallenge.NONE:
        return this.setCurrentCognitoUserAndProfile(cognitoUser, storage);
      case AuthenticateChallenge.NEW_PASSWORD_REQUIRED:
        return Contract.makeAuthenticateResultNewPasswordRequired(
          cognitoUser,
          ''
        );
      case AuthenticateChallenge.SMS_MFA:
      case AuthenticateChallenge.SOFTWARE_TOKEN_MFA:
        this.currentCognitoUser = cognitoUser;
        return Contract.makeAuthenticateResultMFACodeRequired(cognitoUser);
    }
  }

  //This method determines the users login type. It will refresh on every page load when the user profile is set.
  //This will occurr on signin, createAccount, resumesession and MFA sign in.
  setAuthMode(token: string) {
    //Check that federated signin is used by this institution, which is set in init.ts
    const isFederatedSignIn =
      browserStorage.getItem(institutionUsesFederatedSignInKey) !== null;

    //Decode the jwt to fetch the current user name for the user.
    const decoded = jwt_decode<JWTPayload>(token);

    //Check the username is not a federated signin username, but normal email login
    const isNonFederatedEmail = !decoded.username.includes(SAML_EMAIL_PREFIX);
    browserStorage.setItem(
      isSAMLBypassKey,
      (isFederatedSignIn && isNonFederatedEmail).toString()
    );

    //Determine if the host name is a global admin domain login.
    const isAdminDomain = RouteHelper.getIsAdminDomain();
    browserStorage.setItem(isSeeModeAdminDomainKey, isAdminDomain.toString());
  }

  async setCurrentCognitoUserAndProfile(
    cognitoUser: AuthUser,
    storage: Storage
  ): Promise<Contract.AuthenticateResult> {
    const userData = await this.handleGetProfile(storage, cognitoUser);
    this.currentCognitoUser = cognitoUser;
    this.currentProfile = userData.profile;
    this.currentProfile.email = this.GetEmail();
    this.currentProfile.isAdmin = this.IsAdmin();

    this._username = this.currentProfile.email;
    browserStorage.setItem('seemode_email', this._username);
    window.addEventListener('storage', event => {
      if (event.key === 'seemode_email' && event.newValue !== this._username) {
        this.SignOut(false);
        window.location.href = RouteHelper.authSignInReasonSignedInUserChanged;
      }
    });

    const token = this.GetToken();
    this.setAuthMode(token);
    return Contract.makeAuthenticateResultSuccess(
      token,
      cognitoUser.getUsername(),
      userData.profile,
      toMFAEnabled(this.currentCognitoUser)
    );
  }

  verifyMFATokenAndSetPreferredMFA(
    token: string,
    preferredMFA: MFAMethod
  ): Promise<boolean> {
    return new Promise((resolve, _reject) => {
      Auth.verifyTotpToken(this.currentCognitoUser, token)
        .then(async _userSession => {
          await Auth.setPreferredMFA(this.currentCognitoUser, preferredMFA);
        })
        .then(() => {
          resolve(true);
        })
        .catch(_err => {
          resolve(false);
        });
    });
  }

  ConfirmSignInByProvidingNewPassword(
    token: AuthUser,
    currentPassword: string,
    newPassword: string
  ): Promise<Contract.AuthenticateResult> {
    return new Promise((resolve, reject) => {
      Auth.changePassword(token, currentPassword, newPassword)
        .then(() => {
          if (this.currentProfile) {
            resolve(
              Contract.makeAuthenticateResultSuccess(
                this.GetToken(),
                token.getUsername(),
                this.currentProfile,
                toMFAEnabled(token)
              )
            );
          }
          throw new Error('No current profile available');
        })
        .catch(err => reject(this.makeAuthenticateResultError(err)));
    });
  }

  SignInWithMFA(
    storage: Storage,
    mfaCode: string
  ): Promise<Contract.AuthenticateResult> {
    const challengeName = this.currentCognitoUser?.challengeName;

    const mfaMethod = challengeName as
      | 'SMS_MFA'
      | 'SOFTWARE_TOKEN_MFA'
      | null
      | undefined;

    return Auth.confirmSignIn(
      this.currentCognitoUser, // Return object from Auth.signIn()
      mfaCode, // Confirmation code
      mfaMethod // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .then(async (user: AuthUser) =>
        this.setCurrentCognitoUserAndProfile(user, storage)
      )
      .catch(err => this.makeAuthenticateResultError(err));
  }

  SignOut(clearStoredUsername = true): void {
    let seeModeEmail = '';
    if (!clearStoredUsername) {
      seeModeEmail = browserStorage.getItem('seemode_email') || seeModeEmail;
    }
    browserStorage.clear();
    sessionStorage.clear();
    if (!clearStoredUsername) {
      browserStorage.setItem('seemode_email', seeModeEmail);
    }
    Auth.signOut();
  }

  ForgotPassword(username: string): Promise<'success'> {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(username)
        .then(() => resolve('success'))
        .catch(err => reject(this.makeAuthenticateResultError(err)));
    });
  }

  ForgotPasswordReset(
    username: string,
    code: string,
    newPassword: string
  ): Promise<'success'> {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => resolve('success'))
        .catch(err => reject(this.makeAuthenticateResultError(err)));
    });
  }

  ChangePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<Contract.AuthenticateResult> {
    return new Promise(resolve => {
      Auth.changePassword(this.currentCognitoUser, currentPassword, newPassword)
        .then(() => {
          if (this.currentProfile) {
            return resolve(
              Contract.makeAuthenticateResultSuccess(
                this.GetToken(),
                this.currentCognitoUser?.getUsername() || '',
                this.currentProfile,
                toMFAEnabled(this.currentCognitoUser)
              )
            );
          }
          throw new Error('No current profile available');
        })
        .catch(err => {
          resolve(this.makeAuthenticateResultError(err));
        });
    });
  }

  UpdateProfile(
    storage: Storage,
    profile: Profile
  ): Promise<Contract.AuthenticateResult> {
    return this.handleUpdateProfile(storage, profile);
  }

  GetEmail(): string {
    if (!this.currentCognitoUser) {
      throw Error('No current user');
    }
    const email = this.currentCognitoUser.getUsername();
    if (!email) {
      throw Error('No user email available');
    }
    return email;
  }

  IsAdmin(): boolean {
    if (!this.currentCognitoUser) {
      throw Error('No current user');
    }
    return this.currentProfile?.isAdmin || false;
  }

  async GetCurrentSession(): Promise<string | undefined> {
    return Auth.currentSession()
      .then((session: CognitoUserSession) => {
        return session.getAccessToken().getJwtToken();
      })
      .catch(() => undefined);
  }

  GetToken(): string {
    return (
      this.currentCognitoUser
        ?.getSignInUserSession()
        ?.getAccessToken()
        .getJwtToken() || ''
    );
  }

  GetCurrentUserId(): undefined | string {
    return this.currentProfile?.id;
  }

  GetProfile(): Profile | undefined {
    return this.currentProfile;
  }

  GetInstitutionId(): string {
    return this.currentProfile?.institutionId || '';
  }

  SetUpMFA(): Promise<Contract.MFASetupResult> {
    return Auth.setupTOTP(this.currentCognitoUser)
      .then(code => {
        return Contract.MFAProcessInitialize(code);
      })
      .catch(err => {
        return Contract.MFAProcessFailed(err);
      });
  }

  EnableMFA(token: string): Promise<boolean> {
    return this.verifyMFATokenAndSetPreferredMFA(token, MFAMethod.TOTP);
  }

  DisableMFA(token: string): Promise<boolean> {
    return this.verifyMFATokenAndSetPreferredMFA(token, MFAMethod.NOMFA);
  }
}

const encodeProfileUser = (
  profile: Profile
): components['schemas']['UpdateUserProfileInput'] => {
  return {
    user_profile_id: profile.id,
    firstname: profile.firstName,
    lastname: profile.lastName,
    swatch: profile.swatchColour,
    preferences: profile.preferences as Record<string, never>,
    is_activated: profile.isActive,
  };
};

const toMFAEnabled = (user: AuthUser | undefined) => {
  // It seems like when you're signing in, you have challengeName
  // If you're resuming a session, you have preferredMFA
  const name = user?.challengeName || user?.preferredMFA;
  const challengeName = name && name.toString();

  switch (challengeName) {
    default:
    case AuthenticateChallenge.NEW_PASSWORD_REQUIRED:
    case AuthenticateChallenge.NONE:
      return false;
    case AuthenticateChallenge.SMS_MFA:
    case AuthenticateChallenge.SOFTWARE_TOKEN_MFA:
      return true;
  }
};

export default Authenticate;

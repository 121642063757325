import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Icon } from '@webMolecules/Icon/Icon';
import {
  Position,
  PositionedBox,
} from '@webMolecules/PositionedBox/PositionedBox';
import { cnames } from '@helpers/cnames';
import {
  DrawerMotionDirection,
  getMotionVariantsFromDirection,
  spring,
} from '../DrawerMenu';
import styles from './multilevelDrawerMenu.scss';

export interface MultilevelDrawerMenuItem {
  onSelect?: () => void;
  label: string;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  strong?: boolean;
  scrollTo?: boolean;
  hint?: string;
}

export interface MultilevelDrawerMenuProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  title?: string;
  hint?: string;
  motionDirection?: DrawerMotionDirection;
  menuFooter?: React.ReactNode;
  titleBefore?: JSX.Element;
  titleAfter?: JSX.Element;
  drawerMenuItems: MultilevelDrawerMenuItem[];
  submenuOptions?: MultilevelDrawerMenuItem[];
}

const MotionBox = motion(Box);

export const MultilevelDrawerMenu: React.FC<MultilevelDrawerMenuProps> = ({
  isOpen,
  title,
  hint,
  motionDirection = DrawerMotionDirection.Up,
  menuFooter,
  titleBefore,
  titleAfter,
  drawerMenuItems,
  submenuOptions,
}) => {
  const hintClassNames = cnames(styles.menuHint, {
    [styles.hasIconBefore]: !!titleBefore,
  });

  const menuItemClassNames = cnames(styles.menuItem, styles['neutral'], {
    [styles.hasIconBefore]: !!titleBefore,
  });

  const [showSubmenu, setShowSubmenu] = React.useState(false);
  const [subMenuReferencePosition, setSubMenuReferencePosition] =
    React.useState<Position | undefined>(undefined);

  if (submenuOptions) {
    submenuOptions.forEach(option => {
      const onSelect = option.onSelect;
      option.onSelect = () => {
        setShowSubmenu(false);
        onSelect && onSelect();
      };
    });
  }

  const onRefChange = React.useCallback(
    node => {
      if (node) {
        const index = drawerMenuItems.findIndex(item => item.scrollTo);

        if (index !== -1) {
          const drawerItems = node.querySelectorAll('[data-drawer-item]');

          // Scroll to the selected drawer item within the menu
          // Wait one frame before scrolling to allow floating UI to position the menu
          requestAnimationFrame(() => {
            drawerItems[index]?.scrollIntoView({
              block: 'nearest',
              inline: 'nearest',
            });
          });
        }
      }
    },
    [drawerMenuItems]
  );

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <MotionBox
            className={styles.menuDrawer}
            variants={getMotionVariantsFromDirection(motionDirection)}
            transition={spring}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <Card elevation="medium" padding="none">
              {title && (
                <Box
                  padding="s"
                  paddingY="xs"
                  className={styles.menuHeader}
                  display="flex"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" fullWidth>
                    {titleBefore && <Box marginRight="xs">{titleBefore}</Box>}
                    <Text type="display6" display="block">
                      {title}
                    </Text>
                    {titleAfter && <Box marginLeft="auto">{titleAfter}</Box>}
                  </Box>
                </Box>
              )}
              {hint && (
                <Box
                  paddingY="xxs"
                  display="flex"
                  className={hintClassNames}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text type="body" display="block" muted>
                    {hint}
                  </Text>
                </Box>
              )}

              <Box
                className={styles.drawerMenuItemBox}
                scrollable="y"
                ref={onRefChange}
              >
                {drawerMenuItems &&
                  drawerMenuItems.map((item, index) => {
                    let handleClick: any = item.onSelect;

                    if (submenuOptions) {
                      handleClick = (e: React.MouseEvent) => {
                        const clientReact =
                          e.currentTarget.getBoundingClientRect();
                        setShowSubmenu(true);
                        setSubMenuReferencePosition({
                          x: clientReact.x + clientReact.width,
                          y: clientReact.y,
                        });
                        item.onSelect && item.onSelect();
                      };
                    }

                    return (
                      <Box
                        data-drawer-item
                        key={`option-${index}-${item.label}`}
                        element="button"
                        className={menuItemClassNames}
                        onClick={handleClick}
                        display="flex"
                        alignItems="center"
                      >
                        {item.iconBefore && (
                          <Box marginRight="xs">{item.iconBefore}</Box>
                        )}
                        <Text type="body" display="block">
                          {item.strong ? (
                            <strong>{item.label}</strong>
                          ) : (
                            item.label
                          )}
                        </Text>

                        {item.hint && (
                          <Text
                            type="body"
                            display="block"
                            muted
                            marginLeft="s"
                          >
                            {item.hint}
                          </Text>
                        )}
                        {item.iconAfter && (
                          <Box marginLeft="auto" display="flex">
                            {item.iconAfter}
                          </Box>
                        )}

                        {submenuOptions && (
                          <Box marginLeft="auto">
                            <Icon name="chevron-right" />
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>

              {menuFooter && (
                <Box
                  className={styles.menuFooter}
                  paddingY="s"
                  paddingX="l"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                  gapColumn="s"
                >
                  {menuFooter}
                </Box>
              )}
            </Card>
          </MotionBox>
        )}
      </AnimatePresence>

      {submenuOptions && subMenuReferencePosition && (
        <PositionedBox
          className={styles.subMenu}
          placement="bottom-start"
          referencePosition={subMenuReferencePosition}
        >
          <MultilevelDrawerMenu
            key="subMenu"
            isOpen={showSubmenu}
            motionDirection={DrawerMotionDirection.Left}
            drawerMenuItems={submenuOptions}
          />
        </PositionedBox>
      )}
    </>
  );
};

import { Auth } from 'aws-amplify';
import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
} from '@aws-crypto/client-browser';
import { ICredentials } from '@aws-amplify/core';
import * as Contracts from '@contracts/Encryption';
import { logError } from '@webInterfaces/Error';
import { useAuthenticationMode } from '@interfaces/Authentication';

const { encrypt, decrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
);

/**
 * Helper class for encrypting and decrypting things.
 * Built on top of KMS: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/KMS.html
 */
export class Encryption implements Contracts.Encryption {
  private kmsKeyArn: string | undefined;
  private region: string | undefined;
  private kms: KMS | undefined;
  private credentials: ICredentials | undefined;

  /**
   * Set up KMS. Requires an ARN, and time to asynchrounously retrieve the credentials
   *
   * @param arn The KMS Key ARN
   */
  async configure(key: string | undefined, nativeRegion: string | undefined) {
    this.kmsKeyArn = key;
    this.region = nativeRegion;
    this.credentials = await Auth.currentCredentials();
    this.kms = new KMS({ credentials: this.credentials, region: nativeRegion });
  }

  /**
   * Helper function to encrypt a plaintext string with KMS.
   *
   * @param input Plain text string to encrypt
   * @returns A string encrypted using KMS, encoded with Base64
   */
  async encryptString(input: string): Promise<string | undefined> {
    if (!(this.kms && this.kmsKeyArn && this.region)) {
      logError(new Error('Encryption service has not been initialised'));
      return undefined;
    }

    if (input.trim() === '') {
      return undefined;
    }

    const buffer = Buffer.from(input, 'utf-8');
    const encrypted = await this.kms
      .encrypt({ KeyId: this.kmsKeyArn, Plaintext: buffer })
      .promise()
      .catch(e => {
        logError(e);
        return undefined;
      });

    const result = encrypted?.CiphertextBlob?.toString('base64');
    return result?.trim() === '' ? undefined : result;
  }

  /**
   * Helper function to decrypt a base 64 encoded, KMS encrypted string into plaintext
   *
   * @param input base 64 encoded, KMS encrypted string
   * @returns Decrypted plaintext string
   */
  async decryptString(input: string): Promise<string | undefined> {
    if (!(this.kms && this.kmsKeyArn && this.region)) {
      const { isAdminDomain } = useAuthenticationMode();
      if (!isAdminDomain) {
        logError(new Error('Encryption service has not been initialised'));
      }
      return undefined;
    }

    if (input.trim() === '') {
      return undefined;
    }

    const base64Buffer = Buffer.from(input.toString(), 'base64');
    const decryptedBuffer = await this.kms
      .decrypt({ CiphertextBlob: base64Buffer, KeyId: this.kmsKeyArn })
      .promise()
      .catch(e => {
        logError(e);
        return undefined;
      });

    return decryptedBuffer?.Plaintext?.toString();
  }

  async encryptFile(
    buffer: Buffer,
    encryptionContext: { [key: string]: string }
  ): Promise<Uint8Array | undefined> {
    if (!(this.kms && this.kmsKeyArn && this.region && this.credentials)) {
      logError(new Error('Encryption service has not been initialised'));
      return new Uint8Array();
    }

    const generatorKeyId = this.kmsKeyArn;
    const keyIds: string[] = [this.kmsKeyArn];
    const clientProvider = getClient(KMS, { credentials: this.credentials });
    const keyring = new KmsKeyringBrowser({
      clientProvider,
      generatorKeyId,
      keyIds,
    });

    const { result } = await encrypt(keyring, buffer, {
      encryptionContext,
    }).catch(e => {
      logError(e);
      return { result: undefined };
    });

    return result;
  }

  async decryptFile(buffer: Buffer): Promise<Uint8Array | undefined> {
    const { isAdminDomain } = useAuthenticationMode();
    // there is no need to decrypted redacted files for the super admin
    if (isAdminDomain) return buffer;

    if (!(this.kms && this.kmsKeyArn && this.region && this.credentials)) {
      logError(new Error('Encryption service has not been initialised'));
      return new Uint8Array();
    }

    const generatorKeyId = this.kmsKeyArn;
    const keyIds: string[] = [this.kmsKeyArn];
    const clientProvider = getClient(KMS, { credentials: this.credentials });
    const keyring = new KmsKeyringBrowser({
      clientProvider,
      generatorKeyId,
      keyIds,
    });

    const result = await decrypt(keyring, buffer).catch(e => {
      logError(e);
      return { plaintext: undefined, messageHeader: undefined };
    });

    // TODO: We can/should verify the messageHeader.encryptionContext

    return result.plaintext;
  }
}

export default Encryption;

import React from 'react';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { TextArea } from '@webMolecules/TextArea/TextArea';
import { Button } from '@webMolecules/Button/Button';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import { LineItem } from '@webMolecules/LineItem/LineItem';
import { Input as CellInput } from '@webMolecules/LineItem/Cell/Input';
import { Select as CellSelect } from '@webMolecules/LineItem/Cell/Select';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import { Input } from '@webMolecules/Input/Input';
import { Modal } from '@webMolecules/Modal/Modal';
import { Divider } from '@webMolecules/Divider/Divider';
import * as I from '@interfaces/FilterConfiguration';
import { Checkbox } from '@webMolecules/Checkbox/Checkbox';
import { t } from '@webInterfaces/I18n';
import {
  availableStudyTypes,
  studyTypeToI18NKey,
  studyTypeToString,
} from '@interfaces/StudyType';
import { PROPERTY_COLUMN_OPTIONS } from '@interfaces/FilterConfiguration';
import { FeatureFlags } from '@entities/FeatureFlag';
import styles from './workspaceDetails.scss';

export interface WorkspaceDetailsProps {
  filterConfiguration: I.FilterConfiguration;
  featureFlags: FeatureFlags;
  onClose: () => void;
  onCreateFilterConfiguration: (
    filterConfiguration: I.FilterConfiguration
  ) => void;
  onUpdateFilterConfiguration: (
    filterConfiguration: I.FilterConfiguration
  ) => void;
}

const WorkspaceDetails: React.FC<WorkspaceDetailsProps> = ({
  filterConfiguration,
  featureFlags,
  onClose,
  onCreateFilterConfiguration,
  onUpdateFilterConfiguration,
}) => {
  const setTmpId = (
    p: I.FilterConfigurationProperty
  ): I.FilterConfigurationProperty => ({
    ...p,
    _tmpId: p._tmpId || uuid(),
    childProperties: p.childProperties
      ? p.childProperties.map(setTmpId)
      : p.childProperties,
  });

  const [state, setState] = React.useState<I.FilterConfiguration>({
    ...filterConfiguration,
    properties: filterConfiguration.properties.map(setTmpId),
  });

  const addProperty = (
    index: number | false = false,
    childIndex: number | false = false
  ) => {
    const newProperty: I.FilterConfigurationProperty = {
      _tmpId: uuid(),
      column: 'institution_name',
      operator: I.FilterConfigurationPropertyOperator.Contains,
      value: '',
    };

    setState({
      ...state,
      properties:
        index !== false
          ? state.properties.map((p, i) => ({
              ...p,
              childProperties:
                index === i
                  ? childIndex !== false
                    ? (p.childProperties || []).reduce(
                        (acc, p, ci) =>
                          ci === childIndex
                            ? [...acc, p, newProperty]
                            : [...acc, p],
                        [] as I.FilterConfigurationProperty[]
                      )
                    : [newProperty, ...(p.childProperties || [])]
                  : p.childProperties,
            }))
          : [...state.properties, newProperty],
    });
  };

  const removeProperty = (
    index: number,
    childIndex: number | false = false
  ) => {
    setState({
      ...state,
      properties:
        childIndex !== false
          ? state.properties.map((p, i) => ({
              ...p,
              childProperties:
                p.childProperties && i === index
                  ? p.childProperties.filter((_, ci) => ci !== childIndex)
                  : p.childProperties,
            }))
          : state.properties.filter((_, i) => i !== index),
    });
  };

  const updateProperty = (
    index: number,
    childIndex: number | false = false,
    property: I.FilterConfigurationProperty
  ) => {
    setState({
      ...state,
      properties:
        childIndex !== false
          ? state.properties.map((p, i) => ({
              ...p,
              childProperties:
                p.childProperties && i === index
                  ? p.childProperties.map((cp, ci) =>
                      ci === childIndex ? property : cp
                    )
                  : p.childProperties,
            }))
          : state.properties.map((p, i) => (i === index ? property : p)),
    });
  };

  const renderPropertyColumn = (
    property: I.FilterConfigurationProperty,
    index: number,
    childIndex: number | false = false
  ) => {
    const columnOptions = PROPERTY_COLUMN_OPTIONS.map(option => {
      return {
        label: t(`pages.settings.admin.workspaces.rules.option.${option}`),
        value: option,
      };
    });

    return (
      <CellSelect
        data-testid="admin-workspace-workspacedetails-col"
        label={childIndex !== false ? 'And' : undefined}
        fluid
        value={property.column}
        controlled={false}
        onChange={value => {
          updateProperty(index, childIndex, {
            ...property,
            column: value,
          });
        }}
        options={columnOptions}
      />
    );
  };

  const renderPropertyOperator = (
    property: I.FilterConfigurationProperty,
    index: number,
    childIndex: number | false = false
  ) => {
    const operatorToString = (
      operator: I.FilterConfigurationPropertyOperator
    ) => {
      switch (operator) {
        case I.FilterConfigurationPropertyOperator.Contains:
          return 'contains';
        case I.FilterConfigurationPropertyOperator.Matches:
          return 'matches';
      }
    };

    const stringToOperator = (str: string) => {
      switch (str) {
        default:
        case 'contains':
          return I.FilterConfigurationPropertyOperator.Contains;
        case 'matches':
          return I.FilterConfigurationPropertyOperator.Matches;
      }
    };

    return (
      <CellSelect
        data-testid="admin-workspace-workspacedetails-operator"
        fluid
        value={operatorToString(property.operator)}
        controlled={false}
        onChange={value => {
          updateProperty(index, childIndex, {
            ...property,
            operator: stringToOperator(value),
          });
        }}
        options={[
          {
            label: t('pages.settings.admin.workspaces.rules.option.contains'),
            value: 'contains',
          },
          {
            label: t('pages.settings.admin.workspaces.rules.option.matches'),
            value: 'matches',
          },
        ]}
      />
    );
  };

  const renderPropertyValue = (
    property: I.FilterConfigurationProperty,
    index: number,
    childIndex: number | false = false
  ) => {
    if (
      property.column == 'study_type' &&
      property.operator == I.FilterConfigurationPropertyOperator.Matches
    ) {
      return (
        <CellSelect
          data-testid="admin-workspace-workspacedetails-value"
          label={childIndex !== false ? 'And' : undefined}
          fluid
          value={property.value}
          controlled={false}
          onChange={value => {
            updateProperty(index, childIndex, {
              ...property,
              value,
            });
          }}
          options={availableStudyTypes().map(st => {
            return {
              label: t(studyTypeToI18NKey(st)),
              value: studyTypeToString(st),
            };
          })}
        />
      );
    }
    return (
      <CellInput
        data-testid="admin-workspace-workspacedetails-value"
        fluid
        defaultValue={property.value}
        onChange={event => {
          updateProperty(index, childIndex, {
            ...property,
            value: event.currentTarget.value,
          });
        }}
      />
    );
  };

  const onSetCustomWorkspace = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      isCustomWorkspace: e.target.checked,
      properties: e.target.checked ? [] : state.properties,
    });
  };

  const renderPropertyActions = (
    index: number,
    childIndex: number | false = false
  ) => (
    <Box display="flex" alignItems="center">
      <Button
        data-testid="admin-workspace-workspacedetails-remove-prop"
        circle
        size="small"
        onClick={() => removeProperty(index, childIndex)}
        iconBefore={<Icon name="minus-small" />}
      />
      <Button
        data-testid="admin-workspace-workspacedetails-add-prop"
        circle
        size="small"
        onClick={() => addProperty(index, childIndex)}
        iconBefore={<Icon name="plus-small" />}
      />
    </Box>
  );

  return (
    <Modal isOpen={true} size="medium" onClose={onClose}>
      <Card elevation="medium" padding="none">
        <Box
          data-testid="admin-workspace-workspacedetails"
          className={styles.modalBody}
          padding="xxl"
        >
          <Box className={styles.modalClose} padding="xl">
            <Button
              iconBefore={<Icon name="x" />}
              circle
              intent="neutral"
              size="small"
              onClick={onClose}
            />
          </Box>
          <Stack spacing="m">
            <Text type="display1" display="block" marginBottom="m">
              {state.id
                ? t('pages.settings.admin.workspace.title.edit')
                : t('pages.settings.admin.workspace.title.add')}
            </Text>
            <Box marginBottom="xs">
              <Text type="display6" display="block" marginBottom="xs">
                {t('pages.settings.admin.workspace.name')}
              </Text>
              <Input
                data-testid="admin-workspace-workspacedetails-name"
                fluid
                defaultValue={state.name}
                onChange={event => {
                  setState({ ...state, name: event.target.value });
                }}
              />
            </Box>

            <Box
              marginBottom="xs"
              data-testid="admin-workspace-workspacedetails-desc"
            >
              <Text type="display6" display="block" marginBottom="xs">
                {t('pages.settings.admin.workspace.description')}
              </Text>
              <TextArea
                proxiedKey="admin-workspace-description"
                fluid
                defaultValue={state.description}
                onBlur={event => {
                  setState({ ...state, description: event.target.value });
                }}
              />
            </Box>

            <Box marginBottom="xs">
              <Text type="display3" display="block" marginRight="m">
                {t('pages.settings.admin.workspace.rules.title')}
              </Text>
            </Box>

            <Schedule
              columns={[
                {
                  label: t(
                    'pages.settings.admin.workspace.rules.list.heading.column'
                  ),
                  width: '130px',
                },
                {
                  label: t(
                    'pages.settings.admin.workspace.rules.list.heading.operator'
                  ),
                  width: '130px',
                },
                {
                  label: t(
                    'pages.settings.admin.workspace.rules.list.heading.value'
                  ),
                },
                { label: '', width: '80px' },
              ]}
              items={state.properties.map((property, index) => (
                <Box key={property._tmpId}>
                  <LineItem
                    data-testid="admin-workspace-workspacedetails-line-item"
                    condensed
                    className={styles.lineItemRule}
                  >
                    {renderPropertyColumn(property, index)}
                    {renderPropertyOperator(property, index)}
                    {renderPropertyValue(property, index)}
                    {renderPropertyActions(index)}
                  </LineItem>
                  {(property.childProperties || []).map(
                    (childProperty, childIndex) => (
                      <LineItem
                        key={childProperty._tmpId}
                        condensed
                        className={styles.lineItemRule}
                      >
                        {renderPropertyColumn(childProperty, index, childIndex)}
                        {renderPropertyOperator(
                          childProperty,
                          index,
                          childIndex
                        )}
                        {renderPropertyValue(childProperty, index, childIndex)}
                        {renderPropertyActions(index, childIndex)}
                      </LineItem>
                    )
                  )}

                  {index !== state.properties.length - 1 && (
                    <Divider marginY="m" color="var(--ds-color-neutral-800)" />
                  )}
                </Box>
              ))}
            />
            <Button
              data-testid="admin-workspace-workspacedetails-add-rule"
              intent="primary"
              size="small"
              strong
              pill
              onClick={() => addProperty()}
            >
              {t('pages.settings.admin.workspace.add_rule')}
            </Button>
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingX="xxl"
          paddingY="l"
          className={styles.modalFooter}
        >
          {featureFlags.customWorkspaces && (
            <Checkbox
              onChange={e => onSetCustomWorkspace(e)}
              defaultChecked={state.isCustomWorkspace}
            >
              Set as custom workspace
            </Checkbox>
          )}
          <Button
            data-testid="admin-workspace-workspacedetails-cancel"
            strong
            onClick={onClose}
          >
            {t('pages.settings.admin.workspace.cancel')}
          </Button>
          <Box marginRight="m" />
          <Button
            data-testid="admin-workspace-workspacedetails-save"
            intent="primary"
            strong
            onClick={() => {
              if (state?.id) {
                onUpdateFilterConfiguration(state);
              } else {
                onCreateFilterConfiguration(state);
              }
              onClose();
            }}
          >
            {t('pages.settings.admin.workspace.save')}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default React.memo(WorkspaceDetails, isEqual);

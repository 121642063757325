import isUndefined from 'lodash/isUndefined';
import { store } from '@webInterfaces/Store';
import { toI18n } from '@interfaces/Root';
import { formatDate } from '@helpers/datetime';

export const APP_DATE_FORMAT = 'DD-MMM-YYYY';

export const t = (key: string, defTranslation?: string): string => {
  const translation = tSafe(key);
  return translation !== null
    ? translation
    : isUndefined(defTranslation)
      ? key
      : defTranslation;
};

export const tSafe = (key: string): null | string => {
  const translation = toI18n(store.getState()).translations.get(key);
  return isUndefined(translation) ? null : translation;
};

export const tExists = (key: string): boolean =>
  toI18n(store.getState()).translations.has(key);

export const tPrint = (
  key: string,
  data: string | number | Record<string, string | number>,
  defaultTranslation?: string
): string =>
  typeof data !== 'object'
    ? t(key, defaultTranslation).replace(
        new RegExp(`%${typeof data === 'string' ? 's' : 'd'}`),
        String(data)
      )
    : Object.keys(data).reduce(
        (prev, key) => prev.replace(new RegExp(key, 'g'), String(data[key])),
        t(key, defaultTranslation)
      );

export const tDate = (date: Date | string): string => {
  // consistent date format: 12/MAR/2023
  return formatDate(date, APP_DATE_FORMAT).toUpperCase();
};

import { EffectReducer, ReducerResult } from '@library/Reducer';
import { createUseCase } from '@helpers/createUseCase';
import { FindingInteraction, initial } from '@entities/FindingInteraction';
import {
  Finding,
  initialBreastFinding,
  initialThyroidFinding,
} from '@entities/Finding';
import { StudyType } from '@entities/StudyType';

export const findingInteractionUseCase = {
  Select: createUseCase('FINDING_INTERACTION_SELECT').withPayload<{
    id: string;
  }>(),

  Deselect: createUseCase('FINDING_INTERACTION_DESELECT').noPayload(),

  Create: createUseCase('FINDING_INTERACTION_CREATE').withPayload<{
    studyType: StudyType | undefined;
  }>(),

  Edit: createUseCase('FINDING_INTERACTION_EDIT').withPayload<{
    finding: Finding;
  }>(),

  Close: createUseCase('FINDING_INTERACTION_CLOSE').noPayload(),

  RemoveFilter: createUseCase('FINDING_INTERACTION_REMOVE_FILTER').noPayload(),

  HoverMeasurement: createUseCase(
    'FINDING_INTERACTION_HOVER_MEASUREMENT'
  ).withPayload<{ id: string | null }>(),

  Reset: createUseCase('FINDING_INTERACTION_RESET').noPayload(),

  SyncToFindings: createUseCase('FINDING_INTERACTION_SYNC').withPayload<{
    findings: Finding[];
  }>(),
};

export type FindingInteractionUseCases = ReturnType<
  | typeof findingInteractionUseCase.Select
  | typeof findingInteractionUseCase.Deselect
  | typeof findingInteractionUseCase.Create
  | typeof findingInteractionUseCase.Edit
  | typeof findingInteractionUseCase.Close
  | typeof findingInteractionUseCase.RemoveFilter
  | typeof findingInteractionUseCase.HoverMeasurement
  | typeof findingInteractionUseCase.Reset
  | typeof findingInteractionUseCase.SyncToFindings
>;

export class FindingInteractionReducer extends EffectReducer<FindingInteraction> {
  constructor() {
    super();
  }

  Perform(
    findingInteraction: FindingInteraction = initial(),
    { type, payload: useCase }: FindingInteractionUseCases
  ): ReducerResult<FindingInteraction> {
    switch (type) {
      case findingInteractionUseCase.Select.type: {
        if (!!findingInteraction.activeFinding) {
          // Can't change selection when something is active
          return this.Result(findingInteraction);
        }

        return this.Result({
          ...findingInteraction,
          selectedId: useCase.id,
          filterId: useCase.id,
        });
      }

      case findingInteractionUseCase.Deselect.type: {
        if (!!findingInteraction.activeFinding) {
          // Can't change selection when something is active
          return this.Result(findingInteraction);
        }
        return this.Result({
          ...findingInteraction,
          selectedId: undefined,
          filterId: undefined,
        });
      }

      case findingInteractionUseCase.Create.type: {
        const { studyType } = useCase;
        const activeFinding = (() => {
          switch (studyType) {
            case StudyType.SmallPartsThyroid:
              return initialThyroidFinding();
            case StudyType.SmallPartsBreast:
              return initialBreastFinding();
          }
        })();

        if (!activeFinding) {
          return this.Result(findingInteraction);
        }

        return this.Result({ ...findingInteraction, activeFinding });
      }

      case findingInteractionUseCase.Edit.type: {
        const { selectedId } = findingInteraction;
        // Deselect the id if it's not the one being made active
        const newSelectedId =
          selectedId !== useCase.finding.id ? undefined : selectedId;

        return this.Result({
          ...findingInteraction,
          activeFinding: useCase.finding,
          selectedId: newSelectedId,
          filterId: useCase.finding.id,
        });
      }

      case findingInteractionUseCase.Close.type: {
        const { selectedId } = findingInteraction;

        return this.Result({
          ...findingInteraction,
          activeFinding: undefined,
          filterId: selectedId,
        });
      }

      case findingInteractionUseCase.RemoveFilter.type: {
        // Removing the filter deselects but does not close the editing modal
        return this.Result({
          ...findingInteraction,
          filterId: undefined,
          selectedId: undefined,
        });
      }

      case findingInteractionUseCase.HoverMeasurement.type: {
        return this.Result({
          ...findingInteraction,
          hoverMeasurementId: useCase.id ?? undefined,
        });
      }

      case findingInteractionUseCase.Reset.type: {
        return this.Result({});
      }

      case findingInteractionUseCase.SyncToFindings.type: {
        const { filterId, selectedId } = findingInteraction;

        // Unset the interaction field if the finding is not present
        const syncToFindings = (id?: string) => {
          if (id && useCase.findings.map(f => f.id).includes(id)) {
            return id;
          } else {
            return undefined;
          }
        };

        return this.Result({
          ...findingInteraction,
          filterId: syncToFindings(filterId),
          selectedId: syncToFindings(selectedId),
        });
      }
    }
  }
}

import React from 'react';
import get from 'lodash/get';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuLink,
  MenuLinkProps,
  MenuItemProps,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Button } from '../Button/Button';
import { Box } from '../Box/Box';
import { Popover, usePopoverContext } from '../Popover/Popover';
import { Text } from '../Text/Text';
import styles from './dropdown.scss';

export enum DropdownMenuPosition {
  MiddleBottom = 'bottom',
  BottomRight = 'bottom-start',
  BottomLeft = 'bottom-end',
  Left = 'left',
  Right = 'right',
}

export interface IDropdownProps {
  size?: 'small' | 'medium' | 'large';
  trigger: JSX.Element;
  children?: React.ReactNode;
  position?: DropdownMenuPosition;
}

/**
 * Primary UI component for user interaction
 */
export const Dropdown: React.FC<IDropdownProps> = ({
  size,
  trigger,
  children,
  position = DropdownMenuPosition.BottomRight,
}) => {
  // Check if trigger is a DS Button
  const isButton = get(trigger, 'type', null) === Button;
  const { children: triggerChildren, ...triggerRest } = trigger.props;

  return (
    <Menu>
      <Popover
        padding="none"
        position={position}
        size={size}
        trigger={
          isButton ? (
            <MenuButton as={Button} {...triggerRest}>
              {triggerChildren}
            </MenuButton>
          ) : (
            <MenuButton>{trigger}</MenuButton>
          )
        }
      >
        <Box marginY="xs">
          <MenuItems as={Box} padding="none" className={styles.dropdownItems}>
            {children}
          </MenuItems>
        </Box>
      </Popover>
    </Menu>
  );
};

interface IDropdownItemProps extends MenuItemProps, IDropdownLinkItemProps {
  onSelect: () => void;
}

export const DropdownItem: React.FC<IDropdownItemProps> = ({
  children,
  iconBefore,
  muted,
  onSelect,
  condensed,
  ...menuItemProps
}) => {
  const { close } = usePopoverContext();
  const handleSelect = () => {
    close();
    onSelect();
  };

  return (
    <MenuItem
      {...menuItemProps}
      onSelect={handleSelect}
      className={styles.dropdownItem}
    >
      <DropdownLinkItem
        muted={muted}
        iconBefore={iconBefore}
        condensed={condensed}
        data-testid={menuItemProps['data-testid']}
      >
        {children}
      </DropdownLinkItem>
    </MenuItem>
  );
};

interface IDropdownLinkProps extends MenuLinkProps, IDropdownLinkItemProps {
  /**
   * The 'as' and 'to' props are supported by MenuLink, but it's supported
   * using some convoluted TypeScript/PolyMorphic/Forward ref magic, and it's
   * not as easy and importing and extending a type.
  /*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: any;
  to?: string;
}
export const DropdownLink: React.FC<IDropdownLinkProps> = ({
  children,
  iconBefore,
  muted,
  condensed,
  ...menuLinkProps
}) => {
  return (
    <MenuLink {...menuLinkProps} className={styles.dropdownItem}>
      <DropdownLinkItem
        muted={muted}
        iconBefore={iconBefore}
        condensed={condensed}
      >
        {children}
      </DropdownLinkItem>
    </MenuLink>
  );
};

interface IDropdownLinkItemProps {
  children: React.ReactNode;
  iconBefore?: JSX.Element;
  muted?: boolean;
  condensed?: boolean;
  'data-testid'?: string;
}

const DropdownLinkItem: React.FC<IDropdownLinkItemProps> = itemProps => {
  const { children, iconBefore, muted, condensed = false } = itemProps;
  return (
    <Box
      paddingY={condensed ? 'xs' : 's'}
      paddingX={condensed ? 'm' : 'l'}
      display="flex"
      alignItems="center"
      data-testid={itemProps['data-testid']}
    >
      {iconBefore && (
        <Box marginRight={condensed ? 'xs' : 's'}>{iconBefore}</Box>
      )}
      <Text muted={muted} type={condensed ? 'body2' : 'body'}>
        {children}
      </Text>
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import styles from './findingSizePill.scss';

interface FindingSizePillProps {
  value: number;
  referenceId: string;
}

export const FindingSizePill: React.FC<FindingSizePillProps> = ({
  value,
  referenceId,
}) => {
  const dispatchHoverMeasurement = useDispatcher(
    findingInteractionUseCase.HoverMeasurement
  );

  return (
    <Box
      paddingX="s"
      className={styles.pill}
      onMouseEnter={() => dispatchHoverMeasurement({ id: referenceId })}
      onMouseLeave={() => dispatchHoverMeasurement({ id: null })}
    >
      <Text type="display5">{value}</Text>
    </Box>
  );
};

import React from 'react';
import isEqual from 'lodash/isEqual';
import {
  AvatarGroup,
  AvatarGroupVariant,
} from '@webOrganisms/AvatarGroup/AvatarGroup';

export interface UsersProps {
  users: { color: string; name: string; initial: string }[];
}

export const UsersComponent: React.FC<UsersProps> = ({ users }) => {
  return (
    <AvatarGroup
      data={users}
      maxDisplay={2}
      size="small"
      variant={AvatarGroupVariant.Stack}
    />
  );
};

export const Users = React.memo(UsersComponent, (prev, next) => {
  return isEqual(prev, next);
});

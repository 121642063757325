import * as React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { Pill } from '@webMolecules/Pill/Pill';
import { Finding } from '@entities/Finding';
import { selectFindingTableConfig } from '@selectors/SessionSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { selectConfig } from '@selectors/BaseSelectors';
import { eventFindingActionModifiedCharacteristic } from '@webInterfaces/Analytics';
import {
  Characteristic,
  getCharacteristicsList,
} from './FindingSummaryRowPillsConfig';

interface CharacteristicPillProps {
  findingId: string;
  characteristic: Characteristic;
  indeterminate?: boolean;
  autoGenerated: boolean;
  editable?: boolean;
}

export const CharacteristicPill: React.FC<CharacteristicPillProps> = ({
  findingId,
  characteristic,
  indeterminate,
  autoGenerated,
  editable,
}) => {
  const updateFindingCharacteristic = useDispatcher(
    findingUseCase.UpdateFindingCharacteristic
  );
  const { data, path, config, displayValue, dotIntent } = characteristic;

  const onChange = (value: string | string[]) => {
    const key = path.replace('characteristics.', '');
    eventFindingActionModifiedCharacteristic(
      autoGenerated,
      {
        key,
        newValue: value,
        oldValue: data,
      },
      findingId
    );
    updateFindingCharacteristic({ findingId, path, value });
  };

  const options = config.options.map(option => ({
    value: option.value,
    label: t(option.editableI18NKey),
    separator: option.separator,
  }));
  const dotBefore = !!dotIntent;

  if (!editable) {
    if (displayValue) {
      return (
        <Pill
          content={t(displayValue)}
          dotBefore={dotBefore}
          dotIntent={dotIntent}
        />
      );
    } else if (indeterminate) {
      return (
        <Pill
          variant="hollow"
          content={t(config.label)}
          dotBefore={dotBefore}
          dotIntent={dotIntent}
        />
      );
    } else if (typeof data === 'string') {
      const option = options.find(o => o.value === data);
      return option ? (
        <Pill
          content={option?.label}
          dotBefore={dotBefore}
          dotIntent={dotIntent}
        />
      ) : null;
    }
  }

  return (
    <Box
      display="inline-flex"
      marginRight="xs"
      marginBottom="xs"
      onClick={e => e.stopPropagation()}
    >
      {typeof data === 'string' && (
        <MenuSelect
          variant={indeterminate ? 'pill-outline' : 'pill'}
          value={data}
          overrideLabel={indeterminate ? t(config.label) : undefined}
          options={options}
          onChange={onChange}
          icon="pen"
        />
      )}
      {Array.isArray(data) && (
        <MenuSelect
          variant="pill"
          value={data}
          overrideLabel={displayValue && t(displayValue)}
          options={options}
          onChange={onChange}
          onToggle={config.onToggle}
          icon="pen"
          header={t(config.label)}
          multiSelect
        />
      )}
    </Box>
  );
};

export interface FindingSummaryRowPillsProps {
  finding: Finding;
  editable?: boolean;
}

export const FindingSummaryRowPills: React.FC<FindingSummaryRowPillsProps> = ({
  finding,
  editable,
}) => {
  const institutionConfig = useSelector(selectConfig);
  const findingConfig = useSelector(selectFindingTableConfig);
  if (!findingConfig) return null;

  const { characteristics, indeterminateCharacteristics } =
    getCharacteristicsList(finding, findingConfig.config, institutionConfig);

  return (
    <Box>
      {characteristics.map((characteristic, i) => (
        <CharacteristicPill
          key={i}
          autoGenerated={finding.autoGenerated}
          findingId={finding.id}
          characteristic={characteristic}
          editable={editable}
        />
      ))}
      {indeterminateCharacteristics.length > 0 && (
        <Box>
          <Box paddingTop="xxs" paddingBottom="xs">
            <Text type="caption" muted>
              {t('config_key.result_table.thyroid_finding.indeterminate.label')}
            </Text>
          </Box>
          {indeterminateCharacteristics.map((characteristic, i) => (
            <CharacteristicPill
              key={i}
              autoGenerated={finding.autoGenerated}
              findingId={finding.id}
              characteristic={characteristic}
              indeterminate
              editable={editable}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

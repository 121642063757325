import React from 'react';
import { useController } from 'react-hook-form';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { ResultTableRadioConfig } from '@config/ResultTable/ResultTable';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';
import { CheckboxGroup, CheckboxProps } from '@webMolecules/Checkbox/Checkbox';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { getDisplayedOptionLabel } from '../FindingsTable/Shared';

interface ResultTableCellToggleGroupProps extends ResultTableSharedProps {
  checkboxConfig: ResultTableRadioConfig;
  checkboxKey: string;
  disabled?: boolean;
  onToggle?(oldValues: string[], newValues: string[]): string[];
}

export const ResultTableCellCheck: React.FC<
  ResultTableCellToggleGroupProps
> = ({ path, checkboxConfig, checkboxKey, disabled, onToggle }) => {
  const { theme } = React.useContext(ResultTableContext);
  const { field } = useController({
    name: path ?? '',
  });

  const checkedValues: string[] =
    Array.isArray(field.value) && !disabled ? field.value : [];

  if (!checkboxConfig.options) {
    return null;
  }

  const handleToggle = (values: string[]) => {
    const modifiedValues = onToggle ? onToggle(checkedValues, values) : values;
    return modifiedValues;
  };

  const addInputValue = (value: string) => {
    const updatedInputValue = [...checkedValues, value];
    return handleToggle(updatedInputValue);
  };

  const removeInputValue = (value: string) => {
    const updatedInputValue = [...checkedValues.filter(a => a !== value)];
    return handleToggle(updatedInputValue);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const checked = event.target.checked;
    const updatedCheckedValues = checked
      ? addInputValue(value)
      : removeInputValue(value);

    field.onChange(updatedCheckedValues);
  };

  if (theme == ResultTableStyle.preview) {
    const value = checkboxConfig.options
      .map(opt =>
        checkedValues.includes(opt.value) ? t(opt.previewI18NKey) : null
      )
      .filter(Boolean)
      .join(', ');

    return (
      <Text type="body2" lineClamp={1}>
        {value}
      </Text>
    );
  }

  const entries: CheckboxProps[] = checkboxConfig.options.map(option => ({
    key: `key-${checkboxKey}-${option.value}`,
    value: option.value,
    name: `${checkboxKey}-${option.value}`,
    id: `${checkboxKey}-${option.value}`,
    children: getDisplayedOptionLabel(
      option.value,
      t(option.editableI18NKey),
      checkboxConfig.scoreConfig
    ),
    onChange: onChange,
    checked: Boolean(checkedValues.find(a => a === option.value)),
    disabled: disabled,
    separator: option.separator,
  }));
  const checkboxWidth = `calc(${100 / (checkboxConfig.columns || 1)}% - 10px)`;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      fullWidth
    >
      <CheckboxGroup
        entries={entries}
        containerStyles={{
          flex: `0 0 ${checkboxWidth}`,
          flexWrap: 'wrap',
        }}
      />
    </Box>
  );
};

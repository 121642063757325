import { useMediaQuery } from 'react-responsive';

export enum ScreenWidth {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  None = 'none',
}
export const getScreenWidth = () => {
  const smallScreenMax = useMediaQuery({
    query: '(max-width: 1360px)',
  });
  const mediumScreenMin = useMediaQuery({
    query: '(min-width: 1360px)',
  });
  const mediumScreenMax = useMediaQuery({
    query: '(max-width: 1600px)',
  });
  const largeScreenMin = useMediaQuery({
    query: '(min-width: 1600px)',
  });

  if (smallScreenMax) {
    return ScreenWidth.Small;
  } else if (mediumScreenMin && mediumScreenMax) {
    return ScreenWidth.Medium;
  } else if (largeScreenMin) {
    return ScreenWidth.Large;
  } else {
    return ScreenWidth.Large;
  }
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Modal } from '@webMolecules/Modal/Modal';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Image } from '@webMolecules/Image/Image';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import styles from './aboutModal.scss';
import userManual from './about_consult.png';
import userManualPDF from './DC-SMART-19_Ver_1.8_Instructions_For_Use_-_EXTERNAL.pdf';
import barcode from './barcode-3.0.png';
import manufacturerDate from './manufacturer_date.svg';
import manufacturer from './manufacturer.svg';
import serialNumber from './serial_number.svg';

interface AboutModalProps {
  isOpen: boolean;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  onClose: () => void;
}

export const AboutModal = ({
  isOpen,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  onClose,
}: AboutModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Card padding="none" elevation="medium">
        <Box
          data-testid="about-modal"
          className={styles.modalBody}
          padding="xl"
        >
          <Box className={styles.modalClose} padding="s">
            <Button
              data-testid="test-about-modal-close"
              onClick={onClose}
              iconBefore={<Icon name="x" />}
              circle
              strong
            />
          </Box>
          <Stack spacing="l">
            <Text type="display2" display="block">
              {t('about.modal.heading')}
            </Text>
            <Text type="body2">{t('about.modal.body')}</Text>
            <Box>
              <a
                href="https://www.see-mode.com"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                <Text type="body2" display="block" marginBottom="s">
                  {t('about.modal.website_link')}
                </Text>
              </a>
              <a href="mailto:admin@see-mode.com" className={styles.link}>
                <Text
                  data-testid="about-modal-email"
                  type="body2"
                  display="block"
                >
                  {t('about.modal.email')}
                </Text>
              </a>
            </Box>
            <Box paddingTop="l">
              <Box
                data-testid="about-modal-manufacturer"
                marginBottom="l"
                display="flex"
                alignItems="center"
              >
                <Image
                  src={manufacturer}
                  roundedCorners={false}
                  width={48}
                  alt={t('about.modal.manufacturer.image_alt')}
                />
                <Box marginLeft="m">
                  <Text type="body2" display="block">
                    {t('about.modal.manufacturer.address.1')}
                  </Text>
                  <Text type="caption" display="block">
                    {t('about.modal.manufacturer.address.2')}
                  </Text>
                </Box>
              </Box>
              <Box marginBottom="l" display="flex" alignItems="center">
                <Image
                  src={manufacturerDate}
                  roundedCorners={false}
                  width={48}
                  alt={t('about.modal.manufactured.image_alt')}
                />
                <Box marginLeft="m">
                  <Text type="caption" display="block">
                    {regulatoryVersionModifiedDate}
                  </Text>
                </Box>
              </Box>
              <Box
                data-testid="about-modal-sn"
                marginBottom="l"
                display="flex"
                alignItems="center"
              >
                <Image
                  src={serialNumber}
                  roundedCorners={false}
                  width={48}
                  alt={t('about.modal.serial_number.image_alt')}
                />
                <Box marginLeft="m">
                  <Text type="caption" display="block">
                    v.{regulatoryVersion}
                  </Text>
                </Box>
              </Box>
              <Box marginBottom="l" display="flex" alignItems="center">
                <Image
                  src={userManual}
                  roundedCorners={false}
                  width={48}
                  alt={t('about.modal.user_manual.image_alt')}
                />
                <Box marginLeft="m">
                  <a href={userManualPDF} className={styles.link}>
                    <Text type="caption" display="block">
                      {t('about.modal.user_manual.label')}
                    </Text>
                  </a>
                </Box>
              </Box>
            </Box>
            <Text type="caption">{t('about.modal.copyright')}</Text>
            <Box className={styles.barcode} padding="s">
              <Image
                src={barcode}
                roundedCorners={false}
                fluid
                alt={t('about.modal.barcode.image_alt')}
              />
            </Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
};

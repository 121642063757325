import React, { useMemo } from 'react';
import { Box, BoxProps } from '../Box/Box';

export interface StackProps {
  children?: React.ReactNode;
  /**
   * Space between children
   */
  spacing:
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  /**
   * Stack Direction
   */
  direction?: 'horizontal' | 'vertical';
}

export const Stack = ({
  children,
  spacing,
  direction = 'vertical',
}: StackProps) => {
  const memoizedChildrenMap = useMemo(() => {
    const stackItemProps = (index: number): BoxProps => {
      const stackMargin =
        index === React.Children.count(children) - 1 ? 'none' : spacing;
      return direction === 'horizontal'
        ? {
            marginRight: stackMargin as BoxProps['marginRight'],
            display: 'inline-flex' as BoxProps['display'],
          }
        : {
            marginBottom: stackMargin,
          };
    };

    return React.Children.map(children, (child, index) => {
      return child ? <Box {...stackItemProps(index)}>{child}</Box> : null;
    });
  }, [children, spacing, direction]);

  return (
    <Box
      data-testid="stack-test-id"
      display="flex"
      flexDirection={direction == 'vertical' ? 'column' : 'row'}
      flexWrap={direction === 'horizontal' ? 'nowrap' : undefined}
    >
      {memoizedChildrenMap}
    </Box>
  );
};

import React from 'react';
import { cnames } from '@helpers/cnames';
import { Box } from '../Box/Box';
import styles from './skeleton.scss';

export interface SkeletonProps {
  animated?: boolean;
  size?: 'small' | 'medium' | 'large';
  width?: 'width-xs' | 'width-s' | 'width-m' | 'width-l' | 'width-xl';
  center?: boolean;
  absolute?: boolean;
  spaceAround?: boolean;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  animated = true,
  size = 'medium',
  center = false,
  width = 'width-m',
  absolute = true,
  spaceAround = false,
}) => {
  const skeletonClassNames = cnames(styles.skeleton, {
    [styles.animated]: animated,
    [styles[size]]: true,
    [styles.center]: center,
    [styles[width]]: true,
    [styles.absolute]: absolute,
    [styles.margin]: spaceAround,
  });

  return <Box className={skeletonClassNames} />;
};

import dayjs from 'dayjs';

export interface JSONResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// http://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html#sect_6.2.1.1
export const parseDicomName = (name: string | undefined) => {
  const [familyName, givenName, middleName, namePrefix, nameSuffix] = (
    name || ''
  ).split('^');

  const lastName = [namePrefix, familyName].filter(a => a).join(' ');

  const firstName = [givenName, middleName, nameSuffix]
    .filter(a => a)
    .join(' ');

  return [lastName, firstName].filter(a => a).join(', ');
};

export const cleanTag = (tag: string): string => {
  if (tag.length < 3) {
    return tag.toUpperCase();
  } else if (tag[0] === 'x') {
    return tag.substring(1).toUpperCase();
  } else if (tag.substring(0, 2) === '0x') {
    return tag.substring(2).toUpperCase();
  }
  return tag.toUpperCase();
};

export const getAge = (datasetJson: JSONResult) => {
  const dob_tag = tagsToRemove.patients_birth_date.tag;
  const age_tag = tagsToRemove.patients_age.tag;

  try {
    const age = datasetJson[age_tag];
    if (age && age.length === 4) {
      return age;
    }

    const dob = datasetJson[dob_tag];

    if (dob && dob.length === 8) {
      const parsedDob = dayjs(dob, 'YYYYMMDD');
      if (parsedDob.isValid()) {
        const ageInYears = dayjs().diff(parsedDob, 'years');

        if (ageInYears >= 90) {
          return '090Y';
        } else if (!isNaN(ageInYears)) {
          return `${ageInYears}Y`.padStart(4, '0');
        }
      }
    }
  } catch (err) {
    console.error('Error parsing age: ' + err);
  }
  return '';
};

export const tagsToRemove = {
  patient_name: {
    tag: 'x00100010',
    type: '2',
  },
  patient_id: {
    tag: 'x00100020',
    type: '2',
  },
  patients_birth_date: {
    tag: 'x00100030',
    type: '2',
  },
  patients_birth_time: {
    tag: 'x00100032',
    type: '3',
  },
  patients_birth_date_in_alternative_calendar_attribute: {
    tag: 'x00100033',
    type: '3',
  },
  patients_death_date_in_alternative_calendar_attribute: {
    tag: 'x00100034',
    type: '3',
  },
  patient_sex: {
    tag: 'x00100040',
    type: '2',
  },
  other_patient_names_attribute: {
    tag: 'x00101001',
    type: '3',
  },
  other_patient_ids_sequence_attribute: {
    tag: 'x00101002',
    type: '3',
  },
  referenced_patient_photo_sequence_attribute: {
    tag: 'x00101100',
    type: '3',
  },
  de_identification_method_attribute: {
    tag: 'x00120063',
    type: '1C',
  },
  patient_comments_attribute: {
    tag: 'x00104000',
    type: '3',
  },
  responsible_person: {
    tag: 'x00102297',
    type: '2C',
  },
  referring_physicians_name_attribute: {
    tag: 'x00080090',
    type: '2',
  },
  consulting_physicians_name_attribute: {
    tag: 'x0008009C',
    type: '3',
  },
  study_id_attribute: {
    tag: 'x00200010',
    type: '2',
  },
  additional_patient_history_attribute: {
    tag: 'x001021B0',
    type: '3',
  },
  performing_physicians_name_attribute: {
    tag: 'x00081050',
    type: '3',
  },
  image_comments: {
    tag: 'x00204000',
    type: '3',
  },
  persons_address: {
    tag: 'x00401102',
    type: '3',
  },
  patients_address: {
    tag: 'x00101040',
    type: '3',
  },
  person_phone_number: {
    tag: 'x00401103',
    type: '3',
  },
  persons_telecom_information: {
    tag: 'x00401104',
    type: '3',
  },
  name_of_physician_reading_study: {
    tag: 'x00081060',
    type: '3',
  },
  physician_of_record_identification_sequence: {
    tag: 'x00081049',
    type: '3',
  },
  physician_reading_study_identification_sequence: {
    tag: 'x00081062',
    type: '3',
  },
  referring_physician_identification_sequence: {
    tag: 'x00080096',
    type: '3',
  },
  reason_for_the_requested_procedure: {
    tag: 'x00401002',
    type: '3',
  },
  comments_on_the_performed_procedure_step: {
    tag: 'x00400280',
    type: '3',
  },
  patients_age: {
    tag: 'x00101010',
    type: '3',
  },
  // Clinical Trial:
  clinical_trial_sponsor_name: {
    tag: 'x00120010',
    type: '1',
  },
  clinical_trial_protocol_id: {
    tag: 'x00120020',
    type: '1',
  },
  clinical_trial_protocol_name: {
    tag: 'x00120021',
    type: '2',
  },
  clinical_trial_site_id: {
    tag: 'x00120030',
    type: '2',
  },
  clinical_trial_site_name: {
    tag: 'x00120031',
    type: '2',
  },
  clinical_trial_subject_id: {
    tag: 'x00120040',
    type: '1C',
  },
  clinical_trial_subject_reading_id: {
    tag: 'x00120042',
    type: '1C',
  },
  clinical_trial_protocol_ethics_committee_name: {
    tag: 'x00120081',
    type: '1C',
  },
  clinical_trial_protocol_ethics_committee_approval_number: {
    tag: 'x00120082',
    type: '3',
  },
};

export const tagsToRemoveAsArray = Object.values(tagsToRemove).map(
  ({ tag }) => tag
);

export const getScanTime = (dataset: JSONResult) => {
  let dateTime = '';

  const contentDateTag = '00080023';
  const acquisitionDateTag = '00080022';
  const acquisitionTimeTag = '00080032';
  const contentTimeTag = '00080033';
  try {
    const scanDate =
      getValueFromPydicomJson(dataset, acquisitionDateTag) ||
      getValueFromPydicomJson(dataset, contentDateTag);

    const scanTime =
      getValueFromPydicomJson(dataset, acquisitionTimeTag) ||
      getValueFromPydicomJson(dataset, contentTimeTag);

    if (scanDate && scanTime) {
      dateTime = scanDate + parseInt(scanTime).toString();
    }
  } catch (error) {
    console.error(`Failed to get scan time ${error}`);
  } finally {
    return dateTime;
  }
};

export const extractRegions = (dataset: JSONResult) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extractRegionOfImage = (region: any) => {
    const regionLocationMinX0Tag = '00186018';
    const regionLocationMinY0Tag = '0018601A';
    const regionLocationMaxX1Tag = '0018601C';
    const regionLocationMaxY1Tag = '0018601E';

    const regionSpatialFormatTag = '00186012';
    const physicalDeltaXTag = '0018602C';
    const physicalDeltaYTag = '0018602E';
    const referencePixelX0Tag = '00186020';
    const referencePixelY0Tag = '00186022';
    return {
      bounds: {
        // we want to return default positional value to dicoms to fail
        x1: getValueFromPydicomJson(region, regionLocationMinX0Tag) ?? 0,
        y1: getValueFromPydicomJson(region, regionLocationMinY0Tag) ?? 0,
        x2: getValueFromPydicomJson(region, regionLocationMaxX1Tag),
        y2: getValueFromPydicomJson(region, regionLocationMaxY1Tag),
      },
      spatial_format: getValueFromPydicomJson(region, regionSpatialFormatTag),
      scale_x: getValueFromPydicomJson(region, physicalDeltaXTag),
      scale_y: getValueFromPydicomJson(region, physicalDeltaYTag),
      origin_x: getValueFromPydicomJson(region, referencePixelX0Tag) || null,
      origin_y: getValueFromPydicomJson(region, referencePixelY0Tag) || null,
    };
  };

  const sequenceOfUltrasoundRegionsTag = '00186011';
  const sequenceOfUltrasoundRegions =
    dataset[sequenceOfUltrasoundRegionsTag]?.Value || [];

  return sequenceOfUltrasoundRegions.map(extractRegionOfImage);
};

export const getValueFromPydicomJson = (dataset: JSONResult, tag: string) => {
  if (!dataset[tag]) return undefined;

  return dataset[tag].Value[0] || undefined;
};

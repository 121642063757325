import React from 'react';
import { Field, FieldProps } from 'formik';
import { Input, AutoComplete } from '@webMolecules/Input/Input';
import { Checkbox } from '@webMolecules/Checkbox/Checkbox';
import {
  MenuSelect,
  MenuSelectProps,
} from '@webMolecules/MenuSelect/MenuSelect';

export enum FormInputTypes {
  Text = 'form_field_auth_types--text',
  Email = 'form_field_auth_types--email',
  Password = 'form_field_auth_types--password',
  Checkbox = 'form_field_auth_types--checkbox',
  Color = 'form_field_auth_types--color',
  Select = 'form_field_auth_types--select',
  Number = 'form_field_auth_types--number',
}

export interface FormInputProps extends Record<string, unknown> {
  autoComplete?: AutoComplete;
  type: FormInputTypes;
  error?: string;
  touched?: boolean;
  loading?: boolean;
  options?: Array<{ label: string; value: string }>;
  intent?: 'success' | 'neutral' | 'error' | 'required' | 'warning';
}

export const FormInput: React.FC<FormInputProps> = ({
  error,
  touched,
  loading = false,
  type = FormInputTypes.Text,
  options,
  intent: proxiedIntent,
  ...rest
}) => {
  const intent = proxiedIntent
    ? proxiedIntent
    : loading
      ? 'loading'
      : error && touched
        ? 'error'
        : null;
  const fieldProps = { ...rest, options };

  switch (type) {
    default:
    case FormInputTypes.Text:
      return <Field as={Input} type="text" intent={intent} {...fieldProps} />;
    case FormInputTypes.Email:
      return <Field as={Input} type="email" intent={intent} {...fieldProps} />;
    case FormInputTypes.Password:
      return (
        <Field as={Input} type="password" intent={intent} {...fieldProps} />
      );
    case FormInputTypes.Checkbox:
      return (
        <Field intent={intent} {...fieldProps}>
          {({ field, form }: FieldProps) => {
            return (
              <Checkbox
                {...fieldProps}
                onChange={value =>
                  form.setFieldValue(field.name, value.target.checked)
                }
                checked={field.value}
              />
            );
          }}
        </Field>
      );
    case FormInputTypes.Color:
      return <Field as={Input} type="color" intent={intent} {...fieldProps} />;
    case FormInputTypes.Select:
      return (
        <Field intent={intent} {...fieldProps}>
          {({ field, form }: FieldProps) => {
            return (
              <MenuSelect
                {...fieldProps}
                onChange={value => form.setFieldValue(field.name, value)}
                value={field.value}
                options={options ?? []}
                intent={intent as MenuSelectProps['intent']}
              />
            );
          }}
        </Field>
      );
    case FormInputTypes.Number:
      return <Field as={Input} type="number" intent={intent} {...fieldProps} />;
  }
};

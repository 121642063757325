import { nothing } from '@monads/Maybe';
import * as Contract from '@contracts/Admin/Institutions';
import { Institution } from '@entities/Admin/Institution';
import {
  InstitutionLineItem,
  InstitutionRegion,
  InstitutionStatus,
} from '@entities/Admin/InstitutionLineItem';
import { Profile } from '@interfaces/Profile';
import { post } from '@api/schema/client';
import { encodeInstitutionRegion } from '@api/decoders/Institution';
import {
  encodeOperatorMappingProperties,
  decodeSync,
} from '../decoders/Profile';

export class Institutions implements Contract.Institutions {
  async List(): Promise<InstitutionLineItem[]> {
    const data = await post('/global-admin/list-institutions', {});

    return data.institutions.map(institution => {
      const status = institution.deployed
        ? InstitutionStatus.Deployed
        : InstitutionStatus.NotDeployed;

      return {
        id: institution.institution_id,
        institutionCopyText: institution.institution_login_copy,
        region: institution.native_region,
        status,
        syncing: nothing<Date>(),
      };
    });
  }

  async Get(id: string): Promise<Institution> {
    const data = await post('/global-admin/list-institutions', {});

    const institution = data.institutions.find(i => i.institution_id === id);
    if (!institution) {
      throw new Error(`Institution ${id} not found`);
    }

    const users = await this.ListUsers(id);

    return {
      id: institution.institution_id,
      institutionCopyText: institution.institution_login_copy,
      users,
    };
  }

  async Create(args: {
    id: string;
    institutionCopyText: string;
    region: InstitutionRegion;
  }): Promise<string> {
    await post('/global-admin/create-institution', {
      body: {
        institution_id: args.id,
        institution_login_copy: args.institutionCopyText,
        native_region: encodeInstitutionRegion(args.region),
      },
    });

    return args.id;
  }

  async CreateUser(institutionId: string, user: Profile): Promise<'success'> {
    await post('/global-admin/create-institution-user', {
      body: {
        institution_id: institutionId,
        email: user.email,
        is_admin: user.isAdmin,
        operator_mapping: encodeOperatorMappingProperties(
          user.operatorMappingProperties
        ),
      },
    });

    return 'success';
  }

  async DeleteUser(institutionId: string, id: string): Promise<'success'> {
    await post('/global-admin/delete-institution-user', {
      body: {
        institution_id: institutionId,
        user_profile_id: id,
      },
    });

    return 'success';
  }

  async ListUsers(institutionId: string): Promise<Profile[]> {
    try {
      const data = await post('/global-admin/list-institution-users', {
        body: {
          institution_id: institutionId,
        },
      });

      const users = data.user_profiles.map(a => decodeSync(a));

      return users;
    } catch (e) {
      return [];
    }
  }
}

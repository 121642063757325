import React, { useState, useEffect } from 'react';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';

const POLL_INTERVAL_MS = 300000; // Poll every 5 minutes

export const VersionCheck = () => {
  const [currentHash, setCurrentHash] = useState<string | null>(null);
  const [updateDetected, setUpdateDetected] = useState(false);

  const checkForUpdates = async () => {
    try {
      const response = await fetch('/index.html', { cache: 'no-cache' });
      const fetchedHtml = await response.text();

      // Webpack adds a unique hash to the main.js file,
      // so this will be different if a new version was deployed.
      const fetchedHash = fetchedHtml.match(/main\.(\w+)\.js/)?.[0] ?? null;

      if (currentHash === null) {
        setCurrentHash(fetchedHash);
      } else if (currentHash !== fetchedHash) {
        setUpdateDetected(true);
      }
    } catch (e) {
      // Fail quietly if the fetch is unsuccessful
    }
  };

  useEffect(() => {
    checkForUpdates();
    const interval = setInterval(checkForUpdates, POLL_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [currentHash]);

  return (
    <DialogConfirm
      isOpen={updateDetected}
      onConfirm={() => window.location.reload()}
      onCancel={() => null}
      labelOk={t('version_check.modal.button')}
      intent="primary"
    >
      <Text type="display2" display="block" marginBottom="m">
        {t('version_check.modal.title')}
      </Text>
      <Text renderHtml display="block">
        {t('version_check.modal.text')}
      </Text>
    </DialogConfirm>
  );
};

import React from 'react';
import isEqual from 'lodash/isEqual';
import { Icon } from '@webMolecules/Icon/Icon';
import { LineItem, LineItemProps } from '@webMolecules/LineItem/LineItem';
import { Text, TextMuted } from '@webMolecules/LineItem/Cell/Text';
import { titleCase } from '@helpers/string';
import { t } from '@webInterfaces/I18n';
import { userStatusToString, Profile } from '@interfaces/Profile';

export interface UserLineItemProps {
  href: string;
  userLineItem: Profile;
  profile: Profile;
  onDeleteUser: (userLineItem: Profile) => void;
}

const UserLineItemList = ({
  href,
  userLineItem,
  profile,
  onDeleteUser,
}: UserLineItemProps): React.ReactElement<LineItemProps> => {
  const isOwnAccount = userLineItem.email === profile.email;
  const contextMenu = isOwnAccount
    ? undefined
    : [
        {
          label: t('pages.settings.users.item.dropdown.remove_user'),
          icon: <Icon name="trash" />,
          onClick: () => onDeleteUser(userLineItem),
        },
      ];

  return (
    <LineItem contextMenu={contextMenu} href={href}>
      <Text>{userLineItem.email}</Text>
      <TextMuted>{userStatusToString(userLineItem.isActive)}</TextMuted>
      <TextMuted>
        {titleCase(`${userLineItem.firstName} ${userLineItem.lastName}`)}
      </TextMuted>

      <TextMuted>
        {userLineItem.isAdmin
          ? t('pages.settings.user_info.permission.admin')
          : t('pages.settings.user_info.permission.user')}
      </TextMuted>
    </LineItem>
  );
};

export const MemoizedUserlistLineItem = React.memo(UserLineItemList, isEqual);

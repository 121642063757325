import React from 'react';
import { useController } from 'react-hook-form';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import {
  ResultTableRadioConfig,
  ResultTableToggleOption,
} from '@config/ResultTable/ResultTable';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';
import {
  RadioButtonProps,
  RadioGroup,
} from '@webMolecules/RadioButton/RadioButton';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { getDisplayedOptionLabel } from '../FindingsTable/Shared';

interface ResultTableCellRadioGroupProps extends ResultTableSharedProps {
  radioConfig: ResultTableRadioConfig;
  radioKey: string;
  disabled?: boolean;
}

export const ResultTableCellRadioGroup: React.FC<
  ResultTableCellRadioGroupProps
> = ({ path, radioConfig, radioKey }) => {
  const { theme } = React.useContext(ResultTableContext);
  const { field } = useController({
    name: path ?? '',
  });

  const checkEnteries =
    radioConfig.options && radioConfig.options.length > 0
      ? radioConfig.options
      : [];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  if (theme == ResultTableStyle.preview) {
    const option = checkEnteries.find(option => option.value === field.value);
    return option ? (
      <Text type="body2" lineClamp={1}>
        {t(option.previewI18NKey)}
      </Text>
    ) : null;
  }

  const entries: RadioButtonProps[] = checkEnteries.map(
    (option: ResultTableToggleOption) => ({
      key: `key-${option.value}`,
      value: option.value,
      groupName: `${radioKey}`,
      id: `${radioKey}-${option.value}`,
      children: getDisplayedOptionLabel(
        option.value,
        t(option.editableI18NKey),
        radioConfig.scoreConfig
      ),
      onChange: onChange,
      onClick:
        field.value === option.value
          ? () => field.onChange(undefined)
          : () => null,
      checked: field.value === option.value,
      tooltipI18NKey: option.previewI18NKey,
      captionI18NKey: option.captionI18NKey,
    })
  );

  const radioWidth = `calc(${100 / (radioConfig.columns || 1)}% - 10px)`;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      fullWidth
    >
      <RadioGroup
        entries={entries}
        containerStyles={{
          flex: `0 0 ${radioWidth}`,
          flexWrap: 'wrap',
        }}
      />
    </Box>
  );
};

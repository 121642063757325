import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Finding } from '@entities/Finding';
import styles from './comparison.scss';
import { ComparisonButton } from './ComparisonButton';
import { ComparisonItem } from './ComparisonItem';
import { DraggableFinding } from './ComparisonDragAndDrop';

export interface UnassignedComparisonRowProps {
  isExpand: boolean;
  finding: Finding;
  totalItems: number;
  onClickFinding?(findingId: string): void;
}
export const UnassignedComparisonRow: React.FC<
  UnassignedComparisonRowProps
> = ({ isExpand, finding }) => {
  const ref = useRef<HTMLDivElement>(null);
  const previousPositionY = useRef<number | null>(null);
  const controls = useAnimation();
  const [isOpen, setIsOpen] = React.useState<boolean>(isExpand);

  useEffect(() => {
    setIsOpen(isExpand);
  }, [isExpand]);

  // Whenever the row position changes after a render, calculate the difference
  // from the previous position and translate it by that amount before
  // animating towards zero offset. This produces a smooth transition whenever
  // rows are added or removed from the list.
  useEffect(() => {
    const currentPositionY = ref.current?.offsetTop ?? 0;
    if (previousPositionY.current) {
      const deltaY = currentPositionY - previousPositionY.current;
      if (Math.abs(deltaY) > 0.01) {
        controls.set({
          y: -deltaY,
        });
        controls.start({
          y: 0,
          transition: { type: 'ease', ease: 'easeInOut', duration: 0.2 },
        });
      }
    }

    previousPositionY.current = currentPositionY;
  });

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.div
      ref={ref}
      key={finding.id}
      animate={controls}
      className={styles.innerUnassignedComparisonRow}
      style={{ zIndex: ref.current ? 0 : 999 }}
    >
      <DraggableFinding findingId={finding.id}>
        <ComparisonItem
          finding={finding}
          isExpanded={isOpen}
          isDraggable={!isOpen}
          isPrevious
        />
      </DraggableFinding>
      <ComparisonButton
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        previousConnectorActive={true}
        currentConnectorActive={false}
      />
    </motion.div>
  );
};

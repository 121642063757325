import { AnalyticsEvent } from '@contracts/Analytics';
import { analytics } from '@segmentio/Analytics';
import { Profile } from '@interfaces/Profile';
import Maybe from '@monads/Maybe';
import { State } from '@webInterfaces/StoreTypes';
import { getState } from '@webInterfaces/Store';

export const identify = (profile: Profile) => {
  analytics().Identify(profile);
};

export const event = (
  f: AnalyticsEvent | ((state: State) => Maybe<AnalyticsEvent>)
) => {
  try {
    if (typeof f === 'function') {
      f(getState()).map(analytics().Event);
    } else {
      analytics().Event(f);
    }
  } catch (err) {}
};

import { Dispatch } from 'redux';
import { toApp } from '@interfaces/Root';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { toSession } from '@interfaces/App';
import { SessionConfiguration } from '@entities/SessionLineItem';
import { updateConfiguration } from '@webViewInterfaces/Study/Session';
import { ColourPalette } from '@appCanvas/interfaces/types';
import { StudyType } from '@entities/StudyType';
import { ApiStatus } from '@entities/Status';

export type ReportDiagram = ReportDiagramProps & ReportDiagramDispatchers;

export interface ProxiedReportDiagramProps {
  studyType?: StudyType;
  canvasColorPalette: ColourPalette;
  showCanvasOnly: boolean;
  isPrevious?: boolean;
  onlyShowMatchedLesion?: boolean;
  disableInteractions?: boolean;
  setShowGeneralTable?: (show: boolean) => void;
}

export interface ReportDiagramProps extends ProxiedReportDiagramProps {
  loading: boolean;
  sessionConfiguration?: SessionConfiguration;
}

export interface ReportDiagramDispatchers {
  dispatchUpdateConfiguration: (
    configuration: SessionConfiguration
  ) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  dispatchUpdateConfiguration: (configuration: SessionConfiguration) =>
    dispatch(updateConfiguration(configuration)),
});

export const fromState = (
  state: State,
  {
    studyType,
    canvasColorPalette,
    showCanvasOnly,
    disableInteractions,
  }: ProxiedReportDiagramProps
): ReportDiagramProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const session = toSession(app);

  const configuration = session.details.mapOr(
    a => JSON.parse(JSON.stringify(a.configuration)) as SessionConfiguration,
    undefined
  );

  return {
    studyType,
    canvasColorPalette,
    loading: session.status !== ApiStatus.Idle,
    sessionConfiguration: configuration,
    showCanvasOnly,
    disableInteractions,
  };
};

import React from 'react';
import { cnames } from '@helpers/cnames';
import { Box, BoxProps } from '../Box/Box';
import styles from './card.scss';

export interface CardProps {
  alignItems?:
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline';
  children?: React.ReactNode;
  display?:
    | 'flex'
    | 'inline-flex'
    | 'block'
    | 'inline-block'
    | 'inline'
    | 'none';
  elevation?: 'low' | 'medium' | 'high';
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
  flex?: string;
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  scrollable?: BoxProps['scrollable'];
  transparent?: boolean;
  padding?:
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  'data-testid'?: string;
}

export const Card: React.FC<CardProps> = props => {
  const {
    children,
    elevation = 'low',
    padding = 'm',
    transparent = false,
    display,
    scrollable,
    flex,
    alignItems,
    justifyContent,
    flexDirection,
    flexWrap,
  } = props;

  const classNames = cnames(styles.card, styles[`elevation-${elevation}`], {
    [styles.transparent]: transparent,
  });

  return (
    <Box
      data-testid={props['data-testid'] || 'card-test-id'}
      className={classNames}
      padding={padding}
      display={display}
      flex={flex}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      scrollable={scrollable}
    >
      {children}
    </Box>
  );
};

import * as Contract from '@contracts/DicomAnonymiser';
import { encodeSha256 } from '@helpers/cryptoSha256';

export class DicomAnonymiser implements Contract.DicomAnonymiser {
  ToDicomFile(institutionId: string, file: File): Promise<Contract.DicomFile> {
    return new Promise(async (resolve, reject) => {
      const {
        loadDICOMImageResource,
        createAnonymisedDicomFileFromDataset,
        getMetaDataFromJsonObject,
      } = await import(/* webpackChunkName: "cornerstone" */ './Cornerstone');

      if (file.size === 0) {
        reject('File is empty');
        return;
      }
      const dicomLoadTimeout = setTimeout(() => {
        reject(new Error(`Load dicom timed out - ${file.name}`));
      }, 2500);
      const rejectDicomToFile = (a: unknown) => {
        clearTimeout(dicomLoadTimeout);
        reject(a);
      };
      try {
        const loadedDicomImage = await loadDICOMImageResource(file);

        // @TODO what if it isn't "loaded"?
        if (loadedDicomImage) {
          const anonymisedJsonObject = createAnonymisedDicomFileFromDataset(
            loadedDicomImage.dicomInfo.data
          );

          if (anonymisedJsonObject) {
            const metadata = getMetaDataFromJsonObject(anonymisedJsonObject);
            const smPatientId =
              loadedDicomImage.decryptedPatientId &&
              loadedDicomImage.decryptedPatientId.length > 0
                ? await encodeSha256(
                    institutionId,
                    loadedDicomImage.decryptedPatientId
                  )
                : '';
            const dir = loadedDicomImage.parentAnalysis;
            const institutionName = loadedDicomImage.institutionName;
            const name = loadedDicomImage.filename;
            const studyDescription = loadedDicomImage.studyDescription;
            const operatorCode = loadedDicomImage.operatorCode;
            const stationName = loadedDicomImage.stationName;
            const decryptedPhi = loadedDicomImage.decryptedPhi;
            const dicomStudyDate = loadedDicomImage.dicomStudyDate;
            const dicomStudyTime = loadedDicomImage.dicomStudyTime;
            clearTimeout(dicomLoadTimeout);
            return resolve({
              dir,
              institutionName,
              name,
              studyDescription,
              operatorCode,
              stationName,
              decryptedPhi,
              smPatientId,
              dicomStudyDate,
              dicomStudyTime,
              metadata: JSON.stringify(metadata),
              sopInstanceUid: metadata?.sop_instance_uid,
              anonymisedImageBlob: loadedDicomImage.annoCanvasBlob as Blob,
            });
          }
        }
        rejectDicomToFile(JSON.stringify(loadedDicomImage));
      } catch (e) {
        rejectDicomToFile(e);
      }
    });
  }
}

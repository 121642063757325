import { Action } from 'redux';
import { ApiError } from '@api/schema/client';
import { just } from '@monads/Maybe';
import { Root, initial } from '@entities/Root';
import { Profile } from '@entities/Profile';
import * as App from '@entities/App';
import Reducer, { ReducerResult } from '@library/Reducer';
import { none } from '@library/Effect';
import { Authenticate as ContractAuthenticate } from '@contracts/Authenticate';
import { Institutions as ContactInstitutions } from '@contracts/Admin/Institutions';
import { DicomAnonymiser as ContractDicomAnonymiser } from '@contracts/DicomAnonymiser';
import { Analyses as ContractAnalyses } from '@contracts/Analyses';
import { Encryption as ContractEncryption } from '@contracts/Encryption';
import { FilterConfiguration as ContractFilterConfiguration } from '@contracts/Admin/FilterConfiguration';
import { I18n as ContractI18n } from '@contracts/I18n';
import { Location as ContractLocation } from '@contracts/Location';
import { PDF as ContractPDF } from '@contracts/PDF';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { Search as ContractSearch } from '@contracts/Search';
import { Session as ContractSession } from '@contracts/Session';
import { Storage as ContractStorage } from '@contracts/Storage';
import { Workspaces as ContractWorkspaces } from '@contracts/Workspaces';
import { PACSStudy as ContractPACSStudy } from '@contracts/PACSStudy';
import { AppConfiguration as ContractAppConfiguration } from '@contracts/AppConfiguration';
import { FeatureFlags as ContractFeatureFlags } from '@contracts/FeatureFlags';
import { Institution as ContractInstitution } from '@contracts/Admin/Institution';
import { Message } from '@entities/Message';
import { identify } from '@helpers/analytics';
import { addOfflineErrorNotification } from '@interfaces/Notifications';
import { effectErrorToMaybeNotification } from '@interfaces/Root';
import { I18n } from '@entities/I18n';
import { createUseCase } from '@helpers/createUseCase';
import { LocationReducer, locationUseCase } from './Location';
import { analysisUploadsUseCase } from './AnalysisUploads';
import { I18nReducer, i18nUseCase } from './I18n';
import { AuthReducer, authenticateUseCase } from './Authentication';
import { AppReducer, appUseCase } from './App';

export const rootUseCase = {
  /*
   * Init
   */
  Init: createUseCase('ROOT_INIT').withPayload<{
    dispatch: (action: Action) => void;
  }>(),

  /*
   * Init App
   */
  InitApp: createUseCase('ROOT_INIT_APP').withPayload<{
    profile: Profile;
  }>(),

  /*
   * Clear
   */
  Clear: createUseCase('ROOT_CLEAR').noPayload(),

  /**
   * Add a new Message
   */
  AddMessage: createUseCase('ROOT_ADD_MESSAGE').withPayload<{
    message: Message;
    clearOthers?: boolean;
  }>(),

  /**
   * Clear messages
   */
  ClearMessages: createUseCase('ROOT_CLEAR_MESSAGES').noPayload(),

  /*
   * Redirect to path
   */
  Redirect: createUseCase('ROOT_REDIRECT').withPayload<{
    pathname: string;
    replace?: boolean;
  }>(),

  /*
   * Reload path
   */
  Reload: createUseCase('ROOT_RELOAD').withPayload<{ path?: string }>(),

  /*
   * Reload i18n locale
   */
  ReloadLocale: createUseCase('ROOT_RELOAD_LOCALE').withPayload<{
    locale?: string;
  }>(),

  /*
   * Directly set state
   */
  SetState: createUseCase('ROOT_SET_STATE').withPayload<{
    state: Root;
  }>(),

  /**
   * Respond to any error caused by an effect
   */
  OnErrorEffect: createUseCase('ROOT_ON_ERROR_EFFECT').withPayload<{
    state: Root;
    label: string;
    error: Error;
  }>(),
};

/**
 * All UseCases
 */

export type RootUseCases = ReturnType<
  | typeof rootUseCase.Redirect
  | typeof rootUseCase.Reload
  | typeof rootUseCase.AddMessage
  | typeof rootUseCase.ClearMessages
  | typeof rootUseCase.ReloadLocale
  | typeof rootUseCase.Clear
  | typeof rootUseCase.Init
  | typeof rootUseCase.InitApp
  | typeof rootUseCase.SetState
  | typeof rootUseCase.OnErrorEffect
>;

/**
 * Reducer
 */
export class RootReducer extends Reducer<Root> {
  private i18nReducer: I18nReducer;
  private authReducer: AuthReducer;
  private locationReducer: LocationReducer;
  private makeAppReducer: (profile: Profile) => AppReducer;

  constructor(implementations: {
    authenticate: ContractAuthenticate;
    institutions: ContactInstitutions;
    dicomAnonymiser: ContractDicomAnonymiser;
    analyses: ContractAnalyses;
    filterConfiguration: ContractFilterConfiguration;
    i18n: ContractI18n;
    location: ContractLocation;
    pdf: ContractPDF;
    profiles: ContractProfiles;
    search: ContractSearch;
    session: ContractSession;
    storage: ContractStorage;
    users: ContractProfiles;
    userEncryption: ContractEncryption;
    workspaces: ContractWorkspaces;
    pacsStudy: ContractPACSStudy;
    appConfiguration: ContractAppConfiguration;
    featureFlags: ContractFeatureFlags;
    institution: ContractInstitution;
  }) {
    super();
    this.authReducer = new AuthReducer(
      implementations.authenticate,
      implementations.storage
    );
    this.i18nReducer = new I18nReducer(implementations.i18n);
    this.locationReducer = new LocationReducer(implementations.location);
    this.makeAppReducer = (profile: Profile) => {
      return new AppReducer(implementations, profile);
    };

    this.CreateSubReducer<I18n>(
      this.i18nReducer,
      root => root.i18n,
      (root, i18n) => ({ ...root, i18n })
    );

    this.CreateSubReducer<Root>(
      this.locationReducer,
      root => root,
      root => root
    );

    this.CreateSubReducer<Root['auth']>(
      this.authReducer,
      root => root.auth,
      (root, auth) => ({ ...root, auth })
    );
  }

  private dispatch?: (useCase: Action) => void;

  Perform(root: Root = initial(), action: Action): ReducerResult<Root> {
    const { type, payload: useCase } = action as RootUseCases;

    switch (type) {
      case rootUseCase.Init.type:
        this.dispatch = useCase.dispatch;
        return this.Result(root, none(), [
          i18nUseCase.Init(),
          authenticateUseCase.Init(),
        ]);

      case rootUseCase.InitApp.type: {
        identify(useCase.profile);

        this.CreateSubReducer<App.App>(
          this.makeAppReducer(useCase.profile),
          root => root.app,
          (root, app) => ({ ...root, app: just(app) })
        );

        return this.Result(
          {
            ...root,
            app: just(App.initial(useCase.profile)),
          },
          none(),
          [
            appUseCase.Init({
              dispatch: action => {
                if (!this.dispatch) {
                  // @TODO Fix this state
                  throw new Error('dispatch is not set');
                }
                this.dispatch(action);
              },
            }),
          ]
        );
      }

      case rootUseCase.AddMessage.type:
        return this.Result({
          ...root,
          messages:
            useCase.clearOthers ?? true
              ? [useCase.message]
              : [useCase.message, ...root.messages],
        });

      case rootUseCase.ClearMessages.type:
        return this.Result({
          ...root,
          messages: [],
        });

      case rootUseCase.Clear.type:
        return this.Result(root, none(), [analysisUploadsUseCase.Clear()]);

      case rootUseCase.Redirect.type:
        return this.Result(root, none(), [
          locationUseCase.Update({
            pathname: useCase.pathname,
            replace: useCase.replace,
          }),
        ]);

      case rootUseCase.Reload.type:
        return this.Result(root, none(), [
          locationUseCase.Reload({ path: useCase.path }),
        ]);

      case rootUseCase.ReloadLocale.type:
        return this.Result(root, none(), [
          i18nUseCase.LoadTranslations({ locale: useCase.locale }),
        ]);

      case rootUseCase.SetState.type:
        return this.Result({ ...useCase.state });

      case rootUseCase.OnErrorEffect.type: {
        if (useCase.error instanceof ApiError && useCase.error.isAuthError) {
          return this.Result(root, none(), [appUseCase.Reload({})]);
        }

        const batch: Action[] = [
          rootUseCase.SetState({ state: useCase.state }),
        ];
        if (window.navigator.onLine) {
          const notification = effectErrorToMaybeNotification(
            useCase.label
          ).lift();
          if (notification) {
            batch.push(notification);
          }
        } else {
          batch.push(addOfflineErrorNotification());
        }
        return this.Result(useCase.state, none(), batch);
      }

      default: {
        return this.SubReduce(root, action);
      }
    }
  }
}

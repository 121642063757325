import React, { useState } from 'react';
import { Button } from '@webMolecules/Button/Button';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import FindingSummaryRow from '@webOrganisms/ResultTable/FindingsTable/FindingSummaryRow';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import { Checkbox } from '@webMolecules/Checkbox/Checkbox';
import { cnames } from '@helpers/cnames';
import { FilterPill } from '@webMolecules/FilterPill/FilterPill';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { Finding } from '@entities/Finding';
import { FindingFilterType } from '@config/ResultTable/FindingTable';
import styles from './findingSelectPanel.scss';

export interface FindingSelectPanel {
  initialSelectedIds: string[];
  findings: Finding[];
  handleClose(): void;
}

export const FindingSelectPanel: React.FC<FindingSelectPanel> = ({
  initialSelectedIds,
  findings,
  handleClose,
}) => {
  const dispatchFilterFindings = useDispatcher(findingUseCase.FilterFindings);
  const [selectedIds, setSelectedIds] = useState(initialSelectedIds);

  const handleConfirm = () => {
    dispatchFilterFindings({
      filterType: FindingFilterType.IncludeSelected,
      selectedIds,
    });
    handleClose();
  };

  const handleSelectAll = () => {
    setSelectedIds(findings.map(finding => finding.id));
  };

  const handleUnselectAll = () => {
    setSelectedIds([]);
  };

  return (
    <Box className={styles.container}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        fullWidth
        padding="m"
        className={styles.header}
      >
        <Button
          circle
          strong
          size="medium"
          onClick={handleClose}
          iconBefore={<Icon name="x" />}
        />
        <Text type="display2" display="block" marginLeft="m">
          {t('pages.study.finding.filter.select.heading')}
        </Text>
      </Box>
      <Box display="flex" paddingX="l" gap="s" alignItems="center">
        <Box className={styles.selectedCount}>
          <Text type="body2">{selectedIds.length} Findings Selected</Text>
        </Box>
        <Box display="flex" gap="xs">
          <FilterPill onSelect={handleSelectAll}>Select All</FilterPill>
          <FilterPill onSelect={handleUnselectAll}>Unselect All</FilterPill>
        </Box>
      </Box>
      <Box paddingX="l" paddingY="l" className={styles.content} scrollable="y">
        <Box display="flex" flexDirection="column" gap="s">
          {findings.map((finding, index) => (
            <FindingSelectRow
              finding={finding}
              index={index}
              key={finding.id}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="m"
        className={styles.footer}
        gap="m"
      >
        <Box marginLeft="auto" display="flex" gap="m">
          <Button strong pill onClick={handleClose}>
            {t('generic.submit.cancel')}
          </Button>
          <Button
            strong
            pill
            intent="primary"
            onClick={handleConfirm}
            disabled={selectedIds.length === 0}
          >
            {t('pages.study.finding.filter.select.done')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

interface FindingSelectRowProps {
  index: number;
  finding: Finding;
  selectedIds: string[];
  setSelectedIds(ids: string[]): void;
}

const FindingSelectRow: React.FC<FindingSelectRowProps> = ({
  index,
  finding,
  selectedIds,
  setSelectedIds,
}) => {
  const findingId = finding.id;
  const selected = selectedIds.includes(findingId);
  const setSelected = (checked: boolean) => {
    if (checked && !selected) {
      setSelectedIds([...selectedIds, findingId]);
    } else if (!checked && selected) {
      setSelectedIds(selectedIds.filter(id => id !== findingId));
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      className={cnames(styles.selectRow, {
        [styles.selected]: selected,
      })}
    >
      <Box flex="0 0 auto" paddingLeft="xs">
        <Checkbox
          checked={selected}
          onChange={event => setSelected(event.target.checked)}
        />
      </Box>
      <Box
        flex="1 1 auto"
        className={cnames(styles.findingSummaryRowContainer, {
          [styles.selected]: selected,
        })}
        onClick={() => setSelected(!selected)}
      >
        <FindingSummaryRow
          finding={finding}
          theme={ResultTableStyle.editable}
          interactable={false}
          index={index}
        />
      </Box>
    </Box>
  );
};

import * as StudyItem from '@entities/StudyType';
import { StudyType } from '@entities/StudyType';
import { t } from '@webInterfaces/I18n';

export const studyTypeToI18NKey = (studyType: StudyItem.StudyType): string => {
  const key = studyTypeToString(studyType);
  return key ? `study.location.${key}` : '';
};

export const availableStudyTypes = () => {
  return [StudyType.SmallPartsThyroid, StudyType.SmallPartsBreast];
};

// Decode Pistachio's Study Type to Infrastructure's Study Type
export const studyTypeToString = (studyType: StudyItem.StudyType): string => {
  switch (studyType) {
    case StudyItem.StudyType.SmallPartsBreast:
      return 'small_parts_breast';
    case StudyItem.StudyType.SmallPartsThyroid:
      return 'small_parts_thyroid';
    case StudyItem.StudyType.SmallPartsScrotum:
      return 'small_parts_scrotum';
    case StudyItem.StudyType.SmallPartsNeck:
      return 'small_parts_neck';
    case StudyItem.StudyType.AbdoRenal:
      return 'abdo_renal';
    case StudyItem.StudyType.AbdoUpperAbdomen:
      return 'abdo_upper_abdomen';
    case StudyItem.StudyType.AbdoPelvic:
      return 'abdo_pelvic';
    case StudyItem.StudyType.AbdoLiver:
      return 'abdo_liver';
    case StudyItem.StudyType.Unknown:
      return 'unknown';
  }
};

export const stringToStudyType = (
  input: string | null
): StudyItem.StudyType => {
  switch (input) {
    case 'small_parts_breast':
      return StudyItem.StudyType.SmallPartsBreast;
    case 'small_parts_thyroid':
      return StudyItem.StudyType.SmallPartsThyroid;
    case 'small_parts_scrotum':
      return StudyItem.StudyType.SmallPartsScrotum;
    case 'small_parts_neck':
      return StudyItem.StudyType.SmallPartsNeck;
    case 'abdo_pelvic':
      return StudyItem.StudyType.AbdoPelvic;
    case 'abdo_liver':
      return StudyItem.StudyType.AbdoLiver;
    case 'abdo_renal':
      return StudyItem.StudyType.AbdoRenal;
    case 'abdo_upper_abdomen':
      return StudyItem.StudyType.AbdoUpperAbdomen;
    default:
      return StudyItem.StudyType.Unknown;
  }
};

export const studyTypeToDescription = (studyType: StudyType) => {
  return t(studyTypeToI18NKey(studyType), '');
};

import React, { useEffect } from 'react';
import { autoPlacement, Placement, useFloating } from '@floating-ui/react';
import { Box, BoxProps } from '@webMolecules/Box/Box';

export type Position = {
  x: number;
  y: number;
};

export interface PositionedBoxProps extends BoxProps {
  referencePosition: Position;
  placement?: Placement;
  onClear?: () => void;
}

export const PositionedBox: React.FC<PositionedBoxProps> = ({
  referencePosition,
  placement,
  children,
  onClear: onClear,
  style,
  ...rest
}) => {
  const { refs, floatingStyles, elements } = useFloating({
    strategy: 'absolute',
    placement,
    middleware: [
      autoPlacement({
        alignment: 'start',
      }),
    ],
  });

  useEffect(() => {
    const handleClear = (event: MouseEvent) => {
      if (
        elements.floating &&
        !elements.floating.contains(event.target as Node)
      ) {
        event.stopPropagation();
        onClear?.();
      }
    };

    // Add a short delay before attaching the event listener
    // so that the initial click doesn't close the box
    const listenerTimeout = setTimeout(() => {
      document.addEventListener('mousedown', handleClear);
    }, 100);

    return () => {
      clearTimeout(listenerTimeout);
      document.removeEventListener('mousedown', handleClear);
    };
  }, [elements.floating, onClear]);

  useEffect(() => {
    const checkScroll = (event: Event) => {
      if (!elements.floating || !onClear) return;

      const target = event.target;
      // check if the target contains the popper element to fix
      // the disappearing issue when the scrollable box is inside popper element
      if (target instanceof HTMLElement && target.contains(elements.floating)) {
        const targetRect = target.getBoundingClientRect();
        const popperRect = elements.floating.getBoundingClientRect();

        const isInScrollArea =
          popperRect.top >= targetRect.top &&
          popperRect.left >= targetRect.left &&
          popperRect.bottom <= targetRect.bottom &&
          popperRect.right <= targetRect.right;

        if (!isInScrollArea) {
          onClear();
        }
      }
    };
    document.addEventListener('scroll', checkScroll, true);
    return () => {
      document.removeEventListener('scroll', checkScroll, true);
    };
  }, [elements.floating, onClear]);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: `${referencePosition.x}px`,
          top: `${referencePosition.y}px`,
          width: '0px',
          height: '0px',
        }}
        ref={refs.setReference}
      ></div>
      <Box
        {...rest}
        ref={refs.setFloating}
        style={{ ...floatingStyles, ...style }}
      >
        {children}
      </Box>
    </>
  );
};

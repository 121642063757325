import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Avatar } from '@webMolecules/Avatar/Avatar';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import { cnames } from '@helpers/cnames';
import { Dropdown } from '@webMolecules/Dropdown/Dropdown';
import { Text } from '@webMolecules/Text/Text';
import styles from './avatarGroup.scss';

export enum AvatarGroupVariant {
  Stack = 'stack',
  Grid = 'grid',
  List = 'list',
}

export interface AvatarGroupProps {
  data: {
    name: string;
    color: string;
    initial: string;
  }[];
  size: 'small' | 'medium' | 'large';
  variant: AvatarGroupVariant;
  maxDisplay?: number;
  borderColor?: string;
  actionSlot?: () => React.ReactNode;
}

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
  data,
  size,
  variant,
  maxDisplay = null,
  borderColor,
  actionSlot,
}) => {
  const hiddenAvatars =
    maxDisplay && data.length > maxDisplay ? data.slice(maxDisplay) : [];

  let displayAvatars =
    maxDisplay && data.length > maxDisplay
      ? data.slice(0, maxDisplay)
      : [...data];

  displayAvatars =
    variant === AvatarGroupVariant.Stack
      ? displayAvatars.reverse()
      : displayAvatars;

  const border = borderColor ? `0 0 0 2px ${borderColor}` : undefined;

  const countNumClass = cnames(styles[size], styles.countNumBox);
  const avatarBoxClass = cnames(styles[variant]);

  const displayedAvatars =
    displayAvatars.length > 0
      ? displayAvatars.map((avatar, i) => (
          <Box
            data-testid="study-page-user-avatar"
            className={avatarBoxClass}
            key={i}
          >
            <Tooltip label={avatar.name} position={TooltipPosition.Bottom}>
              <Avatar
                src=""
                name={avatar.initial}
                color={avatar.color}
                size={size}
                borderColor={borderColor}
              />
            </Tooltip>
          </Box>
        ))
      : null;

  const countNum =
    maxDisplay && data.length > maxDisplay ? (
      <Dropdown
        trigger={
          <Box
            marginLeft={variant === AvatarGroupVariant.Stack ? 'xs' : 'none'}
            data-testid="avatar-test-id"
            className={countNumClass}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            aria-label="avatar-name"
            style={{
              border: border,
            }}
            onClick={event => event.preventDefault()}
          >
            <Text muted type="caption">
              +{data.length - maxDisplay}
            </Text>
          </Box>
        }
      >
        <Box display="flex" flexWrap="wrap" className={styles.hiddenAvatarsBox}>
          {hiddenAvatars?.map((avatar, index) => (
            <Box
              marginRight="xs"
              marginBottom="xs"
              display="flex"
              alignItems="center"
              key={index}
            >
              <Tooltip
                data-testid="study-avatar-tooltip"
                label={avatar.name}
                position={TooltipPosition.Bottom}
              >
                <Avatar
                  src=""
                  name={avatar.initial}
                  color={avatar.color}
                  size={size}
                />
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Dropdown>
    ) : null;

  const content =
    variant === AvatarGroupVariant.Stack ? (
      <Box display="flex">
        <Box display="flex" alignItems="center" flexDirection="row-reverse">
          {actionSlot && <Box>{actionSlot()}</Box>}
          {countNum}
          {displayedAvatars}
        </Box>
      </Box>
    ) : variant === AvatarGroupVariant.Grid ? (
      <Box display="flex" flexWrap="wrap">
        {displayedAvatars}
        {countNum}
        {actionSlot && <Box>{actionSlot()}</Box>}
      </Box>
    ) : (
      <Box display="flex" flexWrap="wrap" flexDirection="column">
        {displayedAvatars}
        {countNum}
        {actionSlot && <Box>{actionSlot()}</Box>}
      </Box>
    );

  return content;
};

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Button } from '@webMolecules/Button/Button';
import { cnames } from '@helpers/cnames';
import styles from './drawerMenu.scss';

export enum DrawerMotionDirection {
  Left = 'drawer_motion_direction--left',
  Right = 'drawer_motion_direction--right',
  UpShort = 'drawer_motion_direction--upShort',
  Up = 'drawer_motion_direction--up',
}

export const spring = {
  type: 'spring',
  damping: 15,
  mass: 0.5,
  stiffness: 200,
};

export const getMotionVariantsFromDirection = (
  direction: string | undefined
) => {
  switch (direction) {
    case DrawerMotionDirection.Right:
      return {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '10%' },
      };
    case DrawerMotionDirection.Left:
      return {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '-10%' },
      };
    case DrawerMotionDirection.UpShort:
      return {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '10%' },
      };
    case DrawerMotionDirection.Up:
    default:
      return {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '100%' },
      };
  }
};

export interface DrawerMenuProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  hint?: string;
  motionDirection?: DrawerMotionDirection;
}

const MotionBox = motion(Box);

export const DrawerMenu: React.FC<DrawerMenuProps> = ({
  isOpen,
  onClose,
  title,
  hint,
  children,
  motionDirection = DrawerMotionDirection.Up,
}) => {
  const handleClose = () => onClose && onClose();

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          className={styles.menuDrawer}
          variants={getMotionVariantsFromDirection(motionDirection)}
          transition={spring}
          initial="closed"
          animate="open"
          exit="closed"
        >
          <Card elevation="medium" padding="none">
            {title && (
              <Box
                padding="s"
                paddingY="xs"
                className={styles.menuHeader}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text type="display6" display="block">
                  {title}
                </Text>
                <Button onClick={() => handleClose()} size="small">
                  Cancel
                </Button>
              </Box>
            )}
            {hint && (
              <Box
                padding="s"
                paddingBottom="none"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text type="caption" display="block" muted>
                  {hint}
                </Text>
              </Box>
            )}
            <Box className={styles.menuItems} paddingY="s">
              {children}
            </Box>
          </Card>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

interface DrawerMenuItemProps {
  onSelect?: () => void;
  label: string;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  intent?: 'neutral' | 'destructive' | 'primary';
  disabled?: boolean;
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
}

export const DrawerMenuItem: React.FC<DrawerMenuItemProps> = ({
  onSelect,
  label,
  iconBefore,
  iconAfter,
  intent = 'neutral',
  disabled = false,
  justifyContent,
}) => {
  const handleSelect = () => !disabled && onSelect && onSelect();

  const classNames = cnames(styles.menuItem, styles[intent], {
    [styles.disabled]: disabled,
  });

  return (
    <Box
      element="button"
      className={classNames}
      padding="s"
      onClick={() => handleSelect()}
      display="flex"
      alignItems="center"
      justifyContent={justifyContent}
    >
      {iconBefore && <Box marginRight="xs">{iconBefore}</Box>}
      <Text muted={disabled} type="body" display="block">
        {label}
      </Text>
      {iconAfter && <Box marginLeft="auto">{iconAfter}</Box>}
    </Box>
  );
};

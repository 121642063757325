/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */

export type FeatureFlags = {
  bookmarks: boolean;
  comparisonStudiesManual: boolean;
  customWorkspaces: boolean;
  supportVideoViewing: boolean;
  resetStudy: boolean;
  manualUpload: boolean;
};

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  bookmarks: true,
  comparisonStudiesManual: false,
  customWorkspaces: false,
  supportVideoViewing: false,
  resetStudy: true,
  manualUpload: true,
};

export const CYPRESS_FEATURE_FLAGS: FeatureFlags = {
  bookmarks: true,
  comparisonStudiesManual: true,
  customWorkspaces: true,
  supportVideoViewing: true,
  resetStudy: true,
  manualUpload: true,
};

declare var window: any;

function getGlobalContext(): any {
  if (typeof process !== 'undefined') {
    if (process.env.JEST_WORKER_ID !== undefined) {
      // Jest context
      return global;
    } else {
      // Lambda context
      return process;
    }
  } else if (typeof window !== 'undefined') {
    // Browser context
    return window;
  } else {
    throw new Error('Unable to determine global context');
  }
}

export function initializeGlobalFeatureFlags(featureFlags: FeatureFlags) {
  const context = getGlobalContext();
  context.globalFeatureFlags = featureFlags;
}

export function getFeatureFlags(): FeatureFlags {
  const context = getGlobalContext();
  return context.globalFeatureFlags ?? DEFAULT_FEATURE_FLAGS;
}

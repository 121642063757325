import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { Popover, usePopoverContext } from '@webMolecules/Popover/Popover';
import { Button } from '@webMolecules/Button/Button';
import { t, tPrint } from '@webInterfaces/I18n';
import { eventComparisonActionShowedImages } from '@webInterfaces/Analytics';
import { InlineDicomImageGrid } from './InlineDicomImageGrid';
import styles from './comparisonUtility.scss';

interface FindingNodulePopoverProps {
  dicomIds: string[];
  index?: number;
  isPrevious: boolean;
}
export const FindingNodulePopover = ({
  dicomIds,
  index,
  isPrevious,
}: FindingNodulePopoverProps) => {
  return (
    <Box display="flex">
      <Popover
        keepOpen={true}
        trigger={
          <Button
            disabled={dicomIds.length === 0}
            iconBefore={
              <Icon
                name="image"
                color={
                  dicomIds.length === 0
                    ? 'var(--ds-color-neutral-600)'
                    : 'var(--ds-color-neutral-300)'
                }
              />
            }
            size="small"
          />
        }
        onOpen={() => eventComparisonActionShowedImages()}
        position="bottom-end"
        size="large"
      >
        {dicomIds.length > 0 && (
          <Box display="flex" flexDirection="column">
            <PopoverLabel
              index={index}
              imageLength={dicomIds.length}
              isPrevious={isPrevious}
            />
            <Box display="flex" gap="xs" className={styles.popover}>
              <InlineDicomImageGrid dicomIds={dicomIds} />
            </Box>
          </Box>
        )}
      </Popover>
    </Box>
  );
};

interface PopoverLabelProps {
  index: number | undefined;
  isPrevious: boolean;
  imageLength: number;
}
const PopoverLabel: React.FC<PopoverLabelProps> = ({
  index,
  imageLength,
  isPrevious,
}) => {
  const { close } = usePopoverContext();
  const printPreviousLabel = index
    ? tPrint('pages.study.comparison.previous.finding.index', index)
    : t('pages.study.comparison.previous.finding.without.index');

  const printCurrentLabel = index
    ? tPrint('pages.study.comparison.finding.index', index)
    : t('pages.study.comparison.finding.without.index');

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="xs"
    >
      <Box display="flex">
        <Box>
          <Text type="display6" muted>
            {isPrevious ? printPreviousLabel : printCurrentLabel}
          </Text>
          <Text type="body2" marginX="xs" muted>
            |
          </Text>
        </Box>
        <Text type="body2" muted>
          {tPrint('pages.study.comparison.images.label', imageLength)}
        </Text>
      </Box>
      <Box onClick={close} className={styles.closeIcon}>
        <Icon name="x" />
      </Box>
    </Box>
  );
};

import { useSelector } from 'react-redux';
import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';
import { Divider } from '@webMolecules/Divider/Divider';
import { Text } from '@webMolecules/Text/Text';
import { FindingSectionConfig } from '@config/ResultTable/Shared';
import { selectConfig, selectWorkflow } from '@selectors/BaseSelectors';
import {
  filterConfigItemsByFeatureFlag,
  filterConfigItemsByWorkflow,
} from '@config/ResultTable/ResultTable';
import { Finding } from '@entities/Finding';
import { FeatureFlags } from '@entities/FeatureFlag';
import { ResultTableCell } from '../ResultTableCell';
import { evaluateFieldFunction, getFieldStyle } from './Shared';
import styles from './DetailedFindingsTable.scss';
import { DetailedFindingTableMergedCell } from './DetailedFindingTableMergedCell';

export interface DetailedFindingsTableSectionProps {
  section: FindingSectionConfig;
  featureFlags: FeatureFlags;
}

export const DetailedFindingsTableSection: React.FC<
  DetailedFindingsTableSectionProps
> = ({ section, featureFlags }) => {
  const workflow = useSelector(selectWorkflow);
  const config = useSelector(selectConfig);
  const { watch } = useFormContext<Finding>();
  const finding = watch();

  let filterField = filterConfigItemsByFeatureFlag(
    section.fields,
    featureFlags,
    config
  );

  filterField = filterConfigItemsByWorkflow(filterField, workflow);

  return (
    <Box key={`${section.title}-${section.index}`}>
      {section.title && (
        <Box marginBottom="m">
          <Text type="display6">{t(section.title, '')}</Text>
          {section.titleSeparator && (
            <Divider
              marginTop="xxs"
              marginBottom="m"
              color="var(--ds-border-color-strong)"
            />
          )}
        </Box>
      )}
      <Box>
        {filterField.map(field => {
          const disabled = evaluateFieldFunction(field.isDisabled, finding);
          const showNote =
            !!field.note && evaluateFieldFunction(field.showNote, finding);

          const label = field.label ? t(field.label, '') : '';

          const defaultFieldLayout = (
            <Box
              display="flex"
              flexDirection="row"
              marginBottom="m"
              data-testid="finding-field-test-id"
            >
              {!field.hideLabel && (
                <Box
                  className={styles.fieldLabelBox}
                  margin={field.labelBoxMargin || 'auto'}
                >
                  <Text
                    type={field.boldLabel ? 'display6' : 'body2'}
                    disabled={disabled}
                  >
                    {label}
                  </Text>

                  {field.labelCaption && (
                    <Text
                      type="caption"
                      disabled={disabled}
                      muted
                      marginLeft="xxs"
                    >
                      {t(field.labelCaption, '')}
                    </Text>
                  )}
                </Box>
              )}
              <Box display="flex" flexDirection="column" fullWidth>
                <Box
                  className={styles.cellContainer}
                  style={getFieldStyle(field)}
                >
                  <ResultTableCell
                    path={field.path}
                    footnotes={new Map()}
                    rowConfig={{}}
                    config={field}
                    disabled={disabled}
                    onToggle={field.onToggle}
                  />
                </Box>
                {showNote && field.note && (
                  <Box marginTop="xxs">
                    <Text type="caption" muted>
                      {t(field.note)}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          );

          const getMergedCellFieldLayout = () => {
            if (!field.mergedCellFields) return;
            const filteredMergedCellFields = filterConfigItemsByFeatureFlag(
              field.mergedCellFields.config || [],
              featureFlags,
              config
            );

            return (
              <Box display="flex" marginBottom="s">
                <Box
                  display="flex"
                  flexDirection="column"
                  gapRow="s"
                  margin="auto"
                >
                  {filteredMergedCellFields.map(subField => {
                    const fieldDisabled = evaluateFieldFunction(
                      subField.isDisabled,
                      finding
                    );
                    return !subField.hideLabel ? (
                      <Box
                        className={styles.fieldLabelBox}
                        key={field.key + subField.label}
                      >
                        <Text
                          type={subField.boldLabel ? 'display6' : 'body2'}
                          muted={!subField.boldLabel}
                          disabled={fieldDisabled}
                        >
                          {t(subField.label, '')}
                        </Text>

                        {subField.labelCaption && (
                          <Text
                            type="caption"
                            disabled={disabled}
                            muted
                            marginLeft="xxs"
                          >
                            {t(subField.labelCaption, '')}
                          </Text>
                        )}
                      </Box>
                    ) : null;
                  })}
                </Box>

                <Box display="flex" flexDirection="column" fullWidth>
                  <DetailedFindingTableMergedCell
                    config={{
                      ...field,
                      mergedCellFields: {
                        ...field.mergedCellFields,
                        config: filteredMergedCellFields,
                      },
                    }}
                    disabled={disabled}
                  />
                  {showNote && (
                    <Box marginTop="xxs">
                      <Text type="caption" muted>
                        {t(field.note!)}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            );
          };

          return (
            <Fragment key={`${section.index} - ${field.key}`}>
              {field.mergedCellFields
                ? getMergedCellFieldLayout()
                : defaultFieldLayout}

              {field.separator && (
                <Box>
                  {field.separatorLabel && (
                    <Text type="body2" muted>
                      {t(field.separatorLabel)}
                    </Text>
                  )}
                  <Divider
                    marginBottom="xs"
                    color="var(--ds-border-color-strong)"
                  />
                </Box>
              )}
            </Fragment>
          );
        })}
      </Box>
      {section.separator && (
        <Divider marginBottom="m" color="var(--ds-border-color-strong)" />
      )}
    </Box>
  );
};

import { TiRadsGrading, BiradsScore } from '@entities/Grading';

interface NoduleGradingConfig {
  prefix: string;
  value: string;
  color: string;
  severity: number;
}

export const NoduleGradingMap: Record<
  TiRadsGrading | BiradsScore,
  NoduleGradingConfig
> = {
  [TiRadsGrading.TR1]: { prefix: 'TR', value: '1', color: '100', severity: 1 },
  [TiRadsGrading.TR2]: { prefix: 'TR', value: '2', color: '300', severity: 2 },
  [TiRadsGrading.TR3]: { prefix: 'TR', value: '3', color: '500', severity: 3 },
  [TiRadsGrading.TR4]: { prefix: 'TR', value: '4', color: '700', severity: 4 },
  [TiRadsGrading.TR5]: { prefix: 'TR', value: '5', color: '900', severity: 5 },
  [BiradsScore.BIRADS_0]: {
    prefix: 'BR',
    value: '0',
    color: '000',
    severity: 0,
  },
  [BiradsScore.BIRADS_1]: {
    prefix: 'BR',
    value: '1',
    color: '100',
    severity: 1,
  },
  [BiradsScore.BIRADS_2]: {
    prefix: 'BR',
    value: '2',
    color: '300',
    severity: 2,
  },
  [BiradsScore.BIRADS_3]: {
    prefix: 'BR',
    value: '3',
    color: '500',
    severity: 3,
  },
  [BiradsScore.BIRADS_4A]: {
    prefix: 'BR',
    value: '4A',
    color: '700',
    severity: 4,
  },
  [BiradsScore.BIRADS_4B]: {
    prefix: 'BR',
    value: '4B',
    color: '700',
    severity: 5,
  },
  [BiradsScore.BIRADS_4C]: {
    prefix: 'BR',
    value: '4C',
    color: '700',
    severity: 6,
  },
  [BiradsScore.BIRADS_5]: {
    prefix: 'BR',
    value: '5',
    color: '900',
    severity: 7,
  },
  [BiradsScore.BIRADS_6]: {
    prefix: 'BR',
    value: '6',
    color: '1000',
    severity: 8,
  },
};

export function getGradingColorStyle(grading: TiRadsGrading | BiradsScore) {
  return {
    backgroundColor: `var(--ds-nodule-severity-${NoduleGradingMap[grading].color})`,
  };
}

import React from 'react';
import { useController } from 'react-hook-form';
import { Switch } from '@webMolecules/Switch/Switch';
import { t } from '@webInterfaces/I18n';
import { ResultTableSharedProps } from '../ResultTableSharedProps';

export const FindingIncludeSwitch: React.FC<ResultTableSharedProps> = ({
  path,
}) => {
  const { field } = useController({
    name: path ?? '',
  });

  const isIncluded: boolean = field.value;
  const updateIncluded = (included: boolean) => {
    field.onChange(included);
  };

  return (
    <Switch
      align="left"
      checked={isIncluded}
      onChange={checked => updateIncluded(checked)}
      label={checked =>
        checked
          ? t('result_table.shared.options.yes')
          : t('result_table.shared.options.no')
      }
    />
  );
};

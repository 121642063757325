import { Action } from 'redux';
import Result, { ok, err } from '@monads/Result';
import { append } from '@monads/Tuple';
import { withLabeledTimer } from '@helpers/debug';

export type Effect = EffectNone | EffectSingle | EffectBatch;

export interface EffectRunnerInterface {
  label: string;
  run: () => void;
  on: (
    eventName: EffectRunnerEventName,
    callback: EffectRunnerEventCallback
  ) => void;
  then: (f: (result: Result<Action>) => Result<Action>) => Effect;
  done: (f: (result: Result<Action>) => void) => Effect;
  recover: (f: (error: Error) => Action) => Effect;
  cancel: () => boolean;
}
interface EffectRunnerEventListener {
  eventName: EffectRunnerEventName;
  callback: EffectRunnerEventCallback;
}

export type EffectRunnerEventCallback = (
  status: EffectRunnerStatus,
  effect: Effect,
  result?: Result<Action>
) => void;

export enum EffectRunnerEventName {
  Started = 'effect_event_name--started',
  Finished = 'effect_event_name--finished',
  Canceled = 'effect_event_name--canceled',
}

export enum EffectRunnerStatus {
  Idle = 'effect_event_status--idle',
  Started = 'effect_event_status--started',
  Finished = 'effect_event_status--finished',
  Canceled = 'effect_event_status--canceled',
}

export class EffectNone implements EffectRunnerInterface {
  public label = 'none';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  run() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  on() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancel() {
    return false;
  }
  then() {
    return this;
  }
  done() {
    return this;
  }
  recover() {
    return this;
  }
}
export class EffectSingle implements EffectRunnerInterface {
  public label;
  private __f: () => Action | Promise<Action>;
  private __listeners: EffectRunnerEventListener[] = [];
  private __status = EffectRunnerStatus.Idle;
  private __result?: Result<Action>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private __mappers: Array<(result: Result<any>) => Result<any>> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private __onDone?: (result: Result<any>) => void;

  constructor(f: () => Action | Promise<Action>, label = '') {
    this.label = label;
    this.__f = f;
  }

  async run() {
    if (this.__status !== EffectRunnerStatus.Idle) {
      return;
    }

    this.updateStatus(EffectRunnerStatus.Started);

    try {
      const value = await withLabeledTimer(
        () => Promise.resolve(this.__f()),
        this.label
      );

      const result = ok(value);

      this.updateStatus(EffectRunnerStatus.Finished, result);
    } catch (e: unknown) {
      const error = e instanceof Error ? e : new Error(String(e));
      const result = err(e);
      console.error(`Error on effect ${this.label}`, error);
      this.updateStatus(EffectRunnerStatus.Finished, result);
    }
  }

  on(
    eventName: EffectRunnerEventName,
    callback: EffectRunnerEventCallback
  ): void {
    this.__listeners.push({ eventName, callback });
  }

  then(f: (result: Result<Action>) => Result<Action>): Effect {
    this.__mappers.push(f);
    return this;
  }

  done(f: (result: Result<Action>) => void): Effect {
    this.__onDone = f;
    return this;
  }

  recover(f: (error: Error) => Action): Effect {
    return this.then(result => result.recoverError(f));
  }

  cancel(): boolean {
    if (
      this.__status === EffectRunnerStatus.Idle ||
      this.__status === EffectRunnerStatus.Canceled
    ) {
      this.__status = EffectRunnerStatus.Canceled;
      return true;
    }
    return false;
  }

  private updateStatus(
    effectStatus: EffectRunnerStatus,
    result?: Result<Action>
  ): void {
    if (this.__status === EffectRunnerStatus.Canceled) {
      return;
    }

    this.__status = effectStatus;

    if (result) {
      this.__result = result;
    }

    switch (effectStatus) {
      case EffectRunnerStatus.Idle:
        return;
      case EffectRunnerStatus.Canceled:
        this.trigger(EffectRunnerEventName.Canceled);
        return;
      case EffectRunnerStatus.Started:
        this.trigger(EffectRunnerEventName.Started);
        return;
      case EffectRunnerStatus.Finished:
        if (result) {
          if (this.__onDone) {
            const mappedResult = this.__mappers.reduce(
              (a, b) => append(a, b).apply() || a,
              result
            );
            this.__onDone(mappedResult);
          }

          this.trigger(EffectRunnerEventName.Finished);
        }

        return;
    }
  }

  private trigger(eventName: EffectRunnerEventName): void {
    this.__listeners.forEach(listener => {
      if (listener.eventName === eventName) {
        listener.callback(this.__status, this, this.__result);
      }
    });
  }
}
export class EffectBatch implements EffectRunnerInterface {
  public label: string;
  private __batch: Effect[];
  private __status = EffectRunnerStatus.Idle;
  private __result?: Result<Action>;

  constructor(batch: Effect[]) {
    const flatten = (a: Effect[]): Effect[] =>
      a.reduce((acc: Effect[], eff: Effect) => {
        if (eff instanceof EffectSingle) {
          return [...acc, eff];
        } else if (eff instanceof EffectBatch) {
          return [...acc, ...flatten(eff.toList())];
        }
        return acc;
      }, []);

    this.__batch = flatten(batch);
    this.label = this.__batch.map(a => a.label).join(',');
  }

  run() {
    this.__batch.forEach(a => a.run());
  }

  on(
    eventName: EffectRunnerEventName,
    callback: EffectRunnerEventCallback
  ): void {
    this.__batch.forEach(a => a.on(eventName, callback));
  }

  cancel(): boolean {
    return this.__batch.map(a => a.cancel()).reduce((a, b) => a && b, true);
  }

  then(f: (result: Result<Action>) => Result<Action>): Effect {
    this.__batch.forEach(a => a.then(f));
    return this;
  }

  done(f: (result: Result<Action>) => void): Effect {
    this.__batch.forEach(a => a.done(f));
    return this;
  }

  recover(f: (error: Error) => Action): Effect {
    this.__batch.forEach(a => a.recover(f));
    return this;
  }

  toList(): Effect[] {
    return this.__batch;
  }
}

export const none = (): Effect => new EffectNone();

export const effect = (
  f: () => Action | Promise<Action>,
  label: string
): Effect => new EffectSingle(f, label);

export const batch = (batch: Effect[]): Effect => new EffectBatch(batch);

export const batchOrSingle = (effects: Effect[]): Effect | undefined => {
  if (effects.length > 1) {
    return batch(effects);
  } else if (effects.length > 0) {
    return effects[0];
  } else {
    return undefined;
  }
};

export default Effect;

import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Badge } from '@webMolecules/Badge/Badge';
import { InteractiveBox } from '@webMolecules/InteractiveBox/InteractiveBox';
import { Avatar } from '@webMolecules/Avatar/Avatar';
import { Icon } from '@webMolecules/Icon/Icon';
import {
  Dropdown,
  DropdownItem,
  DropdownLink,
} from '@webMolecules/Dropdown/Dropdown';
import { Divider } from '@webMolecules/Divider/Divider';
import { Profile } from '@interfaces/Profile';
import { t } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import styles from './usermenu.scss';

export interface UserMenuProps {
  onSignOut?: () => void;
  heading?: string;
  profile?: Profile;
}

export const UserMenu: React.FC<UserMenuProps> = ({
  profile,
  onSignOut = () => null,
}) => {
  const fullName = `${profile?.firstName} ${profile?.lastName}`;
  const swatchColour = `${profile?.swatchColour}`;
  const institutionId = `${profile?.institutionId}`;

  const trigger = (
    <InteractiveBox pill data-testid="usermenu-button-trigger">
      <Box display="flex" alignItems="center" paddingY="xxs" paddingRight="xxs">
        <Box marginX="s">{<Icon name="menu" />}</Box>
        <Avatar
          src=""
          name={profile?.firstName}
          color={swatchColour}
          size="medium"
        />
      </Box>
    </InteractiveBox>
  );
  return (
    <Dropdown trigger={trigger} size="medium">
      <Box
        padding="xl"
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={styles.wrapText}
        data-testid="usermenu-dropdown"
      >
        <Avatar
          src=""
          name={profile?.firstName}
          color={swatchColour}
          size="large"
        />
        <Box marginBottom="m" />
        <Text display="block" type="display5" marginBottom="xs">
          {fullName}
        </Text>
        <Badge subtle>{institutionId}</Badge>
      </Box>
      <Divider marginBottom="xs" />
      <DropdownLink
        as={Link}
        to={RouteHelper.settingsProfile}
        iconBefore={<Icon name="settings" />}
      >
        {t('sidebar.settings')}
      </DropdownLink>
      <DropdownItem
        onSelect={onSignOut}
        muted
        iconBefore={<Icon name="logout" color="var(--ds-text-color-muted)" />}
      >
        {t('usermenu.sign_out')}
      </DropdownItem>
    </Dropdown>
  );
};

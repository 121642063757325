import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Finding } from '@entities/Finding';
import { Icon } from '@webMolecules/Icon/Icon';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import styles from '../findingSummary.scss';
import { PreviewIcon } from '../SmallPartsStudyFindingHelper';

interface FindingLocationProps {
  finding: Finding;
  theme: ResultTableStyle;
}

export const FindingLocation: React.FC<FindingLocationProps> = ({
  finding,
  theme,
}) => {
  const formattedLocationWithSymbol = finding.formatted.location ?? '';

  switch (theme) {
    case ResultTableStyle.preview:
      return pdfLocationLayout([formattedLocationWithSymbol]);
    default:
      return smallOrLargeScreenLocationLayout([formattedLocationWithSymbol]);
  }
};

const smallOrLargeScreenLocationLayout = (locationValue: string[]) => {
  return locationValue ? (
    <Text type="display4">
      <span className={styles.muted}>{locationValue}</span>
    </Text>
  ) : null;
};

const pdfLocationLayout = (locationValue: string[]) => {
  const splitLocationValue = locationValue[0].split(` • `);
  return (
    <Box display="flex" alignItems="center">
      <Text type="display4">{splitLocationValue[0]}</Text>
      {splitLocationValue[1] && (
        <>
          <PreviewIcon icon={<Icon name="bullet-separator" fluid />} />
          <Text type="display4" marginLeft="xs">
            {splitLocationValue[1]}
          </Text>
        </>
      )}
    </Box>
  );
};

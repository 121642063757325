import {
  decode,
  decodeSync,
  encodeOperatorMappingProperties,
} from '@api/decoders/Profile';
import { post } from '@api/schema/client';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { Storage as ContractStorage } from '@contracts/Storage';
import { Profile } from '@interfaces/Profile';

export class Profiles implements ContractProfiles {
  async List(): Promise<Profile[]> {
    const data = await post('/list-user-profiles', {});
    const preParsedUserProfiles = data.user_profiles;

    return preParsedUserProfiles
      .map(item => decodeSync(item))
      .sort((a, b) => a.firstName.localeCompare(b.firstName));
  }

  async Get(storage: ContractStorage, userId: string): Promise<Profile> {
    const data = await post('/get-user-profile', {
      body: {
        user_profile_id: userId,
      },
    });

    return await decode(storage, data);
  }

  async Create(profile: Profile): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/admin/create-user-profile', {
          body: {
            username: profile.email,
            is_institution_owner: profile.isAdmin,
            operator_mapping: encodeOperatorMappingProperties(
              profile.operatorMappingProperties
            ),
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  Update(profile: Profile): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/admin/update-user-profile', {
          body: {
            user_profile_id: profile.id,
            is_institution_owner: profile.isAdmin,
            operator_mapping: encodeOperatorMappingProperties(
              profile.operatorMappingProperties
            ),
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }

  Destroy(id: string): Promise<'success'> {
    return new Promise(async (resolve, reject) => {
      try {
        await post('/admin/delete-user-profile', {
          body: {
            user_profile_id: id,
          },
        });

        resolve('success');
      } catch (e) {
        reject(e);
      }
    });
  }
}

import { analysisUploadsUseCase } from '@useCases/AnalysisUploads';
import { dispatch } from '@webInterfaces/Store';
import { logError } from '@webInterfaces/Error';
import { Actions } from '@webInterfaces/StoreTypes';

export const upload = (fileList: File[]): Actions =>
  analysisUploadsUseCase.NewUpload({
    fileList,
    onUpdate: useCase => {
      dispatch(useCase);
    },
    onError: logError,
  });

import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Tag } from '@webMolecules/Tag/Tag';
import {
  selectComparisonAvailable,
  selectIsSize3D,
} from '@selectors/SessionSelectors';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import { ThyroidFinding } from '@entities/Finding';
import { computeFindingClinicalAction } from '@webViews/Pages/Study/helper';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import {
  clinicalActionToString,
  clinicalActionToTagIntent,
} from '../FindingsTable/Shared';
import styles from './cells.scss';

export const ResultTableCellFindingRecommendation: React.FC<
  ResultTableSharedProps
> = () => {
  const isComparisonAvailable = useSelector(selectComparisonAvailable);
  const isSize3D = useSelector(selectIsSize3D);

  const { watch } = useFormContext<ThyroidFinding>();
  const finding = watch();

  const clinicalAction = computeFindingClinicalAction(finding, isSize3D);
  const clinicalActionLabel = clinicalActionToString(clinicalAction);

  if (!clinicalActionLabel) {
    return (
      <Box
        display="flex"
        alignItems="center"
        paddingX="s"
        paddingY="xxs"
        className={styles.noteBackground}
        gapColumn="xs"
      >
        <Icon
          name="alert-triangle"
          color="var(--ds-color-warning-500)"
          size="large"
        />

        <Text type="body2">
          {t('detailed_finding_table.recommendation.warning')}
        </Text>
      </Box>
    );
  }

  if (isComparisonAvailable) {
    return <NextPageRecommendation />;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="l"
      data-testid="finding-recommendation"
    >
      <Tag
        label={clinicalActionLabel || ''}
        intent={clinicalActionToTagIntent(clinicalAction)}
        strong
      />
    </Box>
  );
};

function NextPageRecommendation() {
  return (
    <Box
      display="flex"
      paddingX="s"
      paddingY="xxs"
      className={styles.noteBackground}
    >
      <Box marginRight="xs">
        <Icon name="info" color="var(--ds-color-primary)" />
      </Box>
      <Box>
        <Text display="block" type="body2" marginBottom="xs">
          {t('detailed_finding_table.recommendation.press')}
          &nbsp;
          <Text type="display6" textColor="low">
            {t('detailed_finding_table.recommendation.next')}
          </Text>
          &nbsp;
          {t('detailed_finding_table.recommendation.compare')}
        </Text>
        <Text display="block" type="display6">
          {t('detailed_finding_table.recommendation.next_page')}
        </Text>
      </Box>
    </Box>
  );
}

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from '@webMolecules/Text/Text';
import { ThyroidInteractiveSVG } from '@webOrganisms/ThyroidInteractiveSVG/ThyroidInteractiveSVG';
import {
  ResultTableSelectConfig,
  ResultTableSelectOptions,
} from '@config/ResultTable/ResultTable';
import { Box } from '@webMolecules/Box/Box';
import { MergedCellFieldConfig } from '@config/ResultTable/Shared';
import { t } from '@webInterfaces/I18n';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { ThyroidFinding } from '@entities/Finding';
import { BodySide, ThyroidPole } from '@entities/Characteristics';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import {
  evaluateFieldFunction,
  getDisplayedOptionLabel,
} from '../FindingsTable/Shared';

interface MergedCellThyroidSVGSelectionProps extends ResultTableSharedProps {
  mergedCellFields: MergedCellFieldConfig[];
  disabled?: boolean;
  confidenceThreshold?: number;
}

export const MergedCellThyroidSVGSelection: React.FC<
  MergedCellThyroidSVGSelectionProps
> = ({ mergedCellFields }) => {
  const { setValue, watch } = useFormContext<ThyroidFinding>();

  const finding = watch();
  const selectedSide = finding.characteristics.side ?? undefined;
  const selectedPole = finding.characteristics.pole ?? undefined;

  const handleChange = (bodySide: string, pole?: string) => {
    if (bodySide !== selectedSide) {
      setValue('characteristics.side', bodySide as BodySide, {
        shouldDirty: true,
      });
    }

    if (pole && pole != selectedPole) {
      setValue('characteristics.pole', pole as ThyroidPole, {
        shouldDirty: true,
      });
    }
  };

  const onChange = (value: string, key: string) => {
    if (key === 'side') {
      setValue('characteristics.side', value as BodySide, {
        shouldDirty: true,
      });
    } else {
      setValue('characteristics.pole', value as ThyroidPole, {
        shouldDirty: true,
      });
    }
  };

  const getSelectOptions = (options: ResultTableSelectOptions) => {
    const arrayOption = Array.from(options);
    return arrayOption?.map(option => {
      return {
        value: option.value,
        label: getDisplayedOptionLabel(option.value, t(option.editableI18NKey)),
      };
    });
  };

  return (
    <Box display="flex" data-testid="thyroid-svg-selection">
      <Box
        style={{ width: '170px' }}
        display="flex"
        gap="m"
        marginX="m"
        justifyContent="center"
        alignItems="center"
      >
        <Text type="display6" muted>
          {t('detailed_finding_table.thyroid.mini_canvas.right')}
        </Text>
        <ThyroidInteractiveSVG
          selectedPole={selectedPole}
          selectedBodySide={selectedSide}
          onChange={handleChange}
        />
        <Text type="display6" muted>
          {t('detailed_finding_table.thyroid.mini_canvas.left')}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        marginLeft="m"
        gap="m"
        flex="1"
      >
        {mergedCellFields.map((field, index) => {
          const fieldDisabled = evaluateFieldFunction(
            field.isDisabled,
            finding
          );
          return (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              gapColumn="l"
              data-testid="finding-field-test-id"
            >
              <Text type="caption" display="inline" muted>
                {t(field.label)}
              </Text>
              <Box key={index} fullWidth>
                <MenuSelect
                  fluid
                  disabled={fieldDisabled}
                  value={field.key == 'side' ? selectedSide : selectedPole}
                  onChange={value => onChange(value, field.key)}
                  options={getSelectOptions(
                    (field.inputConfig as ResultTableSelectConfig).options
                  )}
                  placeholder={'-'}
                  size="small"
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

import isEqual from 'lodash/isEqual';
import { Action } from 'redux';
import { pair } from '@monads/Tuple';
import { Worklist, WorklistLineItem, initial } from '@entities/Worklist';
import { WorkspaceStatus } from '@entities/WorkspaceStatus';
import { Session as ContractSession } from '@contracts/Session';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { Authenticate as ContractAuthenticate } from '@contracts/Authenticate';
import { Encryption as ContractEncryption } from '@contracts/Encryption';
import { Workspaces as ContractWorkspaces } from '@contracts/Workspaces';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { effect, none, batch } from '@library/Effect';
import { ApiStatus } from '@interfaces/Status';
import { Workspace } from '@interfaces/Workspace';
import { fromString as workspaceGroupsFromString } from '@interfaces/WorkspaceGroup';
import { appUseCase } from '@useCases/App';
import {
  SessionStatus,
  stringToSessionStatusArray,
} from '@interfaces/SessionLineItem';
import { just } from '@monads/Maybe';
import {
  FilterOptions,
  filterOptionToTargetHash,
} from '@interfaces/FilterOptions';
import * as RouteHelper from '@helpers/routes';
import {
  decryptPatientHealthInfo,
  effectReanalyseSession,
} from '@useCases/Session';
import { Profile } from '@entities/Profile';
import { Storage as ContractStorage } from '@contracts/Storage';
import { createUseCase } from '@helpers/createUseCase';
import { unique } from '@helpers/array';

export const worklistUseCase = {
  /**
   * Init
   * - init will load all analyses and start subscribing for any analysis creations and changes.
   */
  Init: createUseCase('WORKLIST_INIT').withPayload<{
    dispatch: (action: Action) => void;
  }>(),

  /**
   * Cancel Poll
   * - cancel polling for sidebar information
   */
  CancelPoll: createUseCase('WORKLIST_CANCEL_POLL').noPayload(),

  /**
   * Start Poll
   * - start polling for sidebar information
   */
  StartPoll: createUseCase('WORKLIST_START_POLL').withPayload<{
    group?: string;
    id?: string;
    status?: string;
  }>(),

  /**
   * Set profiles
   */
  SetProfiles: createUseCase('WORKLIST_SET_PROFILES').withPayload<{
    profiles: Profile[];
  }>(),

  /**
   * Set workspaces
   */
  SetWorkspaces: createUseCase('WORKLIST_SET_WORKSPACES').withPayload<{
    workspaces: Workspace[];
  }>(),

  /**
   * List workspace status
   */
  WorkspaceStatusList: createUseCase(
    'WORKLIST_LIST_WORKSPACE_STATUS'
  ).noPayload(),

  /**
   * Set workspace status
   */
  SetWorkspaceStatus: createUseCase(
    'WORKLIST_SET_WORKSPACE_STATUS'
  ).withPayload<{
    workspaceStatus: WorkspaceStatus[];
  }>(),

  /**
   * List Sessions
   * Load a worklist with the given optional options
   */
  ListSessions: createUseCase('WORKLIST_LIST_SESSIONS').withPayload<{
    group?: string;
    id?: string;
    status?: string;
    reset?: boolean;
  }>(),

  /**
   * Update status
   * Update the session status
   */
  UpdateStatus: createUseCase('WORKLIST_UPDATE_STATUS').withPayload<{
    status: ApiStatus;
  }>(),

  /**
   * List next set of Sessions with respect to the filterOptions in place.
   */
  ListNextSessions: createUseCase('WORKLIST_LIST_NEXT_SESSIONS').noPayload(),

  /**
   * Set Sessions
   * List Sessions Result
   */
  SetSessions: createUseCase('WORKLIST_SET_SESSIONS').withPayload<{
    worklistLineItems: WorklistLineItem[];
    filterOptions: FilterOptions;
    totalItems: number;
  }>(),

  /**
   *
   * Set decrypt sessions line item
   */
  SetDecryptedSessions: createUseCase(
    'WORKLIST_SET_DECRYPTED_SESSIONS'
  ).withPayload<{
    worklistLineItems: WorklistLineItem[];
    filterOptions: FilterOptions;
  }>(),

  /**
   * Set New Sessions
   */
  SetNewSessions: createUseCase('WORKLIST_SET_NEW_SESSIONS').withPayload<{
    worklistLineItems: WorklistLineItem[];
    filterOptions: FilterOptions;
    totalItems: number;
    reset?: boolean;
  }>(),

  /**
   * Unload Sessions
   */
  UnloadSessions: createUseCase('WORKLIST_UNLOAD_SESSIONS').noPayload(),

  /**
   * Set Session LineItem status
   */
  SetSessionLineItemStatus: createUseCase(
    'WORKLIST_SET_SESSION_LINE_ITEM_STATUS'
  ).withPayload<{
    analysisId: string;
    sessionId: string;
    sessionStatus: SessionStatus;
  }>(),

  /**
   * Mark as bookmarked
   */
  MarkAsBookmarked: createUseCase('WORKLIST_MARK_AS_BOOKMARKED').withPayload<{
    sessionId: string;
    isBookmarked: boolean;
  }>(),

  /**
   * Update session assigned workspaces
   */
  SessionAssignedWorkspacesUpdated: createUseCase(
    'WORKLIST_SESSION_ASSIGNED_WORKSPACES_UPDATED'
  ).withPayload<{
    sessionId: string;
    workspaces: string[];
  }>(),

  /**
   * Update session assigned workspaces
   */
  UpdateSessionAssignedWorkspaces: createUseCase(
    'WORKLIST_UPDATE_SESSION_ASSIGNED_WORKSPACES'
  ).withPayload<{
    sessionId: string;
    workspaces: string[];
  }>(),

  /**
   * Add a workspace to multiple sessions
   */
  AddWorkspaceToSessions: createUseCase(
    'WORKLIST_ADD_WORKSPACE_TO_SESSIONS'
  ).withPayload<{
    sessionIds: string[];
    workspace: string;
  }>(),

  /**
   * Set workspace status
   */
  SetBookmarkStatus: createUseCase('WORKLIST_BOOKMARK_STATUS_SET').withPayload<{
    sessionId: string;
    isBookmarked: boolean;
  }>(),

  /**
   * Open completed session line item
   */
  OpenCompletedSession: createUseCase(
    'WORKLIST_OPEN_COMPLETED_SESSION'
  ).withPayload<{
    analysisId: string;
    sessionId: string;
  }>(),

  /**
   * Redirect to session from worklist page
   */
  RedirectToSession: createUseCase('WORKLIST_REDIRECT_TO_SESSION').withPayload<{
    analysisId: string;
    sessionId: string;
  }>(),

  /**
   * Reset a session from the worklist
   */
  ResetSession: createUseCase('WORKLIST_RESET_SESSION').withPayload<{
    sessionId: string;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('WORKLIST_NO_OP').noPayload(),
};

export type WorklistUseCases = ReturnType<
  | typeof worklistUseCase.Init
  | typeof worklistUseCase.StartPoll
  | typeof worklistUseCase.CancelPoll
  | typeof worklistUseCase.SetProfiles
  | typeof worklistUseCase.SetWorkspaces
  | typeof worklistUseCase.WorkspaceStatusList
  | typeof worklistUseCase.SetWorkspaceStatus
  | typeof worklistUseCase.ListSessions
  | typeof worklistUseCase.ListNextSessions
  | typeof worklistUseCase.SetSessions
  | typeof worklistUseCase.SetNewSessions
  | typeof worklistUseCase.UnloadSessions
  | typeof worklistUseCase.SetSessionLineItemStatus
  | typeof worklistUseCase.UpdateStatus
  | typeof worklistUseCase.SetDecryptedSessions
  | typeof worklistUseCase.MarkAsBookmarked
  | typeof worklistUseCase.UpdateSessionAssignedWorkspaces
  | typeof worklistUseCase.AddWorkspaceToSessions
  | typeof worklistUseCase.SessionAssignedWorkspacesUpdated
  | typeof worklistUseCase.SetBookmarkStatus
  | typeof worklistUseCase.OpenCompletedSession
  | typeof worklistUseCase.RedirectToSession
  | typeof worklistUseCase.ResetSession
  | typeof worklistUseCase.NoOp
>;

type IntervalLabel = 'sidebar';

const SESSION_LIST_LIMIT = 25;

export class WorklistReducer extends EffectReducer<Worklist> {
  private apiSession: ContractSession;
  private apiStorage: ContractStorage;
  private apiProfiles: ContractProfiles;
  private apiAuthenticate: ContractAuthenticate;
  private apiEncryption: ContractEncryption;
  private apiWorkspaces: ContractWorkspaces;
  private profile: Profile;
  private pollDispatch?: (useCase: WorklistUseCases) => void;
  private intervalIds: Map<IntervalLabel, NodeJS.Timeout> = new Map();
  private static INTERVAL_SIDEBAR_MS = 60 * 1000;

  constructor(
    apiSession: ContractSession,
    apiStorage: ContractStorage,
    apiAuthenticate: ContractAuthenticate,
    apiProfiles: ContractProfiles,
    apiEncryption: ContractEncryption,
    apiWorkspaces: ContractWorkspaces,
    profile: Profile
  ) {
    super();
    this.apiSession = apiSession;
    this.apiStorage = apiStorage;
    this.apiAuthenticate = apiAuthenticate;
    this.apiProfiles = apiProfiles;
    this.apiEncryption = apiEncryption;
    this.apiWorkspaces = apiWorkspaces;
    this.profile = profile;
  }

  Perform(
    worklist: Worklist = initial(),
    { type, payload: useCase }: WorklistUseCases
  ): ReducerResult<Worklist> {
    switch (type) {
      case worklistUseCase.Init.type: {
        this.CancelLastEffect();
        this.pollDispatch = useCase.dispatch;
        return this.Result(worklist, effectListProfiles(this.apiProfiles));
      }
      case worklistUseCase.StartPoll.type:
        const { group, id, status } = useCase;

        if (!group || !id || !status) {
          // Redirect if route has incomplete parameters
          return this.Result(worklist, none(), [
            appUseCase.Redirect({
              pathname: RouteHelper.worklist('user', this.profile.id, 'active'),
            }),
          ]);
        }

        this.StartBackgroundPollingSidebarFeed(group, id, status);

        const worklistPath = RouteHelper.worklist(group, id, status);
        const clearWorklist = worklistPath !== worklist.lastWorklistPath;

        return this.Result(
          {
            ...worklist,
            worklistLineItems: pair(
              ApiStatus.Idle,
              clearWorklist ? [] : worklist.worklistLineItems.second()
            ),
            lastWorklistPath: RouteHelper.worklist(group, id, status),
          },
          batch([
            effectListProfiles(this.apiProfiles),
            effectListWorkspaces(this.apiWorkspaces),
          ]),
          [
            worklistUseCase.ListSessions({
              group,
              id,
              status,
              reset: true,
            }),
            worklistUseCase.WorkspaceStatusList(),
          ]
        );
      case worklistUseCase.CancelPoll.type:
        this.CancelBackgroundPollingSidebarFeed();
        return this.Result({
          ...worklist,
        });
      case worklistUseCase.SetProfiles.type:
        return this.Result({
          ...worklist,
          profiles: pair(ApiStatus.Idle, useCase.profiles),
        });
      case worklistUseCase.UpdateStatus.type:
        return this.Result({
          ...worklist,
          status: useCase.status,
        });
      case worklistUseCase.SetWorkspaces.type:
        return this.Result({
          ...worklist,
          availableWorkspaces: pair(ApiStatus.Idle, just(useCase.workspaces)),
        });

      case worklistUseCase.WorkspaceStatusList.type:
        return this.Result(
          {
            ...worklist,
            availableWorkspaces: worklist.availableWorkspaces.setFirst(
              ApiStatus.Busy
            ),
          },
          effectListWorkspaceStatus(this.apiSession, this.profile)
        );

      case worklistUseCase.SetWorkspaceStatus.type:
        return this.Result({
          ...worklist,
          workspaceStatus: pair(ApiStatus.Idle, useCase.workspaceStatus),
        });
      case worklistUseCase.SetBookmarkStatus.type:
        return this.Result({
          ...worklist,
          worklistLineItems: worklist.worklistLineItems.mapSecond(a =>
            a.map(item => {
              if (item.id === useCase.sessionId) {
                item.isBookmarked = useCase.isBookmarked;
              }
              return item;
            })
          ),
        });
      case worklistUseCase.ListSessions.type: {
        let useFilterOptions = worklist.filterOptions.lift();
        const { group, id, status } = useCase;

        if (group && id && status) {
          const statusArray = stringToSessionStatusArray(status || '');
          useFilterOptions = {
            group: workspaceGroupsFromString(group),
            id,
            status: statusArray,
            offset: 0,
            limit: SESSION_LIST_LIMIT,
            isBookmarked: status === 'bookmarked' || undefined, // Needs to be undefined if not set to bookmarked
          };
        }
        if (useFilterOptions) {
          const existingSessionLineItems = worklist.worklistLineItems.second();
          return this.Result(
            {
              ...worklist,
              worklistLineItems: pair(
                existingSessionLineItems.length == 0
                  ? ApiStatus.Busy
                  : ApiStatus.Idle,
                existingSessionLineItems
              ),
              filterOptions: just(useFilterOptions),
            },
            batch([
              effectListUpdatedSessions(
                this.apiSession,
                this.apiStorage,
                useFilterOptions,
                useCase.reset
              ),
            ])
          );
        }
        return this.Result(worklist);
      }
      case worklistUseCase.ListNextSessions.type: {
        const filterOptions = worklist.filterOptions.lift();
        if (!filterOptions) {
          return this.Result(worklist);
        }
        const newFilterOptions = {
          ...filterOptions,
          offset: worklist.worklistLineItems.second().length,
        };

        const effect = effectListSessions(
          this.apiSession,
          this.apiStorage,
          newFilterOptions
        );

        return this.Result(
          {
            ...worklist,
            filterOptions: just(newFilterOptions),
            worklistLineItems: worklist.worklistLineItems.setFirst(
              ApiStatus.Busy
            ),
          },
          effect
        );
      }

      case worklistUseCase.SetSessions.type: {
        if (isEqual(worklist.filterOptions.lift(), useCase.filterOptions)) {
          const sessionLineItems = worklist.worklistLineItems
            .setFirst(ApiStatus.Idle)
            .mapSecond(currentWorklistLineItems =>
              worklist.filterOptions.mapOr(
                a =>
                  filterOptionToTargetHash(a) ===
                  filterOptionToTargetHash(useCase.filterOptions),
                false
              )
                ? [...currentWorklistLineItems, ...useCase.worklistLineItems]
                : useCase.worklistLineItems
            );
          return this.Result(
            {
              ...worklist,
              totalAvailableItems: useCase.totalItems,
              worklistLineItems: sessionLineItems,
            },
            effectDecryptSessions(
              sessionLineItems.second(),
              this.apiEncryption,
              this.profile,
              useCase.filterOptions
            )
          );
        }
        return this.Result({ ...worklist });
      }

      case worklistUseCase.SetDecryptedSessions.type: {
        if (isEqual(worklist.filterOptions.lift(), useCase.filterOptions)) {
          return this.Result({
            ...worklist,
            worklistLineItems: worklist.worklistLineItems.setSecond(
              useCase.worklistLineItems
            ),
          });
        }
        return this.Result({ ...worklist });
      }

      case worklistUseCase.SetNewSessions.type: {
        if (isEqual(worklist.filterOptions.lift(), useCase.filterOptions)) {
          const sessionLineItems = worklist.worklistLineItems
            .setFirst(ApiStatus.Idle)
            .mapSecond(currentSessionLineItems => {
              return worklist.filterOptions.mapOr(
                a =>
                  filterOptionToTargetHash(a) ===
                  filterOptionToTargetHash(useCase.filterOptions),
                false
              )
                ? mergeIncomingSessionLineItems(
                    useCase.worklistLineItems,
                    currentSessionLineItems,
                    useCase.reset
                  )
                : currentSessionLineItems;
            });
          return this.Result(
            {
              ...worklist,
              totalAvailableItems: useCase.totalItems,
              worklistLineItems: sessionLineItems,
            },
            effectDecryptSessions(
              sessionLineItems.second(),
              this.apiEncryption,
              this.profile,
              useCase.filterOptions
            )
          );
        }
        return this.Result({ ...worklist });
      }

      case worklistUseCase.UnloadSessions.type: {
        this.CancelBackgroundPollingSidebarFeed();

        // Retain the first page of sessions so they appear instantly when returning to the worklist
        const sessionsToRetain = worklist.worklistLineItems
          .second()
          .filter((_, i) => i < SESSION_LIST_LIMIT);

        return this.Result({
          ...worklist,
          worklistLineItems: pair(ApiStatus.Idle, sessionsToRetain),
          totalAvailableItems: sessionsToRetain.length,
        });
      }

      case worklistUseCase.SetSessionLineItemStatus.type:
        return this.Result(
          {
            ...worklist,
            worklistLineItems: worklist.worklistLineItems.mapSecond(a =>
              a.map(item => {
                if (item.id === useCase.sessionId) {
                  item.status = useCase.sessionStatus;
                }
                return item;
              })
            ),
          },
          effectSetSessionLineItemStatus(
            this.apiSession,
            useCase.sessionId,
            useCase.sessionStatus
          )
        );

      case worklistUseCase.MarkAsBookmarked.type: {
        return this.Result(
          { ...worklist },
          effectUpdateWorklistBookmarked(
            this.apiSession,
            useCase.sessionId,
            useCase.isBookmarked
          )
        );
      }
      case worklistUseCase.UpdateSessionAssignedWorkspaces.type: {
        return this.Result(
          { ...worklist },
          effectUpdateSessionAssignedWorkspaces(
            this.apiSession,
            useCase.sessionId,
            useCase.workspaces
          )
        );
      }

      case worklistUseCase.SessionAssignedWorkspacesUpdated.type: {
        return this.Result({
          ...worklist,
          worklistLineItems: worklist.worklistLineItems.mapSecond(a =>
            a.map(item => {
              if (item.id === useCase.sessionId) {
                item.assignedWorkspaces = useCase.workspaces;
              }
              return item;
            })
          ),
        });
      }

      case worklistUseCase.AddWorkspaceToSessions.type: {
        const selectedSessionLineItems = worklist.worklistLineItems
          .second()
          .filter(item => useCase.sessionIds.includes(item.id));

        return this.Result(
          {
            ...worklist,
            worklistLineItems: worklist.worklistLineItems.mapSecond(a =>
              a.map(item => {
                if (useCase.sessionIds.includes(item.id)) {
                  item.assignedWorkspaces = unique([
                    ...(item.assignedWorkspaces || []),
                    useCase.workspace,
                  ]);
                }
                return item;
              })
            ),
          },
          effectAssignWorkspaceToSessions(
            this.apiSession,
            selectedSessionLineItems,
            useCase.workspace
          )
        );
      }

      case worklistUseCase.OpenCompletedSession.type:
        return this.Result(
          {
            ...worklist,
          },
          effectSetSessionLineItemOpenCompletedSession(
            this.apiSession,
            useCase.sessionId,
            useCase.analysisId
          )
        );

      case worklistUseCase.RedirectToSession.type:
        const newLocation = RouteHelper.study(
          useCase.analysisId,
          useCase.sessionId
        );

        return this.Result(worklist, none(), [
          appUseCase.Redirect({ pathname: newLocation }),
        ]);

      case worklistUseCase.ResetSession.type: {
        const filteredItems = worklist.worklistLineItems
          .second()
          .filter(item => item.id !== useCase.sessionId);
        return this.Result(
          {
            ...worklist,
            worklistLineItems:
              worklist.worklistLineItems.setSecond(filteredItems),
          },
          effectReanalyseSession(this.apiSession, useCase.sessionId, false)
        );
      }

      case worklistUseCase.NoOp.type:
        return this.Result({ ...worklist });
    }
  }

  private poll(
    useCases: WorklistUseCases[],
    intervalLabel: IntervalLabel,
    interval: number,
    instant?: true
  ) {
    if (!this.pollDispatch) {
      return;
    }
    this.cancelPoll(intervalLabel);
    const f = async (retryOnUnauthenticated: boolean, backoff: number) => {
      // Only poll when dispatch is available and user is logged in
      const accessToken = await this.apiAuthenticate.GetCurrentSession();
      if (this.pollDispatch && accessToken) {
        useCases.forEach(this.pollDispatch);
      } else if (retryOnUnauthenticated) {
        setTimeout(() => f(true, backoff * 2), backoff * 1000);
      }
    };
    if (instant) {
      window.requestAnimationFrame(() => {
        f(true, 1);
      });
    }

    this.intervalIds.set(
      intervalLabel,
      setInterval(() => f(false, 1), interval)
    );
  }

  private cancelPoll(intervalLabel: IntervalLabel) {
    const intervalId = this.intervalIds.get(intervalLabel);
    if (intervalId) {
      clearInterval(intervalId);
      this.intervalIds.delete(intervalLabel);
    }
  }

  private StartBackgroundPollingSidebarFeed(
    group?: string,
    id?: string,
    status?: string
  ) {
    this.poll(
      [
        worklistUseCase.WorkspaceStatusList(),
        worklistUseCase.ListSessions({
          group,
          id,
          status,
        }),
      ],
      'sidebar',
      WorklistReducer.INTERVAL_SIDEBAR_MS
    );
  }
  private CancelBackgroundPollingSidebarFeed() {
    this.cancelPoll('sidebar');
  }
}

const effectUpdateWorklistBookmarked = (
  apiSession: ContractSession,
  sessionId: string,
  isBookmarked: boolean
): Effect =>
  effect(async () => {
    await apiSession.UpdateBookmarked(sessionId, isBookmarked);

    return worklistUseCase.SetBookmarkStatus({ sessionId, isBookmarked });
  }, 'effect-worklist-update-bookmark');

/*
 * Retrieve all user profiles whit in the institution
 */
const effectListProfiles = (apiProfiles: ContractProfiles): Effect =>
  effect(async () => {
    const profiles = await apiProfiles.List();
    return worklistUseCase.SetProfiles({ profiles });
  }, 'effect-worklist-list-profiles');

/*
 * Retrieve all workspaces available to the User
 */
const effectListWorkspaces = (apiWorkspaces: ContractWorkspaces): Effect =>
  effect(async () => {
    const workspaces = await apiWorkspaces.List();
    return worklistUseCase.SetWorkspaces({ workspaces });
  }, 'effect-worklist-list-workspaces');

/*
 * Retrieve workspace status for user preferred workspaces
 */
const effectListWorkspaceStatus = (
  apiSession: ContractSession,
  profile: Profile
): Effect =>
  effect(async () => {
    const userFilters = profile.preferences.userFilters || [];
    const workspaceFilters = profile.preferences.workspaceFilters || [];
    const workspaceStatus = await apiSession.GetWorkspaceStatus(
      userFilters,
      workspaceFilters
    );
    return worklistUseCase.SetWorkspaceStatus({ workspaceStatus });
  }, 'effect-worklist-list-workspace-status');

/*
 * Retrieve all sessionLineItems available for the given parameters
 */
const effectListSessions = (
  apiSession: ContractSession,
  apiStorage: ContractStorage,
  params: FilterOptions
): Effect =>
  effect(async () => {
    try {
      const { worklistLineItems, totalItems, filterOptions } =
        await apiSession.List(apiStorage, params);

      return worklistUseCase.SetSessions({
        worklistLineItems,
        filterOptions,
        totalItems,
      });
    } catch (e) {
      return worklistUseCase.UpdateStatus({ status: ApiStatus.Error });
    }
  }, 'effect-worklist-list-sessions');

/*
 * Decrypt all sessionLineItems
 */
const effectDecryptSessions = (
  worklistLineItems: WorklistLineItem[],
  apiEncryption: ContractEncryption,
  profile: Profile,
  filterOptions: FilterOptions
): Effect =>
  effect(async () => {
    try {
      const decryptedSessionLineItems: WorklistLineItem[] = (await Promise.all(
        worklistLineItems.map(item =>
          decryptPatientHealthInfo(apiEncryption, profile, item)
        )
      )) as WorklistLineItem[];

      return worklistUseCase.SetDecryptedSessions({
        worklistLineItems: decryptedSessionLineItems,
        filterOptions,
      });
    } catch (e) {
      return worklistUseCase.UpdateStatus({ status: ApiStatus.Error });
    }
  }, 'effect-worklist-decrypt-sessions');

/*
 * Retrieve all sessionLineItems available for the given parameters
 */
const effectListUpdatedSessions = (
  apiSession: ContractSession,
  apiStorage: ContractStorage,
  params: FilterOptions,
  reset?: boolean
): Effect =>
  effect(async () => {
    const filteredSessionLineItems = await apiSession.List(apiStorage, {
      ...params,
      offset: 0,
      limit: SESSION_LIST_LIMIT,
    });

    const worklistLineItems = filteredSessionLineItems.worklistLineItems.map(
      s => ({
        ...s,
        animated: true,
      })
    );
    return worklistUseCase.SetNewSessions({
      worklistLineItems,
      filterOptions: params,
      totalItems: filteredSessionLineItems.totalItems,
      reset,
    });
  }, 'effect-worklist-list-updated-sessions');

/**
 * Change the status of a session line item
 */
const effectSetSessionLineItemStatus = (
  apiSession: ContractSession,
  sessionId: string,
  sessionStatus: SessionStatus
): Effect =>
  effect(async () => {
    await apiSession.UpdateStatus(sessionId, sessionStatus);
    return worklistUseCase.NoOp();
  }, 'effect-worklist-set-session-line-item-status');

/**
 * Open the completed session
 */
const effectSetSessionLineItemOpenCompletedSession = (
  apiSession: ContractSession,
  sessionId: string,
  analysisId: string
): Effect =>
  effect(async () => {
    await apiSession.UpdateStatus(sessionId, SessionStatus.InProgress);
    return worklistUseCase.RedirectToSession({ analysisId, sessionId });
  }, 'effect-worklist-set-session-line-item-status');

/*
 * Update session assigned workspaces
 */
const effectUpdateSessionAssignedWorkspaces = (
  apiSession: ContractSession,
  sessionId: string,
  workspaces: string[]
): Effect =>
  effect(async () => {
    await apiSession.UpdateAssignedWorkspaces(sessionId, workspaces);

    return worklistUseCase.SessionAssignedWorkspacesUpdated({
      sessionId,
      workspaces,
    });
  }, 'effect-worklist-update-session-assigned-workspaces');

/*
 * Assign a workspace to sessions
 */
const effectAssignWorkspaceToSessions = (
  apiSession: ContractSession,
  sessions: WorklistLineItem[],
  workspace: string
): Effect =>
  effect(async () => {
    const promises: Promise<string>[] = [];

    sessions.forEach(session => {
      const workspaces = unique([
        ...(session.assignedWorkspaces || []),
        workspace,
      ]);

      promises.push(
        apiSession.UpdateAssignedWorkspaces(session.id, workspaces)
      );
    });

    await Promise.all(promises);

    return worklistUseCase.NoOp();
  }, 'effect-worklist-assign-workspace-to-sessions');

function mergeIncomingSessionLineItems(
  incoming: WorklistLineItem[],
  current: WorklistLineItem[],
  reset?: boolean
) {
  const currentIds = current.map(item => item.id);

  if (reset) {
    return incoming;
  }

  return [
    ...incoming.filter(item => !currentIds.includes(item.id)),
    ...current,
  ];
}

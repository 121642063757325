import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import {
  toSearch,
  toAdmin,
  toPACS,
  toProfile,
  toAppSettings,
} from '@interfaces/App';
import { toApp } from '@interfaces/Root';
import { toInstitutions } from '@interfaces/Admin';
import * as ViewModelLayout from '@webViewModels/Pages/Settings/Layout';
import { ApiStatus } from '@interfaces/Status';
import { Message } from '@interfaces/Message';
import { InstitutionLineItem } from '@interfaces/Admin/InstitutionLineItem';
import * as I from '@interfaces/Admin/InstitutionLineItem';
import {
  create,
  createInstitutionUser,
  get,
  unload,
  deleteInstitutionUser,
} from '@webViewInterfaces/Settings/Admin/Institutions';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import Maybe from '@monads/Maybe';
import { Profile } from '@interfaces/Profile';
import { Institution } from '@entities/Admin/Institution';
import { updateUser } from '@webViewInterfaces/Settings/Admin/Users';

export type Institutions = InstitutionsProps &
  InstitutionsDispatchers &
  ViewModelLayout.Layout;

export interface InstitutionsProps {
  profile: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  loading: boolean;
  messages: Message[];
  institutionLineItems: InstitutionLineItem[];
  mode: 'list' | 'detail';
  targetInstitutionLineItem: Maybe<InstitutionLineItem>;
  targetInstitution: Maybe<Institution>;
  targetInstitutionUser: Maybe<Profile>;
}

export interface InstitutionsDispatchers {
  dispatchCreateInstitution: (
    institution: I.InstitutionLineItem
  ) => Dispatch<Actions>;
  dispatchCreateInstitutionUser: (userLineItem: Profile) => Dispatch<Actions>;
  dispatchDeleteInstitutionUser: (id: string) => Dispatch<Actions>;
  dispatchGetInstitution: (id: string) => Dispatch<Actions>;
  dispatchUnloadTargetInstitution: () => Dispatch<Actions>;
  dispatchUnloadTargetInstitutionUser: () => Dispatch<Actions>;
  dispatchUpdateUser: (userLineItem: Profile) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any): InstitutionsDispatchers => ({
  ...ViewModelLayout.dispatchers(dispatch),
  dispatchCreateInstitution: (institution: I.InstitutionLineItem) =>
    dispatch(create(institution)),
  dispatchCreateInstitutionUser: (userLineItem: Profile) =>
    dispatch(createInstitutionUser(userLineItem)),
  dispatchDeleteInstitutionUser: (id: string) =>
    dispatch(deleteInstitutionUser(id)),
  dispatchGetInstitution: (id: string) => dispatch(get(id)),
  dispatchUnloadTargetInstitution: () => dispatch(unload('institution')),
  dispatchUnloadTargetInstitutionUser: () => dispatch(unload('user')),
  dispatchUpdateUser: (userLineItem: Profile) =>
    dispatch(updateUser(userLineItem)),
});

export const fromState = (state: State): InstitutionsProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }
  const profile = toProfile(app);
  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const search = toSearch(app);
  const searchQuery = search.query;
  const admin = toAdmin(app);
  const institutions = toInstitutions(admin);

  const mode =
    institutions.targetInstitution.first() === ApiStatus.Busy ||
    institutions.targetInstitution.second().isJust()
      ? 'detail'
      : 'list';
  const targetInstitution = institutions.targetInstitution.second();
  const targetInstitutionLineItem = institutions.targetInstitutionLineItem;
  const targetInstitutionUser = institutions.targetUser;

  const messages = institutions.messages;
  const loading =
    institutions.institutionLineItems.first() === ApiStatus.Busy ||
    institutions.targetInstitution.first() === ApiStatus.Busy;
  const institutionLineItems = institutions.institutionLineItems.second();
  const pacs = toPACS(app);
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;

  return {
    mode,
    profile,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    searchQuery,
    pacsStudyLineItems,
    pacsSystemStatus,
    loading,
    messages,
    institutionLineItems,
    targetInstitutionLineItem,
    targetInstitution,
    targetInstitutionUser,
  };
};

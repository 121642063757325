import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Header, HeaderSlot } from '@webOrganisms/Header/Header';
import { Sidebar } from '@webOrganisms/Sidebar/Sidebar';
import { SidebarGroup } from '@webOrganisms/SidebarGroup/SidebarGroup';
import { SidebarItem } from '@webOrganisms/SidebarItem/SidebarItem';
import { Icon } from '@webMolecules/Icon/Icon';
import { Profile } from '@interfaces/Profile';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import { Button } from '@webMolecules/Button/Button';
import { t } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@entities/PACSStudyLineItem';
import { useAuthenticationMode } from '@interfaces/Authentication';
import styles from './layout.scss';

export interface LayoutProps {
  onSignOut: () => void;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  profile: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  onSignOut,
}) => {
  const { isFederatedIdentity, isAdminDomain, isSAMLBypass } =
    useAuthenticationMode();

  return (
    <>
      <Header profile={profile} onSignOut={onSignOut} searchQuery={searchQuery}>
        <HeaderSlot>
          <RouteLink
            data-testid="test-settings-btn"
            to={RouteHelper.lastWorklistPath()}
            component={Button}
            iconBefore={<Icon name="arrow-left" />}
          >
            {t('sidebar.settings')}
          </RouteLink>
        </HeaderSlot>
      </Header>
      <Box display="flex">
        <Sidebar
          pacsStudyLineItems={pacsStudyLineItems}
          pacsSystemStatus={pacsSystemStatus}
          regulatoryVersion={regulatoryVersion}
          regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
        >
          <SidebarGroup>
            {!isAdminDomain && !isSAMLBypass ? (
              <SidebarItem
                icon={<Icon name="user" />}
                title={t('pages.settings.sidebar.profile')}
                to={RouteHelper.settingsProfile}
              />
            ) : null}

            {!isFederatedIdentity && !isAdminDomain && !isSAMLBypass ? (
              <SidebarItem
                icon={<Icon name="lock" />}
                title={t('pages.settings.sidebar.security')}
                to={RouteHelper.settingsSecurity}
              />
            ) : null}
          </SidebarGroup>
          {profile.isAdmin && (
            <SidebarGroup heading={t('pages.settings.sidebar.admin.heading')}>
              <SidebarItem
                icon={<Icon name="package" />}
                title={t('pages.settings.sidebar.application')}
                to={RouteHelper.settingsApplication}
              />
              <SidebarItem
                icon={<Icon name="users" />}
                title={t('pages.settings.sidebar.admin.users')}
                to={RouteHelper.adminUsers()}
              />
              <SidebarItem
                icon={<Icon name="sliders" />}
                title={t('pages.settings.sidebar.admin.workspaces')}
                to={RouteHelper.adminWorkspaces()}
              />
              {isAdminDomain ? (
                <SidebarItem
                  icon={<Icon name="zap" />}
                  title={t('pages.settings.sidebar.admin.institutions')}
                  to={RouteHelper.adminInstitutions()}
                />
              ) : null}
            </SidebarGroup>
          )}
        </Sidebar>
        <Box
          className={styles.layoutContainer}
          role="main"
          flex="1"
          padding="xl"
          scrollable="y"
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

import React from 'react';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';

export interface ResultTableSuffixProps {
  unitI18NKey?: string;
  wrapper?: boolean;
}

export const ResultTableSuffix: React.FC<ResultTableSuffixProps> = ({
  unitI18NKey,
  wrapper = true,
}) => {
  if (!unitI18NKey) {
    return null;
  }
  return wrapper ? (
    <Box display="flex" alignItems="center">
      <Text marginLeft="xs" type="body2" muted>
        {t(unitI18NKey)}
      </Text>
    </Box>
  ) : (
    <Text marginLeft="xs" type="body2" muted>
      {t(unitI18NKey)}
    </Text>
  );
};

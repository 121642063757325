import React from 'react';
import { Icon } from '@webMolecules/Icon/Icon';
import { Finding } from '@entities/Finding';
import { calculatePercentageDifference } from '@helpers/number';
import { FindingNoduleSizeValues } from './FindingNoduleSizeValues';
import { FindingNoduleSeparator } from './FindingNoduleSeparator';

interface FindingNoduleSizeDifferenceValuesProps {
  finding: Finding;
  comparedFinding: Finding;
}

export const FindingNoduleSizeDifferenceValues = ({
  finding,
  comparedFinding,
}: FindingNoduleSizeDifferenceValuesProps) => {
  return (
    <>
      {finding.formatted.isSize3D ? (
        <>
          <FindingNoduleSizeValues
            side="width"
            value={percentageDifference(finding, comparedFinding, 'width')}
            isCompare
          />
          <FindingNoduleSeparator
            symbol={<Icon name="bullet-separator" fluid />}
          />
          <FindingNoduleSizeValues
            side="height"
            value={percentageDifference(finding, comparedFinding, 'height')}
            isCompare
          />
          <FindingNoduleSeparator
            symbol={<Icon name="bullet-separator" fluid />}
          />
          <FindingNoduleSizeValues
            side="length"
            value={percentageDifference(finding, comparedFinding, 'length')}
            isCompare
          />
          <FindingNoduleSeparator symbol="|" />
          <FindingNoduleSizeValues
            side="volume"
            value={percentageDifference(finding, comparedFinding, 'volume')}
            isCompare
          />
        </>
      ) : (
        <FindingNoduleSizeValues
          side="size"
          value={percentageDifference(finding, comparedFinding, 'max')}
          isCompare
        />
      )}
    </>
  );
};

function percentageDifference(
  finding: Finding,
  comparedFinding: Finding,
  dimension: 'max' | 'length' | 'width' | 'height' | 'volume'
): number {
  const previous = comparedFinding.formatted[dimension]?.value;
  const current = finding.formatted[dimension]?.value;
  if (previous === undefined || current === undefined) {
    return NaN;
  }
  return calculatePercentageDifference(previous, current);
}

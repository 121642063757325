import { SessionLineItem } from '@interfaces/SessionLineItem';
import { PatientReportInfo } from '@interfaces/PatientInfo';
import Maybe, { just, nothing } from '@monads/Maybe';
import {
  SessionConfiguration,
  SessionMetadata,
} from '@entities/SessionLineItem';
import { Storage } from '@contracts/Storage';
import { PhiCipher } from '@interfaces/Session';
import { stringToStudyType } from '@interfaces/StudyType';
import { schemas } from '@api/schema/client';
import { decode as decodeDate } from './date';
import { getStorageJsonFile } from './Shared';
import { SessionStatusMap, decodeEnum } from './Enum';

export const decode = async (
  storage: Storage,
  data: schemas['routers__session_data__SessionResponse']
): Promise<SessionLineItem> => {
  const id = data.session_id;
  const userId = data.user_profile_id ?? '';
  const collaborators = data.collaborator_user_profile_ids ?? [];
  const analysisId = data.analysis_id;
  const comparisonSessionId = null;
  const comparisonAnalysisId = null;
  const studyMetadataS3Key = data.study_metadata_s3_key;
  const institutionId = data.institution_id;
  const institutionName = ''; // TODO
  const configuration = {} as SessionConfiguration; // TODO
  const assignedWorkspaces = data.assigned_workspaces ?? [];
  const studyType = stringToStudyType(data.study_type);
  const date = decodeDate(data.pacs_scan_date || '');
  const phiCipher = (await getStorageJsonFile(
    storage,
    studyMetadataS3Key
  )) as PhiCipher;

  const patientName = '';

  const patientInfo = {
    sessionId: data.session_id,
    operatorCode: data.operator_code,
  } as PatientReportInfo;

  const accessionNumber = data.accession_id;
  const status = decodeEnum(data.status, SessionStatusMap);

  const generatedPDF: Maybe<string> = data.completed_report_pdf_path
    ? just(data.completed_report_pdf_path)
    : nothing();

  const isBookmarked = data.is_bookmarked;

  const metadata: SessionMetadata = data.encrypted_metadata ?? {};

  return {
    id,
    analysisId,
    userId,
    date,
    patientName,
    accessionNumber,
    patientInfo,
    status,
    studyType,
    configuration,
    generatedPDF,
    phiCipher: phiCipher?.phi_b64_cipher,
    decryptedPhi: undefined,
    live: true,
    institutionId,
    collaborators,
    institutionName,
    animated: false,
    decrypted: false,
    metadata,
    isBookmarked,
    comparisonSessionId,
    comparisonAnalysisId,
    assignedWorkspaces,
  };
};

export const encodeMetadata = (
  metadata: SessionMetadata
): Record<string, string> => {
  const encoded: Record<string, string> = {};
  if (metadata.encryptedComments) {
    encoded.encryptedComments = metadata.encryptedComments;
  }
  if (metadata.encryptedClinicalFindings) {
    encoded.encryptedClinicalFindings = metadata.encryptedClinicalFindings;
  }

  if (metadata.encryptedOtherHistory) {
    encoded.encryptedOtherHistory = metadata.encryptedOtherHistory;
  }
  if (metadata.encryptedWorksheetExtraInfo) {
    encoded.encryptedWorksheetExtraInfo = metadata.encryptedWorksheetExtraInfo;
  }

  return encoded;
};

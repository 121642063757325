import React from 'react';
import { Icon } from '@webMolecules/Icon/Icon';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './form-hint.scss';

export enum FormHintIntent {
  Error = 'form-hint-intent__error',
  Information = 'form-hint-intent__information',
  Neutral = 'form-hint-intent__neutral',
  Success = 'form-hint-intent__success',
  Warning = 'form-hint-intent__warning',
}

export const formHintIntentFromString = (input: string): FormHintIntent => {
  switch (input) {
    case 'error':
      return FormHintIntent.Error;
    case 'information':
      return FormHintIntent.Information;
    case 'success':
      return FormHintIntent.Success;
    case 'warning':
      return FormHintIntent.Warning;
    default:
    case 'neutral':
      return FormHintIntent.Neutral;
  }
};

const stringFromFormHintIntent = (input: FormHintIntent): string => {
  switch (input) {
    case FormHintIntent.Error:
      return 'error';
    case FormHintIntent.Information:
      return 'information';
    case FormHintIntent.Success:
      return 'success';
    case FormHintIntent.Warning:
      return 'warning';
    default:
    case FormHintIntent.Neutral:
      return 'neutral';
  }
};

export interface FormHintProps {
  intent: FormHintIntent;
  action?: React.ReactNode;
  children: string;
}

export const FormHint: React.FC<FormHintProps> = ({
  intent,
  children,
  action,
}) => {
  const classNames = cnames(
    styles.hint,
    styles[stringFromFormHintIntent(intent)]
  );

  return (
    <Box className={`${classNames}`} display="flex">
      <Box padding="m" paddingRight="none">
        {(() => {
          switch (intent) {
            case FormHintIntent.Error:
              return <Icon name="alert-circle" />;
            case FormHintIntent.Information:
              return <Icon name="help-circle" />;
            case FormHintIntent.Neutral:
              return <Icon name="help-circle" />;
            case FormHintIntent.Success:
              return <Icon name="check-circle" />;
            case FormHintIntent.Warning:
              return <Icon name="alert-triangle" />;
          }
        })()}
      </Box>
      <Box padding="m">
        <Text type="body2" display="block">
          {children}
        </Text>
      </Box>
      {action && (
        <Box padding="xs" display="flex" alignItems="center">
          {action}
        </Box>
      )}
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Finding } from '@entities/Finding';
import { FindingSummaryRowPills } from '../FindingsTable/FindingSummaryRowPills';
import styles from './comparison.scss';
import { InlineDicomImageGrid } from './Utility/InlineDicomImageGrid';

export interface ComparisonFindingDetailProps {
  finding: Finding;
}

export const ComparisonFindingDetail = ({
  finding,
}: ComparisonFindingDetailProps) => {
  return (
    <Box paddingX="s" paddingBottom="xs" marginLeft="xl" paddingTop="xs">
      <FindingSummaryRowPills finding={finding} />
      <Box className={styles.divider}></Box>
      <InlineDicomImageGrid
        dicomIds={finding.formatted.dicomIds ?? []}
        showControls
      />
    </Box>
  );
};

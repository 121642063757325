import { unzip } from 'unzipit';

export const Unzip = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = [];
  if (event.target.files) {
    const file = event.target.files[0];
    const { entries } = await unzip(file);
    for (const entryKey in entries) {
      const entry = entries[entryKey];
      if (!entry.isDirectory) {
        const blob = await entry.blob();
        const fileName = entry.name.split('/').pop() as string;
        const file = new File([blob], fileName);
        const checkIsValid = await checkIsDicom(file);
        if (checkIsValid) {
          files.push(file);
        }
      }
    }
  }
  return files;
};

const MAX_BYTE_LENGTH = 250;
const DICOM_SIGNATURE_LENGTH = 8;
const DICOM_MIME_SIGNATURE = '4449434D';
export const checkIsDicom = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = e => {
      try {
        if (e.target && e.target.result) {
          if (e.target.result instanceof ArrayBuffer) {
            const arr = new Uint8Array(e.target.result).subarray(0, 1000);
            let header = '';
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            //Truncate leading 0s
            header = header.replace(/^0+/, '');
            //Take only the signature we are interested in
            header = header.slice(0, DICOM_SIGNATURE_LENGTH);
            if (header.toUpperCase() === DICOM_MIME_SIGNATURE) {
              resolve(true);
            }
          }
        }
        resolve(false);
      } catch (e) {
        reject(e);
      }
    };
    //Take only the first 250 bytes, not entire file
    fileReader.readAsArrayBuffer(file.slice(0, MAX_BYTE_LENGTH));
  });
};

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { createAccount } from '@webViewInterfaces/Pages/Authenticate/CreateAccount';
import { Message } from '@interfaces/Message';
import { getSearchParam } from '@webInterfaces/ReactRouter';
import { toAuth, toLatestMessage } from '@interfaces/Root';
import { isBusy, isDomainBlocked } from '@interfaces/Authentication';

export type CreateAccount = CreateAccountProps & CreateAccountDispatchers;

export interface CreateAccountProps {
  loading: boolean;
  blocked: boolean;
  latestMessage?: Message;
  email: string;
  password: string;
}

export interface CreateAccountDispatchers {
  dispatchCreateAccount: (
    email: string,
    newPassword: string,
    currentPassword: string,
    firstName: string,
    lastName: string
  ) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  dispatchCreateAccount: (
    email: string,
    newPassword: string,
    currentPassword: string,
    firstName: string,
    lastName: string
  ) =>
    dispatch(
      createAccount(email, newPassword, currentPassword, firstName, lastName)
    ),
});

export const fromState = (state: State): CreateAccountProps => {
  const auth = toAuth(state);
  const blocked = isDomainBlocked(auth);
  const loading = isBusy(auth);
  const email = getSearchParam('email') || getSearchParam('username');
  const password = getSearchParam('password');
  const latestMessage = toLatestMessage(state);
  return {
    loading,
    blocked,
    latestMessage,
    email,
    password,
  };
};

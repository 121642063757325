import React from 'react';
import { useController } from 'react-hook-form';
import debounce from 'lodash/debounce';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { Box } from '@webMolecules/Box/Box';
import { ResultTableNumberConfig } from '@config/ResultTable/ResultTable';
import { AnyUnit } from '@entities/Unit';
import { convertAndRoundByUnit } from '@handlers/UnitHandler';
import { isBlank } from '@helpers/string';
import { DEBOUNCE_TIME } from '@webViews/constant';
import { Input } from '@webMolecules/Input/Input';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { ResultTableSuffix } from './Shared/Suffix';

export interface ResultTableCellNumberProps extends ResultTableSharedProps {
  type: 'integer' | 'float';
  config?: ResultTableNumberConfig;
  disabled?: boolean;
  unitI18NKey?: string;
  unit?: AnyUnit;
}

export const ResultTableCellNumber: React.FC<ResultTableCellNumberProps> = ({
  path,
  config,
  disabled,
  unitI18NKey,
  unit: targetUnit,
}) => {
  const { theme } = React.useContext(ResultTableContext);

  const { displayValue, onChange, onBlur, onKeyDown } = useNumberUnitField({
    path: path ?? '',
    targetUnit,
  });

  if (theme == ResultTableStyle.preview) {
    return displayValue != null ? (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Text data-testid="result-table-number-preview" type="body2">
          {displayValue}
        </Text>
        <ResultTableSuffix unitI18NKey={unitI18NKey} wrapper={false} />
      </Box>
    ) : null;
  }

  const showWarning = config?.showSizeWarning && displayValue == undefined;

  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      <Input
        data-testid="result-table-number-editable"
        disabled={disabled}
        readOnly={config?.readOnly}
        fluid
        size="small"
        defaultValue={displayValue}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={5}
      />
      <ResultTableSuffix unitI18NKey={unitI18NKey} />

      {showWarning && (
        <Box style={{ position: 'absolute', right: 0 }} paddingRight="xl">
          <Icon name="alert-triangle" color="var(--ds-color-warning-500)" />
        </Box>
      )}
    </Box>
  );
};

interface NumberUnit {
  value: number;
  unit: AnyUnit;
}

export type NumberUnitField = ReturnType<typeof useNumberUnitField>;

export const useNumberUnitField = ({
  path,
  targetUnit,
}: {
  path: string;
  targetUnit?: AnyUnit;
}) => {
  if (!targetUnit) {
    throw new Error(`No target unit for path: ${path}`);
  }

  const { field } = useController({
    name: path,
  });

  const numberUnit = getNumberUnit(field.value);

  const displayValue = numberUnit
    ? convertAndRoundByUnit(numberUnit.value, numberUnit.unit, targetUnit)
    : undefined;

  const handleChange = (value: string, referenceId?: string) => {
    if (isBlank(value)) {
      if (value != undefined) {
        field.onChange(null);
      }
      return;
    }

    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) return;

    // Don't trigger when there is no change
    if (parsedValue === displayValue) return;

    field.onChange({
      value: parsedValue,
      unit: targetUnit,
      referenceId,
    });
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange.cancel();
    handleChange(e.target.value);
  };

  const onChange = debounce(onBlur, DEBOUNCE_TIME);

  return {
    displayValue,
    referenceId: numberUnit?.referenceId,
    onChange,
    onStringChange: handleChange,
    onBlur,
    onKeyDown: handleKeyDown,
  };
};

const handleKeyDown = (event: React.KeyboardEvent) => {
  const key = event.key;
  const isValidKey =
    key.match(/^[0-9.]$/) !== null ||
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    key === 'Backspace' ||
    key === 'Delete' ||
    key === 'ArrowLeft' ||
    key === 'ArrowRight' ||
    key === 'Tab' ||
    key === 'Enter';

  if (!isValidKey) {
    event.preventDefault();
  }
};

interface NumberUnit {
  value: number;
  unit: AnyUnit;
  referenceId?: string;
}

export function getNumberUnit(object: unknown): NumberUnit | null {
  if (object == null) {
    return null;
  }
  if (
    typeof object === 'object' &&
    'value' in object &&
    typeof object.value === 'number' &&
    'unit' in object &&
    typeof object.unit === 'string'
  ) {
    if ('referenceId' in object && typeof object.referenceId === 'string') {
      return {
        value: object.value,
        unit: object.unit as AnyUnit,
        referenceId: object.referenceId,
      };
    } else {
      return {
        value: object.value,
        unit: object.unit as AnyUnit,
      };
    }
  }
  console.warn(`Invalid size value: ${JSON.stringify(object)}`);
  return null;
}

import React from 'react';
import styles from './image.scss';
import { Box } from './../Box/Box';

export interface WithRatioProps {
  mode?: string;
  ratio?: 'square' | 'portrait' | 'landscape';
  roundedCorners?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge' | 'jumbo';
}

export interface ImageProps extends WithRatioProps {
  circle?: boolean;
  fluid?: boolean;
  role?: string;
  src?: string;
  srcSet?: string;
  width?: number;
  height?: number;
  alt?: string;
  loading?: 'lazy' | 'eager';
  'data-testid'?: string;
  crossOrigin?: '' | 'anonymous' | 'use-credentials';
}

const getTypeAndMode = (
  ratio: WithRatioProps['ratio']
): null | [ratio: WithRatioProps['ratio'], mode: string] => {
  if (!ratio) return null;
  return [ratio, 'contain'];
};

export const WithRatio: React.FC<WithRatioProps> = ({
  children,
  ratio,
  size,
  roundedCorners,
}) => {
  const typeAndMode = getTypeAndMode(ratio);
  if (!typeAndMode) return <>{children}</>;
  const [type, mode] = typeAndMode;
  const ratioClasses = [
    roundedCorners ? styles.rounded : '',
    type ? styles[type] : '',
    styles[mode],
    styles.ratioOuter,
  ].join(' ');
  return (
    <Box className={size && styles[size]}>
      <Box className={ratioClasses}>
        <Box className={styles.ratioInner}>{children}</Box>
      </Box>
    </Box>
  );
};

export const Image: React.FC<ImageProps> = ({
  roundedCorners = true,
  fluid,
  circle,
  srcSet,
  width,
  height,
  role,
  src,
  ratio,
  size,
  alt,
  loading,
  crossOrigin,
  ...props
}) => {
  const classNames = [
    roundedCorners ? styles.rounded : '',
    fluid || ratio ? styles.fluid : '',
    circle ? styles.circle : '',
    size ? styles[size] : '',
    styles.image,
  ].join(' ');

  return (
    <WithRatio
      ratio={circle ? 'square' : ratio}
      roundedCorners={roundedCorners}
      size={size}
    >
      <img
        crossOrigin={crossOrigin}
        data-testid={props['data-testid']}
        className={classNames}
        src={src}
        srcSet={srcSet}
        alt={alt}
        width={width}
        height={height}
        role={role}
        loading={loading}
      />
    </WithRatio>
  );
};

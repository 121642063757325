import { AdminWorkspaces, initial } from '@entities/Admin/Workspaces';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import { Message } from '@entities/Message';
import { ApiStatus } from '@entities/Status';
import { FilterConfiguration as ContractFilterConfiguration } from '@contracts/Admin/FilterConfiguration';
import { Location as ContractLocation } from '@contracts/Location';
import * as E from '@entities/FilterConfiguration';
import { FilterConfigurationLineItem } from '@entities/Admin/FilterConfigurationLineItem';
import { FilterConfigurationPropertyOperator } from '@entities/FilterConfiguration';
import Result, { ok, err } from '@monads/Result';
import { notificationUseCase } from '@useCases/Notifications';
import Effect, { effect, none } from '@library/Effect';
import { nothing, just } from '@monads/Maybe';
import * as RouteHelper from '@helpers/routes';
import { createUseCase } from '@helpers/createUseCase';

export const adminWorkspacesUseCase = {
  /**
   * Load Filter Configurations
   */
  Load: createUseCase('ADMIN_WORKSPACES_LOAD').noPayload(),

  /**
   * Load Filter Configurations - Result
   */
  LoadResult: createUseCase('ADMIN_WORKSPACES_LOAD_RESULT').withPayload<{
    filterConfigurationLineItems: FilterConfigurationLineItem[];
  }>(),

  /**
   * On Unload
   */
  Unload: createUseCase('ADMIN_WORKSPACES_UNLOAD').withPayload<{
    targetOnly?: boolean;
  }>(),

  /**
   * Prepare a new FilterConfiguration
   */
  CreateEmpty: createUseCase('ADMIN_WORKSPACES_CREATE_EMPTY').noPayload(),

  /**
   * Store a newly created FilterConfiguration
   */
  Create: createUseCase('ADMIN_WORKSPACES_CREATE').withPayload<{
    filterConfiguration: E.FilterConfiguration;
  }>(),

  /**
   * Store a newly created FilterConfiguration - Result
   */
  CreateResult: createUseCase('ADMIN_WORKSPACES_CREATE_RESULT').withPayload<{
    id: Result<string>;
  }>(),

  /**
   * Get a FilterConfiguration
   */
  Get: createUseCase('ADMIN_WORKSPACES_GET').withPayload<{
    id: string;
  }>(),

  /**
   * Get a FilterConfiguration - Result
   */
  GetResult: createUseCase('ADMIN_WORKSPACES_GET_RESULT').withPayload<{
    filterConfiguration: Result<E.FilterConfiguration>;
  }>(),

  /**
   * Update a FilterConfiguration
   */
  Update: createUseCase('ADMIN_WORKSPACES_UPDATE').withPayload<{
    filterConfiguration: E.FilterConfiguration;
  }>(),

  /**
   * Update a FilterConfiguration - Result
   */
  UpdateResult: createUseCase('ADMIN_WORKSPACES_UPDATE_RESULT').withPayload<{
    success: boolean;
  }>(),

  /**
   * Destroy a FilterConfiguration
   */
  Destroy: createUseCase('ADMIN_WORKSPACES_DESTROY').withPayload<{
    id: string;
  }>(),

  /**
   * Destroy a FilterConfiguration - Result
   */
  DestroyResult: createUseCase('ADMIN_WORKSPACES_DESTROY_RESULT').withPayload<{
    success: boolean;
  }>(),

  /**
   * Sets a message
   */
  SetMessage: createUseCase('ADMIN_WORKSPACES_SET_MESSAGE').withPayload<{
    message: Message;
    clearOthers: boolean;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('ADMIN_WORKSPACES_NO_OP').noPayload(),
};

/**
 *  All UseCases
 * */
export type AdminWorkspacesUseCases = ReturnType<
  | typeof adminWorkspacesUseCase.Load
  | typeof adminWorkspacesUseCase.LoadResult
  | typeof adminWorkspacesUseCase.Unload
  | typeof adminWorkspacesUseCase.CreateEmpty
  | typeof adminWorkspacesUseCase.Create
  | typeof adminWorkspacesUseCase.CreateResult
  | typeof adminWorkspacesUseCase.Get
  | typeof adminWorkspacesUseCase.GetResult
  | typeof adminWorkspacesUseCase.Update
  | typeof adminWorkspacesUseCase.UpdateResult
  | typeof adminWorkspacesUseCase.Destroy
  | typeof adminWorkspacesUseCase.DestroyResult
  | typeof adminWorkspacesUseCase.SetMessage
  | typeof adminWorkspacesUseCase.NoOp
>;

export class AdminWorkspacesReducer extends EffectReducer<AdminWorkspaces> {
  private apiFilterConfiguration: ContractFilterConfiguration;
  private apiLocation: ContractLocation;

  constructor(
    apiFilterConfiguration: ContractFilterConfiguration,
    apiLocation: ContractLocation
  ) {
    super();
    this.apiFilterConfiguration = apiFilterConfiguration;
    this.apiLocation = apiLocation;
  }

  Perform(
    adminWorkspaces: AdminWorkspaces = initial(),
    { type, payload: useCase }: AdminWorkspacesUseCases
  ): ReducerResult<AdminWorkspaces> {
    switch (type) {
      case adminWorkspacesUseCase.Load.type: {
        return this.Result(
          {
            ...adminWorkspaces,
            targetFilterConfiguration: nothing(),
            apiStatus: ApiStatus.Busy,
          },
          effectFilterConfigurationList(this.apiFilterConfiguration)
        );
      }

      case adminWorkspacesUseCase.LoadResult.type:
        return this.Result({
          ...adminWorkspaces,
          apiStatus: ApiStatus.Idle,
          filterConfigurationLineItems: useCase.filterConfigurationLineItems,
        });

      case adminWorkspacesUseCase.Unload.type: {
        this.CancelLastEffect();
        if (useCase.targetOnly) {
          return this.Result(
            {
              ...adminWorkspaces,
              targetFilterConfiguration: nothing(),
            },
            effectUpdateLocation(this.apiLocation)
          );
        }
        return this.Result(initial());
      }

      case adminWorkspacesUseCase.CreateEmpty.type:
        return this.Result({
          ...adminWorkspaces,
          targetFilterConfiguration: just({
            name: '',
            description: '',
            properties: [
              {
                column: 'institution_name',
                operator: FilterConfigurationPropertyOperator.Contains,
                value: '',
              },
            ],
            isCustomWorkspace: false,
          }),
        });

      case adminWorkspacesUseCase.Create.type:
        return this.Result(
          {
            ...adminWorkspaces,
            apiStatus: ApiStatus.Busy,
          },
          effectFilterConfigurationCreate(
            this.apiFilterConfiguration,
            useCase.filterConfiguration
          )
        );

      case adminWorkspacesUseCase.CreateResult.type: {
        const intent = useCase.id.isOk() ? 'success' : 'error';
        const message = notificationUseCase.Add({
          notification: {
            intent,
            titleI18nKey: `admin.filter_configuration.create.${intent}`,
          },
        });

        return this.Result(adminWorkspaces, none(), [
          adminWorkspacesUseCase.Load(),
          message,
        ]);
      }

      case adminWorkspacesUseCase.Get.type:
        return this.Result(
          {
            ...adminWorkspaces,
            targetFilterConfiguration: nothing(),
            apiStatus: ApiStatus.Busy,
          },
          effectFilterConfigurationGet(this.apiFilterConfiguration, useCase.id)
        );

      case adminWorkspacesUseCase.GetResult.type: {
        return this.Result(
          {
            ...adminWorkspaces,
            apiStatus: ApiStatus.Idle,
            targetFilterConfiguration: useCase.filterConfiguration.toMaybe(),
          },
          none(),
          useCase.filterConfiguration.isOk()
            ? []
            : [
                notificationUseCase.Add({
                  notification: {
                    intent: 'error',
                    titleI18nKey: 'admin.filter_configuration.get.error',
                  },
                }),
              ]
        );
      }

      case adminWorkspacesUseCase.Update.type: {
        const prevFilterConfiguration =
          adminWorkspaces.targetFilterConfiguration.lift() ||
          useCase.filterConfiguration;
        return this.Result(
          {
            ...adminWorkspaces,
            apiStatus: ApiStatus.Busy,
          },
          effectFilterConfigurationUpdate(
            this.apiFilterConfiguration,
            prevFilterConfiguration,
            useCase.filterConfiguration
          )
        );
      }

      case adminWorkspacesUseCase.UpdateResult.type: {
        const intent = useCase.success ? 'success' : 'error';
        const message = notificationUseCase.Add({
          notification: {
            intent,
            titleI18nKey: `admin.filter_configuration.update.${intent}`,
          },
        });

        return this.Result(adminWorkspaces, none(), [
          adminWorkspacesUseCase.Load(),
          message,
        ]);
      }

      case adminWorkspacesUseCase.Destroy.type:
        return this.Result(
          {
            ...adminWorkspaces,
            filterConfigurationLineItems:
              adminWorkspaces.filterConfigurationLineItems.filter(
                lineItem => lineItem.id !== useCase.id
              ),
            apiStatus: ApiStatus.Busy,
          },
          effectFilterConfigurationDestroy(
            this.apiFilterConfiguration,
            useCase.id
          )
        );

      case adminWorkspacesUseCase.DestroyResult.type: {
        const intent = useCase.success ? 'success' : 'error';
        const message = notificationUseCase.Add({
          notification: {
            intent,
            titleI18nKey: `admin.filter_configuration.destroy.${intent}`,
          },
        });

        return this.Result(adminWorkspaces, none(), [
          adminWorkspacesUseCase.Load(),
          message,
        ]);
      }

      case adminWorkspacesUseCase.SetMessage.type:
        return this.Result({
          ...adminWorkspaces,
          messages: useCase.clearOthers
            ? [useCase.message]
            : [useCase.message, ...adminWorkspaces.messages],
        });

      case adminWorkspacesUseCase.NoOp.type:
        return this.Result({ ...adminWorkspaces });
    }
  }
}

const effectFilterConfigurationList = (
  apiFilterConfiguration: ContractFilterConfiguration
): Effect =>
  effect(
    async () =>
      adminWorkspacesUseCase.LoadResult({
        filterConfigurationLineItems: await apiFilterConfiguration.List(),
      }),
    'effect-admin-workspaces-list'
  );

const effectFilterConfigurationCreate = (
  apiFilterConfiguration: ContractFilterConfiguration,
  filterConfiguration: E.FilterConfiguration
): Effect =>
  effect(async () => {
    try {
      const id = await apiFilterConfiguration.Create(filterConfiguration);
      return adminWorkspacesUseCase.CreateResult({ id: ok(id) });
    } catch (e: unknown) {
      return adminWorkspacesUseCase.CreateResult({ id: err(e) });
    }
  }, 'effect-admin-workspaces-create');

const effectFilterConfigurationGet = (
  apiFilterConfiguration: ContractFilterConfiguration,
  id: string
): Effect =>
  effect(async () => {
    try {
      const filterConfiguration = await apiFilterConfiguration.Get(id);
      return adminWorkspacesUseCase.GetResult({
        filterConfiguration: ok(filterConfiguration),
      });
    } catch (e: unknown) {
      return adminWorkspacesUseCase.GetResult({
        filterConfiguration: err(e),
      });
    }
  }, 'effect-admin-workspaces-get');

const effectFilterConfigurationUpdate = (
  apiFilterConfiguration: ContractFilterConfiguration,
  prevFilterConfiguration: E.FilterConfiguration,
  newFilterConfiguration: E.FilterConfiguration
): Effect =>
  effect(async () => {
    try {
      await apiFilterConfiguration.Update(
        prevFilterConfiguration,
        newFilterConfiguration
      );
      return adminWorkspacesUseCase.UpdateResult({ success: true });
    } catch (e) {
      return adminWorkspacesUseCase.UpdateResult({ success: false });
    }
  }, 'effect-admin-workspaces-update');

const effectFilterConfigurationDestroy = (
  apiFilterConfiguration: ContractFilterConfiguration,
  id: string
): Effect =>
  effect(async () => {
    try {
      await apiFilterConfiguration.Destroy(id);
      return adminWorkspacesUseCase.DestroyResult({ success: true });
    } catch (e) {
      return adminWorkspacesUseCase.DestroyResult({ success: false });
    }
  }, 'effect-admin-workspaces-destroy');

const effectUpdateLocation = (
  apiLocation: ContractLocation,
  filterConfigurationId?: string
): Effect =>
  effect(async () => {
    const pathname = RouteHelper.adminWorkspaces(filterConfigurationId);
    if (window.location.pathname !== pathname) {
      apiLocation.Change(pathname, true);
    }
    return adminWorkspacesUseCase.NoOp();
  }, 'effect-admin-workspaces-update-location');

import { ApiStatus } from '@interfaces/Status';
import Tuple, { pair } from '@monads/Tuple';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';

export interface PACS {
  pacsSystemStatus: PACSSystemStatus;
  pacsStudyLineItems: Tuple<ApiStatus, PACSStudyLineItem[]>;
  totalAvailableItems: number;
}

export const initial = (): PACS => ({
  pacsSystemStatus: PACSSystemStatus.Init,
  pacsStudyLineItems: pair(ApiStatus.Idle, []),
  totalAvailableItems: 0,
});

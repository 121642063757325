import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import styles from './collapsibleSection.scss';

export interface CollapsibleSectionProps {
  children: React.ReactNode;
  open: boolean;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  children,
  open,
}) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (open && ref.current) {
      setHeight(ref.current.clientHeight);
    } else {
      setHeight(0);
    }
  }, [open]);

  return (
    <Box className={styles.collapsibleContainer} style={{ height }}>
      {open && (
        <Box ref={ref} className={styles.collapsibleContent}>
          {children}
        </Box>
      )}
    </Box>
  );
};

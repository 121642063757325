import React from 'react';
import { LineItem, LineItemProps } from '@webMolecules/LineItem/LineItem';
import {
  TextMuted,
  TextMutedWithIcon,
  TextDisplay,
} from '@webMolecules/LineItem/Cell/Text';
import { Box } from '@webMolecules/Box/Box';
import { Badge } from '@webMolecules/Badge/Badge';
import { Icon } from '@webMolecules/Icon/Icon';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { isAnonymousName } from '@helpers/string';
import { t } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import { Users } from '@webMolecules/LineItem/Cell/Users';
import { Skeleton } from '@webMolecules/Skeleton/Skeleton';
import styles from './searchResultLineItem.scss';

export interface SearchResultLineItemData {
  analysisId: string;
  accessionNumber: string;
  studyTypeDescription: string;
  completedDate?: Date;
  date?: Date;
  sessionId?: string;
  pdfUrl?: string;
  userId?: string;
  userProfiles: { initial: string; name: string; color: string }[];
  patientName?: string;
  status?: SessionStatus;
  collaborators?: string[];
  hasDecryptedPhi?: boolean;
}

export interface SearchResultLineItemProps extends SearchResultLineItemData {
  onSetAsArchived: (analysisId: string, sessionId: string) => void;
  downloadPdf: (url: string, fileName: string) => void;
  onOpenUnclaimed: (
    analysisId: string,
    sessionId: string,
    accessionNumber: string
  ) => void;
}

export const SearchResultLineItem = ({
  accessionNumber,
  completedDate,
  sessionId,
  analysisId,
  patientName,
  studyTypeDescription,
  pdfUrl,
  userProfiles,
  status,
  userId,
  onOpenUnclaimed,
  onSetAsArchived,
  downloadPdf,
  hasDecryptedPhi,
}: SearchResultLineItemProps): React.ReactElement<LineItemProps> => {
  const patientNameContent = isAnonymousName(patientName) ? (
    <TextMutedWithIcon
      icon={<Icon name="user-off" color="var(--ds-text-color-muted)" />}
    >
      {t('pages.worklist.worklist_item.no_patient_name')}
    </TextMutedWithIcon>
  ) : (
    <TextDisplay patientSensitive>{patientName}</TextDisplay>
  );

  const colPatientName = hasDecryptedPhi ? (
    patientNameContent
  ) : (
    <Box className={styles.patientNameLoading}>
      <Skeleton center={true}></Skeleton>
    </Box>
  );

  const colCompletedDate = (
    <TextMuted>
      {completedDate ? completedDate.toLocaleDateString('en-AU') : ''}
    </TextMuted>
  );

  const colAccessionNumber = (
    <TextMuted>{accessionNumber ? accessionNumber : ''}</TextMuted>
  );

  const colStudyLocation = <TextMuted>{studyTypeDescription}</TextMuted>;

  const users =
    userProfiles.length > 0
      ? userProfiles
      : [
          {
            initial: '?',
            name: t('pages.worklist.worklist_item.user.unassigned'),
            color: 'var(--ds-background-color-alt-200)',
          },
        ];
  const colUser = <Users users={users} />;

  const colStatus = (
    <Box marginLeft="auto">
      {(() => {
        switch (status) {
          case SessionStatus.New:
            return (
              <Badge intent="information">
                {t('types.analysis.status.new')}
              </Badge>
            );
          case SessionStatus.Completed:
            return (
              <Badge intent="success">
                {t('types.analysis.status.completed')}
              </Badge>
            );
          case SessionStatus.InProgress:
          case SessionStatus.Archived:
            return null;
        }
      })()}
    </Box>
  );

  const contextMenu = [];
  if (pdfUrl) {
    const fileName = accessionNumber ? `${accessionNumber}.pdf` : 'report.pdf';
    contextMenu.push({
      label: t('pages.worklists.analysis.dropdown.download_pdf'),
      icon: <Icon name="download" />,
      onClick: () => {
        downloadPdf(pdfUrl, fileName);
      },
    });
  }
  if (sessionId) {
    contextMenu.push({
      label: t('generic.dropdown.remove'),
      icon: <Icon name="trash" />,
      onClick: () => {
        onSetAsArchived(analysisId, sessionId);
      },
    });
  }

  let lineItemAction: Pick<LineItemProps, 'href' | 'onClick'> = {};
  if (status === SessionStatus.Completed && pdfUrl) {
    const fileName = accessionNumber ? `${accessionNumber}.pdf` : 'report.pdf';
    lineItemAction = { onClick: () => downloadPdf(pdfUrl, fileName) };
  } else if (!userId && sessionId) {
    lineItemAction = {
      onClick: () =>
        onOpenUnclaimed(analysisId, sessionId, accessionNumber || ''),
    };
  } else if (userId && sessionId) {
    lineItemAction = { href: RouteHelper.study(analysisId, sessionId) };
  }

  return (
    <LineItem {...lineItemAction} contextMenu={contextMenu}>
      {colPatientName}
      {colCompletedDate}
      {colAccessionNumber}
      {colStudyLocation}
      {colUser}
      {colStatus}
    </LineItem>
  );
};

export default SearchResultLineItem;

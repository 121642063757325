import Tuple, { pair } from './Tuple';

export default class Maybe<A> {
  private value?: A;

  constructor(value?: A) {
    this.value = value;
  }

  isJust(): boolean {
    return !this.isNothing();
  }

  isNothing(): boolean {
    return typeof this.value === 'undefined';
  }

  map<S>(f: (arg: A) => S): Maybe<S> {
    if (typeof this.value === 'undefined') {
      return nothing();
    }
    return new Maybe(f(this.value));
  }

  pair<B>(maybeB: Maybe<B>): Maybe<Tuple<A, B>> {
    return map2(pair, this, maybeB);
  }

  andThen<S>(f: (arg: A) => Maybe<S>): Maybe<S> {
    if (typeof this.value === 'undefined') {
      return nothing();
    }
    return f(this.value);
  }

  async asyncAndThen<S>(f: (arg: A) => Promise<Maybe<S>>): Promise<Maybe<S>> {
    if (typeof this.value === 'undefined') {
      return nothing();
    }
    return f(this.value);
  }

  withDefault(defaultValue: A): A {
    if (typeof this.value === 'undefined') {
      return defaultValue;
    }
    return this.value;
  }

  mapOr<S>(f: (arg: A) => S, or: S): S {
    if (typeof this.value === 'undefined') {
      return or;
    }
    return f(this.value);
  }

  orMaybe(b: Maybe<A>): Maybe<A> {
    if (typeof this.value === 'undefined') {
      return b;
    }
    return this;
  }

  case(f: (arg: A) => void, or: () => void): void {
    if (typeof this.value === 'undefined') {
      or();
    } else {
      f(this.value);
    }
  }

  lift(): undefined | A {
    return this.value;
  }

  mapOrThrowError<S>(f: (arg: A) => S, error: string): S {
    if (typeof this.value === 'undefined') {
      throw new Error(error);
    }
    return f(this.value);
  }
}

export const just = <A>(value: A): Maybe<A> => new Maybe(value);

export const nothing = <A>(): Maybe<A> => new Maybe();

export const maybe = <A>(value: undefined | A): Maybe<A> => new Maybe(value);

export const fromFalsy = <A>(
  value: '' | null | undefined | false | 0 | A
): Maybe<A> => (value ? just(value) : nothing());

export const justIf = <A>(value: A, truthy: boolean): Maybe<A> =>
  truthy ? just(value) : nothing();

export const map2 = <A, B, C>(
  f: (a: A, b: B) => C,
  ma: Maybe<A>,
  mb: Maybe<B>
): Maybe<C> => {
  const a = ma.lift();
  const b = mb.lift();

  if (typeof a !== 'undefined' && typeof b !== 'undefined') {
    return new Maybe(f(a, b));
  }
  return new Maybe();
};

export const map3 = <A, B, C, D>(
  f: (a: A, b: B, c: C) => D,
  ma: Maybe<A>,
  mb: Maybe<B>,
  mc: Maybe<C>
): Maybe<D> => {
  const a = ma.lift();
  const b = mb.lift();
  const c = mc.lift();

  if (
    typeof a !== 'undefined' &&
    typeof b !== 'undefined' &&
    typeof c !== 'undefined'
  ) {
    return new Maybe(f(a, b, c));
  }
  return new Maybe();
};

export const filter = (list: Maybe<unknown>[]): Maybe<unknown>[] =>
  list.filter(a => a.isJust());

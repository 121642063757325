import isUndefined from 'lodash/isUndefined';
import Maybe, { just, map2 } from './Maybe';

export default class Tuple<A, B = A> {
  private a: A;
  private b: B;

  constructor(a: A, b: B) {
    this.a = a;
    this.b = b;
  }

  first(): A {
    return this.a;
  }

  second(): B {
    return this.b;
  }

  swap(): Tuple<B, A> {
    return new Tuple(this.b, this.a);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map<Y = unknown>(f: (a: any) => Y): Tuple<Y, Y> {
    return new Tuple(f(this.a), f(this.b));
  }

  mapBoth<C, D>(fa: (a: A) => C, fb: (b: B) => D): Tuple<C, D> {
    return new Tuple(fa(this.a), fb(this.b));
  }

  mapFirst<C>(f: (a: A) => C): Tuple<C, B> {
    return new Tuple(f(this.a), this.b);
  }

  mapSecond<D>(f: (b: B) => D): Tuple<A, D> {
    return new Tuple(this.a, f(this.b));
  }

  setFirst<C>(c: C): Tuple<C, B> {
    return new Tuple(c, this.b);
  }

  setSecond<C>(c: C): Tuple<A, C> {
    return new Tuple(this.a, c);
  }

  maybeMapBoth<C, D>(
    fa: (a: A) => Maybe<C>,
    fb: (b: B) => Maybe<D>
  ): Maybe<Tuple<C, D>> {
    return map2(pair, fa(this.a), fb(this.b));
  }

  maybeMapFirst<C>(f: (a: A) => Maybe<C>): Maybe<Tuple<C, B>> {
    return map2(pair, f(this.a), just(this.b));
  }

  maybeMapSecond<D>(f: (a: B) => Maybe<D>): Maybe<Tuple<A, D>> {
    return map2(pair, just(this.a), f(this.b));
  }

  lift(): [A, B] {
    return [this.a, this.b];
  }

  uncurry<C>(f: (a: A, b: B) => C): C {
    return f(this.a, this.b);
  }

  apply<C>(): undefined | C {
    if (typeof this.a === 'function') {
      return this.a(this.b);
    }
    return undefined;
  }
}

export const pair = <A, B>(a: A, b: B): Tuple<A, B> => {
  return new Tuple(a, b);
};

export const append = <A, B>(b: B, a: A): Tuple<A, B> => {
  return new Tuple(a, b);
};

export const zip = <A, B>(a: A[], b: B[]): Tuple<A, B>[] =>
  a
    .map<[A, B]>((item: A, index) => [item, b[index]])
    .filter(([_, b]) => !isUndefined(b))
    .map(([a, b]) => pair(a, b));

export const unzip = <A, B>(a: Tuple<A, B>[]): [A[], B[]] =>
  a
    .map(tuple => tuple.lift())
    .reduce<[A[], B[]]>(
      ([a, b], [x, y]) => [
        [...a, x],
        [...b, y],
      ],
      [[], []]
    );

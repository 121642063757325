import { Location as ContractLocation, History } from '@contracts/Location';

export class Location implements ContractLocation {
  private windowHistory: History = {
    push: a => window.history.pushState({}, '', a),
    replace: a => window.history.pushState({}, '', a),
  };
  private history: History = { ...this.windowHistory };

  UseHistory(history: History): void {
    this.history = history;
  }

  Change(path: string, replace = false, alwaysUseWindowHistory = false): void {
    if (window.location.pathname === path) {
      return;
    }
    window.requestAnimationFrame(() => {
      const history = alwaysUseWindowHistory
        ? this.windowHistory
        : this.history;
      if (replace) {
        history.replace(path);
      } else {
        history.push(path);
      }
    });
  }

  Reload(path?: string): void {
    window.requestAnimationFrame(() => {
      if (path && window.location.pathname !== path) {
        window.location.href = path;
      } else {
        window.location.reload();
      }
    });
  }
}

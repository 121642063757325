import { WorkspaceGroup } from '@interfaces/WorkspaceGroup';
import {
  SessionStatus,
  sessionStatusArrayToString,
} from '@interfaces/SessionLineItem';

export interface FilterOptions {
  group: WorkspaceGroup;
  id: string;
  status: SessionStatus[];
  offset: number;
  limit: number;
  isBookmarked?: boolean;
}

export const filterOptionToTargetHash = (
  filterOptions: FilterOptions
): string => {
  const { group, id, status, isBookmarked } = filterOptions;
  return `${group}-${id}-${sessionStatusArrayToString(status, isBookmarked)}`;
};

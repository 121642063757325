import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Grid } from '@webMolecules/Grid/Grid';
import { t, tDate } from '@webInterfaces/I18n';
import { isAnonymousName, stringFallback } from '@helpers/string';
import { PatientHealthInfo } from '@interfaces/SessionLineItem';
import { Table, TableCell, TableRow } from '@webMolecules/Table/Table';

export interface StaticPatientInfoProps {
  studyTypeDescription: string;
  accessionNum?: string | undefined;
  decryptedPhi: PatientHealthInfo;
}

const StaticPatientInfo = ({
  studyTypeDescription,
  accessionNum,
  decryptedPhi,
}: StaticPatientInfoProps) => {
  const na = t('pages.study.patient_info.not_available');

  const patient = {
    patientId: stringFallback(decryptedPhi.patient_id, na),
    patientName: stringFallback(decryptedPhi.patient_name, na),
    dob: stringFallback(tDate(decryptedPhi.patient_dob), na),
    sex: stringFallback(decryptedPhi.patient_sex, na),
  };

  const exam = {
    accessionNum: stringFallback(accessionNum, decryptedPhi.accession_num),
    visitationDate: stringFallback(tDate(decryptedPhi.date), na),
    studyTypeDescription,
    operatorCode: stringFallback(decryptedPhi.operator_code, na),
  };

  return (
    <Grid columns={2}>
      <Box style={{ maxWidth: '400px' }}>
        <Text type="display2" display="block" marginBottom="l">
          {t('pages.study.patient_info.information')}
        </Text>
        <Table spacing="condensed">
          <TableRow>
            <TableCell width="45%" verticalAlign="top">
              <Text display="block" type="display5">
                {t('pages.study.patient_info.patient_name')}
              </Text>
            </TableCell>
            <TableCell>
              <Text patientSensitive>
                {isAnonymousName(patient.patientName)
                  ? t('pages.worklist.worklist_item.no_patient_name')
                  : patient.patientName}
              </Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Text display="block" type="display5">
                {t('pages.study.patient_info.patient_id')}
              </Text>
            </TableCell>
            <TableCell>
              <Text patientSensitive>{patient.patientId}</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Text display="block" type="display5">
                {t('pages.study.patient_info.date_of_birth')}
              </Text>
            </TableCell>
            <TableCell>
              <Text patientSensitive>{patient.dob}</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Text display="block" type="display5">
                {t('pages.study.patient_info.sex')}
              </Text>
            </TableCell>
            <TableCell>
              <Text>{patient.sex}</Text>
            </TableCell>
          </TableRow>
        </Table>
      </Box>

      <Box style={{ maxWidth: '400px' }}>
        <Text type="display2" display="block" marginBottom="l">
          {t('pages.study.patient_info.examination')}
        </Text>

        <Table spacing="condensed">
          <TableRow>
            <TableCell width="55%">
              <Text display="block" type="display5">
                {t('pages.study.patient_info.accession_number')}
              </Text>
            </TableCell>
            <TableCell>
              <Text>{exam.accessionNum}</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Text display="block" type="display5">
                {t('pages.study.patient_info.visitation_date')}
              </Text>
            </TableCell>
            <TableCell>
              <Text>{exam.visitationDate}</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Text display="block" type="display5">
                {t('pages.study.patient_info.study_type')}
              </Text>
            </TableCell>
            <TableCell>
              <Text>{exam.studyTypeDescription}</Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Text display="block" type="display5">
                {t('pages.study.patient_info.operator_code')}
              </Text>
            </TableCell>
            <TableCell>
              <Text>{exam.operatorCode}</Text>
            </TableCell>
          </TableRow>
        </Table>
      </Box>
    </Grid>
  );
};

export default StaticPatientInfo;

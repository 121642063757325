import Either, { left, right } from '@monads/Either';
import Maybe, { just, nothing } from '@monads/Maybe';
import { toError } from '@helpers/error';

export interface Result<R> {
  mapError(f: (error: Error) => Error): Result<R>;
  mapOk<R2>(f: (value: R) => R2): Result<R2>;
  mapLift<R2>(a: (value: R) => R2, b: (error: Error) => R2): R2;
  recoverError<R2>(f: (value: Error) => R2): Result<R | R2>;
  andThen<R2>(f: (value: R) => Result<R2>): Result<R2>;
  isError(): boolean;
  isOk(): boolean;
  withDefault(value: R): R;
  lift(): Error | R;
  toEither(): Either<Error, R>;
  toMaybe(): Maybe<R>;
  case(fOk: (value: R) => void, fError: (error: Error) => void): void;
}

export class Err<R = never> implements Result<R> {
  constructor(private value: Error) {}

  mapError(f: (error: Error) => Error): Result<never> {
    return err(f(this.lift()));
  }

  mapOk<R2>(): Result<R2> {
    return err(this.lift());
  }

  mapLift<R2>(_: never, f: (error: Error) => R2): R2 {
    return f(this.value);
  }

  recoverError<R2>(f: (value: Error) => R2): Result<R | R2> {
    return ok(f(this.lift()));
  }

  andThen<R2>(): Result<R2> {
    return err(this.lift());
  }

  isError(): boolean {
    return true;
  }

  isOk(): boolean {
    return false;
  }

  withDefault(value: R): R {
    return value;
  }

  lift(): Error {
    return this.value;
  }

  toEither(): Either<Error, never> {
    return left(this.lift());
  }

  toMaybe(): Maybe<never> {
    return nothing();
  }

  case(fOk: (value: R) => void, fError: (error: Error) => void) {
    fError(this.value);
  }
}

export class Ok<R> implements Result<R> {
  constructor(private value: R) {}

  mapError(): Result<R> {
    return ok(this.lift());
  }

  mapOk<R2>(f: (value: R) => R2): Result<R2> {
    return ok(f(this.lift()));
  }

  mapLift<R2>(f: (value: R) => R2): R2 {
    return f(this.value);
  }

  recoverError(): Result<R> {
    return ok(this.lift());
  }

  andThen<R2>(f: (value: R) => Result<R2>): Result<R2> {
    return f(this.lift());
  }

  isError(): boolean {
    return false;
  }

  isOk(): boolean {
    return true;
  }

  withDefault(): R {
    return this.lift();
  }

  lift(): R {
    return this.value;
  }

  toEither(): Either<never, R> {
    return right(this.lift());
  }

  toMaybe(): Maybe<R> {
    return just(this.lift());
  }

  case(fOk: (value: R) => void) {
    fOk(this.lift());
  }
}

export const err = <R = never>(error: unknown): Result<R> =>
  new Err(toError(error));

export const ok = <R>(value: R): Result<R> => new Ok(value);

export default Result;

import { Workflow } from '@entities/Workflow';
import { ThyroidFinding } from '@entities/Finding';
import { AnyUnit, DistanceUnit, VolumeUnit } from '@entities/Unit';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import {
  CompositionScore,
  EchogenicFociScore,
  EchogenicityScore,
  MarginScore,
  ShapeScore,
} from '@handlers/TiRadsHandler';
import { ThyroidGalleryFilter } from '@config/Gallery/SpecificGalleryFilter';
import {
  EchotextureValue,
  NormalAbnormalValue,
  NormalIncreasedValue,
  ThyroidFindingComposition,
  ThyroidFindingEchogenicFoci,
  ThyroidFindingEchogenicity,
  ThyroidFindingMargin,
  ThyroidFindingShape,
} from '@entities/Characteristics';
import {
  GeneralCharacteristicTableConfig,
  is1DFindingSizeThyroid,
  is3DFindingSizeThyroid,
  isFivePolesVariant,
  isThreePolesVariant,
  ResultTableRadioConfig,
  ResultTableSelectOptions,
  ResultTableToggleConfig,
} from './ResultTable';
import { FindingConfig, yesNoSelectOptions } from './Shared';

const bodySideConfig: ResultTableSelectOptions = [
  {
    value: 'right',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.body_side_right',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.body_side_right',
  },
  {
    value: 'median',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.body_side_median',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.body_side_median',
  },
  {
    value: 'left',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.body_side_left',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.body_side_left',
  },
];

const threePoleConfig: ResultTableSelectOptions = [
  {
    value: 'upper',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_upper',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_upper',
  },
  {
    value: 'mid',
    editableI18NKey: 'config_key.result_table.thyroid_finding.options.pole_mid',
    previewI18NKey: 'config_key.result_table.thyroid_finding.options.pole_mid',
  },
  {
    value: 'lower',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_lower',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_lower',
  },
];

const fivePoleConfig: ResultTableSelectOptions = [
  {
    value: 'upper',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_upper',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_upper',
  },
  {
    value: 'upper_mid',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_upper_mid',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_upper_mid',
  },
  {
    value: 'mid',
    editableI18NKey: 'config_key.result_table.thyroid_finding.options.pole_mid',
    previewI18NKey: 'config_key.result_table.thyroid_finding.options.pole_mid',
  },
  {
    value: 'lower_mid',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_lower_mid',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_lower_mid',
  },
  {
    value: 'lower',
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_lower',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.pole_lower',
  },
];

const thyroidFindingEchogenicFociConfig: ResultTableRadioConfig = {
  columns: 1,
  options: [
    {
      value: ThyroidFindingEchogenicFoci.NoneOrLargeCometTailArtefacts,
      editableI18NKey:
        'config_key.result_table.thyroid_finding.options.none_or_large_comet_tail_artefacts',
      previewI18NKey:
        'config_key.result_table.thyroid_finding.options.none_or_large_comet_tail_artefacts',
      separator: true,
    },
    {
      value: ThyroidFindingEchogenicFoci.Macrocalcifications,
      editableI18NKey:
        'config_key.result_table.thyroid_finding.options.macrocalcification',
      previewI18NKey:
        'config_key.result_table.thyroid_finding.options.macrocalcification',
    },
    {
      value: ThyroidFindingEchogenicFoci.Peripheral,
      editableI18NKey:
        'config_key.result_table.thyroid_finding.options.peripheral_rim_calcification',
      previewI18NKey:
        'config_key.result_table.thyroid_finding.options.peripheral_rim_calcification',
    },
    {
      value: ThyroidFindingEchogenicFoci.PunctateEchogenicFoci,
      editableI18NKey:
        'config_key.result_table.thyroid_finding.options.punctate_echogenic_foci',
      previewI18NKey:
        'config_key.result_table.thyroid_finding.options.punctate_echogenic_foci',
    },
  ],
  scoreConfig: EchogenicFociScore,
};

export const echotextureOptions: ResultTableToggleConfig = {
  options: [
    {
      editableI18NKey:
        'config_key.result_table.echotexture.options.homogeneous.short',
      previewI18NKey:
        'config_key.result_table.echotexture.options.homogeneous.short',
      value: EchotextureValue.Homogeneous,
    },
    {
      editableI18NKey:
        'config_key.result_table.echotexture.options.heterogeneous.short',
      previewI18NKey:
        'config_key.result_table.echotexture.options.heterogeneous.short',
      value: EchotextureValue.Heterogeneous,
    },
  ],
};

export const normalIncreasedOptions: ResultTableToggleConfig = {
  size: 'small',
  options: [
    {
      value: NormalIncreasedValue.Normal,
      editableI18NKey: 'result_table.shared.options.normal',
      previewI18NKey: 'result_table.shared.options.normal',
    },
    {
      value: NormalIncreasedValue.Increased,
      editableI18NKey: 'result_table.shared.options.increased',
      previewI18NKey: 'result_table.shared.options.increased',
    },
  ],
};

export const normalAbnormalOptions: ResultTableToggleConfig = {
  size: 'small',
  options: [
    {
      value: NormalAbnormalValue.Normal,
      editableI18NKey: 'result_table.shared.options.normal',
      previewI18NKey: 'result_table.shared.options.normal',
    },
    {
      value: NormalAbnormalValue.Abnormal,
      editableI18NKey: 'result_table.shared.options.abnormal',
      previewI18NKey: 'result_table.shared.options.abnormal',
    },
  ],
};

const thyroidFindingShapeConfig: ResultTableSelectOptions = [
  {
    value: ThyroidFindingShape.WiderThanTall,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.wider_than_tall',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.wider_than_tall',
  },
  {
    value: ThyroidFindingShape.TallerThanWide,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.taller_than_wide',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.taller_than_wide',
  },
];

const thyroidFindingMarginConfig: ResultTableSelectOptions = [
  {
    value: ThyroidFindingMargin.Smooth,
    editableI18NKey: 'config_key.result_table.thyroid_finding.options.smooth',
    previewI18NKey: 'config_key.result_table.thyroid_finding.options.smooth',
  },
  {
    value: ThyroidFindingMargin.IllDefined,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.ill_defined_or_smooth',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.ill_defined_or_smooth',
  },
  {
    value: ThyroidFindingMargin.LobulatedOrIrregular,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.lobulated_or_irregular',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.lobulated_or_irregular',
  },
  {
    value: ThyroidFindingMargin.ExtraThyroidalExtension,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.extra_thyroidal_extension',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.extra_thyroidal_extension',
  },
  {
    value: ThyroidFindingMargin.Indeterminate,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.margin_indeterminate',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.margin_indeterminate',
  },
];

const thyroidFindingEchogenicityConfig: ResultTableSelectOptions = [
  {
    value: ThyroidFindingEchogenicity.Anechoic,
    editableI18NKey: 'config_key.result_table.thyroid_finding.options.anechoic',
    previewI18NKey: 'config_key.result_table.thyroid_finding.options.anechoic',
  },
  {
    value: ThyroidFindingEchogenicity.HyperechoicOrIsoechoic,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.hyperechoic_or_isoechoic',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.hyperechoic_or_isoechoic',
  },
  {
    value: ThyroidFindingEchogenicity.Hypoechoic,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.hypoechoic',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.hypoechoic',
  },
  {
    value: ThyroidFindingEchogenicity.VeryHypoechoic,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.very_hypoechoic',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.very_hypoechoic',
  },
  {
    value: ThyroidFindingEchogenicity.Indeterminate,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.echogenicity_indeterminate',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.echogenicity_indeterminate',
  },
];

export const GeneralCharacteristicsConfig = {
  volume: {
    valueType: 'float',
    unit: VolumeUnit.ML,
  },
  size: {
    valueType: 'dimensions',
    unit: DistanceUnit.MM,
  },
};

export const rightThyroidLobe: GeneralCharacteristicTableConfig = {
  sections: [
    {
      heading: 'config_key.title.short-right.thyroid.thyroid',
      galleryFilter: ThyroidGalleryFilter.RightLobe,
      rows: [
        {
          path: 'rightLobe',
          key: 'size',
          input: 'dimensions',
          unit: DistanceUnit.MM,
          unitPosition: 'inline',
        },
        {
          path: 'rightLobe.volume',
          key: 'volume',
          input: 'float',
          unit: VolumeUnit.ML,
          unitPosition: 'inline',
        },
        {
          path: 'rightLobe.vascularity',
          key: 'vascularity',
          input: 'toggleGroup',
          inputConfig: normalIncreasedOptions,
        },
        {
          path: 'rightLobe.echotexture',
          key: 'echotexture',
          input: 'toggleGroup',
          inputConfig: echotextureOptions,
        },
        {
          path: 'rightLobe.retrosternal',
          key: 'retrosternal_ext',
          input: 'toggleGroup',
          inputConfig: {
            size: 'small',
            options: [...yesNoSelectOptions],
          },
        },
      ],
    },
  ],
};
export const leftThyroidLobe: GeneralCharacteristicTableConfig = {
  sections: [
    {
      heading: 'config_key.title.short-left.thyroid.thyroid',
      galleryFilter: ThyroidGalleryFilter.LeftLobe,
      rows: [
        {
          path: 'leftLobe',
          key: 'size',
          input: 'dimensions',
          unit: DistanceUnit.MM,
          unitPosition: 'inline',
        },
        {
          path: 'leftLobe.volume',
          key: 'volume',
          input: 'float',
          unit: VolumeUnit.ML,
          unitPosition: 'inline',
          width: '215px',
        },
        {
          path: 'leftLobe.vascularity',
          key: 'vascularity',
          input: 'toggleGroup',
          inputConfig: normalIncreasedOptions,
          width: '200px',
        },
        {
          path: 'leftLobe.echotexture',
          key: 'echotexture',
          input: 'toggleGroup',
          inputConfig: echotextureOptions,
          width: '200px',
        },
        {
          path: 'leftLobe.retrosternal',
          key: 'retrosternal_ext',
          input: 'toggleGroup',
          inputConfig: { size: 'small', options: [...yesNoSelectOptions] },
          width: '200px',
        },
      ],
    },
  ],
};

export const otherTable: GeneralCharacteristicTableConfig = {
  sections: [
    {
      heading: 'Other',
      rows: [
        {
          path: 'isthmusThickness',
          key: 'isthmus_thickness',
          input: 'float',
          unit: DistanceUnit.MM,
          unitPosition: 'inline',
          galleryFilter: ThyroidGalleryFilter.Isthmus,
        },
        {
          path: 'trachealDeviation',
          key: 'tracheal_deviation',
          input: 'toggleGroup',
          inputConfig: {
            size: 'small',
            options: [...yesNoSelectOptions],
          },
          width: '200px',
        },
        {
          path: 'lymphNodes',
          key: 'lymph_nodes_normality',
          input: 'toggleGroup',
          inputConfig: normalAbnormalOptions,
        },
        {
          path: 'abnormalLymphNodesText',
          key: 'lymph_nodes_normality',
          hideLabel: true,
          input: 'textArea',
          width: '100%',
          placeholder:
            'config_key.result_table.lymph_nodes_normality.textarea.placeholder',
          isShown: isLymphNodeAbnormal,
        },
      ],
    },
  ],
};

const thyroidFindingCompositionConfig: ResultTableSelectOptions = [
  {
    value: ThyroidFindingComposition.CysticOrAlmostCompletelyCystic,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.cystic_or_almost_completely_cystic',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.cystic_or_almost_completely_cystic',
  },
  {
    value: ThyroidFindingComposition.Spongiform,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.spongiform',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.spongiform',
  },
  {
    value: ThyroidFindingComposition.MixedCysticAndSolid,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.mixed_cystic_and_solid',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.mixed_cystic_and_solid',
  },
  {
    value: ThyroidFindingComposition.SolidOrAlmostCompletelySolid,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.solid_or_almost_completely_solid',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.solid_or_almost_completely_solid',
  },
  {
    value: ThyroidFindingComposition.Indeterminate,
    editableI18NKey:
      'config_key.result_table.thyroid_finding.options.composition_indeterminate',
    previewI18NKey:
      'config_key.result_table.thyroid_finding.options.composition_indeterminate',
  },
];

export const getThyroidFindingDetailedConfig = (
  sizeUnit: AnyUnit
): FindingConfig<ThyroidFinding> => {
  return {
    sections: [
      {
        index: 0,
        title: '',
        separator: false,
        fields: [
          {
            path: 'included',
            key: 'findingInclude',
            input: 'findingInclude',
            label: 'pages.report.findings.label.include_in_report',
            boldLabel: true,
          },
        ],
      },
      {
        index: 1,
        title: 'study.result_table.position',
        separator: true,
        titleSeparator: true,
        fields: [
          {
            key: 'body_side_pole',
            input: 'mergedCell',
            mergedCellFields: {
              type: 'thyroidSVGSelection',
              config: [
                {
                  key: 'side',
                  label:
                    'config_key.result_table.thyroid_finding.body_side.label',
                  inputConfig: { options: bodySideConfig },
                  hideLabel: true,
                },
                {
                  key: 'pole',
                  label: 'config_key.result_table.thyroid_finding.pole.label',
                  isDisabled: finding =>
                    finding.characteristics.side === 'median',
                  inputConfig: { options: threePoleConfig },
                  featureEnabled: isThreePolesVariant,
                  hideLabel: true,
                },
                {
                  key: 'pole',
                  label: 'config_key.result_table.thyroid_finding.pole.label',
                  isDisabled: finding =>
                    finding.characteristics.side === 'median',
                  inputConfig: { options: fivePoleConfig },
                  featureEnabled: isFivePolesVariant,
                  hideLabel: true,
                },
              ],
            },
          },
        ],
      },
      {
        index: 2,
        title: '',
        separator: true,
        fields: [
          {
            path: 'sizes.max',
            input: 'float',
            key: 'size',
            label: 'config_key.result_table.finding.column.size',
            width: '100px',
            unit: sizeUnit,
            unitPosition: 'inline',
            featureEnabled: is1DFindingSizeThyroid,
            boldLabel: true,
            inputConfig: {
              showSizeWarning: true,
            },
          },
          {
            key: 'finding_size_volume',
            path: 'sizes',
            label: 'config_key.result_table.finding.column.size',
            boldLabel: true,
            input: 'dimensions',
            unit: sizeUnit,
            unitPosition: 'inline',
            inputConfig: { showVolume: true, showSizeOptions: true },
            featureEnabled: is3DFindingSizeThyroid,
          },
          {
            path: 'characteristics.composition',
            key: 'composition',
            input: 'select',
            inputConfig: {
              options: thyroidFindingCompositionConfig,
              scoreConfig: CompositionScore,
            },
            label: 'config_key.result_table.thyroid_finding.composition.label',
            note: 'config_key.result_table.thyroid_finding.composition.note',
            showNote: isSpongiform,
            boldLabel: true,
            showPill: true,
          },
          {
            path: 'characteristics.echogenicity',
            key: 'echogenicity',
            input: 'select',
            inputConfig: {
              options: thyroidFindingEchogenicityConfig,
              scoreConfig: EchogenicityScore,
            },
            label: 'config_key.result_table.thyroid_finding.echogenicity.label',
            isDisabled: isSpongiform,
            boldLabel: true,
            showPill: true,
          },
          {
            path: 'characteristics.shape',
            key: 'shape',
            input: 'select',
            inputConfig: {
              options: thyroidFindingShapeConfig,
              scoreConfig: ShapeScore,
            },
            label: 'config_key.result_table.thyroid_finding.shape.label',
            isDisabled: isSpongiform,
            boldLabel: true,
            showPill: true,
          },
          {
            path: 'characteristics.margin',
            key: 'margin',
            input: 'select',
            inputConfig: {
              options: thyroidFindingMarginConfig,
              scoreConfig: MarginScore,
            },
            label: 'config_key.result_table.thyroid_finding.margin.label',
            isDisabled: isSpongiform,
            boldLabel: true,
            showPill: true,
          },
          {
            path: 'characteristics.echogenicFoci',
            key: 'echogenicFoci',
            input: 'check',
            inputConfig: thyroidFindingEchogenicFociConfig,
            label:
              'config_key.result_table.thyroid_finding.echogenic_foci.label',
            isDisabled: isSpongiform,
            boldLabel: true,
            labelBoxMargin: 'none',
            onToggle: handleToggleEchogenicFoci,
            showPill: true,
          },
        ],
      },
      {
        index: 3,
        title: '',
        separator: false,
        fields: [
          {
            key: 'tiRadScore',
            input: 'score',
            label: 'config_key.result_table.thyroid_finding.ti_rads.label',
            boldLabel: true,
          },
        ],
      },
      {
        index: 4,
        title: '',
        separator: false,
        fields: [
          {
            key: 'recommendationLabel',
            input: 'findingRecommendation',
            label:
              'config_key.result_table.thyroid_finding.recommendation.label',
            boldLabel: true,
            workflow: Workflow.Radiologist,
            labelBoxMargin: 'none',
          },
        ],
      },
    ],
  };
};

function isSpongiform(finding: ThyroidFinding) {
  return (
    finding.characteristics.composition === ThyroidFindingComposition.Spongiform
  );
}
// turn off comment if LymphNode is Normal
function isLymphNodeAbnormal(
  generalCharacteristics: ThyroidGeneralCharacteristics
) {
  return generalCharacteristics.lymphNodes == NormalAbnormalValue.Abnormal;
}

function handleToggleEchogenicFoci(oldValues: string[], newValues: string[]) {
  const NONE = ThyroidFindingEchogenicFoci.NoneOrLargeCometTailArtefacts;

  // 1. If nothing is selected, ensure None is selected
  if (newValues.length === 0) {
    return [NONE];
  }

  // 2. If None is selected, the rest should not be selected
  if (!oldValues.includes(NONE) && newValues.includes(NONE)) {
    return [NONE];
  }

  // 3. If another characteristic is selected, deselect None
  const withoutNone = (c: string) => c !== NONE;
  const oldOthers = oldValues.filter(withoutNone);
  const newOthers = newValues.filter(withoutNone);
  if (newOthers.length > oldOthers.length) {
    return newOthers;
  }

  return newValues;
}

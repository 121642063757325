export interface FilterConfiguration {
  id?: string;
  name: string;
  description: string;
  properties: FilterConfigurationProperty[];
  isCustomWorkspace: boolean;
}

export interface FilterConfigurationProperty {
  id?: string;
  parentId?: string;
  column: string;
  operator: FilterConfigurationPropertyOperator;
  value: string;
  childProperties?: FilterConfigurationProperty[];
  _tmpId?: string;
  _delete?: boolean;
}

export enum FilterConfigurationPropertyOperator {
  Contains = 'filter_configuration_property_operator--contains',
  Matches = 'filter_configuration_property_operator--matches',
}

export const PROPERTY_COLUMN_OPTIONS = [
  'institution_name',
  'study_type',
  'pacs_study_data_batch.pacs_ip_address',
];

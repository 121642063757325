import React from 'react';
import {
  ToastOptions,
  UpdateOptions,
  ToastContainer as ToastifyContainer,
  toast as toastify,
  cssTransition,
} from 'react-toastify';
import { Card } from '@webMolecules/Card/Card';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import styles from './toast.scss';

export type ToastifyUpdateOptions = UpdateOptions;
export type ToastifyOptions = ToastOptions;

export const ToastContainer = () => (
  <ToastifyContainer
    closeButton={CloseButton}
    className={styles.toast}
    hideProgressBar={true}
    newestOnTop={true}
    draggable={false}
    closeOnClick={false}
    position="bottom-right"
    autoClose={false}
  />
);

export const openToast = (
  children: React.ReactNode,
  options: ToastifyOptions
): React.ReactText => {
  return toastify(<ToastTemplate>{children}</ToastTemplate>, {
    ...options,
    transition: customAnimation,
    className: styles.toastContainer,
    bodyClassName: styles.toastBody,
    progressClassName: styles.toastProgressBar,
  });
};

export const updateToast = (
  id: React.ReactText,
  options: ToastifyUpdateOptions
) =>
  toastify.update(id, {
    ...options,
    render: <ToastTemplate>{options.render}</ToastTemplate>,
  });

export const closeToast = toastify.dismiss;

export interface closeToastProps {
  closeToast?: () => void;
}

const customAnimation = cssTransition({
  enter: styles.toastEnter,
  exit: styles.toastExit,
});

const ToastTemplate: React.FC = ({ children }) => (
  <Box marginBottom="xs">
    <Card padding="none" elevation="high">
      <Box padding="m">{children}</Box>
    </Card>
  </Box>
);

export const CloseButton = ({ closeToast }: closeToastProps) => (
  <Box
    data-testid="toast-close-button"
    className={styles.closeButton}
    padding="xxs"
  >
    <Button
      onClick={closeToast}
      iconBefore={<Icon name="x" />}
      circle
      size="small"
    />
  </Box>
);

import React, {
  PropsWithChildren,
  UIEventHandler,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from 'overlayscrollbars-react';

import { omit } from '../../helpers/omit';
import styles from './box.scss';
// eslint-disable-next-line import/no-unresolved
import 'overlayscrollbars/overlayscrollbars.css';

type Spacing = 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';

type MarginSpacing = 'auto' | 'none' | Spacing;
type PaddingSpacing = 'auto' | 'none' | Spacing;
type GapSpacing = 'normal' | Spacing;

export interface BoxProps {
  contentEditable?: boolean;
  alignItems?:
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline';
  children?: React.ReactNode;
  className?: string;
  display?:
    | 'flex'
    | 'inline-flex'
    | 'block'
    | 'inline-block'
    | 'inline'
    | 'none';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element?: keyof JSX.IntrinsicElements | React.FC<any>;
  flex?: string;
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  href?: string;
  id?: string;
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
  gap?: GapSpacing;
  gapColumn?: GapSpacing;
  gapRow?: GapSpacing;
  margin?: MarginSpacing;
  marginBottom?: MarginSpacing;
  marginLeft?: MarginSpacing;
  marginRight?: MarginSpacing;
  marginTop?: MarginSpacing;
  marginX?: MarginSpacing;
  marginY?: MarginSpacing;
  /**
   * Add an optional onClick Event
   */
  onClick?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  /**
   * Add optional mouse events
   */
  onMouseEnter?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onMouseLeave?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onPointerDown?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  padding?: PaddingSpacing;
  paddingBottom?: PaddingSpacing;
  paddingLeft?: PaddingSpacing;
  paddingRight?: PaddingSpacing;
  paddingTop?: PaddingSpacing;
  paddingX?: PaddingSpacing;
  paddingY?: PaddingSpacing;
  role?: string;
  style?: React.CSSProperties;
  title?: string | React.ReactNode;
  to?: string;
  'data-testid'?: string;
  fullWidth?: boolean;
  border?: 'Left' | 'Top' | 'Bottom' | 'Right';
  scrollable?: boolean | 'x' | 'y';
}

export const Box = forwardRef<HTMLElement, BoxProps>((props, ref) => {
  const {
    flex,
    style = {},
    children,
    href,
    element = 'div',
    scrollable = false,
    ...rest
  } = props;
  const classNames = getClassNames(rest);
  const filteredProps = omit(props as Record<string, unknown>, [
    ...Object.keys(STYLE_PROPS),
    'fullWidth',
    'sticky',
    'element',
    'className',
    'scrollable',
  ]);

  if (flex) {
    style.flex = flex;
  }

  filteredProps['data-testid'] = props['data-testid']
    ? props['data-testid']
    : 'box-test-id';
  const boxProps = {
    ...filteredProps,
    ref,
    // ['data-testid']: 'box-test-id',
    className: classNames,
    style,
  };

  if (scrollable) {
    return (
      <ScrollBox
        scrollable={scrollable}
        ref={ref}
        className={classNames}
        style={style}
        onScroll={props.onScroll}
        filteredProps={filteredProps}
      >
        {children}
      </ScrollBox>
    );
  }

  return React.createElement(href ? 'a' : element, boxProps, children);
});

interface ScrollBoxProps {
  className: string;
  style: React.CSSProperties;
  scrollable: true | 'x' | 'y';
  filteredProps: Record<string, unknown>;
  onScroll?: UIEventHandler<HTMLDivElement>;
}

const ScrollBox = forwardRef<HTMLElement, PropsWithChildren<ScrollBoxProps>>(
  (props, parentRef) => {
    const { className, style, children, scrollable, onScroll, filteredProps } =
      props;

    const osRef = useRef<OverlayScrollbarsComponentRef>(null);

    useEffect(() => {
      const scrollElement =
        osRef.current?.osInstance()?.elements().viewport ?? null;
      if (parentRef) {
        if (typeof parentRef === 'function') {
          parentRef(scrollElement);
        } else {
          parentRef.current = scrollElement;
        }
      }
    }, [osRef, parentRef]);

    useEffect(() => {
      const scrollElement =
        osRef.current?.osInstance()?.elements().viewport ?? null;
      if (scrollElement && onScroll) {
        const scrollHandler = (event: Event) => {
          onScroll({
            ...event,
            currentTarget: event.target,
          } as unknown as React.UIEvent<HTMLDivElement>);
        };
        scrollElement.addEventListener('scroll', scrollHandler);
        return () => scrollElement.removeEventListener('scroll', scrollHandler);
      }
    }, [osRef, onScroll]);

    return (
      <OverlayScrollbarsComponent
        ref={osRef}
        className={className}
        style={style}
        options={{
          overflow: {
            x: scrollable === true || scrollable === 'x' ? 'scroll' : 'hidden',
            y: scrollable === true || scrollable === 'y' ? 'scroll' : 'hidden',
          },
          scrollbars: {
            theme: 'os-theme-light',
          },
        }}
        {...filteredProps}
      >
        {children}
      </OverlayScrollbarsComponent>
    );
  }
);

const getClassNames = (props: BoxProps): string => {
  const { className = '' } = props;
  const classNames: string[] = [styles.box];

  const getStyleValueFromProps = (
    declaration: keyof BoxProps,
    props: BoxProps
  ): string | null => {
    if (!props[declaration]) {
      return null;
    }

    const value = `${props[declaration]}`;
    if (
      declaration.startsWith('padding') ||
      declaration.startsWith('margin') ||
      declaration.startsWith('gap')
    ) {
      return value.replace('.', '');
    }
    return value;
  };

  Object.keys(props)
    .filter(a => Object.keys(STYLE_PROPS).indexOf(a) !== -1)
    .forEach((declaration: string) => {
      const value = getStyleValueFromProps(
        declaration as keyof BoxProps,
        props
      );
      if (value) {
        const declarations =
          STYLE_PROPS[declaration].length > 0
            ? STYLE_PROPS[declaration]
            : [declaration];

        declarations.forEach(declaration => {
          if (typeof styles[`${declaration}-${value}`] !== 'undefined') {
            classNames.push(styles[`${declaration}-${value}`]);
          }
        });
      }
    });

  if (props.fullWidth) {
    classNames.push(styles.fullWidth);
  }

  if (props.border) {
    classNames.push(styles['border' + props.border]);
  }

  return [...className.split(' '), ...classNames]
    .filter(a => a !== '')
    .join(' ');
};

const STYLE_PROPS: Record<string, string[]> = {
  marginX: ['marginLeft', 'marginRight'],
  marginY: ['marginTop', 'marginBottom'],
  gap: [],
  gapRow: [],
  gapColumn: [],
  paddingX: ['paddingLeft', 'paddingRight'],
  paddingY: ['paddingTop', 'paddingBottom'],
  display: [],
  flexDirection: [],
  flexWrap: [],
  id: [],
  alignItems: [],
  justifyContent: [],
  padding: [],
  paddingTop: [],
  paddingBottom: [],
  paddingLeft: [],
  paddingRight: [],
  margin: [],
  marginTop: [],
  marginBottom: [],
  marginLeft: [],
  marginRight: [],
};

import { Storage } from '@contracts/Storage';

export const getStorageJsonFile = async (storage: Storage, key?: string) => {
  let json = undefined;
  try {
    json = key ? await storage.GetJson(key) : undefined;
  } catch (err) {
    console.error(`Could not get ${key} file from storage`, err);
  }

  return json;
};

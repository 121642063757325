import React from 'react';
import { useSelector } from 'react-redux';
import { Workflow } from '@entities/Workflow';
import { selectWorkflow } from '@selectors/BaseSelectors';
import { selectStudyType } from '@selectors/SessionSelectors';
import { StudyType } from '@entities/StudyType';
import { RadiologyLayout } from './Radiology/RadiologyLayout';
import { SonographerLayout } from './Sonographer/SonographerLayout';
import { UnknownStudyLayout } from './UnknownStudyLayout';

export default () => {
  const workflow = useSelector(selectWorkflow);
  const studyType = useSelector(selectStudyType);

  if (studyType === StudyType.Unknown) return <UnknownStudyLayout />;

  return workflow === Workflow.Radiologist ? (
    <RadiologyLayout />
  ) : (
    <SonographerLayout />
  );
};

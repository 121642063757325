// Node env
export const nodeEnv = process.env.NODE_ENV || 'development';
export const isDevelopment = nodeEnv === 'development';

// App

// Amplify config
export const amplifyAppRegion = process.env.AMPLIFY_APP_REGION || '';

// API config
export const apiBaseUrl = process.env.API_BASE_URL || '';

// Sentry config
export const sentryDSN = process.env.SENTRY_DSN || '';
export const sentryEnvironment = process.env.SENTRY_ENVIRONMENT || '';
export const sentryTraceSampleRate =
  parseInt(process.env.SENTRY_TRACE_SAMPLE_RATE || '1.0', 10) || 1.0;
export const sentryEnabled =
  process.env.SENTRY_ENABLED === 'true' || !isDevelopment;
// Segment.io config
export const segmentioEnabled =
  process.env.SEGMENTIO_ENABLED === 'true' || !isDevelopment;
export const segmentioKey = process.env.SEGMENTIO_KEY;

// Verbose
export const verboseLevel: string[] =
  process.env.VERBOSE === 'true'
    ? ['all']
    : JSON.parse(process.env.VERBOSE || '[]');

// Launch Darkly
export const launchDarklyClientId = process.env.LAUNCH_DARKLY_CLIENT_ID || '';

// Onboarding (Institutions)
export const institutionIdOverride = process.env.INSTITUTION_ID_OVERRIDE || '';

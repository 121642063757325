import { schemas } from '@api/schema/client';
import {
  FindingSortConfigEnum,
  InstitutionWorkflowEnum,
  NoduleDisplayUnitEnum,
  SupportedRegionEnum,
  ThyroidPoleVariantEnum,
} from '@api/schema/generated';
import { InstitutionRegion } from '@entities/Admin/InstitutionLineItem';
import {
  FindingSortType,
  InstitutionConfiguration,
  SizeVariant,
  ThyroidPoleVariant,
} from '@entities/InstitutionConfiguration';
import { DistanceUnit } from '@entities/Unit';
import { Workflow } from '@entities/Workflow';

export const decodeInstitutionConfig = (
  config: schemas['InstitutionConfigResponse']['config']
): InstitutionConfiguration => {
  return {
    workflow: decodeWorkflow(config.workflow),
    thyroidSizeVariant: decodeSizeVariant(config.size_3d_thyroid),
    breastSizeVariant: decodeSizeVariant(config.size_3d_breast),
    autosizing: config.autosizing,
    noduleSizeDisplayUnit: decodeNoduleSizeDisplayUnit(
      config.nodule_size_display_unit
    ),
    minimumNoduleSizeThreshold:
      config.minimum_nodule_size_threshold_in_mm?.toString() ?? undefined,
    showBiradsScore: config.show_birads_score,
    simplifiedBreastReportingWorkflow:
      config.simplified_breast_reporting_workflow,
    thyroidAutoGrouping: config.thyroid_auto_grouping,
    thyroidPoleVariant: decodeThyroidPoleVariant(config.thyroid_pole_variant),
    thyroidSortType: decodeFindingSortType(config.thyroid_finding_sort_config),
    breastSortType: decodeFindingSortType(config.breast_finding_sort_config),
  };
};

export const encodeInstitutionConfig = (
  data: InstitutionConfiguration
): schemas['UpdateInstitutionConfigInput']['config'] => {
  return {
    workflow: data.workflow && encodeWorkflow(data.workflow),
    size_3d_thyroid: encodeSizeVariant(data.thyroidSizeVariant),
    size_3d_breast: encodeSizeVariant(data.breastSizeVariant),
    autosizing: data.autosizing,
    nodule_size_display_unit: encodeNoduleSizeDisplayUnit(
      data.noduleSizeDisplayUnit
    ),
    minimum_nodule_size_threshold_in_mm: encodeNumberOrNull(
      data.minimumNoduleSizeThreshold
    ),
    show_birads_score: data.showBiradsScore,
    simplified_breast_reporting_workflow:
      data.simplifiedBreastReportingWorkflow,
    thyroid_auto_grouping: data.thyroidAutoGrouping,
    thyroid_pole_variant: encodeThyroidPoleVariant(data.thyroidPoleVariant),
    thyroid_finding_sort_config:
      data.thyroidSortType && encodeFindingSortType(data.thyroidSortType),
    breast_finding_sort_config:
      data.breastSortType && encodeFindingSortType(data.breastSortType),
  };
};

function encodeNumberOrNull(data: string | undefined) {
  if (!data) return null;
  const n = parseFloat(data);
  return isNaN(n) ? null : n;
}

function decodeWorkflow(data: schemas['InstitutionWorkflowEnum']) {
  switch (data) {
    case InstitutionWorkflowEnum.radiologist:
      return Workflow.Radiologist;
    case InstitutionWorkflowEnum.sonographer:
      return Workflow.Sonographer;
  }
}

function encodeWorkflow(data: Workflow) {
  switch (data) {
    case Workflow.Radiologist:
      return InstitutionWorkflowEnum.radiologist;
    case Workflow.Sonographer:
    default:
      return InstitutionWorkflowEnum.sonographer;
  }
}

function decodeNoduleSizeDisplayUnit(data: schemas['NoduleDisplayUnitEnum']) {
  switch (data) {
    case NoduleDisplayUnitEnum.MM:
      return DistanceUnit.MM;
    case NoduleDisplayUnitEnum.CM:
      return DistanceUnit.CM;
  }
}

function encodeNoduleSizeDisplayUnit(
  data: DistanceUnit.CM | DistanceUnit.MM | undefined
) {
  switch (data) {
    case DistanceUnit.MM:
      return NoduleDisplayUnitEnum.MM;
    case DistanceUnit.CM:
      return NoduleDisplayUnitEnum.CM;
  }
}

function decodeThyroidPoleVariant(data: schemas['ThyroidPoleVariantEnum']) {
  switch (data) {
    case ThyroidPoleVariantEnum.five_poles:
      return ThyroidPoleVariant.FivePoles;
    case ThyroidPoleVariantEnum.three_poles:
      return ThyroidPoleVariant.ThreePoles;
  }
}

function encodeThyroidPoleVariant(data: ThyroidPoleVariant | undefined) {
  switch (data) {
    case ThyroidPoleVariant.FivePoles:
      return ThyroidPoleVariantEnum.five_poles;
    case ThyroidPoleVariant.ThreePoles:
      return ThyroidPoleVariantEnum.three_poles;
  }
}

function encodeSizeVariant(data: SizeVariant | undefined) {
  switch (data) {
    case SizeVariant.Size1D:
      return false;
    case SizeVariant.Size3D:
      return true;
    default:
      return undefined;
  }
}

function decodeSizeVariant(data: boolean | undefined) {
  switch (data) {
    case false:
      return SizeVariant.Size1D;
    case true:
      return SizeVariant.Size3D;
    default:
      return undefined;
  }
}

export function encodeFindingSortType(data: FindingSortType) {
  switch (data) {
    case FindingSortType.Severity:
      return FindingSortConfigEnum.severity;
    case FindingSortType.Side:
      return FindingSortConfigEnum.severity_by_side;
  }
}

export function decodeFindingSortType(data: FindingSortConfigEnum) {
  switch (data) {
    case FindingSortConfigEnum.severity:
      return FindingSortType.Severity;
    case FindingSortConfigEnum.severity_by_side:
      return FindingSortType.Side;
  }
}

export function decodeInstitutionRegion(
  data: schemas['SupportedRegionEnum']
): InstitutionRegion {
  switch (data) {
    case SupportedRegionEnum.ap_southeast_2:
      return 'ap-southeast-2';
    case SupportedRegionEnum.ca_central_1:
      return 'ca-central-1';
    case SupportedRegionEnum.us_east_2:
      return 'us-east-2';
  }
}

export function encodeInstitutionRegion(
  data: InstitutionRegion
): schemas['SupportedRegionEnum'] {
  switch (data) {
    case 'ap-southeast-2':
      return SupportedRegionEnum.ap_southeast_2;
    case 'ca-central-1':
      return SupportedRegionEnum.ca_central_1;
    case 'us-east-2':
      return SupportedRegionEnum.us_east_2;
  }
}

import React from 'react';
import { Boundary } from '@webPages/Error/Boundary';
import { dispatch, store } from '@webInterfaces/Store';
import { signOut } from '@webViewInterfaces/Pages/General';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (e: Error) => void;
}

interface State {
  error?: Error;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error) {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  public render() {
    if (this.state && this.state.error) {
      const state = store.getState();
      const profile = state.app.map(a => a.profile).lift();

      return (
        <Boundary onSignOut={() => dispatch(signOut())} profile={profile} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

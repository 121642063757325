import { adminUsersUseCase } from '@useCases/Admin/Users';
import { Actions } from '@webInterfaces/StoreTypes';
import { Profile } from '@entities/Profile';

export const load = (userId?: string): Actions => {
  return adminUsersUseCase.Load({ userId });
};

export const unload = (targetOnly?: boolean): Actions =>
  adminUsersUseCase.Unload({ targetOnly });

export const empty = (): Actions => adminUsersUseCase.CreateUserEmpty();

export const createUser = (userLineItem: Profile): Actions =>
  adminUsersUseCase.CreateUser({ userLineItem });

export const updateUser = (userLineItem: Profile): Actions =>
  adminUsersUseCase.UpdateUser({ userLineItem });

export const deleteUser = (id: string): Actions =>
  adminUsersUseCase.DeleteUser({ email: id });

import { InstitutionStatus } from '@entities/Admin/InstitutionLineItem';
import { t } from '@webInterfaces/I18n';
export * from '@entities/Admin/InstitutionLineItem';

export const institutionStatusToString = (
  customerStatus: InstitutionStatus
): string => {
  switch (customerStatus) {
    case InstitutionStatus.NotDeployed:
      return t('pages.settings.institution.status.not_deployed');
    case InstitutionStatus.Deployed:
      return t('pages.settings.institution.status.deployed');
  }
};

import React from 'react';
import { SidebarGroup } from '@webOrganisms/SidebarGroup/SidebarGroup';
import { SidebarItem } from '@webOrganisms/SidebarItem/SidebarItem';
import { SidebarHint } from '@webOrganisms/SidebarHint/SidebarHint';
import { Profile, ProfilePreferences } from '@interfaces/Profile';
import { Workspace } from '@interfaces/Workspace';
import { Avatar } from '@webMolecules/Avatar/Avatar';
import { t } from '@webInterfaces/I18n';
import * as WorkspaceGroup from '@interfaces/WorkspaceGroup';
import { WorkspaceStatus } from '@interfaces/WorkspaceStatus';
import * as RouteHelper from '@helpers/routes';
import { FilterOptions } from '@interfaces/FilterOptions';
import Maybe from '@monads/Maybe';
import { FilterListLineItem } from '@webOrganisms/FilterList/FilterList';
import UserFilterModal from '@webOrganisms/UserFilterModal/UserFilterModal';
import WorkSpaceFilterModal from '@webOrganisms/WorkSpaceFilterModal/WorkSpaceFilterModal';
import { useAuthenticationMode } from '@interfaces/Authentication';

export interface SidebarProps {
  me: Profile;
  availableWorkspaces: Maybe<Workspace[]>;
  availableProfiles: Profile[];
  workspaceStatus: WorkspaceStatus[];
  filterOptions?: FilterOptions;
  onUpdateUserProfilePreferences: (
    profilePreferences: ProfilePreferences
  ) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  me,
  availableWorkspaces,
  availableProfiles,
  workspaceStatus,
  filterOptions,
  onUpdateUserProfilePreferences,
}) => {
  const [showUserFilterModal, setShowUserFilterModal] = React.useState(false);
  const [showWorkspaceFilterModal, setShowWorkspaceFilterModal] =
    React.useState(false);

  const searchIsActive =
    window.location.pathname.indexOf(RouteHelper.search('')) === 0;

  const [profilePreferences, setProfilePreferences] =
    React.useState<ProfilePreferences>(me.preferences);
  const [timeoutRef, setTimeoutRef] = React.useState<NodeJS.Timeout | null>(
    null
  );
  React.useEffect(() => undefined, [me, filterOptions]);

  const { isAdminDomain } = useAuthenticationMode();

  const workspacesLoaded = availableWorkspaces.isJust();
  const currentAvailableWorkspaces = workspacesLoaded
    ? (availableWorkspaces.lift() as Workspace[])
    : [];

  const filteredAvailableWorkSpaces = isAdminDomain
    ? currentAvailableWorkspaces
    : currentAvailableWorkspaces.filter(({ id }) =>
        profilePreferences.workspaceFilters?.includes(id)
      );

  const countWorkspaceSelectedItem = (): number =>
    workspacesLoaded ? filteredAvailableWorkSpaces.length : 0;

  const getWorkspaceCount = (
    group: WorkspaceGroup.WorkspaceGroup,
    id: string
  ): undefined | number =>
    workspaceStatus.find(a => a.group === group && a.id === id)
      ?.totalAmountOfNewItems;

  const updateProfilePreferences = (value: ProfilePreferences) => {
    setProfilePreferences(value);
    timeoutRef && clearTimeout(timeoutRef);
    setTimeoutRef(
      setTimeout(() => {
        onUpdateUserProfilePreferences(value);
      }, 1500)
    );
  };
  const availableProfilesMeFirst = [
    ...availableProfiles.filter(a => a.id === me.id),
    ...availableProfiles.filter(a => a.id !== me.id),
  ];

  const filteredProfiles = isAdminDomain
    ? availableProfilesMeFirst
    : availableProfilesMeFirst.filter(
        ({ id }) => me.id === id || profilePreferences.userFilters?.includes(id)
      );
  const userSidebarGroup = (
    <SidebarGroup
      heading={t('pages.worklist.sidebar.users.title')}
      onToggleModal={
        !isAdminDomain
          ? () => {
              setShowUserFilterModal(true);
            }
          : undefined
      }
    >
      {filteredProfiles.map(profile => {
        const workspaceCount = getWorkspaceCount(
          WorkspaceGroup.WorkspaceGroup.User,
          profile.id
        );

        return (
          <SidebarItem
            key={profile.id}
            icon={
              <Avatar
                src=""
                name={profile.firstName}
                color={profile.swatchColour}
                size="medium"
              />
            }
            title={`${profile.firstName} ${profile.lastName}`}
            active={
              !searchIsActive &&
              filterOptions?.group === WorkspaceGroup.WorkspaceGroup.User &&
              filterOptions?.id === profile.id
            }
            to={RouteHelper.worklist(
              WorkspaceGroup.toString(WorkspaceGroup.WorkspaceGroup.User),
              profile.id,
              'active'
            )}
            badge={workspaceCount}
          />
        );
      })}
    </SidebarGroup>
  );

  const workspaceSidebarGroup = (
    <SidebarGroup
      heading={t('pages.worklist.sidebar.workspaces.title')}
      onToggleModal={
        !isAdminDomain
          ? () => {
              setShowWorkspaceFilterModal(true);
            }
          : undefined
      }
    >
      {filteredAvailableWorkSpaces.length === 0 ? (
        workspacesLoaded ? (
          <SidebarHint>
            {t(
              'pages.worklist.sidebar.workspaces.default.configuration.message'
            )}
          </SidebarHint>
        ) : null
      ) : countWorkspaceSelectedItem() > 0 ? (
        filteredAvailableWorkSpaces.map(workspace => {
          const workspaceCount = getWorkspaceCount(
            WorkspaceGroup.WorkspaceGroup.Workspace,
            workspace.id
          );
          return (
            <SidebarItem
              key={workspace.id}
              title={workspace.name}
              active={
                !searchIsActive &&
                filterOptions?.group ===
                  WorkspaceGroup.WorkspaceGroup.Workspace &&
                filterOptions?.id === workspace.id
              }
              to={RouteHelper.worklist(
                WorkspaceGroup.toString(
                  WorkspaceGroup.WorkspaceGroup.Workspace
                ),
                workspace.id,
                'active'
              )}
              badge={workspaceCount}
            />
          );
        })
      ) : !isAdminDomain ? (
        <SidebarHint>
          {t('pages.worklist.sidebar.workspaces.default.message')}
        </SidebarHint>
      ) : null}
    </SidebarGroup>
  );
  const availableProfilesWithoutMe = [
    ...availableProfiles.filter(a => a.id !== me.id),
  ];
  const userProfileItems = availableProfilesWithoutMe
    .filter(profile => !!profile.firstName && profile.isActive)
    .map(profile => {
      return {
        id: profile.id,
        title: `${profile.firstName} ${profile.lastName}`,
        matchString: `${profile.firstName} ${profile.lastName} ${profile.email}`,
        swatchColour: profile.swatchColour,
        added:
          me.id === profile.id ||
          !!profilePreferences.userFilters?.find(id => profile.id === id),
      } as FilterListLineItem;
    });

  const userFilterModal = (
    <UserFilterModal
      isOpen={showUserFilterModal}
      subtitle={t('userfilter.modal.subtitle.sidebar')}
      items={userProfileItems}
      closeModal={() => {
        setShowUserFilterModal(false);
      }}
      onAdd={itemId => {
        const mutatedProfilePreferences = { ...profilePreferences };

        if (!mutatedProfilePreferences.userFilters) {
          mutatedProfilePreferences.userFilters = [];
        }
        mutatedProfilePreferences.userFilters.push(itemId);

        mutatedProfilePreferences.userFilters =
          mutatedProfilePreferences.userFilters?.filter(id =>
            availableProfiles.find(profile => profile.id === id)
          );

        updateProfilePreferences(mutatedProfilePreferences);
      }}
      onRemove={itemId => {
        const mutatedProfilePreferences = { ...profilePreferences };
        mutatedProfilePreferences.userFilters =
          mutatedProfilePreferences.userFilters?.filter(id => id !== itemId);
        mutatedProfilePreferences.userFilters =
          mutatedProfilePreferences.userFilters?.filter(id =>
            availableProfiles.find(profile => profile.id === id)
          );

        updateProfilePreferences(mutatedProfilePreferences);
      }}
    ></UserFilterModal>
  );

  const workSpaceItems = currentAvailableWorkspaces.map(workspace => {
    return {
      id: workspace.id,
      title: `${workspace.name}`,
      matchString: `${workspace.name}`,
      added: !!profilePreferences.workspaceFilters?.find(
        id => workspace.id === id
      ),
    } as FilterListLineItem;
  });

  const workspaceFilterModal = (
    <WorkSpaceFilterModal
      isOpen={showWorkspaceFilterModal}
      subtitle={t('workspacefilter.modal.subtitle')}
      items={workSpaceItems}
      closeModal={() => {
        setShowWorkspaceFilterModal(false);
      }}
      onAdd={itemId => {
        const mutatedProfilePreferences = { ...profilePreferences };

        if (!mutatedProfilePreferences.workspaceFilters) {
          mutatedProfilePreferences.workspaceFilters = [];
        }
        mutatedProfilePreferences.workspaceFilters.push(itemId);

        mutatedProfilePreferences.workspaceFilters =
          mutatedProfilePreferences.workspaceFilters?.filter(id =>
            currentAvailableWorkspaces.find(workspace => workspace.id === id)
          );

        updateProfilePreferences(mutatedProfilePreferences);
      }}
      onRemove={itemId => {
        const mutatedProfilePreferences = { ...profilePreferences };

        mutatedProfilePreferences.workspaceFilters =
          mutatedProfilePreferences.workspaceFilters?.filter(
            id => id !== itemId
          );

        mutatedProfilePreferences.workspaceFilters =
          mutatedProfilePreferences.workspaceFilters?.filter(id =>
            currentAvailableWorkspaces.find(workspace => workspace.id === id)
          );

        updateProfilePreferences(mutatedProfilePreferences);
      }}
    ></WorkSpaceFilterModal>
  );

  return (
    <>
      {userSidebarGroup}
      {workspaceSidebarGroup}
      {userFilterModal}
      {workspaceFilterModal}
    </>
  );
};

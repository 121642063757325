import React from 'react';
import { Box, BoxProps } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';

export interface LabelProps extends BoxProps {
  children?: React.ReactNode;
  htmlFor?: string;
}

export const Label: React.FC<LabelProps> = ({
  htmlFor,
  children,
  ...props
}) => {
  return (
    // TODO: consider what and how to spread props to Label component
    <Box {...props}>
      <label htmlFor={htmlFor}>
        <Text type="display5">{children}</Text>
      </label>
    </Box>
  );
};

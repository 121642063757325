import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { InteractiveBox } from '@webMolecules/InteractiveBox/InteractiveBox';
import { Badge } from '@webMolecules/Badge/Badge';
import styles from './sidebarItem.scss';

export interface SidebarItemProps {
  loading?: boolean;
  to?: string;
  title?: string;
  badge?: number | '';
  icon?: JSX.Element;
  active?: boolean;
}

export const SidebarItem = ({
  to = '/',
  title = 'neutral',
  icon,
  badge,
  active,
}: SidebarItemProps) => {
  const { pathname } = window.location || useLocation();
  active = active || pathname.indexOf(to) === 0;
  return (
    <InteractiveBox
      element={Link}
      to={to}
      active={active}
      padding="xxs"
      style={{ margin: '0 calc(-1 * var(--ds-spacing-xxs))' }}
    >
      <Box display="flex" alignItems="center" className={styles.siderbarItem}>
        {icon && <Box marginRight="s">{icon}</Box>}
        <Box>
          <Text data-testid="sidebar-item" type="display5" display="block">
            {title}
          </Text>
        </Box>
        {badge ? (
          <Box marginLeft="auto" marginRight="xxs">
            <Badge intent="information">{badge}</Badge>
          </Box>
        ) : null}
      </Box>
    </InteractiveBox>
  );
};

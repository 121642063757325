import * as AdminUsers from '@entities/Admin/Users';
import * as AdminWorkspaces from '@entities/Admin/Workspaces';
import * as AdminInstitutions from '@entities/Admin/Institutions';
import * as AdminApplication from '@entities/Admin/Application';

export interface Admin {
  adminApplication: AdminApplication.AdminApplication;
  adminUsers: AdminUsers.AdminUsers;
  adminWorkspaces: AdminWorkspaces.AdminWorkspaces;
  adminInstitutions: AdminInstitutions.AdminInstitutions;
}

export const initial = (): Admin => ({
  adminApplication: AdminApplication.initial(),
  adminUsers: AdminUsers.initial(),
  adminWorkspaces: AdminWorkspaces.initial(),
  adminInstitutions: AdminInstitutions.initial(),
});

import { Admin, initial } from '@entities/Admin';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { FilterConfiguration as ContractFilterConfiguration } from '@contracts/Admin/FilterConfiguration';
import { Location as ContractLocation } from '@contracts/Location';
import { Institutions as ContactInstitutions } from '@contracts/Admin/Institutions';
import { Storage as ContractStorage } from '@contracts/Storage';
import { Institution as ContractInstitution } from '@contracts/Admin/Institution';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import { AdminUsers } from '@entities/Admin/Users';
import { AdminWorkspaces } from '@entities/Admin/Workspaces';
import { AdminInstitutions } from '@entities/Admin/Institutions';
import { AdminApplication } from '@entities/Admin/Application';
import { AdminInstitutionsReducer } from './Admin/Institutions';
import { AdminWorkspacesReducer } from './Admin/Workspaces';
import { AdminUsersReducer } from './Admin/Users';
import { AdminApplicationReducer } from './Admin/Application';

export const adminUseCases = {};

/**
 *  All UseCases
 * */
export type AdminUseCases = never;

/**
 * Reducer
 *
 * The AdminReducer takes AdminUseCases and the current Admin (state)
 * and returns a new state and possible side effects.
 */
export class AdminReducer extends EffectReducer<Admin> {
  private adminUsersReducer: AdminUsersReducer;
  private adminWorkspacesReducer: AdminWorkspacesReducer;
  private adminInstitutionsReducer: AdminInstitutionsReducer;
  private adminApplicationReducer: AdminApplicationReducer;

  constructor(
    apiFilterConfiguration: ContractFilterConfiguration,
    apiLocation: ContractLocation,
    apiInstitutions: ContactInstitutions,
    apiStorage: ContractStorage,
    apiProfiles: ContractProfiles,
    apiInstitution: ContractInstitution
  ) {
    super();

    this.adminUsersReducer = new AdminUsersReducer(
      apiStorage,
      apiLocation,
      apiProfiles
    );
    this.adminWorkspacesReducer = new AdminWorkspacesReducer(
      apiFilterConfiguration,
      apiLocation
    );
    this.adminInstitutionsReducer = new AdminInstitutionsReducer(
      apiInstitutions,
      apiLocation
    );
    this.adminApplicationReducer = new AdminApplicationReducer(apiInstitution);

    this.CreateSubReducer<AdminUsers>(
      this.adminUsersReducer,
      admin => admin.adminUsers,
      (admin, adminUsers) => ({ ...admin, adminUsers })
    );

    this.CreateSubReducer<AdminWorkspaces>(
      this.adminWorkspacesReducer,
      admin => admin.adminWorkspaces,
      (admin, adminWorkspaces) => ({ ...admin, adminWorkspaces })
    );

    this.CreateSubReducer<AdminInstitutions>(
      this.adminInstitutionsReducer,
      admin => admin.adminInstitutions,
      (admin, adminInstitutions) => ({ ...admin, adminInstitutions })
    );

    this.CreateSubReducer<AdminApplication>(
      this.adminApplicationReducer,
      admin => admin.adminApplication,
      (admin, adminApplication) => ({ ...admin, adminApplication })
    );
  }

  Perform(
    admin: Admin = initial(),
    action: AdminUseCases
  ): ReducerResult<Admin> {
    return this.SubReduce(admin, action);
  }
}

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Study/Gallery';
import { Gallery } from '@webOrganisms/Gallery/Gallery';

const GalleryWrapper: React.FC<ViewModel.Gallery> = props => {
  const [keyPressed, setKeyPressed] = React.useState(false);
  React.useEffect(() => {
    const downHandler = (e: any) => {
      window.removeEventListener('keydown', downHandler);
      const event = document.all ? window.event : e;

      if (
        !/^(?:input['range']|textarea)$/i.test(event.target.tagName) &&
        event.keyCode === 68
      ) {
        setKeyPressed(true);
      }
      return keyPressed;
    };
    const upHandler = (e: any) => {
      window.addEventListener('keydown', downHandler);
      if (e.keyCode === 68) {
        setKeyPressed(false);
      }
    };
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return <Gallery {...props} hotKeyDown={keyPressed} />;
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(GalleryWrapper);

import { BreastFindingPosition } from '@entities/Characteristics';
import { DistanceUnit } from '@entities/Unit';

export const INITIAL_LANDSCAPE_CANVAS_WIDTH = 900;
export const INITIAL_LANDSCAPE_CANVAS_HEIGHT = 393;
export const LANDSCAPE_CANVAS_WIDTH_HEIGHT_RATIO = 0.4375;
export const SONOGRAPHER_THYROID_CANVAS_WIDTH_HEIGHT_RATIO = 0.64;

export const INITIAL_PORTRAIT_CANVAS_WIDTH = 600;
export const INITIAL_PORTRAIT_CANVAS_HEIGHT = 960;
export const PORTRAIT_CANVAS_WIDTH_HEIGHT_RATIO = 1.6;

export const STUDY_TEMPLATE_VERTICAL_PADDING = 40;

// from carotid template height
export const GENERIC_CANVAS_TEMPLATE_FIXED_HEIGHT = 450;

export const OUTLINE_BODY_STROKE_WIDTH = 2;
export const OUTLINE_VESSEL_STROKE_WIDTH = 1;
export const BODY_OUTLINE_STROKE_DASH_PATTERN = [10, 5];
export const INITIAL_CANVAS_OFFSET = { x: 0, y: 0 };
export const CORNER_SIZE = 8;
export const MINIMUM_OBJECT_WALL_LENGTH = 5;
export const MOVEMENT_DIRECTION_TOLERANT_VALUE = 3;

export const ORIGIN_CENTER = { originX: 'center', originY: 'center' };

export const LABEL_FONT_SIZE = 10;
export const LABEL_BADGE_SIZE = 10;
export const NODULE_OVERLAP_CLEARANCE = 20;
export const NODULE_LABEL_FONT_SIZE_WORKSHEET = 10;
export const NODULE_LABEL_FONT_SIZE_SUMMARY = 15;
export const SMALL_PARTS_LABEL_FONT_SIZE = 16;
export const LABEL_FONT_FAMILY = 'Inter';
export const OBJECT_PADDING = 4;
export const TEMPLATE_WALL_LENGTH = 400;
export const TEMPLATE_VESSEL_WIDTH = 200;
export const PERCENTAGE_VESSEL_WIDTH_FOR_OBJECTS = 0.5;

export const MIN_SCALE = 0.5;
export const MAX_SCALE = 1.5;
export const MIN_OBJECT_SCALE = 0.1;
export const MAX_OBJECT_SCALE = 2.0;

export const CONTEXT_BTN_HORIZONTAL_PADDING = 5;
export const DEFAULT_CONTROLS_VISIBILITY = {
  tl: false,
  tr: false,
  bl: false,
  br: false,
  mtr: false,
  mt: true,
  mb: true,
  ml: true,
  mr: true,
};

export const NODULE_SHAPE_WIDER_THAN_TALL_RATIO = 1.5;

// Thyroid
export const THYROID_TEMPLE_HEIGHT_IN_MM = 60;
export const DEFAULT_THYROID_FINDING_POLE = 'mid';
export const DEFAULT_THYROID_FINDING_WIDTH_IN_MM = 10;
export const CALCULATE_TEMPLATE_HEIGHT_RATIO =
  DEFAULT_THYROID_FINDING_WIDTH_IN_MM / THYROID_TEMPLE_HEIGHT_IN_MM;

// Breast
export const BREAST_RADIUS_IN_MM = 80;
export const DEFAULT_BREAST_FINDING_POSITION = BreastFindingPosition.Breast;
export const DEFAULT_BREAST_FINDING_WIDTH_IN_MM = 30;

export const DEFAULT_BREAST_FINDING_CLOCK_POSITION_HOUR = 12;
export const DEFAULT_BREAST_FINDING_CLOCK_POSITION_MIN = 0;
export const DEFAULT_BREAST_FINDING_DISTANCE_FN_IN_MM = 0;

// finding
export const NODULE_REDUCED_OPACITY = 0.2;
export const NODULE_LABEL_BG_BORDER_RADIUS = 5;
export const NODULE_OUTLINE_PADDING = 3;
export const NODULE_OUTLINE_WIDTH = 4;
export const NODULE_LABEL_ICON_LEFT_PADDING = 8;
export const NODULE_LABEL_RULER_ICON_TOP_PADDING = 28;
export const NODULE_LABEL_CLOCK_ICON_TOP_PADDING = 9;

export const NODULE_LABEL_TEXT_LEFT_PADDING = 24;
export const NODULE_LABEL_ICON_SIZE = 12;
export const NODULE_LABEL_VERTICAL_PADDING = 10;
export const LABEL_HORIZONTAL_PADDING = 16;
export const LABEL_VERTICAL_PADDING = 5;

export const INDICATION_LINE_STROKE_WIDTH = 1;
export const LABEL_DEFAULT_ANGLE = -45; // top right

// free drawing
export const FREEHAND_BRUSH_SIZE_SMALL = 2;
export const FREEHAND_BRUSH_SIZE_MEDIUM = 5;
export const FREEHAND_BRUSH_SIZE_LARGE = 10;

export const ERASER_BRUSH_SIZE_SMALL = 3;
export const ERASER_BRUSH_SIZE_MEDIUM = 10;
export const ERASER_BRUSH_SIZE_LARGE = 13;

export enum BrushSize {
  S = 'small',
  M = 'medium',
  L = 'large',
}

export const FREEHAND_BRUSH_SIZE_OPTIONS_MAP = new Map([
  [BrushSize.S, FREEHAND_BRUSH_SIZE_SMALL],
  [BrushSize.M, FREEHAND_BRUSH_SIZE_MEDIUM],
  [BrushSize.L, FREEHAND_BRUSH_SIZE_LARGE],
]);

export const ERASER_BRUSH_SIZE_OPTIONS_MAP = new Map([
  [BrushSize.S, ERASER_BRUSH_SIZE_SMALL],
  [BrushSize.M, ERASER_BRUSH_SIZE_MEDIUM],
  [BrushSize.L, ERASER_BRUSH_SIZE_LARGE],
]);

// finding images DataURL
export const EXPORTED_IMAGE_LEFT_OFFSET = 4;

export const DistanceUnitInCanvas = DistanceUnit.MM;

// cystic nodule
export const CYSTIC_NODULE_STROKE_DASH_ARRAY = [6, 6];
export const CYSTIC_NODULE_STROKE_WIDTH = 8.4;

import { InstitutionLineItem } from '@entities/Admin/InstitutionLineItem';
import { Institution } from '@entities/Admin/Institution';
import { ApiStatus } from '@entities/Status';
import { Message } from '@entities/Message';
import Maybe, { nothing } from '@monads/Maybe';
import Tuple, { pair } from '@monads/Tuple';
import { Profile } from '@interfaces/Profile';

export interface AdminInstitutions {
  institutionLineItems: Tuple<ApiStatus, InstitutionLineItem[]>;
  institutionUserLineItems: Tuple<ApiStatus, Profile[]>;
  messages: Message[];
  targetInstitutionLineItem: Maybe<InstitutionLineItem>;
  targetInstitution: Tuple<ApiStatus, Maybe<Institution>>;
  targetUser: Maybe<Profile>;
}

export const initial = (): AdminInstitutions => ({
  institutionLineItems: pair(ApiStatus.Idle, []),
  institutionUserLineItems: pair(ApiStatus.Idle, []),
  targetInstitutionLineItem: nothing(),
  targetInstitution: pair(ApiStatus.Idle, nothing()),
  targetUser: nothing(),
  messages: [],
});

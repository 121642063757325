import React, { ReactNode } from 'react';
import TextAreaResize from 'react-textarea-autosize';
import styles from './textarea.scss';
import { Box } from './../Box/Box';

export interface TextAreaProps {
  field?: string;
  defaultValue?: string;
  icon?: JSX.Element;
  fluid?: boolean;
  iconBefore?: string;
  iconAfter?: string;
  loading?: boolean;
  size?: string;
  disabled?: boolean;
  readOnly?: boolean;
  intent?: string;
  children?: ReactNode | JSX.Element[];
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  autoSize?: boolean;
  ref?: React.Ref<HTMLTextAreaElement>;
  proxiedKey: string;
  maxLength?: number;
}

/**
 * Primary UI component for user interaction
 */
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const {
      proxiedKey,
      rows,
      placeholder,
      fluid,
      defaultValue,
      loading,
      intent,
      iconBefore,
      iconAfter,
      disabled,
      readOnly,
      autoSize,
      onChange,
      onKeyUp,
      onBlur,
      maxLength,
    } = props;
    const textAreaClasses = [
      fluid ? styles.fluid : '',
      iconBefore ? styles.iconBefore : '',
      iconAfter ? styles.iconAfter : '',
      disabled ? styles.disabled : '',
      readOnly ? styles.readOnly : '',
      iconAfter || intent || loading ? styles.after : '',
      loading ? styles.iconLoading : '',
      styles.textarea,
    ].join(' ');

    const textAreaProps = {
      key: proxiedKey,
      readOnly,
      disabled,
      placeholder,
      rows,
      minrows: rows,
      className: textAreaClasses,
      defaultValue,
      onChange,
      onKeyUp,
      onBlur,
      ref,
      maxLength,
    };

    return (
      <Box
        fullWidth={fluid}
        display={fluid ? 'block' : 'inline-block'}
        className={styles.textAreaOuter}
      >
        {autoSize ? (
          <TextAreaResize
            data-testid="test-comment-section"
            style={{
              padding: 'var(--ds-spacing-xs)',
              fontSize: 'var(--ds-font-size-body)',
            }}
            {...textAreaProps}
            minRows={rows}
            key={`${proxiedKey}-autoresize`}
          />
        ) : (
          <textarea {...textAreaProps} />
        )}
        {(iconAfter || intent || loading) && (
          <Box
            className={styles.icon}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* {iconAfter ? iconAfter : inputIcon(icon)} Todo: add Input icon */}
          </Box>
        )}
        {iconBefore && (
          <Box
            className={styles.icon}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {iconBefore}
          </Box>
        )}
      </Box>
    );
  }
);

export interface InstitutionLineItem {
  id: string;
  institutionCopyText: string;
  region: InstitutionRegion;
  status: InstitutionStatus;
}

export type InstitutionRegion = 'ap-southeast-2' | 'ca-central-1' | 'us-east-2';

export enum InstitutionStatus {
  Deployed = 'institution_status--deployed',
  NotDeployed = 'institution_status--not_deployed',
}

export const defaultInstitutionCopyText = 'Login with your SAML account';

import React, { SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import {
  GalleryFilter,
  getDefaultGalleryFilter,
} from '@interfaces/GalleryFilter';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { useDispatcher } from '@helpers/useDispatcher';
import { selectFilterId } from '@selectors/FindingInteractionSelectors';
import {
  selectIsSessionLoading,
  selectSessionDetails,
  selectStudyType,
} from '@selectors/SessionSelectors';
import { sessionUseCase } from '@useCases/Session';
import { ImageProvider } from '@hooks/useImageContext';
import { LoadingLayout } from '@webPages/Study/LoadingLayout';
import { Header } from '@webOrganisms/Header/Header';
import { authenticateUseCase } from '@useCases/Authentication';
import { selectProfile } from '@selectors/BaseSelectors';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import DebugBar from './DebugBar';
import GalleryWrapper from './GalleryWrapper';
import { StudyTitle } from './Sonographer/StudyTitle';
import styles from './study.scss';

export const UnknownStudyLayout: React.FC = () => {
  const sessionDetails = useSelector(selectSessionDetails);
  const loading = sessionDetails == null;
  const filterId = useSelector(selectFilterId);
  const studyType = useSelector(selectStudyType);

  const setSessionViewed = useDispatcher(sessionUseCase.SetSessionViewed);
  const removeFilter = useDispatcher(findingInteractionUseCase.RemoveFilter);
  const [galleryFilter, setGalleryFilter] = React.useState<GalleryFilter>(
    getDefaultGalleryFilter(studyType, true)
  );

  const me = useSelector(selectProfile);
  const isLoading = useSelector(selectIsSessionLoading);

  const onSignOut = useDispatcher(authenticateUseCase.SignOut);

  React.useEffect(() => {
    return () => {
      setSessionViewed();
    };
  }, []);

  const galleryFilterWhenSelected = !!filterId
    ? { configKeys: [], findingIds: [filterId] }
    : galleryFilter;

  const setGalleryFilterWhenSelected: React.Dispatch<
    React.SetStateAction<GalleryFilter>
  > = (filter: SetStateAction<GalleryFilter>) => {
    if (!!filterId) {
      removeFilter();
    } else {
      setGalleryFilter(filter);
    }
  };
  return (
    <ImageProvider>
      <Box display="flex" flexDirection="column" className={styles.fullHeight}>
        <DebugBar />
        <Header
          profile={me}
          sticky={false}
          onSignOut={onSignOut}
          showSearchBar={false}
        >
          {isLoading ? (
            <Box display="flex" alignItems="center">
              <LoadingIcon />
            </Box>
          ) : (
            <StudyTitle />
          )}
        </Header>
        {isLoading ? (
          <LoadingLayout />
        ) : (
          <>
            <Box data-testid="page-loaded-indicator" display="inline" />

            <Box scrollable={true} flex="1 1 auto">
              <GalleryWrapper
                loading={loading}
                filters={galleryFilterWhenSelected}
                setGalleryFilter={setGalleryFilterWhenSelected}
                useFindingGalleryThumbnail
              />
            </Box>
          </>
        )}
      </Box>
    </ImageProvider>
  );
};

import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Divider } from '@webMolecules/Divider/Divider';
import { Text } from '@webMolecules/Text/Text';
import { Badge } from '@webMolecules/Badge/Badge';
import { Stack } from '@webMolecules/Stack/Stack';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { t } from '@webInterfaces/I18n';
import { Label } from '@webMolecules/Label/Label';
import { ThemeContainer } from '@webAtoms/Theme/ThemeContainer';
import { Button } from '@webMolecules/Button/Button';
import { Container } from '@webMolecules/Container/Container';
import {
  FormHint,
  formHintIntentFromString,
} from '@webOrganisms/FormHint/FormHint';
import { Icon } from '@webMolecules/Icon/Icon';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import * as StorySettingsChangePassword from '@webPages/Settings/ChangePassword';
import { Message } from '@interfaces/Message';
import styles from './security.scss';

export interface SecurityProfile {
  hasMFAenabled: boolean;
  verificationCode?: string;
  otpAuthUrl?: string;
}

export interface SecurityProps {
  securityProfile: SecurityProfile;
  onSetUpMFA: () => void;
  onDisableMFA: (otp: string) => void;
  onVerifyMFA: (otp: string) => void;
  message: Message | undefined;
  dispatchChangePassword: (oldPassword: string, newPassword: string) => void;
}

export interface SecurityFormProps {
  verificationCode: string;
}

enum FormAppearance {
  Enable = 'form_appearance--enable',
  Disable = 'form_appearance--disable',
  Initial = 'form_appearance--initial',
}

export const Security: React.FC<SecurityProps> = ({
  securityProfile,
  onSetUpMFA,
  onDisableMFA,
  onVerifyMFA,
  message,
  dispatchChangePassword,
}) => {
  const { verificationCode, otpAuthUrl, hasMFAenabled } = securityProfile;

  const formProps = { verificationCode } as SecurityFormProps;
  const verificationCodeSchema = Yup.string()
    .min(6, t('generic.validation.invalid.mfa'))
    .max(6, t('generic.validation.invalid.mfa'));
  const SecuritySettingsSchema = Yup.object().shape({
    verificationCode: verificationCodeSchema,
  });

  const [formAppearance, setFormAppearance] = useState<FormAppearance>(
    FormAppearance.Initial
  );
  const [formLoading, setFormLoading] = useState<boolean>(false);

  useEffect(() => {
    if (formLoading && message) {
      setFormLoading(false);
    }
  }, [message]);

  useEffect(() => {
    setFormAppearance(FormAppearance.Initial);
  }, [hasMFAenabled]);

  const handleFormSubmit = (form: SecurityFormProps) => {
    if (formAppearance === FormAppearance.Enable) {
      onVerifyMFA(form.verificationCode);
    } else if (formAppearance === FormAppearance.Disable) {
      onDisableMFA(form.verificationCode);
    }
    setFormLoading(true);
  };

  const handleUIStateChange = () => {
    if (hasMFAenabled) {
      setFormAppearance(FormAppearance.Disable);
    } else {
      onSetUpMFA();
      setFormAppearance(FormAppearance.Enable);
    }
  };

  return (
    <Container centered={false} size="xxxs" paddingBottom="xl">
      <Text type="display1" marginBottom="xl" display="block">
        {t('pages.settings.security.heading')}
      </Text>
      <StorySettingsChangePassword.ChangePassword
        onUpdate={dispatchChangePassword}
        message={message}
      />
      <Divider marginY="xxl" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="m"
      >
        <Text type="display3" display="block">
          {t('pages.settings.security.mfa.heading')}
        </Text>
      </Box>
      <Badge intent={hasMFAenabled ? 'success' : 'warning'}>
        {hasMFAenabled
          ? t('pages.settings.security.mfa.badge.enabled')
          : t('pages.settings.security.mfa.badge.disabled')}
      </Badge>
      <Text display="block" marginTop="m" type="body2">
        {t('pages.settings.security.mfa.description')}
      </Text>
      <Box display="flex" alignItems="center" marginBottom="l" marginTop="xl">
        <Button
          onClick={handleUIStateChange}
          intent={hasMFAenabled ? 'destructive' : 'primary'}
          strong
          pill
        >
          {hasMFAenabled
            ? t('pages.settings.security.mfa.toggle.disabled')
            : t('pages.settings.security.mfa.toggle.enabled')}
        </Button>
      </Box>
      {message && message.source === 'MFA' && (
        <Box marginBottom="l">
          <FormHint intent={formHintIntentFromString(message.intent)}>
            {t(message.key)}
          </FormHint>
        </Box>
      )}
      {formAppearance !== FormAppearance.Initial && (
        <Box marginTop="l">
          <Card padding="xl">
            {formAppearance === FormAppearance.Enable && (
              <Box paddingBottom="xl">
                <ThemeContainer name="print">
                  <Card>
                    <Box padding="m" display="flex" justifyContent="center">
                      {otpAuthUrl ? (
                        <QRCode value={otpAuthUrl} className={styles.QRImage} />
                      ) : (
                        <Box
                          className={styles.QRPlaceholder}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <LoadingIcon />
                        </Box>
                      )}
                    </Box>
                  </Card>
                </ThemeContainer>
                <Box marginTop="m">
                  <Text type="body2">
                    {t('pages.settings.security.mfa.setup.instructions')}
                  </Text>
                </Box>
              </Box>
            )}
            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={formProps}
                validationSchema={SecuritySettingsSchema}
              >
                {formProps => (
                  <Form>
                    <Stack spacing="l">
                      <Box>
                        {formAppearance === FormAppearance.Disable && (
                          <>
                            <Text
                              marginBottom="m"
                              type="display3"
                              display="block"
                            >
                              {t(
                                'pages.settings.security.mfa.verification_code.disable.heading'
                              )}
                            </Text>
                            <Text
                              marginBottom="xl"
                              display="block"
                              type="body2"
                            >
                              {t(
                                'pages.settings.security.mfa.verification_code.caption'
                              )}
                            </Text>
                          </>
                        )}
                        <Label htmlFor="verificationCode" marginBottom="xs">
                          {t(
                            'pages.settings.security.mfa.verification_code.label'
                          )}
                        </Label>
                        <FormInput
                          error={formProps.errors.verificationCode}
                          id="verificationCode"
                          name="verificationCode"
                          placeholder={t(
                            'pages.settings.security.mfa.verification_code.placeholder'
                          )}
                          fluid
                          size="large"
                          touched={formProps.touched.verificationCode}
                          type={FormInputTypes.Text}
                        />
                        <ErrorMessage
                          name="verificationCode"
                          render={msg => (
                            <FormFieldHint intent="error" marginTop="xs">
                              {msg}
                            </FormFieldHint>
                          )}
                        />
                      </Box>
                      <Button
                        type="submit"
                        intent={hasMFAenabled ? 'destructive' : 'primary'}
                        strong
                        size="large"
                        fluid
                        loading={formLoading}
                      >
                        {hasMFAenabled
                          ? t(
                              'pages.settings.security.mfa.verification_code.action.disable'
                            )
                          : t(
                              'pages.settings.security.mfa.verification_code.action.enable'
                            )}
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
            {formAppearance === FormAppearance.Enable && otpAuthUrl && (
              <>
                <Divider marginY="xl" />
                <Text type="caption" muted>
                  {t('pages.settings.security.mfa.setup.instructions.help')}
                </Text>
              </>
            )}
          </Card>
        </Box>
      )}
      <Box display="flex" marginTop="xl">
        <Icon name="help-circle" color="var(--ds-text-color-muted)" />
        <Box paddingLeft="s">
          <Text type="caption" muted>
            {t('pages.settings.security.mfa.byline.support')}
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default Security;

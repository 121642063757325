import React from 'react';
import isEqual from 'lodash/isEqual';
import { Box } from '@webMolecules/Box/Box';
import { Badge } from '@webMolecules/Badge/Badge';
import { Icon } from '@webMolecules/Icon/Icon';
import { LineItem, LineItemProps } from '@webMolecules/LineItem/LineItem';
import {
  TextDisplay,
  TextMuted,
  TextMutedWithIcon,
} from '@webMolecules/LineItem/Cell/Text';
import { Users } from '@webMolecules/LineItem/Cell/Users';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { isAnonymousName } from '@helpers/string';
import { t, tDate } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import { PatientSensitive } from '@webMolecules/PatientSensitive/PatientSensitive';
import { openToast } from '@webMolecules/Toast/Toast';
import { ToastBookmarked } from '@webViews/Toasts/ToastBookmarked';
import { useAuthenticationMode } from '@interfaces/Authentication';
import { FilterListLineItem } from '@webOrganisms/FilterList/FilterList';
import WorkSpaceFilterModal from '@webOrganisms/WorkSpaceFilterModal/WorkSpaceFilterModal';
import { Workspace } from '@interfaces/Workspace';
import { unique } from '@helpers/array';
import {
  eventStudyActionCompareStudy,
  eventStudyActionResetStudy,
} from '@webInterfaces/Analytics';
import { useDispatcher } from '@helpers/useDispatcher';
import { sessionUseCase } from '@useCases/Session';
import {
  DeleteSessionDialog,
  EditCompletedStudyDialog,
  ResetSessionDialog,
} from '@webViews/Pages/Study/SessionDialogs';
import StudyComparisonModal from '@webOrganisms/StudyComparisonModal/StudyComparisonModal';
import { getFeatureFlags } from '@entities/FeatureFlag';
import { worklistUseCase } from '@useCases/Worklist';

export interface WorklistLineItemData {
  patientName: string;
  status: SessionStatus;
  accessionNumber: string;
  date: null | Date;
  sessionId: string;
  analysisId: string;
  pdfUrl?: string;
  userId: string;
  userProfiles: { initial: string; name: string; color: string }[];
  animated: boolean;
  studyTypeDescription: string;
  hasDecryptedPhi: boolean;
  isBookmarked: boolean;
  isBookmarkEnabled: boolean;
  assignedWorkspaces: string[];
}

export interface WorklistLineItemProps extends WorklistLineItemData {
  onSetAsNew: (analysisId: string, sessionId: string) => void;
  onOpenUnclaimed: (
    analysisId: string,
    sessionId: string,
    accessionNumber: string
  ) => void;
  downloadPdf: (url: string, fileName: string) => void;
  setBookmarked: (sessionId: string, isBookmarked: boolean) => void;
  isCustomWorkspacesEnabled: boolean;
  customWorkspaces: Workspace[];
  updateSessionAssignedWorkspaces: (
    sessionId: string,
    workspaces: string[]
  ) => void;
}

const WorklistLineItem = ({
  patientName,
  status,
  accessionNumber,
  date,
  sessionId,
  analysisId,
  pdfUrl,
  userId,
  userProfiles,
  animated,
  isBookmarked,
  onOpenUnclaimed,
  downloadPdf,
  studyTypeDescription,
  hasDecryptedPhi,
  setBookmarked,
  isBookmarkEnabled,
  isCustomWorkspacesEnabled,
  assignedWorkspaces,
  customWorkspaces,
  updateSessionAssignedWorkspaces,
}: WorklistLineItemProps): React.ReactElement<LineItemProps> => {
  const dispatchSetSessionStatus = useDispatcher(
    worklistUseCase.SetSessionLineItemStatus
  );
  const dispatchOpenCompletedSession = useDispatcher(
    worklistUseCase.OpenCompletedSession
  );
  const dispatchResetSession = useDispatcher(worklistUseCase.ResetSession);
  const dispatchUpdateComparisonSession = useDispatcher(
    sessionUseCase.UpdateComparisonSession
  );

  const [openDialog, setOpenDialog] = React.useState<
    'edit' | 'reset' | 'delete' | 'compare' | 'workspaces' | null
  >(null);
  const closeDialog = () => setOpenDialog(null);

  const [sessionWorkspaces, setSessionWorkspaces] =
    React.useState(assignedWorkspaces);

  const patientNameContent = isAnonymousName(patientName) ? (
    <TextMutedWithIcon
      icon={<Icon name="user-off" color="var(--ds-text-color-muted)" />}
    >
      <Box display="flex" gap="s" data-testid="test-patient-name">
        {t('pages.worklist.worklist_item.no_patient_name')}
        {isBookmarkEnabled && isBookmarked ? (
          <Box data-testid="bookmark-icon">
            <Icon name="bookmark-plus" color="var(--ds-text-color-muted)" />
          </Box>
        ) : null}
      </Box>
    </TextMutedWithIcon>
  ) : (
    <PatientSensitive>
      <TextDisplay>{patientName}</TextDisplay>
      {isBookmarked ? (
        <Box data-testid="bookmark-icon">
          <Icon name="bookmark-plus" color="var(--ds-text-color-muted)" />
        </Box>
      ) : null}
    </PatientSensitive>
  );

  const colPatientName = hasDecryptedPhi ? (
    patientNameContent
  ) : (
    <TextDisplay loading>Loading</TextDisplay>
  );

  const colDate = <TextMuted>{date ? tDate(date) : ''}</TextMuted>;

  const colAccessionNumber = (
    <TextMuted>{accessionNumber ? accessionNumber : ''}</TextMuted>
  );

  const colStudyLocation = <TextMuted>{studyTypeDescription}</TextMuted>;

  const users =
    userProfiles.length > 0
      ? userProfiles
      : [
          {
            initial: '?',
            name: t('pages.worklist.worklist_item.user.unassigned'),
            color: 'var(--ds-background-color-alt-200)',
          },
        ];
  const colUser = <Users users={users} />;

  const colStatus = (
    <Box marginLeft="auto">
      {(() => {
        switch (status) {
          case SessionStatus.New:
            return (
              <Badge intent="information">
                {t('types.analysis.status.new')}
              </Badge>
            );
          case SessionStatus.Completed:
            return (
              <Badge intent="success">
                {t('types.analysis.status.completed')}
              </Badge>
            );
          case SessionStatus.InProgress:
          case SessionStatus.Archived:
            return null;
        }
      })()}
    </Box>
  );

  let lineItemAction: Pick<LineItemProps, 'href' | 'onClick'> = {};

  const { isAdminDomain } = useAuthenticationMode();
  if (status === SessionStatus.Completed && pdfUrl) {
    const fileName = accessionNumber ? `${accessionNumber}.pdf` : 'report.pdf';
    lineItemAction = { onClick: () => downloadPdf(pdfUrl, fileName) };
  } else if (!userId && !isAdminDomain) {
    lineItemAction = {
      onClick: () =>
        onOpenUnclaimed(analysisId, sessionId, accessionNumber || ''),
    };
  } else if ((userId && sessionId) || isAdminDomain) {
    lineItemAction = { href: RouteHelper.study(analysisId, sessionId) };
  }

  const contextMenu = [];
  if (userId && sessionId && status === SessionStatus.Completed) {
    contextMenu.push({
      label: t('pages.worklists.analysis.dropdown.edit_study'),
      icon: <Icon name="edit-pen" />,
      onClick: () => setOpenDialog('edit'),
    });
  }

  if ([SessionStatus.New, SessionStatus.InProgress].includes(status)) {
    contextMenu.push({
      label: t('generic.dropdown.delete_study'),
      icon: <Icon name="trash" />,
      onClick: () => setOpenDialog('delete'),
    });
  }
  if (isBookmarkEnabled && userId && sessionId) {
    contextMenu.push({
      label: isBookmarked
        ? t('generic.dropdown.remove_bookmarked')
        : t('generic.dropdown.bookmarked'),
      icon: (
        <Icon
          name={isBookmarked ? 'bookmark-minus' : 'bookmark-plus'}
          color="var(--ds-text-color-muted)"
        />
      ),
      onClick: () => {
        openToast(<ToastBookmarked isBookmarked={!isBookmarked} />, {
          autoClose: 500,
          closeButton: true,
        });
        setBookmarked(sessionId, !isBookmarked);
      },
    });
  }

  if ([SessionStatus.New, SessionStatus.InProgress].includes(status)) {
    contextMenu.push({
      label: t('generic.dropdown.reset_study'),
      icon: <Icon name="rotate-ccw" />,
      onClick: () => setOpenDialog('reset'),
    });

    if (getFeatureFlags().comparisonStudiesManual) {
      contextMenu.push({
        label: t('generic.dropdown.compare_study'),
        icon: <Icon name="table" />,
        onClick: () => setOpenDialog('compare'),
      });
    }
  }

  if (isCustomWorkspacesEnabled && sessionId) {
    contextMenu.push({
      label: t('generic.dropdown.add_to_workspaces'),
      icon: <Icon name="folder-input" color="var(--ds-text-color-muted)" />,
      onClick: () => setOpenDialog('workspaces'),
    });
  }

  const workspaceItems = customWorkspaces.map(workspace => {
    return {
      id: workspace.id,
      title: `${workspace.name}`,
      matchString: `${workspace.name}`,
      added: sessionWorkspaces.includes(workspace.id),
    } as FilterListLineItem;
  });

  const onConfirmReset = () => {
    eventStudyActionResetStudy();
    dispatchResetSession({ sessionId });
    closeDialog();
  };

  const onConfirmCompare = (comparisonSessionId: string) => {
    eventStudyActionCompareStudy();
    dispatchUpdateComparisonSession({
      comparisonSessionId,
      currentSessionId: sessionId,
    });
    closeDialog();
  };

  return (
    <>
      <WorkSpaceFilterModal
        isOpen={openDialog === 'workspaces'}
        subtitle="Search for workspaces and add the session to custom workspaces"
        items={workspaceItems}
        closeModal={closeDialog}
        onAdd={itemId => {
          const mutatedWorkspaces = unique([...sessionWorkspaces, itemId]);
          setSessionWorkspaces(mutatedWorkspaces);
          updateSessionAssignedWorkspaces(sessionId, mutatedWorkspaces);
        }}
        onRemove={itemId => {
          const mutatedWorkspaces = [...sessionWorkspaces].filter(
            workspace => workspace !== itemId
          );

          setSessionWorkspaces(mutatedWorkspaces);
          updateSessionAssignedWorkspaces(sessionId, mutatedWorkspaces);
        }}
      />

      <EditCompletedStudyDialog
        isOpen={openDialog === 'edit'}
        onCancel={closeDialog}
        onConfirm={() =>
          dispatchOpenCompletedSession({ analysisId, sessionId })
        }
      />

      <ResetSessionDialog
        isOpen={openDialog === 'reset'}
        onConfirm={onConfirmReset}
        onCancel={closeDialog}
      />

      <DeleteSessionDialog
        isOpen={openDialog === 'delete'}
        onConfirm={() => {
          dispatchSetSessionStatus({
            analysisId,
            sessionId,
            sessionStatus: SessionStatus.Archived,
          });
          closeDialog();
        }}
        onCancel={closeDialog}
      />

      <StudyComparisonModal
        isOpen={openDialog === 'compare'}
        onCompare={onConfirmCompare}
        onClose={closeDialog}
      />

      <LineItem
        key={sessionId}
        {...lineItemAction}
        contextMenu={contextMenu}
        data-testid={`worklist-item:${sessionId}`}
        animated={animated}
      >
        {colPatientName}
        {colDate}
        {colAccessionNumber}
        {colStudyLocation}
        {colUser}
        {colStatus}
      </LineItem>
    </>
  );
};

export const MemoizedWorklistLineItem = React.memo(WorklistLineItem, isEqual);

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import { ArrowIcon, ArrowIconProps } from '@webMolecules/ArrowIcon/ArrowIcon';
import { labelColor } from '@webOrganisms/ResultTable/FindingsTable/Shared';
import styles from './changeLabel.scss';

export interface ChangeLabelProps extends ArrowIconProps {
  label: string;
  unit?: string;
}

export const ChangeLabel: React.FC<ChangeLabelProps> = ({
  previousValue,
  currentValue,
  type = 'absolute',
  label = '',
  unit = '',
  previousDisplayValue,
  currentDisplayValue,
}) => {
  let defaultDifferenceValue = undefined;

  if (currentValue != undefined && previousValue != undefined) {
    defaultDifferenceValue = currentValue - previousValue;
  }
  const backgroundStyle = labelColor(Number(defaultDifferenceValue));
  const classNames = cnames(
    styles.labelContainer,
    styles[`${backgroundStyle}-background`]
  );
  const textClassName = cnames(
    styles.unit,
    styles[`${backgroundStyle}-text-color`]
  );

  return (
    <Box
      className={classNames}
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        padding="xs"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        <Text type="body2" muted marginX="xxs">
          {label}
        </Text>
        {(previousValue || currentValue) && (
          <ArrowIcon
            previousValue={previousValue}
            currentValue={currentValue}
            type={type}
            previousDisplayValue={previousDisplayValue}
            currentDisplayValue={currentDisplayValue}
          />
        )}
      </Box>

      {!Number.isNaN(defaultDifferenceValue) && unit && (
        <Box className={textClassName} padding="xs" alignItems="center">
          <Text type="body2">{unit}</Text>
        </Box>
      )}
    </Box>
  );
};

export const convertCmToMm = (cmValue: number): number => {
  return cmValue * 10;
};

export const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length: length }, (_, i) => start + i);
};

export const calculatePercentageDifference = (
  previous: number,
  current: number
) => {
  const difference = ((current - previous) / previous) * 100;
  return +difference.toFixed(0);
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { t } from '@webInterfaces/I18n';
export interface ToastDownloadProp {
  isDownloading: boolean;
}

export const ToastDownload: React.FC<ToastDownloadProp> = ({
  isDownloading,
}) => {
  return (
    <Box display="flex" gap="m">
      <Box marginTop="xxxs">
        {isDownloading ? (
          <LoadingIcon />
        ) : (
          <Icon name="check-circle" color="var(--ds-color-success-500)" />
        )}
      </Box>
      <Box
        data-testid="test-download-study"
        display="flex"
        flexDirection="column"
        gap="xxs"
      >
        <Text type="display3">
          {isDownloading
            ? t('toast.pdf.generating.title')
            : t('toast.pdf.success.title')}
        </Text>
        <Text>
          {isDownloading
            ? t('toast.pdf.generating.body')
            : t('toast.pdf.success.body')}
        </Text>
      </Box>
    </Box>
  );
};

import { App } from '@entities/App';
import { Session } from '@entities/Session';
import { AnalysisUploads } from '@entities/AnalysisUploads';
import { AppSettings } from '@entities/AppSettings';
import { Notifications } from '@entities/Notifications';
import { Search } from '@entities/Search';
import { Admin } from '@entities/Admin';
import { Worklist } from '@entities/Worklist';
import { PACS } from '@entities/PACS';
import { Profile } from '@entities/Profile';

export const toAdmin = (app: App): Admin => app.admin;

export const toWorklist = (app: App): Worklist => app.worklist;

export const toPACS = (app: App): PACS => app.pacs;

export const toSession = (app: App): Session => app.session;

export const toAnalysisUploads = (app: App): AnalysisUploads =>
  app.analysisUploads;

export const toAppSettings = (app: App): AppSettings => app.appSettings;

export const toNotifications = (app: App): Notifications => app.notifications;

export const toSearch = (app: App): Search => app.search;

export const toProfile = (app: App): Profile => app.profile;

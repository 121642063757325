import { Storage as AWSStorage } from 'aws-amplify';
import { saveAs } from '@progress/kendo-file-saver';
import { Storage as ContractStorage } from '@contracts/Storage';
import Queue from '@library/Queue';
import Result from '@monads/Result';
import { sleep } from '@helpers/async';

interface BucketConfig {
  [key: string]: string | number | true;
}
export class Storage implements ContractStorage {
  private _queue: Queue = new Queue();

  async GetUrl(key: string, expires?: number): Promise<string> {
    const config: BucketConfig = {};
    if (expires) {
      config['expires'] = expires;
    }

    return (await AWSStorage.get(key, config)) as string;
  }

  async PollUrl(key: string): Promise<string> {
    const INTERVAL = 1000;
    const RETRIES = 60;

    let count = 0;
    do {
      try {
        const url = await AWSStorage.get(key, {
          validateObjectExistence: true,
        });
        return url;
      } catch (e) {
        await sleep(INTERVAL);
        count++;
      }
    } while (count <= RETRIES);

    throw new Error('Asset does not exist');
  }

  async GetJson(key: string): Promise<object> {
    const config: BucketConfig = {
      download: true,
      cacheControl: 'max-age=120',
    };
    const r = (await AWSStorage.get(key, config)) as unknown as {
      Body: Blob;
    };
    const text = await r.Body.text();
    return JSON.parse(text);
  }

  async GetEncodedImage(key: string): Promise<string> {
    const config: BucketConfig = {
      download: true,
      cacheControl: 'max-age=120',
    };
    const r = (await AWSStorage.get(key, config)) as unknown as {
      Body: Blob;
    };
    const reader = new FileReader();
    reader.readAsDataURL(r.Body);
    return new Promise(resolve => {
      const timeout = setTimeout(() => resolve(''), 1000);
      reader.onloadend = () => {
        clearTimeout(timeout);
        resolve(reader.result as string);
      };
    });
  }

  Store({
    location,
    body,
  }: {
    location: string;
    body: string | Buffer | Blob;
    contentType: string;
  }): Promise<'success'> {
    return new Promise((resolve, reject) => {
      this._queue.add(
        (): Promise<'success'> =>
          AWSStorage.put(location, body).then(() => 'success'),
        (result: Result<'success'>) => {
          if (result.isError()) {
            reject(result.lift());
          } else {
            resolve('success');
          }
        }
      );
    });
  }

  SaveAs(filename: string, data: string | Blob): void {
    saveAs(data, filename);
  }
}

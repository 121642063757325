import React from 'react';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { cnames } from '@helpers/cnames';
import { Field } from '../Field/Field';
import { Skeleton } from '../Skeleton/Skeleton';
import styles from './input.scss';

export type AutoComplete =
  | false
  | 'given-name'
  | 'family-name'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'one-time-code';

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'autoComplete' | 'size'
  > {
  autoComplete?: AutoComplete;
  field?: string;
  fluid?: boolean;
  form?: string;
  iconAfter?: JSX.Element;
  iconBefore?: JSX.Element;
  intent?: 'success' | 'neutral' | 'error' | 'required' | 'warning' | 'primary';
  intentIcon?: React.FC;
  pill?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  skeleton?: boolean;
  width?: string;
  strong?: boolean;
  ref?: React.Ref<HTMLInputElement>;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const inputClassNames = cnames(styles.input, {
      [styles[props.intent as string]]: Boolean(props.intent),
      [styles[props.size || 'medium']]: true,
      [styles.disabled]: props.disabled,
      [styles.readOnly]: props.readOnly,
    });

    const fieldPropsToPick = [
      'disabled',
      'fluid',
      'readOnly',
      'size',
      'width',
      'pill',
      'strong',
      ...(props.skeleton
        ? []
        : ['iconAfter', 'iconBefore', 'intent', 'intentIcon']),
    ];

    const fieldProps = pick(props, ...fieldPropsToPick);
    const filteredProps = omit(
      props,
      'autoComplete',
      'field',
      'fluid',
      'form',
      'iconAfter',
      'iconBefore',
      'intent',
      'strong',
      'intentIcon',
      'pill',
      'size',
      'skeleton'
    );

    const inputProps: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > = { ...filteredProps, ref };

    return (
      <Field {...fieldProps}>
        {props.skeleton ? (
          <Skeleton size={props.size} />
        ) : (
          <input
            className={inputClassNames}
            autoComplete={!props.autoComplete ? 'off' : props.autoComplete}
            {...inputProps}
          />
        )}
      </Field>
    );
  }
);

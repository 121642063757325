import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useDispatcher } from '@helpers/useDispatcher';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { t, tDate } from '@webInterfaces/I18n';
import { FindingSummaryRow } from '@webOrganisms/ResultTable/FindingsTable/FindingSummaryRow';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import { userColourPaletteLight } from '@appCanvas/interfaces/types/ColourPalette.type';
import * as ViewModel from '@webViewModels/Pages/Study/OffScreenWorksheet';
import { Card } from '@webMolecules/Card/Card';
import { ComparisonDiagramSection } from '@webOrganisms/ResultTable/Comparison/ComparisonDiagram';
import { selectFindingTableConfig } from '@selectors/SessionSelectors';
import { selectIncludedFindings } from '@selectors/FindingSelectors';
import SummaryObservation from '@webOrganisms/SummaryObservation/SummaryObservation';
import { MetadataDestination } from '@webOrganisms/WorkSheetTable/Row/CommentSection';
import { TextArea } from '@webMolecules/TextArea/TextArea';
import { sessionUseCase } from '@useCases/Session';
import ReportDiagramWrapper from '../Diagram/ReportDiagramWrapper';
import styles from './offScreenWorkSheetPDFGenerator.scss';

const SmallPartsStudyWorkSheetLayout: React.FC<
  ViewModel.OffScreenWorksheet
> = ({
  metadata,
  accessionNum,
  me,
  studyTypeI18NKey,
  isComparisonAvailable,
  sessionDate,
  comparisonSessionDate,
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const indicationsRef = React.useRef<HTMLDivElement>(null);
  const commentRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLDivElement>(null);
  const findingRef = React.useRef<HTMLDivElement>(null);

  const findingTableConfig = useSelector(selectFindingTableConfig);
  const findings = useSelector(selectIncludedFindings);
  const dispatchUpdateMetadata = useDispatcher(sessionUseCase.UpdateMetadata);

  const onChange = React.useCallback(
    (value: string, dest: MetadataDestination) => {
      const {
        decryptedComments: comments,
        decryptedClinicalFindings: findings,
        decryptedOtherHistory: history,
      } = metadata;

      dispatchUpdateMetadata({
        metadata: {
          ...metadata,
          decryptedComments: dest === 'comment' ? value : comments,
          decryptedClinicalFindings: dest === 'findings' ? value : findings,
          decryptedOtherHistory: dest === 'history' ? value : history,
        },
      });
    },
    [dispatchUpdateMetadata, metadata]
  );

  const observation = <SummaryObservation />;

  const canvas =
    findingTableConfig && findingTableConfig.showCanvas ? (
      isComparisonAvailable ? (
        <ComparisonDiagramSection
          previousDate={comparisonSessionDate}
          currentDate={sessionDate}
          fullPageWidth={false}
          onlyShowMatchedLesion
          lightTheme
        />
      ) : (
        <Box ref={canvasRef}>
          <Card padding="none">
            <Box className={styles.canvasContainer}>
              <ReportDiagramWrapper
                canvasColorPalette={userColourPaletteLight}
                showCanvasOnly={true}
              />
            </Box>
          </Card>
        </Box>
      )
    ) : null;

  const findingsElements = findings.map(finding => {
    return (
      <Box data-pdf-keep-together key={`finding-${finding.id}`}>
        <FindingSummaryRow
          finding={finding}
          theme={ResultTableStyle.preview}
          index={finding.index}
        />
      </Box>
    );
  });

  const findingSummary = (
    <Box ref={findingRef} display="flex" flexDirection="column" gap="m">
      {findingsElements}
    </Box>
  );

  const todayStr = tDate(new Date());

  return (
    <Box className={styles.pdfContainer} display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        paddingX="xl"
        paddingY="l"
      >
        <Box ref={headerRef} marginBottom="m">
          <Text display="block" type="display2" marginBottom="xs">
            {t(studyTypeI18NKey, '')} {t('pages.study.worksheet.title')}
          </Text>
          <Box display="flex" justifyContent="space-between">
            <Text display="inline" marginBottom="s" type="body">
              By {me.firstName} {me.lastName}
            </Text>
          </Box>
        </Box>
        <Box className={styles.cornerInfo} padding="l">
          <Text display="block" type="body">
            {todayStr}
          </Text>
          <Text display="block" type="display2">
            #{accessionNum}
          </Text>
        </Box>

        <Box display="flex" gapColumn="xs">
          <Box ref={indicationsRef} flex="1">
            <Text display="block" type="display5" marginBottom="xxxs">
              {t('pages.study.worksheet.heading.clinical_findings')}
            </Text>

            <Box data-patient-sensitive className={styles.textAreaParent}>
              <TextArea
                proxiedKey="worksheet-clinical-findings"
                fluid
                autoSize
                rows={2}
                defaultValue={metadata.decryptedClinicalFindings}
                onChange={e => onChange(e.target.value, 'findings')}
              />
            </Box>
          </Box>
          <Box ref={commentRef} flex="1">
            <Text display="block" type="display5" marginBottom="xxxs">
              {t('pages.study.worksheet.label.comments')}
            </Text>

            <Box data-patient-sensitive className={styles.textAreaParent}>
              <TextArea
                proxiedKey="worksheet-comments"
                fluid
                autoSize
                rows={2}
                defaultValue={metadata.decryptedComments}
                onChange={e => onChange(e.target.value, 'comment')}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        paddingX="xl"
        paddingY="m"
        gap="l"
        className={styles.reportSection}
      >
        {observation}
        {canvas}
        {findingSummary}
      </Box>
    </Box>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(SmallPartsStudyWorkSheetLayout);

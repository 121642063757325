import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Finding } from '@entities/Finding';
import styles from './comparison.scss';
import { FindingNoduleSizeHeaderValues } from './Utility/FindingNoduleSizeHeaderValues';
import { FindingNoduleSizeDifferenceValues } from './Utility/FindingNoduleSizeDifferenceValues';

export interface FindingSizeGridProps {
  finding: Finding;
  showDifferences?: boolean;
}

export const FindingSizeGrid = ({
  finding,
  showDifferences,
}: FindingSizeGridProps) => {
  return (
    <Box
      className={
        finding.formatted.isSize3D
          ? styles.findingSizeDisplay
          : styles.findingDimensionDisplay
      }
    >
      <FindingNoduleSizeHeaderValues finding={finding} />
      {showDifferences && finding.comparedFinding && (
        <FindingNoduleSizeDifferenceValues
          finding={finding}
          comparedFinding={finding.comparedFinding}
        />
      )}
    </Box>
  );
};

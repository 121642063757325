import React from 'react';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import { LineItem } from '@webMolecules/LineItem/LineItem';
import { Input as CellInput } from '@webMolecules/LineItem/Cell/Input';
import { Input } from '@webMolecules/Input/Input';
import { Select as CellSelect } from '@webMolecules/LineItem/Cell/Select';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import { Modal } from '@webMolecules/Modal/Modal';
import { Divider } from '@webMolecules/Divider/Divider';
import * as I from '@interfaces/Profile';
import { t, tPrint } from '@webInterfaces/I18n';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Profile } from '@entities/Profile';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import styles from './workspaceDetails.scss';

export interface UserDetailsProps {
  profile: Profile;
  userLineItem: I.Profile;
  emailsInUse: string[];
  onClose: () => void;
  onCreateUser: (userLineItem: I.Profile) => void;
  onUpdateUser: (userLineItem: I.Profile) => void;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  profile,
  userLineItem,
  emailsInUse,
  onClose,
  onCreateUser,
  onUpdateUser,
}) => {
  const setTmpId = (
    p: I.OperatorMappingProperty
  ): I.OperatorMappingProperty => ({
    ...p,
    _tmpId: p._tmpId || uuid(),
    childProperties: p.childProperties
      ? p.childProperties.map(setTmpId)
      : p.childProperties,
  });

  const [state, setState] = React.useState<I.Profile>({
    ...userLineItem,
    operatorMappingProperties: (
      userLineItem?.operatorMappingProperties || []
    ).map(setTmpId),
  });

  const yupEmailSchema = Yup.string()
    .email('generic.validation.invalid.email')
    .required('generic.validation.required.email')
    .test(
      'Unique',
      'generic.validation.required.email_exists',
      a => emailsInUse.indexOf(a || '') === -1
    );

  const [emailIsValid, setEmailIsValid] = React.useState<string | true>(true);
  if (!state.id) {
    React.useEffect(() => {
      (async () => {
        const isValid: string | true = await yupEmailSchema
          .validate(state.email)
          .then(() => true)
          .catch(({ errors }) => errors[0]);
        setEmailIsValid(isValid);
      })();
    }, [state.email]);
  }

  const updateUser = (userLineItem: I.Profile) => {
    setState(userLineItem);
  };

  const addProperty = (
    index: number | false = false,
    childIndex: number | false = false
  ) => {
    const newProperty: I.OperatorMappingProperty = {
      _tmpId: uuid(),
      column: 'operator_code',
      value: '',
    };

    setState({
      ...state,
      operatorMappingProperties:
        index !== false
          ? state.operatorMappingProperties.map((p, i) => ({
              ...p,
              childProperties:
                index === i
                  ? childIndex !== false
                    ? (p.childProperties || []).reduce(
                        (acc, p, ci) =>
                          ci === childIndex
                            ? [...acc, p, newProperty]
                            : [...acc, p],
                        [] as I.OperatorMappingProperty[]
                      )
                    : [newProperty, ...(p.childProperties || [])]
                  : p.childProperties,
            }))
          : [...state.operatorMappingProperties, newProperty],
    });
  };

  const removeProperty = (
    index: number,
    childIndex: number | false = false
  ) => {
    setState({
      ...state,
      operatorMappingProperties:
        childIndex !== false
          ? state.operatorMappingProperties.map((p, i) => ({
              ...p,
              childProperties:
                p.childProperties && i === index
                  ? p.childProperties.filter((_, ci) => ci !== childIndex)
                  : p.childProperties,
            }))
          : state.operatorMappingProperties.filter((_, i) => i !== index),
    });
  };

  const updateProperty = (
    index: number,
    childIndex: number | false = false,
    property: I.OperatorMappingProperty
  ) => {
    setState({
      ...state,
      operatorMappingProperties:
        childIndex !== false
          ? state.operatorMappingProperties.map((p, i) => ({
              ...p,
              childProperties:
                p.childProperties && i === index
                  ? p.childProperties.map((cp, ci) =>
                      ci === childIndex ? property : cp
                    )
                  : p.childProperties,
            }))
          : state.operatorMappingProperties.map((p, i) =>
              i === index ? property : p
            ),
    });
  };

  const renderPropertyColumn = (
    property: I.OperatorMappingProperty,
    index: number,
    childIndex: number | false = false
  ) => (
    <CellSelect
      label={childIndex !== false ? 'And' : undefined}
      fluid
      data-testid="admin-users-userdetails-property"
      value={property.column}
      controlled={false}
      onChange={value => {
        updateProperty(index, childIndex, {
          ...property,
          column: value.toString(),
        });
      }}
      options={[
        {
          label: t('pages.settings.admin.users.rules.option.operator_code'),
          value: 'operator_code',
        },
        {
          label: t('pages.settings.admin.users.rules.option.institution_name'),
          value: 'institution_name',
        },
      ]}
    />
  );

  const renderPropertyValue = (
    property: I.OperatorMappingProperty,
    index: number,
    childIndex: number | false = false
  ) => (
    <CellInput
      data-testid="admin-users-userdetails-value"
      fluid
      defaultValue={property.value}
      onChange={event => {
        updateProperty(index, childIndex, {
          ...property,
          value: event.currentTarget.value,
        });
      }}
    />
  );

  const renderPropertyActions = (
    index: number,
    childIndex: number | false = false
  ) => (
    <Box display="flex" alignItems="center">
      <Button
        circle
        size="small"
        data-testid="admin-users-userdetails-remove-rule"
        onClick={() => removeProperty(index, childIndex)}
      >
        <Icon size="small" name="minus" />
      </Button>
      <Button
        circle
        size="small"
        onClick={() => addProperty(index, childIndex)}
      >
        <Icon size="small" name="plus" />
      </Button>
    </Box>
  );

  const humaniseOperatorMappingProperties = (
    operatorMappingProperty: I.OperatorMappingProperty[]
  ) => {
    const orRules = operatorMappingProperty.map(a =>
      (a.childProperties || []).reduce(
        (str, p) =>
          `${str} ${tPrint('pages.settings.admin.users.rules.human.and', {
            '%column': t(`pages.settings.admin.users.rules.option.${p.column}`),
            '%value': p.value,
          })}`,
        tPrint('pages.settings.admin.users.rules.human.root', {
          '%column': t(`pages.settings.admin.users.rules.option.${a.column}`),
          '%value': a.value,
        })
      )
    );

    return (
      <Box marginY="m">
        {orRules.map((a, i) => (
          <Text display="block" muted type="caption" key={i}>
            {i > 0 && t('pages.settings.admin.users.rules.human.or')}
            {a}
          </Text>
        ))}
      </Box>
    );
  };

  return (
    <Modal isOpen={true} size="medium" onClose={onClose}>
      <Card elevation="medium" padding="none">
        <Box
          className={styles.modalBody}
          padding="xxl"
          data-testid="admin-users-userdetails"
        >
          <Box className={styles.modalClose} padding="xl">
            <Button
              data-testid="admin-users-userdetails-close"
              iconBefore={<Icon name="x" />}
              circle
              intent="neutral"
              size="small"
              onClick={onClose}
            />
          </Box>

          <Stack spacing="m">
            <Text type="display1" display="block" marginBottom="m">
              {state.id
                ? t('pages.settings.admin.users.title.edit')
                : t('pages.settings.admin.users.title.add')}
            </Text>

            <Box flex="5" marginRight="l">
              <Text type="body2" display="block" marginBottom="xs">
                {t('pages.settings.admin.users.email')}
              </Text>
              <Input
                id="email"
                name="email"
                data-testid="admin-users-userdetails-email"
                type="email"
                defaultValue={userLineItem.email}
                fluid
                placeholder="user@email.com"
                readOnly={!!state.id}
                onChange={event =>
                  updateUser({ ...state, email: event.target.value })
                }
              />
              {!state.id && state.email.length > 0 && emailIsValid !== true ? (
                <FormFieldHint intent="error" marginTop="xs" marginBottom="xs">
                  {t(emailIsValid)}
                </FormFieldHint>
              ) : (
                <Box marginTop="xs"></Box>
              )}
            </Box>

            <Box marginBottom="xs" marginRight="l">
              <Text type="display6" display="block" marginBottom="xs">
                {t('pages.settings.admin.users.permission')}
              </Text>
              <Box data-testid="admin-users-userdetails-permission">
                <MenuSelect
                  disabled={profile.email === state.email}
                  options={[
                    {
                      label: t(
                        'pages.settings.admin.users.permission.label.user'
                      ),
                      value: I.userPermissionToString(false),
                    },
                    {
                      label: t(
                        'pages.settings.admin.users.permission.label.admin'
                      ),
                      value: I.userPermissionToString(true),
                    },
                  ]}
                  value={I.userPermissionToString(state.isAdmin)}
                  controlled={false}
                  onChange={value =>
                    updateUser({
                      ...state,
                      isAdmin: I.stringToUserPermission(value),
                    })
                  }
                />
              </Box>
            </Box>

            <Box marginBottom="xs">
              <Text type="display3" display="block" marginRight="m">
                {t('pages.settings.admin.users.rules.title')}
              </Text>
            </Box>

            <Box data-testid="admin-users-userdetails-studymapping">
              <Schedule
                columns={[
                  {
                    label: t(
                      'pages.settings.admin.users.rules.list.heading.column'
                    ),
                  },
                  {
                    label: t(
                      'pages.settings.admin.users.rules.list.heading.value'
                    ),
                  },
                  { label: '', width: '80px' },
                ]}
                items={state.operatorMappingProperties.map(
                  (operatorMappingProperties, index) => (
                    <Box key={operatorMappingProperties._tmpId}>
                      <LineItem
                        data-testid="admin-users-userdetails-line-item"
                        condensed
                        className={styles.lineItemRule}
                      >
                        {renderPropertyColumn(operatorMappingProperties, index)}

                        {renderPropertyValue(operatorMappingProperties, index)}
                        {renderPropertyActions(index)}
                      </LineItem>
                      {(operatorMappingProperties.childProperties || []).map(
                        (childProperty, childIndex) => (
                          <LineItem
                            key={childProperty._tmpId}
                            condensed
                            className={styles.lineItemRule}
                          >
                            {renderPropertyColumn(
                              childProperty,
                              index,
                              childIndex
                            )}
                            {renderPropertyValue(
                              childProperty,
                              index,
                              childIndex
                            )}
                            {renderPropertyActions(index, childIndex)}
                          </LineItem>
                        )
                      )}

                      {index !== state.operatorMappingProperties.length - 1 && (
                        <Divider
                          marginY="m"
                          color="var(--ds-color-neutral-800)"
                        />
                      )}
                    </Box>
                  )
                )}
              />

              <Button
                data-testid="admin-users-userdetails-add-rule"
                intent="primary"
                size="small"
                strong
                pill
                onClick={() => addProperty()}
              >
                {t('pages.settings.admin.users.add_rule')}
              </Button>
            </Box>
          </Stack>

          {humaniseOperatorMappingProperties(state.operatorMappingProperties)}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingX="xxl"
          paddingY="l"
          className={styles.modalFooter}
        >
          <Button
            data-testid="admin-users-userdetails-cancel"
            strong
            onClick={onClose}
          >
            {t('pages.settings.admin.users.cancel')}
          </Button>
          <Box marginRight="m" />
          <Button
            intent="primary"
            strong
            data-testid="admin-users-userdetails-save"
            disabled={!state.id && emailIsValid !== true}
            onClick={() => {
              if (emailIsValid === true) {
                if (state.id) {
                  onUpdateUser(state);
                } else {
                  onCreateUser(state);
                }
                onClose();
              }
            }}
          >
            {t('pages.settings.admin.users.save')}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default React.memo(UserDetails, isEqual);

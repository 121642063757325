import { v4 as uuid } from 'uuid';
import { Finding } from '@entities/Finding';
import { post } from '@api/schema/client';
import { FindingFilterType } from '@config/ResultTable/FindingTable';
import { FindingSortType } from '@entities/InstitutionConfiguration';
import {
  decodeUpdateFindingResponse,
  encodeFindingInput,
} from './decoders/Finding';
import { FindingFilterMap, encodeEnum } from './decoders/Enum';
import { encodeFindingSortType } from './decoders/Institution';

export const FindingAPI = {
  reorderFindings: async (updatedFindings: Finding[]) => {
    const input = updatedFindings.map(f => {
      return {
        finding_id: f.id,
        index: f.index,
        included: f.included,
      };
    });

    const data = await post('/sort-findings', {
      body: {
        findings: input,
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  sortFindings: async (sessionId: string, sortType: FindingSortType) => {
    const data = await post('/sort-findings-by-config', {
      body: {
        session_id: sessionId,
        config: encodeFindingSortType(sortType),
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  updateFinding: async (finding: Finding) => {
    const data = await post('/update-finding', {
      body: {
        finding_id: finding.id,
        data: encodeFindingInput(finding),
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  assignDetection: async (findingId: string, detectionId: string) => {
    const data = await post('/assign-detections-to-finding', {
      body: {
        detection_ids: [detectionId],
        finding_id: findingId,
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  unassignDetection: async (findingId: string, detectionId: string) => {
    const data = await post('/unassign-finding-detection', {
      body: {
        finding_id: findingId,
        detection_id: detectionId,
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  createFindingFromDetection: async (
    sessionId: string,
    detectionId: string
  ) => {
    const data = await post('/create-finding-from-detections', {
      body: {
        session_id: sessionId,
        detection_ids: [detectionId],
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  deleteFinding: async (findingId: string) => {
    const data = await post('/delete-finding', {
      body: {
        finding_id: findingId,
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  createFinding: async (sessionId: string, finding: Finding) => {
    const data = await post('/create-finding', {
      body: {
        session_id: sessionId,
        finding_id: finding.id || uuid(),
        data: encodeFindingInput(finding),
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  filterFindings: async (
    sessionId: string,
    filterType: FindingFilterType,
    selectedIds?: string[]
  ) => {
    const data = await post('/filter-findings', {
      body: {
        session_id: sessionId,
        filter_type: encodeEnum(filterType, FindingFilterMap),
        selected_ids: selectedIds,
      },
    });

    return decodeUpdateFindingResponse(data);
  },

  updateComparisonFinding: async (
    findingId: string,
    comparisonFindingId: string | null
  ) => {
    const data = await post('/update-comparison-finding', {
      body: {
        finding_id: findingId,
        comparison_finding_id: comparisonFindingId,
      },
    });

    return decodeUpdateFindingResponse(data);
  },
};

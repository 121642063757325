export default interface Either<L, R> {
  isLeft(): this is Either<L, never>;
  isRight(): this is Either<never, R>;
  lift(): L | R;
  bimap<L2, R2>(f: (value: L) => L2, g: (value: R) => R2): Either<L2, R2>;
  mapLeft<L2>(f: (value: L) => L2): Either<L2, R>;
  map<R2>(f: (value: R) => R2): Either<L, R2>;
  andThen<L2, R2>(f: (value: R) => Either<L2, R2>): Either<L | L2, R2>;
}

export class Left<L, R = never> implements Either<L, R> {
  constructor(private value: L) {}

  isLeft() {
    return true;
  }

  isRight() {
    return false;
  }

  lift(): L {
    return this.value;
  }

  bimap<L2, R2>(f: (value: L) => L2): Either<L2, R2> {
    return left(f(this.value));
  }

  mapLeft<L2>(f: (value: L) => L2): Either<L2, R> {
    return left(f(this.value));
  }

  map<R2>(): Either<L, R2> {
    return left(this.value);
  }

  andThen<L2, R2>(): Either<L | L2, R2> {
    return left(this.value);
  }
}

export class Right<R, L = never> implements Either<L, R> {
  constructor(private value: R) {}

  isLeft() {
    return false;
  }

  isRight() {
    return true;
  }

  lift(): R {
    return this.value;
  }

  bimap<L2, R2>(_: (value: L) => L2, g: (value: R) => R2): Either<L2, R2> {
    return right(g(this.value));
  }

  mapLeft<L2>(): Either<L2, R> {
    return right(this.value);
  }

  map<R2>(f: (value: R) => R2): Either<L, R2> {
    return right(f(this.value));
  }

  andThen<L2, R2>(f: (value: R) => Either<L2, R2>): Either<L | L2, R2> {
    return f(this.value);
  }
}

export const left = <L, R = never>(value: L): Either<L, R> => new Left(value);

export const right = <R, L = never>(value: R): Either<L, R> => new Right(value);

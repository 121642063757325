import { PACSStudyStatus } from '@entities/PACSStudyLineItem';
export * from '@entities/PACSStudyLineItem';

export const PACSStudyStatusToString = (
  pacsStudyStatus: PACSStudyStatus
): string => {
  switch (pacsStudyStatus) {
    case PACSStudyStatus.Analysed:
      return 'pages.worklists.pacs_study.status.ready';
    case PACSStudyStatus.Analysing:
      return 'pages.worklists.pacs_study.status.analysing';
    case PACSStudyStatus.Retrieving:
      return 'pages.worklists.pacs_study.status.retrieving';
    case PACSStudyStatus.Error:
      return 'pages.worklists.pacs_study.status.error';
  }
};

export const mapPACSStudyStatusToColour = (
  pacsSystemStatus: PACSStudyStatus
): 'neutral' | 'success' | 'error' | 'information' | 'warning' => {
  switch (pacsSystemStatus) {
    default:
    case PACSStudyStatus.Retrieving:
      return 'information';
    case PACSStudyStatus.Analysing:
      return 'information';
    case PACSStudyStatus.Analysed:
      return 'success';
    case PACSStudyStatus.Error:
      return 'error';
  }
};

import React from 'react';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Card } from '@webMolecules/Card/Card';
import { Stack } from '@webMolecules/Stack/Stack';
import { Modal } from '@webMolecules/Modal/Modal';
import {
  InstitutionLineItem,
  InstitutionRegion,
  defaultInstitutionCopyText,
} from '@interfaces/Admin/InstitutionLineItem';
import {
  AWS_REGION_DIRECTORY,
  AWS_REGION_KEYS,
} from '@contracts/Admin/Institutions';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';

import styles from './institutionNew.scss';

export interface InstitutionNewProps {
  institutionLineItem: InstitutionLineItem;
  onClose: () => void;
  onCreateInstitution: (institutionLineItem: InstitutionLineItem) => void;
}

interface FormValues {
  id: string;
  institutionCopyText: string;
  region: InstitutionRegion;
}

const InstitutionNew: React.FC<InstitutionNewProps> = ({
  institutionLineItem,
  onClose,
  onCreateInstitution,
}) => {
  const formProps: FormValues = {
    id: institutionLineItem.id,
    institutionCopyText: institutionLineItem.institutionCopyText,
    region: institutionLineItem.region,
  };

  const regionOptions = AWS_REGION_KEYS.map(value => ({
    value,
    label: AWS_REGION_DIRECTORY[value] || value,
  }));

  const NewInstitutionSchema = Yup.object().shape({
    id: Yup.string()
      .required('Institution ID is required')
      .matches(
        /^[a-z][a-z0-9-]+$/i,
        'The Institution ID can only contain alphanumeric characters and a hyphen (a-z 0-9 and -)'
      ),
    institutionCopyText: Yup.string(),
    region: Yup.string().required('Region is required'),
  });

  const handleFormSubmit = (formValues: FormValues) => {
    onCreateInstitution({
      ...institutionLineItem,
      ...formValues,
      institutionCopyText:
        formValues.institutionCopyText.length > 0
          ? formValues.institutionCopyText
          : defaultInstitutionCopyText,
    });
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <Modal isOpen={true} size="medium" onClose={onClose}>
      <Card elevation="medium" padding="none">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={formProps}
          validationSchema={NewInstitutionSchema}
        >
          {formProps => (
            <Form>
              <Box className={styles.modalBody} padding="xxl">
                <Box className={styles.modalClose} padding="xl">
                  <Button
                    iconBefore={<Icon name="x" />}
                    circle
                    intent="neutral"
                    size="small"
                    onClick={onClose}
                  />
                </Box>
                <Stack spacing="m">
                  <Text type="display1" display="block" marginBottom="m">
                    Add new institution
                  </Text>
                  <Box marginBottom="xs">
                    <Text type="display6" display="block" marginBottom="xs">
                      Institution ID
                    </Text>
                    <FormInput
                      type={FormInputTypes.Text}
                      error={formProps.errors.id}
                      id="id"
                      name="id"
                      touched={formProps.touched.id}
                      fluid
                    />
                    {formProps.errors.id && (
                      <ErrorMessage
                        render={msg => (
                          <FormFieldHint
                            intent="error"
                            marginTop="xs"
                            marginBottom="xs"
                          >
                            {msg}
                          </FormFieldHint>
                        )}
                        name="id"
                      />
                    )}
                  </Box>
                  <Box marginBottom="xs">
                    <Text type="display6" display="block" marginBottom="xs">
                      Login Copy Text
                    </Text>
                    <FormInput
                      type={FormInputTypes.Text}
                      error={formProps.errors.institutionCopyText}
                      id="institutionCopyText"
                      name="institutionCopyText"
                      touched={formProps.touched.institutionCopyText}
                      fluid
                    />
                  </Box>
                  <Box marginBottom="xs">
                    <Text type="display6" display="block" marginBottom="xs">
                      Region
                    </Text>
                    <FormInput
                      type={FormInputTypes.Select}
                      options={regionOptions}
                      error={formProps.errors.region}
                      id="region"
                      name="region"
                      touched={formProps.touched.region}
                      fluid
                    />
                  </Box>
                </Stack>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                paddingX="xxl"
                paddingY="l"
                className={styles.modalFooter}
              >
                <Button strong onClick={onClose}>
                  Cancel
                </Button>
                <Box marginRight="m" />
                <Button type="submit" intent="primary" strong>
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export default React.memo(InstitutionNew, isEqual);

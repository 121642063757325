import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@webMolecules/Box/Box';
import { InteractiveBox } from '@webMolecules/InteractiveBox/InteractiveBox';
import { Input } from '@webMolecules/Input/Input';
import { Icon } from '@webMolecules/Icon/Icon';
import { Logo } from '@webMolecules/Logo/Logo';
import { UserMenu } from '@webOrganisms/UserMenu/UserMenu';
import { cnames } from '@helpers/cnames';
import { Profile } from '@interfaces/Profile';
import { t } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import { eventWorklistActionPerformedSearch } from '@webInterfaces/Analytics';
import { RouteLink } from '../RouteLink/RouteLink';
import styles from './header.scss';

export interface HeaderProps {
  onSignOut?: () => void;
  profile?: Profile;
  children?: React.ReactNode;
  sticky?: boolean;
  searchQuery?: string;
  showSearchBar?: boolean;
}

export const Header = ({
  onSignOut,
  profile,
  children,
  searchQuery = '',
  sticky = true,
  showSearchBar = true,
}: HeaderProps) => {
  const classNames = cnames(styles.header, {
    [styles.sticky]: sticky,
  });
  const [localSearchQuery, setLocalSearchQuery] =
    React.useState<string>(searchQuery);
  React.useEffect(() => {
    setLocalSearchQuery(searchQuery);
  }, [searchQuery]);

  const history = useHistory();
  const onSubmitSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    eventWorklistActionPerformedSearch();
    if (localSearchQuery.length >= 1) {
      // encodes special characters including: , / ? : @ & = + $ # but since % is not handled here are replacing it with empty string
      history.push(
        RouteHelper.search(
          encodeURIComponent(localSearchQuery.replace(/[%]/g, ''))
        )
      );
    }
  };

  return (
    <Box
      display="flex"
      className={classNames}
      paddingX="xl"
      alignItems="center"
      gap="m"
    >
      <RouteLink
        component={InteractiveBox}
        to="/"
        element="a"
        title="Home"
        padding="xs"
      >
        <Logo variant="mark" height={28} />
      </RouteLink>
      {children}
      <Box display="flex" alignItems="center" marginLeft="auto">
        {showSearchBar && (
          <Box marginRight="s">
            <form onSubmit={onSubmitSearch}>
              <Input
                data-testid="header-search-bar"
                placeholder={t('header.search.placeholder')}
                fluid
                pill
                minLength={3}
                defaultValue={localSearchQuery}
                onChange={ev => {
                  setLocalSearchQuery(ev.target.value);
                }}
                iconBefore={<Icon name="search" />}
              />
            </form>
          </Box>
        )}
        <UserMenu profile={profile} onSignOut={onSignOut} />
      </Box>
    </Box>
  );
};

export interface HeaderSlotProps {
  children?: React.ReactNode;
  alignItems?:
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline';
}

export const HeaderSlot = ({ children }: HeaderSlotProps) => <>{children}</>;

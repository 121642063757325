import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box } from '@webMolecules/Box/Box';
import { Header } from '@webOrganisms/Header/Header';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { selectProfile } from '@selectors/BaseSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { authenticateUseCase } from '@useCases/Authentication';
import {
  selectIsSessionLoading,
  selectComparisonStatus,
} from '@selectors/SessionSelectors';
import { LoadingLayout } from '@webPages/Study/LoadingLayout';
import { Footer } from '@webMolecules/Footer/Footer';
import { ImageProvider } from '@hooks/useImageContext';
import { ComparisonStatus } from '@webViewModels/Pages/Study/Shared';
import DebugBar from '../DebugBar';
import ComparisonLayout from '../Comparison/ComparisonLayout';
import { StudyTitle } from '../Sonographer/StudyTitle';
import { RadiologyFooter } from './RadiologyFooter';
import { RadiologySession } from './RadiologySession';
import styles from './radiology.scss';

export const RadiologyLayout: React.FC = () => {
  const me = useSelector(selectProfile);
  const isLoading = useSelector(selectIsSessionLoading);
  const comparisonStatus = useSelector(selectComparisonStatus);

  const onSignOut = useDispatcher(authenticateUseCase.SignOut);

  const isComparisonAvailable =
    comparisonStatus !== ComparisonStatus.Unavailable;
  const [isComparisonPage, setComparisonPage] = useState(false);
  const togglePage = () => setComparisonPage(!isComparisonPage);

  return (
    <ImageProvider>
      <Box display="flex" flexDirection="column" className={styles.fullHeight}>
        <DebugBar />
        <Header
          profile={me}
          sticky={false}
          onSignOut={onSignOut}
          showSearchBar={false}
        >
          {isLoading ? (
            <Box display="flex" alignItems="center">
              <LoadingIcon />
            </Box>
          ) : (
            <StudyTitle />
          )}
        </Header>
        {isLoading ? (
          <LoadingLayout />
        ) : (
          <>
            <Box data-testid="page-loaded-indicator" display="inline" />
            <Box scrollable={true} flex="1 1 auto">
              <Box className={styles.layoutContainer}>
                {isComparisonPage ? (
                  <DndProvider backend={HTML5Backend}>
                    <ComparisonLayout />
                  </DndProvider>
                ) : (
                  <RadiologySession />
                )}
              </Box>
              <Box display="flex" justifyContent="center" fullWidth>
                <Footer>
                  <RadiologyFooter
                    isComparisonAvailable={isComparisonAvailable}
                    isComparisonPage={isComparisonPage}
                    togglePage={togglePage}
                  />
                </Footer>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </ImageProvider>
  );
};

import { authenticateUseCase } from '@useCases/Authentication';
import { profileUseCase } from '@useCases/Profile';
import { Profile } from '@interfaces/Profile';
import { Actions } from '@webInterfaces/StoreTypes';

export const update = (profile: Profile): Actions =>
  profileUseCase.Update({ profile });

export const changePassword = (
  oldPassword: string,
  newPassword: string
): Actions => authenticateUseCase.ChangePassword({ oldPassword, newPassword });

export const setUpMFA = (): Actions => authenticateUseCase.SetupMFA();

export const verifyMFA = (otp: string): Actions =>
  authenticateUseCase.EnableMFA({ token: otp });

export const disableMFA = (otp: string): Actions =>
  authenticateUseCase.DisableMFA({ token: otp });

import isUndefined from 'lodash/isUndefined';

export const titleCase = (text: string): string => {
  text = text.replace(/_/gi, ' ');
  const textList = text.toLowerCase().split(' ');
  for (let i = 0; i < textList.length; i++) {
    textList[i] = textList[i].charAt(0).toUpperCase() + textList[i].slice(1);
  }
  return textList.join(' ');
};

export const slugify = (input: string): string => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return input
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

/**
 * Get the first "real" char from a string (e.g. an emoji)
 * http://xahlee.info/js/js_string_byte_sequence.html
 */
export const getFirstChar = (str: string): string => {
  for (const c of str) {
    return c;
  }
  return '';
};

export const stringFallback = (
  input: string | null | undefined,
  fallback: string
) => {
  return typeof input === 'string' && input.trim().length > 0
    ? input
    : fallback;
};

export const isAnonymousName = (name: string | null | undefined): boolean => {
  if (!name || name.trim().length === 0) {
    return true;
  }

  const filter = new RegExp(/anonymised|anonymized|anonymous|[\*]{3}/);
  return !!name.toLowerCase().match(filter);
};

const padStart = (digit: number) => {
  return digit.toString().padStart(2, '0');
};

export const stringFromHourAndMinute = (hour: number, minute: number) => {
  return `${padStart(hour)}:${padStart(minute)}`;
};

export const stringFromSize = (
  {
    width,
    height,
    length,
  }: { width?: number; height?: number; length?: number },
  unit?: string
) => {
  const reg = /null/g;
  const roundedSize = [width, height, length].map(ele =>
    ele ? Math.round(ele * 100) / 100 : undefined
  );
  const sizing = roundedSize
    .filter(a => !isUndefined(a))
    .map(String)
    .join(' × ')
    .trim();
  if (sizing && unit) {
    return `${sizing.replace(reg, '-')} ${unit}`;
  } else if (sizing) {
    return sizing.replace(reg, '-');
  }
  return '';
};

export const getHourMinuteFromTimeString = (time: string) => {
  const timeSplit = time.split(':');
  const hour = parseInt(timeSplit[0]);
  const minute = parseInt(timeSplit[1]);

  return [hour, minute];
};

export const isBlank = (str: string | null | undefined) => {
  return str === null || str === undefined || str.trim() === '';
};

export const isNotBlank = (str: string | null | undefined): str is string =>
  !isBlank(str);

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { Drawer } from '@webMolecules/Drawer/Drawer';
import { selectActiveFinding } from '@selectors/FindingInteractionSelectors';
import { selectFindingTableConfig } from '@selectors/SessionSelectors';
import { FeatureFlags } from '@entities/FeatureFlag';
import DetailedFindingsTable from './DetailedFindingsTable';
import SmallPartsStudyFindingOverview from './SmallPartsStudyFindingOverview';

export interface FindingsTableProps {
  disableInteractions: boolean;
  applyFindingFilter?: boolean;
  featureFlags: FeatureFlags;
}

export const FindingsTable: React.FC<FindingsTableProps> = ({
  disableInteractions,
  featureFlags,
}) => {
  const activeFinding = useSelector(selectActiveFinding);
  const findingTableConfig = useSelector(selectFindingTableConfig);
  if (!findingTableConfig) {
    return null;
  }

  const { config } = findingTableConfig;
  return (
    <>
      <Box
        data-testid="test-findings-table"
        style={{
          pointerEvents:
            disableInteractions || !!activeFinding ? 'none' : 'auto',
        }}
      >
        <SmallPartsStudyFindingOverview />
      </Box>
      <Drawer isOpen={!!activeFinding} size="medium">
        <DetailedFindingsTable
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          activeFinding={activeFinding!}
          config={config}
          featureFlags={featureFlags}
        />
      </Drawer>
    </>
  );
};

import * as Contract from '@contracts/AppConfiguration';
import { AppConfig } from '@interfaces/AppConfig';
import { post } from '@api/schema/client';
import { getHostNameFromURL } from '@amplify/Init';
import { decode } from './decoders/AppConfig';

export class AppConfiguration implements Contract.AppConfiguration {
  async Get(): Promise<AppConfig> {
    const { endpointSlug } = getHostNameFromURL();
    const data = await post('/anon/get-application-config', {
      body: {
        institution_id: endpointSlug,
      },
    });

    return decode(data);
  }
}

import {
  FilterConfigurationProperty,
  FilterConfigurationPropertyOperator,
} from '@entities/FilterConfiguration';
import { schemas } from '@api/schema/client';

export const decode = (
  data: schemas['FilterPropertyResponse']
): FilterConfigurationProperty => {
  const filterPropertyId = data.filter_property_id;
  const column = data.db_column;
  const value = data.value;
  const parentId = data.parent?.filter_property_id;

  const operator = (() => {
    switch (data.operator) {
      default:
      case 'matches':
        return FilterConfigurationPropertyOperator.Matches;
      case 'contains':
        return FilterConfigurationPropertyOperator.Contains;
    }
  })();

  return {
    id: filterPropertyId,
    column,
    operator,
    value,
    parentId,
    childProperties: [],
  };
};

import { worklistUseCase } from '@useCases/Worklist';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { Actions } from '@webInterfaces/StoreTypes';
import { PACSStudyUseCase } from '@useCases/PACSStudy';
import { listPACSStudies } from './PACS';

export const listSessions = (args: {
  group?: string;
  id?: string;
  status?: string;
}): Actions[] => {
  return [
    listPACSStudies(),
    worklistUseCase.StartPoll({
      group: args.group,
      id: args.id,
      status: args.status,
    }),
  ];
};

export const listNextSessions = (): Actions =>
  worklistUseCase.ListNextSessions();

export const markAsBookmarked = (
  sessionId: string,
  isBookmarked: boolean
): Actions => worklistUseCase.MarkAsBookmarked({ sessionId, isBookmarked });

export const updateSessionAssignedWorkspaces = (
  sessionId: string,
  workspaces: string[]
): Actions => {
  return worklistUseCase.UpdateSessionAssignedWorkspaces({
    sessionId,
    workspaces,
  });
};

export const updateSessionsAssignedWorkspaces = (
  sessionIds: string[],
  workspace: string
): Actions => {
  return worklistUseCase.AddWorkspaceToSessions({
    sessionIds,
    workspace,
  });
};

export const setSessionStatus = (
  analysisId: string,
  sessionId: string,
  status: SessionStatus
): Actions =>
  worklistUseCase.SetSessionLineItemStatus({
    analysisId,
    sessionId,
    sessionStatus: status,
  });

export const unloadSessions = (): Actions[] => [
  worklistUseCase.UnloadSessions(),
  PACSStudyUseCase.CancelPoll(),
  worklistUseCase.CancelPoll(),
];

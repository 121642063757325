import { State } from '@webInterfaces/StoreTypes';
import { toApp } from '@interfaces/Root';
import { toAppSettings, toPACS, toProfile, toSearch } from '@interfaces/App';
import { Profile } from '@interfaces/Profile';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import * as ViewModelLayout from '../Layout';

export type Application = ApplicationProps & ViewModelLayout.Layout;

export interface ApplicationProps {
  profile: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  ...ViewModelLayout.dispatchers(dispatch),
});

export const fromState = (state: State): ApplicationProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const pacs = toPACS(app);
  const search = toSearch(app);
  const profile = toProfile(app);

  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const searchQuery = search.query;
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;

  return {
    profile,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    searchQuery,
    pacsStudyLineItems,
    pacsSystemStatus,
  };
};

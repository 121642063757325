export enum PACSSystemStatus {
  Init = 'pacs_system_status--init',
  UptoDate = 'pacs_system_status--up-to-date',
  Analysing = 'pacs_system_status--analysing',
  Failed = 'pacs_system_status--failed',
}

export const mapPACSStudyStatusToPacsSystemStatus = (
  pacsStudyLineItems: PACSStudyLineItem[]
): PACSSystemStatus => {
  const statuses = pacsStudyLineItems.map(psli => psli.status);
  if (statuses.includes(PACSStudyStatus.Analysing)) {
    return PACSSystemStatus.Analysing;
  } else if (statuses.includes(PACSStudyStatus.Retrieving)) {
    return PACSSystemStatus.Analysing;
  } else if (statuses.includes(PACSStudyStatus.Error)) {
    return PACSSystemStatus.Failed;
  }
  return PACSSystemStatus.UptoDate;
};

export const mapPacsSystemStatusToColour = (
  pacsSystemStatus: PACSSystemStatus
): 'neutral' | 'success' | 'error' | 'information' | 'warning' => {
  switch (pacsSystemStatus) {
    default:
    case PACSSystemStatus.Init:
      return 'neutral';
    case PACSSystemStatus.UptoDate:
      return 'success';
    case PACSSystemStatus.Analysing:
      return 'information';
    case PACSSystemStatus.Failed:
      return 'error';
  }
};

export const PACSSystemStatusToString = (
  pacsSystemStatus: PACSSystemStatus
): string => {
  switch (pacsSystemStatus) {
    case PACSSystemStatus.Init:
      return 'generic.pacs_system.status.connecting';
    case PACSSystemStatus.Analysing:
      return 'generic.pacs_system.status.processing';
    case PACSSystemStatus.UptoDate:
      return 'generic.pacs_system.status.upToDate';
    case PACSSystemStatus.Failed:
      return 'generic.pacs_system.status.failed';
  }
};
export enum PACSStudyStatus {
  Retrieving = 'pacs_study_status--retrieving',
  Analysing = 'pacs_study_status--analysing',
  Analysed = 'pacs_study_status--analysed',
  Error = 'pacs_study_status--error',
}

export interface PACSStudyLineItem {
  id: string;
  date: string;
  accession: string;
  operatorCode: string;
  status: PACSStudyStatus;
}

import React from 'react';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import styles from './modal.scss';

export interface ModalProps {
  onClose: () => void;
  children?: React.ReactNode;
  onOpen?: () => void;
  isOpen?: boolean;
  width?: number;
  ariaLabel?: string;
  size?: 'small' | 'medium' | 'large';
  fluid?: boolean;
  fullHeight?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  size = 'medium',
  children,
  isOpen,
  fluid,
  width,
  ariaLabel = 'modal',
  fullHeight,
}) => {
  const classNames = [
    fluid ? styles.fluid : '',
    size === 'small' ? styles.small : '',
    size === 'medium' ? styles.medium : '',
    size === 'large' ? styles.large : '',
    fullHeight ? styles.fullHeight : '',
    styles.dialog,
  ].join(' ');

  return (
    <Dialog
      className={classNames}
      allowPinchZoom={true}
      isOpen={isOpen}
      onDismiss={onClose}
      style={{ width: width }}
      aria-label={ariaLabel}
      onMouseDown={e => e.stopPropagation()}
      onPointerDown={e => e.stopPropagation()}
    >
      {children}
    </Dialog>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { cnames } from '@helpers/cnames';
import { Icon } from '@webMolecules/Icon/Icon';
import styles from './slider.scss';

export interface SliderProps {
  step?: number;
  defaultValue?: number;
  min: number;
  max: number;
  disabled?: boolean;
  id?: string;
  fluid?: boolean;
  onChange?: (value: number) => void;
}

export const Slider: React.FC<SliderProps> = ({
  defaultValue,
  fluid,
  min,
  max,
  id,
  step = 1,
  disabled,
  onChange = () => null,
}) => {
  const [value, setValue] = React.useState(defaultValue || min);

  const handleChange = (newValue: number) => {
    onChange(newValue);
    setValue(newValue);
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(parseInt(e.target.value));
  };

  const handleIncrease = () => {
    if (value < max) handleChange(value + step);
  };

  const handleDecrease = () => {
    if (value > min) handleChange(value - step);
  };

  return (
    <Box
      fullWidth={fluid}
      display={fluid ? 'flex' : 'inline-flex'}
      alignItems="center"
      className={cnames(styles.slider, { [styles.disabled]: disabled })}
      style={
        {
          '--ds-slider-value': value,
          '--ds-slider-min': min,
          '--ds-slider-max': max,
        } as React.CSSProperties
      }
    >
      <button
        className={styles.sliderBtn}
        onClick={handleDecrease}
        disabled={disabled}
      >
        <Icon name="minus-small" />
      </button>
      <input
        id={id}
        className={styles.sliderInput}
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        disabled={disabled}
        onChange={e => handleSliderChange(e)}
      />
      <button
        className={styles.sliderBtn}
        onClick={handleIncrease}
        disabled={disabled}
      >
        <Icon name="plus-small" />
      </button>
    </Box>
  );
};

import { EffectReducer, ReducerResult } from '@library/Reducer';
import { AppSettings, initial } from '@entities/AppSettings';
import { AppConfig } from '@entities/AppConfig';
import Maybe from '@monads/Maybe';
import Effect, { effect, batch } from '@library/Effect';
import { AppConfiguration as ContractAppConfiguration } from '@contracts/AppConfiguration';
import { FeatureFlags as ContractFeatureFlags } from '@contracts/FeatureFlags';
import { Profile } from '@entities/Profile';
import { createUseCase } from '@helpers/createUseCase';
import {
  FeatureFlags,
  initializeGlobalFeatureFlags,
} from '@entities/FeatureFlag';

export const appSettingsUseCase = {
  /**
   * Init
   * - init will load all analyses and start subscribing for any analysis creations and changes.
   */
  Init: createUseCase('APP_SETTINGS_INIT').noPayload(),

  /*
   * Update Feature Flags use case
   */
  UpdateFeatureFlags: createUseCase(
    'APP_SETTINGS_UPDATE_FEATURE_FLAGS'
  ).withPayload<{
    featureFlags: FeatureFlags;
  }>(),

  /*
   * Update App Configuration use case
   */
  UpdateAppConfiguration: createUseCase(
    'APP_SETTINGS_UPDATE_APP_CONFIGURATION'
  ).withPayload<{
    appConfig: AppConfig;
  }>(),

  /*
   * No Operation
   */
  NoOp: createUseCase('APP_SETTINGS_NO_OP').noPayload(),
};

/**
 *  All UseCases
 * */
export type AppSettingsUseCases = ReturnType<
  | typeof appSettingsUseCase.Init
  | typeof appSettingsUseCase.UpdateFeatureFlags
  | typeof appSettingsUseCase.UpdateAppConfiguration
  | typeof appSettingsUseCase.NoOp
>;

/**
 * Reducer
 *
 * The AppSettingsReducer takes AppSettingsUseCases and the current App Settings (state)
 * and returns a new state and possible side effects.
 */
export class AppSettingsReducer extends EffectReducer<AppSettings> {
  private apiAppConfiguration: ContractAppConfiguration;
  private apiFeatureFlags: ContractFeatureFlags;
  private profile: Profile;

  constructor(
    apiAppConfiguration: ContractAppConfiguration,
    apiFeatureFlags: ContractFeatureFlags,
    profile: Profile
  ) {
    super();
    this.apiAppConfiguration = apiAppConfiguration;
    this.apiFeatureFlags = apiFeatureFlags;
    this.profile = profile;
  }

  Perform(
    appSettings: AppSettings = initial(),
    { type, payload: useCase }: AppSettingsUseCases
  ): ReducerResult<AppSettings> {
    switch (type) {
      case appSettingsUseCase.Init.type: {
        return this.Result(
          { ...appSettings },
          batch([
            effectGetAppFeatureFlags(this.apiFeatureFlags, this.profile),
            effectGetAppConfiguration(this.apiAppConfiguration),
          ])
        );
      }
      case appSettingsUseCase.UpdateAppConfiguration.type: {
        return this.Result({
          ...appSettings,
          regulatoryVersion: new Maybe(useCase.appConfig.regulatoryVersion),
          regulatoryVersionModifiedDate: new Maybe(
            useCase.appConfig.regulatoryVersionModifiedDate
          ),
        });
      }
      case appSettingsUseCase.UpdateFeatureFlags.type: {
        return this.Result(
          {
            ...appSettings,
            featureFlagsInitialised: true,
          },
          effectInitialiseGlobalFeatureFlags(useCase.featureFlags)
        );
      }
      case appSettingsUseCase.NoOp.type:
        return this.Result(appSettings);
    }
  }
}

/*
 * Retrieve app config
 */
const effectGetAppConfiguration = (
  apiAppConfiguration: ContractAppConfiguration
): Effect =>
  effect(async () => {
    const appConfig = await apiAppConfiguration.Get();
    return appSettingsUseCase.UpdateAppConfiguration({ appConfig });
  }, 'effect-app-settings-get-app-config');

/*
 * Retrieve app feature flags
 */
const effectGetAppFeatureFlags = (
  apiFeatureFlags: ContractFeatureFlags,
  profile: Profile
): Effect =>
  effect(async () => {
    const featureFlags = await apiFeatureFlags.GetFeatureFlagsForUser(profile);
    return appSettingsUseCase.UpdateFeatureFlags({ featureFlags });
  }, 'effect-app-settings-get-app-feature-flags');

const effectInitialiseGlobalFeatureFlags = (
  featureFlags: FeatureFlags
): Effect =>
  effect(() => {
    initializeGlobalFeatureFlags(featureFlags);
    return appSettingsUseCase.NoOp();
  }, 'effect-initialise-global-feature-flags');

import { stringToStudyType } from '@interfaces/StudyType';
import Maybe, { just, nothing } from '@monads/Maybe';
import { Storage } from '@contracts/Storage';
import { PhiCipher } from '@interfaces/Session';
import { WorklistLineItem } from '@entities/Worklist';
import { schemas } from '@api/schema/client';
import { decode as decodeDate } from './date';
import { getStorageJsonFile } from './Shared';
import { SessionStatusMap, decodeEnum } from './Enum';

export const decodeWorklistLineItem = async (
  storage: Storage,
  data: schemas['ListSessionSessionResponse']
): Promise<WorklistLineItem> => {
  const id = data.session_id;
  const userId = data.user_profile_id ?? '';
  const collaborators = data.collaborator_user_profile_ids ?? [];
  const analysisId = data.analysis_id;
  const studyMetadataS3Key = data.study_metadata_s3_key;
  const studyType = stringToStudyType(data.study_type);
  const date = decodeDate(data.pacs_scan_date);
  const phiCipher = (await getStorageJsonFile(
    storage,
    studyMetadataS3Key
  )) as PhiCipher;

  const patientName = '';

  const accessionNumber = data.accession_id;

  const status = decodeEnum(data.status, SessionStatusMap);

  const generatedPDF: Maybe<string> = data.completed_report_pdf_path
    ? just(data.completed_report_pdf_path)
    : nothing();

  const isBookmarked = data.is_bookmarked;

  const completedDate = data.completed_timestamp
    ? decodeDate(data.completed_timestamp) ?? undefined
    : undefined;

  return {
    id,
    analysisId,
    userId,
    date,
    patientName,
    accessionNumber,
    status,
    studyType,
    generatedPDF,
    phiCipher: phiCipher?.phi_b64_cipher,
    decryptedPhi: undefined,
    live: true,
    collaborators,
    animated: false,
    decrypted: false,
    isBookmarked,
    completedDate,
  };
};

import React from 'react';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';

export interface FilterListItemProps {
  icon?: JSX.Element;
  id: string;
  title: string;
  added: boolean;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

export const FilterListItem: React.FC<FilterListItemProps> = ({
  icon,
  id,
  title,
  added,
  onAdd,
  onRemove,
}) => {
  return (
    <Box
      display="flex"
      marginBottom="xxs"
      justifyContent="space-between"
      alignItems="center"
      data-testid="filter-modal-item"
    >
      <Box data-testid="filter-modal-title" display="flex" alignItems="center">
        {icon && <Box marginRight="s">{icon}</Box>}
        <Text type="display5" display="block">
          {title}
        </Text>
      </Box>
      <Box
        data-testid="filter-modal-button"
        display="flex"
        justifyContent="flex-end"
        marginRight="xs"
      >
        {added ? (
          <Button
            data-testid="filter-modal-remove-button"
            size="small"
            intent="primary"
            onClick={() => {
              onRemove(id);
            }}
          >
            Remove
          </Button>
        ) : (
          <Button
            data-testid="filter-modal-add-button"
            size="small"
            intent="primary"
            onClick={() => {
              onAdd(id);
            }}
          >
            Add
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FilterListItem;

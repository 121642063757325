import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
export interface ToastDownloadProp {
  isBookmarked: boolean;
}

export const ToastBookmarked: React.FC<ToastDownloadProp> = ({
  isBookmarked,
}) => {
  return (
    <Box display="flex" gap="m">
      <Box display="flex" flexDirection="column" gap="xxs">
        <Text type="display3">
          {isBookmarked
            ? t('toast.add_to_bookmark.title')
            : t('toast.remove_from_bookmark.title')}
        </Text>
        <Text>
          {isBookmarked
            ? t('toast.add_to_bookmark.body')
            : t('toast.remove_from_bookmark.body')}
        </Text>
      </Box>
    </Box>
  );
};

import { toApp } from '@interfaces/Root';
import { toSession } from '@interfaces/App';
import { State } from '@webInterfaces/StoreTypes';

export type Comparison = ComparisonProps;

export interface ComparisonProps {
  sessionId: string;
  sessionDate: Date | null;
  comparisonSessionDate: Date | null;
  loadingComparisonSession?: boolean;
}

export const fromState = (state: State): ComparisonProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const session = toSession(app);
  const { details, comparisonSession } = session;

  const sessionDate = details.mapOr(a => a.date, null);

  return {
    sessionId: details.mapOr(a => a.id, ''),
    sessionDate,
    comparisonSessionDate: comparisonSession.mapOr(a => a.date, null),
  };
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { selectIncludedFindings } from '@selectors/FindingSelectors';
import { ComparisonRow } from './ComparisonRow';

export interface ComparisionAreaProps {
  isExpandAll: boolean;
}

export const ComparisionArea: React.FC<ComparisionAreaProps> = ({
  isExpandAll,
}) => {
  const findings = useSelector(selectIncludedFindings);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      fullWidth
      gapRow="s"
      scrollable
    >
      <Box display="flex" alignItems="stretch" flexDirection="column" fullWidth>
        {findings.map(finding => {
          return (
            <ComparisonRow
              key={finding.id}
              index={finding.index}
              finding={finding}
              isExpand={isExpandAll}
            />
          );
        })}
      </Box>
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Header, HeaderSlot } from '@webOrganisms/Header/Header';
import { Sidebar } from '@webOrganisms/Sidebar/Sidebar';
import { Text } from '@webMolecules/Text/Text';
import { Profile } from '@interfaces/Profile';
import { t } from '@webInterfaces/I18n';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import DebugBar from '@webViews/Pages/Study/DebugBar';
import styles from './worklist.scss';

export interface LayoutProps {
  me: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  sidebar: React.ReactElement;
  onSignOut: () => void;
}

export const Layout: React.FC<LayoutProps> = ({
  me,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  sidebar,
  children,
  onSignOut,
}) => {
  return (
    <Box display="flex" flexDirection="column" className={styles.fullHeight}>
      <DebugBar />
      <Header profile={me} onSignOut={onSignOut} searchQuery={searchQuery}>
        <HeaderSlot>
          <Text type="display2">{t('pages.worklists.layout.title')}</Text>
        </HeaderSlot>
      </Header>
      <Box scrollable={true} flex="1 1 auto">
        <Box className={styles.worklistContainer}>
          <Sidebar
            pacsStudyLineItems={pacsStudyLineItems}
            pacsSystemStatus={pacsSystemStatus}
            regulatoryVersion={regulatoryVersion}
            regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
          >
            {sidebar}
          </Sidebar>

          <Box role="main" flex="1" padding="xl" scrollable="y">
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

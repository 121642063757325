import * as Contract from '@contracts/Analytics';
import { Profile } from '@interfaces/Profile';
import { segmentioEnabled } from '@helpers/env';
import { log } from '@helpers/log';
import './segmentio.js';

export class Analytics implements Contract.Analytics {
  private static _instance: Analytics = new Analytics();
  private static _enabled: boolean = segmentioEnabled;
  private static _identity?: Contract.IdentityTraits;
  private static _group?: Contract.GroupTraits;

  constructor() {
    if (Analytics._instance) {
      throw new Error(
        'Instantiation failed: Use Analytics.getInstance() instead of new.'
      );
    }

    Analytics._instance = this;
  }

  public static getInstance(): Analytics {
    return Analytics._instance;
  }

  Identify(profile: Profile): Promise<void> {
    if (!Analytics._enabled) {
      return Promise.resolve();
    }

    const identity = {
      name: `${profile.firstName} ${profile.lastName}`,
      firstName: profile.firstName,
      lastName: profile.lastName,
      institutionId: profile.institutionId,
    };

    const group = {
      institutionId: profile.institutionId,
    };

    Analytics._identity = identity;
    Analytics._group = group;

    return new Promise<void>(resolve => {
      log('analytics', 'Identify', profile);
      try {
        window.analytics.identify(profile.id, identity, () => {
          window.analytics.group(profile.institutionId, group);
          resolve();
        });
      } catch (err) {}
    });
  }

  Event(event: Contract.AnalyticsEvent): Promise<void> {
    if (!Analytics._enabled) {
      return Promise.resolve();
    }

    const eventWithIdentity = {
      ...event,
      identity: Analytics._identity || null,
      group: Analytics._group || null,
    };

    return new Promise(resolve => {
      log('analytics', event.type, eventWithIdentity);
      try {
        window.analytics.track(event.type, eventWithIdentity, () => {
          resolve();
        });
      } catch (e) {}
    });
  }
}

export const analytics = (): Analytics => Analytics.getInstance();

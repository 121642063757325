import { ApiStatus } from '@entities/Status';
import { FilterOptions } from '@interfaces/FilterOptions';
import Maybe, { nothing } from '@monads/Maybe';
import { WorklistLineItem } from './Worklist';

export interface Search {
  status: ApiStatus;
  query: string;
  searchResultItems: WorklistLineItem[];
  totalAvailableItems: number;
  filterOptions: Maybe<FilterOptions>;
}

export const initial = (): Search => ({
  status: ApiStatus.Idle,
  query: '',
  searchResultItems: [],
  totalAvailableItems: 0,
  filterOptions: nothing(),
});

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import MiniCanvasContext from '@webViews/Pages/Study/MiniCanvasContext';
import { Image } from '@webMolecules/Image/Image';
import { GradingLabel } from '@webOrganisms/GradingLabel/GradingLabel';
import { Finding } from '@entities/Finding';
import { ClinicalRecommendationTag } from '../FindingsTable/ClinicalRecommendationTag';
import styles from './comparison.scss';
import { FindingSizeGrid } from './FindingSizeGrid';
import { FindingNodulePopover } from './Utility/FindingNodulePopover';

interface ComparisonFindingHeaderProps {
  finding: Finding;
  isExpanded: boolean;
  isPrevious: boolean;
  rowIndex?: number;
}

export const ComparisonFindingHeader: React.FC<
  ComparisonFindingHeaderProps
> = ({
  finding,
  isExpanded,
  isPrevious,
  rowIndex,
}: ComparisonFindingHeaderProps) => {
  const { miniCanvasURLs: dataURLs } = React.useContext(MiniCanvasContext);

  return (
    <Box
      display="flex"
      gap="m"
      className={!isExpanded ? styles.comparisonHeader : ''}
    >
      <Box paddingTop="l">
        <GradingLabel grading={finding.formatted.grading} />
      </Box>
      <Box className={styles.miniCanvas} paddingY="xs">
        <Image src={dataURLs[finding.id]} />
      </Box>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        paddingTop={isExpanded ? 'xs' : 'none'}
        justifyContent={isExpanded ? 'flex-start' : 'center'}
      >
        <Box display="flex">
          <Text muted type="display4">
            {finding.formatted.location}
          </Text>
        </Box>

        <Box
          display="flex"
          alignItems="start"
          justifyContent="space-between"
          className={styles.findingSizeContainer}
        >
          <Box flex="1">
            <FindingSizeGrid finding={finding} showDifferences={isExpanded} />
          </Box>

          <Box flex="0 0 4rem">
            {!isExpanded && (
              <FindingNodulePopover
                dicomIds={finding.formatted.dicomIds ?? []}
                index={rowIndex}
                isPrevious={isPrevious}
              />
            )}
          </Box>
        </Box>
        {isPrevious && (
          <Box>
            <ClinicalRecommendationTag finding={finding} outline={false} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { selectComparisonAvailable } from '@selectors/SessionSelectors';
import { NavTab } from '@webMolecules/NavTab/NavTab';

export const COMPARE_ROUTE = 'compare';
export const SUMMARY_ROUTE = 'summary';

interface SonographerNavBarProps {
  tab?: string;
  updateTab: (tab?: string) => void;
}

export const SonographerNavBar: React.FC<SonographerNavBarProps> = ({
  tab,
  updateTab,
}) => {
  const isComparisonAvailable = useSelector(selectComparisonAvailable);

  return (
    <Box display="flex">
      <NavTab index={1} current={tab === undefined} onClick={() => updateTab()}>
        Findings
      </NavTab>
      {isComparisonAvailable && (
        <NavTab
          index={2}
          current={tab === COMPARE_ROUTE}
          onClick={() => updateTab(COMPARE_ROUTE)}
        >
          Compare
        </NavTab>
      )}
      <NavTab
        index={isComparisonAvailable ? 3 : 2}
        current={tab === SUMMARY_ROUTE}
        finalTab
        onClick={() => updateTab(SUMMARY_ROUTE)}
      >
        Finalise
      </NavTab>
    </Box>
  );
};

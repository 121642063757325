import React from 'react';
import styles from './divider.scss';
import { Box } from './../Box/Box';

//Todo: extend box
export interface DividerProps {
  marginBottom?:
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginTop?:
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginY?:
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  color?: string;
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({
  color,
  marginY,
  marginBottom,
  marginTop,
  className = '',
}) => {
  return (
    <Box
      data-testid="divider-test-id"
      style={{ backgroundColor: color }}
      className={`${styles.divider} ${className}`}
      {...{
        marginY,
        marginTop,
        marginBottom,
      }}
    />
  );
};

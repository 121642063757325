import { NoduleGradingMap } from '@config/NoduleGrading';
import { FindingConfig } from '@config/ResultTable/Shared';
import { Finding } from '@entities/Finding';
import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';
import { t, tPrint } from '@webInterfaces/I18n';
import { getCharacteristicsList } from '@webOrganisms/ResultTable/FindingsTable/FindingSummaryRowPillsConfig';
import { checkIfSizeMissing } from '@webOrganisms/ResultTable/FindingsTable/SmallPartsStudyFindingHelper';

export const formatFindings = (
  findings: Finding[],
  findingConfig: FindingConfig,
  institutionConfig: Required<InstitutionConfiguration>
) => {
  return findings.map(finding => {
    const findingIndex = finding.index;
    const location = finding.formatted.location;
    const grading = finding.formatted.grading;
    const size = formatFindingSize(finding);
    let gradingStr = '';

    let characteristicsStr = findingConfig
      ? formatFindingCharacteristics(finding, findingConfig, institutionConfig)
      : '';

    characteristicsStr = characteristicsStr
      ? ' - ' + characteristicsStr
      : characteristicsStr;

    if (grading) {
      const { prefix: gradingPrefix, value: gradingValue } =
        NoduleGradingMap[grading];
      gradingStr = ` ${gradingPrefix} ${gradingValue}.`;
    }

    return `${tPrint('pages.study.worksheet.finalise.findings.nodule_index', findingIndex)} - ${location} (${size})${characteristicsStr}.${gradingStr}`;
  });
};

const formatFindingSize = (finding: Finding) => {
  if (checkIfSizeMissing(finding)) {
    return t('pages.study.finding.summary.size.missing');
  }
  if (finding.formatted.isSize3D) {
    const values = (['width', 'height', 'length'] as const)
      .map(key => {
        const size = finding.formatted[key];
        return size?.value;
      })
      .filter(Boolean);
    const unit =
      finding.formatted.width?.unit ||
      finding.formatted.height?.unit ||
      finding.formatted.length?.unit;

    return `${values.join(' x ')} ${unit}`;
  } else {
    const size = finding.formatted.max;
    return `${size?.value ?? '-'} ${size?.unit}`;
  }
};

const formatFindingCharacteristics = (
  finding: Finding,
  findingConfig: FindingConfig,
  institutionConfig: Required<InstitutionConfiguration>
) => {
  const { characteristics, indeterminateCharacteristics } =
    getCharacteristicsList(finding, findingConfig, institutionConfig);

  const formattedCharacteristics = characteristics.map(characteristics => {
    if (characteristics.displayValue) {
      return t(characteristics.displayValue).toLowerCase();
    } else if (typeof characteristics.data === 'string') {
      const options = characteristics.config.options.map(option => ({
        value: option.value,
        label: t(option.editableI18NKey),
        separator: option.separator,
      }));

      const option = options.find(o => o.value === characteristics.data);
      return option?.label.toLowerCase();
    }
  });

  const formattedIndeterminateCharacteristics =
    indeterminateCharacteristics.map(characteristics => {
      return t(characteristics.config.label).toLowerCase();
    });

  return formattedCharacteristics
    .concat(formattedIndeterminateCharacteristics)
    .join(', ');
};

import React from 'react';
import { cnames } from '@helpers/cnames';
import { Box } from '../Box/Box';
import styles from './text.scss';

export interface TextProps {
  dangerouslySetInnerHTML?: JSX.Element;
  display?: 'block' | 'inline';
  lineClamp?: number;
  loading?: boolean;
  muted?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  renderHtml?: boolean;
  patientSensitive?: boolean;
  element?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'sup'
    | 'sub';
  type?:
    | 'display1'
    | 'display2'
    | 'display3'
    | 'display4'
    | 'display5'
    | 'display6'
    | 'body'
    | 'body2'
    | 'caption';
  margin?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginBottom?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginLeft?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginRight?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginTop?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginX?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  marginY?:
    | 'auto'
    | 'none'
    | 'xxxs'
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'xxxl';
  noShrink?: boolean;
  textColor?: 'high' | 'low';
}

export const Text: React.FC<TextProps> = ({
  children,
  display,
  lineClamp,
  loading,
  muted,
  disabled = false,
  renderHtml = false,
  type = 'body',
  element = 'span',
  patientSensitive,
  noShrink = false,
  textColor,
  ...rest
}) => {
  const isNoWrap = lineClamp && lineClamp === 1 ? true : false;
  const isLineClamp = !isNoWrap && !!lineClamp ? true : false;

  const classNames = cnames(
    styles.text,
    styles[type],
    styles[textColor ?? ''],
    {
      [styles.muted]: muted,
      [styles.disabled]: disabled,
      [styles.lineclamp]: isLineClamp,
      [styles.nowrap]: isNoWrap,
      [styles.sub]: element === 'sub',
      [styles.sup]: element === 'sup',
      [styles.noShrink]: noShrink,
    }
  );

  // Limit display to block or inline
  const displayType = display === 'block' ? 'block' : 'inline';

  // Handle lineClamp, if it's 1 line, use nowrap instead
  const clampStyles = isLineClamp ? { WebkitLineClamp: lineClamp } : undefined;

  return (
    <Box
      className={classNames}
      display={displayType}
      element={element}
      style={clampStyles}
      {...rest}
      data-patient-sensitive={patientSensitive}
    >
      {loading ? (
        <span className={styles.loading}>{children}</span>
      ) : renderHtml ? (
        <span dangerouslySetInnerHTML={{ __html: String(children) }} />
      ) : (
        children
      )}
    </Box>
  );
};

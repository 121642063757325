import { sessionUseCase } from '@useCases/Session';
import {
  SessionMetadata,
  SessionConfiguration,
} from '@interfaces/SessionLineItem';
import { Actions } from '@webInterfaces/StoreTypes';

export const init = (analysisId: string, sessionId: string): Actions =>
  sessionUseCase.Init({ analysisId, sessionId });

export const unload = (): Actions => sessionUseCase.Unload();

export const updateSessionMetadata = (metadata: SessionMetadata): Actions =>
  sessionUseCase.UpdateMetadata({ metadata });

export const updateConfiguration = (
  configuration: SessionConfiguration,
  optimistic = true
): Actions => sessionUseCase.UpdateConfiguration({ configuration, optimistic });

export const loadSessionFromAccessionNum = (accessionNum: string): Actions =>
  sessionUseCase.LoadFromAccessionNum({ accessionNum });

import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';
import { ApiStatus } from '@entities/Status';
import Maybe, { nothing } from '@monads/Maybe';
import Tuple, { pair } from '@monads/Tuple';

export interface AdminApplication {
  institutionConfiguration: Tuple<ApiStatus, Maybe<InstitutionConfiguration>>;
}

export const initial = (): AdminApplication => ({
  institutionConfiguration: pair(ApiStatus.Idle, nothing()),
});

export enum UnitEntity {
  Velocity = 'unit_entity--velocity',
  Time = 'unit_entity--time',
  Distance = 'unit_entity--distance',
  Volume = 'unit_entity--volume',
  Acceleration = 'unit_entity--acceleration',
  Pressure = 'unity_entity--pressure',
}

export enum VelocityUnit {
  CMS = 'velocity_unit--centimeter_per_second',
  MS = 'velocity_unit--meter_per_second',
}

export enum DistanceUnit {
  MM = 'distance_unit--millimeter',
  CM = 'distance_unit--centimeter',
  M = 'distance_unit--meter',
}

export enum TimeUnit {
  MS = 'time_unit--millisecond',
  S = 'time_unit--second',
  M = 'time_unit--minute',
  H = 'time_unit--hour',
  D = 'time_unit--day',
}

export enum VolumeUnit {
  ML = 'volume_unit--milliliter',
  CL = 'volume_unit--centiliter',
  L = 'volume_unit--liter',
}

export enum AccelerationUnit {
  MMS2 = 'acceleration_unit--millimeter_square_second',
  CMS2 = 'acceleration_unit--centimeter_square_second',
}

export enum PressureUnit {
  MMHG = 'pressure_unit--millimeters_of_mercury',
}

export type AnyUnit =
  | VelocityUnit
  | DistanceUnit
  | TimeUnit
  | VolumeUnit
  | AccelerationUnit
  | PressureUnit;

export const unitSymbols: Record<AnyUnit, string> = {
  [VelocityUnit.CMS]: 'cm/s',
  [VelocityUnit.MS]: 'm/s',
  [DistanceUnit.MM]: 'mm',
  [DistanceUnit.CM]: 'cm',
  [DistanceUnit.M]: 'm',
  [TimeUnit.MS]: 'ms',
  [TimeUnit.S]: 's',
  [TimeUnit.M]: 'm',
  [TimeUnit.H]: 'h',
  [TimeUnit.D]: 'd',
  [VolumeUnit.ML]: 'ml',
  [VolumeUnit.CL]: 'cl',
  [VolumeUnit.L]: 'l',
  [AccelerationUnit.MMS2]: 'mm/s2',
  [AccelerationUnit.CMS2]: 'cm/s2',
  [PressureUnit.MMHG]: 'mmHg',
};

export const getUnitI18nKey = (unit: AnyUnit) => {
  return `unit.${unit}`;
};

export const getUnitSymbol = (unit: AnyUnit) => {
  return unitSymbols[unit];
};

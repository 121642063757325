import * as React from 'react';
import {
  getSymbolDrawingColor,
  SymbolColorKey,
} from '@webOrganisms/DrawingToolOptions/DrawingColors';

interface SymbolProps {
  name: SymbolName;
  colorKey?: SymbolColorKey;
}

export enum SymbolName {
  Incompetent = 'incompetent',
  Competent = 'competent',
}

export const Symbol = ({ name, colorKey }: SymbolProps) => {
  const { primaryColor, secondaryColor } = getSymbolDrawingColor(colorKey);
  switch (name) {
    case SymbolName.Incompetent:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="20" height="20" rx="10" fill={secondaryColor} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8489 16.3635C13.5036 17.3902 11.823 18 10 18C8.17693 18 6.49629 17.3902 5.15093 16.3634L9.99989 11.5145L14.8489 16.3635ZM16.2409 15.0056L11.3748 10.1395L16.3634 5.15093C17.3902 6.49629 18 8.17693 18 10C18 11.8945 17.3415 13.6351 16.2409 15.0056ZM15.0055 3.75902L9.99989 8.76461L4.9944 3.75912C6.36486 2.65851 8.10553 2 10 2C11.8944 2 13.635 2.65847 15.0055 3.75902ZM3.63647 5.15105C2.60977 6.49639 2 8.17699 2 10C2 11.8944 2.65847 13.635 3.75902 15.0055L8.62496 10.1395L3.63647 5.15105ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
            fill={primaryColor}
          />
        </svg>
      );
    case SymbolName.Competent:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="9"
            fill={secondaryColor}
            stroke={primaryColor}
            strokeWidth="2"
          />
        </svg>
      );
    default:
      return null;
  }
};

import { authenticateUseCase } from '@useCases/Authentication';
import { appUseCase } from '@useCases/App';
import { appSettingsUseCase } from '@useCases/AppSettings';
import { Actions } from '@webInterfaces/StoreTypes';
import { FeatureFlags } from '@entities/FeatureFlag';

export const signOut = (): Actions => authenticateUseCase.SignOut();

export const updateFeatureFlags = (featureFlags: FeatureFlags): Actions =>
  appSettingsUseCase.UpdateFeatureFlags({ featureFlags });

export const initFeatureFlags = (): Actions => appSettingsUseCase.Init();

export const redirectToUrl = (location: string): Actions =>
  appUseCase.Redirect({ pathname: location });

import { State } from '@webInterfaces/StoreTypes';
import { toApp } from '@interfaces/Root';
import { toSession } from '@interfaces/App';
import { ResultTableStyle } from '@webOrganisms/ResultTable/ResultTableContext';
import { FeatureFlags, getFeatureFlags } from '@entities/FeatureFlag';
import { ApiStatus } from '@entities/Status';

export type FindingTable = FindingTableProps;

interface ProxiedFindingTableProps {
  resultTableStyle: ResultTableStyle;
  applyFindingFilter: boolean;
}
interface FindingTableProps extends ProxiedFindingTableProps {
  loading: boolean;
  featureFlags: FeatureFlags;
}

export const fromState = (
  state: State,
  proxiedProps: ProxiedFindingTableProps
): FindingTableProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }
  const session = toSession(app);
  const featureFlags = getFeatureFlags();

  return {
    ...proxiedProps,
    loading: session.status !== ApiStatus.Idle,
    featureFlags,
  };
};

import { Storage } from '@contracts/Storage';
import { Annotations, IMAGE_TYPE } from '@entities/Dicom';
import * as Dicom from '@interfaces/Dicom';
import { components } from '@api/schema/generated';
import { logError } from '@webInterfaces/Error';
import { BoundingBox, Detection, DetectionSize } from '@entities/Detection';
import { DimensionTypeMap, DistanceUnitMap, decodeEnum } from './Enum';

const ExpiryInSeconds = 60 * 60 * 12;

export const decode = (
  storage: Storage,
  data: components['schemas']['DicomResponse']
): Dicom.Dicom => {
  try {
    const displayImageUrl = data.image_s3_key;
    const displayVideoUrl = data.video_s3_key ?? '';
    const GetImage = () => storage.GetUrl(displayImageUrl, ExpiryInSeconds);
    const GetVideo = () => storage.GetUrl(displayVideoUrl, ExpiryInSeconds);
    const imageType = decodeImageType(data.image_type);

    const annotations = decodeAnnotations(data.annotations);
    const frameRate = data.recommended_display_framerate ?? undefined;

    const detections = data.detections.map(detection =>
      decodeDetection(detection)
    );

    return {
      id: data.dicom_id,
      sopInstanceUid: data.sop_instance_uid,
      width: data.width,
      height: data.height,
      scanTime: data.scan_time,
      GetImage,
      GetVideo,
      imageType,
      annotations,
      hasVideo: Boolean(data.video_s3_key),
      frameRate,
      detections,
    };
  } catch (e) {
    throw new Error(String(e));
  }
};

export const decodeImageType = (input?: string | null): IMAGE_TYPE => {
  switch (input) {
    case 'image-type--abdo_upper_abdomen':
      return IMAGE_TYPE.UpperAbdomen;
    case 'image-type--gall-bladder':
      return IMAGE_TYPE.GallBladder;
    case 'image-type--liver':
      return IMAGE_TYPE.Liver;
    case 'image-type--common-bile-duct':
      return IMAGE_TYPE.CommonBileDuct;
    case 'image-type--portal-vein':
      return IMAGE_TYPE.PortalVein;
    case 'image-type--pancreas':
      return IMAGE_TYPE.Pancreas;
    case 'image-type--spleen':
      return IMAGE_TYPE.Spleen;
    case 'image-type--carotid_artery':
      return IMAGE_TYPE.CarotidArtery;
    case 'image-type--lower_limbs_artery':
      return IMAGE_TYPE.LowerLimbsArtery;
    case 'image-type--lower_limbs_vein':
      return IMAGE_TYPE.LowerLimbsVein;
    case 'image-type--aorta':
      return IMAGE_TYPE.Aorta;
    case 'image-type--renal_artery':
      return IMAGE_TYPE.RenalArtery;
    case 'image-type--mesenteric_artery':
      return IMAGE_TYPE.MesentericArtery;
    case 'image-type--kidney':
      return IMAGE_TYPE.Kidney;
    case 'image-type--iliac_artery':
      return IMAGE_TYPE.IliacArtery;
    case 'image-type--upper_limbs_artery':
      return IMAGE_TYPE.UpperLimbsArtery;
    case 'image-type--upper_limbs_vein':
      return IMAGE_TYPE.UpperLimbsVein;
    case 'image-type--subclavian_artery':
      return IMAGE_TYPE.SubclavianArtery;
    case 'image-type--subclavian_vein':
      return IMAGE_TYPE.SubclavianVein;
    case 'image-type--breast':
      return IMAGE_TYPE.Breast;
    case 'image-type--thyroid':
      return IMAGE_TYPE.Thyroid;
    case 'image-type--neck':
      return IMAGE_TYPE.Neck;
    case 'image-type--head':
      return IMAGE_TYPE.Head;
    case 'image-type--scrotum':
      return IMAGE_TYPE.Scrotum;
    case 'image-type--bladder':
      return IMAGE_TYPE.Bladder;
    case 'image-type--prostate':
      return IMAGE_TYPE.Prostate;
    case 'image-type--ovaries':
      return IMAGE_TYPE.Ovaries;
    case 'image-type--uterus':
      return IMAGE_TYPE.Uterus;
    case 'image-type--cervix':
      return IMAGE_TYPE.Cervix;
    case 'image-type--endometrium':
      return IMAGE_TYPE.Endometrium;
    default:
      return IMAGE_TYPE.Unknown;
  }
};

const decodeAnnotations = (
  annotations: components['schemas']['DicomResponse']['annotations']
): Annotations => {
  const { body_location, body_side, vessel, region } = annotations ?? {};
  const result: Annotations = {};
  try {
    return {
      bodyLocation: decodeAnnotationValue(body_location),
      bodySide: decodeAnnotationValue(body_side),
      vessel: decodeAnnotationValue(vessel),
      region: decodeAnnotationValue(region),
    };
  } catch (e) {
    logError(e as Error);
  }
  return result;
};

const decodeAnnotationValue = (
  value: string | number | null | undefined
): string | undefined => {
  return value?.toString() ?? undefined;
};

const decodeDetection = (
  detection: components['schemas']['DetectionResponse']
): Detection => {
  return {
    id: detection.detection_id,
    boundingBox: decodeBoundingBox(detection.bounding_box),
    detectionSizes: detection.sizes.map(size => decodeDetectionSize(size)),
  };
};

const decodeBoundingBox = (
  bounding_box: components['schemas']['BoundingBox']
): BoundingBox => {
  return {
    xMax: bounding_box.x_max,
    xMin: bounding_box.x_min,
    yMax: bounding_box.y_max,
    yMin: bounding_box.y_min,
    offsetX: bounding_box.offset_x,
    offsetY: bounding_box.offset_y,
    heightInMm: bounding_box.height_mm ?? undefined,
    widthInMm: bounding_box.width_mm ?? undefined,
    confidenceClass: bounding_box.confidence_class ?? undefined,
  };
};

const decodeDetectionSize = ({
  detection_size_id,
  path,
  size,
  annotated_size,
  unit,
  dimension_type,
}: components['schemas']['DetectionSizeDataResponse']): DetectionSize => {
  return {
    id: detection_size_id,
    path: path ?? undefined,
    size,
    annotatedSize: annotated_size,
    unit: decodeEnum(unit, DistanceUnitMap),
    dimensionType: decodeEnum(dimension_type, DimensionTypeMap),
  };
};

/* eslint-disable @typescript-eslint/ban-types */
import Reducer, { ReducerResult } from '@library/Reducer';
import { Location as ContractLocation, History } from '@contracts/Location';
import Effect, { effect } from '@library/Effect';
import { createUseCase } from '@helpers/createUseCase';
import { Root } from '@entities/Root';

export const locationUseCase = {
  /**
   * Update the browsers location
   */
  UseHistory: createUseCase('LOCATION_USE_HISTORY').withPayload<{
    history: History;
  }>(),

  /**
   * Update the browsers location
   */
  Update: createUseCase('LOCATION_UPDATE').withPayload<{
    pathname: string;
    replace?: boolean;
    alwaysUseWindowHistory?: boolean;
  }>(),

  /**
   * Reload the browsers location
   */
  Reload: createUseCase('LOCATION_RELOAD').withPayload<{
    path?: string;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('LOCATION_NO_OP').noPayload(),
};

/**
 *  All UseCases
 */
export type LocationUseCases = ReturnType<
  | typeof locationUseCase.UseHistory
  | typeof locationUseCase.Update
  | typeof locationUseCase.Reload
  | typeof locationUseCase.NoOp
>;

/*
 * Reducer
 *
 * The LocationReducer takes LocationUseCases
 * and possible side effects.
 */
export class LocationReducer extends Reducer<Root> {
  private apiLocation: ContractLocation;

  constructor(apiLocation: ContractLocation) {
    super();
    this.apiLocation = apiLocation;
  }

  Perform(
    root: Root,
    { type, payload: useCase }: LocationUseCases
  ): ReducerResult<Root> {
    switch (type) {
      case locationUseCase.UseHistory.type:
        return this.Result(
          root,
          effectUseHistory(this.apiLocation, useCase.history)
        );
      case locationUseCase.Update.type: {
        return this.Result(
          root,
          effectUpdatelocation(
            this.apiLocation,
            useCase.pathname,
            useCase.replace || false,
            useCase.alwaysUseWindowHistory || false
          )
        );
      }
      case locationUseCase.Reload.type: {
        return this.Result(
          root,
          effectReloadlocation(this.apiLocation, useCase.path)
        );
      }
      case locationUseCase.NoOp.type: {
        return this.Result(root);
      }
    }
  }
}

const effectUseHistory = (
  apiLocation: ContractLocation,
  history: History
): Effect =>
  effect(() => {
    try {
      apiLocation.UseHistory(history);
    } catch (e) {}
    return locationUseCase.NoOp();
  }, 'effect-location-use-history');

const effectUpdatelocation = (
  apiLocation: ContractLocation,
  pathname: string,
  replace: boolean,
  alwaysUseWindowHistory: boolean
): Effect =>
  effect(() => {
    try {
      apiLocation.Change(pathname, replace, alwaysUseWindowHistory);
    } catch (e) {}
    return locationUseCase.NoOp();
  }, 'effect-location-update-location');

const effectReloadlocation = (
  apiLocation: ContractLocation,
  path?: string
): Effect =>
  effect(() => {
    try {
      apiLocation.Reload(path);
    } catch (e) {}
    return locationUseCase.NoOp();
  }, 'effect-location-reload-location');

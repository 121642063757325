import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Toasts/Notifications';
import {
  ToastifyUpdateOptions,
  openToast,
  updateToast,
} from '@webMolecules/Toast/Toast';
import ToastNotification from '@webViews/Toasts/ToastNotification';
import { ToastDownload } from './ToastDownload';

const Notifications: React.FC<ViewModel.Notifications> = ({
  notifications,
  isPdfDownloading,
  dispatchDeleteNotification,
}) => {
  const [notificationToasts, setNotificationToasts] = React.useState<
    Map<number, React.ReactText>
  >(new Map());
  const [pdfToast, setPdfToast] = React.useState<React.ReactText | null>(null);

  React.useEffect(() => {
    if (isPdfDownloading) {
      const newToast = openToast(<ToastDownload isDownloading={true} />, {
        closeButton: true,
        pauseOnFocusLoss: false,
      });
      setPdfToast(newToast);
    } else if (pdfToast) {
      const updateToastOptions: ToastifyUpdateOptions = {
        render: <ToastDownload isDownloading={true} />,
        autoClose: 500,
        closeButton: true,
        pauseOnFocusLoss: false,
      };
      updateToast(pdfToast, updateToastOptions);
      setPdfToast(null);
    }
  }, [isPdfDownloading]);

  React.useEffect(() => {
    notifications.forEach(notification => {
      const notificationToastId = notificationToasts.get(notification.id);

      if (notificationToastId) {
        updateToast(notificationToastId, {
          render: <ToastNotification {...notification} />,
        });
      } else {
        const newToastId = openToast(<ToastNotification {...notification} />, {
          autoClose: notification.autoClose,
          closeButton: true,
          onClose: () => {
            dispatchDeleteNotification(notification.id);
            setNotificationToasts(prev => {
              const newNotificationsToasts = new Map(prev);
              newNotificationsToasts.delete(notification.id);
              return newNotificationsToasts;
            });
          },
        });

        setNotificationToasts(
          prev => new Map([...prev, [notification.id, newToastId]])
        );
      }
    });
  }, [notifications]);

  return null;
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(Notifications);

import get from 'lodash/get';
import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';
import {
  rightThyroidObservationsConfig,
  leftThyroidObservationsConfig,
  thyroidInlineFeaturesConfig,
  ObservationFeatureConfig,
  InlineFeatureConfig,
  thyroidIsthmusObservationsConfig,
} from '@config/SummaryTab/Thyroid';
import { getUnitI18nKey } from '@entities/Unit';
import { t, tPrint } from '@webInterfaces/I18n';
import {
  InlineFeature,
  Observation,
} from '@webOrganisms/SummaryObservation/SummaryObservation';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { Size } from '@entities/Finding';

export const getObservationsForSummaryTab = (
  generalCharacteristics: ThyroidGeneralCharacteristics
) => {
  const observations: Observation[] = [];

  [
    rightThyroidObservationsConfig,
    thyroidIsthmusObservationsConfig,
    leftThyroidObservationsConfig,
  ].map(config => {
    const lobeObservation = {} as Observation;

    lobeObservation.title = t(config.displayI18NKey);

    lobeObservation.size = getFormattedValueForObservationConfig(
      config.size,
      generalCharacteristics,
      '-'
    );

    if (config.volume) {
      lobeObservation.volume = getFormattedValueForObservationConfig(
        config.volume,
        generalCharacteristics,
        '-'
      );
    }

    const characteristics = config.characteristics
      .map(config => {
        return getFormattedValueForObservationConfig(
          config,
          generalCharacteristics
        );
      })
      .filter(Boolean);

    lobeObservation.characteristics = characteristics;

    observations.push(lobeObservation);
  });

  const inlineFeatures: InlineFeature[] = [];

  thyroidInlineFeaturesConfig.map(config => {
    const feature = {} as InlineFeature;
    feature.title = t(config.displayI18NKey);
    feature.value = getFormattedValueForObservationConfig(
      config,
      generalCharacteristics,
      '-'
    );

    inlineFeatures.push(feature);
  });

  return {
    observations: observations,
    inlineFeatures: inlineFeatures,
  };
};

export const getFormattedValueForObservationConfig = (
  config: ObservationFeatureConfig | InlineFeatureConfig,
  generalCharacteristics: ThyroidGeneralCharacteristics,
  fallBackValue = ''
) => {
  const valueObject = get(generalCharacteristics, config.path);

  let formattedValue;
  if (valueObject) {
    switch (config.valueType) {
      case 'float': {
        formattedValue = valueObject.value.toString();

        if (config.unit) {
          const value = convertUnit(
            valueObject.unit,
            config.unit,
            valueObject.value
          );

          formattedValue = roundValueByUnit(value, config.unit);
          return `${formattedValue} ${t(getUnitI18nKey(config.unit))}`;
        }
        break;
      }
      case 'dimensions': {
        const validSizes = [
          valueObject.width,
          valueObject.height,
          valueObject.length,
        ].filter(size => size && size.value) as Size[];

        const formattedValue = validSizes.length
          ? [valueObject.width, valueObject.height, valueObject.length]
              .map(size => {
                if (size && size.value) {
                  if (config.unit) {
                    return roundValueByUnit(
                      convertUnit(size.unit, config.unit, size.value),
                      config.unit
                    );
                  }
                }

                return '-';
              })
              .join(' x ')
          : '-';

        if (config.unit) {
          return `${formattedValue} ${t(getUnitI18nKey(config.unit))}`;
        }

        break;
      }
      case 'string':
        if (config.formattedFunction) {
          const result = config.formattedFunction(
            valueObject,
            generalCharacteristics
          );

          if (result) {
            if (typeof result === 'string') {
              formattedValue = t(result);
            } else {
              formattedValue = tPrint(result.i18nKey, result.data);
            }
          }
        } else {
          formattedValue = valueObject.toString();
        }

        break;
      default:
        formattedValue = valueObject.value?.toString();
        break;
    }
  }

  return formattedValue ? formattedValue : fallBackValue;
};

import React, { useState } from 'react';
import { t } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { Text } from '@webMolecules/Text/Text';

interface DialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ResetSessionDialog: React.FC<DialogProps> = props => {
  return (
    <DialogConfirm
      {...props}
      intent="destructive"
      labelOk={t('generic.submit.reset')}
      labelCancel={t('generic.submit.cancel')}
    >
      <Text type="display2" display="block" marginBottom="m">
        {t('pages.study.dialog.reset.title')}
      </Text>
      <Text>{t('pages.study.dialog.reset.blurb1')}</Text>
      <Text display="block" marginTop="s">
        {t('pages.study.dialog.reset.blurb2')}
      </Text>
    </DialogConfirm>
  );
};

export const EditCompletedStudyDialog: React.FC<DialogProps> = ({
  onConfirm,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <DialogConfirm
      {...props}
      intent="primary"
      labelOk={t('pages.completed.dialog.edit.submit')}
      labelCancel={t('generic.submit.cancel')}
      onConfirm={() => {
        setLoading(true);
        onConfirm();
      }}
      confirmButtonLoading={loading}
    >
      <Text type="display2" display="block" marginBottom="m">
        {t('pages.completed.dialog.edit.title')}
      </Text>
      <Text renderHtml display="block">
        {t('pages.completed.dialog.edit.blurb1')}
      </Text>
    </DialogConfirm>
  );
};

export const DeleteSessionDialog: React.FC<DialogProps> = props => {
  return (
    <DialogConfirm
      {...props}
      intent="destructive"
      labelOk={t('generic.submit.delete')}
      labelCancel={t('generic.submit.cancel')}
    >
      <Text type="display2" display="block" marginBottom="m">
        {t('pages.study.dialog.delete.title')}
      </Text>
      <Text>{t('pages.study.dialog.delete.blurb1')}</Text>
      <Text display="block" marginTop="s">
        {t('pages.study.dialog.delete.blurb2')}
      </Text>
    </DialogConfirm>
  );
};

import { isBlank, isNotBlank, titleCase } from '@helpers/string';
import { t, tPrint } from '@webInterfaces/I18n';
import { BreastFinding, Clockface, Size } from '@entities/Finding';
import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';
import { DistanceUnit } from '@entities/Unit';
import { BodySide, BreastFindingPosition } from '@entities/Characteristics';

export const generateBreastPosition = (finding: BreastFinding): string => {
  return getFormattedLocation(
    finding.characteristics.side,
    finding.characteristics.position,
    finding.characteristics.clockface,
    finding.characteristics.distanceFromNipple
  );
};

export const getFormattedLocation = (
  bodySide: BodySide | null,
  position: BreastFindingPosition | null,
  clockface: Clockface | null,
  distanceFromNipple: Size | null
) => {
  const formattedBodySide = bodySide ? titleCase(bodySide) : '';
  const formattedPosition = t(
    `config_key.result_table.breast_finding.options.${position}`,
    ''
  );

  if (
    position === BreastFindingPosition.Nipple ||
    position === BreastFindingPosition.Axilla
  ) {
    return `${formattedBodySide} ${formattedPosition}`.trim();
  }
  const formattedClock = clockface ? formatClockface(clockface) : '';

  let formattedDist = '';
  if (distanceFromNipple) {
    const distValue = convertUnit(
      distanceFromNipple.unit,
      DistanceUnit.MM,
      distanceFromNipple.value
    );

    formattedDist = tPrint(
      'formatted.distance_from_nipple.mm',
      roundValueByUnit(distValue, DistanceUnit.MM)
    );
  }

  const combinedBodysideClock = [formattedBodySide, formattedClock].join(' ');
  return [combinedBodysideClock, formattedDist].filter(Boolean).join(` • `);
};

export function formatClockface({ hour, minute }: Clockface): string {
  if (isBlank(hour) && isBlank(minute)) {
    return '';
  }
  const formattedHour =
    isNotBlank(hour) && hour !== '0' ? hour.padStart(2, '0') : '12';
  const formattedMinute = isNotBlank(minute) ? minute.padStart(2, '0') : '00';
  return `${formattedHour}:${formattedMinute}`;
}

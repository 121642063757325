import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import { Stack } from '@webMolecules/Stack/Stack';
import { t, tPrint } from '@webInterfaces/I18n';
import { PASSWORD_REGEX } from '@webViews/constant';
import { Input } from '@webMolecules/Input/Input';
export interface CreateAccountProps {
  /**
   * Current email
   */
  email: string;
  password: string;
  /**
   * onSubmit sign in callback
   */
  onSubmit: (
    credentials: {
      email: string;
      currentPassword: string;
      newPassword: string;
    },
    profile: {
      firstName: string;
      lastName: string;
    }
  ) => void;
  /**
   * Form is loading
   */
  loading?: boolean;
}

export const CreateAccount = ({
  email,
  password,
  onSubmit,
  loading = false,
}: CreateAccountProps) => {
  const CreateAccountSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('generic.validation.invalid.email'))
      .required(t('generic.validation.required.email')),
    firstName: Yup.string().required(
      tPrint(
        'generic.validation.required.field',
        t('pages.auth.create_account.placeholder.first_name')
      )
    ),
    lastName: Yup.string().required(
      tPrint(
        'generic.validation.required.field',
        t('pages.auth.create_account.placeholder.last_name')
      )
    ),
    newPassword: Yup.string()
      .required(
        tPrint(
          'generic.validation.required.field',
          t('pages.auth.create_account.placeholder.new_password')
        )
      )
      .matches(PASSWORD_REGEX, t('generic.validation.invalid.password')),
  });

  const initialFormValues = {
    email: email.replace(/\s+/g, '+'),
    currentPassword: password,
    firstName: '',
    lastName: '',
    password: '',
    newPassword: '',
    showPassword: false,
    showCurrentPassword: false,
  };

  return (
    <Formik
      onSubmit={form => {
        onSubmit(
          {
            email: form.email,
            currentPassword: form.currentPassword,
            newPassword: form.newPassword,
          },
          {
            firstName: form.firstName,
            lastName: form.lastName,
          }
        );
      }}
      initialValues={initialFormValues}
      validationSchema={CreateAccountSchema}
    >
      {formProps => (
        <Form>
          <Box margin="none" display="none">
            <Input
              name="username"
              autoComplete="username"
              data-testid="createaccount-username"
              value={email}
              disabled
            />
          </Box>
          <Stack spacing="m" direction="vertical">
            <Box>
              <FormInput
                name="firstName"
                autoComplete="given-name"
                data-testid="createaccount-firstname"
                error={formProps.errors.firstName}
                placeholder={t(
                  'pages.auth.create_account.placeholder.first_name'
                )}
                size="large"
                maxLength="64"
                fluid
                touched={formProps.touched.firstName}
                type={FormInputTypes.Text}
              />
              <ErrorMessage
                name="firstName"
                render={msg => (
                  <FormFieldHint intent="error" marginTop="xs">
                    {msg}
                  </FormFieldHint>
                )}
              />
            </Box>
            <Box>
              <FormInput
                name="lastName"
                autoComplete="family-name"
                data-testid="createaccount-lastname"
                error={formProps.errors.lastName}
                placeholder={t(
                  'pages.auth.create_account.placeholder.last_name'
                )}
                size="large"
                maxLength="64"
                fluid
                touched={formProps.touched.lastName}
                type={FormInputTypes.Text}
              />
              <ErrorMessage
                name="lastName"
                render={msg => (
                  <FormFieldHint intent="error" marginTop="xs">
                    {msg}
                  </FormFieldHint>
                )}
              />
            </Box>
            <Box>
              <FormInput
                name="newPassword"
                autoComplete="new-password"
                data-testid="createaccount-newpassword"
                error={formProps.errors.newPassword}
                fluid
                placeholder={t(
                  'pages.auth.create_account.placeholder.new_password'
                )}
                size="large"
                touched={formProps.touched.newPassword}
                type={
                  formProps.values.showPassword
                    ? FormInputTypes.Text
                    : FormInputTypes.Password
                }
              />
              {formProps.errors.newPassword && formProps.touched.newPassword ? (
                <ErrorMessage
                  render={msg => (
                    <FormFieldHint intent="error" marginTop="xs">
                      {msg}
                    </FormFieldHint>
                  )}
                  name="newPassword"
                />
              ) : (
                <FormFieldHint intent="neutral" marginTop="xs">
                  {t('generic.validation.invalid.password')}
                </FormFieldHint>
              )}
            </Box>
            <FormInput name="showPassword" type={FormInputTypes.Checkbox}>
              {t('pages.auth.create_account.show_password')}
            </FormInput>
            <Button
              loading={loading}
              type="submit"
              data-testid="createaccount-submit"
              intent="primary"
              strong
              fluid
              size="large"
            >
              {t('pages.auth.create_account.submit')}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { makeFilterTitleFromFindingId } from '@webViewModels/Pages/Study/GalleryHelper';
import { getTotalFilters } from '@webViewModels/Pages/Study/Gallery';
import { GalleryFilter, defaultGalleryFilter } from '@interfaces/GalleryFilter';
import { selectFindings } from '@selectors/FindingSelectors';
import styles from './gallery.scss';

interface GalleryFilterPillsProps {
  filters: GalleryFilter;
  handleClearFilter: (filter: string) => void;
  setGalleryFilter: React.Dispatch<React.SetStateAction<GalleryFilter>>;
  thumbnailsNum: number;
}

export const GalleryFilterPills = (props: GalleryFilterPillsProps) => {
  const { filters, handleClearFilter, setGalleryFilter, thumbnailsNum } = props;

  const findings = useSelector(selectFindings);

  return (
    <Box display="flex" gap="s">
      {filters.findingIds.map(filter => (
        <FilterPill
          key={`filter-${filter}`}
          title={
            makeFilterTitleFromFindingId(findings, filter) +
            ` (${thumbnailsNum})`
          }
          onClick={() => handleClearFilter(filter)}
        />
      ))}
      {getTotalFilters(filters) > 1 && (
        <FilterPill
          title={t('pages.study.gallery.controls.clear_all_filters')}
          onClick={() => setGalleryFilter(defaultGalleryFilter)}
        />
      )}
    </Box>
  );
};

interface GalleryFilterPillProps {
  title: string;
  onClick: () => void;
}

export const FilterPill = (props: GalleryFilterPillProps) => {
  const { title, onClick } = props;

  if (!title) return null;

  return (
    <Box
      className={styles.filterPill}
      display="inline-flex"
      alignItems="center"
    >
      <Box className={styles.filterPillLabel} paddingLeft="s" paddingRight="xs">
        <Text data-testid="test-filterPill" display="block" type="body2">
          {title}
        </Text>
      </Box>
      <Box
        data-testid="test-filterPill-close"
        onClick={onClick}
        role="button"
        className={styles.filterPillBtn}
        paddingY="xs"
        paddingLeft="xs"
        paddingRight="s"
      >
        <Icon name="x" size="small" />
      </Box>
    </Box>
  );
};

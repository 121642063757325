import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Input } from '@webMolecules/Input/Input';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { Text } from '@webMolecules/Text/Text';
import { ResultTableSizeConfig } from '@config/ResultTable/ResultTable';
import { Box } from '@webMolecules/Box/Box';
import {
  AnyUnit,
  DistanceUnit,
  getUnitI18nKey,
  VolumeUnit,
} from '@entities/Unit';
import { Finding } from '@entities/Finding';
import { computeFindingVolume } from '@webViews/Pages/Study/helper';
import { formatVolume } from '@selectors/FindingSelectors';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { selectDetections } from '@selectors/DicomSelectors';
import { selectConfig } from '@selectors/BaseSelectors';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { generateSizeOptionsFromDetections } from '../FindingsTable/Shared';
import { ResultTableSuffix } from './Shared/Suffix';
import { NumberUnitField, useNumberUnitField } from './Number';

export interface ResultTableCellFindingSizeProps
  extends ResultTableSharedProps {
  config?: ResultTableSizeConfig;
  disabled?: boolean;
  unit?: AnyUnit;
  unitI18NKey?: string;
}

export const ResultTableCellFindingSize: React.FC<
  ResultTableCellFindingSizeProps
> = ({
  path,
  config,
  disabled,
  unit: targetUnit = DistanceUnit.MM,
  unitI18NKey,
}) => {
  const { theme } = React.useContext(ResultTableContext);

  const fields = {
    width: useNumberUnitField({
      path: `${path}.width`,
      targetUnit,
    }),
    height: useNumberUnitField({
      path: `${path}.height`,
      targetUnit,
    }),
    length: useNumberUnitField({
      path: `${path}.length`,
      targetUnit,
    }),
  };

  const showVolume = !!config?.showVolume;
  const volume = useCalculatedVolume(path ?? '');

  const dimensions = filterFieldsByConfig(fields, config);

  if (theme == ResultTableStyle.preview) {
    const previewValue = dimensions
      .map(d => d.displayValue)
      .filter(v => v != undefined)
      .join(' x ');
    return Boolean(previewValue) ? (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Text type="body2" data-testid="result-table-number-preview" muted>
          {previewValue}
        </Text>
        <ResultTableSuffix unitI18NKey={unitI18NKey} wrapper={false} />
      </Box>
    ) : null;
  }

  const TimesSpacer = () => <Box padding="xxs">&times;</Box>;

  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      {dimensions.map((field, i) => {
        return (
          <React.Fragment key={i}>
            <Box display="inline-block" style={{ width: '50px' }}>
              <FindingSizeInput
                field={field}
                disabled={disabled}
                readOnly={config?.readOnly}
                unit={targetUnit}
                showSizeOptions={config?.showSizeOptions}
              />
            </Box>
            {i < dimensions.length - 1 && <TimesSpacer />}
          </React.Fragment>
        );
      })}
      <ResultTableSuffix unitI18NKey={unitI18NKey} />
      {showVolume && volume && (
        <>
          <Box display="flex" paddingX="s">
            <Text type="body2" muted>
              |
            </Text>
          </Box>
          <Text type="body2">{volume.value}</Text>
          <ResultTableSuffix unitI18NKey={getUnitI18nKey(VolumeUnit.ML)} />
        </>
      )}
    </Box>
  );
};

const filterFieldsByConfig = <T extends object>(
  fields: { width: T; height: T; length: T },
  config?: ResultTableSizeConfig
): T[] => {
  const dimensions: T[] = [];
  if (config?.showWidth !== false) dimensions.push(fields.width);
  if (config?.showHeight !== false) dimensions.push(fields.height);
  if (config?.showLength !== false) dimensions.push(fields.length);
  return dimensions;
};

const useCalculatedVolume = (path: string) => {
  const { field } = useController({
    name: path,
  });
  const sizes = field.value as Finding['sizes'];
  return formatVolume(computeFindingVolume(sizes) ?? null);
};

interface FindingSizeInputProps {
  field: NumberUnitField;
  disabled?: boolean;
  readOnly?: boolean;
  unit: AnyUnit;
  showSizeOptions?: boolean;
}

const FindingSizeInput: React.FC<FindingSizeInputProps> = ({
  field,
  disabled,
  readOnly,
  unit,
  showSizeOptions,
}) => {
  const { autosizing } = useSelector(selectConfig);
  const detections = useSelector(selectDetections);
  const { getValues } = useFormContext<Finding>();
  const findingId = getValues().id;

  if (autosizing && showSizeOptions) {
    const options = generateSizeOptionsFromDetections(
      detections,
      findingId,
      unit
    );

    const onChange = (value: string) => {
      // Match the value to the corresponding size option
      const selectedOption = value
        ? options.find(o => o.value === value)
        : undefined;

      field.onStringChange(value, selectedOption?.id);
    };

    const onInputChange = (value: string) => {
      if (!value && field.referenceId) {
        return; // Don't clear a referenced option if done is clicked
      }
      field.onStringChange(value);
    };

    return (
      <MenuSelect
        disabled={disabled}
        fluid
        size="small"
        value={field.displayValue?.toString()}
        onChange={onChange}
        onInputChange={onInputChange}
        showInput={true}
        options={options}
      />
    );
  } else {
    return (
      <Input
        data-testid="result-table-number-editable"
        disabled={disabled}
        readOnly={readOnly}
        fluid
        size="small"
        defaultValue={field.displayValue}
        onChange={field.onChange}
        onBlur={field.onBlur}
        onKeyDown={field.onKeyDown}
      />
    );
  }
};

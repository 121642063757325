import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './NavTab.scss';

interface NavTabProps {
  index: number;
  current: boolean;
  onClick: () => void;
  finalTab?: boolean;
}

export const NavTab: React.FC<NavTabProps> = ({
  children,
  index,
  current,
  onClick,
  finalTab,
}) => {
  return (
    <button type="button" onClick={onClick}>
      <Box
        display="flex"
        alignItems="stretch"
        className={cnames(styles.navTab, { [styles.current]: current })}
      >
        {ArrowTail}
        <Box
          display="flex"
          paddingLeft="xxs"
          alignItems="center"
          className={styles.expandingContainer}
          gap="xs"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text type="display6">{index}</Text>
          </Box>
          <Text type="display5">{children}</Text>
        </Box>
        {finalTab ? ArrowFinal : ArrowHead}
      </Box>
    </button>
  );
};

const ArrowTail = (
  <svg
    width="14"
    height="40"
    viewBox="0 0 14 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.723608 1.44722C0.391157 0.782314 0.874652 0 1.61803 0H14V40H1.61803C0.874652 40 0.391156 39.2177 0.723607 38.5528L9.55279 20.8944C9.83431 20.3314 9.83431 19.6686 9.55279 19.1056L0.723608 1.44722Z"
      fill="var(--nav-fill-color)"
    />
    <path
      d="M14 0.5H1.61026C1.23905 0.5 0.997278 0.890242 1.16256 1.22263L10.0573 19.1098C10.3361 19.6706 10.3362 20.3295 10.0576 20.8903L1.16999 38.7775C1.00484 39.1099 1.24662 39.5 1.61777 39.5H14"
      stroke="currentColor"
    />
  </svg>
);

const ArrowHead = (
  <svg
    width="14"
    height="40"
    viewBox="0 0 14 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H1.52786C3.04295 0 4.428 0.85601 5.10557 2.21115L13.5528 19.1056C13.8343 19.6686 13.8343 20.3314 13.5528 20.8944L5.10557 37.7889C4.42801 39.144 3.04295 40 1.52786 40H0V0Z"
      fill="var(--nav-fill-color)"
    />
    <path
      d="M0 0.5H1.50579C2.82684 0.5 4.03534 1.24382 4.63049 2.42323L13.0453 19.099C13.3313 19.6656 13.3313 20.3344 13.0453 20.901L4.63049 37.5768C4.03534 38.7562 2.82684 39.5 1.50578 39.5H0"
      stroke="currentColor"
    />
  </svg>
);

const ArrowFinal = (
  <svg
    width="14"
    height="40"
    viewBox="0 0 14 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H10C12.2091 0 14 1.79086 14 4V20V36C14 38.2091 12.2091 40 10 40H0V0Z"
      fill="var(--nav-fill-color)"
    />
    <path
      d="M0 0.5H10C11.933 0.5 13.5 2.067 13.5 4V20V36C13.5 37.933 11.933 39.5 10 39.5H0"
      stroke="currentColor"
    />
  </svg>
);

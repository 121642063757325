import {
  InstitutionLineItem,
  InstitutionRegion,
} from '@entities/Admin/InstitutionLineItem';
import { Institution } from '@entities/Admin/Institution';
import { Profile } from '@interfaces/Profile';

export interface Institutions {
  List(): Promise<InstitutionLineItem[]>;
  Get(id: string): Promise<Institution>;
  Create(args: {
    id: string;
    institutionCopyText: string;
    region: InstitutionRegion;
  }): Promise<string>;
  CreateUser(institutionId: string, userLineItem: Profile): Promise<'success'>;
  DeleteUser(institutionId: string, id: string): Promise<'success'>;
}

export const AWS_REGION_DIRECTORY: Record<InstitutionRegion, string> = {
  'us-east-2': 'US East (Ohio)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ca-central-1': 'Canada (Central)',
} as const;

export const AWS_REGION_KEYS = Object.keys(
  AWS_REGION_DIRECTORY
) as InstitutionRegion[];

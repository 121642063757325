import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import {
  MenuSelect,
  MenuSelectProps,
} from '@webMolecules/MenuSelect/MenuSelect';
import { Text } from '@webMolecules/LineItem/Cell/Text';

export interface CellSelectProps extends MenuSelectProps {
  label?: string;
}

export const Select: React.FC<CellSelectProps> = ({ label, ...rest }) =>
  label ? (
    <Box marginRight="s" display="flex" alignItems="center">
      <Text muted type="caption" marginRight="s">
        {label}
      </Text>
      <Box flex="1">
        <MenuSelect size="small" {...rest} />
      </Box>
    </Box>
  ) : (
    <Box marginRight="s">
      <MenuSelect size="small" {...rest} />
    </Box>
  );

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Text } from '@webMolecules/Text/Text';
import { Pill } from '@webMolecules/Pill/Pill';
import { getObservationsForSummaryTab } from '@webViewModels/Pages/Study/SummaryTabHelper';
import { selectThyroidGeneralCharacteristics } from '@selectors/SessionSelectors';

export interface Observation {
  title: string;
  volume?: string;
  size: string;
  characteristics: string[];
}

export interface InlineFeature {
  title: string;
  value: string;
}

interface SummaryObservationProps {}

const SummaryObservation: React.FC<SummaryObservationProps> = ({}) => {
  const thyroidGeneralCharacteristics = useSelector(
    selectThyroidGeneralCharacteristics
  );
  if (!thyroidGeneralCharacteristics) {
    return null;
  }
  const { observations, inlineFeatures } = getObservationsForSummaryTab(
    thyroidGeneralCharacteristics
  );

  return (
    <Box display="flex" flexDirection="column" gap="l">
      <Box display="flex" gap="m">
        {observations.map((observations, index) => {
          return (
            <Box
              key={'observation' + index}
              display="flex"
              flexDirection="column"
              flex={observations.volume ? '4' : '1'}
              gap="s"
            >
              <Text type="display3">{observations.title}</Text>

              <Card flex="1">
                <Box data-testid="summary-value">
                  {observations.volume && (
                    <>
                      <Text type="display3">{observations.volume}</Text>
                      <Text muted> | </Text>
                    </>
                  )}
                  <Text type="display4">{observations.size}</Text>
                  <Box marginTop="s">
                    {observations.characteristics.map((feature, i) => {
                      return <Pill key={i} content={feature}></Pill>;
                    })}
                  </Box>
                </Box>
              </Card>
            </Box>
          );
        })}
      </Box>

      <Box display="flex" flexDirection="row" gap="s">
        {inlineFeatures.map((feature, index) => {
          return (
            <Box key={'inlineFeature' + index} display="flex" gap="s">
              <Box flex="1">
                <Text type="body2" lineClamp={1}>
                  <strong>{feature.title}: </strong>
                </Text>
              </Box>

              <Text type="body2"> {feature.value}</Text>
              {index !== inlineFeatures.length - 1 && <Text muted> | </Text>}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SummaryObservation;

import { SessionLineItem, PatientHealthInfo } from '@entities/SessionLineItem';
import { stringFallback } from '@helpers/string';

export const getTitle = (
  session?: SessionLineItem,
  decryptedPhi?: PatientHealthInfo
) => {
  const accessionNumber = stringFallback(session?.accessionNumber, ' ');
  const patientName = stringFallback(session?.patientName, accessionNumber);
  const title = stringFallback(decryptedPhi?.patient_name, patientName);
  return title;
};

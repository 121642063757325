import createClient, { MaybeOptionalInit, Middleware } from 'openapi-fetch';
import { Auth } from 'aws-amplify';
import { PathsWithMethod } from 'openapi-typescript-helpers';
import { apiBaseUrl } from '@helpers/env';
import type { components, paths } from './generated';

export type schemas = components['schemas'];

export const client = createClient<paths>({ baseUrl: apiBaseUrl });
export const anonClient = createClient<paths>({ baseUrl: apiBaseUrl });

const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    throw e;
  }
};

const authMiddleware: Middleware = {
  async onRequest(req) {
    const token = await getToken();
    req.headers.set('Authorization', `Bearer ${token}`);
    return req;
  },
};

const errorMiddleware: Middleware = {
  async onResponse(res) {
    if (!res.ok) {
      const isAuthError = res.status === 401;
      const errorText = await res.text();
      throw new ApiError(
        `API error: ${res.statusText}\n${errorText}`,
        isAuthError
      );
    }
    return res;
  },
};

client.use(authMiddleware);
client.use(errorMiddleware);

// Anon client doesn't use auth middleware
anonClient.use(errorMiddleware);

export async function post<P extends PathsWithMethod<paths, 'post'>>(
  url: P,
  request: MaybeOptionalInit<paths[P], 'post'>,
  anon = false
) {
  try {
    const result = await (anon ? anonClient : client).POST(url, request);
    if (!result.data) {
      throw new ApiError('API error: No data in response', false);
    }
    return result.data;
  } catch (e) {
    if (e instanceof ApiError) {
      throw e;
    } else if (e instanceof Error) {
      throw new ApiError(`Unexpected API error: ${e.message}`, false);
    } else {
      throw e;
    }
  }
}

/**
 * Post without expecting data in the response
 */
export async function postOnly<P extends PathsWithMethod<paths, 'post'>>(
  url: P,
  request: MaybeOptionalInit<paths[P], 'post'>,
  anon = false
) {
  try {
    await (anon ? anonClient : client).POST(url, request);
  } catch (e) {
    if (e instanceof ApiError) {
      throw e;
    } else if (e instanceof Error) {
      throw new ApiError(`Unexpected API error: ${e.message}`, false);
    } else {
      throw e;
    }
  }
}

export class ApiError extends Error {
  public readonly isAuthError: boolean;

  constructor(message: string, isAuthError: boolean) {
    super(message);
    this.name = 'ApiError';
    this.isAuthError = isAuthError;
  }
}

import { v4 as uuid } from 'uuid';
import { FilterConfiguration as ContractFilterConfiguration } from '@contracts/Admin/FilterConfiguration';
import { FilterConfigurationLineItem } from '@entities/Admin/FilterConfigurationLineItem';
import * as E from '@entities/FilterConfiguration';
import { FilterConfiguration as FC } from '@entities/FilterConfiguration';
import { filterOperatorToString } from '@interfaces/FilterConfiguration';
import { post } from '@api/schema/client';
import { decode as decodeFilterConfiguration } from '../decoders/FilterConfiguration';

export class FilterConfiguration implements ContractFilterConfiguration {
  async List(): Promise<FilterConfigurationLineItem[]> {
    const data = await post('/list-filter-configuration', {
      body: {},
    });

    const filterConfigurations: FC[] = data.filter_configuration_list.map(
      item => decodeFilterConfiguration(item)
    );

    // Sort objects using two properties
    const filterConfigurationLineItems: FilterConfigurationLineItem[] =
      filterConfigurations
        .map(item => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            propertiesCount: item.properties.length,
            isCustomWorkspace: item.isCustomWorkspace,
          } as FilterConfigurationLineItem;
        })
        .sort((a, b) => {
          const order =
            a.propertiesCount > b.propertiesCount
              ? 1
              : a.propertiesCount === b.propertiesCount
                ? 0
                : -1;
          return order === 0 ? a.name.localeCompare(b.name) : order;
        });
    return filterConfigurationLineItems;
  }

  async Get(id: string): Promise<E.FilterConfiguration> {
    const data = await post('/admin/get-filter-configuration', {
      body: {
        filter_configuration_id: id,
      },
    });

    const filterConfigurationLineItem = decodeFilterConfiguration(data);
    return Promise.resolve(filterConfigurationLineItem);
  }

  async Create(filterConfiguration: E.FilterConfiguration): Promise<string> {
    const filterConfigurationId = uuid();
    await this.CreateFilterConfiguration(
      filterConfigurationId,
      filterConfiguration
    );
    const filterConfigurationPropertiesFlattened =
      this.flattenProperties(filterConfiguration);

    for (const fp of filterConfigurationPropertiesFlattened) {
      await this.CreateFilterProperty(filterConfigurationId, fp);
    }

    return 'success';
  }

  async Update(
    prevFilterConfiguration: E.FilterConfiguration,
    newFilterConfiguration: E.FilterConfiguration
  ): Promise<'success'> {
    const prevFilterConfigurationPropertiesFlattened = this.flattenProperties(
      prevFilterConfiguration
    );
    const newFilterConfigurationPropertiesFlattened = this.flattenProperties(
      newFilterConfiguration
    );

    const prevPropIds = prevFilterConfigurationPropertiesFlattened.map(
      ({ id }) => id
    );
    const newPropIds = newFilterConfigurationPropertiesFlattened.map(
      ({ id }) => id
    );
    const propsToDestroy = prevPropIds.filter(id => !newPropIds.includes(id));

    await Promise.all(
      propsToDestroy.map(id => this.DeleteFilterProperty(id as string))
    );

    await this.UpdateFilterConfiguration(newFilterConfiguration);

    for (const fp of newFilterConfigurationPropertiesFlattened) {
      if (fp.id) {
        await this.UpdateFilterProperty(fp);
      } else {
        await this.CreateFilterProperty(
          newFilterConfiguration.id as string,
          fp
        );
      }
    }
    return 'success';
  }

  async Destroy(filter_configuration_id: string): Promise<'success'> {
    await post('/admin/delete-filter-configuration', {
      body: {
        filter_configuration_id,
      },
    });

    return 'success';
  }

  private async CreateFilterConfiguration(
    filter_configuration_id: string,
    filterConfiguration: E.FilterConfiguration
  ) {
    await post('/admin/create-filter-configuration', {
      body: {
        filter_configuration_id,
        display_name: filterConfiguration.name,
        description: filterConfiguration.description,
        is_custom_workspace: filterConfiguration.isCustomWorkspace,
      },
    });
  }

  private async UpdateFilterConfiguration(
    filterConfiguration: E.FilterConfiguration
  ) {
    await post('/admin/update-filter-configuration', {
      body: {
        filter_configuration_id: filterConfiguration.id || '',
        display_name: filterConfiguration.name,
        description: filterConfiguration.description,
        is_custom_workspace: filterConfiguration.isCustomWorkspace,
      },
    });
  }

  private async CreateFilterProperty(
    filterConfigurationId: string,
    filterProperty: E.FilterConfigurationProperty
  ) {
    await post('/admin/create-filter-property', {
      body: {
        filter_configuration_id: filterConfigurationId,
        parent_id: filterProperty.parentId ?? '',
        db_column: filterProperty.column,
        operator: filterOperatorToString(filterProperty.operator),
        value: filterProperty.value,
      },
    });
  }

  private async UpdateFilterProperty(
    filterProperty: E.FilterConfigurationProperty
  ) {
    await post('/admin/update-filter-property', {
      body: {
        filter_property_id: filterProperty.id || '',
        db_column: filterProperty.column,
        operator: filterOperatorToString(filterProperty.operator),
        value: filterProperty.value,
      },
    });
  }

  private async DeleteFilterProperty(filter_property_id: string) {
    await post('/admin/delete-filter-property', {
      body: {
        filter_property_id,
      },
    });
  }

  private flattenProperties = (
    fc: E.FilterConfiguration
  ): E.FilterConfigurationProperty[] =>
    fc.properties.reduce(
      (acc, p) => [
        ...acc,
        p,
        ...(p.childProperties || []).reduce(
          (accB, pB) => [...accB, { ...pB, parentId: p.id || p._tmpId }],
          [] as E.FilterConfigurationProperty[]
        ),
      ],
      [] as E.FilterConfigurationProperty[]
    );
}

import * as Contract from '@contracts/PACSStudy';
import { PACSStudyLineItem } from '@interfaces/PACSStudyLineItem';
import { post } from '@api/schema/client';
import {
  decode as decodePACSStudyLineItem,
  getFilteredPACSStudies,
} from './decoders/PACSStudyLineItem';

export class PACSStudy implements Contract.PACSStudy {
  async List() {
    const data = await post('/polling/pacs-studies', {});

    const totalPACSStudyLineItems: PACSStudyLineItem[] = data.studies
      .map(decodePACSStudyLineItem)
      .sort((a, b) => {
        // Sort the most recent studies on top
        return Number(b.date) - Number(a.date);
      });

    const PACSStudyLineItems = getFilteredPACSStudies(totalPACSStudyLineItems);

    const totalItems = data.studies.length;

    return {
      PACSStudyLineItems,
      totalItems,
    };
  }
}

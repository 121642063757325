/* eslint-disable no-console */
import { log, LogLevel } from '@helpers/log';
import { formatTime } from '@helpers/datetime';

export const withLabeledTimer = async <T>(
  f: () => Promise<T>,
  label: string,
  callback?: (label: string, executionTime: number) => void
): Promise<T> => {
  const startTime = new Date();

  const logLevel = ((label: string): LogLevel => {
    if (label.startsWith('api')) {
      return 'api';
    } else if (label.startsWith('effect')) {
      return 'effect';
    }
    return 'system';
  })(label);

  log(logLevel, `${label}--${formatTime(startTime)}`);

  const result = await f();

  if (callback) {
    const executionTime = new Date().getTime() - startTime.getTime();
    log(logLevel, `${label}--${formatTime(executionTime)}`);
    callback(label, executionTime);
  }

  return result;
};

import Maybe, { nothing } from '@monads/Maybe';
import * as App from '@entities/App';
import * as Authentication from '@entities/Authentication';
import * as Message from '@entities/Message';
import * as I18n from './I18n';

export interface Root {
  i18n: I18n.I18n;
  auth: Authentication.Authentication;
  messages: Message.Messages;
  app: Maybe<App.App>;
}

export const initial = (): Root => ({
  i18n: I18n.initial(),
  auth: Authentication.initial(),
  messages: Message.initial(),
  app: nothing(),
});

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Dropdown, DropdownItem } from '@webMolecules/Dropdown/Dropdown';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import { FilterOptions } from '@interfaces/FilterOptions';
import { t } from '@webInterfaces/I18n';
import { useDispatcher } from '@helpers/useDispatcher';
import { locationUseCase } from '@useCases/Location';
import * as RouteHelper from '@helpers/routes';
import { toString as workspaceGroupToString } from '@interfaces/WorkspaceGroup';
import {
  eventWorklistSwitchedViewToActive,
  eventWorklistSwitchedViewToBookmarked,
  eventWorklistSwitchedViewToCompleted,
} from '@webInterfaces/Analytics';
import { sessionStatusArrayToString } from '@interfaces/SessionLineItem';

interface StatusFilterProps {
  activeFilters: FilterOptions;
  isBookmarkEnabled: boolean;
}

interface StatusFilterOption {
  label: string;
  status: string;
  hide?: boolean;
}

export const StatusFilter: React.FC<StatusFilterProps> = ({
  activeFilters,
  isBookmarkEnabled,
}) => {
  const dispatchUpdateLocation = useDispatcher(locationUseCase.Update);

  const options: StatusFilterOption[] = [
    {
      label: 'active',
      status: 'active',
    },
    {
      label: 'completed',
      status: 'completed',
    },
    {
      label: 'bookmarked',
      status: 'bookmarked',
      hide: !isBookmarkEnabled,
    },
  ];

  const activeStatus = sessionStatusArrayToString(
    activeFilters.status,
    activeFilters.isBookmarked
  );
  const activeOption: StatusFilterOption =
    options.find(o => o.status === activeStatus) ?? options[0];

  const changeStatus = (status: string) => {
    if (activeOption.status !== status) {
      if (status === 'bookmarked') {
        eventWorklistSwitchedViewToBookmarked();
      } else if (status === 'completed') {
        eventWorklistSwitchedViewToCompleted();
      } else {
        eventWorklistSwitchedViewToActive();
      }
    }
    dispatchUpdateLocation({
      pathname: RouteHelper.worklist(
        workspaceGroupToString(activeFilters.group),
        activeFilters.id,
        status
      ),
    });
  };

  const trigger = (
    <Button
      data-testid="test-worklist-filter"
      size="small"
      iconAfter={<Icon name="chevron-down-small" />}
      strong
      pill
    >
      {t(`pages.worklist.filter.status.${activeOption.label}`)}
    </Button>
  );
  return (
    <Dropdown trigger={trigger}>
      {options
        .filter(o => !o.hide)
        .map(option => (
          <DropdownItem
            key={option.label}
            onSelect={() => changeStatus(option.status)}
            condensed
          >
            <Box
              data-testid={`test-${option.label}`}
              display="flex"
              alignItems="center"
            >
              <Text marginX="xs" display="block" type="body2">
                {t(`pages.worklist.filter.status.${option.label}`)}
              </Text>
            </Box>
          </DropdownItem>
        ))}
    </Dropdown>
  );
};

import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';

import {
  ICanvasBreastFinding,
  ICanvasItem,
  ICanvasThyroidFinding,
  CanvasObjectType,
  ICanvasTextObject,
  ICanvasSymbolObject,
  ICanvasFreeDrawingObject,
} from '@appCanvas/interfaces/types';
import {
  CALCULATE_TEMPLATE_HEIGHT_RATIO,
  DEFAULT_BREAST_FINDING_CLOCK_POSITION_HOUR,
  DEFAULT_BREAST_FINDING_CLOCK_POSITION_MIN,
  DEFAULT_BREAST_FINDING_DISTANCE_FN_IN_MM,
  DEFAULT_BREAST_FINDING_POSITION,
  DEFAULT_BREAST_FINDING_WIDTH_IN_MM,
  DEFAULT_THYROID_FINDING_WIDTH_IN_MM,
  DistanceUnitInCanvas,
  INITIAL_LANDSCAPE_CANVAS_HEIGHT,
  INITIAL_LANDSCAPE_CANVAS_WIDTH,
  LANDSCAPE_CANVAS_WIDTH_HEIGHT_RATIO,
  NODULE_SHAPE_WIDER_THAN_TALL_RATIO,
  SONOGRAPHER_THYROID_CANVAS_WIDTH_HEIGHT_RATIO,
} from '@appCanvas/config/constants';
import {
  BreastFinding,
  Finding,
  FindingSizes,
  FindingType,
  Size,
  ThyroidFinding,
} from '@entities/Finding';
import { DistanceUnit, getUnitI18nKey } from '@entities/Unit';
import { Measurements } from '@appCanvas/interfaces/types/Measurements.type';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { ThyroidObservationsConfig } from '@config/SummaryTab/Thyroid';
import { t } from '@webInterfaces/I18n';
import { CanvasObject } from '@entities/CanvasObject';
import {
  BreastFindingEchoPattern,
  BreastFindingMargin,
  BreastFindingOrientation,
  BreastFindingShape,
  BreastFindingSpecialCase,
  ThyroidFindingComposition,
  ThyroidFindingEchogenicity,
  ThyroidFindingShape,
} from '@entities/Characteristics';

type FindingSize = {
  max: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
};

export const calcCanvasWidthAndHeight = (
  canvasContainer: HTMLDivElement | null,
  useCanvasLargeRatio: boolean
) => {
  const ratio = useCanvasLargeRatio
    ? SONOGRAPHER_THYROID_CANVAS_WIDTH_HEIGHT_RATIO
    : LANDSCAPE_CANVAS_WIDTH_HEIGHT_RATIO;

  if (canvasContainer) {
    const cs = getComputedStyle(canvasContainer);

    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);

    // border width for bordered class in reportCanvas.scss
    const borderWidth = 2;
    const width = (canvasContainer?.clientWidth || 0) - paddingX - borderWidth;
    const height = width * ratio;
    return [width, height];
  }

  return [INITIAL_LANDSCAPE_CANVAS_WIDTH, INITIAL_LANDSCAPE_CANVAS_HEIGHT];
};

export const getCanvasObjectsFromFindings = (
  findings: Finding[],
  size?: Size,
  isPreviousCanvas = false
): ICanvasItem[] => {
  const findingCanvasItems = findings
    .map(finding => {
      let findingObject = null;

      if (finding.type === FindingType.Thyroid) {
        findingObject = getCanvasThyroidFinding(finding);

        if (size) {
          let value = size.value;
          if (size.unit !== DistanceUnitInCanvas) {
            value = convertUnit(size.unit, DistanceUnitInCanvas, size.value);
          }
          findingObject.maxLobeHeight = value;
        }
      } else {
        findingObject = getCanvasBreastFinding(finding);
      }

      const index =
        isPreviousCanvas && finding.index === 0
          ? undefined
          : finding.index.toString();

      findingObject.label = index;
      findingObject.isPrevious = isPreviousCanvas;

      return findingObject;
    })
    .filter(Boolean);

  // sort findings by width
  return findingCanvasItems.sort((a, b) => {
    return b.width - a.width;
  });
};

const getCanvasThyroidFinding = (
  finding: ThyroidFinding,
  maxLobeHeight?: number
): ICanvasThyroidFinding => {
  const findingObject = {
    id: finding.id,
    type: CanvasObjectType.ThyroidFinding,
    width: maxLobeHeight
      ? CALCULATE_TEMPLATE_HEIGHT_RATIO * maxLobeHeight
      : DEFAULT_THYROID_FINDING_WIDTH_IN_MM, // A default width is set to avoid invisible nodule creation
    side: finding.characteristics.side,
    pole: finding.characteristics.pole,
    score: finding.characteristics.scorePoints,
    xCoordinate: finding.canvasPosition?.xCoordinate ?? undefined,
    yCoordinate: finding.canvasPosition?.yCoordinate ?? undefined,
    positionNoiseX: finding.canvasPosition?.positionNoiseX ?? undefined,
    positionNoiseY: finding.canvasPosition?.positionNoiseY ?? undefined,
    cystic: checkIfFindingIsCystic(finding),
    isTallerThanWide:
      finding.characteristics.shape === ThyroidFindingShape.TallerThanWide,
  } as ICanvasThyroidFinding;

  const convertedFindingSize = convertSizesToCanvasUnit(finding.sizes);
  const size_values = [
    convertedFindingSize.max,
    convertedFindingSize.height,
    convertedFindingSize.length,
    convertedFindingSize.width,
  ].filter(Boolean) as number[];

  findingObject.width = size_values.length
    ? Math.max(...size_values)
    : findingObject.width;

  // A default nodule should always be an ellipse i.e. shape COULD BE undefined
  if (finding.characteristics.shape === ThyroidFindingShape.TallerThanWide) {
    findingObject.height = findingObject.width;
  } else {
    findingObject.height =
      findingObject.width / NODULE_SHAPE_WIDER_THAN_TALL_RATIO;
  }

  if (finding.canvasPosition) {
    const { customWidth, customHeight } = finding.canvasPosition;
    if (customWidth) findingObject.width = customWidth;
    if (customHeight) findingObject.height = customHeight;
    if (customHeight || customWidth) {
      findingObject.isCustomSize = true;
    }
  }

  return findingObject;
};

const getCanvasBreastFinding = (
  finding: BreastFinding
): ICanvasBreastFinding => {
  const findingObject = {
    id: finding.id,
    type: CanvasObjectType.BreastFinding,
    side: finding.characteristics.side,
    width: DEFAULT_BREAST_FINDING_WIDTH_IN_MM,
    position:
      finding.characteristics.position ?? DEFAULT_BREAST_FINDING_POSITION,
    clockPositionHour: finding.characteristics.clockface?.hour
      ? parseInt(finding.characteristics.clockface?.hour)
      : DEFAULT_BREAST_FINDING_CLOCK_POSITION_HOUR,
    clockPositionMin: finding.characteristics.clockface?.minute
      ? parseInt(finding.characteristics.clockface?.minute)
      : DEFAULT_BREAST_FINDING_CLOCK_POSITION_MIN,
    distanceInMM: DEFAULT_BREAST_FINDING_DISTANCE_FN_IN_MM,
    cystic: checkIfFindingIsCystic(finding),
    grading: finding.formatted.grading,
  } as ICanvasBreastFinding;

  if (finding.characteristics.distanceFromNipple?.value != null) {
    findingObject.distanceInMM = convertUnit(
      finding.characteristics.distanceFromNipple.unit,
      DistanceUnitInCanvas,
      finding.characteristics.distanceFromNipple.value
    );
  }

  const convertedFindingSize = convertSizesToCanvasUnit(finding.sizes);

  if (
    finding.characteristics.orientation === BreastFindingOrientation.NotParallel
  ) {
    // render the nodule shape to circle, and the diameter will be the maximum value from the sizes
    const size_values = [
      convertedFindingSize.max,
      convertedFindingSize.height,
      convertedFindingSize.length,
      convertedFindingSize.width,
    ].filter(Boolean) as number[];

    findingObject.width = size_values.length
      ? Math.max(...size_values)
      : findingObject.width;

    findingObject.height = findingObject.width;
  } else {
    findingObject.width = convertedFindingSize.width || findingObject.width;
    findingObject.height = convertedFindingSize.height
      ? convertedFindingSize.height // Oval with variable aspect ratio
      : findingObject.width / NODULE_SHAPE_WIDER_THAN_TALL_RATIO; // Oval with fixed aspect ratio
  }

  if (finding.canvasPosition) {
    const { customWidth, customHeight } = finding.canvasPosition;
    if (customWidth) findingObject.width = customWidth;
    if (customHeight) findingObject.height = customHeight;
  }

  return findingObject;
};

export const convertSizesToCanvasUnit = (sizes: FindingSizes) => {
  return ['width', 'height', 'length', 'max'].reduce((newSize, property) => {
    type FindingSizesKey = keyof FindingSize;
    const field = property as FindingSizesKey;

    newSize[field] = null;

    if (sizes[field]?.unit && sizes[field]?.value != null) {
      newSize[field] = convertUnit(
        sizes[field]?.unit as DistanceUnit,
        DistanceUnitInCanvas,
        sizes[field]?.value as number
      );
    }

    return newSize;
  }, {} as FindingSize);
};

export const checkIfFindingIsCystic = (finding: Finding) => {
  if (finding.type == FindingType.Thyroid) {
    const { echogenicity, composition } = finding.characteristics;

    return (
      echogenicity == ThyroidFindingEchogenicity.Anechoic ||
      composition === ThyroidFindingComposition.CysticOrAlmostCompletelyCystic
    );
  } else if (finding.type == FindingType.Breast) {
    const { echoPattern, margin, shape, specialCase } = finding.characteristics;

    const isAnechoic = echoPattern == BreastFindingEchoPattern.Anechoic;
    const isRoundOrOval =
      shape &&
      [BreastFindingShape.Round, BreastFindingShape.Oval].includes(shape);
    const isCircumscribed = margin == BreastFindingMargin.Circumscribed;

    // TODO: the second condition is redundant when the first condition is met, need to double check with
    // Jad/Shehan about the breast cyst logic
    return (
      isAnechoic ||
      (isAnechoic && isRoundOrOval && isCircumscribed) ||
      specialCase === BreastFindingSpecialCase.SimpleCyst
    );
  }
  return false;
};

export const getMeasurementsFromGeneralCharacteristics = (
  generalCharacteristics?: ThyroidGeneralCharacteristics
): Measurements | undefined => {
  if (!generalCharacteristics) return undefined;

  const [rightLobe, leftLobe] = [
    generalCharacteristics.rightLobe,
    generalCharacteristics.leftLobe,
  ].map(lobe => {
    const displaySizeUnit = ThyroidObservationsConfig.size.unit;
    const displayVolumeUnit = ThyroidObservationsConfig.volume.unit;

    let sizes = '';
    if (displaySizeUnit) {
      const values = [lobe.width, lobe.height, lobe.length].map(size => {
        if (size && size.value) {
          const value = convertUnit(
            size.unit,
            displaySizeUnit,
            size.value
          ) as number;

          return roundValueByUnit(value, displaySizeUnit);
        }
        return null;
      });

      const formattedValue = values.filter(Boolean).join(' x ');
      sizes = `${formattedValue} ${t(getUnitI18nKey(displaySizeUnit))}`;
    }

    let volume = '';
    if (displayVolumeUnit) {
      if (lobe.volume) {
        const value = convertUnit(
          lobe.volume?.unit,
          displayVolumeUnit,
          lobe.volume.value
        );

        const formattedValue = roundValueByUnit(value, displayVolumeUnit);
        volume = `${formattedValue} ${t(getUnitI18nKey(displayVolumeUnit))}`;
      }
    }
    return {
      sizes: sizes,
      volume: volume,
    };
  });

  const displayThicknessUnit = ThyroidObservationsConfig.isthmusThickness.unit;
  let thicknessString = '';

  if (generalCharacteristics.isthmusThickness) {
    const value = convertUnit(
      generalCharacteristics.isthmusThickness.unit,
      displayThicknessUnit,
      generalCharacteristics.isthmusThickness.value
    );

    const formattedValue = roundValueByUnit(value, displayThicknessUnit);
    thicknessString = `${formattedValue} ${t(getUnitI18nKey(displayThicknessUnit))}`;
  }

  return {
    rightLobe,
    leftLobe,
    isthmus: { thickness: thicknessString },
  };
};

export const decodeCanvasObjectsToCanvasItems = (
  canvasObjects: CanvasObject[]
): ICanvasItem[] => {
  return canvasObjects
    .map(canvasObject => {
      switch (canvasObject.key) {
        case CanvasObjectType.CustomText:
          return {
            id: canvasObject.id,
            type: canvasObject.key,
            text: canvasObject.value.drawingData.text,
            left: canvasObject.value.positionConfig.left,
            top: canvasObject.value.positionConfig.top,
            width: canvasObject.value.drawingData.width,
            height: canvasObject.value.drawingData.height,
            canvasOffset: canvasObject.value.positionConfig.canvasOffset,
          } as ICanvasTextObject;
        case CanvasObjectType.Symbol:
          return {
            id: canvasObject.id,
            type: canvasObject.key,
            color: canvasObject.value.drawingData.color,
            symbol: canvasObject.value.drawingData.symbol,
            left: canvasObject.value.positionConfig.left,
            top: canvasObject.value.positionConfig.top,

            scale: canvasObject.value.positionConfig.customXScale,
            canvasOffset: canvasObject.value.positionConfig.canvasOffset,
          } as ICanvasSymbolObject;
        case CanvasObjectType.FreeDrawingPath:
          return {
            id: canvasObject.id,
            type: canvasObject.key,
            path: canvasObject.value.drawingData.path,
            index: canvasObject.value.drawingData.index,
            canvasOffset: canvasObject.value.positionConfig.canvasOffset,
          } as ICanvasFreeDrawingObject;
        default:
          return null;
      }
    })
    .filter(Boolean) as ICanvasItem[];
};

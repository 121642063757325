import React, { useContext, useEffect, useRef, useState } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { motion, useAnimation } from 'framer-motion';
import { Box } from '@webMolecules/Box/Box';
import { DropArea } from '@webMolecules/DropArea/DropArea';
import { t } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { cnames } from '@helpers/cnames';
import styles from './comparison.scss';

export const ItemTypes = {
  FINDING: 'finding',
};

interface FindingItem {
  findingId: string;
}

interface DraggableFindingProps {
  findingId: string;
  children: React.ReactElement;
}

export const DraggableFinding: React.FC<DraggableFindingProps> = ({
  findingId,
  children,
}) => {
  const [isDragHover, setDragHover] = useState(false);
  const [{ isDragging }, drag] = useDrag<
    FindingItem,
    void,
    { isDragging: boolean }
  >(
    () => ({
      type: ItemTypes.FINDING,
      item: { findingId },
      collect: monitor => {
        return {
          isDragging: !!monitor.isDragging(),
        };
      },
    }),
    [findingId]
  );

  const dragRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    drag(dragRef);
  }, []);

  const controls = useAnimation();

  const { dropPosition, resetDropPosition } = useContext(DropAreaContext);

  // After dropping the drag preview element, when the actual element is mounted,
  // set its position to the last registered position of the preview, and then
  // animate back to the origin. This gives the appearance of the dragged element
  // snapping to the drop area.
  useEffect(() => {
    if (dragRef.current && dropPosition) {
      const initial = convertToRelativePosition(dropPosition, dragRef.current);

      controls.set({
        ...initial,
        opacity: 0.5,
      });
      controls
        .start({
          x: 0,
          y: 0,
          opacity: 1,
          transition: { type: 'spring', stiffness: 500, damping: 35 },
        })
        .then(resetDropPosition);
    }
  }, [controls]);

  return (
    <motion.div
      animate={controls}
      className={cnames(styles.draggableFinding, {
        [styles.hover]: isDragHover,
      })}
      style={{
        height: '100%',
        visibility: isDragging ? 'hidden' : 'visible',
      }}
    >
      {!isDragging && (
        <Box className={styles.dragHandle}>
          <Icon name="drag-handle" />
        </Box>
      )}
      {React.cloneElement(children, {
        ref: dragRef,
        onMouseEnter: () => setDragHover(true),
        onMouseLeave: () => setDragHover(false),
      })}
    </motion.div>
  );
};

export interface DropAreaContextProps {
  dropPosition: XYCoord | null;
  resetDropPosition(): void;
}

const DropAreaContext = React.createContext<DropAreaContextProps>({
  dropPosition: null,
  resetDropPosition: () => null,
});

interface ComparisonDropAreaProps {
  showDropBackground?: boolean;
  isEmpty?: boolean;
  onDropFinding(findingId: string): void;
}

export const ComparisonDropArea: React.FC<ComparisonDropAreaProps> = ({
  isEmpty,
  showDropBackground,
  onDropFinding,
  children,
}) => {
  const [dropPosition, setDropPosition] = useState<XYCoord | null>(null);

  const [{ isOver, canDrop }, drop] = useDrop<
    FindingItem,
    void,
    { isOver: boolean; canDrop: boolean }
  >(
    () => ({
      accept: ItemTypes.FINDING,
      drop: (item, monitor) => {
        setDropPosition(monitor.getSourceClientOffset());
        onDropFinding(item.findingId);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDropFinding]
  );

  const dropRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    drop(dropRef);
  }, []);
  return (
    <DropAreaContext.Provider
      value={{
        dropPosition,
        resetDropPosition: () => {
          setDropPosition(null);
        },
      }}
    >
      <Box ref={dropRef} style={{ position: 'relative' }}>
        {children}
        {showDropBackground && (
          <DropArea
            isEmpty={isEmpty}
            iconLabel={t('pages.study.comparison.drop_area.info.content')}
            isOver={isOver}
            icon={<DropAreaIcon />}
            label={
              canDrop
                ? t('pages.study.comparison.drop_finding_here')
                : t('pages.study.comparison.drop_area')
            }
          />
        )}
      </Box>
    </DropAreaContext.Provider>
  );
};

const DropAreaIcon = () => {
  return (
    <Box className={styles.dropAreaIconStyle}>
      <Icon name="info" />
    </Box>
  );
};
function convertToRelativePosition(position: XYCoord, element: HTMLElement) {
  const rect = element.getBoundingClientRect();

  const x = position.x - rect.left;
  const y = position.y - rect.top;

  return { x, y };
}

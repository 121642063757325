import { createSelector } from '@reduxjs/toolkit';
import { Root } from '@entities/Root';
import { Workflow } from '@entities/Workflow';
import { App } from '@entities/App';
import { withDefaultConfigs } from '@entities/InstitutionConfiguration';

export const selectApp = (root: Root): App =>
  root.app.mapOrThrowError(app => app, 'App is not initialised');

export const selectProfile = createSelector(selectApp, app => app.profile);

export const selectProfileConfiguration = createSelector(
  selectProfile,
  profile => profile.configuration
);

export const selectWorkflow = createSelector(
  selectProfileConfiguration,
  config => config.workflow ?? Workflow.Sonographer
);

export const selectWorklist = (root: Root) =>
  root.app.mapOrThrowError(app => app.worklist, 'App not initialised');

export const selectLastWorklistPath = createSelector(
  selectWorklist,
  worklist => worklist.lastWorklistPath ?? '/worklist'
);

export const selectWorklistProfiles = createSelector(selectWorklist, worklist =>
  worklist.profiles.second()
);

export const selectShowBoundingBoxConfidence = createSelector(
  selectApp,
  app => app.debug.showBoundingBoxConfidence
);

export const selectConfig = createSelector(selectApp, app =>
  withDefaultConfigs(app.profile.configuration)
);

export const selectAdmin = createSelector(selectApp, app => app.admin);

export const selectAdminApplication = createSelector(
  selectAdmin,
  admin => admin.adminApplication
);

import { Action } from 'redux';
import Effect, {
  none,
  effect as createEffect,
  batch,
  EffectRunnerEventName,
  batchOrSingle,
} from '@library/Effect';
import Result, { ok, err, Err } from '@monads/Result';
import Tuple, { pair } from '@monads/Tuple';
import Maybe from '@monads/Maybe';

export type ReducerResult<T> = Result<Tuple<T, Effect>>;

type SubReducer<S, C> = {
  f: EffectReducer<C>;
  getState: (state: S) => C;
  toState: (state: S, subState: C) => S;
};

export class EffectReducer<State> {
  private __lastEffect?: Effect;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subReducers: Array<SubReducer<State, any>> = [];

  CancelLastEffect(): void {
    this.__lastEffect?.cancel();
  }

  CreateSubReducer<SubState>(
    subReducer: EffectReducer<SubState>,
    getState: (state: State) => SubState | Maybe<SubState>,
    toState: (state: State, subState: SubState) => State
  ): void {
    this.subReducers.push({ f: subReducer, getState, toState });
  }

  Perform(state: State, _useCase: Action): ReducerResult<State> {
    // This gets overriden by the reducer implementations
    return this.Result(state);
  }

  Result(
    state: State,
    effect?: Effect,
    useCasesOut: Action[] = []
  ): ReducerResult<State> {
    // Convert follow up use cases into effects to lift the unnecessary
    // restrictions around the type of use cases that can be returned
    const useCaseEffects = useCasesOut.map(useCaseToEffect);

    let combinedEffects: Effect | undefined;
    if (effect) {
      combinedEffects =
        useCaseEffects.length > 0 ? batch([...useCaseEffects, effect]) : effect;
    } else if (useCaseEffects.length > 0) {
      combinedEffects = batch(useCaseEffects);
    }

    if (combinedEffects) {
      this.__lastEffect = combinedEffects;
      combinedEffects.on(EffectRunnerEventName.Finished, () => {
        this.__lastEffect = undefined;
      });
    }
    return ok(pair(state, combinedEffects ? combinedEffects : none()));
  }

  SubReduce(state: State, useCase: Action): ReducerResult<State> {
    let resultState = state;
    const resultEffects: Effect[] = [];

    for (const subReducer of this.subReducers) {
      let childState = subReducer.getState(resultState);

      // Unwrap state if within a Maybe
      if (childState instanceof Maybe) {
        if (childState.isJust()) {
          childState = childState.lift();
        } else {
          // Skip if Maybe is nothing
          continue;
        }
      }
      const subReducerResult = subReducer.f.Perform(childState, useCase);
      if (subReducerResult === undefined) {
        continue;
      }

      if (subReducerResult.isError()) {
        return this.Error((subReducerResult as Err).lift());
      }

      subReducerResult.mapOk(value => {
        resultState = subReducer.toState(resultState, value.first());
        resultEffects.push(value.second());
      });
    }

    return this.Result(resultState, batchOrSingle(resultEffects));
  }

  Error(error: Error): ReducerResult<State> {
    return err(error);
  }
}

export const useCaseToEffect = (useCaseOut: Action) =>
  createEffect(() => useCaseOut, 'effect-' + useCaseOut.type);

export default EffectReducer;

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { IndexLabel } from '@webOrganisms/IndexLabel/IndexLabel';
import { ChangeLabel } from '@webMolecules/ChangeLabel/ChangeLabel';
import {
  eventComparisonActionCollapsedFindingMatch,
  eventComparisonActionExpandedFindingMatch,
  eventComparisonActionMatchedFinding,
} from '@webInterfaces/Analytics';
import { cnames } from '@helpers/cnames';
import { GradingChangeLabel } from '@webOrganisms/GradingLabel/GradingLabel';
import { Finding } from '@entities/Finding';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { ClinicalRecommendationTag } from '../FindingsTable/ClinicalRecommendationTag';
import styles from './comparison.scss';
import { ComparisonItem } from './ComparisonItem';
import { ComparisonButton } from './ComparisonButton';
import { ComparisonDropArea, DraggableFinding } from './ComparisonDragAndDrop';

export interface ComparisonRowProps {
  index: number;
  isExpand: boolean;
  finding: Finding;
}
export const ComparisonRow: React.FC<ComparisonRowProps> = ({
  index,
  isExpand,
  finding,
}) => {
  const dispatchAssignComparisonFinding = useDispatcher(
    findingUseCase.AssignComparisonFinding
  );

  const [isOpen, setIsOpen] = React.useState<boolean>(isExpand);
  React.useEffect(() => {
    setIsOpen(isExpand);
  }, [isExpand]);

  const toggleOpen = () => {
    const newValue = !isOpen;

    if (newValue) {
      eventComparisonActionExpandedFindingMatch();
    } else {
      eventComparisonActionCollapsedFindingMatch();
    }

    setIsOpen(newValue);
  };

  const previousGrading = finding.comparedFinding?.formatted.grading;
  const currentGrading = finding.formatted.grading;

  return (
    <Box className={styles.comparisonRow} marginBottom="s">
      <Box
        justifyContent="flex-start"
        alignItems="flex-start"
        padding="xs"
        className={styles.comparisonGutter}
      >
        <IndexLabel index={index} intent="neutral" borderRadius="topLeft" />
      </Box>
      <Box className={styles.innerComparisonRow}>
        <ComparisonDropArea
          isEmpty={!finding.comparedFinding}
          showDropBackground
          onDropFinding={comparisonFindingId => {
            eventComparisonActionMatchedFinding();
            dispatchAssignComparisonFinding({
              findingId: finding.id,
              comparisonFindingId,
            });
          }}
        >
          {finding.comparedFinding && (
            <DraggableFinding
              findingId={finding.comparedFinding.id}
              key={finding.comparedFinding.id}
            >
              <ComparisonItem
                finding={finding.comparedFinding}
                isExpanded={isOpen}
                isDraggable
                isPrevious
                rowIndex={index}
              />
            </DraggableFinding>
          )}
        </ComparisonDropArea>
        <ComparisonButton
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          index={index}
          previousConnectorActive={!!finding.comparedFinding}
          currentConnectorActive
        />
        <ComparisonItem
          finding={finding}
          isExpanded={isOpen}
          rowIndex={index}
        />
      </Box>
      <Box
        gap="xs"
        className={cnames(styles.comparisonGutter)}
        justifyContent="center"
        alignItems="center"
        padding="xs"
      >
        {previousGrading && currentGrading && (
          <GradingChangeLabel
            previousGrading={previousGrading}
            currentGrading={currentGrading}
          />
        )}
        {!finding.comparedFinding && (
          <ChangeLabel
            type="absolute"
            label="NEW"
            previousValue={undefined}
            currentValue={undefined}
          />
        )}

        <ClinicalRecommendationTag finding={finding} />
      </Box>
    </Box>
  );
};

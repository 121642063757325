import React from 'react';
import { connect } from 'react-redux';
import { useDndScrolling } from 'react-dnd-scrolling';
import * as ViewModel from '@webViewModels/Pages/Study/Comparison';
import { Box } from '@webMolecules/Box/Box';
import { ComparisionArea } from '@webOrganisms/ResultTable/Comparison/ComparisonArea';
import { UnassignedFindingSection } from '@webOrganisms/ResultTable/Comparison/UnassignedFindingSection';
import { t } from '@webInterfaces/I18n';
import { Text } from '@webMolecules/Text/Text';
import { ExpandButton } from '@webOrganisms/ExpandButton/ExpandButton';
import { Icon } from '@webMolecules/Icon/Icon';
import { ComparisonDiagramSection } from '@webOrganisms/ResultTable/Comparison/ComparisonDiagram';
import { Divider } from '@webMolecules/Divider/Divider';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import styles from './comparisonLayout.scss';

const ComparisonLayout: React.FC<ViewModel.Comparison> = ({
  sessionId,
  sessionDate,
  comparisonSessionDate,
}) => {
  const [isExpandAll, setIsExpandAll] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  useDndScrolling(ref, {});

  return (
    <MiniCanvasProvider key={sessionId}>
      <Box
        ref={ref}
        scrollable="y"
        className={styles.comparisonPanel}
        padding="m"
        fullWidth
      >
        <ComparisonDiagramSection
          currentDate={sessionDate}
          previousDate={comparisonSessionDate}
        />
        <Divider color="var(--ds-border-color-weak)" />

        <Box className={styles.findingLabel}>
          <Box display="inline-flex" alignItems="center" padding="xs">
            <Text muted type="display5">
              {t(`pages.study.comparison.header.label`)}
            </Text>
          </Box>
          <Box
            className={styles.expandButton}
            display="flex"
            alignItems="center"
            marginBottom="s"
          >
            <ExpandButton
              isOpen={isExpandAll}
              type="horizontal"
              onClick={() => setIsExpandAll(!isExpandAll)}
              iconAfter={
                <Icon
                  size="extraSmall"
                  name={!isExpandAll ? 'expand' : 'collapse'}
                />
              }
            >
              <Text type="display6">
                {isExpandAll
                  ? t(`pages.study.comparison.collapse_all.button`)
                  : t(`pages.study.comparison.expand_all.button`)}
              </Text>
            </ExpandButton>
          </Box>

          {/* {workflow == Workflow.Radiologist && (
            <Box marginLeft="auto" className={styles.undoRedo}>
              <UndoRedoTool />
            </Box>
          )} */}
        </Box>
        <ComparisionArea isExpandAll={isExpandAll} />
        <UnassignedFindingSection isExpanded={isExpandAll} />
      </Box>
    </MiniCanvasProvider>
  );
};

export default connect(ViewModel.fromState)(ComparisonLayout);

import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { t } from '@webInterfaces/I18n';
import { ListDivider } from '@webMolecules/ListDivider/ListDivider';
import { eventFindingActionReorderedFinding } from '@webInterfaces/Analytics';
import { useDispatcher } from '@helpers/useDispatcher';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import {
  selectIncludedFindings,
  selectExcludedFindings,
  selectFindings,
} from '@selectors/FindingSelectors';
import { findingUseCase } from '@useCases/Finding';
import ResultTableContext, { ResultTableStyle } from '../ResultTableContext';
import styles from './styles.scss';
import FindingSummaryRow from './FindingSummaryRow';

interface FindingSummaryProps {}

const FindingSummary: React.FC<FindingSummaryProps> = ({}) => {
  const { theme } = React.useContext(ResultTableContext) || {
    theme: ResultTableStyle.editable,
  };

  const allFindings = useSelector(selectFindings);
  const includedFindings = useSelector(selectIncludedFindings);
  const excludedFindings = useSelector(selectExcludedFindings);

  const mapToOrderedIds = () => [
    ...includedFindings.map(a => a.id),
    'divider',
    ...excludedFindings.map(a => a.id),
  ];

  const [orderedIds, setOrderedIds] =
    React.useState<(string | 'divider')[]>(mapToOrderedIds());

  React.useEffect(() => {
    setOrderedIds(mapToOrderedIds());
  }, [includedFindings, excludedFindings]);

  const dispatchReorderFindings = useDispatcher(findingUseCase.ReorderFindings);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onReorder = (items: (string | 'divider')[]) => {
    const includedIds: string[] = [];
    const excludedIds: string[] = [];
    let afterExcludedDivider = false;
    for (const item of items) {
      if (item === 'divider') {
        afterExcludedDivider = true;
      } else {
        (afterExcludedDivider ? excludedIds : includedIds).push(item);
      }
    }
    dispatchReorderFindings({ includedIds, excludedIds });
    eventFindingActionReorderedFinding();
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setOrderedIds(items => {
        const oldIndex = items.indexOf(active.id as string);
        const newIndex = items.indexOf(over.id as string);

        const newItems = arrayMove(items, oldIndex, newIndex);
        onReorder(newItems);
        return newItems;
      });
    }
  };

  if (theme !== ResultTableStyle.editable) {
    return (
      <Box>
        {includedFindings.length > 0 && (
          <>
            <Box marginTop="xxxs">
              <ListDivider>
                {t('pages.report.findings.label.included_in_report')}
              </ListDivider>
            </Box>
            <Box
              element="ul"
              className={styles.list}
              data-testid="test-ordered-list"
            >
              {includedFindings.map(finding => (
                <FindingSummaryRow
                  key={`finding-summary-${finding.id}-row`}
                  finding={finding}
                  theme={theme}
                  index={finding.index}
                />
              ))}
            </Box>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box>
      {orderedIds.length > 0 && (
        <>
          <Box marginTop="xxxs">
            <ListDivider>
              {t('pages.report.findings.label.included_in_report')}
            </ListDivider>
            {includedFindings.length === 0 && (
              <Box
                display="flex"
                paddingY="xl"
                justifyContent="center"
                marginTop="xs"
                className={styles.noFindingBox}
              >
                <Icon name="download" />
                <Text type="body" marginLeft="s">
                  {t(
                    'pages.report.findings.label.included_in_report.no_findings'
                  )}
                </Text>
              </Box>
            )}
          </Box>
          <Box
            element="ul"
            className={styles.list}
            data-testid="test-ordered-list"
          >
            <DndContext
              sensors={sensors}
              modifiers={[
                restrictToVerticalAxis,
                restrictToFirstScrollableAncestor,
              ]}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={orderedIds}
                strategy={verticalListSortingStrategy}
              >
                {orderedIds.map(id => {
                  if (id === 'divider') {
                    return (
                      <ExcludedDivider
                        key="divider"
                        showExcludedText={excludedFindings.length === 0}
                      />
                    );
                  }
                  const finding = allFindings.find(a => a.id === id);
                  return (
                    finding && (
                      <FindingSummaryRow
                        key={`finding-summary-${finding.id}-row`}
                        finding={finding}
                        theme={theme}
                        draggable
                        index={finding.index}
                      />
                    )
                  );
                })}
              </SortableContext>
            </DndContext>
          </Box>
        </>
      )}
    </Box>
  );
};

interface ExcludedDividerProps {
  showExcludedText: boolean;
}

const ExcludedDivider: React.FC<ExcludedDividerProps> = ({
  showExcludedText,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: 'divider', disabled: true });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ListDivider>
        {t('pages.report.findings.label.excluded_from_report')}
      </ListDivider>

      {showExcludedText && (
        <Box
          display="flex"
          paddingY="xl"
          justifyContent="center"
          marginTop="xs"
          className={styles.noFindingBox}
        >
          <Icon name="download" />
          <Text type="body" marginLeft="s">
            {t('pages.report.findings.label.excluded_from_report.no_findings')}
          </Text>
        </Box>
      )}
    </li>
  );
};

export default FindingSummary;

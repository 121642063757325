import Maybe from '@monads/Maybe';

export interface AppSettings {
  studies: AppStudySettings;
  featureFlagsInitialised: boolean;
  regulatoryVersion: Maybe<string>;
  regulatoryVersionModifiedDate: Maybe<string>;
}

export interface AppStudySettings {
  canvas: {
    enableTemplateClipPath: boolean;
  };
}

export const initial = (): AppSettings => ({
  studies: {
    canvas: {
      enableTemplateClipPath: false,
    },
  },
  featureFlagsInitialised: false,
  regulatoryVersion: new Maybe(),
  regulatoryVersionModifiedDate: new Maybe(),
});

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Settings/Admin/Application';
import * as StoryLayout from '@webPages/Settings/Layout';
import { ApplicationSettings } from '@webPages/Settings/Admin/ApplicationSettings';

const Application: React.FC<ViewModel.Application> = ({
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  dispatchOnSignOut,
}) => {
  return (
    <StoryLayout.Layout
      profile={profile}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
      onSignOut={dispatchOnSignOut}
      searchQuery={searchQuery}
    >
      <ApplicationSettings />
    </StoryLayout.Layout>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(Application);

import { Annotations } from '@entities/Dicom';
import { Workflow } from '@entities/Workflow';

export enum BreastGalleryFilter {
  IncludingDetections = 'breast_gallery_filter--including_detections',
  All = 'breast_gallery_filter--all',
  RightBreast = 'breast_gallery_filter--right_breast',
  LeftBreast = 'breast_gallery_filter--left_breast',
  Other = 'breast_gallery_filter--other',
}

export enum ThyroidGalleryFilter {
  IncludingDetections = 'thyroid_gallery_filter--including_detections',
  All = 'thyroid_gallery_filter--all',
  Thyroid = 'thyroid_gallery_filter--thyroid',
  RightLobe = 'thyroid_gallery_filter--right_lobe',
  Isthmus = 'thyroid_gallery_filter--isthmus',
  LeftLobe = 'thyroid_gallery_filter--left_lobe',
  NeckHead = 'thyroid_gallery_filter--neck_head',
  Other = 'thyroid_gallery_filter--other',
}

export type SpecificGalleryFilter = ThyroidGalleryFilter | BreastGalleryFilter;

export interface SpecificGalleryFilterConfig {
  filterType: SpecificGalleryFilter;
  labelKey: string;
}

export const ThyroidGalleryFilters: SpecificGalleryFilterConfig[] = [
  {
    filterType: ThyroidGalleryFilter.IncludingDetections,
    labelKey: 'gallery.finding.filter.including_detections',
  },
  {
    filterType: ThyroidGalleryFilter.All,
    labelKey: 'gallery.finding.filter.all',
  },
  {
    filterType: ThyroidGalleryFilter.Thyroid,
    labelKey: 'gallery.finding.filter.thyroid',
  },
  {
    filterType: ThyroidGalleryFilter.RightLobe,
    labelKey: 'gallery.thyroid.finding.filter.right_lobe',
  },
  {
    filterType: ThyroidGalleryFilter.Isthmus,
    labelKey: 'gallery.thyroid.finding.filter.isthmus',
  },
  {
    filterType: ThyroidGalleryFilter.LeftLobe,
    labelKey: 'gallery.thyroid.finding.filter.left_lobe',
  },
  {
    filterType: ThyroidGalleryFilter.NeckHead,
    labelKey: 'gallery.thyroid.finding.filter.neck_head',
  },
  {
    filterType: ThyroidGalleryFilter.Other,
    labelKey: 'gallery.thyroid.finding.filter.other',
  },
];

export const BreastGalleryFilters: SpecificGalleryFilterConfig[] = [
  {
    filterType: BreastGalleryFilter.IncludingDetections,
    labelKey: 'gallery.finding.filter.including_detections',
  },
  {
    filterType: BreastGalleryFilter.All,
    labelKey: 'gallery.finding.filter.all',
  },
  {
    filterType: BreastGalleryFilter.RightBreast,
    labelKey: 'gallery.breast.finding.filter.right_breast',
  },
  {
    filterType: BreastGalleryFilter.LeftBreast,
    labelKey: 'gallery.breast.finding.filter.left_breast',
  },
  {
    filterType: BreastGalleryFilter.Other,
    labelKey: 'gallery.breast.finding.filter.other',
  },
];

export const getSpecificGalleryFilterLabel = (
  filterType: SpecificGalleryFilter
) => {
  return [...ThyroidGalleryFilters, ...BreastGalleryFilters].find(
    f => f.filterType === filterType
  )?.labelKey;
};

export const thyroidGalleryFilterToAnnotations = (
  filter: ThyroidGalleryFilter
): Annotations[] | null => {
  switch (filter) {
    case ThyroidGalleryFilter.Thyroid:
      return [{ bodyLocation: 'thyroid' }];
    case ThyroidGalleryFilter.RightLobe:
      return [{ bodyLocation: 'thyroid', bodySide: 'right' }];
    case ThyroidGalleryFilter.LeftLobe:
      return [{ bodyLocation: 'thyroid', bodySide: 'left' }];
    case ThyroidGalleryFilter.Isthmus:
      return [{ bodyLocation: 'thyroid', bodySide: 'median' }];
    case ThyroidGalleryFilter.NeckHead:
      return [{ bodyLocation: 'head' }, { bodyLocation: 'neck' }];
    case ThyroidGalleryFilter.Other:
    default:
      return null;
  }
};

export const breastGalleryFilterToAnnotations = (
  filter: BreastGalleryFilter
): Annotations[] | null => {
  switch (filter) {
    case BreastGalleryFilter.RightBreast:
      return [{ bodyLocation: 'breast', bodySide: 'right' }];
    case BreastGalleryFilter.LeftBreast:
      return [{ bodyLocation: 'breast', bodySide: 'left' }];
    case BreastGalleryFilter.Other:
    default:
      return null;
  }
};

export const getWorkflowDefaultFilter = (workflow: Workflow) => {
  switch (workflow) {
    case Workflow.Radiologist:
      return [
        BreastGalleryFilter.IncludingDetections,
        ThyroidGalleryFilter.IncludingDetections,
      ];
    case Workflow.Sonographer:
    default:
      return [BreastGalleryFilter.All, ThyroidGalleryFilter.All];
  }
};

import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';
import { ColourPalette } from '@appCanvas/interfaces/types';
import { Finding, Size, ThyroidFinding, Volume } from '@entities/Finding';
import { AnyUnit, DistanceUnit, VolumeUnit } from '@entities/Unit';
import { BiradsScore, TiRadsGrading } from '@entities/Grading';
import {
  computeFindingScorePoints,
  scorePointToGrading,
} from '@handlers/TiRadsHandler';
import { DetectionSize } from '@entities/Detection';
import { ClinicalAction } from '@entities/Characteristics';
import { getClinicalActionByNoduleSizeAndTiRadGrading } from './clinicalAction';

export const getUniqueDetectionSizes = (
  detectionSizes: DetectionSize[],
  unit: AnyUnit | null
) => {
  const sizeObjects = detectionSizes
    .map(detectionSize => {
      const value = detectionSize.annotatedSize || detectionSize.size;
      if (unit) {
        return { id: detectionSize.id, size: roundValueByUnit(value, unit) };
      } else {
        return {
          id: detectionSize.id,
          size: value,
        };
      }
    })
    .sort((a, b) => {
      if (a.size > b.size) {
        return 1;
      } else if (a.size < b.size) {
        return -1;
      }
      return 0;
    });

  // remove the duplicate size values from different detections
  return [...new Map(sizeObjects.map(item => [item.size, item])).values()];
};

export const tiRadsGradingToNoduleColor = (
  grading: TiRadsGrading,
  colourPalette: ColourPalette
) => {
  switch (grading) {
    case TiRadsGrading.TR5:
      return colourPalette.NODULE_SEVERITY_900;
    case TiRadsGrading.TR4:
      return colourPalette.NODULE_SEVERITY_700;
    case TiRadsGrading.TR3:
      return colourPalette.NODULE_SEVERITY_500;
    case TiRadsGrading.TR2:
      return colourPalette.NODULE_SEVERITY_300;
    default:
      return colourPalette.NODULE_SEVERITY_100;
  }
};

export const biRadsScoreToNoduleColor = (
  colourPalette: ColourPalette,
  score: BiradsScore | undefined
) => {
  switch (score) {
    case BiradsScore.BIRADS_0:
      return colourPalette.NODULE_SEVERITY_000;
    case BiradsScore.BIRADS_1:
      return colourPalette.NODULE_SEVERITY_100;
    case BiradsScore.BIRADS_2:
      return colourPalette.NODULE_SEVERITY_300;
    case BiradsScore.BIRADS_3:
      return colourPalette.NODULE_SEVERITY_500;
    case BiradsScore.BIRADS_4A:
    case BiradsScore.BIRADS_4B:
    case BiradsScore.BIRADS_4C:
      return colourPalette.NODULE_SEVERITY_700;
    case BiradsScore.BIRADS_5:
      return colourPalette.NODULE_SEVERITY_900;
    case BiradsScore.BIRADS_6:
      return colourPalette.NODULE_SEVERITY_1000;
    default:
      return colourPalette.SMALL_PARTS_BREAST_NODULE_LIGHT_COLOUR;
  }
};

export const getNoduleMaximumSize = (
  finding: Finding,
  targetUnit: DistanceUnit,
  isSize3D: boolean
): number | undefined => {
  let maxSize: Size | undefined = undefined;

  if (isSize3D) {
    // Handle 3D size
    const { width, height, length } = finding.sizes;
    [width, height, length].forEach(size => {
      if (size) {
        if (!maxSize || size.value > maxSize.value) {
          maxSize = size;
        }
      }
    });
  } else {
    maxSize = finding.sizes.max ?? undefined;
  }

  if (maxSize) {
    return convertUnit(maxSize.unit, targetUnit, maxSize.value);
  }
  return undefined;
};

export const computeFindingClinicalAction = (
  finding: ThyroidFinding,
  isSize3D: boolean
): ClinicalAction | undefined => {
  const targetUnit = DistanceUnit.MM;
  const score = computeFindingScorePoints(finding);
  const maxSize = getNoduleMaximumSize(finding, targetUnit, isSize3D);

  return maxSize
    ? getClinicalActionByNoduleSizeAndTiRadGrading(
        scorePointToGrading(score),
        maxSize,
        targetUnit
      )
    : undefined;
};

export const computeFindingVolume = (
  sizes: Finding['sizes']
): Volume | undefined => {
  const targetUnit = DistanceUnit.CM;
  const length = convertToUnit(sizes.length, targetUnit);
  const width = convertToUnit(sizes.width, targetUnit);
  const height = convertToUnit(sizes.height, targetUnit);

  if (length == undefined || width == undefined || height == undefined) {
    return undefined;
  }

  const volume = length * width * height;

  return {
    value: volume,
    unit: VolumeUnit.ML,
  };
};

function convertToUnit(
  size: { value: number; unit: DistanceUnit } | null | undefined,
  targetUnit: DistanceUnit
) {
  if (!size) return undefined;
  return convertUnit(size.unit, targetUnit, size.value);
}

export interface Authentication {
  status: AuthenticationStatus;
}

export const initial = (): Authentication => ({
  status: makeAuthenticationStatusNone(),
});

export const AUTHENTICATION_STATUS_NONE = 'AUTHENTICATION_STATUS_NONE';

interface AuthenticationStatusNone {
  type: typeof AUTHENTICATION_STATUS_NONE;
}

export const makeAuthenticationStatusNone = (): AuthenticationStatus => ({
  type: AUTHENTICATION_STATUS_NONE,
});

export const AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET =
  'AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET';

interface AuthenticationStatusAwaitingPasswordReset {
  type: typeof AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET;
  email: string;
}

export const makeAuthenticationStatusAwaitingPasswordReset = (args: {
  email: string;
}): AuthenticationStatus => ({
  type: AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET,
  ...args,
});

export const AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET_LOGIN =
  'AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET_LOGIN';

interface AuthenticationStatusAwaitingPasswordResetLogin {
  type: typeof AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET_LOGIN;
}

export const makeAuthenticationStatusAwaitingPasswordResetLogin =
  (): AuthenticationStatus => ({
    type: AUTHENTICATION_STATUS_AWAITING_PASSWORD_RESET_LOGIN,
  });

export const AUTHENTICATION_STATUS_AWAITING_CHANGE_PASSWORD_RESPONSE =
  'AUTHENTICATION_STATUS_AWAITING_CHANGE_PASSWORD_RESPONSE';

interface AuthenticationStatusAwaitingChangePasswordResponse {
  type: typeof AUTHENTICATION_STATUS_AWAITING_CHANGE_PASSWORD_RESPONSE;
}

export const makeAuthenticationStatusAwaitingChangePasswordResponse =
  (): AuthenticationStatus => ({
    type: AUTHENTICATION_STATUS_AWAITING_CHANGE_PASSWORD_RESPONSE,
  });

export const AUTHENTICATION_STATUS_AUTHENTICATING =
  'AUTHENTICATION_STATUS_AUTHENTICATING';

interface AuthenticationStatusAuthenticating {
  type: typeof AUTHENTICATION_STATUS_AUTHENTICATING;
}

export const makeAuthenticationStatusAuthenticating =
  (): AuthenticationStatus => ({
    type: AUTHENTICATION_STATUS_AUTHENTICATING,
  });

export const AUTHENTICATION_STATUS_BLOCKED = 'AUTHENTICATION_STATUS_BLOCKED';

interface AuthenticationStatusBlocked {
  type: typeof AUTHENTICATION_STATUS_BLOCKED;
}

export const makeAuthenticationStatusBlocked = (): AuthenticationStatus => ({
  type: AUTHENTICATION_STATUS_BLOCKED,
});

export const AUTHENTICATION_STATUS_AUTHENTICATING_FROM_SESSION =
  'AUTHENTICATION_STATUS_AUTHENTICATING_FROM_SESSION';

interface AuthenticationStatusAuthenticatingFromSession {
  type: typeof AUTHENTICATION_STATUS_AUTHENTICATING_FROM_SESSION;
}

export const makeAuthenticationStatusAuthenticatingFromSession =
  (): AuthenticationStatus => ({
    type: AUTHENTICATION_STATUS_AUTHENTICATING_FROM_SESSION,
  });

export const AUTHENTICATION_STATUS_AUTHENTICATED =
  'AUTHENTICATION_STATUS_AUTHENTICATED';

interface AuthenticationStatusAuthenticated {
  type: typeof AUTHENTICATION_STATUS_AUTHENTICATED;
  email: string;
  userId: string;

  token: string;
  security: SecurityConfiguration;
}

export const makeAuthenticationStatusAuthenticated = (args: {
  email: string;
  userId: string;

  token: string;
  security: SecurityConfiguration;
}): AuthenticationStatus => ({
  type: AUTHENTICATION_STATUS_AUTHENTICATED,
  ...args,
});

export const AUTHENTICATION_STATUS_MFA_AUTHENTICATING =
  'AUTHENTICATION_STATUS_MFA_AUTHENTICATING';

interface AuthenticationStatusMFAAuthenticating {
  type: typeof AUTHENTICATION_STATUS_MFA_AUTHENTICATING;
  security: SecurityConfiguration;
}

export const makeAuthenticationStatusMFAAuthenticating =
  (): AuthenticationStatus => ({
    type: AUTHENTICATION_STATUS_MFA_AUTHENTICATING,
    security: {
      hasMFAEnabled: true,
    },
  });

export const AUTHENTICATION_STATUS_MFA_REQUIRED =
  'AUTHENTICATION_STATUS_MFA_REQUIRED';

interface AuthenticationStatusMFARequired {
  type: typeof AUTHENTICATION_STATUS_MFA_REQUIRED;
  security: SecurityConfiguration;
}

export const makeAuthenticationStatusMFARequired =
  (): AuthenticationStatus => ({
    type: AUTHENTICATION_STATUS_MFA_REQUIRED,
    security: {
      hasMFAEnabled: true,
    },
  });

export type AuthenticationStatus =
  | AuthenticationStatusNone
  | AuthenticationStatusAwaitingPasswordReset
  | AuthenticationStatusAwaitingPasswordResetLogin
  | AuthenticationStatusAwaitingChangePasswordResponse
  | AuthenticationStatusAuthenticating
  | AuthenticationStatusAuthenticatingFromSession
  | AuthenticationStatusAuthenticated
  | AuthenticationStatusMFAAuthenticating
  | AuthenticationStatusMFARequired
  | AuthenticationStatusBlocked;

export interface SecurityConfiguration {
  hasMFAEnabled: boolean;
  otpAuthUrl?: string;
}

import React from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { t } from '@webInterfaces/I18n';
import { Text } from '@webMolecules/Text/Text';
import { Modal } from '@webMolecules/Modal/Modal';
import { Card } from '@webMolecules/Card/Card';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { FormInput, FormInputTypes } from '@webOrganisms/FormInput/FormInput';
import { FormFieldHint } from '@webOrganisms/FormFieldHint/FormFieldHint';
import styles from './styles.scss';

export interface StudyComparisonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCompare: (sessionId: string) => void;
}

interface FormValues {
  studyURL: string;
}
// Warning: This modal is for internal use only
const StudyComparisonModal: React.FC<StudyComparisonModalProps> = ({
  isOpen,
  onClose,
  onCompare,
}) => {
  const handleFormSubmit = (formValues: FormValues) => {
    const previousStudy = extractAnalysisAndSessionIdFromURL(
      formValues.studyURL
    );
    if (previousStudy) {
      onCompare(previousStudy.sessionId);
    }
  };

  const formProps: FormValues = {
    studyURL: '',
  };

  const CompareStudySchema = Yup.object().shape({
    studyURL: Yup.string()
      .required('Study URL is required')
      .matches(
        /\/study\/([^\/]+)\/([^\/]+)/,
        'Study URL must include /study/{analysisId}/{sessionId}'
      ),
  });
  return (
    <Modal isOpen={isOpen} size="medium" onClose={onClose}>
      <Card elevation="medium" padding="none">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={formProps}
          validationSchema={CompareStudySchema}
        >
          {formProps => (
            <Form>
              <Box className={styles.modalBody} padding="l">
                <Box className={styles.modalClose}>
                  <Button
                    iconBefore={<Icon name="x" />}
                    circle
                    intent="neutral"
                    size="small"
                    onClick={onClose}
                  />
                </Box>
                <Text type="display1" display="block" marginBottom="m">
                  {t('pages.study.modal.compare.title')}
                </Text>
                <Box marginBottom="xs">
                  <Text type="display6" display="block" marginBottom="xs">
                    {t('pages.study.modal.compare.placeholder')}
                  </Text>
                  <FormInput
                    type={FormInputTypes.Text}
                    error={formProps.errors.studyURL}
                    id="studyURL"
                    name="studyURL"
                    touched={formProps.touched.studyURL}
                    fluid
                  />
                  {formProps.errors.studyURL && (
                    <ErrorMessage
                      render={msg => (
                        <FormFieldHint
                          intent="error"
                          marginTop="xs"
                          marginBottom="xs"
                        >
                          {msg}
                        </FormFieldHint>
                      )}
                      name="studyURL"
                    />
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                paddingX="xxl"
                paddingY="l"
                className={styles.modalFooter}
              >
                <Button strong onClick={onClose}>
                  Cancel
                </Button>
                <Box marginRight="m" />
                <Button type="submit" intent="primary" strong>
                  Compare
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

function extractAnalysisAndSessionIdFromURL(url: string) {
  const match = url.match(/\/study\/([^\/]+)\/([^\/]+)/);

  if (match && match.length > 2) {
    return { analysisId: match[1], sessionId: match[2] };
  } else {
    return undefined;
  }
}

export default StudyComparisonModal;

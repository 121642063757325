import {
  ColourPalette,
  userColourPaletteActive,
} from '@appCanvas/interfaces/types/ColourPalette.type';

export enum DrawingColorKey {
  Light = 'light',
  Dark = 'dark',
  Red = 'red',
  Green = 'green',
  Default = 'default',
}

export enum SymbolColorKey {
  Neutral = 'neutral',
  Red = 'red',
  Green = 'green',
  Default = 'default',
}

const DRAWING_COLOR_MAP: {
  [key in DrawingColorKey]: string;
} = {
  [DrawingColorKey.Light]: userColourPaletteActive.FREEHAND_BRUSH_COLOUR,
  [DrawingColorKey.Dark]:
    userColourPaletteActive.FREEHAND_BRUSH_COLOUR_SECONDARY,
  [DrawingColorKey.Red]: userColourPaletteActive.FREEHAND_BRUSH_RED_COLOUR,
  [DrawingColorKey.Green]: userColourPaletteActive.FREEHAND_BRUSH_GREEN_COLOUR,
  [DrawingColorKey.Default]: userColourPaletteActive.FREEHAND_BRUSH_COLOUR,
};

export interface SymbolColor {
  primaryColor: string;
  secondaryColor: string;
}

const SYMBOL_COLOR_MAP: {
  [key in SymbolColorKey]: SymbolColor;
} = {
  [SymbolColorKey.Neutral]: {
    primaryColor: userColourPaletteActive.SYMBOL_PRIMARY_NEUTRAL_COLOUR,
    secondaryColor: userColourPaletteActive.SYMBOL_SECONDARY_NEUTRAL_COLOUR,
  },
  [SymbolColorKey.Red]: {
    primaryColor: userColourPaletteActive.SYMBOL_PRIMARY_RED_COLOUR,
    secondaryColor: userColourPaletteActive.SYMBOL_SECONDARY_RED_COLOUR,
  },
  [SymbolColorKey.Green]: {
    primaryColor: userColourPaletteActive.SYMBOL_PRIMARY_GREEN_COLOUR,
    secondaryColor: userColourPaletteActive.SYMBOL_SECONDARY_GREEN_COLOUR,
  },
  [SymbolColorKey.Default]: {
    primaryColor: userColourPaletteActive.SYMBOL_PRIMARY_NEUTRAL_COLOUR,
    secondaryColor: userColourPaletteActive.SYMBOL_SECONDARY_NEUTRAL_COLOUR,
  },
};

export function getDrawingColor(
  colorKey: DrawingColorKey = DrawingColorKey.Default
): string {
  return DRAWING_COLOR_MAP[colorKey];
}

export function getSymbolDrawingColor(
  colorKey: SymbolColorKey = SymbolColorKey.Default,
  canvasColorPalette?: ColourPalette
): SymbolColor {
  if (canvasColorPalette) {
    switch (colorKey) {
      case SymbolColorKey.Neutral:
        return {
          primaryColor: canvasColorPalette.SYMBOL_PRIMARY_NEUTRAL_COLOUR,
          secondaryColor: canvasColorPalette.SYMBOL_SECONDARY_NEUTRAL_COLOUR,
        };
      case SymbolColorKey.Red:
        return {
          primaryColor: canvasColorPalette.SYMBOL_PRIMARY_RED_COLOUR,
          secondaryColor: canvasColorPalette.SYMBOL_SECONDARY_RED_COLOUR,
        };
      case SymbolColorKey.Green:
        return {
          primaryColor: canvasColorPalette.SYMBOL_PRIMARY_GREEN_COLOUR,
          secondaryColor: canvasColorPalette.SYMBOL_SECONDARY_GREEN_COLOUR,
        };
      case SymbolColorKey.Default:
        return {
          primaryColor: canvasColorPalette.SYMBOL_PRIMARY_NEUTRAL_COLOUR,
          secondaryColor: canvasColorPalette.SYMBOL_SECONDARY_NEUTRAL_COLOUR,
        };
    }
  }
  return SYMBOL_COLOR_MAP[colorKey];
}

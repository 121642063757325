import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';

export const LoadingLayout = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingY="xxxl"
    >
      <LoadingIcon />
    </Box>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import {
  AnalysisUploadsLineItem,
  DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT,
  DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS,
} from '@interfaces/AnalysisUploadsLineItem';
import { Box } from '@webMolecules/Box/Box';
import { ProgressBar } from '@webMolecules/ProgressBar/ProgressBar';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { t, tPrint } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';
import styles from './toasts.scss';

const ToastUpload: React.FC<AnalysisUploadsLineItem> = props =>
  !!props.sessionId ? (
    <ToastUploadReady {...props} />
  ) : (
    <ToastUploadProgress {...props} />
  );

const ToastUploadProgress: React.FC<AnalysisUploadsLineItem> = props => {
  let percentageDone;
  let title;
  let errors;

  switch (props.type) {
    case DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT:
      percentageDone = Math.floor(
        (99 / (props.filesTotal * 3)) *
          (props.filesAnonymised + props.filesUploaded + props.filesProcessed)
      );

      title = t('uploads.status.in_progress');
      if (props.filesTotal > props.filesAnonymised) {
        title = t('uploads.status.anonymising');
      } else if (props.filesTotal > props.filesUploaded) {
        title = t('uploads.status.uploading');
      } else if (props.filesTotal > props.filesProcessed) {
        title = t('uploads.status.processing');
      } else if (!props.sessionId) {
        title = t('uploads.status.generating_session');
      }

      errors = props.errors;
      break;

    case DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS:
      percentageDone = Math.floor(
        10 + (89 / props.filesTotal) * props.filesProcessed
      );

      title = t('uploads.status.processing');
      if (
        props.filesProcessed >= 1 &&
        props.filesProcessed >= props.filesTotal
      ) {
        title = t('uploads.status.generating_session');
      }

      errors = [];
      break;
  }

  return (
    <Box display="flex">
      <Box paddingRight="m" paddingTop="xxxs">
        <LoadingIcon />
      </Box>
      <Box flex="1">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="s"
        >
          <Box display="flex">
            <Box>
              <Text display="block" type={'display5'}>
                {title}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="1">
            <ProgressBar percentage={percentageDone} intent="information" />
          </Box>
        </Box>

        {errors.length > 0 && (
          <Box marginTop="m">
            <Text type="body2" muted>
              {errors.length > 1
                ? tPrint(
                    'uploads.status.warning.could_not_be_processed.multiple',
                    errors.length
                  )
                : t('uploads.status.warning.could_not_be_processed.single')}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ToastUploadReady: React.FC<AnalysisUploadsLineItem> = props => {
  const title = t('uploads.status.ready');
  // let amountOfClientSideErrors;

  // switch (props.type) {
  //   case DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT:
  //     amountOfClientSideErrors = props.errors.length;
  //     break;

  //   case DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS:
  //     amountOfClientSideErrors = 0;
  //     break;
  // }

  return (
    <Box display="flex">
      <Box paddingRight="m" paddingTop="xxxs">
        <Icon name="check-circle" color="var(--ds-color-success-500)" />
      </Box>
      <Box flex="1">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="s"
        >
          <Box display="flex">
            <Box>
              <Text display="block" type={'display3'}>
                {title}
              </Text>
              <Text display="block" marginTop="s" type="body">
                <Text display="inline" renderHtml type="body">
                  {t('uploads.status.success.result_2')}
                </Text>
                &nbsp;
                {!props.autoRedirect && (
                  <Link
                    to={RouteHelper.study(
                      props.localAnalysisId || '',
                      props.sessionId
                    )}
                    className={styles.link}
                    data-testid="test-view-study"
                  >
                    <strong>{t('uploads.status.success.open')}</strong>
                  </Link>
                )}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ToastUpload;

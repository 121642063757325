// @TODO this should be a more generic type
import { BrushSize } from '@appCanvas/contracts/DrawingTools.contract';
import { SymbolName } from '@webMolecules/Symbol/Symbol';
import {
  DrawingColorKey,
  SymbolColorKey,
} from '@webOrganisms/DrawingToolOptions/DrawingColors';

export const PISTACHIO_CANVAS_MODE_SELECT = 'PISTACHIO_CANVAS_MODE_SELECT';

export interface PistachioCanvasModeSelect {
  type: typeof PISTACHIO_CANVAS_MODE_SELECT;
}

export const makePistachioCanvasModeSelect = (): PistachioCanvasModeSelect => ({
  type: PISTACHIO_CANVAS_MODE_SELECT,
});

export const PISTACHIO_CANVAS_MODE_TEXT = 'PISTACHIO_CANVAS_MODE_TEXT';

export interface PistachioCanvasModeText {
  type: typeof PISTACHIO_CANVAS_MODE_TEXT;
}

export const makePistachioCanvasModeText = (): PistachioCanvasModeText => ({
  type: PISTACHIO_CANVAS_MODE_TEXT,
});

export const PISTACHIO_CANVAS_MODE_DRAW = 'PISTACHIO_CANVAS_MODE_DRAW';

export interface PistachioCanvasModeDraw {
  type: typeof PISTACHIO_CANVAS_MODE_DRAW;
  mode: 'erase' | 'draw';
  brushSize: BrushSize;
  brushColor?: DrawingColorKey;
}

export const makePistachioCanvasModeDraw = (
  mode: 'erase' | 'draw',
  brushSize: BrushSize,
  brushColor?: DrawingColorKey
): PistachioCanvasModeDraw => ({
  type: PISTACHIO_CANVAS_MODE_DRAW,
  mode,
  brushSize,
  brushColor,
});

export const PISTACHIO_CANVAS_MODE_SYMBOL = 'PISTACHIO_CANVAS_MODE_SYMBOL';

export interface PistachioCanvasModeSymbol {
  type: typeof PISTACHIO_CANVAS_MODE_SYMBOL;
  symbolColor: SymbolColorKey;
  symbolName: SymbolName;
}

export const makePistachioCanvasModeSymbol = (
  symbolColor: SymbolColorKey,
  symbolName: SymbolName
): PistachioCanvasModeSymbol => ({
  type: PISTACHIO_CANVAS_MODE_SYMBOL,
  symbolColor,
  symbolName,
});

export type PistachioCanvasMode =
  | PistachioCanvasModeSelect
  | PistachioCanvasModeText
  | PistachioCanvasModeDraw
  | PistachioCanvasModeSymbol;

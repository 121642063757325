import React from 'react';
import { cnames } from '@helpers/cnames';
import { Icon } from '@webMolecules/Icon/Icon';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import styles from './dropArea.scss';

export interface DropAreaProps {
  isEmpty?: boolean;
  iconLabel?: string;
  label: string;
  isOver: boolean;
  icon?: JSX.Element;
}

export const DropArea: React.FC<DropAreaProps> = ({
  isEmpty,
  iconLabel,
  label,
  isOver,
  icon,
}) => {
  const classNames = cnames(styles.emptyRow, {
    [styles.isOver]: isOver,
    [styles.showHoverContent]: isEmpty,
  });

  return (
    <Box
      className={classNames}
      alignItems="center"
      padding="m"
      display="flex"
      justifyContent="center"
      gap="s"
    >
      <Box display="flex">
        <Icon name="arrow-down" color="var(--ds-state-color-disabled-text)" />
        <Icon name="arrow-up" color="var(--ds-state-color-disabled-text)" />
      </Box>
      <Text display="block" disabled noShrink type="body2">
        {label}
      </Text>
      {icon && iconLabel && (
        <Tooltip label={iconLabel} position={TooltipPosition.Top}>
          {icon}
        </Tooltip>
      )}
    </Box>
  );
};

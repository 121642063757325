import { createSelector } from '@reduxjs/toolkit';
import {
  Finding,
  FindingType,
  FormattedSize,
  Size,
  Volume,
} from '@entities/Finding';
import { t } from '@webInterfaces/I18n';
import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';
import { DistanceUnit, VolumeUnit, getUnitI18nKey } from '@entities/Unit';
import { scorePointToGrading } from '@handlers/TiRadsHandler';
import { BiradsScore, TiRadsGrading } from '@entities/Grading';
import { Dicom } from '@entities/Dicom';
import { filterDicomsByFindingId } from '@webViewModels/Pages/Study/GalleryHelper';
import { generateThyroidLocation } from '@webOrganisms/ResultTable/FindingsTable/ThyroidFindingHelper';
import { generateBreastPosition } from '@webOrganisms/ResultTable/FindingsTable/BreastFindingHelper';
import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';
import { selectComparisonSession, selectSession } from './SessionSelectors';
import { selectComparisonDicoms, selectDicoms } from './DicomSelectors';
import { selectConfig } from './BaseSelectors';

export const selectComparisonFindings = createSelector(
  selectSession,
  selectComparisonSession,
  selectComparisonDicoms,
  selectConfig,
  (session, comparisonSession, comparisonDicoms, config) => {
    return comparisonSession.mapOr(
      a =>
        a.data.findings
          .filter(f => f.included)
          .map(f =>
            computeFindingData(f, comparisonDicoms, session.isSize3D, config)
          ),
      []
    );
  }
);

export const selectFindings = createSelector(
  selectSession,
  selectDicoms,
  selectComparisonFindings,
  selectConfig,
  (session, dicoms, comparisonFindings, config) => {
    const sortedFindings = [...session.data.findings].sort(
      (a, b) => a.index - b.index
    );
    const findingsWithComputedData = sortedFindings
      .map(f => computeFindingData(f, dicoms, session.isSize3D, config))
      .map(f => attachComparisonFinding(f, comparisonFindings));

    return findingsWithComputedData;
  }
);

export const selectIncludedFindings = createSelector(
  selectFindings,
  findings => {
    return findings.filter(f => f.included);
  }
);

export const selectExcludedFindings = createSelector(
  selectFindings,
  findings => {
    return findings.filter(f => !f.included);
  }
);

export const selectFindingSizeReferences = createSelector(
  selectFindings,
  findings => {
    return findings
      .flatMap(f => [
        f.sizes.max?.referenceId,
        f.sizes.length?.referenceId,
        f.sizes.width?.referenceId,
        f.sizes.height?.referenceId,
      ])
      .filter(Boolean) as string[];
  }
);

function attachComparisonFinding<T extends Finding>(
  finding: T,
  comparisonFindings: T[]
): T {
  return {
    ...finding,
    comparedFinding: comparisonFindings.find(
      f => f.id === finding.comparisonFindingId
    ),
  };
}

function computeFindingData(
  finding: Finding,
  dicoms: Dicom[],
  isSize3D: boolean,
  config: Required<InstitutionConfiguration>
): Finding {
  const targetUnit = config.noduleSizeDisplayUnit;
  return {
    ...finding,
    formatted: {
      grading: getFindingGrading(finding, config),
      isSize3D,
      max: formatSize(finding.sizes.max, targetUnit),
      length: formatSize(finding.sizes.length, targetUnit),
      width: formatSize(finding.sizes.width, targetUnit),
      height: formatSize(finding.sizes.height, targetUnit),
      volume: formatVolume(finding.sizes.volume),
      dicomIds: getDicomIds(finding, dicoms),
      location: formatLocation(finding),
    },
  };
}

function getDicomIds(finding: Finding, dicoms: Dicom[]) {
  return filterDicomsByFindingId(dicoms, [finding.id]).map(dicom => dicom.id);
}

function getFindingGrading(
  finding: Finding,
  config: Required<InstitutionConfiguration>
): TiRadsGrading | BiradsScore | undefined {
  switch (finding.type) {
    case FindingType.Thyroid:
      return scorePointToGrading(finding.characteristics.scorePoints);
    case FindingType.Breast:
      return config.showBiradsScore
        ? finding.characteristics.biradsScore ?? undefined
        : undefined;
    default:
      return undefined;
  }
}

function formatSize(
  size: Size | null,
  targetUnit: DistanceUnit
): FormattedSize | undefined {
  if (!size) {
    return undefined;
  }
  const convertedSize = convertUnit(size.unit, targetUnit, size.value);
  const roundedSize = roundValueByUnit(convertedSize, targetUnit);

  return {
    value: roundedSize,
    unit: t(getUnitI18nKey(targetUnit)),
    referenceId: size.referenceId,
  };
}

export function formatVolume(volume: Volume | null): FormattedSize | undefined {
  if (!volume) {
    return undefined;
  }
  const targetUnit = VolumeUnit.ML;
  const convertedVolume = convertUnit(volume.unit, targetUnit, volume.value);
  const roundedVolume = roundValueByUnit(convertedVolume, targetUnit);

  return {
    value: roundedVolume,
    unit: t(getUnitI18nKey(targetUnit)),
  };
}

function formatLocation(finding: Finding) {
  switch (finding.type) {
    case FindingType.Thyroid: {
      return generateThyroidLocation(finding);
    }
    case FindingType.Breast: {
      return generateBreastPosition(finding);
    }
    default:
      return '';
  }
}

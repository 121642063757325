import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { Dropdown, DropdownItem } from '@webMolecules/Dropdown/Dropdown';
import { Button } from '@webMolecules/Button/Button';
import ScheduleGridTemplateColumns from '@webMolecules/Schedule/ScheduleGridTemplateColumns';
import { cnames } from '@helpers/cnames';
import styles from './styles.scss';

export interface LineItemProps {
  gridTemplateColumns?: string;
  children?: React.ReactNode;
  href?: string;
  className?: string;
  condensed?: boolean;
  onClick?: () => void;
  contextMenu?: LineItemContextMenuOptions[];
  animated?: boolean;
}

export interface LineItemContextMenuOptions {
  label: React.ReactText | React.ReactNode;
  icon?: JSX.Element;
  onClick?: () => void;
}

const MotionBox = motion(Box);

export const LineItem: React.FC<LineItemProps> = ({
  children,
  href,
  gridTemplateColumns,
  className = '',
  condensed = false,
  onClick,
  contextMenu,
  animated,
  ...rest
}) => {
  const classNames = cnames(styles.lineItem, className, {
    [styles.interactive]: onClick !== undefined || href !== undefined,
    [styles.animated]: animated,
  });

  const makeGridTemplateColumns = (fromContext: null | string) => {
    if (!fromContext) {
      return {
        gridTemplateColumns:
          gridTemplateColumns ||
          new Array(React.Children.count(children)).fill('1fr').join(' '),
      };
    }
    return {
      gridTemplateColumns: fromContext,
    };
  };

  let contents = (
    <ScheduleGridTemplateColumns.Consumer>
      {contextValue => (
        <Box
          className={styles.grid}
          padding={condensed ? undefined : 's'}
          marginBottom={condensed ? 'xs' : undefined}
          style={makeGridTemplateColumns(contextValue)}
        >
          {children}
        </Box>
      )}
    </ScheduleGridTemplateColumns.Consumer>
  );

  if (href) {
    contents = (
      <Link to={href} className={styles.link}>
        {contents}
      </Link>
    );
  }

  if (onClick) {
    contents = (
      <Box element="button" onClick={() => onClick()} className={styles.link}>
        {contents}
      </Box>
    );
  }

  return (
    <MotionBox
      className={classNames}
      display="flex"
      alignItems="center"
      {...rest}
      layout="position"
    >
      {contents}
      <Box
        className={styles.actionSlot}
        marginRight="xs"
        data-testid={`worklist-line-item-container`}
      >
        {contextMenu && contextMenu.length > 0 ? (
          <Dropdown
            trigger={
              <Button
                data-testid="worklist-line-item-vertical-dropdown"
                iconBefore={<Icon name="more-vertical" />}
                circle
                size="small"
              />
            }
          >
            {contextMenu.map(({ label, onClick, icon }, index) => (
              <DropdownItem
                key={index}
                onSelect={() => onClick && onClick()}
                condensed
                iconBefore={icon}
                data-testid="worklist-line-item-action"
              >
                {label}
              </DropdownItem>
            ))}
          </Dropdown>
        ) : (
          <Box />
        )}
      </Box>
    </MotionBox>
  );
};

export default LineItem;

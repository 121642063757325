import { Detection } from './Detection';

export enum IMAGE_TYPE {
  Liver = 'image-type--liver',
  GallBladder = 'image-type--gallbladder',
  CommonBileDuct = 'image-type--common-bile-duct',
  PortalVein = 'image-type--portal-vein',
  Pancreas = 'image-type--pancreas',
  Spleen = 'image-type--spleen',
  Aorta = 'image-type--aorta',
  CarotidArtery = 'image-type--carotid_artery',
  IliacArtery = 'image-type--iliac_artery',
  Kidney = 'image-type--kidney',
  Bladder = 'image-type--bladder',
  Prostate = 'image-type--prostate',
  LowerLimbsArtery = 'image-type--lower_limbs_artery',
  LowerLimbsVein = 'image-type--lower_limbs_vein',
  MesentericArtery = 'image-type--mesenteric_artery',
  RenalArtery = 'image-type--renal_artery',
  SubclavianArtery = 'image-type--subclavian_artery',
  SubclavianVein = 'image-type--subclavian_vein',
  UpperLimbsArtery = 'image-type--upper_limbs_artery',
  UpperLimbsVein = 'image-type--upper_limbs_vein',
  Breast = 'image-type--breast',
  Thyroid = 'image-type--thyroid',
  Scrotum = 'image-type--scrotum',
  Neck = 'image-type--neck',
  Head = 'image-type--head',
  UpperAbdomen = 'image-type--abdo_upper_abdomen',
  Ovaries = 'image-type--ovaries',
  Uterus = 'image-type--uterus',
  Endometrium = 'image--type-endometrium',
  Cervix = 'image-type--cervix',
  Unknown = 'image-type--unknown',
}

export interface Dicom {
  id: string;
  sopInstanceUid: string;
  height: number;
  width: number;
  scanTime?: string;
  GetImage: () => Promise<string>;
  GetVideo: () => Promise<string>;
  hasVideo: boolean;
  imageType: IMAGE_TYPE;
  annotations: Annotations;
  frameRate?: number;
  detections: Detection[];
}

export type Annotations = {
  bodyLocation?: string;
  bodySide?: string;
  vessel?: string;
  region?: string;
};

import * as EntitySession from '@entities/Session';
import { App } from '@entities/App';
import { ApiStatus } from '@entities/Status';
import { toSession } from './App';
export { FinaliseStatus } from '@entities/Status';

export type StudyLayout = EntitySession.StudyLayout;
export type Session = EntitySession.Session;

export interface ComparisonStudiesConfig {
  enableComparisonStudies: boolean;
  enableComparisonStudiesManual: boolean;
  enableBookmarkDropdown: boolean;
}
export const fromApp = (app: App): EntitySession.Session => toSession(app);

export const isLoading = (session: EntitySession.Session): boolean =>
  session.status === ApiStatus.Busy ||
  session.analysis.isNothing() ||
  session.details.isNothing();

export interface PhiCipher {
  phi_b64_cipher: string;
}

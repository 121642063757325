import { fabric } from 'fabric';
import { t } from '@webInterfaces/I18n';
import { Detection, DetectionSize, DimensionType } from '@entities/Detection';
import { DistanceUnit, getUnitI18nKey } from '@entities/Unit';
import { roundValueByUnit } from '@handlers/UnitHandler';

// PIXEL RATIO
const pixelRatio = window.devicePixelRatio;
// BOUNDING LIMIT PADDING
const BOUNDING_LIMIT_PADDING = 2;

// LESION DETECTION BOX
const BOX_STROKE_COLOR = '#FFFFFF';
const BOX_STROKE_WIDTH = 1 * pixelRatio;
const BOX_STROKE_DASHED = [2 * pixelRatio, 2 * pixelRatio];
const BOX_RADIUS = 8 * pixelRatio;
const BOX_OVERLAY_HOVER = 'rgba(255, 255, 255, 0.1)';

// LESION INDEX BADGE
const BADGE_CIRCLE_RADIUS = 10 * pixelRatio;
const BADGE_TEXT_SIZE = 10 * pixelRatio;
const BADGE_TEXT_COLOR = '#FFFFFF';
const BADGE_FILL = '#2d7ff9';

// AXIS
const AXIS_STROKE_COLOR = '#FFFFFF';
const AXIS_STROKE_WIDTH = 1 * pixelRatio;
const AXIS_STROKE_DASHED = [3 * pixelRatio, 3 * pixelRatio];
const AXIS_ENDPOINT_SIZE = 3 * pixelRatio;
const AXIS_HIGHLIGHT_COLOR = '#00FFBA';

// MEASUREMENT
const MEASUREMENT_BACKGROUND_COLOR = '#213846';
const MEASUREMENT_BORDER_COLOR = '#3A4E5A';
const MEASUREMENT_BORDER_WIDTH = 2 * pixelRatio;
const MEASUREMENT_TEXT_COLOR = '#FFFFFF';
const MEASUREMENT_FONT_SIZE = 10 * pixelRatio;
const MEASUREMENT_PADDING = 10 * pixelRatio;
const MEASUREMENT_OFFSET = 10 * pixelRatio;
const MEASUREMENT_HIGHLIGHT_TEXT_COLOR = '#0A161D';

export const drawEndpoint = (
  left: number,
  top: number,
  highlighted: boolean
) => {
  return new fabric.Polygon(
    [
      { x: 0, y: -AXIS_ENDPOINT_SIZE },
      { x: AXIS_ENDPOINT_SIZE, y: 0 },
      { x: 0, y: AXIS_ENDPOINT_SIZE },
      { x: -AXIS_ENDPOINT_SIZE, y: 0 },
    ],
    {
      fill: highlighted ? AXIS_HIGHLIGHT_COLOR : AXIS_STROKE_COLOR,
      left,
      top,
      originX: 'center',
      originY: 'center',
    }
  );
};

export const drawMeasurement = (
  size: number,
  unit: DistanceUnit | null,
  left: number,
  top: number,
  dimensionType: DimensionType,
  highlighted: boolean
) => {
  const sizeText = unit
    ? `${roundValueByUnit(size, unit)} ${t(getUnitI18nKey(unit))}`
    : size;
  const text = new fabric.Text(`${sizeText}`, {
    fontSize: MEASUREMENT_FONT_SIZE,
    fill: highlighted
      ? MEASUREMENT_HIGHLIGHT_TEXT_COLOR
      : MEASUREMENT_TEXT_COLOR,
    fontFamily: 'Inter',
    fontWeight: 'bold',
    originX: 'center',
    originY: 'center',
  });

  const textWidth = (text.width ?? 0) + MEASUREMENT_PADDING;
  const textHeight = (text.height ?? 0) + MEASUREMENT_PADDING;

  const pill = new fabric.Rect({
    fill: highlighted ? AXIS_HIGHLIGHT_COLOR : MEASUREMENT_BACKGROUND_COLOR,
    stroke: highlighted ? AXIS_HIGHLIGHT_COLOR : MEASUREMENT_BORDER_COLOR,
    strokeWidth: MEASUREMENT_BORDER_WIDTH,
    rx: (textHeight + MEASUREMENT_BORDER_WIDTH) / 2,
    ry: (textHeight + MEASUREMENT_BORDER_WIDTH) / 2,
    width: textWidth,
    height: textHeight,
    originX: 'center',
    originY: 'center',
  });

  const { width, height } = pill.getBoundingRect();

  const offsetX =
    dimensionType === DimensionType.Height ? 0 : width / 2 + MEASUREMENT_OFFSET;
  const offsetY =
    dimensionType !== DimensionType.Height
      ? 0
      : height / 2 + MEASUREMENT_OFFSET;

  return new fabric.Group([pill, text], {
    left: left + offsetX,
    top: top + offsetY,
    originX: 'center',
    originY: 'center',
  });
};

export const drawAxis = (
  detection: Detection,
  axis: DetectionSize,
  highlighted: boolean,
  widthRatio = 1,
  heightRatio = 1
): fabric.Group | null => {
  const { path, size, dimensionType, annotatedSize } = axis;

  if (!detection.boundingBox || !path) return null;
  const { offsetX, offsetY } = detection.boundingBox;

  const width = Math.abs(path.start.x - path.end.x) * widthRatio;
  const height = Math.abs(path.start.y - path.end.y) * heightRatio;
  const xMin = Math.min(path.start.x, path.end.x);
  const yMin = Math.min(path.start.y, path.end.y);
  const left = (xMin + offsetX) * widthRatio;
  const top = (yMin + offsetY) * heightRatio;

  const startX = path.start.x < path.end.x ? 0 : width;
  const startY = path.start.y < path.end.y ? 0 : height;
  const endX = path.start.x < path.end.x ? width : 0;
  const endY = path.start.y < path.end.y ? height : 0;

  const line = new fabric.Line([startX, startY, endX, endY], {
    originX: 'left',
    originY: 'top',
    stroke: highlighted ? AXIS_HIGHLIGHT_COLOR : AXIS_STROKE_COLOR,
    strokeWidth: AXIS_STROKE_WIDTH,
    strokeDashArray: AXIS_STROKE_DASHED,
  });

  const endPoints = [
    drawEndpoint(startX, startY, highlighted),
    drawEndpoint(endX, endY, highlighted),
  ];

  const useEndPosition: boolean =
    dimensionType === DimensionType.Height ? endY > startY : endX > startX;
  const measurementLeft = left + (useEndPosition ? endX : startX);
  const measurementTop = top + (useEndPosition ? endY : startY);

  const measurement = drawMeasurement(
    annotatedSize || size,
    axis.unit,
    measurementLeft,
    measurementTop,
    dimensionType,
    highlighted
  );

  const group = new fabric.Group([line, ...endPoints], {
    hasRotatingPoint: false,
    hasControls: false,
    data: {},
    lockMovementX: true,
    lockMovementY: true,
    hasBorders: false,
    left,
    top,
    hoverCursor: 'default',
    evented: false,
  });

  group.addWithUpdate(measurement);

  return group;
};

export const drawBoundingBox = (
  canvas: fabric.Canvas | null,
  detection: Detection,
  findingIndex: number | string,
  widthRatio = 1,
  heightRatio = 1
): fabric.Group | null => {
  if (!detection.boundingBox || !canvas) return null;
  const { boundingBox } = detection;

  const padding = 32 * widthRatio;

  const { xMin, yMin, xMax, yMax, offsetX, offsetY } = boundingBox;
  let width = Math.abs(xMax - xMin) * widthRatio + padding;
  const height = Math.abs(yMax - yMin) * heightRatio + padding;

  const left = (xMin + offsetX) * widthRatio - padding / 2;
  const top = (yMin + offsetY) * heightRatio - padding / 2;

  if (canvas?.width) {
    const canvasWidth = canvas.width * canvas.getZoom();
    // limit the roi within the canvas boundary
    if (canvasWidth && width + left > canvasWidth) {
      width = canvasWidth - left - BOUNDING_LIMIT_PADDING;
    }
  }

  const hasFindingNum = typeof findingIndex === 'number';

  const roi = new fabric.Rect({
    strokeDashArray: hasFindingNum ? [0, 0] : BOX_STROKE_DASHED,
    stroke: BOX_STROKE_COLOR,
    strokeWidth: BOX_STROKE_WIDTH,
    fill: 'transparent',
    width,
    height,
    rx: BOX_RADIUS,
    ry: BOX_RADIUS,
  });

  const detectionGroup = new fabric.Group([roi], {
    hasRotatingPoint: false,
    hasControls: false,
    data: {
      findingIndex: findingIndex,
    },
    lockMovementX: true,
    lockMovementY: true,
    hasBorders: false,
    left,
    top,
    hoverCursor: 'copy',
  });

  detectionGroup.on('mouseover', e => {
    if (!e.target) return;
    const detectionGroup = e.target as fabric.Group;

    detectionGroup.item(0).set({
      fill: BOX_OVERLAY_HOVER,
      strokeDashArray: [0, 0],
    });

    detectionGroup.canvas?.requestRenderAll();
  });

  detectionGroup.on('mouseout', e => {
    if (!e.target) return;
    const detectionGroup = e.target as fabric.Group;

    detectionGroup.item(0).set({
      fill: 'transparent',
      strokeDashArray: hasFindingNum ? [0, 0] : BOX_STROKE_DASHED,
    });

    detectionGroup.canvas?.requestRenderAll();
  });

  return detectionGroup;
};

export const drawBoundingBoxIndex = (
  group: fabric.Group,
  findingIndex: number | string
): fabric.Group | null => {
  const top = group.top || 0;
  let left = (group.left || 0) + (group.width || 0);
  const zoom = group.canvas?.getZoom() || 1;
  if (group.canvas?.width) {
    // limit the index label within the canvas boundary
    const canvasWidth = group.canvas.width * zoom;
    if (
      canvasWidth &&
      left + BOUNDING_LIMIT_PADDING + BADGE_CIRCLE_RADIUS >= canvasWidth
    ) {
      left = canvasWidth - BADGE_CIRCLE_RADIUS;
    }
  }

  const hasFindingNum = typeof findingIndex === 'number';

  const indexBackground = new fabric.Circle({
    radius: BADGE_CIRCLE_RADIUS,
    fill: BADGE_FILL,
    originX: 'center',
    originY: 'center',
  });

  const indexText = new fabric.Text(`${findingIndex}`, {
    fontSize: BADGE_TEXT_SIZE,
    fontFamily: 'Inter',
    fill: BADGE_TEXT_COLOR,
    originX: 'center',
    originY: 'center',
    fontWeight: 'bold',
  });

  const indexBadge = new fabric.Group([indexBackground, indexText], {
    left: left,
    top: top,
    originX: 'center',
    originY: 'center',
    visible: hasFindingNum,
    lockMovementX: true,
    lockMovementY: true,
    hasBorders: false,
    hasControls: false,
    hoverCursor: 'default',
  });

  return indexBadge;
};

export const drawBoundingBoxConfidence = (
  group: fabric.Group,
  detection: Detection
) => {
  if (!detection.boundingBox) return null;
  const confidence = detection.boundingBox.confidenceClass ?? '-';
  const confidenceText = new fabric.Text(`confidence: ${confidence}`, {
    fontSize: BADGE_TEXT_SIZE,
    fontFamily: 'Inter',
    fill: 'yellow',
    fontWeight: 'bold',
    left: group.left,
    top: group.top,
  });
  return confidenceText;
};

import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import { Storage } from '@contracts/Storage';
import { Analysis } from '@interfaces/Analysis';
import { PreviousSessionInfoLineItem } from '@entities/PreviousSessionInfoLineItem';
import {
  BreastFinding,
  BreastFindingCharacteristics,
  CanvasPosition,
  Clockface,
  Finding,
  FindingSizes,
  FindingType,
  Size,
  ThyroidFinding,
  ThyroidFindingCharacteristics,
  Volume,
} from '@entities/Finding';
import {
  BreastSessionData,
  ComparisonSession,
  ThyroidSessionData,
  UnknownSessionData,
} from '@entities/Session';
import { schemas } from '@api/schema/client';
import { BiradsScore } from '@entities/Grading';
import { StudyType } from '@entities/StudyType';
import { stringToStudyType } from '@interfaces/StudyType';
import {
  BodySide,
  BreastFindingArchitecture,
  BreastFindingCalcification,
  BreastFindingEchoPattern,
  BreastFindingElasticity,
  BreastFindingMargin,
  BreastFindingOrientation,
  BreastFindingPosition,
  BreastFindingPosteriorFeature,
  BreastFindingShape,
  BreastFindingSkinChanges,
  BreastFindingSpecialCase,
  BreastFindingType,
  BreastFindingVascularity,
  ThyroidFindingComposition,
  ThyroidFindingEchogenicFoci,
  ThyroidFindingEchogenicity,
  ThyroidFindingMargin,
  ThyroidFindingShape,
  ThyroidPole,
} from '@entities/Characteristics';
import { decode as decodeSession } from './SessionLineItem';
import { decode as decodeDicom } from './Dicom';
import {
  ClinicalActionMap,
  DistanceUnitMap,
  VolumeUnitMap,
  decodeEnum,
  mapEnumArrayByValue,
  mapEnumByValue,
} from './Enum';
import { decodeCanvasObject } from './CanvasObject';
import { decode as decodeDate } from './date';
import { decodeThyroidGeneralCharacteristics } from './ThyroidGeneralCharacteristics';

export const decode = async (
  storage: Storage,
  data: schemas['SessionDataResponse']
): Promise<Analysis> => {
  const dicoms = data.dicoms.map(dicom => decodeDicom(storage, dicom));
  const session = await decodeSession(storage, data.session);
  const previousSessions = decodePreviousSessions(data.previous_sessions);
  const parsedDate = dayjs(data.session.pacs_scan_date || null).toDate();
  const comparisonSession = decodeComparisonSession(
    storage,
    data.comparison_session
  );

  return {
    id: session.analysisId,
    studyUID: data.session.study_instance_uid,
    batchUID: data.session.batch_uid,
    accessionNumber: session.accessionNumber,
    date: parsedDate,
    dicoms,
    studyType: session.studyType,
    session,
    previousSessions,
    studyLatestAnalysisId: data.session.analysis_id,
    data: decodeSessionData(session.studyType, data.data),
    comparisonSession,
  };
};

export function decodePreviousSessions(
  previousSessionInfo: schemas['PreviousSessionData'][]
) {
  // Maps and filters out previous sessions without a valid date
  const previousSessions = previousSessionInfo
    .map(({ session_id, analysis_id, date }) => {
      const parsedDate = dayjs(date);
      return {
        id: session_id,
        analysisId: analysis_id,
        date: parsedDate.isValid() ? parsedDate.toDate() : null,
      };
    })
    .filter(info => !!info.date) as PreviousSessionInfoLineItem[];

  // Sort the sessions by date in descending order
  return orderBy(previousSessions, ['date'], ['desc']);
}

export function decodeComparisonSession(
  storage: Storage,
  comparison_session: schemas['ComparisonSessionDataResponse'] | null
): ComparisonSession | undefined {
  if (!comparison_session?.data) {
    return undefined;
  }

  const studyType = stringToStudyType(comparison_session.session.study_type);
  const date = decodeDate(comparison_session.session.pacs_scan_date);
  const dicoms = comparison_session.dicoms.map(dicom =>
    decodeDicom(storage, dicom)
  );

  return {
    date,
    dicoms,
    data: decodeSessionData(studyType, comparison_session.data),
  };
}

export function decodeSessionData(
  studyType: StudyType,
  data:
    | schemas['ThyroidData-Output']
    | schemas['BreastData-Output']
    | schemas['UnknownData']
) {
  switch (studyType) {
    case StudyType.SmallPartsBreast:
      return decodeBreastSessionData(data as schemas['BreastData-Output']);
    case StudyType.SmallPartsThyroid:
      return decodeThyroidSessionData(data as schemas['ThyroidData-Output']);
    case StudyType.Unknown:
      return decodeUnknownSessionData();
    default:
      throw new Error('Invalid study type');
  }
}

export function decodeUnknownSessionData(): UnknownSessionData {
  return {
    findings: [],
    canvasObjects: [],
  };
}

export function decodeThyroidSessionData(
  data: schemas['ThyroidData-Output']
): ThyroidSessionData {
  return {
    findings: data.findings.map(f => decodeThyroidFinding(f)),
    thyroidGeneralCharacteristics: decodeThyroidGeneralCharacteristics(
      data.thyroid_general_characteristics
    ),
    impressions: data.impressions ?? undefined,
    canvasObjects: data.canvas_objects.map(object =>
      decodeCanvasObject(object)
    ),
  };
}

export function decodeFinding(
  data:
    | schemas['ThyroidFindingData-Output']
    | schemas['BreastFindingData-Output']
): Finding {
  switch (data.type) {
    case 'thyroid':
      return decodeThyroidFinding(data as schemas['ThyroidFindingData-Output']);
    case 'breast':
      return decodeBreastFinding(data as schemas['BreastFindingData-Output']);
    default:
      throw new Error('Invalid finding type');
  }
}

export function decodeThyroidFinding(
  data: schemas['ThyroidFindingData-Output']
): ThyroidFinding {
  const base = decodeBaseFinding(data);
  return {
    ...base,
    type: FindingType.Thyroid,
    sizes: decodeFindingSizes(data.size),
    characteristics: decodeThyroidCharacteristics(data.characteristics),
    formatted: {},
    canvasPosition: decodeCanvasPosition(data.canvas_position),
  };
}

export function decodeBaseFinding(
  data:
    | schemas['ThyroidFindingData-Output']
    | schemas['BreastFindingData-Output']
): Pick<
  Finding,
  | 'id'
  | 'index'
  | 'included'
  | 'detectionIds'
  | 'comparisonFindingId'
  | 'autoGenerated'
  | 'notes'
> {
  return {
    id: data.finding_id,
    index: data.index,
    included: data.included,
    detectionIds: data.detection_ids,
    comparisonFindingId: data.comparison_finding_id ?? undefined,
    autoGenerated: data.is_autogenerated,
    notes: data.notes,
  };
}

export function decodeFindingSizes(
  data: schemas['FindingSizeData-Output']
): FindingSizes {
  return {
    max: decodeSize(data.max),
    length: decodeSize(data.length),
    width: decodeSize(data.width),
    height: decodeSize(data.height),
    volume: decodeVolume(data.volume),
  };
}

export function decodeSize(data: schemas['SizeData'] | null): Size | null {
  if (!data) {
    return null;
  }
  return {
    value: data.value,
    unit: decodeEnum(data.unit, DistanceUnitMap),
    referenceId: data.reference_id ?? undefined,
  };
}

export function decodeVolume(
  data: schemas['VolumeData'] | null
): Volume | null {
  if (!data) {
    return null;
  }
  return {
    value: data.value,
    unit: decodeEnum(data.unit, VolumeUnitMap),
  };
}

export function decodeClockface(data: schemas['ClockfaceData']): Clockface {
  return {
    hour: data.hour ?? null,
    minute: data.minute ?? null,
  };
}

export function decodeThyroidCharacteristics(
  data: schemas['ThyroidFindingCharacteristicsData']
): ThyroidFindingCharacteristics {
  return {
    side: mapEnumByValue(data.side, BodySide),
    pole: mapEnumByValue(data.pole, ThyroidPole),
    composition: mapEnumByValue(data.composition, ThyroidFindingComposition),
    echogenicity: mapEnumByValue(data.echogenicity, ThyroidFindingEchogenicity),
    margin: mapEnumByValue(data.margin, ThyroidFindingMargin),
    shape: mapEnumByValue(data.shape, ThyroidFindingShape),
    echogenicFoci: mapEnumArrayByValue(
      data.echogenic_foci,
      ThyroidFindingEchogenicFoci
    ),
    scorePoints: data.score_points,
    clinicalAction:
      data.clinical_action &&
      decodeEnum(data.clinical_action, ClinicalActionMap),
  };
}

export function decodeCanvasPosition(
  data: schemas['CanvasPositionData']
): CanvasPosition {
  return {
    xCoordinate: data.x_coordinate,
    yCoordinate: data.y_coordinate,
    positionNoiseX: data.position_noise_x,
    positionNoiseY: data.position_noise_y,
    customWidth: data.custom_width,
    customHeight: data.custom_height,
  };
}

export function decodeBreastSessionData(
  data: schemas['BreastData-Output']
): BreastSessionData {
  return {
    findings: data.findings.map(f => decodeBreastFinding(f)),
    impressions: data.impressions ?? undefined,
    canvasObjects: [],
  };
}

export function decodeBreastFinding(
  data: schemas['BreastFindingData-Output']
): BreastFinding {
  const base = decodeBaseFinding(data);
  return {
    ...base,
    type: FindingType.Breast,
    sizes: decodeFindingSizes(data.size),
    characteristics: decodeBreastCharacteristics(data.characteristics),
    formatted: {},
    canvasPosition: decodeCanvasPosition(data.canvas_position),
  };
}

export function decodeBreastCharacteristics(
  data: schemas['BreastFindingCharacteristicsData-Output']
): BreastFindingCharacteristics {
  return {
    side: mapEnumByValue(data.side, BodySide),
    position: mapEnumByValue(data.position, BreastFindingPosition),
    clockface: data.clockface && decodeClockface(data.clockface),
    distanceFromNipple: decodeSize(data.distance_from_nipple),
    specialCase: mapEnumByValue(data.special_case, BreastFindingSpecialCase),
    posteriorFeatures: mapEnumByValue(
      data.posterior_features,
      BreastFindingPosteriorFeature
    ),
    shape: mapEnumByValue(data.shape, BreastFindingShape),
    orientation: mapEnumByValue(data.orientation, BreastFindingOrientation),
    margin: mapEnumByValue(data.margin, BreastFindingMargin),
    echoPattern: mapEnumByValue(data.echo_pattern, BreastFindingEchoPattern),
    calcifications: mapEnumByValue(
      data.calcifications,
      BreastFindingCalcification
    ),
    type: mapEnumByValue(data.type, BreastFindingType),
    vascularity: mapEnumByValue(data.vascularity, BreastFindingVascularity),
    elasticity: mapEnumByValue(data.elasticity, BreastFindingElasticity),
    skinChanges: mapEnumByValue(data.skin_changes, BreastFindingSkinChanges),
    architecture: mapEnumArrayByValue(
      data.architecture,
      BreastFindingArchitecture
    ),
    biradsScore: mapEnumByValue(data.birads_score, BiradsScore),
  };
}

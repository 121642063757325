import { Routes } from '@webViewInterfaces/Routes';
import * as RouteHelper from '@helpers/routes';
import * as ViewInterfacesStudySession from '@webViewInterfaces/Study/Session';
import * as ViewInterfacesUseCaseSession from '@webViewInterfaces/UseCase/Session';
import * as ViewInterfacesWorklist from '@webViewInterfaces/UseCase/Worklist';
import * as ViewInterfacesPACS from '@webViewInterfaces/UseCase/PACS';
import * as ViewInterfacesSearch from '@webViewInterfaces/UseCase/Search';
import * as ViewInterfacesAdminUsers from '@webViewInterfaces/Settings/Admin/Users';
import * as ViewInterfacesAdminWorkspaces from '@webViewInterfaces/Settings/Admin/Workspaces';
import * as ViewInterfacesAdminInstitutions from '@webViewInterfaces/Settings/Admin/Institutions';
import * as ViewInterfacesAdminApplication from '@webViewInterfaces/Settings/Admin/Application';
import PagesAuthenticateCreateAccount from '@webViews/Pages/Authenticate/CreateAccount';
import PagesAuthenticateForgotPassword from '@webViews/Pages/Authenticate/ForgotPassword';
import PagesAuthenticateSignIn from '@webViews/Pages/Authenticate/SignIn';
import PagesAuthenticateBlockedDomain from '@webViews/Pages/Authenticate/BlockedDomain';
import PagesStudySession from '@webViews/Pages/Study/Session';
import PagesWorklist from '@webViews/Pages/Worklist/Worklist';
import PagesSearch from '@webViews/Pages/Worklist/Search';
import PagesSettingsProfile from '@webViews/Pages/Settings/Profile';
import PagesSettingsApplication from '@webViews/Pages/Settings/Admin/Application';
import PagesSettingsSecurity from '@webViews/Pages/Settings/Security';
import PagesSettingsAdminUsers from '@webViews/Pages/Settings/Admin/Users';
import PagesSettingsAdminWorkspaces from '@webViews/Pages/Settings/Admin/Workspaces';
import PagesSettingsAdminInstitutions from '@webViews/Pages/Settings/Admin/Institutions';
import { Profile } from '@interfaces/Profile';
import { useAuthenticationMode } from '@interfaces/Authentication';

export const routesUnauthenticated: Routes = [
  {
    default: true,
    path: RouteHelper.authSignIn,
    component: PagesAuthenticateSignIn,
  },
  {
    path: RouteHelper.authCreateAccount,
    component: PagesAuthenticateCreateAccount,
  },
  {
    path: RouteHelper.authForgotPassword,
    component: PagesAuthenticateForgotPassword,
  },
  {
    default: true,
    path: RouteHelper.blockedDomain,
    component: PagesAuthenticateBlockedDomain,
  },
];

export const routesAuthenticated: Routes = [
  {
    path: RouteHelper.search(':query?'),
    component: PagesSearch,
    onEnter: ({ params }) =>
      ViewInterfacesSearch.perform(params?.query || 'empty'),
    onLeave: ViewInterfacesSearch.unload,
  },
  {
    default: true,
    defaultPath: RouteHelper.worklist,
    path: RouteHelper.worklist(':group?', ':id?', ':status?'),
    component: PagesWorklist,
    onEnter: ({ params }) =>
      ViewInterfacesWorklist.listSessions({
        group: params?.group,
        id: params?.id,
        status: params?.status,
      }),
    onLeave: ViewInterfacesWorklist.unloadSessions,
  },
  {
    path: RouteHelper.studyArchive(':analysisId', ':sessionId'),
    component: () => null,
    onEnter: ({ params }) => {
      if (!params || !params?.sessionId) {
        throw new Error('sessionId should always be set');
      }
      return ViewInterfacesUseCaseSession.downloadStudyArchive(
        params.sessionId
      );
    },
  },
  {
    path: RouteHelper.accession(':accessionNum'),
    component: PagesStudySession,
    onEnter: ({ params }) => {
      if (!params || !params?.accessionNum) {
        throw new Error('accession number should always be set');
      }

      return ViewInterfacesStudySession.loadSessionFromAccessionNum(
        params.accessionNum
      );
    },
    onLeave: ViewInterfacesStudySession.unload,
  },
  {
    path: RouteHelper.study(':analysisId', ':sessionId?', ':tab?', ':preview?'),
    component: PagesStudySession,
    onEnter: ({ params }) => {
      if (!params || !params?.analysisId || !params?.sessionId) {
        throw new Error('analysisId should always be set');
      }

      return ViewInterfacesStudySession.init(
        params.analysisId,
        params.sessionId
      );
    },
    onLeave: ViewInterfacesStudySession.unload,
  },
  {
    path: RouteHelper.settingsProfile,
    component: PagesSettingsProfile,
    onEnter: () => ViewInterfacesPACS.listPACSStudies(),
  },
  {
    path: RouteHelper.settingsSecurity,
    component: PagesSettingsSecurity,
    onEnter: () => ViewInterfacesPACS.listPACSStudies(),
  },
];

export const routesAuthenticatedAdmin: Routes = [
  ...routesAuthenticated,
  {
    path: RouteHelper.settingsApplication,
    component: PagesSettingsApplication,
    onEnter: () => {
      return [
        ViewInterfacesPACS.listPACSStudies(),
        ViewInterfacesAdminApplication.load(),
      ];
    },
    onLeave: () => ViewInterfacesAdminApplication.unload(),
  },
  {
    path: RouteHelper.adminUsers(':userId?', true),
    component: PagesSettingsAdminUsers,
    onEnter: ({ params }) => {
      if (params?.userId && params.userId === 'new') {
        return [
          ViewInterfacesPACS.listPACSStudies(),
          ViewInterfacesAdminUsers.load(),
          ViewInterfacesAdminUsers.empty(),
        ];
      } else {
        return [
          ViewInterfacesPACS.listPACSStudies(),
          ViewInterfacesAdminUsers.load(
            params?.userId ? decodeURIComponent(params.userId) : undefined
          ),
        ];
      }
    },
    onLeave: () => ViewInterfacesAdminUsers.unload(),
  },
  {
    path: RouteHelper.adminWorkspaces(':filterConfigurationId?'),
    component: PagesSettingsAdminWorkspaces,
    onEnter: ({ params }) => {
      const useCases = [
        ViewInterfacesPACS.listPACSStudies(),
        ViewInterfacesAdminWorkspaces.load(),
      ];
      if (params?.filterConfigurationId) {
        if (params.filterConfigurationId === 'new') {
          useCases.push(ViewInterfacesAdminWorkspaces.empty());
        } else {
          useCases.push(
            ViewInterfacesAdminWorkspaces.get(params.filterConfigurationId)
          );
        }
      }
      return useCases;
    },
    onLeave: () => ViewInterfacesAdminWorkspaces.unload(),
  },
];

export const routesAuthenticatedSuperAdmin: Routes = [
  ...routesAuthenticatedAdmin,
  {
    path: RouteHelper.adminInstitutions(':institutionId?', ':userId?'),
    component: PagesSettingsAdminInstitutions,
    onEnter: ({ params }) => {
      const useCases = [
        // Always load PACS studies
        ViewInterfacesPACS.listPACSStudies(),
      ];

      if (params?.institutionId === 'new') {
        useCases.push(ViewInterfacesAdminInstitutions.load());
        useCases.push(ViewInterfacesAdminInstitutions.empty());
      } else if (params?.userId === 'new') {
        useCases.push(ViewInterfacesAdminInstitutions.emptyUser());
      } else if (params?.institutionId && params?.userId) {
        useCases.push(
          ViewInterfacesAdminInstitutions.get(
            params.institutionId,
            params.userId
          )
        );
      } else if (params?.institutionId) {
        useCases.push(
          ViewInterfacesAdminInstitutions.get(params.institutionId)
        );
      } else {
        useCases.push(ViewInterfacesAdminInstitutions.load());
      }
      return useCases;
    },
    onLeave: () => ViewInterfacesAdminInstitutions.unload('all'),
  },
];

export const getRoutes = (profile: Profile | undefined) => {
  const { isAdminDomain } = useAuthenticationMode();
  if (!profile) return routesUnauthenticated;

  if (profile.email) {
    if (isAdminDomain) {
      return routesAuthenticatedSuperAdmin;
    }

    if (profile.isAdmin) {
      return routesAuthenticatedAdmin;
    }

    return routesAuthenticated;
  }

  return routesUnauthenticated;
};

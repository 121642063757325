import { CanvasObjectAPI } from '@api/CanvasObject';
import { createUseCase } from '@helpers/createUseCase';
import { Session, initial } from '@entities/Session';
import EffectReducer, { ReducerResult } from '@library/Reducer';
import { CanvasObject, CanvasObjectData } from '@entities/CanvasObject';
import { Effect, effect, none } from '@library/Effect';

export const canvasObjectUseCase = {
  CreateCanvasObject: createUseCase(
    'CREATE_CANVAS_OBJECT'
  ).withPayload<CanvasObjectData>(),
  DeleteCanvasObject: createUseCase('DELETE_CANVAS_OBJECT').withPayload<{
    canvasObjectId: string;
  }>(),
  UpdateCanvasObject: createUseCase('UPDATE_CANVAS_OBJECT').withPayload<{
    canvasObjectId: string;
    data: CanvasObjectData;
  }>(),
  ReceiveUpdatedCanvasObject: createUseCase(
    'RECEIVE_UPDATED_CANVAS_OBJECT'
  ).withPayload<{
    canvasObject: CanvasObject;
  }>(),
  NoOp: createUseCase('NO_OP').noPayload(),
};
export type CanvasObjectUseCases = ReturnType<
  | typeof canvasObjectUseCase.CreateCanvasObject
  | typeof canvasObjectUseCase.DeleteCanvasObject
  | typeof canvasObjectUseCase.UpdateCanvasObject
  | typeof canvasObjectUseCase.ReceiveUpdatedCanvasObject
  | typeof canvasObjectUseCase.NoOp
>;

export class CanvasObjectReducer extends EffectReducer<Session> {
  constructor() {
    super();
  }

  Perform(
    session: Session = initial(),
    { type, payload: useCase }: CanvasObjectUseCases
  ): ReducerResult<Session> {
    switch (type) {
      case canvasObjectUseCase.CreateCanvasObject.type: {
        const sessionId = session.details.mapOr(a => a.id, undefined);
        return this.Result(
          {
            ...session,
          },
          sessionId ? effectCreateCanvasObject(sessionId, useCase) : none()
        );
      }

      case canvasObjectUseCase.DeleteCanvasObject.type: {
        const remainingCanvasObjects = session.data.canvasObjects.filter(
          object => object.id !== useCase.canvasObjectId
        );
        return this.Result(
          {
            ...session,
            data: {
              ...session.data,
              canvasObjects: remainingCanvasObjects,
            },
          },
          effectDeleteCanvasObject(useCase.canvasObjectId)
        );
      }

      case canvasObjectUseCase.UpdateCanvasObject.type: {
        return this.Result(
          {
            ...session,
          },
          effectUpdateCanvasObject(useCase.canvasObjectId, useCase.data)
        );
      }

      case canvasObjectUseCase.ReceiveUpdatedCanvasObject.type: {
        const oldObject = session.data.canvasObjects.find(
          object => object.id == useCase.canvasObject.id
        );

        return this.Result({
          ...session,
          data: {
            ...session.data,
            canvasObjects: oldObject
              ? session.data.canvasObjects.map(object => {
                  if (object.id == useCase.canvasObject.id) {
                    return useCase.canvasObject;
                  }
                  return object;
                })
              : [...session.data.canvasObjects, useCase.canvasObject],
          },
        });
      }

      case canvasObjectUseCase.NoOp.type: {
        return this.Result(session);
      }
    }
  }
}

const effectCreateCanvasObject = (
  sessionId: string,
  canvasObjectData: CanvasObjectData
): Effect =>
  effect(async () => {
    const result = await CanvasObjectAPI.createCanvasObject(
      sessionId,
      canvasObjectData
    );

    return canvasObjectUseCase.ReceiveUpdatedCanvasObject(result);
  }, 'effect-create-canvas-object');

const effectDeleteCanvasObject = (canvasObjectId: string): Effect =>
  effect(async () => {
    await CanvasObjectAPI.deleteCanvasObject(canvasObjectId);

    return canvasObjectUseCase.NoOp();
  }, 'effect-delete-canvas-object');

const effectUpdateCanvasObject = (
  canvasObjectId: string,
  canvasObjectData: CanvasObjectData
): Effect =>
  effect(async () => {
    const result = await CanvasObjectAPI.updateCanvasObject(
      canvasObjectId,
      canvasObjectData
    );

    return canvasObjectUseCase.ReceiveUpdatedCanvasObject(result);
  }, 'effect-update-canvas-object');

import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { t } from '@webInterfaces/I18n';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import MiniCanvasContext from '@webViews/Pages/Study/MiniCanvasContext';
import { cnames } from '@helpers/cnames';
import { Grid } from '@webMolecules/Grid/Grid';
import { IndexLabel } from '@webOrganisms/IndexLabel/IndexLabel';
import { ChangeLabel } from '@webMolecules/ChangeLabel/ChangeLabel';
import {
  eventFindingActionDeletedFinding,
  eventFindingActionSelectedFinding,
} from '@webInterfaces/Analytics';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { useDispatcher } from '@helpers/useDispatcher';
import { selectSelectedId } from '@selectors/FindingInteractionSelectors';
import {
  GradingChangeLabel,
  GradingLabel,
} from '@webOrganisms/GradingLabel/GradingLabel';
import { selectComparisonAvailable } from '@selectors/SessionSelectors';
import { Finding } from '@entities/Finding';
import { findingUseCase } from '@useCases/Finding';
import { ResultTableStyle } from '../ResultTableContext';
import { SummarySizeNodule } from '../Comparison/Utility/SummaryNoduleSize';
import styles from './findingSummary.scss';
import { MemoizedFindingSummaryRowThumbnail } from './FindingSummaryRowThumbnail';
import { FindingSummaryRowPills } from './FindingSummaryRowPills';
import { checkIfSizeMissing } from './SmallPartsStudyFindingHelper';
import { DeleteFindingDialog } from './DetailedFindingsTableDialogs';
import { FindingSizePill } from './FindingSizePill';
import { ClinicalRecommendationTag } from './ClinicalRecommendationTag';
import { FindingMissingSize } from './FindingMissingSize';
import { FindingLocation } from './FindingRow/FindingLocation';

export type LocationColumn = {
  className: string;
  key: string;
  previousKey: string;
  value: string[];
  minWidth?: string;
};

export type SizeColumn = {
  className: string;
  key: string;
  previousKey: string;
  value: (number | undefined)[];
};

export interface FindingSummaryRowProps {
  finding?: Finding;
  theme: ResultTableStyle;
  draggable?: boolean;
  interactable?: boolean;
  index: number;
}
export const FindingSummaryRow = ({
  finding,
  theme,
  draggable,
  interactable = true,
}: FindingSummaryRowProps) => {
  if (!finding) return null;

  const [showWarning, setShowWarning] = React.useState(false);
  const deleteFinding = useDispatcher(findingUseCase.DeleteFinding);

  const { miniCanvasURLs } = React.useContext(MiniCanvasContext);
  const selectedId = useSelector(selectSelectedId);
  const isComparisonAvailable = useSelector(selectComparisonAvailable);
  const selectFinding = useDispatcher(findingInteractionUseCase.Select);
  const deselectFinding = useDispatcher(findingInteractionUseCase.Deselect);
  const editFinding = useDispatcher(findingInteractionUseCase.Edit);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: finding.id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 100 : 0,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  const id = finding.id;
  if (id == undefined) {
    return null;
  }
  const isSelected = selectedId === id;

  const findingIndex = finding.index;

  const miniDiagram = () => {
    return (
      <Box
        display="flex"
        marginX="m"
        alignItems="flex-start"
        marginY="m"
        justifyContent="center"
        className={styles.findingImageContainer}
      >
        <img width="100" src={miniCanvasURLs[finding.id] || ''} />
      </Box>
    );
  };

  const renderLocationSizeColumn = () => {
    const previousGrading = finding.comparedFinding?.formatted.grading;
    const currentGrading = finding.formatted.grading;

    return (
      <Box
        key={`finding-${findingIndex}`}
        display="flex"
        fullWidth
        justifyContent="space-between"
      >
        <FindingLocation finding={finding} theme={theme} />
        {theme !== ResultTableStyle.editable && (
          <Box
            paddingRight={theme === ResultTableStyle.preview ? undefined : 'm'}
          >
            {previousGrading && currentGrading && (
              <GradingChangeLabel
                previousGrading={previousGrading}
                currentGrading={currentGrading}
              />
            )}
            {isComparisonAvailable && finding.comparedFinding === undefined && (
              <ChangeLabel
                type="absolute"
                label="NEW"
                previousValue={undefined}
                currentValue={undefined}
              />
            )}
          </Box>
        )}
      </Box>
    );
  };

  const handlerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (theme !== ResultTableStyle.editable || !interactable) return;
    if (isSelected) {
      deselectFinding();
    } else {
      eventFindingActionSelectedFinding(finding.autoGenerated);
      selectFinding({ id });
    }
  };

  const editableLayout = (
    <Box
      display="flex"
      role="button"
      fullWidth
      key={`finding-${id}-row`}
      onClick={handlerClick}
      className={styles.summaryRowBox}
    >
      {draggable && (
        <Box className={styles.dragHandle}>
          <Icon name="drag-handle" />
        </Box>
      )}
      <Box
        key={`finding-${id}-indices`}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={styles.indices}
      >
        <IndexLabel
          index={findingIndex}
          intent="information"
          borderRadius="topLeft"
          noBorder
          fluid
        />
        <GradingLabel grading={finding.formatted.grading} />
      </Box>
      <Box>{miniDiagram()}</Box>
      <Box fullWidth paddingY="m" className={styles.content}>
        {renderLocationSizeColumn()}
        {theme === ResultTableStyle.editable ? (
          <Box display="flex" className={styles.locationSize}>
            {findingSizeLayout(finding)}
          </Box>
        ) : (
          <SummarySizeNodule
            finding={finding}
            showUnitInSummary={theme === ResultTableStyle.summary}
          />
        )}

        <Box paddingTop="s">
          <FindingSummaryRowPills
            finding={finding}
            editable={theme === ResultTableStyle.editable}
          />
        </Box>
        {finding.notes && (
          <Text type="caption">
            <strong>{t('pages.study.worksheet.heading.notes')}: </strong>
            {finding.notes}
          </Text>
        )}
      </Box>
      <Box marginY="auto" paddingRight="m">
        <ClinicalRecommendationTag
          finding={finding}
          showNextPageTooltip={isComparisonAvailable}
        />
      </Box>
      {theme === ResultTableStyle.editable && interactable && (
        <Box display="flex" className={styles.editAction}>
          <Box
            padding="xs"
            onClick={e => {
              e.stopPropagation();
              editFinding({ finding });
            }}
          >
            <Icon name="edit" />
          </Box>
          <Box
            padding="xs"
            onClick={() => {
              setShowWarning(true);
            }}
          >
            <Icon color="var(--ds-color-destructive-500)" name="trash" />
          </Box>
          <DeleteFindingDialog
            isOpen={showWarning}
            onConfirm={() => {
              setShowWarning(false);
              eventFindingActionDeletedFinding(finding.autoGenerated);
              deleteFinding({ findingId: finding.id });
            }}
            onCancel={() => {
              setShowWarning(false);
            }}
          />
        </Box>
      )}
    </Box>
  );

  const previewLayout = (
    <Box className={styles.previewLayout} key={`finding-${id}-row`}>
      {draggable && (
        <Box className={styles.dragHandle}>
          <Icon name="drag-handle" />
        </Box>
      )}
      <Box key={`finding-${id}-indices`} display="flex" alignItems="stretch">
        <Box className={styles.index}>
          <Text type="caption">
            <strong>{findingIndex}</strong>
          </Text>
        </Box>
        <GradingLabel grading={finding.formatted.grading} horizontal />
      </Box>

      <Box display="flex" paddingX="m" paddingTop="xs" alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <img width="150" src={miniCanvasURLs[finding.id] || ''} />
        </Box>

        <Box fullWidth padding="xs" className={styles.content} paddingX="s">
          {renderLocationSizeColumn()}
          <SummarySizeNodule
            finding={finding}
            showUnitInSummary={theme === ResultTableStyle.summary}
          />
          <Box paddingY="xxs">
            <FindingSummaryRowPills finding={finding} />
          </Box>

          {finding.notes && (
            <Text type="caption">
              <strong>{t('pages.study.worksheet.heading.notes')}: </strong>
              {finding.notes}
            </Text>
          )}
        </Box>
      </Box>
      <Box padding="l">
        <Grid columns={2}>
          {finding.formatted.dicomIds &&
            finding.formatted.dicomIds.map(id => {
              return <MemoizedFindingSummaryRowThumbnail key={id} id={id} />;
            })}
        </Grid>
      </Box>
    </Box>
  );

  if (theme === ResultTableStyle.preview) {
    return <Card padding="none">{previewLayout}</Card>;
  }
  return (
    <>
      {draggable ? (
        <li
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          className={styles.draggableRow}
          data-testid="finding-item"
        >
          <Box
            className={cnames(styles.summaryRow, {
              [styles.isSelected]: isSelected,
              [styles.isFadeOut]: !isSelected && Boolean(selectedId),
            })}
          >
            <Card padding="none">{editableLayout}</Card>
          </Box>
        </li>
      ) : (
        <Box
          element="li"
          className={cnames(styles.summaryRow, {
            [styles.isSelected]: isSelected,
            [styles.isFadeOut]: !isSelected && Boolean(selectedId),
          })}
        >
          <Card padding="none">{editableLayout}</Card>
        </Box>
      )}
    </>
  );
};

export default FindingSummaryRow;

const findingSizeLayout = (finding: Finding) => {
  if (checkIfSizeMissing(finding)) {
    return <FindingMissingSize />;
  }

  if (finding.formatted.isSize3D) {
    const unit =
      finding.formatted.width?.unit ||
      finding.formatted.height?.unit ||
      finding.formatted.length?.unit;

    return (
      <Box display="flex">
        {(['width', 'height', 'length'] as const).map(key => {
          const size = finding.formatted[key];

          return (
            <Box key={key} marginLeft="xxxs" display="flex" alignItems="center">
              <Text type="display4" muted marginRight="xs">
                {t(`study.summary.finding_size.${key}`)}
              </Text>

              {size && size.referenceId ? (
                <FindingSizePill
                  value={size.value}
                  referenceId={size.referenceId}
                />
              ) : (
                <Text type="display3">{size?.value ?? '-'}</Text>
              )}
              {['length'].includes(key) && (
                <Text type="display4" marginLeft="xs">
                  {unit}
                </Text>
              )}

              {['width', 'height'].includes(key) && (
                <Text type="display5" muted marginX="xs">
                  •
                </Text>
              )}
            </Box>
          );
        })}
      </Box>
    );
  } else {
    const size = finding.formatted.max;

    return (
      <Box display="flex" alignItems="center">
        {size && size.referenceId ? (
          <FindingSizePill value={size.value} referenceId={size.referenceId} />
        ) : (
          <Text type="display3">{size?.value ?? '-'}</Text>
        )}
        <Text type="display4" marginLeft="xs">
          {size?.unit}
        </Text>
      </Box>
    );
  }
};

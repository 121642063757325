import {
  FindingSortType,
  SizeVariant,
  ThyroidPoleVariant,
} from '@entities/InstitutionConfiguration';
import { Workflow } from '@entities/Workflow';

export * from '@entities/Admin/Application';

export const workflowToI18nKey = (workflow: Workflow): string => {
  switch (workflow) {
    case Workflow.Sonographer:
      return 'pages.settings.application.workflow.option.sonographer';
    case Workflow.Radiologist:
      return 'pages.settings.application.workflow.option.radiologist';
  }
};

export const thyroidPoleVariantToI18nKey = (
  thyroidPoleVariant: ThyroidPoleVariant
): string => {
  switch (thyroidPoleVariant) {
    case ThyroidPoleVariant.ThreePoles:
      return 'pages.settings.application.thyroid_pole_variant.option.three_poles';
    case ThyroidPoleVariant.FivePoles:
      return 'pages.settings.application.thyroid_pole_variant.option.five_poles';
  }
};

export const sizeVariantToI18nKey = (sizeVariant: SizeVariant) => {
  switch (sizeVariant) {
    case SizeVariant.Size1D:
      return 'pages.settings.application.size_variant.option.size_1d';
    case SizeVariant.Size3D:
      return 'pages.settings.application.size_variant.option.size_3d';
  }
};

export const findingSortTypeToI8nKey = (findingSortType: FindingSortType) => {
  switch (findingSortType) {
    case FindingSortType.Side:
      return 'pages.study.finding.sort.option.side';
    case FindingSortType.Severity:
      return 'pages.study.finding.sort.option.severity';
  }
};

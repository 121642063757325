import { FilterConfiguration } from '@api/Admin/FilterConfiguration';
import { Workspaces as ContractWorkspaces } from '@contracts/Workspaces';
import { Workspace } from '@interfaces/Workspace';

export class Workspaces implements ContractWorkspaces {
  private _filterConfiguration = new FilterConfiguration();

  async List(): Promise<Workspace[]> {
    const filterConfigurationLineItems = await this._filterConfiguration.List();
    return filterConfigurationLineItems.map(a => ({
      id: a.id,
      name: a.name,
      group: 'workspace',
      isCustomWorkspace: a.isCustomWorkspace,
    }));
  }
}

import { Session } from '@interfaces/Session';

export enum ComparisonStatus {
  Unavailable = 'Unavailable', // No comparison session
  Available = 'Available', // Show the prompt and the compare tab
  Ignored = 'Ignored', // Hide the prompt but show the compare tab
  Compared = 'Compared', // At least one finding has been matched
}

export const fetchComparisonStatus = (session: Session): ComparisonStatus => {
  const analysis = session.analysis.lift();
  const details = session.details.lift();
  if (!analysis || !details) {
    return ComparisonStatus.Unavailable;
  }
  const comparisonSession = session.comparisonSession.lift();
  if (!comparisonSession) {
    return ComparisonStatus.Unavailable;
  }

  if (session.ignoreComparison) {
    return ComparisonStatus.Ignored;
  }

  const hasMatchedFinding =
    session.data.findings.filter(f => !!f.comparisonFindingId).length > 0;
  if (hasMatchedFinding) {
    return ComparisonStatus.Compared;
  }

  return ComparisonStatus.Available;
};

import React from 'react';
import { cnames } from '@helpers/cnames';
import { Icon } from '../Icon/Icon';
import { Box } from '../Box/Box';
import styles from './field.scss';

export interface FieldProps {
  disabled?: boolean;
  fluid?: boolean;
  form?: string;
  iconAfter?: JSX.Element;
  iconBefore?: JSX.Element;
  intent?: 'success' | 'neutral' | 'error' | 'required' | 'warning' | 'primary';
  intentIcon?: React.FC;
  pill?: boolean;
  readOnly?: boolean;
  size?: 'small' | 'medium' | 'large';
  width?: string;
  strong?: boolean;
}

export const Field: React.FC<FieldProps> = ({
  children,
  disabled,
  fluid,
  iconAfter,
  iconBefore,
  intent,
  intentIcon: _intentIcon,
  pill,
  readOnly,
  size = 'medium',
  width,
  strong = false,
}) => {
  const fieldClassNames = cnames(styles.field, {
    [styles.disabled]: disabled,
    [styles.fluid]: fluid,
    [styles.iconAfter]: Boolean(iconAfter),
    [styles.iconBefore]: Boolean(iconBefore),
    [styles.pill]: pill,
    [styles.readOnly]: readOnly,
    [styles[intent as string]]: Boolean(intent),
    [styles[size]]: Boolean(size),
    [styles.strong]: strong,
  });

  const iconAfterResolved = iconAfter
    ? iconAfter
    : intent
      ? getIcon(undefined, intent, size)
      : null;

  const iconClassNames = cnames(styles.icon, {
    [styles.before]: Boolean(iconBefore),
    [styles.after]: Boolean(iconAfterResolved),
  });

  return (
    <Box className={fieldClassNames} style={{ width }}>
      {iconBefore && (
        <Box
          className={iconClassNames}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {iconBefore}
        </Box>
      )}

      {children}

      {iconAfterResolved && (
        <Box
          className={iconClassNames}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {iconAfterResolved}
        </Box>
      )}
    </Box>
  );
};

const getIcon = (
  loading?: boolean,
  intent?: FieldProps['intent'],
  size?: string
): null | JSX.Element => {
  if (loading) {
    return (
      <Icon
        name="circle-top-right"
        color="var(--ds-color-information-500)"
        size={size === 'small' ? 'small' : undefined}
      />
    );
  } else {
    switch (intent) {
      case 'error':
        return (
          <Icon
            name="alert-circle"
            color="var(--ds-color-destructive-500)"
            size={size === 'small' ? 'small' : undefined}
          />
        );
      case 'required':
        return (
          <Icon
            name="asterix"
            color="var(--ds-color-destructive-500)"
            size={size === 'small' ? 'small' : undefined}
          />
        );
      case 'success':
        return (
          <Icon
            name="check-circle"
            color="var(--ds-color-success-500)"
            size={size === 'small' ? 'small' : undefined}
          />
        );
      case 'neutral':
        return (
          <Icon
            name="circle"
            color="var(--ds-color-neutral-600)"
            size={size === 'small' ? 'small' : undefined}
          />
        );
      case 'warning':
        return (
          <Icon
            name="alert-triangle"
            color="var(--ds-color-warning-500)"
            size={size === 'small' ? 'small' : undefined}
          />
        );
    }
  }

  return null;
};

import * as Contract from '@contracts/Admin/Institution';
import { InstitutionConfiguration } from '@entities/InstitutionConfiguration';
import { post } from '@api/schema/client';
import {
  decodeInstitutionConfig,
  encodeInstitutionConfig,
} from '@api/decoders/Institution';

export class Institution implements Contract.Institution {
  async GetInstitutionConfiguration(): Promise<InstitutionConfiguration> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post('/get-institution-config', {});

        resolve(decodeInstitutionConfig(data.config));
      } catch (e) {
        reject(e);
      }
    });
  }

  async UpdateInstitutionConfiguration(
    config: InstitutionConfiguration
  ): Promise<InstitutionConfiguration> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await post('/admin/update-institution-config', {
          body: { config: encodeInstitutionConfig(config) },
        });

        resolve(decodeInstitutionConfig(data.config));
      } catch (e) {
        reject(e);
      }
    });
  }
}

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import styles from './sidebarHint.scss';

export interface SidebarHintProps {
  children?: React.ReactNode;
}

export const SidebarHint = ({ children }: SidebarHintProps) => (
  <Box className={styles.sidebarHint} padding="l">
    <Text type="caption" muted>
      {children}
    </Text>
  </Box>
);

import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { Routes } from '@webViewInterfaces/Routes';
import { useHistory as locationUseHistory } from '@webViewInterfaces/UseCase/Location';
import { dispatch } from '@webInterfaces/Store';
import { Actions } from '@webInterfaces/StoreTypes';
import ErrorBoundary from '@webViews/ErrorBoundary/ErrorBoundary';
import { onError } from '@webInterfaces/Error';
import RoutedPage from './RoutedPage';
interface NoMatchInterceptorProps {
  isAuthenticated: boolean;
}

const NoMatchInterceptor: React.FC<NoMatchInterceptorProps> = ({
  isAuthenticated,
  children,
}) => {
  const [ready, setReady] = React.useState(false);
  const [originalPathname, setOriginalPathname] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    if (isAuthenticated) {
      const locationFromStorage = window.sessionStorage.getItem('location');
      if (locationFromStorage) {
        setOriginalPathname(locationFromStorage);
        window.sessionStorage.removeItem('location');
      }
    } else {
      window.sessionStorage.setItem('location', window.location.pathname);
    }

    setReady(true);
  }, []);

  if (originalPathname) {
    return <Redirect to={originalPathname} />;
  }

  return ready ? <>{children}</> : null;
};
export const ReactRouter = (
  isAuthenticated: boolean,
  routes: Routes,
  children?: React.ReactNode,
  originalPathname?: string
): JSX.Element => {
  const defaultRoute = routes.filter(a => a.default).shift();
  const defaultRoutePath =
    originalPathname ||
    (defaultRoute
      ? defaultRoute.defaultPath
        ? defaultRoute.defaultPath()
        : defaultRoute.path
      : '/');
  const redirect = defaultRoute ? (
    <Redirect strict exact from="/" to={defaultRoutePath} />
  ) : null;
  const noMatch = defaultRoute ? (
    <Route>
      <NoMatchInterceptor isAuthenticated={isAuthenticated}>
        <Redirect to={defaultRoutePath} />
      </NoMatchInterceptor>
    </Route>
  ) : null;

  const dispatchHelper = (actions: Actions | Actions[]) => {
    if (actions.constructor.name === 'Array') {
      (actions as Actions[]).forEach(dispatch);
    } else {
      dispatch(actions as Actions);
    }
  };

  const StateHistory = () => {
    const history = useHistory();
    dispatch(
      locationUseHistory({
        push: (a: string) => history.push(a),
        replace: (a: string) => history.replace(a),
      })
    );
    return null;
  };

  return (
    <BrowserRouter>
      <StateHistory />
      {children}
      <ErrorBoundary onError={onError}>
        <Switch>
          {redirect}
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              render={routerProps => {
                const onXArguments = {
                  params: routerProps.match.params,
                };
                const onEnter = () => {
                  if (typeof route.onEnter === 'function') {
                    dispatchHelper(route.onEnter(onXArguments));
                  }
                };
                const onUpdate = () => {
                  if (typeof route.onUpdate === 'function') {
                    dispatchHelper(route.onUpdate(onXArguments));
                  } else {
                    onEnter();
                  }
                };
                const onLeave = () => {
                  if (typeof route.onLeave === 'function') {
                    dispatchHelper(route.onLeave(onXArguments));
                  }
                };

                const Component = route.component;
                return (
                  <RoutedPage
                    path={routerProps.match.url}
                    onEnter={onEnter}
                    onUpdate={onUpdate}
                    onLeave={onLeave}
                  >
                    <Component routerProps={routerProps} />
                  </RoutedPage>
                );
              }}
            />
          ))}
          {noMatch}
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export const getSearchParam = (name: string): string => {
  // @TODO useLocation raises Hooks/React error ?
  const value = new URLSearchParams(window.location.search).get(name);
  return value ? value : '';
};

export default ReactRouter;

import { DistanceUnit } from './Unit';

export interface Detection {
  id: string;
  boundingBox: BoundingBox;
  assignedFinding?: {
    id: string;
    index: number;
    autoGenerated: boolean;
  };
  detectionSizes: DetectionSize[];
}

export type BoundingBox = {
  xMax: number;
  xMin: number;
  yMax: number;
  yMin: number;
  offsetX: number;
  offsetY: number;
  heightInMm?: number;
  widthInMm?: number;
  confidenceClass?: string;
};

export interface DetectionSize {
  id: string;
  path?: {
    start: Point;
    end: Point;
  };
  size: number;
  annotatedSize?: number | null;
  unit: DistanceUnit;
  dimensionType: DimensionType;
}

export type Point = {
  x: number;
  y: number;
};

export enum DimensionType {
  Width = 'width',
  Height = 'height',
  Length = 'length',
}

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Header, HeaderSlot } from '@webOrganisms/Header/Header';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { Profile } from '@interfaces/Profile';
import { t } from '@webInterfaces/I18n';

export interface BoundaryProps {
  onSignOut: () => void;
  profile?: Profile;
}

export const Boundary: React.FC<BoundaryProps> = ({ onSignOut, profile }) => {
  const onBackToWorklist = () => {
    window.location.href = '/';
  };

  return (
    <>
      {profile && (
        <Header profile={profile} onSignOut={onSignOut}>
          <HeaderSlot>
            <Text type="display2">{t('pages.error_boundary.title')}</Text>
          </HeaderSlot>
        </Header>
      )}
      <Box display="flex">
        <Box role="main" flex="1" paddingY="xxl" paddingX="xl">
          <Box marginBottom="xl">
            <Text type="display1">{t('pages.error_boundary.heading')}</Text>
            <Box marginTop="s">
              <Text>{t('pages.error_boundary.blurb')}</Text>
            </Box>
          </Box>
          {profile && (
            <Button
              intent="primary"
              strong
              iconBefore={<Icon name="chevron-left" />}
              onClick={onBackToWorklist}
            >
              {t('pages.error_boundary.back_to_worklist')}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

import React from 'react';
import { GalleryFilter, defaultGalleryFilter } from '@interfaces/GalleryFilter';

export enum ResultTableStyle {
  editable = 'result_table_style--editable',
  summary = 'result_table_style--summary',
  preview = 'result_table_style--preview',
}

export interface ResultTableContextProps {
  setGalleryFilter: React.Dispatch<React.SetStateAction<GalleryFilter>>;
  galleryFilter: GalleryFilter;
  theme: ResultTableStyle;
  applyFindingFilter?: boolean;
  inlineResultTableFlexDirection?: 'column' | 'row';
}

const ResultTableContext = React.createContext<ResultTableContextProps>({
  setGalleryFilter: () => null,
  galleryFilter: defaultGalleryFilter,
  theme: ResultTableStyle.editable,
  applyFindingFilter: false,
  inlineResultTableFlexDirection: 'column',
});

export default ResultTableContext;

import React from 'react';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import SmallPartsStudyWorkSheetLayout from './SmallPartsStudyWorkSheetLayout';

const SmallPartsStudyWorkSheet: React.FC = () => {
  return (
    <MiniCanvasProvider>
      <SmallPartsStudyWorkSheetLayout />
    </MiniCanvasProvider>
  );
};
export default SmallPartsStudyWorkSheet;

import { schemas } from '@api/schema/client';
import { StudyBatchStatusEnum } from '@api/schema/generated';
import {
  BatchStatus,
  StudyBatchStatusItem,
} from '@interfaces/StudyBatchStatusItem';

export const decodeStudyBatchStatus = (
  batchId: string,
  data: schemas['BatchStatusResponse']
): StudyBatchStatusItem => {
  let status = BatchStatus.Pending;
  if (data.status === StudyBatchStatusEnum.ANALYSED) {
    status = BatchStatus.Ready;
  } else if (
    data.status === StudyBatchStatusEnum.DISCARDED ||
    data.status === StudyBatchStatusEnum.ERROR
  ) {
    status = BatchStatus.Error;
  }

  const analyses = data.analyses.map(a => ({
    analysisId: a.analysis_id,
    sessionIds: a.sessions.map(s => s.session_id),
  }));

  return {
    batchId,
    status,
    analyses,
    // TODO
    filesFailed: 0,
    filesProcessed: 0,
    filesTotal: 0,
  };
};

import {
  FilterConfiguration,
  FilterConfigurationProperty,
} from '@entities/FilterConfiguration';
import { schemas } from '@api/schema/client';
import { decode as decodeFilterProperty } from './FilterProperty';

export const decode = (
  data: schemas['FilterConfigurationResponse']
): FilterConfiguration => {
  const filterProperties = data.filter_properties.map(fp => {
    return decodeFilterProperty(fp);
  });

  const stackedFilterProperties = filterProperties.reduce(
    (acc: FilterConfigurationProperty[], fp) => {
      const parent = acc.find(p => p.id == fp.parentId);
      if (parent) {
        parent.childProperties = [...(parent?.childProperties || []), fp];
      }
      return acc;
    },
    filterProperties.filter(fp => !fp.parentId) as FilterConfigurationProperty[]
  );

  return {
    id: data?.filter_configuration_id || '',
    name: data?.display_name || '',
    description: data?.description || '',
    properties: stackedFilterProperties,
    isCustomWorkspace: data?.is_custom_workspace || false,
  };
};

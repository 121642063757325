import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import {
  toProfile,
  toSearch,
  toAdmin,
  toPACS,
  toAppSettings,
} from '@interfaces/App';
import { toApp, toMessages } from '@interfaces/Root';
import { Message } from '@interfaces/Message';
import { toUsers } from '@interfaces/Admin';
import { Profile } from '@interfaces/Profile';
import { ApiStatus } from '@interfaces/Status';
import {
  createUser,
  deleteUser,
  updateUser,
  unload,
} from '@webViewInterfaces/Settings/Admin/Users';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import * as ViewModelLayout from '@webViewModels/Pages/Settings/Layout';

export type Users = UsersProps & UsersDispatchers & ViewModelLayout.Layout;

export interface UsersProps {
  profile: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  searchQuery: string;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  userLineItems: Profile[];
  targetUserLineItem?: Profile;
  loading: boolean;
  messages: Message[];
}

export interface UsersDispatchers {
  dispatchCreateUser: (userLineItem: Profile) => Dispatch<Actions>;
  dispatchUpdateUser: (userLineItem: Profile) => Dispatch<Actions>;
  dispatchDeleteUser: (id: string) => Dispatch<Actions>;
  dispatchUnloadTargetLineItem: () => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  ...ViewModelLayout.dispatchers(dispatch),
  dispatchCreateUser: (userLineItem: Profile) =>
    dispatch(createUser(userLineItem)),
  dispatchUpdateUser: (userLineItem: Profile) =>
    dispatch(updateUser(userLineItem)),
  dispatchDeleteUser: (id: string) => dispatch(deleteUser(id)),
  dispatchUnloadTargetLineItem: () => dispatch(unload(true)),
});

export const fromState = (state: State): UsersProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const users = toUsers(toAdmin(app));
  const userLineItems = users.userLineItems.second();
  const loading = users.userLineItems.first() === ApiStatus.Busy;
  const profile = toProfile(app);
  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const messages = toMessages(state);
  const search = toSearch(app);
  const searchQuery = search.query;
  const targetUserLineItem = users.targetUserLineItem
    .andThen(a => a.second())
    .orMaybe(users.newTargetUserLineItem)
    .lift();
  const pacs = toPACS(app);
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;
  return {
    loading,
    profile,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    targetUserLineItem,
    searchQuery,
    pacsStudyLineItems,
    pacsSystemStatus,
    messages,
    userLineItems,
  };
};

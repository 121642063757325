import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { cnames } from '@helpers/cnames';
import {
  FINDING_SIZE_DIMENSION,
  FINDING_SIZE_LENGTH,
  FINDING_VOLUME,
  labelColor,
  getIconByIconStyle,
} from '@webOrganisms/ResultTable/FindingsTable/Shared';
import DarkThemeColors from '@webAtoms/Theme/theme.dark.scss';
import {
  ScreenWidth,
  getScreenWidth,
} from 'src/web/stories/helpers/MediaQueries';
import { Tooltip, TooltipPosition } from '@webMolecules/Tooltip/Tooltip';
import styles from '../comparison.scss';
import { FindingNoduleUnit } from './FindingNoduleUnit';

interface FindingNoduleSizeValuesProps {
  side: string;
  value: number;
  unit?: string;
  isCompare?: boolean;
  showUnitInSummary?: boolean;
  muteSizeValue?: boolean;
}
export const FindingNoduleSizeValues = ({
  side,
  value,
  unit,
  isCompare,
  showUnitInSummary: showUnit,
  muteSizeValue = false,
}: FindingNoduleSizeValuesProps) => {
  const isLargeScreen = getScreenWidth() === ScreenWidth.Large;
  const isNumber = !isNaN(value);
  const checkNumberValue = isNumber ? Number(value) : 'N/A';
  const iconStyle = labelColor(Number(checkNumberValue));
  const textClassName = cnames(styles[`${iconStyle}-text-color`]);
  const hideUnitTooltip = !Boolean(unit);
  const headerUnit = [
    FINDING_SIZE_LENGTH,
    FINDING_VOLUME,
    FINDING_SIZE_DIMENSION,
  ].includes(side);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      gap={side === 'size' ? 'normal' : 'xs'}
      paddingLeft={side === 'size' ? 'none' : 'xxxs'}
      className={styles.sizeValueContainer}
    >
      <Text type="body">{t(`study.summary.finding_size.${side}`, '')}</Text>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={styles.sizeValue}
      >
        {isCompare ? (
          <Box className={textClassName} display="flex" alignItems="center">
            {isNumber && (
              <Icon
                size="small"
                name={getIconByIconStyle(iconStyle)}
                color={
                  iconStyle === 'positive'
                    ? DarkThemeColors.textDestructiveColor
                    : DarkThemeColors.textSuccessColor
                }
              ></Icon>
            )}
            <Text type="body2" lineClamp={1} noShrink>
              {isNumber ? `${value} %` : 'N/A'}
            </Text>
          </Box>
        ) : (
          <Tooltip
            disabled={isLargeScreen || hideUnitTooltip}
            label={unit as string}
            position={TooltipPosition.Top}
          >
            <Box
              display="flex"
              className={muteSizeValue ? styles.findingMutedText : ''}
            >
              <Text type="display5" lineClamp={1}>
                {checkNumberValue}
              </Text>
            </Box>
          </Tooltip>
        )}
      </Box>

      {headerUnit && unit && (
        <FindingNoduleUnit
          unit={unit}
          showUnit={showUnit || side === FINDING_SIZE_DIMENSION}
        />
      )}
    </Box>
  );
};

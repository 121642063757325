import React from 'react';
import { Dicom } from '@interfaces/Dicom';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Card } from '@webMolecules/Card/Card';
import { Grid } from '@webMolecules/Grid/Grid';
import { Image } from '@webMolecules/Image/Image';
import { GridType } from '@webViewModels/Pages/Study/GalleryHelper';
import { useImageContext } from '@hooks/useImageContext';
import { Video } from '@webMolecules/Video/Video';
import styles from './gallery.scss';
import { DebugOverlay } from './DebugGalleryOverlay';
import { ThumbnailIcon } from './ThumbnailIcon';

export interface GalleryThumbnailProps {
  loading?: boolean;
  isFullscreen?: boolean;
  handleSetView?: (type: GridType, index: number) => void;
  view: string;
  index: number;
  dicom: Dicom;
  debugDicom: boolean;
}

export const GalleryThumbnail = ({
  loading,
  isFullscreen = false,
  handleSetView = () => null,
  view,
  index,
  dicom,
  debugDicom,
}: GalleryThumbnailProps) => {
  const { getImage, getVideo } = useImageContext();
  const imageSrc = getImage(dicom.id);

  if (!imageSrc) {
    return <Box className={styles.thumbnailSkeleton} />;
  }

  return (
    <Box data-thumbnail display="flex">
      <Card padding="none" flex="1">
        <Box className={isFullscreen ? styles.thumbnailFull : styles.thumbnail}>
          <Box className={styles.imageContainer}>
            {dicom.hasVideo ? (
              <Video
                data-testid={dicom.id}
                src={getVideo(dicom.id)}
                fluid
                poster={imageSrc}
                frameRate={dicom.frameRate}
              />
            ) : (
              <Image
                data-testid={dicom.id}
                src={imageSrc}
                roundedCorners={false}
                fluid
                ratio="landscape"
                loading="lazy"
              />
            )}

            <ThumbnailIcon
              isFullscreen={isFullscreen}
              handleSetView={handleSetView}
              view={view}
              index={index}
            />

            <DebugOverlay dicom={dicom} debugDicom={debugDicom} />
          </Box>

          {loading && (
            <Box padding="m" display="flex">
              <Grid columns={3}>
                <Box marginRight="s" key={dicom.id}>
                  <Text
                    type="caption"
                    display="block"
                    muted
                    marginBottom="xxs"
                    loading
                  >
                    load
                  </Text>
                  <Text type="display6" display="block" loading>
                    loading
                  </Text>
                </Box>
              </Grid>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export const MemoizedGalleryThumbnail = React.memo(GalleryThumbnail);

import { Action } from 'redux';
import * as Routes from '@helpers/routes';
import { just, map2, maybe } from '@monads/Maybe';
import { ApiStatus } from '@entities/Status';
import { Analysis } from '@entities/Analysis';
import { PDFTriggerAction, Session, initial } from '@entities/Session';
import { Search as ContractSearch } from '@contracts/Search';
import { Analyses as ContractAnalyses } from '@contracts/Analyses';
import { Session as ContractSession } from '@contracts/Session';
import { Storage as ContractStorage } from '@contracts/Storage';
import { PDF as ContractPDF } from '@contracts/PDF';
import { Profiles as ContractProfiles } from '@contracts/Profiles';
import { Encryption as ContractEncryption } from '@contracts/Encryption';
import Effect, { effect, none, batch } from '@library/Effect';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import {
  SessionLineItem,
  SessionStatus,
  PatientHealthInfo,
  SessionMetadata,
  SessionConfiguration,
  WorksheetExtraInfoObject,
} from '@interfaces/SessionLineItem';
import { Profile } from '@entities/Profile';
import { appUseCase } from '@useCases/App';
import { notificationUseCase } from '@useCases/Notifications';
import { Notification } from '@entities/Notifications';
import { logError } from '@webInterfaces/Error';
import { FinaliseStatus } from '@interfaces/Status';
import { StudyLayout } from '@interfaces/Session';
import { getLayoutFromStudyType } from '@config/Gallery/LayoutConfig';
import { FilterOptions } from '@interfaces/FilterOptions';
import { WorkspaceGroup } from '@interfaces/WorkspaceGroup';
import { useAuthenticationMode } from '@interfaces/Authentication';
import { createUseCase } from '@helpers/createUseCase';
import { parseDicomName } from '@impl/generic/DicomHelpers';
import { FindingInteraction } from '@entities/FindingInteraction';
import { WorklistLineItem } from '@entities/Worklist';
import { StudyType } from '@entities/StudyType';
import { getFeatureFlags } from '@entities/FeatureFlag';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { dispatch } from '@webInterfaces/Store';
import { SizeVariant } from '@entities/InstitutionConfiguration';
import { FindingInteractionReducer } from './FindingInteraction';
import { FindingReducer } from './Finding';
import { CanvasObjectReducer } from './CanvasObject';
import { analysisUploadsUseCase } from './AnalysisUploads';

export const sessionUseCase = {
  /**
   * Initialize a session
   * - Providing the sessionId is optional.
   *   In the case the sessionId is not provided an attempt is made to locate the session
   *   for the currently logged in user. When no session can be found a new session is created.
   */
  Init: createUseCase('SESSION_USE_CASE_INIT').withPayload<{
    analysisId: string;
    sessionId?: string;
  }>(),

  /**
   * Initialize a session
   * - The result of the effectLoadSession when a session was NOT found
   */
  InitSessionNotFound: createUseCase(
    'SESSION_USE_CASE_INIT_SESSION_NOT_FOUND'
  ).withPayload<{
    analysisId: string;
  }>(),

  /**
   * Initialize a session
   * - The result of the effect effectLoadAnalysis
   */
  InitReceiveAnalysis: createUseCase(
    'SESSION_USE_CASE_INIT_RECEIVE_ANALYSIS'
  ).withPayload<{
    analysis: Analysis;
  }>(),

  /**
   * Update the comparison session state
   */
  UpdateComparisonSession: createUseCase(
    'SESSION_USE_CASE_UPDATE_COMPARISON_SESSION'
  ).withPayload<{
    currentSessionId?: string; // Used if sessionId in store is undefined
    comparisonSessionId: string;
  }>(),

  /**
   * Initialize a session
   * - The result of the effect effectLoadAnalysis
   */
  ReceiveComparisonSession: createUseCase(
    'SESSION_USE_CASE_RECEIVE_COMPARISON_SESSION'
  ).withPayload<{
    analysis: Analysis;
  }>(),

  /**
   * Initialize a session
   * - The result of the effectLookupSession
   */
  InitReceiveLookup: createUseCase(
    'SESSION_USE_CASE_INIT_RECEIVE_LOOKUP'
  ).withPayload<{
    analysisId: string;
    sessionId: string;
  }>(),

  /**
   * Initialize a session
   * - The result of the effectNewSession
   */
  InitReceiveNew: createUseCase(
    'SESSION_USE_CASE_INIT_RECEIVE_NEW'
  ).withPayload<{
    analysisId: string;
    sessionId?: string;
  }>(),

  /**
   * Initialize a session
   * - The result of the effectLoadOwner
   */
  InitReceiveOwner: createUseCase(
    'SESSION_USE_CASE_INIT_RECEIVE_OWNER'
  ).withPayload<{
    owner: Profile;
  }>(),

  /**
   * Set Collaborators
   */
  SetCollaborators: createUseCase(
    'SESSION_USE_CASE_SET_COLLABORATORS'
  ).withPayload<{
    collaborators: string[];
  }>(),

  /**
   * Unload a session
   */
  Unload: createUseCase('SESSION_USE_CASE_UNLOAD').noPayload(),

  /**
   * Reset the specified session or the current loaded session
   */
  Reset: createUseCase('SESSION_USE_CASE_RESET').noPayload(),

  /**
   * Start or resume a session
   * - Receive session id
   */
  OpenReceiveSessionId: createUseCase(
    'SESSION_USE_CASE_OPEN_RECEIVE_SESSION_ID'
  ).withPayload<{
    sessionId: string;
  }>(),

  /**
   * Finalise a session
   */
  FinaliseSession: createUseCase(
    'SESSION_USE_CASE_FINALISE_SESSION'
  ).withPayload<{
    htmlElement: HTMLElement;
    onUpdate: (useCase: Action) => void;
    useSinglePage?: boolean;
  }>(),

  /**
   * Set the status of a the current loaded session
   */
  SetStatus: createUseCase('SESSION_USE_CASE_SET_STATUS').withPayload<{
    status: SessionStatus;
  }>(),

  /**
   * Mark as bookmarked
   */
  MarkAsBookmarked: createUseCase(
    'SESSION_USE_CASE_MARK_AS_BOOKMARKED'
  ).withPayload<{
    sessionId: string;
    isBookmarked: boolean;
  }>(),

  /**
   * Finish setting the status of a the current loaded session
   */
  SetStatusFinished: createUseCase(
    'SESSION_USE_CASE_SET_STATUS_FINISHED'
  ).withPayload<{
    status: SessionStatus;
  }>(),

  /**
   * Begin generating the PDF by setting the trigger to render it offscreen
   */
  InitGeneratePDF: createUseCase(
    'SESSION_USE_CASE_INIT_GENERATE_PDF'
  ).withPayload<{
    pdfTriggerAction: PDFTriggerAction;
  }>(),

  /**
   * Generate and store a PDF (based on a html element containing the pdfs content)
   */
  GeneratePDF: createUseCase('SESSION_USE_CASE_GENERATE_PDF').withPayload<{
    htmlElement: HTMLElement;
    saveOnDisk: boolean;
    saveOnRemote: boolean;
    onUpdate: (useCase: Action) => void;
    useSinglePage?: boolean;
  }>(),

  /**
   * Download an encrypted PDF file
   */
  DownloadEncryptedPDF: createUseCase(
    'SESSION_USE_CASE_DOWNLOAD_ENCRYPTED_PDF'
  ).withPayload<{
    encryptedPdfUrl: string;
    fileName?: string;
  }>(),

  /**
   * Download the study archive
   */
  DownloadStudyArchive: createUseCase(
    'SESSION_USE_CASE_DOWNLOAD_STUDY_ARCHIVE'
  ).withPayload<{
    sessionId: string;
  }>(),

  /**
   * Download the study almond result
   */
  DownloadStudyAlmondResult: createUseCase(
    'SESSION_USE_CASE_DOWNLOAD_STUDY_ALMOND_RESULT'
  ).withPayload<{
    sessionId: string;
  }>(),

  /**
   * Search study by accession and study Id from dicomDB
   */
  SearchStudy: createUseCase('SESSION_USE_CASE_SEARCH_STUDY').withPayload<{
    studyId: string;
    accession: string;
  }>(),

  /**
   * Generate and store a PDF (based on a html element containing the pdfs content)
   * - Stored on S3
   */
  GeneratePDFStored: createUseCase(
    'SESSION_USE_CASE_GENERATE_PDF_STORED'
  ).withPayload<{
    htmlElement: HTMLElement;
    useSinglePage?: boolean;
    initial?: boolean;
  }>(),

  /**
   * Update the current studyLayout
   */
  UpdateStudyLayoutConfiguration: createUseCase(
    'SESSION_USE_CASE_UPDATE_STUDY_LAYOUT_CONFIGURATION'
  ).withPayload<{
    studyLayoutConfig: StudyLayout;
  }>(),

  /**
   * Updates the finalise study status
   */
  UpdateFinaliseStatus: createUseCase(
    'SESSION_USE_CASE_UPDATE_FINALISE_STATUS'
  ).withPayload<{
    finaliseStatus: FinaliseStatus;
  }>(),

  /**
   * Patient Information saved successfully
   */
  UpdatePatientInfoSuccess: createUseCase(
    'SESSION_USE_CASE_UPDATE_PATIENT_INFO_SUCCESS'
  ).noPayload(),

  /**
   * Updates the sessions metadata
   */
  UpdateMetadata: createUseCase(
    'SESSION_USE_CASE_UPDATE_METADATA'
  ).withPayload<{
    metadata: SessionMetadata;
  }>(),

  /**
   * After the metadata has been updated
   */
  MetadataUpdated: createUseCase(
    'SESSION_USE_CASE_METADATA_UPDATED'
  ).withPayload<{
    metadata: SessionMetadata;
  }>(),

  /**
   * Updates the sessions configuration
   */
  UpdateConfiguration: createUseCase(
    'SESSION_USE_CASE_UPDATE_CONFIGURATION'
  ).withPayload<{
    configuration: SessionConfiguration;
    optimistic: boolean;
  }>(),

  /**
   * After the configuration has been updated
   */
  ConfigurationUpdated: createUseCase(
    'SESSION_USE_CASE_CONFIGURATION_UPDATED'
  ).withPayload<{
    configuration: SessionConfiguration;
  }>(),

  /**
   * Set decrypted Session
   */
  SetDecryptedSession: createUseCase(
    'SESSION_USE_CASE_SET_DECRYPTED_SESSION'
  ).withPayload<{
    sessionLineItem: SessionLineItem;
  }>(),

  /**
   * Set Session Owner
   */
  SetSessionOwner: createUseCase(
    'SESSION_USE_CASE_SET_SESSION_OWNER'
  ).withPayload<{
    sessionLineItem: SessionLineItem;
    userId: string;
  }>(),

  /**
   * Set Session Owner Result
   */
  SetSessionOwnerResult: createUseCase(
    'SESSION_USE_CASE_SET_SESSION_OWNER_RESULT'
  ).withPayload<{
    sessionLineItem: SessionLineItem;
  }>(),

  /**
   * Pdf generated
   */
  PdfGenerated: createUseCase('SESSION_USE_CASE_PDF_GENERATED').noPayload(),

  /**
   * Mark the session to ignore comparison prompt
   */
  IgnoreComparison: createUseCase(
    'SESSION_USE_CASE_IGNORE_COMPARISON'
  ).noPayload(),

  /**
   * Display a notification
   */
  DisplayNotification: createUseCase(
    'SESSION_USE_CASE_DISPLAY_NOTIFICATION'
  ).withPayload<{
    notification: Omit<Notification, 'id'>;
  }>(),

  /**
   * Load latest session by accession number
   */
  LoadFromAccessionNum: createUseCase(
    'SESSION_USE_CASE_LOAD_SESSION_FROM_ACCESSION_NUM'
  ).withPayload<{
    accessionNum: string;
  }>(),

  /**
   * Can not load the latest session by accession number
   * Redirect user to the active worklist page
   */
  LoadFromAccessionNumNotFound: createUseCase(
    'SESSION_USE_CASE_LOAD_SESSION_FROM_ACCESSION_NUM_NOT_FOUND'
  ).noPayload(),

  /**
   * Update thyroid general characteristics
   */
  UpdateThyroidGeneralCharacteristics: createUseCase(
    'SESSION_USE_CASE_UPDATE_THYROID_GENERAL_CHARACTERISTICS'
  ).withPayload<{
    thyroidGeneralCharacteristic: ThyroidGeneralCharacteristics;
  }>(),

  /**
   * Receive Updated thyroid general characteristics and study impression
   */
  ReceiveUpdatedThyroidGeneralCharacteristics: createUseCase(
    'SESSION_USE_CASE_RECEIVE_UPDATED_THYROID_GENERAL_CHARACTERISTICS'
  ).withPayload<{
    thyroidGeneralCharacteristics: ThyroidGeneralCharacteristics;
    impressions?: string;
  }>(),

  /**
   * Set session viewed
   */
  SetSessionViewed: createUseCase(
    'SESSION_USE_CASE_SET_SESSION_VIEWED'
  ).noPayload(),

  OverrideSize3D: createUseCase(
    'SESSION_USE_CASE_OVERRIDE_3D_SIZE'
  ).withPayload<{
    isSize3D: boolean;
  }>(),

  /**
   * No Operation
   */
  NoOp: createUseCase('SESSION_USE_CASE_NO_OP').noPayload(),
};

/**
 *  All UseCases
 */
export type SessionUseCases = ReturnType<
  | typeof sessionUseCase.Init
  | typeof sessionUseCase.InitSessionNotFound
  | typeof sessionUseCase.InitReceiveLookup
  | typeof sessionUseCase.InitReceiveAnalysis
  | typeof sessionUseCase.InitReceiveOwner
  | typeof sessionUseCase.Unload
  | typeof sessionUseCase.Reset
  | typeof sessionUseCase.SetStatus
  | typeof sessionUseCase.SetStatusFinished
  | typeof sessionUseCase.FinaliseSession
  | typeof sessionUseCase.InitGeneratePDF
  | typeof sessionUseCase.GeneratePDF
  | typeof sessionUseCase.GeneratePDFStored
  | typeof sessionUseCase.DownloadEncryptedPDF
  | typeof sessionUseCase.DownloadStudyArchive
  | typeof sessionUseCase.DownloadStudyAlmondResult
  | typeof sessionUseCase.UpdateStudyLayoutConfiguration
  | typeof sessionUseCase.UpdateFinaliseStatus
  | typeof sessionUseCase.UpdateComparisonSession
  | typeof sessionUseCase.ReceiveComparisonSession
  | typeof sessionUseCase.SetCollaborators
  | typeof sessionUseCase.UpdatePatientInfoSuccess
  | typeof sessionUseCase.UpdateMetadata
  | typeof sessionUseCase.MetadataUpdated
  | typeof sessionUseCase.MarkAsBookmarked
  | typeof sessionUseCase.UpdateConfiguration
  | typeof sessionUseCase.ConfigurationUpdated
  | typeof sessionUseCase.SetDecryptedSession
  | typeof sessionUseCase.SetSessionOwner
  | typeof sessionUseCase.SetSessionOwnerResult
  | typeof sessionUseCase.PdfGenerated
  | typeof sessionUseCase.IgnoreComparison
  | typeof sessionUseCase.DisplayNotification
  | typeof sessionUseCase.LoadFromAccessionNum
  | typeof sessionUseCase.LoadFromAccessionNumNotFound
  | typeof sessionUseCase.UpdateThyroidGeneralCharacteristics
  | typeof sessionUseCase.ReceiveUpdatedThyroidGeneralCharacteristics
  | typeof sessionUseCase.SetSessionViewed
  | typeof sessionUseCase.OverrideSize3D
  | typeof sessionUseCase.NoOp
>;

/*
 * Reducer
 *
 * The SessionReducer takes SessionUseCases and the current Session (state)
 * and returns a new state and possible side effects.
 */
export class SessionReducer extends EffectReducer<Session> {
  private apiSession: ContractSession;
  private apiAnalyses: ContractAnalyses;
  private apiStorage: ContractStorage;
  private apiPDF: ContractPDF;
  private apiProfiles: ContractProfiles;
  private apiSearch: ContractSearch;
  private userEncryption: ContractEncryption;
  private profile: Profile;

  constructor(
    analyses: ContractAnalyses,
    session: ContractSession,
    storage: ContractStorage,
    pdf: ContractPDF,
    profiles: ContractProfiles,
    search: ContractSearch,
    userEncryption: ContractEncryption,
    profile: Profile
  ) {
    super();
    this.apiSession = session;
    this.apiAnalyses = analyses;
    this.apiStorage = storage;
    this.apiPDF = pdf;
    this.apiProfiles = profiles;
    this.apiSearch = search;
    this.userEncryption = userEncryption;
    this.profile = profile;

    this.CreateSubReducer<FindingInteraction>(
      new FindingInteractionReducer(),
      session => session.findingInteraction,
      (session, findingInteraction) => ({
        ...session,
        findingInteraction,
      })
    );

    this.CreateSubReducer<Session>(
      new FindingReducer(),
      session => session,
      (_session, newSession) => ({
        ...newSession,
      })
    );

    this.CreateSubReducer<Session>(
      new CanvasObjectReducer(),
      session => session,
      (_session, newSession) => ({
        ...newSession,
      })
    );
  }

  Perform(
    session: Session = initial(),
    action: SessionUseCases
  ): ReducerResult<Session> {
    const { type, payload: useCase } = action;
    switch (type) {
      case sessionUseCase.Init.type: {
        this.CancelLastEffect();
        return this.Result(
          {
            ...initial(),
            status: ApiStatus.Busy,
          },
          useCase.sessionId
            ? effectLoadAnalysis(
                this.apiAnalyses,
                this.apiStorage,
                useCase.analysisId,
                useCase.sessionId
              )
            : effectLookupSession(
                this.apiSession,
                this.profile,
                useCase.analysisId
              )
        );
      }
      case sessionUseCase.InitReceiveAnalysis.type:
        const { analysis } = useCase;
        const sessionLineItem = analysis.session;

        // If this isn't the latest batch of a study and unopened,
        // redirect to the latest analysis
        if (
          analysis.studyLatestAnalysisId &&
          analysis.id !== analysis.studyLatestAnalysisId &&
          analysis.session.status === SessionStatus.New
        ) {
          return this.Result({ ...session }, none(), [
            sessionUseCase.Init({ analysisId: analysis.studyLatestAnalysisId }),
          ]);
        }

        const isSize3D = (() => {
          switch (analysis.studyType) {
            case StudyType.SmallPartsThyroid:
              return (
                this.profile.configuration.thyroidSizeVariant ===
                SizeVariant.Size3D
              );
            case StudyType.SmallPartsBreast:
              return (
                this.profile.configuration.breastSizeVariant ===
                SizeVariant.Size3D
              );
            default:
              return false;
          }
        })();

        const updatedStateReceiveSession: Session = {
          ...session,
          status: ApiStatus.Busy,
          analysis: just(analysis),
          details: just(sessionLineItem),
          collaborators: just(sessionLineItem.collaborators),
          data: analysis.data,
          isSize3D,
          comparisonSession: maybe(analysis.comparisonSession),
          viewed: sessionLineItem.status !== SessionStatus.New,
        };

        const { isAdminDomain } = useAuthenticationMode();
        if (!sessionLineItem.userId && !isAdminDomain) {
          return this.Perform(
            updatedStateReceiveSession,
            sessionUseCase.SetSessionOwner({
              sessionLineItem,
              userId: this.profile.id,
            })
          );
        }

        // set the session status to in progress for the new session
        //Do not allow admin logins to mutate the session status
        const updateSessionStatus: Effect =
          sessionLineItem.status === SessionStatus.New
            ? effectSetStatus(
                this.apiSession,
                sessionLineItem.id,
                SessionStatus.InProgress
              )
            : none();

        // Generate and upload the PDF when the study is opened for the first time by a normal user
        const generateInitialPDF =
          !isAdminDomain && sessionLineItem.status === SessionStatus.New
            ? sessionUseCase.InitGeneratePDF({
                pdfTriggerAction: PDFTriggerAction.Initial,
              })
            : sessionUseCase.NoOp();

        return this.Result(
          updatedStateReceiveSession,
          batch([
            session.owner.isNothing()
              ? effectLoadOwner(
                  this.apiStorage,
                  this.apiProfiles,
                  sessionLineItem.userId
                )
              : none(),
            updateSessionStatus,
            effectSetInitialLayoutConfig(analysis.studyType),
            effectDecryptSession(
              sessionLineItem,
              this.userEncryption,
              this.profile
            ),
          ]),
          [generateInitialPDF]
        );

      case sessionUseCase.UpdateComparisonSession.type: {
        const currentSessionId = session.details.mapOr(
          details => details.id,
          useCase.currentSessionId
        );

        const refresh = session.details.isJust();

        return this.Result(
          {
            ...session,
            status: ApiStatus.Busy,
          },
          currentSessionId
            ? effectRemoteUpdateComparisonSession(
                this.apiSession,
                this.apiStorage,
                currentSessionId,
                useCase.comparisonSessionId,
                refresh
              )
            : undefined
        );
      }

      case sessionUseCase.ReceiveComparisonSession.type: {
        const { analysis } = useCase;
        const sessionLineItem = analysis.session;

        return this.Result({
          ...session,
          status: ApiStatus.Idle,
          analysis: just(analysis),
          details: just(sessionLineItem),
          collaborators: just(sessionLineItem.collaborators),
          data: analysis.data,
          comparisonSession: maybe(analysis.comparisonSession),
        });
      }

      case sessionUseCase.SetSessionOwner.type:
        return this.Result(
          {
            ...session,
            status: ApiStatus.Busy,
          },
          effectSetSessionOwner(
            this.apiSession,
            useCase.sessionLineItem,
            useCase.userId
          )
        );

      case sessionUseCase.SetSessionOwnerResult.type:
        return this.Perform(
          {
            ...session,
            status: ApiStatus.Idle,
          },
          sessionUseCase.Init({
            analysisId: useCase.sessionLineItem.analysisId,
            sessionId: useCase.sessionLineItem.id,
          })
        );

      case sessionUseCase.InitReceiveLookup.type: {
        return this.Result(session, none(), [
          appUseCase.Redirect({
            pathname: Routes.study(useCase.analysisId, useCase.sessionId),
            replace: true,
          }),
        ]);
      }

      case sessionUseCase.InitSessionNotFound.type: {
        return this.Result(
          {
            ...session,
            status: ApiStatus.Busy,
          },
          none(),
          [
            sessionUseCase.Init({
              analysisId: useCase.analysisId,
            }),
            notificationUseCase.Add({
              notification: {
                intent: 'error',
                titleI18nKey: 'study.notification.session_not_found.title',
                bodyI18nKey: 'study.notification.session_not_found.message',
              },
            }),
          ]
        );
      }

      case sessionUseCase.SetDecryptedSession.type: {
        const newDetails = session.details.lift();
        if (newDetails) {
          return this.Result({
            ...session,
            details: just({
              ...newDetails,
              decryptedPhi: useCase.sessionLineItem.decryptedPhi,
              decrypted: useCase.sessionLineItem.decrypted,
              metadata: useCase.sessionLineItem.metadata,
            }),
          });
        }

        return this.Result({
          ...session,
          details: just(useCase.sessionLineItem),
        });
      }

      case sessionUseCase.InitReceiveOwner.type:
        return this.Result({
          ...session,
          status: ApiStatus.Idle,
          owner: just(useCase.owner),
        });

      case sessionUseCase.SetCollaborators.type:
        return session.details.mapOr(
          sessionLineItem =>
            this.Result(
              {
                ...session,
                collaborators: just(useCase.collaborators),
              },
              effectSetCollaborators(
                this.apiSession,
                sessionLineItem.id,
                useCase.collaborators
              )
            ),
          this.Result(session)
        );

      case sessionUseCase.Unload.type: {
        this.CancelLastEffect();
        return this.Result(initial());
      }

      case sessionUseCase.Reset.type: {
        const sessionId = session.details.mapOr(
          sessionLineItem => sessionLineItem.id,
          undefined
        );
        if (sessionId) {
          return this.Result(
            {
              ...session,
              status: ApiStatus.Busy,
            },
            effectReanalyseSession(this.apiSession, sessionId, true)
          );
        } else {
          return this.Result(session);
        }
      }

      case sessionUseCase.SetStatus.type:
        return session.details.mapOr(
          sessionLineItem =>
            this.Result(
              session,
              effectSetStatus(
                this.apiSession,
                sessionLineItem.id,
                useCase.status
              )
            ),
          this.Result(session)
        );

      case sessionUseCase.SetStatusFinished.type:
        const newDetails = session.details.lift();

        if (newDetails) {
          return this.Result({
            ...session,
            finaliseStatus:
              useCase.status === SessionStatus.Completed
                ? FinaliseStatus.Success
                : session.finaliseStatus,
            details: just({ ...newDetails, status: useCase.status }),
          });
        }

        return this.Result({ ...session });

      case sessionUseCase.FinaliseSession.type: {
        return this.Result(
          session,
          effectFinaliseSession(
            useCase.htmlElement,
            useCase.onUpdate,
            useCase.useSinglePage
          )
        );
      }

      case sessionUseCase.InitGeneratePDF.type: {
        const { pdfTriggerAction } = useCase;

        return this.Result({
          ...session,
          pdfTriggerAction,
          isPdfDownloading: pdfTriggerAction === PDFTriggerAction.Download,
        });
      }

      case sessionUseCase.GeneratePDF.type: {
        return this.Result(
          {
            ...session,
          },
          map2(
            (analysis, sessionLineItem) =>
              effectGenerateFullPDF(
                this.apiPDF,
                this.apiStorage,
                this.userEncryption,
                this.profile,
                useCase.saveOnDisk,
                useCase.saveOnRemote,
                useCase.onUpdate,
                {
                  filePathRemote: `${
                    this.profile.institutionId
                  }/studies/${analysis.studyUID.replace(
                    `${this.profile.institutionId}_`,
                    ''
                  )}/batches/${analysis.batchUID}/analyses/${
                    analysis.id
                  }/sessions/${sessionLineItem.id}/report.pdf`,
                  fileNameLocal:
                    sessionLineItem.accessionNumber.length > 0
                      ? `${sessionLineItem.accessionNumber}.pdf`
                      : 'report.pdf',
                  htmlElement: useCase.htmlElement,
                },
                useCase.useSinglePage
              ),
            session.analysis,
            session.details
          ).withDefault(none())
        );
      }

      case sessionUseCase.DownloadEncryptedPDF.type: {
        return this.Result(
          { ...session, isPdfDownloading: true },
          effectDownloadEncryptedPDF(
            this.apiStorage,
            this.userEncryption,
            this.profile,
            useCase.encryptedPdfUrl,
            useCase.fileName
          )
        );
      }

      case sessionUseCase.PdfGenerated.type: {
        return this.Result(
          {
            ...session,
            isPdfDownloading: false,
          },
          none()
        );
      }

      case sessionUseCase.DownloadStudyArchive.type: {
        return this.Result(
          session,
          effectDownloadStudyArchive(
            this.apiSession,
            this.apiStorage,
            useCase.sessionId
          )
        );
      }

      case sessionUseCase.DownloadStudyAlmondResult.type: {
        return this.Result(
          session,
          effectDownloadStudyAlmondResult(
            this.apiSession,
            this.apiStorage,
            useCase.sessionId
          )
        );
      }

      case sessionUseCase.GeneratePDFStored.type: {
        const { htmlElement, useSinglePage, initial } = useCase;
        return this.Result(
          { ...session },
          batch([
            map2(
              (analysis, sessionLineItem) =>
                effectGenerateRedactedPDF(
                  this.apiPDF,
                  this.apiStorage,
                  {
                    filePath: `${
                      this.profile.institutionId
                    }/studies/${analysis.studyUID.replace(
                      `${this.profile.institutionId}_`,
                      ''
                    )}/batches/${analysis.batchUID}/analyses/${
                      analysis.id
                    }/sessions/${sessionLineItem.id}`,
                    fileName: initial
                      ? 'report-redacted-initial.pdf'
                      : 'report-redacted.pdf',
                    htmlElement,
                  },
                  useSinglePage,
                  initial
                ),
              session.analysis,
              session.details
            ).withDefault(none()),
          ])
        );
      }

      case sessionUseCase.UpdateStudyLayoutConfiguration.type:
        return this.Result({
          ...session,
          studyLayoutConfig: useCase.studyLayoutConfig,
        });

      case sessionUseCase.UpdateFinaliseStatus.type:
        return this.Result({
          ...session,
          finaliseStatus: useCase.finaliseStatus,
        });

      case sessionUseCase.UpdatePatientInfoSuccess.type:
        return this.Result({
          ...session,
          messages: [
            {
              intent: 'success',
              key: 'pages.study.patient_info.profile.form.saved.message',
            },
          ],
        });

      case sessionUseCase.MarkAsBookmarked.type: {
        return this.Result(
          { ...session },
          effectUpdateSessionBookmarked(
            this.apiSession,
            useCase.sessionId,
            useCase.isBookmarked
          )
        );
      }
      case sessionUseCase.UpdateMetadata.type: {
        const optimisticallyUpdatedSession = this.Perform(
          session,
          sessionUseCase.MetadataUpdated({ metadata: useCase.metadata })
        )
          .mapOk(a => a.first())
          .withDefault(session);

        return this.Result(
          optimisticallyUpdatedSession,
          effectUpdateMetadata(
            this.apiSession,
            this.userEncryption,
            this.profile,
            useCase.metadata,
            session.details.mapOr(s => s.id, '')
          )
        );
      }

      case sessionUseCase.MetadataUpdated.type: {
        const newDetails = session.details.lift();
        if (newDetails) {
          return this.Result({
            ...session,
            details: just({ ...newDetails, metadata: useCase.metadata }),
          });
        }

        return this.Result({ ...session });
      }

      case sessionUseCase.UpdateConfiguration.type: {
        if (!useCase.optimistic) {
          return this.Result(
            {
              ...session,
              status: ApiStatus.Busy,
            },
            effectUpdateConfiguration(
              this.apiSession,
              useCase.configuration,
              session.details.mapOr(s => s.id, ''),
              useCase.optimistic
            )
          );
        }

        const optimisticallyUpdatedSession = this.Perform(
          session,
          sessionUseCase.ConfigurationUpdated({
            configuration: useCase.configuration,
          })
        )
          .mapOk(a => a.first())
          .withDefault(session);

        return this.Result(
          optimisticallyUpdatedSession,
          effectUpdateConfiguration(
            this.apiSession,
            useCase.configuration,
            session.details.mapOr(s => s.id, ''),
            useCase.optimistic
          )
        );
      }

      case sessionUseCase.ConfigurationUpdated.type: {
        const sessionDetails = session.details.lift();
        if (sessionDetails) {
          const newDetails = {
            ...sessionDetails,
            configuration: useCase.configuration,
          };
          return this.Result({
            ...session,
            status: ApiStatus.Idle,
            details: just(newDetails),
          });
        }

        return this.Result({ ...session });
      }

      case sessionUseCase.IgnoreComparison.type: {
        // We simply set the flag in the store for now, keeping in mind that it will not be persisted.
        // If this proves insufficient, we can persist either to the session table or local storage.
        return this.Result({
          ...session,
          ignoreComparison: true,
        });
      }

      case sessionUseCase.DisplayNotification.type: {
        const { notification } = useCase;
        return this.Result(
          {
            ...session,
          },
          undefined,
          [notificationUseCase.Add({ notification })]
        );
      }

      case sessionUseCase.LoadFromAccessionNum.type: {
        const { accessionNum } = useCase;

        return this.Result(
          { ...session },
          effectGetSessionFromAccession(
            this.apiSearch,
            this.apiStorage,
            accessionNum
          )
        );
      }

      case sessionUseCase.LoadFromAccessionNumNotFound.type: {
        const userId = this.profile.id;

        return this.Result(session, none(), [
          appUseCase.Redirect({
            pathname: Routes.worklist('user', userId, 'active'),
            replace: true,
          }),
        ]);
      }

      case sessionUseCase.UpdateThyroidGeneralCharacteristics.type: {
        const sessionId = session.details.mapOr(
          sessionLineItem => sessionLineItem.id,
          undefined
        );

        return this.Result(
          {
            ...session,
            data: {
              ...session.data,
              thyroidGeneralCharacteristics:
                useCase.thyroidGeneralCharacteristic,
            },
          },
          sessionId
            ? effectUpdateThyroidGeneralCharacteristic(
                this.apiSession,
                sessionId,
                useCase.thyroidGeneralCharacteristic
              )
            : none()
        );
      }

      case sessionUseCase.ReceiveUpdatedThyroidGeneralCharacteristics.type: {
        return this.Result({
          ...session,
          data: {
            ...session.data,
            thyroidGeneralCharacteristics:
              useCase.thyroidGeneralCharacteristics,
            impressions: useCase.impressions,
          },
        });
      }

      case sessionUseCase.OverrideSize3D.type: {
        return this.Result({
          ...session,
          isSize3D: useCase.isSize3D,
        });
      }

      case sessionUseCase.SetSessionViewed.type:
        if (!session.viewed) {
          return this.Result({
            ...session,
            viewed: true,
          });
        }

        return this.Result(session);

      case sessionUseCase.NoOp.type:
        return this.Result({ ...session });

      default: {
        return this.SubReduce(session, action);
      }
    }
  }
}

/**
 * Get latest session from accession number
 */

export const effectGetSessionFromAccession = (
  apiSearch: ContractSearch,
  apiStorage: ContractStorage,
  query: string
): Effect =>
  effect(async () => {
    const featureFlags = getFeatureFlags();
    const useFilterOptions = {
      group: WorkspaceGroup.User,
      id: '',
      status: [],
      offset: 0,
      limit: 1,
      featureFlags: featureFlags,
    } as FilterOptions;

    const { searchResultItems } = await apiSearch.Perform(
      apiStorage,
      query,
      useFilterOptions
    );

    if (searchResultItems.length) {
      const { analysisId, id } = searchResultItems[0];
      return sessionUseCase.InitReceiveLookup({
        analysisId,
        sessionId: id,
      });
    }
    return sessionUseCase.LoadFromAccessionNumNotFound();
  }, 'effect-session-get-session-from-accession');

/**
 * Set a Session Owner
 */
export const effectSetSessionOwner = (
  apiSession: ContractSession,
  sessionLineItem: SessionLineItem,
  userProfileId: string
): Effect =>
  effect(async () => {
    const collaborators = [userProfileId, ...sessionLineItem.collaborators];

    const { isAdminDomain } = useAuthenticationMode();
    if (!isAdminDomain) {
      await apiSession.SetSessionOwner(
        sessionLineItem.id,
        userProfileId,
        collaborators
      );
    }

    return sessionUseCase.SetSessionOwnerResult({ sessionLineItem });
  }, 'effect-session-set-session-owner');

/**
 * Reanalyse a Session
 */
export const effectReanalyseSession = (
  apiSession: ContractSession,
  sessionId: string,
  autoRedirect: boolean
): Effect =>
  effect(async () => {
    const { batchId, sessionId: targetSessionId } =
      await apiSession.Reanalyse(sessionId);
    return analysisUploadsUseCase.NewReanalyse({
      batchId,
      targetSessionId,
      autoRedirect,
    });
  }, 'effect-session-reanalysed-session');

/**
 * Set status
 */
const effectSetStatus = (
  apiSession: ContractSession,
  sessionId: string,
  status: SessionStatus
): Effect =>
  effect(async () => {
    const { isAdminDomain } = useAuthenticationMode();
    if (!isAdminDomain) {
      await apiSession.UpdateStatus(sessionId, status);
    }
    return sessionUseCase.SetStatusFinished({ status });
  }, 'effect-session-set-status');

/**
 * Set the session status to MARKED_AS_COMPLETED before generating a PDF
 */
export const effectFinaliseSession = (
  htmlElement: HTMLElement,
  onUpdate: (useCase: Action) => void,
  useSinglePage?: boolean
): Effect =>
  effect(async () => {
    const saveOnDisk = false;
    const saveOnRemote = true;

    return sessionUseCase.GeneratePDF({
      htmlElement,
      saveOnDisk,
      saveOnRemote,
      onUpdate,
      useSinglePage,
    });
  }, 'effect-session-finalise-session');

/**
 * Generate a report PDF, including PHI
 */
export const effectGenerateFullPDF = (
  apiPDF: ContractPDF,
  apiStorage: ContractStorage,
  userEncryption: ContractEncryption,
  profile: Profile,
  saveOnDisk: boolean,
  saveOnRemote: boolean,
  onUpdate: (useCase: SessionUseCases) => void,
  {
    filePathRemote,
    fileNameLocal,
    htmlElement,
  }: {
    filePathRemote: string;
    fileNameLocal: string;
    htmlElement: HTMLElement;
  },
  useSinglePage?: boolean
): Effect =>
  effect(async () => {
    const fullPDF = await apiPDF.FromHtml(htmlElement, useSinglePage);

    if (saveOnDisk) {
      const fileNamePdf = `${fileNameLocal}.pdf`.replace('.pdf.pdf', '.pdf');
      apiStorage.SaveAs(fileNamePdf, fullPDF);
      onUpdate(sessionUseCase.PdfGenerated());
    }

    if (saveOnRemote) {
      const fileNamePdf = `${filePathRemote}.pdf`.replace('.pdf.pdf', '.pdf');

      const encodedPDF = Buffer.from(
        fullPDF.replace(/^data:application\/\w+;base64,/, ''),
        'base64'
      );

      // Note: I tried to promisify this and run it concurrently, instead of
      // sequentially, but then it broke in cryptic ways when decrypting the file.
      // So we're stuck with doing it sequentially.

      // Encrypt and upload the PDF for them
      const result = await encryptAndUploadFile(userEncryption, apiStorage, {
        encodedFile: encodedPDF,
        contentType: 'application/pdf',
        fileUploadUrl: fileNamePdf,
        arn: profile.kmsKeyArn,
        region: profile.nativeRegion,
        context: {
          audience: 'user',
          origin: profile.nativeRegion || 'no-region',
          type: 'pdf',
        },
      } as IEncryptedFile);

      if (result) {
        // Return early here because GeneratePDFStored will reset the trigger
        return sessionUseCase.GeneratePDFStored({
          htmlElement,
          useSinglePage,
        });
      } else {
        onUpdate(
          sessionUseCase.UpdateFinaliseStatus({
            finaliseStatus: FinaliseStatus.Error,
          })
        );
      }
    }

    return sessionUseCase.InitGeneratePDF({
      pdfTriggerAction: PDFTriggerAction.None,
    });
  }, 'effect-session-generate-full-pdf') as Effect;

/**
 * Generate a report PDF, with PHI redacted
 */
export const effectGenerateRedactedPDF = (
  apiPDF: ContractPDF,
  apiStorage: ContractStorage,
  {
    filePath,
    fileName,
    htmlElement,
  }: {
    filePath: string;
    fileName: string;
    htmlElement: HTMLElement;
  },
  useSinglePage?: boolean,
  initial?: boolean
): Effect =>
  effect(async () => {
    htmlElement.querySelectorAll('[data-patient-sensitive]').forEach(el => {
      el.setAttribute('style', 'display: none');
    });
    const redactedPDF = await apiPDF.FromHtml(htmlElement, useSinglePage);

    const location = `${filePath}/${fileName}`.replace('//', '/');

    const encodedPDF = Buffer.from(
      redactedPDF.replace(/^data:application\/\w+;base64,/, ''),
      'base64'
    );

    // Reset the trigger after the generation but before the upload
    dispatch(
      sessionUseCase.InitGeneratePDF({
        pdfTriggerAction: PDFTriggerAction.None,
      })
    );

    await apiStorage.Store({
      location,
      contentType: 'application/pdf',
      body: encodedPDF,
    });

    htmlElement.querySelectorAll('[data-patient-sensitive]').forEach(el => {
      el.removeAttribute('style');
    });

    return initial
      ? sessionUseCase.NoOp()
      : sessionUseCase.SetStatus({ status: SessionStatus.Completed });
  }, 'effect-session-generate-redacted-pdf') as Effect;

/**
 * Download an encrypted PDF file
 */
// QUESTION: Does this need to be an effect or simply a helper function?
const effectDownloadEncryptedPDF = (
  apiStorage: ContractStorage,
  userEncryption: ContractEncryption,
  profile: Profile,
  encryptedPdfUrl: string,
  fileName?: string
): Effect =>
  effect(async () => {
    const kmsKeyArn = profile.kmsKeyArn;
    const nativeRegion = profile.nativeRegion;
    await userEncryption.configure(kmsKeyArn, nativeRegion);
    const downloadUrl = await apiStorage.GetUrl(encryptedPdfUrl);
    await fetch(downloadUrl, { method: 'GET' })
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => userEncryption.decryptFile(Buffer.from(arrayBuffer)))
      .then(decryptedPdf => {
        if (decryptedPdf) {
          const fileBlob = new Blob([decryptedPdf], {
            type: 'application/pdf',
          });

          const pdfPathName = new URL(downloadUrl).pathname;
          const downloadPath = fileName
            ? fileName
            : pdfPathName.split('/').slice(-1)[0];

          apiStorage.SaveAs(downloadPath, fileBlob);
        }
      })
      .catch(e => logError(e));

    return sessionUseCase.PdfGenerated();
  }, 'effect-session-download-encrypted-pdf') as Effect;

/**
 * Download a study archive
 */
const effectDownloadStudyArchive = (
  apiSession: ContractSession,
  apiStorage: ContractStorage,
  sessionId: string
): Effect =>
  effect(async () => {
    const key = await apiSession.DownloadArchive(sessionId);
    const url = await apiStorage.PollUrl(key);
    apiStorage.SaveAs(sessionId, url);
    return sessionUseCase.NoOp();
  }, 'effect-session-download-study-archive') as Effect;

/**
 * Download a study archive
 */
const effectDownloadStudyAlmondResult = (
  apiSession: ContractSession,
  apiStorage: ContractStorage,
  sessionId: string
): Effect =>
  effect(async () => {
    const key = await apiSession.DownloadAlmondResult(sessionId);
    const url = await apiStorage.PollUrl(key);
    apiStorage.SaveAs(`${sessionId}-result`, url);
    return sessionUseCase.NoOp();
  }, 'effect-session-download-study-archive') as Effect;

/**
 * Load an analysis using the given analysisId
 */
const effectLoadAnalysis = (
  apiAnalyses: ContractAnalyses,
  apiStorage: ContractStorage,
  analysisId: string,
  sessionId: string
): Effect =>
  effect(async () => {
    try {
      const analysis = await apiAnalyses.Load(
        apiStorage,
        analysisId,
        sessionId
      );
      return sessionUseCase.InitReceiveAnalysis({ analysis });
    } catch (e) {
      return sessionUseCase.InitSessionNotFound({ analysisId });
    }
  }, 'effect-session-load-analysis');

/*
 * Decrypt sessionLineItem
 */
const effectDecryptSession = (
  sessionLineItem: SessionLineItem,
  apiEncryption: ContractEncryption,
  profile: Profile
): Effect =>
  effect(async () => {
    try {
      const decryptedSessionLineItem = (await decryptPatientHealthInfo(
        apiEncryption,
        profile,
        sessionLineItem
      )) as SessionLineItem;
      // Decrypt the comments and clinical findings
      let decryptedComments: string | undefined = undefined;
      if (sessionLineItem.metadata.encryptedComments) {
        decryptedComments = await apiEncryption.decryptString(
          sessionLineItem.metadata.encryptedComments
        );
      }
      let decryptedClinicalFindings: string | undefined = undefined;
      if (sessionLineItem.metadata.encryptedClinicalFindings) {
        decryptedClinicalFindings = await apiEncryption.decryptString(
          sessionLineItem.metadata.encryptedClinicalFindings
        );
      }
      let decryptedOtherHistory: string | undefined = undefined;
      if (sessionLineItem.metadata.encryptedOtherHistory) {
        decryptedOtherHistory = await apiEncryption.decryptString(
          sessionLineItem.metadata.encryptedOtherHistory
        );
      }

      let decryptedWorksheetExtraInfo: WorksheetExtraInfoObject | undefined =
        undefined;
      if (sessionLineItem.metadata.encryptedWorksheetExtraInfo) {
        const decryptedWorksheetExtraInfoString =
          await apiEncryption.decryptString(
            sessionLineItem.metadata.encryptedWorksheetExtraInfo
          );
        decryptedWorksheetExtraInfo = decryptedWorksheetExtraInfoString
          ? JSON.parse(decryptedWorksheetExtraInfoString)
          : undefined;
      }

      const decryptedMetadata: SessionMetadata = {
        decryptedComments,
        encryptedComments: sessionLineItem.metadata.encryptedComments,
        decryptedClinicalFindings,
        encryptedClinicalFindings:
          sessionLineItem.metadata.encryptedClinicalFindings,
        decryptedOtherHistory,
        encryptedOtherHistory: sessionLineItem.metadata.encryptedOtherHistory,
        decryptedWorksheetExtraInfo,
        encryptedWorksheetExtraInfo:
          sessionLineItem.metadata.encryptedWorksheetExtraInfo,
      };

      decryptedSessionLineItem.metadata = decryptedMetadata;

      return sessionUseCase.SetDecryptedSession({
        sessionLineItem: decryptedSessionLineItem,
      });
    } catch (e) {
      logError(e as Error);
      return sessionUseCase.NoOp();
    }
  }, 'effect-session-decrypt-session');

/**
 * Lookup a sessionId for the given analysis and userId
 */
export const effectLookupSession = (
  apiSession: ContractSession,
  profile: Profile,
  analysisId: string
): Effect =>
  effect(async () => {
    const resultSessionId = await apiSession.Lookup(analysisId, profile.id);
    const sessionId = resultSessionId.lift();

    if (!sessionId) {
      throw new Error('Session not found');
    }

    return sessionUseCase.InitReceiveLookup({
      analysisId,
      sessionId,
    });
  }, 'effect-session-lookup-session');

/**
 * Load the owners profile
 */
const effectLoadOwner = (
  apiStorage: ContractStorage,
  apiProfiles: ContractProfiles,
  userId: string
): Effect =>
  effect(async () => {
    const owner = await apiProfiles.Get(apiStorage, userId);
    return sessionUseCase.InitReceiveOwner({ owner });
  }, 'effect-session-load-owner');
/**
 * Set the session collaborators
 */
const effectSetCollaborators = (
  apiSession: ContractSession,
  sessionId: string,
  userIds: string[]
): Effect =>
  effect(async () => {
    const { isAdminDomain } = useAuthenticationMode();
    if (!isAdminDomain) {
      await apiSession.UpdateCollaborators(sessionId, userIds);
    }
    return sessionUseCase.NoOp();
  }, 'effect-session-load-collaborators');

export const decryptPatientHealthInfo = async (
  encryptionHelper: ContractEncryption,
  profile: Profile,
  sessionOrSearchLineItem: SessionLineItem | WorklistLineItem
): Promise<SessionLineItem | WorklistLineItem> => {
  if (!sessionOrSearchLineItem.phiCipher) {
    sessionOrSearchLineItem.decrypted = true;
    return sessionOrSearchLineItem;
  }
  const kmsKeyArn = profile.kmsKeyArn;
  const nativeRegion = profile.nativeRegion;
  await encryptionHelper.configure(kmsKeyArn, nativeRegion);
  const decryptedPhiBlob = await encryptionHelper.decryptString(
    sessionOrSearchLineItem.phiCipher
  );

  if (!decryptedPhiBlob) {
    sessionOrSearchLineItem.decrypted = true;
    return sessionOrSearchLineItem;
  }

  let decryptedPhi: PatientHealthInfo | undefined;

  try {
    decryptedPhi = JSON.parse(decryptedPhiBlob);
    if (decryptedPhi?.patient_name) {
      decryptedPhi.patient_name = parseDicomName(decryptedPhi?.patient_name);
      decryptedPhi.operator_code = parseDicomName(decryptedPhi?.operator_code);
    }
  } catch (e) {
    logError(e as Error);
    decryptedPhi = {} as PatientHealthInfo;
  }

  const newSessionOrSearchLineItem = {
    ...sessionOrSearchLineItem,
    decryptedPhi,
    decrypted: true,
  };

  return newSessionOrSearchLineItem;
};

type IEncryptedFile = {
  encodedFile: Buffer;
  contentType: string;
  fileUploadUrl: string;
  arn: string | undefined;
  region: string | undefined;
  context: { [key: string]: string };
};

const encryptAndUploadFile = (
  encryptionApi: ContractEncryption,
  apiStorage: ContractStorage,
  {
    encodedFile,
    contentType,
    fileUploadUrl,
    arn,
    region,
    context,
  }: IEncryptedFile
) => {
  return new Promise(res => res(encryptionApi.configure(arn, region)))
    .then(() => encryptionApi.encryptFile(encodedFile, context))
    .then(encryptedFile => {
      if (encryptedFile) {
        return apiStorage.Store({
          location: fileUploadUrl,
          contentType,
          body: Buffer.from(encryptedFile),
        });
      } else {
        throw new Error('Failed to encrypt File');
      }
    })
    .then(() => true)
    .catch(e => {
      logError(e);
      return false;
    });
};

export const effectUpdateSessionBookmarked = (
  apiSession: ContractSession,
  sessionId: string,
  isBookmarked: boolean
): Effect =>
  effect(async () => {
    await apiSession.UpdateBookmarked(sessionId, isBookmarked);

    return sessionUseCase.NoOp();
  }, 'effect-session-update-bookmark');

export const effectUpdateMetadata = (
  apiSession: ContractSession,
  apiEncryption: ContractEncryption,
  profile: Profile,
  metadata: SessionMetadata,
  sessionId: string
): Effect =>
  effect(async () => {
    // Configure the Encryption API
    const kmsKeyArn = profile.kmsKeyArn;
    const nativeRegion = profile.nativeRegion;
    await apiEncryption.configure(kmsKeyArn, nativeRegion);

    // Encrypt the comments and clinical findings
    let encryptedComments: string | undefined = undefined;
    if (metadata.decryptedComments) {
      encryptedComments = await apiEncryption.encryptString(
        metadata.decryptedComments
      );
    }
    let encryptedClinicalFindings: string | undefined = undefined;
    if (metadata.decryptedClinicalFindings) {
      encryptedClinicalFindings = await apiEncryption.encryptString(
        metadata.decryptedClinicalFindings
      );
    }
    let encryptedOtherHistory: string | undefined = undefined;
    if (metadata.decryptedOtherHistory) {
      encryptedOtherHistory = await apiEncryption.encryptString(
        metadata.decryptedOtherHistory
      );
    }

    let encryptedWorksheetExtraInfo: string | undefined = undefined;
    if (metadata.decryptedWorksheetExtraInfo) {
      encryptedWorksheetExtraInfo = await apiEncryption.encryptString(
        JSON.stringify(metadata.decryptedWorksheetExtraInfo)
      );
    }

    // Save this updated version via API
    const encryptedMetadata: SessionMetadata = {
      encryptedComments,
      decryptedComments: metadata.decryptedComments,
      encryptedClinicalFindings,
      decryptedClinicalFindings: metadata.decryptedClinicalFindings,
      encryptedOtherHistory,
      decryptedOtherHistory: metadata.decryptedOtherHistory,
      encryptedWorksheetExtraInfo,
      decryptedWorksheetExtraInfo: metadata.decryptedWorksheetExtraInfo,
    };
    await apiSession.UpdateMetadata(sessionId, encryptedMetadata);

    return sessionUseCase.NoOp();
  }, 'effect-session-update-metadata');

export const effectUpdateConfiguration = (
  apiSession: ContractSession,
  configuration: SessionConfiguration,
  sessionId: string,
  optimistic: boolean
): Effect =>
  effect(async () => {
    await apiSession.UpdateConfiguration(sessionId, configuration);

    if (optimistic) {
      return sessionUseCase.NoOp();
    }
    return sessionUseCase.ConfigurationUpdated({ configuration });
  }, 'effect-session-update-configuration');

// Set initial layout based on studyTypes
const effectSetInitialLayoutConfig = (studyType: StudyType): Effect =>
  effect(async () => {
    const layout: StudyLayout = getLayoutFromStudyType(studyType);
    return sessionUseCase.UpdateStudyLayoutConfiguration({
      studyLayoutConfig: layout,
    });
  }, 'effect-session-set-initial-layout');

const effectRemoteUpdateComparisonSession = (
  apiSession: ContractSession,
  apiStorage: ContractStorage,
  sessionId: string,
  comparisonSessionId: string,
  refresh: boolean
): Effect =>
  effect(async () => {
    const analysis = await apiSession.UpdateComparisonSessionId(
      apiStorage,
      sessionId,
      comparisonSessionId
    );

    if (refresh) {
      return sessionUseCase.ReceiveComparisonSession({ analysis });
    } else {
      return sessionUseCase.NoOp();
    }
  }, 'effect-update-comparison-session');

// Update thyroid general characteristic
const effectUpdateThyroidGeneralCharacteristic = (
  apiSession: ContractSession,
  sessionId: string,
  thyroidGeneralCharacteristic: ThyroidGeneralCharacteristics
): Effect =>
  effect(async () => {
    const result = await apiSession.UpdateThyroidGeneralCharacteristics(
      sessionId,
      thyroidGeneralCharacteristic
    );
    return sessionUseCase.ReceiveUpdatedThyroidGeneralCharacteristics(result);
  }, 'effect-session-update-thyroid-general-characteristic');

import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@webMolecules/Button/Button';

export interface RouteLinkProps extends Record<string, unknown> {
  to?: string;
  goBack?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
}

export interface RouteLinkComponent extends Record<string, unknown> {
  navigate?: () => void;
}

export const RouteLink: React.FC<RouteLinkProps> = ({
  goBack,
  to,
  children,
  component = Button,
  ...rest
}) => {
  if (to) {
    return (
      <Link to={to} component={component} {...rest}>
        {children}
      </Link>
    );
  }

  const Component = component;

  if (goBack) {
    const history = useHistory();

    const goBack = () => {
      history.goBack();
    };

    return (
      <Component onClick={goBack} {...rest}>
        {children}
      </Component>
    );
  }

  return <Component {...rest}>{children}</Component>;
};

import React from 'react';

interface RoutedPageProps {
  path: string;
  children: React.ReactNode;
  onEnter?: () => void;
  onLeave?: () => void;
  onUpdate?: () => void;
}

class RoutedPage extends React.Component<RoutedPageProps> {
  constructor(props: RoutedPageProps) {
    if (props.onEnter) {
      props.onEnter();
    }

    super(props);
  }

  componentWillUnmount() {
    if (this.props.onLeave) {
      this.props.onLeave();
    }
  }

  componentDidUpdate() {
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  render() {
    return this.props.children;
  }
}

export default React.memo(RoutedPage, (a, b) => a.path === b.path);

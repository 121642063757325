import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import {
  getIconByIconStyle,
  getColorFromLabel,
  labelColor,
} from '@webOrganisms/ResultTable/FindingsTable/Shared';
import { cnames } from '@helpers/cnames';
import styles from './arrowIcon.scss';
export interface ArrowIconProps {
  previousValue: number | null | undefined;
  currentValue: number | undefined;
  type?: 'percentage' | 'absolute';
  previousDisplayValue?: string;
  currentDisplayValue?: string;
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({
  previousValue,
  currentValue,
  type,
  previousDisplayValue,
  currentDisplayValue,
}) => {
  let defaultDifferenceValue = undefined;

  if (currentValue != undefined && previousValue != undefined) {
    defaultDifferenceValue = currentValue - previousValue;
  }
  const iconStyle = labelColor(Number(defaultDifferenceValue));

  const textClassName = cnames(styles[`${iconStyle}-text-color`]);
  const color = getColorFromLabel(iconStyle);

  switch (type) {
    case 'absolute':
      return (
        <Box className={textClassName} display="flex" alignItems="center">
          <Text marginX="xxs" type="body2">
            {previousDisplayValue || previousValue || '-'}
          </Text>
          <Icon size="small" name="arrow-right" color={color}></Icon>
          <Text marginX="xxs" type="body2">
            {currentDisplayValue || currentValue || '-'}
          </Text>
        </Box>
      );

    case 'percentage':
    default:
      // To calculate percentage value
      let positivePercentage = '-.--';
      const validDiff =
        defaultDifferenceValue != undefined && previousValue != undefined;

      if (validDiff) {
        const percentageDifference = (defaultDifferenceValue &&
          (defaultDifferenceValue / previousValue) * 100) as number;
        positivePercentage = Math.abs(percentageDifference).toFixed(0);
      }
      const showIcon = Boolean(
        positivePercentage != '-.--' && iconStyle != 'default'
      );
      return (
        <Box className={textClassName} display="flex" alignItems="center">
          {showIcon && (
            <Icon
              size="small"
              name={getIconByIconStyle(iconStyle)}
              color={color}
            ></Icon>
          )}
          &nbsp;
          <Text type="body2">{`${positivePercentage || '-.--'}`}</Text>
        </Box>
      );
  }
};

import React from 'react';
import { Icon } from '@webMolecules/Icon/Icon';
import { FindingMissingSize } from '@webOrganisms/ResultTable/FindingsTable/FindingMissingSize';
import { Box } from '@webMolecules/Box/Box';
import { checkIfSizeMissing } from '@webOrganisms/ResultTable/FindingsTable/SmallPartsStudyFindingHelper';
import { Finding } from '@entities/Finding';
import styles from '../comparison.scss';
import { FindingNoduleSizeValues } from './FindingNoduleSizeValues';
import { FindingNoduleSeparator } from './FindingNoduleSeparator';

interface FindingNoduleSizeHeaderValuesProps {
  finding: Finding;
  showUnitInSummary?: boolean;
  muteSizeValue?: boolean;
}
export const FindingNoduleSizeHeaderValues = ({
  finding,
  showUnitInSummary,
  muteSizeValue = false,
}: FindingNoduleSizeHeaderValuesProps) => {
  if (checkIfSizeMissing(finding)) {
    return (
      <Box className={styles.missingSizeContainer} marginRight="auto">
        <FindingMissingSize size="small" />
      </Box>
    );
  }

  if (!finding.formatted.isSize3D) {
    return render1DHeaderValue(finding, Boolean(showUnitInSummary));
  }
  return (
    <>
      <FindingNoduleSizeValues
        side="width"
        value={Number(finding.formatted.width?.value)}
        unit={finding.formatted.width?.unit}
        muteSizeValue={muteSizeValue}
      />
      <FindingNoduleSeparator symbol={<Icon name="bullet-separator" fluid />} />
      <FindingNoduleSizeValues
        side="height"
        value={Number(finding.formatted.height?.value)}
        unit={finding.formatted.height?.unit}
        muteSizeValue={muteSizeValue}
      />
      <FindingNoduleSeparator symbol={<Icon name="bullet-separator" fluid />} />
      <FindingNoduleSizeValues
        side="length"
        value={Number(finding.formatted.length?.value)}
        unit={finding.formatted.length?.unit}
        showUnitInSummary={showUnitInSummary}
        muteSizeValue={muteSizeValue}
      />
      <FindingNoduleSeparator symbol="|" />
      <FindingNoduleSizeValues
        side="volume"
        value={Number(finding.formatted.volume?.value)}
        unit={finding.formatted.volume?.unit}
        showUnitInSummary={showUnitInSummary}
        muteSizeValue={muteSizeValue}
      />
    </>
  );
};

const render1DHeaderValue = (finding: Finding, showUnitInSummary: boolean) => {
  return (
    <FindingNoduleSizeValues
      side="size"
      value={Number(finding.formatted.max?.value)}
      unit={finding.formatted.max?.unit}
      showUnitInSummary={showUnitInSummary}
    />
  );
};

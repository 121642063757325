import React from 'react';
import { connect } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ReactRouter from '@webInterfaces/ReactRouter';
import { dispatch } from '@webInterfaces/Store';
import * as ViewModel from '@webViewModels/App';
import { timedOutSession } from '@webViewInterfaces/IdleTimer';
import { Routes } from '@webViewInterfaces/Routes';
import Notifications from '@webViews/Toasts/Notifications';
import { ToastContainer } from '@webMolecules/Toast/Toast';
import AnalysisUploads from '@webViews/Toasts/Uploads';
import { IdleTimer } from '@webOrganisms/IdleTimer/IdleTimer';
import { Theme } from '@webAtoms/Theme/Theme';
import { VersionCheck } from './VersionCheck';
import { DrawerContainer } from './DrawerContainer';

interface AuthenticatedRouterProps {
  ready: boolean;
  routes: Routes;
  featureFlagsInitialised: boolean;
}

const AuthenticatedRouter = ({
  ready,
  routes,
  featureFlagsInitialised,
}: AuthenticatedRouterProps) => {
  const handleTimeOut = (showMsg: boolean) => {
    dispatch(timedOutSession(showMsg));
  };

  if (!ready || !featureFlagsInitialised) {
    return <></>;
  }

  return (
    <IdleTimer timeoutInMinutes={60} onTimeout={handleTimeOut}>
      {ReactRouter(
        true,
        routes,
        <>
          <ToastContainer />
          <AnalysisUploads />
          <Notifications />
          <VersionCheck />
          <DrawerContainer />
        </>,
        undefined
      )}
    </IdleTimer>
  );
};

const App: React.FC<ViewModel.App> = ({
  routes,
  ready,
  authenticatedEmail,
  featureFlagsInitialised,
}) => (
  <HelmetProvider>
    <Theme name="dark">
      {authenticatedEmail ? (
        <AuthenticatedRouter
          ready={ready}
          routes={routes}
          featureFlagsInitialised={featureFlagsInitialised}
        />
      ) : ready ? (
        ReactRouter(false, routes)
      ) : null}
    </Theme>
  </HelmetProvider>
);

export default connect(ViewModel.fromState)(App);

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Worklist/Search';
import * as StoryLayout from '@webPages/Worklist/Layout';
import * as StorySearch from '@webPages/Worklist/Search';
import { SessionStatus } from '@interfaces/SessionLineItem';
import Sidebar from './Sidebar';

const Worklist: React.FC<ViewModel.Search> = ({
  me,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  loading,
  amountOfResults,
  totalAvailableItems,
  searchResult,
  dispatchOnSignOut,
  dispatchOpenStudy,
  dispatchSetSessionStatus,
  dispatchDownloadEncryptedPdf,
  dispatchLoadNext,
  featureFlags,
}) => {
  return (
    <StoryLayout.Layout
      me={me}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      searchQuery={searchQuery}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
      sidebar={<Sidebar />}
      onSignOut={dispatchOnSignOut}
    >
      <StorySearch.Search
        loading={loading}
        searchQuery={searchQuery}
        amountOfResults={amountOfResults}
        totalAvailableItems={totalAvailableItems}
        searchResult={searchResult}
        onOpenStudy={dispatchOpenStudy}
        onLoadNext={() => {
          dispatchLoadNext(featureFlags);
        }}
        onSetAsArchived={(analysisId, sessionId) =>
          dispatchSetSessionStatus(
            analysisId,
            sessionId,
            SessionStatus.Archived,
            featureFlags
          )
        }
        onDownloadEncryptedPdf={dispatchDownloadEncryptedPdf}
      />
    </StoryLayout.Layout>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(Worklist);

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Card } from '@webMolecules/Card/Card';
import { Modal } from '@webMolecules/Modal/Modal';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import styles from './dialogConfirm.scss';

export interface DialogConfirmProps {
  isOpen: boolean;
  labelCancel?: string;
  labelOk?: string;
  intent?: 'destructive' | 'neutral' | 'primary' | undefined;
  onConfirm: (() => void) | string;
  onCancel: () => void;
  onClose?: () => void;
  confirmButtonLoading?: boolean;
  conformButtonRouteTo?: string;
  'data-testid'?: string;
}

export const DialogConfirm: React.FC<DialogConfirmProps> = props => {
  const {
    isOpen,
    labelCancel,
    labelOk = 'OK',
    intent,
    onConfirm,
    onCancel,
    onClose,
    children,
    confirmButtonLoading = false,
    conformButtonRouteTo,
  } = props;

  const diagramDataTestId = props['data-testid'];

  return (
    <Modal isOpen={isOpen} onClose={onClose || onCancel} size="small">
      <Card padding="none" elevation="medium" data-testid={diagramDataTestId}>
        <Box padding="xl">{children}</Box>
        <Box
          padding="m"
          display="flex"
          gap="m"
          justifyContent="flex-end"
          flexDirection="column"
          className={styles.dialogFooter}
          style={{ textAlign: 'right' }}
        >
          <Box display="flex" gap="m" justifyContent="flex-end">
            {!!labelCancel && (
              <Button
                onClick={typeof onCancel === 'function' ? onCancel : undefined}
                href={typeof onCancel === 'string' ? onCancel : undefined}
                strong
              >
                {labelCancel}
              </Button>
            )}

            <RouteLink
              to={conformButtonRouteTo}
              component={props => (
                <Button
                  data-testid={
                    diagramDataTestId
                      ? diagramDataTestId + '-confirm'
                      : 'dialog-confirm'
                  }
                  onClick={
                    typeof onConfirm === 'function' ? onConfirm : undefined
                  }
                  href={typeof onConfirm === 'string' ? onConfirm : undefined}
                  intent={intent}
                  strong
                  loading={confirmButtonLoading}
                  {...props}
                >
                  {labelOk}
                </Button>
              )}
            />
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { useDispatcher } from '@helpers/useDispatcher';
import { adminApplicationUseCase } from '@useCases/Admin/Application';
import { selectAdminApplication } from '@selectors/BaseSelectors';
import { ApiStatus } from '@entities/Status';
import {
  FindingSortType,
  InstitutionConfiguration,
  SizeVariant,
} from '@entities/InstitutionConfiguration';
import { t } from '@webInterfaces/I18n';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Label } from '@webMolecules/Label/Label';
import { WorkflowValues } from '@entities/Workflow';
import {
  findingSortTypeToI8nKey,
  sizeVariantToI18nKey,
  workflowToI18nKey,
} from '@interfaces/Admin/Application';
import { DistanceUnit, getUnitI18nKey } from '@entities/Unit';
import {
  FormInput,
  FormSwitch,
  FormToggle,
} from '@webOrganisms/FormFields/FormFields';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { RouteLeavingGuard } from '@webMolecules/RouteLeavingGuard/RouteLeavingGuard';

export const ApplicationSettings: React.FC = () => {
  const { institutionConfiguration } = useSelector(selectAdminApplication);
  const values = institutionConfiguration.second().lift();
  const updating = institutionConfiguration.first() === ApiStatus.Busy;

  const updateInstitutionConfiguration = useDispatcher(
    adminApplicationUseCase.UpdateInstitutionConfiguration
  );

  const [showDialog, setShowDialog] = useState<'save' | null>(null);
  const closeDialog = () => setShowDialog(null);

  const methods = useForm<InstitutionConfiguration>({
    values,
  });
  const isDirty = !isEqual(methods.watch(), values);
  const onReset = () => methods.reset(values);

  return (
    <>
      <div className="mb-xl flex items-center">
        <span className="mr-l block text-display1">
          {t('pages.settings.application.title')}
        </span>
        {!values && <LoadingIcon />}
      </div>

      {values && (
        <FormProvider {...methods}>
          <ApplicationSettingsSection
            label={t('pages.settings.application.section.general')}
          >
            <ApplicationSettingsRow
              label="pages.settings.application.workflow.label"
              caption="pages.settings.application.workflow.caption"
            >
              <FormToggle
                name="workflow"
                options={WorkflowValues.map(value => ({
                  label: t(workflowToI18nKey(value)),
                  value,
                }))}
                size="large"
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow label="pages.settings.application.measurementUnit.label">
              <FormToggle
                name="noduleSizeDisplayUnit"
                options={[DistanceUnit.MM, DistanceUnit.CM].map(value => ({
                  label: t(getUnitI18nKey(value)),
                  value,
                }))}
                size="large"
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow
              label="pages.settings.application.minimumNoduleSizeThreshold.label"
              caption="pages.settings.application.minimumNoduleSizeThreshold.caption"
            >
              <div className="flex items-center gap-xs">
                <FormInput
                  name="minimumNoduleSizeThreshold"
                  type="number"
                  min="0"
                  width="119px"
                  size="medium"
                />
                <span className="text-body2 text-muted">
                  {t('unit.distance_unit--millimeter')}
                </span>
              </div>
            </ApplicationSettingsRow>

            <ApplicationSettingsRow
              label="pages.settings.application.autosizing.label"
              caption="pages.settings.application.autosizing.caption"
            >
              <FormSwitch
                name="autosizing"
                label={checked =>
                  checked ? t('generic.switch.on') : t('generic.switch.off')
                }
              />
            </ApplicationSettingsRow>
          </ApplicationSettingsSection>

          <ApplicationSettingsSection
            label={t('pages.settings.application.section.breast')}
          >
            <ApplicationSettingsRow
              label="pages.settings.application.breast_size_variant.label"
              caption="pages.settings.application.breast_size_variant.caption"
            >
              <FormToggle
                name="breastSizeVariant"
                options={[SizeVariant.Size1D, SizeVariant.Size3D].map(
                  value => ({
                    label: t(sizeVariantToI18nKey(value)),
                    value,
                  })
                )}
                size="large"
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow
              label="pages.settings.application.simplifiedBreastReportingWorkflow.label"
              caption="pages.settings.application.simplifiedBreastReportingWorkflow.caption"
            >
              <FormSwitch
                name="simplifiedBreastReportingWorkflow"
                label={checked =>
                  checked ? t('generic.switch.on') : t('generic.switch.off')
                }
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow label="pages.settings.application.showBiradsScore.label">
              <FormSwitch
                name="showBiradsScore"
                label={checked =>
                  checked ? t('generic.switch.on') : t('generic.switch.off')
                }
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow label="pages.settings.application.findingSortType.label">
              <FormToggle
                name="breastSortType"
                options={[FindingSortType.Side, FindingSortType.Severity].map(
                  value => ({
                    label: t(findingSortTypeToI8nKey(value)),
                    value,
                  })
                )}
                size="large"
              />
            </ApplicationSettingsRow>
          </ApplicationSettingsSection>

          <ApplicationSettingsSection
            label={t('pages.settings.application.section.thyroid')}
          >
            <ApplicationSettingsRow
              label="pages.settings.application.thyroid_size_variant.label"
              caption="pages.settings.application.thyroid_size_variant.caption"
            >
              <FormToggle
                name="thyroidSizeVariant"
                options={[SizeVariant.Size1D, SizeVariant.Size3D].map(
                  value => ({
                    label: t(sizeVariantToI18nKey(value)),
                    value,
                  })
                )}
                size="large"
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow
              label="pages.settings.application.thyroidAutoGrouping.label"
              caption="pages.settings.application.thyroidAutoGrouping.caption"
            >
              <FormSwitch
                name="thyroidAutoGrouping"
                label={checked =>
                  checked ? t('generic.switch.on') : t('generic.switch.off')
                }
              />
            </ApplicationSettingsRow>

            <ApplicationSettingsRow label="pages.settings.application.findingSortType.label">
              <FormToggle
                name="thyroidSortType"
                options={[FindingSortType.Side, FindingSortType.Severity].map(
                  value => ({
                    label: t(findingSortTypeToI8nKey(value)),
                    value,
                  })
                )}
                size="large"
              />
            </ApplicationSettingsRow>
          </ApplicationSettingsSection>

          <div className="flex gap-m">
            <Button
              type="button"
              strong
              iconBefore={<Icon name="rotate-ccw" />}
              onClick={onReset}
              disabled={!isDirty}
            >
              {t('generic.submit.reset')}
            </Button>
            <Button
              loading={updating}
              type="button"
              data-testid="settingsapplication-submit"
              intent="primary"
              strong
              iconBefore={<Icon name="check" />}
              onClick={() => setShowDialog('save')}
            >
              {t('pages.settings.application.form.save')}
            </Button>
          </div>
        </FormProvider>
      )}

      <SaveSettingsDialog
        isOpen={showDialog === 'save'}
        onConfirm={() => {
          updateInstitutionConfiguration(methods.getValues());
          closeDialog();
        }}
        onCancel={closeDialog}
      />

      <RouteLeavingGuard
        when={isDirty}
        renderDialog={({ open, confirm, cancel }) => (
          <LeaveSettingsDialog
            isOpen={open}
            onConfirm={confirm}
            onCancel={cancel}
          />
        )}
      />
    </>
  );
};

interface ApplicationSettingsRowProps {
  label: string;
  caption?: string;
}

const ApplicationSettingsRow: React.FC<ApplicationSettingsRowProps> = ({
  label,
  caption,
  children,
}) => {
  return (
    <div>
      <div className="flex items-center">
        <Label className="w-[200px] flex-none">{t(label)}</Label>
        <div>{children}</div>
      </div>
      {caption && (
        <div className="w-full pl-[200px]">
          <span className="whitespace-pre-wrap text-caption text-muted-extra">
            {t(caption)}
          </span>
        </div>
      )}
    </div>
  );
};

interface ApplicationSettingsSectionProps {
  label: string;
}

const ApplicationSettingsSection: React.FC<ApplicationSettingsSectionProps> = ({
  label,
  children,
}) => {
  return (
    <div className="mb-xxl">
      <div className="mb-m border-b border-b-neutral-400 uppercase">
        <span className="text-body2 text-muted">{label}</span>
      </div>
      <div className="flex flex-col gap-l">{children}</div>
    </div>
  );
};

interface DialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const SaveSettingsDialog: React.FC<DialogProps> = props => {
  return (
    <DialogConfirm
      {...props}
      intent="primary"
      labelOk={t('pages.settings.application.form.save')}
      labelCancel={t('generic.submit.cancel')}
    >
      <span className="mb-m block text-display2">
        {t('pages.settings.application.dialog.save.title')}
      </span>
      <span className="text-body">
        {t('pages.settings.application.dialog.save.blurb')}
      </span>
    </DialogConfirm>
  );
};

export const LeaveSettingsDialog: React.FC<DialogProps> = props => {
  return (
    <DialogConfirm
      {...props}
      intent="neutral"
      labelOk={t('generic.page.leave')}
      labelCancel={t('generic.submit.cancel')}
    >
      <span className="mb-m block text-display2">
        {t('pages.settings.application.dialog.leave.title')}
      </span>
      <span className="whitespace-pre-wrap text-body">
        {t('pages.settings.application.dialog.leave.blurb')}
      </span>
    </DialogConfirm>
  );
};

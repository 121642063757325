import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { FindingFieldConfig } from '@config/ResultTable/Shared';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { MergedCellThyroidSVGSelection } from '../Cells/MergedCellThyroidSVGSelection';

export interface DetailedFindingTableMergedCellProps
  extends ResultTableSharedProps {
  config: FindingFieldConfig;
  disabled?: boolean;
}

export const DetailedFindingTableMergedCell: React.FC<
  DetailedFindingTableMergedCellProps
> = ({ config, disabled, ...sharedProps }) => {
  switch (config.mergedCellFields?.type) {
    case 'thyroidSVGSelection':
      return (
        <MergedCellThyroidSVGSelection
          mergedCellFields={config.mergedCellFields.config}
          disabled={disabled}
          confidenceThreshold={config.confidenceThreshold}
          {...sharedProps}
        />
      );

    default:
      return <Box>?</Box>;
  }
};

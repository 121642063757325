import React from 'react';
import { useController } from 'react-hook-form';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { ResultTableToggleConfig } from '@config/ResultTable/ResultTable';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import {
  ToggleGroup,
  ToggleGroupOption,
} from '@webMolecules/ToggleGroup/ToggleGroup';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { getDisplayedOptionLabel } from '../FindingsTable/Shared';

interface ResultTableCellToggleGroupProps extends ResultTableSharedProps {
  toggleConfig: ResultTableToggleConfig;
  toggleKey: string;
  disabled?: boolean;
}

export const ResultTableCellToggleGroup: React.FC<
  ResultTableCellToggleGroupProps
> = ({ path, toggleConfig, toggleKey, disabled }) => {
  const { theme } = React.useContext(ResultTableContext);

  const { field } = useController({
    name: path ?? '',
  });

  if (!toggleConfig) {
    return null;
  }

  if (theme == ResultTableStyle.preview) {
    const option = toggleConfig.options.find(
      option => option.value === field.value
    );
    return option ? (
      <Text type="body2" lineClamp={1}>
        {t(option.previewI18NKey)}
      </Text>
    ) : null;
  }

  const options: ToggleGroupOption[] = toggleConfig?.options?.map(option => ({
    value: option.value,
    label: getDisplayedOptionLabel(option.value, t(option.editableI18NKey)),
    tooltip: option.tooltipI18NKey && t(option.tooltipI18NKey),
  }));

  return (
    <ToggleGroup
      key={toggleKey}
      size={toggleConfig.size || 'small'}
      options={options}
      value={field.value}
      onChange={field.onChange}
      disabled={disabled}
    />
  );
};

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { toSession } from '@interfaces/App';
import { GalleryFilter } from '@interfaces/GalleryFilter';
import { toApp } from '@interfaces/Root';
import { StudyLayout } from '@interfaces/Session';
import { GalleryFilterSelectOption } from '@webOrganisms/Gallery/GalleryFilter';
import { Workflow } from '@entities/Workflow';

import {
  getGalleryFilterSelectOptions,
  sortDicomByScanTime,
} from './GalleryHelper';
export type Gallery = GalleryProps & GalleryDispatchers;

export const getTotalFilters = (galleryFilter: GalleryFilter) => {
  return galleryFilter.findingIds.length;
};

interface ProxiedGalleryProps {
  tabName?: string;
  analysisCreatedDate?: Date;
  loading: boolean;
  filters: GalleryFilter;
  setGalleryFilter: React.Dispatch<React.SetStateAction<GalleryFilter>>;
  includeAllDicoms?: boolean;
  workflow?: Workflow;
  useFindingGalleryThumbnail?: boolean;
}

export interface GalleryProps extends ProxiedGalleryProps {
  studyLayoutConfig: StudyLayout;
  debugDicom: boolean;
  hotKeyDown: boolean;
  galleryFilterSelectOptions: GalleryFilterSelectOption[];
}

export interface GalleryDispatchers {}

export const dispatchers = (
  _dispatch: Dispatch<Actions>
): GalleryDispatchers => ({});

export const fromState = (
  state: State,
  proxiedProps: ProxiedGalleryProps
): GalleryProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const session = toSession(app);

  const dicoms = session.analysis.mapOr(a => a.dicoms, []);
  const studyType = session.analysis.mapOr(a => a.studyType, undefined);

  const sortedDicoms = sortDicomByScanTime(dicoms);

  const debugDicom = app.debug.dicom;

  const galleryFilterSelectOptions = getGalleryFilterSelectOptions(
    studyType,
    sortedDicoms
  );

  return {
    ...proxiedProps,
    studyLayoutConfig: session.studyLayoutConfig,
    debugDicom,
    hotKeyDown: false,
    galleryFilterSelectOptions,
  };
};

import { Action } from 'redux';
import { PACS, initial } from '@entities/PACS';
import { PACSStudy as ContractPACSStudy } from '@contracts/PACSStudy';
import { EffectReducer, ReducerResult } from '@library/Reducer';
import Effect, { batch, effect } from '@library/Effect';
import { Authenticate as ContractAuthenticate } from '@contracts/Authenticate';
import {
  mapPACSStudyStatusToPacsSystemStatus,
  PACSStudyLineItem,
} from '@entities/PACSStudyLineItem';
import { pair } from '@monads/Tuple';
import { ApiStatus } from '@interfaces/Status';
import { createUseCase } from '@helpers/createUseCase';
export const PACSStudyUseCase = {
  /**
   * Init
   * - init will load all analyses and start subscribing for any analysis creations and changes.
   */
  Init: createUseCase('PACS_STUDY_INIT').withPayload<{
    dispatch: (action: Action) => void;
  }>(),

  /**
   * Start Poll
   * - start polling for pacs modal information
   */
  StartPoll: createUseCase('PACS_STUDY_START_POLL').noPayload(),

  /**
   * Cancel Poll
   * - cancel polling for pacs modal information
   */
  CancelPoll: createUseCase('PACS_STUDY_CANCEL_POLL').noPayload(),

  /**
   * List studies
   * Load a worklist with the given optional options
   */
  ListStudies: createUseCase('PACS_STUDY_LIST_STUDIES').noPayload(),

  /**
   * Set studies
   * List studies Result
   */
  SetStudies: createUseCase('PACS_STUDY_SET_STUDIES').withPayload<{
    pacsStudyLineItems: PACSStudyLineItem[];
    totalItems: number;
  }>(),

  /**
   * Unload PACS
   */
  UnloadStudies: createUseCase('PACS_STUDY_UNLOAD_STUDIES').noPayload(),

  /**
   * No Operation
   */
  NoOp: createUseCase('PACS_STUDY_NO_OP').noPayload(),
};

type IntervalLabel = 'pacsModal';

export type PACSStudyUseCases = ReturnType<
  | typeof PACSStudyUseCase.Init
  | typeof PACSStudyUseCase.StartPoll
  | typeof PACSStudyUseCase.CancelPoll
  | typeof PACSStudyUseCase.SetStudies
  | typeof PACSStudyUseCase.ListStudies
  | typeof PACSStudyUseCase.UnloadStudies
  | typeof PACSStudyUseCase.NoOp
>;

export class PACSStudyReducer extends EffectReducer<PACS> {
  private apiPACSStudy: ContractPACSStudy;
  private apiAuthenticate: ContractAuthenticate;
  private pollDispatch?: (useCase: PACSStudyUseCases) => void;
  private intervalIds: Map<IntervalLabel, NodeJS.Timeout> = new Map();

  private static INTERVAL_SIDEBAR_MS = 10 * 1000;

  constructor(
    apiPACSStudy: ContractPACSStudy,
    apiAuthenticate: ContractAuthenticate
  ) {
    super();
    this.apiPACSStudy = apiPACSStudy;
    this.apiAuthenticate = apiAuthenticate;
  }

  Perform(
    pacs: PACS = initial(),
    { type, payload: useCase }: PACSStudyUseCases
  ): ReducerResult<PACS> {
    switch (type) {
      case PACSStudyUseCase.Init.type: {
        this.CancelLastEffect();
        this.pollDispatch = useCase.dispatch;
        return this.Result(pacs);
      }
      case PACSStudyUseCase.StartPoll.type:
        this.StartBackgroundPollingPACSModalFeed();
        return this.Result(
          {
            ...pacs,
          },
          batch([effectListPACSStudies(this.apiPACSStudy)])
        );
      case PACSStudyUseCase.CancelPoll.type:
        this.CancelBackgroundPollingSidebarFeed();
        return this.Result({
          ...pacs,
        });
      case PACSStudyUseCase.ListStudies.type: {
        return this.Result(
          {
            ...pacs,
            pacsStudyLineItems: pacs.pacsStudyLineItems.setFirst(
              ApiStatus.Busy
            ),
          },
          batch([effectListPACSStudies(this.apiPACSStudy)])
        );
      }
      case PACSStudyUseCase.SetStudies.type: {
        const pacsSystemStatus = mapPACSStudyStatusToPacsSystemStatus(
          useCase.pacsStudyLineItems
        );
        return this.Result({
          ...pacs,
          pacsSystemStatus,
          pacsStudyLineItems: pair(ApiStatus.Idle, useCase.pacsStudyLineItems),
          totalAvailableItems: useCase.totalItems,
        });
      }
      case PACSStudyUseCase.UnloadStudies.type: {
        return this.Result({ ...pacs });
      }
      case PACSStudyUseCase.NoOp.type:
        return this.Result(pacs);
    }
  }

  // @TODO(Perf): There are a few ways to optimise polling
  // - Create a separate 'authenticated' reducer so we can always guaranteee authenticated users
  // - Only poll in the worklist page page.
  private poll(
    useCases: PACSStudyUseCases[],
    intervalLabel: IntervalLabel,
    interval: number,
    instant?: true
  ) {
    if (!this.pollDispatch) {
      return;
    }
    this.cancelPoll(intervalLabel);
    const f = async (retryOnUnauthenticated: boolean, backoff: number) => {
      // Only poll when dispatch is available and user is logged in
      const accessToken = await this.apiAuthenticate.GetCurrentSession();
      if (this.pollDispatch && accessToken) {
        useCases.forEach(this.pollDispatch);
      } else if (retryOnUnauthenticated) {
        setTimeout(() => f(true, backoff * 2), backoff * 1000);
      }
    };
    if (instant) {
      window.requestAnimationFrame(() => {
        f(true, 1);
      });
    }

    this.intervalIds.set(
      intervalLabel,
      setInterval(() => f(false, 1), interval)
    );
  }

  private cancelPoll(intervalLabel: IntervalLabel) {
    const intervalId = this.intervalIds.get(intervalLabel);
    if (intervalId) {
      clearInterval(intervalId);
      this.intervalIds.delete(intervalLabel);
    }
  }

  private StartBackgroundPollingPACSModalFeed() {
    this.poll(
      [PACSStudyUseCase.ListStudies()],
      'pacsModal',
      PACSStudyReducer.INTERVAL_SIDEBAR_MS,
      true
    );
  }
  private CancelBackgroundPollingSidebarFeed() {
    this.cancelPoll('pacsModal');
  }
}

/*
 * Retrieve all pacsStudyLineItems available for the given parameters
 */
const effectListPACSStudies = (apiPACSStudy: ContractPACSStudy): Effect =>
  effect(async () => {
    const { PACSStudyLineItems, totalItems } = await apiPACSStudy.List();
    return PACSStudyUseCase.SetStudies({
      pacsStudyLineItems: PACSStudyLineItems,
      totalItems,
    });
  }, 'effect-worklist-list-pacs-studies');

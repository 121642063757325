import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { deleteNotification } from '@webViewInterfaces/Notifications';
import { toNotifications, toSession } from '@interfaces/App';
import { Notification } from '@interfaces/Notifications';
import { toApp } from '@interfaces/Root';

export type Notifications = NotificationsProps & NotificationsDispatchers;

export interface NotificationsProps {
  notifications: Notification[];
  isPdfDownloading?: boolean;
}

export interface NotificationsDispatchers {
  dispatchDeleteNotification: (id: number) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  dispatchDeleteNotification: (id: number) => dispatch(deleteNotification(id)),
});

export const fromState = (state: State): NotificationsProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }
  const session = toSession(app);
  const isPdfDownloading = session.isPdfDownloading;
  return {
    notifications: toNotifications(app).list,
    isPdfDownloading,
  };
};

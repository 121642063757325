import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import styles from '../comparison.scss';
interface FindingNoduleSeparatorProps {
  symbol: string | React.ReactNode;
}
export const FindingNoduleSeparator = ({
  symbol,
}: FindingNoduleSeparatorProps) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      className={styles.separator}
    >
      <Text display="block">{symbol}</Text>
    </Box>
  );
};

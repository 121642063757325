import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import {
  GeneralCharacteristicFieldConfig,
  GeneralCharacteristicTableConfigSection,
  ResultTableFilterButtonOptions,
} from '@config/ResultTable/ResultTable';
import { Table, TableCell, TableRow } from '@webMolecules/Table/Table';
import { ResultTableCellFilterButton } from '@webOrganisms/ResultTable/Cells/FilterButton';
import { InfoButton } from '@webMolecules/InfoButton/InfoButton';
import { InlineResultTableCell } from '@webOrganisms/ResultTable/InlineResultTable/InlineResultTableCell';
import { ThyroidGeneralCharacteristics } from '@entities/ThyroidGeneralCharacteristics';
import { GalleryFilter } from '@interfaces/GalleryFilter';

export interface GeneralCharacteristicsTableSectionProps {
  config: GeneralCharacteristicTableConfigSection<FieldValues>;
  filters: GalleryFilter;
  setGalleryFilter: React.Dispatch<React.SetStateAction<GalleryFilter>>;
}

export const evaluateFieldFunction = (
  fieldFunction:
    | ((generalCharacteristics: ThyroidGeneralCharacteristics) => boolean)
    | undefined,
  generalCharacteristics: ThyroidGeneralCharacteristics
) => {
  return fieldFunction?.(generalCharacteristics) ?? false;
};

export const GeneralCharacteristicsTableSection: React.FC<
  GeneralCharacteristicsTableSectionProps
> = ({ config, filters, setGalleryFilter }) => {
  const { watch } = useFormContext<ThyroidGeneralCharacteristics>();
  const generalCharacteristics = watch();

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        {config.heading && (
          <Text type="body" muted display="block" marginBottom="xs">
            {t(config.heading)}
          </Text>
        )}
        {config.galleryFilter && (
          <ResultTableCellFilterButton
            specificGalleryFilter={config.galleryFilter}
            filters={filters}
            setGalleryFilter={setGalleryFilter}
          />
        )}
      </Box>

      <Table spacing="condensed">
        {config?.rows.map((row, i) => {
          const shown = row.isShown
            ? evaluateFieldFunction(row.isShown, generalCharacteristics)
            : true;
          return (
            shown && (
              <GeneralCharacteristicsRow
                key={i}
                config={row}
                labelWidth={config.labelWidth}
                filters={filters}
                setGalleryFilter={setGalleryFilter}
              />
            )
          );
        })}
      </Table>
    </Box>
  );
};

export interface GeneralCharacteristicsRowProps {
  config: GeneralCharacteristicFieldConfig<FieldValues>;
  labelWidth?: string;
  filters: GalleryFilter;
  setGalleryFilter: React.Dispatch<React.SetStateAction<GalleryFilter>>;
}
export const GeneralCharacteristicsRow: React.FC<
  GeneralCharacteristicsRowProps
> = ({ config, labelWidth = '150px', filters, setGalleryFilter }) => {
  const { key, label, hideLabel } = config;

  return (
    <TableRow>
      <TableCell key={`cell-${key}`} verticalAlign={config.verticalAlign}>
        <Box display="flex" flexDirection="row" gap="m">
          <Box display="flex" style={{ width: labelWidth }}>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                {hideLabel ? null : (
                  <Text
                    type="body2"
                    display="block"
                    marginTop="xxs"
                    lineClamp={!!config.infoButton ? 1 : undefined}
                  >
                    {label ? t(label) : t(`study.result_table.${key}`)}
                  </Text>
                )}
                {!!config.infoButton && <InfoButton {...config.infoButton} />}
              </Box>
              {config.caption && (
                <Text type="caption" muted>
                  {t(config.caption)}
                </Text>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" flex="1">
            <Box
              style={{ width: config.width }}
              marginTop="auto"
              marginBottom="auto"
            >
              <InlineResultTableCell config={config} path={config.path} />
            </Box>
            <Box>
              {config.galleryFilter && (
                <ResultTableCellFilterButton
                  options={config.inputConfig as ResultTableFilterButtonOptions}
                  specificGalleryFilter={config.galleryFilter}
                  filters={filters}
                  setGalleryFilter={setGalleryFilter}
                />
              )}
            </Box>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

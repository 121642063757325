export const DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT =
  'DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT';

export interface AnalysisUploadsLineItemManualImport
  extends AnalysisUploadsLineItemBase {
  type: typeof DIRECTORY_UPLOADS_LINE_ITEM_MANUAL_IMPORT;
  filesUploaded: number;
  filesAnonymised: number;
  errors: AnalysisUploadsLineItemFileError[];
}

export const DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS =
  'DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS';

export interface AnalysisUploadsLineItemReanalysis
  extends AnalysisUploadsLineItemBase {
  type: typeof DIRECTORY_UPLOADS_LINE_ITEM_REANALYSIS;
}

export interface AnalysisUploadsLineItemBase {
  localBatchId: string;
  localAnalysisId?: string;
  accessionNumber?: string;
  sessionId?: string;
  filesTotal: number;
  filesProcessed: number;
  failed: boolean;
  autoRedirect?: boolean;
}

export type AnalysisUploadsLineItem =
  | AnalysisUploadsLineItemManualImport
  | AnalysisUploadsLineItemReanalysis;

export interface AnalysisUploadsLineItemFileError {
  fileName: string;
  errorMessage: string;
}

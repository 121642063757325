import {
  BodySide,
  BreastFindingArchitecture,
  BreastFindingCalcification,
  BreastFindingEchoPattern,
  BreastFindingElasticity,
  BreastFindingMargin,
  BreastFindingOrientation,
  BreastFindingPosition,
  BreastFindingPosteriorFeature,
  BreastFindingShape,
  BreastFindingSkinChanges,
  BreastFindingSpecialCase,
  BreastFindingType,
  BreastFindingVascularity,
  ClinicalAction,
  ThyroidFindingComposition,
  ThyroidFindingEchogenicFoci,
  ThyroidFindingEchogenicity,
  ThyroidFindingMargin,
  ThyroidFindingShape,
  ThyroidPole,
} from './Characteristics';
import { DistanceUnit, VolumeUnit } from './Unit';
import { BiradsScore, TiRadsGrading } from './Grading';

export enum FindingType {
  Thyroid = 'Thyroid',
  Breast = 'Breast',
}

export interface GenericFinding<T extends FindingType> {
  type: T;
  id: string;
  index: number;
  included: boolean;
  detectionIds: string[];
  comparisonFindingId?: string;
  sizes: FindingSizes;
  characteristics: CharacteristicsMap[T];
  comparedFinding?: ComparedGenericFinding<T>;
  formatted: FormattedProperties;
  canvasPosition?: CanvasPosition;
  autoGenerated: boolean;
  notes?: string | null;
}

export interface CanvasPosition {
  xCoordinate?: number | null;
  yCoordinate?: number | null;
  positionNoiseX?: number | null;
  positionNoiseY?: number | null;
  customWidth?: number | null;
  customHeight?: number | null;
}

export type ComparedGenericFinding<T extends FindingType> = Omit<
  GenericFinding<T>,
  'comparedFinding'
>;

export interface FormattedProperties {
  grading?: TiRadsGrading | BiradsScore;
  location?: string;
  isSize3D?: boolean;
  max?: FormattedSize;
  length?: FormattedSize;
  width?: FormattedSize;
  height?: FormattedSize;
  volume?: FormattedSize;
  dicomIds?: string[];
}

export type ThyroidFinding = GenericFinding<FindingType.Thyroid>;
export type BreastFinding = GenericFinding<FindingType.Breast>;
export type Finding = ThyroidFinding | BreastFinding;

export interface FindingSizes {
  max: Size | null;
  length: Size | null;
  width: Size | null;
  height: Size | null;
  volume: Volume | null;
}

export interface Size {
  value: number;
  unit: DistanceUnit;
  referenceId?: string;
}

export interface Volume {
  value: number;
  unit: VolumeUnit;
}

export type Clockface = {
  hour: string | null;
  minute: string | null;
};

export interface FormattedSize {
  value: number;
  unit: string;
  referenceId?: string;
}

interface CharacteristicsMap {
  [FindingType.Thyroid]: ThyroidFindingCharacteristics;
  [FindingType.Breast]: BreastFindingCharacteristics;
}

export interface ThyroidFindingCharacteristics {
  side: BodySide | null;
  pole: ThyroidPole | null;
  composition: ThyroidFindingComposition | null;
  echogenicity: ThyroidFindingEchogenicity | null;
  margin: ThyroidFindingMargin | null;
  shape: ThyroidFindingShape | null;
  echogenicFoci: ThyroidFindingEchogenicFoci[] | null;
  scorePoints: number;
  clinicalAction: ClinicalAction | null;
}

export interface BreastFindingCharacteristics {
  side: BodySide | null;
  position: BreastFindingPosition | null;
  clockface: Clockface | null;
  distanceFromNipple: Size | null;
  specialCase: BreastFindingSpecialCase | null;
  posteriorFeatures: BreastFindingPosteriorFeature | null;
  shape: BreastFindingShape | null;
  orientation: BreastFindingOrientation | null;
  margin: BreastFindingMargin | null;
  echoPattern: BreastFindingEchoPattern | null;
  calcifications: BreastFindingCalcification | null;
  type: BreastFindingType | null;
  vascularity: BreastFindingVascularity | null;
  elasticity: BreastFindingElasticity | null;
  skinChanges: BreastFindingSkinChanges | null;
  architecture: BreastFindingArchitecture[] | null;
  biradsScore: BiradsScore | null;
}

export const initialThyroidFinding = (): ThyroidFinding => {
  return {
    type: FindingType.Thyroid,
    id: '',
    index: 100,
    included: true,
    autoGenerated: false,
    detectionIds: [],
    characteristics: {
      side: null,
      pole: null,
      composition: null,
      echogenicity: null,
      margin: null,
      shape: null,
      echogenicFoci: null,
      scorePoints: 0,
      clinicalAction: null,
    },
    sizes: {
      max: null,
      length: null,
      width: null,
      height: null,
      volume: null,
    },
    formatted: {},
  };
};

export const initialBreastFinding = (): BreastFinding => {
  return {
    type: FindingType.Breast,
    id: '',
    index: 100,
    included: true,
    autoGenerated: false,
    detectionIds: [],
    characteristics: {
      side: null,
      position: null,
      clockface: null,
      distanceFromNipple: null,
      specialCase: null,
      posteriorFeatures: null,
      shape: null,
      orientation: null,
      margin: null,
      echoPattern: null,
      calcifications: null,
      type: null,
      vascularity: null,
      elasticity: null,
      skinChanges: null,
      architecture: null,
      biradsScore: null,
    },
    sizes: {
      max: null,
      length: null,
      width: null,
      height: null,
      volume: null,
    },
    formatted: {},
  };
};

import { StudyType } from '@entities/StudyType';
import {
  FindingSortType,
  InstitutionConfiguration,
} from '@entities/InstitutionConfiguration';
import { getBreastFindingDetailedConfig } from './Breast';
import { getThyroidFindingDetailedConfig } from './Thyroid';
import { FindingConfig } from './Shared';

type FindingTableConfigProps = Partial<Record<StudyType, DetailedFindingProps>>;

export interface DetailedFindingProps {
  config: FindingConfig;
  showCanvas?: boolean;
  findingFilters: FindingFilterConfig[];
  sortOptions: FindingSortOption[];
}

export enum FindingFilterType {
  IncludeTop = 'include_top',
  IncludeAll = 'include_all',
  IncludeSelected = 'include_selected',
  ExcludeSimpleCyst = 'exclude_simple_cyst',
}

export interface FindingFilterConfig {
  filterType: FindingFilterType;
  labelKey: string;
}

export const ThyroidFindingFilters: FindingFilterConfig[] = [
  {
    filterType: FindingFilterType.IncludeTop,
    labelKey: 'pages.study.finding.filter.top',
  },
  {
    filterType: FindingFilterType.IncludeAll,
    labelKey: 'pages.study.finding.filter.all',
  },
  {
    filterType: FindingFilterType.IncludeSelected,
    labelKey: 'pages.study.finding.filter.selected',
  },
];

export const BreastFindingFilters: FindingFilterConfig[] = [
  {
    filterType: FindingFilterType.ExcludeSimpleCyst,
    labelKey: 'pages.study.finding.filter.exclude_simple_cyst',
  },
  {
    filterType: FindingFilterType.IncludeAll,
    labelKey: 'pages.study.finding.filter.all',
  },
  {
    filterType: FindingFilterType.IncludeSelected,
    labelKey: 'pages.study.finding.filter.selected',
  },
];

export interface FindingSortOption {
  sortType: FindingSortType;
  labelKey: string;
}

export const ThyroidSortOptions: FindingSortOption[] = [
  {
    sortType: FindingSortType.Side,
    labelKey: 'pages.study.finding.sort.option.side',
  },
  {
    sortType: FindingSortType.Severity,
    labelKey: 'pages.study.finding.sort.option.severity',
  },
];

export const BreastSortOptions: FindingSortOption[] = [
  {
    sortType: FindingSortType.Side,
    labelKey: 'pages.study.finding.sort.option.side',
  },
  {
    sortType: FindingSortType.Severity,
    labelKey: 'pages.study.finding.sort.option.severity',
  },
];

export const getAllFindingTableConfig = (
  institutionConfig: Required<InstitutionConfiguration>
): FindingTableConfigProps => {
  const sizeUnit = institutionConfig.noduleSizeDisplayUnit;
  return {
    [StudyType.SmallPartsBreast]: {
      config: getBreastFindingDetailedConfig(sizeUnit),
      showCanvas: true,
      findingFilters: BreastFindingFilters,
      sortOptions: BreastSortOptions,
    },
    [StudyType.SmallPartsThyroid]: {
      config: getThyroidFindingDetailedConfig(sizeUnit),
      showCanvas: true,
      findingFilters: ThyroidFindingFilters,
      sortOptions: ThyroidSortOptions,
    },
  } as const;
};

export function getFindingTableConfig(
  studyType: StudyType | undefined,
  institutionConfig: Required<InstitutionConfiguration>
) {
  if (!studyType) return undefined;
  return getAllFindingTableConfig(institutionConfig)[studyType];
}

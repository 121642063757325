import { schemas } from '@api/schema/client';
import { AppConfig } from '@interfaces/AppConfig';

export const decode = (
  data: schemas['GetApplicationConfigResponse']
): AppConfig => {
  return {
    regulatoryVersion: data.regulatory_version?.regulatory_version ?? '',
    regulatoryVersionModifiedDate: data.regulatory_version?.modified_date ?? '',
  };
};

import * as React from 'react';
import { t } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { Text } from '@webMolecules/Text/Text';

interface DialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DiscardChangesDialog: React.FC<DialogProps> = props => {
  return (
    <DialogConfirm
      {...props}
      intent="destructive"
      labelCancel={t('generic.submit.cancel')}
      labelOk={t('pages.report.findings.dialog.discard.submit')}
    >
      <Text type="display2" display="block" marginBottom="m">
        {t('pages.report.findings.dialog.discard.title')}
      </Text>
      <Text renderHtml display="block">
        {t('pages.report.findings.dialog.discard.blurb1')}
      </Text>
    </DialogConfirm>
  );
};

export const DeleteFindingDialog: React.FC<DialogProps> = props => {
  return (
    <DialogConfirm
      {...props}
      intent="destructive"
      labelCancel={t('generic.submit.cancel')}
      labelOk={t('pages.report.findings.dialog.delete.submit')}
    >
      <Text type="display2" display="block" marginBottom="m">
        {t('pages.report.findings.dialog.delete.title')}
      </Text>
      <Text renderHtml display="block">
        {t('pages.report.findings.dialog.delete.blurb1')}
      </Text>
    </DialogConfirm>
  );
};

import { FindingFilterType } from '@config/ResultTable/FindingTable';
import { DimensionType } from '@entities/Detection';
import { SessionStatus } from '@entities/SessionLineItem';
import { DistanceUnit, VolumeUnit } from '@entities/Unit';
import {
  SessionStatusEnum as ApiSessionStatus,
  DistanceUnit as ApiDistanceUnit,
  VolumeUnit as ApiVolumeUnit,
  Dimension as ApiDimension,
  ClinicalAction as ApiClinicalAction,
  FilterTypeEnum as ApiFilterType,
} from '@api/schema/generated';
import { ClinicalAction } from '@entities/Characteristics';

/*
 * While incrementally migrating, we need to map the API generated enums
 * to the app enums to avoid affecting too many places in the codebase at once.
 * Once finalised, we would ideally use generated enums directly in the code.
 */

export function decodeEnum<T extends string, U extends string>(
  value: U,
  mapping: Record<U, T>
): T {
  const result = mapping[value];
  if (result === undefined) {
    throw new Error(`Invalid API enum value: ${value}`);
  }
  return result;
}

export function encodeEnum<T extends string, U extends string>(
  value: T,
  mapping: Record<U, T>
): U {
  const entry = Object.entries(mapping).find(([_, val]) => val === value);
  if (!entry) {
    throw new Error(`Invalid client enum value ${value}`);
  }
  return entry[0] as U;
}

export function mapEnumByValue<
  T extends string,
  U extends Record<string, string>,
>(value: T | null, enumType: U): U[keyof U] | null {
  if (value === null) return null;

  return Object.values(enumType).includes(value)
    ? (value as unknown as U[keyof U])
    : null;
}

export function mapEnumArrayByValue<
  T extends string,
  U extends Record<string, string>,
>(value: T[] | null, enumType: U): U[keyof U][] | null {
  if (value === null) return null;

  return value
    .map(v => mapEnumByValue(v, enumType))
    .filter(Boolean) as U[keyof U][];
}

export const SessionStatusMap: Record<ApiSessionStatus, SessionStatus> = {
  [ApiSessionStatus.READY]: SessionStatus.New,
  [ApiSessionStatus.IN_PROGRESS]: SessionStatus.InProgress,
  [ApiSessionStatus.COMPLETED]: SessionStatus.Completed,
  [ApiSessionStatus.ARCHIVED]: SessionStatus.Archived,
  [ApiSessionStatus.ERROR]: SessionStatus.Error,
  [ApiSessionStatus.PROCESSING]: SessionStatus.Processing,
};

export const DistanceUnitMap: Record<ApiDistanceUnit, DistanceUnit> = {
  [ApiDistanceUnit.MM]: DistanceUnit.MM,
  [ApiDistanceUnit.CM]: DistanceUnit.CM,
  [ApiDistanceUnit.M]: DistanceUnit.M,
};

export const VolumeUnitMap: Record<ApiVolumeUnit, VolumeUnit> = {
  [ApiVolumeUnit.ML]: VolumeUnit.ML,
  [ApiVolumeUnit.CL]: VolumeUnit.CL,
  [ApiVolumeUnit.L]: VolumeUnit.L,
};

export const DimensionTypeMap: Record<ApiDimension, DimensionType> = {
  [ApiDimension.LENGTH]: DimensionType.Length,
  [ApiDimension.WIDTH]: DimensionType.Width,
  [ApiDimension.HEIGHT]: DimensionType.Height,
};

export const ClinicalActionMap: Record<ApiClinicalAction, ClinicalAction> = {
  [ApiClinicalAction.FNA]: ClinicalAction.FNA,
  [ApiClinicalAction.FOLLOW_UP]: ClinicalAction.FollowUp,
  [ApiClinicalAction.NO_FURTHER_FOLLOW_UP]: ClinicalAction.NoFurtherFollowUp,
};

export const FindingFilterMap: Record<ApiFilterType, FindingFilterType> = {
  [ApiFilterType.include_all]: FindingFilterType.IncludeAll,
  [ApiFilterType.include_top]: FindingFilterType.IncludeTop,
  [ApiFilterType.include_selected]: FindingFilterType.IncludeSelected,
  [ApiFilterType.exclude_simple_cyst]: FindingFilterType.ExcludeSimpleCyst,
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import styles from './indexLabel.scss';

export interface IndexLabelProps {
  index?: number;
  intent: 'neutral' | 'primary' | 'destructive' | 'information' | 'blank';
  borderRadius: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'all';
  noBorder?: boolean;
  fluid?: boolean;
  active?: boolean;
}

export const IndexLabel: React.FC<IndexLabelProps> = ({
  index,
  intent,
  borderRadius,
  noBorder,
  fluid,
  active,
}) => {
  const classNames = cnames(
    styles.outerContainer,
    styles[intent],
    styles[borderRadius],
    { [styles.noBorder]: noBorder },
    { [styles.fluid]: fluid },
    { [styles.active]: active }
  );
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classNames}
    >
      {index && (
        <Text type="body2" muted={intent === 'neutral'}>
          {index}
        </Text>
      )}
    </Box>
  );
};

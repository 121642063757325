import intersection from 'lodash/intersection';
import Maybe, { just } from '@monads/Maybe';
import { event } from '@helpers/analytics';
import { toApp } from '@interfaces/Root';
import * as Contract from '@contracts/Analytics';
import { stringToStudyType, studyTypeToString } from '@interfaces/StudyType';
import { CanvasAction } from '@contracts/Analytics';
import { CanvasObjectType } from '@appCanvas/interfaces/types';
import { filterDicomsBySpecificGalleryFilter } from '@webViewModels/Pages/Study/GalleryHelper';
import { StudyType } from '@entities/StudyType';
import {
  BreastGalleryFilter,
  ThyroidGalleryFilter,
} from '@config/Gallery/SpecificGalleryFilter';
import { Clockface, Finding, FindingType, Size } from '@entities/Finding';
import { App } from '@entities/App';

export const thyroidLocationProperties = ['side', 'pole'];
export const thyroidCharacteristicsProperties = [
  'composition',
  'echogenicity',
  'margin',
  'shape',
  'echogenicFoci',
];
export const breastLocationProperties = [
  'side',
  'position',
  'clockface',
  'distanceFromNipple',
];

export const breastCharacteristicsProperties = [
  'biradsScore',
  'specialCase',
  'shape',
  'orientation',
  'margin',
  'echoPattern',
  'posteriorFeatures',
  'calcifications',
  'vascularity',
  'elasticity',
  'skinChanges',
  'type',
  'architecture',
];

const sizeProperties = ['width', 'length', 'height', 'max'];

export const getStudyInfo = (app: Maybe<App>) => {
  const analysis = app.andThen(app => app.session.analysis);
  const studyDetails = app.andThen(app => app.session.details);

  const sessionID = studyDetails.map(a => a.id).lift() || '';
  const analysisID = studyDetails.map(a => a.analysisId).lift() || '';
  const studyInstanceUID = analysis.map(a => a.studyUID).lift() || '';
  const accessionID = analysis.map(a => a.accessionNumber).lift() || '';

  const studyType = analysis.mapOr(a => studyTypeToString(a.studyType), '');

  return {
    sessionID,
    analysisID,
    studyInstanceUID,
    accessionID,
    studyType,
  };
};

export const getFindingDicomSopUIDs = (app: Maybe<App>, findingId: string) => {
  const findings = app.lift()?.session.data.findings || [];

  // get detection ids from finding
  const detectionIds =
    findings.find(f => f.id === findingId)?.detectionIds || [];

  const dicomSopUIDs = app
    .andThen(a => a.session.analysis)
    .mapOr(a => a.dicoms, [])
    .filter(dicom => {
      const dicomDetectionIds = dicom.detections.map(d => d.id);
      return !!intersection(dicomDetectionIds, detectionIds).length;
    })
    .map(dicom => dicom.sopInstanceUid);

  return dicomSopUIDs;
};

// FinalisedReport
export const eventFinaliseSession = () => {
  event(state => {
    const app = toApp(state);
    const analysis = app.andThen(app => app.session.analysis);

    const studyType = analysis.mapOr(a => studyTypeToString(a.studyType), '');

    const dicoms = analysis.lift()?.dicoms || [];

    const otherImageCount = filterDicomsBySpecificGalleryFilter(
      dicoms,
      stringToStudyType(studyType) == StudyType.SmallPartsThyroid
        ? ThyroidGalleryFilter.Other
        : BreastGalleryFilter.Other
    ).length;

    const comparisonSession = analysis.map(a => a.comparisonSession).lift();

    const findingCount = analysis.mapOr(
      a => a.data.findings.filter(f => f.included),
      []
    ).length;

    const findingInfo = {
      findingCount,
      ...(comparisonSession && {
        comparisonCount: comparisonSession.data.findings.length,
      }),
    };

    return just({
      type: 'finalised_report',
      ...getStudyInfo(app),
      imageCountTotal: dicoms.length,
      imageCountOther: otherImageCount,
      findingInfo,
    });
  });
};

// WorklistAction
export const eventWorklistActionPerformedSearch = () =>
  worklistAction('performed_search');
export const eventWorklistSwitchedViewToCompleted = () =>
  worklistAction('switched_view_to_completed');
export const eventWorklistSwitchedViewToBookmarked = () =>
  worklistAction('switched_view_to_bookmarked');
export const eventWorklistSwitchedViewToActive = () =>
  worklistAction('switched_view_to_active');
export const eventWorklistCheckedPacsStatus = () =>
  worklistAction('checked_pacs_status');

export const worklistAction = (action: Contract.WorklistAction['action']) => {
  event({
    type: 'worklist_action',
    action,
  });
};

// StudyAction
export const eventStudyActionOpenedStudy = () => studyAction('opened_study');
export const eventStudyActionOpenedPreview = () =>
  studyAction('opened_preview');
export const eventStudyActionDownloadedReport = () =>
  studyAction('downloaded_report');
export const eventStudyActionReimportedStudy = () =>
  studyAction('reimported_study');
export const eventStudyActionResetStudy = () => studyAction('reset_study');
export const eventStudyActionCompareStudy = () => studyAction('compare_study');
export const eventStudyActionAddedCollaborator = () =>
  studyAction('added_collaborator');
export const eventStudyActionRemovedCollaborator = () =>
  studyAction('removed_collaborator');
export const eventStudyActionHidGallery = () => studyAction('hid_gallery');
export const eventStudyActionShowedGallery = () =>
  studyAction('showed_gallery');
export const eventStudyActionFilteredResults = () =>
  studyAction('filtered_results');
export const eventStudyActionIncreasedGalleryResolution = () =>
  studyAction('increased_gallery_resolution');
export const eventStudyActionDecreasedGalleryResolution = () =>
  studyAction('decreased_gallery_resolution');
export const eventStudyActionEnlargedImage = () =>
  studyAction('enlarged_image');
export const eventStudyActionHidDetections = () =>
  studyAction('hid_detections');
export const eventStudyActionShowedDetections = () =>
  studyAction('showed_detections');

export const studyAction = (action: Contract.StudyAction['action']) => {
  event(state => {
    const app = toApp(state);

    return just({
      type: 'study_action',
      action,
      ...getStudyInfo(app),
    });
  });
};

// CanvasAction
export const eventCanvasActionMovedObject = (objectType: CanvasObjectType) =>
  canvasAction('moved_object', { objectType });

export const eventCanvasActionResizedObject = (objectType: CanvasObjectType) =>
  canvasAction('resized_object', { objectType });

export const eventCanvasActionAddedObject = (objectType: CanvasObjectType) =>
  canvasAction('added_object', { objectType });

export const eventCanvasActionDeletedObject = (objectType: CanvasObjectType) =>
  canvasAction('deleted_object', { objectType });

export const eventCanvasActionAddedText = () =>
  canvasAction('added_text', {
    objectType: 'canvas_custom_text',
  });
export const eventCanvasActionAddedFreehand = () =>
  canvasAction('added_freehand', {
    objectType: 'free_draw_path',
  });
export const eventCanvasActionErasedFreehand = () =>
  canvasAction('erased_freehand', {
    objectType: 'free_draw_path',
  });

export const canvasAction = (
  action: Contract.CanvasAction['action'],
  args: Partial<CanvasAction> = {}
) => {
  event(state => {
    const app = toApp(state);

    return just({
      type: 'canvas_action',
      action,
      ...getStudyInfo(app),
      objectType: '',
      ...args,
    });
  });
};

// FindingAction

interface FindingModifiedCharacteristic {
  key: string;
  newValue: string | string[] | undefined;
  oldValue: string | string[] | undefined;
}

export const eventFindingActionSelectedFinding = (
  isAutogeneratedFinding: boolean
) => findingAction('selected_finding', { isAutogeneratedFinding });
export const eventFindingActionCreatedFinding = () =>
  findingAction('created_finding', { isAutogeneratedFinding: true });
export const eventFindingActionDeletedFinding = (
  isAutogeneratedFinding: boolean
) => findingAction('deleted_finding', { isAutogeneratedFinding });
export const eventFindingActionReorderedFinding = () =>
  findingAction('reordered_finding');
export const eventFindingActionAssignedDetection = (
  isAutogeneratedFinding: boolean,
  sopInstanceUID: string
) =>
  findingAction('assigned_detection', {
    isAutogeneratedFinding,
    sopInstanceUIDs: [sopInstanceUID],
  });
export const eventFindingActionUnassignedDetection = (
  isAutogeneratedFinding: boolean,
  sopInstanceUID: string
) =>
  findingAction('unassigned_detection', {
    isAutogeneratedFinding,
    sopInstanceUIDs: [sopInstanceUID],
  });
export const eventFindingActionReassignedDetection = (
  isAutogeneratedFinding: boolean,
  sopInstanceUID: string
) =>
  findingAction('reassigned_detection', {
    isAutogeneratedFinding,
    sopInstanceUIDs: [sopInstanceUID],
  });
export const eventFindingActionModifiedCharacteristic = (
  isAutogeneratedFinding: boolean,
  updatedValue: FindingModifiedCharacteristic,
  findingId: string
) =>
  findingAction(
    'modified_characteristic',
    { isAutogeneratedFinding },
    updatedValue,
    findingId
  );
export const eventFindingActionModifiedSize = (
  isAutogeneratedFinding: boolean
) => findingAction('modified_size', { isAutogeneratedFinding });
export const eventFindingActionModifiedLocation = (
  isAutogeneratedFinding: boolean
) => findingAction('modified_location', { isAutogeneratedFinding });

export const findingAction = (
  action: Contract.FindingAction['action'],
  args: Partial<Contract.FindingAction> = {},
  updatedValue?: FindingModifiedCharacteristic,
  findingId?: string
) => {
  event(state => {
    const app = toApp(state);

    let newValue = '';
    let oldValue = '';
    if (updatedValue) {
      if (Array.isArray(updatedValue.newValue)) {
        newValue = updatedValue.newValue.join();
      } else {
        newValue = updatedValue.newValue ?? '';
      }

      if (Array.isArray(updatedValue.oldValue)) {
        oldValue = updatedValue.oldValue.join();
      } else {
        oldValue = updatedValue.oldValue ?? '';
      }
    }

    let sopInstanceUIDs: string[] = [];
    if (findingId) {
      sopInstanceUIDs = getFindingDicomSopUIDs(app, findingId);
    }

    return just({
      type: 'finding_action',
      action,
      ...getStudyInfo(app),
      ...(updatedValue && {
        characteristic: {
          feature: updatedValue.key,
          newValue,
          oldValue,
        },
      }),
      ...args,
      ...(findingId && {
        sopInstanceUIDs,
      }),
    });
  });
};

// ComparisonAction
export const eventComparisonActionMatchedFinding = () =>
  comparisonAction('matched_finding');
export const eventComparisonActionExpandedFindingMatch = () =>
  comparisonAction('expanded_finding_match');
export const eventComparisonActionCollapsedFindingMatch = () =>
  comparisonAction('collapsed_finding_match');
export const eventComparisonActionShowedImages = () =>
  comparisonAction('showed_images');

export const comparisonAction = (
  action: Contract.ComparisonAction['action']
) => {
  event(state => {
    const app = toApp(state);

    const previousFindingCount = app
      .andThen(app => app.session.analysis)
      .mapOr(a => {
        return a.comparisonSession ? a.comparisonSession.data.findings : [];
      }, []).length;

    const findings = app.lift()?.session.data.findings || [];
    const currentFindingCount = findings.filter(f => f.included).length;

    return just({
      type: 'comparison_action',
      action,
      ...getStudyInfo(app),
      previousFindingCount,
      currentFindingCount,
    });
  });
};

export const getPropertyValue = (finding: Finding, property: string) => {
  return finding.characteristics[property as never];
};

export const checkIfLocationHasChanged = (
  updatedFinding: Finding,
  originalFinding: Finding,
  locationProperties: string[]
) => {
  return locationProperties.some(property => {
    const newValue = getPropertyValue(updatedFinding, property);
    const oldValue = getPropertyValue(originalFinding, property);

    if (property == 'clockface') {
      if (newValue && oldValue) {
        return (
          (newValue as Clockface).hour !== (oldValue as Clockface).hour ||
          (newValue as Clockface).minute !== (oldValue as Clockface).minute
        );
      }
      // if both values are null, return false else return true since one of the size value are set
      return !(!newValue && !oldValue);
    } else if (property == 'distanceFromNipple') {
      if (newValue && oldValue) {
        return (newValue as Size).value !== (oldValue as Size).value;
      }
      // if both size values are null, return false else return true since one of the size value are set
      return !(!newValue && !oldValue);
    }

    return newValue !== oldValue;
  });
};

export const checkIfSizeHasChanged = (
  updatedFinding: Finding,
  originalFinding: Finding
) => {
  return sizeProperties.some(property => {
    const newSize = updatedFinding.sizes[property as never] as Size;
    const oldSize = originalFinding.sizes[property as never] as Size;

    if (newSize && oldSize) {
      return newSize.value !== oldSize.value;
    }

    // if both size values are null, return false else return true since one of the size value are set
    return !(!newSize && !oldSize);
  });
};

export const getFindingProperties = (findingType: FindingType) => {
  if (findingType === FindingType.Breast) {
    return {
      locationProperties: breastLocationProperties,
      characteristicsProperties: breastCharacteristicsProperties,
    };
  }

  return {
    locationProperties: thyroidLocationProperties,
    characteristicsProperties: thyroidCharacteristicsProperties,
  };
};

export const findingAnalyticsEvent = (
  updatedFinding: Finding,
  originalFinding: Finding
) => {
  const { locationProperties, characteristicsProperties } =
    getFindingProperties(updatedFinding.type);

  if (checkIfSizeHasChanged(updatedFinding, originalFinding)) {
    // modified size
    eventFindingActionModifiedSize(updatedFinding.autoGenerated);
  }

  if (
    checkIfLocationHasChanged(
      updatedFinding,
      originalFinding,
      locationProperties
    )
  ) {
    // modified location
    eventFindingActionModifiedLocation(updatedFinding.autoGenerated);
  }

  characteristicsProperties.forEach(property => {
    const updatedProperty = getPropertyValue(updatedFinding, property);
    const originalProperty = getPropertyValue(originalFinding, property);

    if (String(updatedProperty) !== String(originalProperty)) {
      eventFindingActionModifiedCharacteristic(
        updatedFinding.autoGenerated,
        {
          key: property,
          newValue: updatedProperty,
          oldValue: originalProperty,
        },
        updatedFinding.id
      );
    }
  });
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { cnames } from '@helpers/cnames';
import { t } from '@webInterfaces/I18n';
import styles from './radiobutton.scss';

export interface RadioButtonProps {
  id: string;
  key?: string;
  groupName: string;
  value?: string | number;
  children?: React.ReactNode;
  captionI18NKey?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isAffected?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  inLine?: boolean;
}

export const RadioButton = (props: RadioButtonProps) => {
  const {
    id,
    groupName,
    value,
    children,
    checked,
    onChange,
    onClick,
    disabled,
    isAffected,
    readOnly,
    inLine = false,
    captionI18NKey,
  } = props;

  const classNames = cnames(styles.radioOuter, {
    [styles.disabled]: disabled || isAffected,
    [styles.readOnly]: readOnly,
  });

  return (
    <Box
      display={inLine ? 'inline-flex' : 'flex'}
      flexDirection="row"
      alignItems="center"
      element="label"
      className={classNames}
      paddingY={inLine ? 'none' : 'xxxs'}
    >
      <input
        type="radio"
        id={id}
        name={groupName}
        value={value}
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        className={styles.radioInput}
        disabled={disabled || isAffected}
      />
      <Box
        data-testid="fakeRadioInput"
        className={styles.fakeRadioInput}
        marginRight="xs"
      >
        <Box className={styles.fakeRadioInner} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Text type="body2" loading={isAffected}>
          {children}
        </Text>
        {captionI18NKey && (
          <Text muted type="caption">
            {t(captionI18NKey)}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export interface RadioGroupProps {
  entries: RadioButtonProps[];
  containerStyles?: React.CSSProperties;
}

export const RadioGroup = (props: RadioGroupProps) => {
  const { entries, containerStyles } = props;

  return (
    <>
      {entries.map(entry => (
        <Box key={entry.key} marginRight="xs" style={containerStyles}>
          <RadioButton {...entry} captionI18NKey={entry.captionI18NKey} />
        </Box>
      ))}
    </>
  );
};

import * as Contract from '@contracts/Search';
import { FilterOptions } from '@interfaces/FilterOptions';
import { Storage as ContractStorage } from '@contracts/Storage';
import { post } from '@api/schema/client';
import { decodeWorklistLineItem } from './decoders/WorklistLineItem';

export class Search implements Contract.Search {
  async Perform(
    storage: ContractStorage,
    query: string,
    filterOptions: FilterOptions
  ): Promise<Contract.FilteredSearchLineItems> {
    const data = await post('/list-sessions', {
      body: {
        filters: {
          accession_id: query,
        },
        limit: filterOptions.limit,
        offset: filterOptions.offset,
      },
    });

    const searchResultItems = await Promise.all(
      data.sessions.map(item => {
        return decodeWorklistLineItem(storage, item);
      })
    );

    const totalItems = data.total_items ?? searchResultItems.length;
    return { searchResultItems, totalItems, filterOptions };
  }
}

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { cnames } from '@helpers/cnames';
import styles from './expandButton.scss';

export interface ExpandButtonProps {
  children: React.ReactNode;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  type: 'horizontal' | 'vertical';
  onClick: () => void;
  color?: string;
  isPair?: boolean;
  isOpen?: boolean;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({
  children,
  iconBefore = null,
  iconAfter = null,
  type,
  onClick,
  color,
  isPair,
  isOpen,
}) => {
  const classNames = cnames(
    styles.outerContainer,
    styles[type],
    { [styles.hasIconBefore]: iconBefore ? true : false },
    { [styles.hasIconAfter]: iconAfter ? true : false },
    { [styles.horizontal]: type === 'horizontal' },
    { [styles.vertical]: type === 'vertical' },
    { [styles.pair]: isPair },
    { [styles.expanded]: isOpen }
  );

  return (
    <Box
      style={{ backgroundColor: color }}
      onClick={onClick}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      className={classNames}
    >
      {iconBefore && (
        <Box display="flex" alignItems="center" className={styles.iconBefore}>
          {iconBefore}
        </Box>
      )}
      {children && (
        <Box alignItems="center" margin="xxxs">
          {children}
        </Box>
      )}
      {iconAfter && (
        <Box display="flex" alignItems="center" className={styles.iconAfter}>
          {iconAfter}
        </Box>
      )}
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Stack } from '@webMolecules/Stack/Stack';
import { LineItemProps } from '@webMolecules/LineItem/LineItem';
import { TextMuted } from '@webMolecules/LineItem/Cell/Text';
import ScheduleGridTemplateColumns from '@webMolecules/Schedule/ScheduleGridTemplateColumns';
import styles from './styles.scss';

export interface ScheduleProps {
  columns: {
    label: string;
    width?: string;
  }[];
  hasContextMenu?: boolean;
  items: React.ReactElement<LineItemProps>[];
  onInfiniteScroll?: () => void;
}

export const Schedule: React.FC<ScheduleProps> = ({
  columns,
  hasContextMenu,
  items,
  onInfiniteScroll,
}) => {
  const gridTemplateColumns = columns.map(a => a.width || '1fr').join(' ');

  const boxRef = React.useRef<HTMLDivElement>(null);
  const handleScroll = React.useCallback(() => {
    if (onInfiniteScroll && boxRef.current) {
      const boxBottom = boxRef.current.getBoundingClientRect().bottom;
      if (boxBottom < window.innerHeight) {
        onInfiniteScroll();
      }
    }
  }, [onInfiniteScroll]);

  React.useEffect(() => {
    if (items.length > 0) {
      handleScroll();
    }
    if (onInfiniteScroll) {
      document.addEventListener('scroll', handleScroll, true);
      window.addEventListener('resize', handleScroll);
    }
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('resize', handleScroll);
    };
  }, [items, onInfiniteScroll]);

  return (
    <ScheduleGridTemplateColumns.Provider value={gridTemplateColumns}>
      <Box ref={boxRef}>
        <Box display="flex">
          <Box
            className={styles.grid}
            style={{ gridTemplateColumns }}
            marginBottom="s"
            paddingX="s"
            flex="1"
          >
            {columns.map(column => (
              <TextMuted key={column.label}>{column.label}</TextMuted>
            ))}
          </Box>
          {hasContextMenu && (
            <Box className={styles.actions} paddingRight="xs" />
          )}
        </Box>

        <Stack spacing="xs">{items}</Stack>
      </Box>
    </ScheduleGridTemplateColumns.Provider>
  );
};

export default Schedule;

import { toAuth, toI18n, toApp } from '@interfaces/Root';
import { isAuthenticatingFromSession } from '@interfaces/Authentication';
import { State } from '@webInterfaces/StoreTypes';
import { Routes } from '@webViewInterfaces/Routes';
import { getRoutes } from './Routes';

export type App = AppProps;

export interface AppProps {
  ready: boolean;
  routes: Routes;
  authenticatedEmail?: string;
  featureFlagsInitialised: boolean;
}

export const fromState = (state: State): AppProps => {
  const app = toApp(state);

  const auth = toAuth(state);
  const translationsReady = toI18n(state).translations.size > 0;
  const ready =
    translationsReady && isAuthenticatingFromSession(auth) === false;

  const profile = app.map(a => a.profile).lift();
  const authenticatedEmail = profile?.email;
  const routes = getRoutes(profile);

  const featureFlagsInitialised = state.app.mapOr(
    a => a.appSettings.featureFlagsInitialised,
    false
  );

  return {
    ready,
    routes,
    authenticatedEmail,
    featureFlagsInitialised,
  };
};

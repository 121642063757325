import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { Tooltip } from '@webMolecules/Tooltip/Tooltip';
import { t } from '@webInterfaces/I18n';
import { GridType } from '@webViewModels/Pages/Study/GalleryHelper';
import styles from './gallery.scss';

export interface ThumbnailIcon {
  isFullscreen: boolean;
  handleSetView: (type: GridType, index: number) => void;
  view: string;
  index: number;
}

export const ThumbnailIcon = ({
  isFullscreen,
  handleSetView,
  view,
  index,
}: ThumbnailIcon) => {
  return (
    <Box
      className={styles.imageActions}
      padding="xs"
      display="flex"
      flexDirection="column"
      gap="xxs"
    >
      <Tooltip
        label={
          isFullscreen
            ? t('pages.study.gallery.thumbnail.controls.minimise')
            : t('pages.study.gallery.thumbnail.controls.maximise')
        }
      >
        <Button
          circle
          intent="neutral"
          onClick={() =>
            handleSetView(
              view === GridType.single ? GridType.grid : GridType.single,
              index
            )
          }
          strong={isFullscreen}
          iconBefore={
            isFullscreen ? <Icon name="x" /> : <Icon name="maximise" />
          }
          tabIndex={-1}
        />
      </Tooltip>
    </Box>
  );
};

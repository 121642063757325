import { Analysis } from '@interfaces/Analysis';
import * as Contract from '@contracts/Analyses';
import { DicomFile } from '@contracts/DicomAnonymiser';
import { Storage as ContractStorage } from '@contracts/Storage';
import { StudyBatchStatusItem } from '@interfaces/StudyBatchStatusItem';
import { post, postOnly } from '@api/schema/client';
import { decode as decodeAnalysis } from './decoders/Analysis';
import { decodeStudyBatchStatus } from './decoders/StudyBatchStatus';
import { StudyBatchSourceEnum, StudyBatchStatusEnum } from './schema/generated';

const getUploadPathFromParameters = (
  institutionId: string,
  studyId: string,
  batchId: string
) => {
  return `${institutionId}/studies/${studyId}/batches/${batchId}/dicom-files`;
};

export class Analyses implements Contract.Analyses {
  async UploadDicom(
    storage: ContractStorage,
    localStudyId: string,
    localBatchId: string,
    institutionId: string,
    dicomFile: DicomFile
  ): Promise<'success'> {
    const locationPrefix = getUploadPathFromParameters(
      institutionId,
      localStudyId,
      localBatchId
    );

    //Note: Metadata must be string values.
    await storage.Store({
      location: `${locationPrefix}/${dicomFile.sopInstanceUid}_metadata.json`,
      contentType: 'application/octet-stream',
      body: dicomFile.metadata,
    });

    await storage.Store({
      location: `${locationPrefix}/${dicomFile.sopInstanceUid}.png`,
      contentType: 'application/octet-stream',
      body: dicomFile.anonymisedImageBlob,
    });

    return 'success';
  }

  async NotifyUploadCompleted(data: Contract.UploadData): Promise<'success'> {
    // All of our files have been uploaded to S3
    // upload a signal to initiate processing of a study

    await postOnly('/ingest-study', {
      body: {
        batch_status: StudyBatchStatusEnum.RETRIEVED,
        upload_source: StudyBatchSourceEnum.UI,
        study_instance_uid: data.studyInstanceId,
        batch_uid: data.batchUid,
        accession_id: data.accessionId,
        operator_code: data.operatorCode,
        pacs_scan_date: data.pacsScanDate,
        pacs_scan_time: data.pacsScanTime,
        phi_b64_cipher: data.encryptedPhi || '',
        study_description: data.studyDescription,
        station_name: data.stationName,
        institution_name: data.institutionName || '',
        sm_patient_id: data.smPatientId,
        received_file_uids: data.recievedFileUids,
        discarded_file_uids: data.discardedFileUids,
        failed_file_uids: data.failedFileUids,
        user_list: data.userProfileId,
      },
    });

    return 'success';
  }

  async GetStudyBatchStatus(
    studyBatchId: string
  ): Promise<StudyBatchStatusItem> {
    const data = await post('/polling/batch-status', {
      body: {
        batch_uid: studyBatchId,
      },
    });

    return decodeStudyBatchStatus(studyBatchId, data);
  }

  async Load(
    storage: ContractStorage,
    analysisId: string,
    sessionId: string
  ): Promise<Analysis> {
    const data = await post('/get-session-data', {
      body: {
        session_id: sessionId,
      },
    });

    const analysis = await decodeAnalysis(storage, data);
    return analysis;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Settings/Admin/Workspaces';
import { FilterConfiguration as StoryWorkspaces } from '@webPages/Settings/Admin/Workspaces';
import StoryWorkspaceDetails from '@webPages/Settings/Admin/WorkspaceDetails';
import * as StoryLayout from '@webPages/Settings/Layout';

const Workspaces: React.FC<ViewModel.Workspaces> = ({
  messages,
  profile,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  loading,
  searchQuery,
  pacsStudyLineItems,
  pacsSystemStatus,
  filterConfigurationLineItems,
  targetFilterConfiguration,
  featureFlags,
  dispatchOnSignOut,
  dispatchCreateFilterConfiguration,
  dispatchUpdateFilterConfiguration,
  dispatchDestroyFilterConfiguration,
  dispatchUnloadTargetFilterConfiguration,
}) => {
  return (
    <StoryLayout.Layout
      profile={profile}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      onSignOut={dispatchOnSignOut}
      searchQuery={searchQuery}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
    >
      {targetFilterConfiguration && (
        <StoryWorkspaceDetails
          filterConfiguration={targetFilterConfiguration}
          onClose={dispatchUnloadTargetFilterConfiguration}
          onCreateFilterConfiguration={dispatchCreateFilterConfiguration}
          onUpdateFilterConfiguration={dispatchUpdateFilterConfiguration}
          featureFlags={featureFlags}
        />
      )}
      <StoryWorkspaces
        loading={loading}
        messages={messages}
        filterConfigurationLineItems={filterConfigurationLineItems}
        onDestroyFilterConfiguration={dispatchDestroyFilterConfiguration}
      />
    </StoryLayout.Layout>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(Workspaces);

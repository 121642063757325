import { Dispatch } from 'redux';
import { toApp } from '@interfaces/Root';
import { toSession } from '@interfaces/App';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { SessionMetadata } from '@interfaces/SessionLineItem';
import { updateSessionMetadata } from '@webViewInterfaces/Study/Session';
import { ComparisonStatus, fetchComparisonStatus } from './Shared';

export type SmallPartsSummaryTab = SmallPartsSummaryTabProps &
  SmallPartsSummaryTabDispatchers;

export interface SmallPartsSummaryTabProps {
  metadata: SessionMetadata;
  isComparisonAvailable: boolean;
  sessionDate: Date | null;
  comparisonSessionDate: Date | null;
}

export interface SmallPartsSummaryTabDispatchers {
  dispatchUpdateMetadata: (metadata: SessionMetadata) => Dispatch<Actions>;
}

export const dispatchers = (dispatch: any) => ({
  dispatchUpdateMetadata: (metadata: SessionMetadata) =>
    dispatch(updateSessionMetadata(metadata)),
});

export const fromState = (state: State): SmallPartsSummaryTabProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const session = toSession(app);

  const { details, comparisonSession } = session;

  const comparisonStatus = fetchComparisonStatus(session);
  const isComparisonAvailable = comparisonStatus === ComparisonStatus.Compared;

  const sessionDate = details.mapOr(a => a.date, null);

  return {
    isComparisonAvailable,
    metadata: details.mapOr(a => a.metadata, {}),
    sessionDate,
    comparisonSessionDate: comparisonSession.mapOr(a => a.date, null),
  };
};

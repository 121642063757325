import React from 'react';
import {
  makePistachioCanvasModeSelect,
  makePistachioCanvasModeText,
  makePistachioCanvasModeDraw,
  PistachioCanvasMode,
  makePistachioCanvasModeSymbol,
} from '@appCanvas/interfaces/PistachioCanvasMode.type';
import { BrushSize } from '@appCanvas/config/constants';
import { SymbolName } from '@webMolecules/Symbol/Symbol';
import {
  DrawingColorKey,
  SymbolColorKey,
} from '@webOrganisms/DrawingToolOptions/DrawingColors';
import { Annotations } from '@entities/Dicom';

export enum ToolbarMode {
  Select = 'toolbar_mode--select',
  Text = 'toolbar_mode--text',
  Draw = 'toolbar_mode--draw',
  Erase = 'toolbar_mode--erase',
  Symbol = 'toolbar_mode--symbol',
}

export interface DrawingConfig {
  brushColor: DrawingColorKey;
  brushSize: BrushSize;
  eraseSize: BrushSize;
  symbolColor: SymbolColorKey;
  symbolName: SymbolName;
}

export interface CanvasContextMenu {
  show: boolean;
  title: string;
  annotations: Annotations[];
}

export interface DiagramContext {
  toolbarMode: ToolbarMode;
  selectedCanvasObject: null | string;
  drawingConfig: DrawingConfig;
  menu: CanvasContextMenu;
  latestCreatedObjectId: null | string;
  canvasRegions: Annotations[];
  setCanvasRegions(regions: Annotations[]): void;
  setMenu(menu: CanvasContextMenu): void;
  setLatestCreatedObjectId: React.Dispatch<React.SetStateAction<null | string>>;
  setSelectedCanvasObject: React.Dispatch<React.SetStateAction<null | string>>;
  setToolbarMode(mode: ToolbarMode): void;
  setDrawingConfig(config: DrawingConfig): void;
  getPistachioCanvasMode: () => PistachioCanvasMode;
  doToolbarAction: (action: ToolbarAction) => void;
}

export const DiagramContext = React.createContext<DiagramContext>({
  toolbarMode: ToolbarMode.Select,
  selectedCanvasObject: null,
  latestCreatedObjectId: null,
  drawingConfig: {} as DrawingConfig,
  menu: {} as CanvasContextMenu,
  canvasRegions: [],
  setCanvasRegions: () => null,
  setMenu: () => null,
  setLatestCreatedObjectId: () => null,
  setSelectedCanvasObject: () => null,
  setToolbarMode: () => null,
  setDrawingConfig: () => null,
  getPistachioCanvasMode: () => makePistachioCanvasModeSelect(),
  doToolbarAction: () => null,
});

export const TOOLBAR_ACTION_DELETE_CANVAS_OBJECT =
  'TOOLBAR_ACTION_DELETE_CANVAS_OBJECT';

export interface ToolbarActionDeleteCanvasObject {
  type: typeof TOOLBAR_ACTION_DELETE_CANVAS_OBJECT;
  id: string;
}

export const makeToolbarActionDeleteCanvasObject = (
  id: string
): ToolbarActionDeleteCanvasObject => ({
  type: TOOLBAR_ACTION_DELETE_CANVAS_OBJECT,
  id,
});

export type ToolbarAction = ToolbarActionDeleteCanvasObject;

export interface DiagramContextProviderProps {
  onToolbarAction: (action: ToolbarAction) => void;
}

export const DiagramContextProvider: React.FC<DiagramContextProviderProps> = ({
  children,
  onToolbarAction,
}) => {
  const [toolbarMode, setToolbarMode] = React.useState<ToolbarMode>(
    ToolbarMode.Select
  );

  const [menu, setMenu] = React.useState<CanvasContextMenu>({
    show: false,
    title: '',
    annotations: [],
  });

  const [selectedCanvasObject, setSelectedCanvasObject] = React.useState<
    null | string
  >(null);

  const [latestCreatedObjectId, setLatestCreatedObjectId] = React.useState<
    null | string
  >(null);

  const [canvasRegions, setCanvasRegions] = React.useState<Annotations[]>([]);

  const [drawingConfig, setDrawingConfig] = React.useState<DrawingConfig>({
    brushColor: DrawingColorKey.Light,
    brushSize: BrushSize.M,
    eraseSize: BrushSize.M,
    symbolColor: SymbolColorKey.Red,
    symbolName: SymbolName.Competent,
  });

  const getPistachioCanvasMode = () => {
    switch (toolbarMode) {
      default:
      case ToolbarMode.Select:
        return makePistachioCanvasModeSelect();
      case ToolbarMode.Text:
        return makePistachioCanvasModeText();
      case ToolbarMode.Draw:
        return makePistachioCanvasModeDraw(
          'draw',
          drawingConfig.brushSize,
          drawingConfig.brushColor
        );
      case ToolbarMode.Erase:
        return makePistachioCanvasModeDraw('erase', drawingConfig.eraseSize);
      case ToolbarMode.Symbol:
        return makePistachioCanvasModeSymbol(
          drawingConfig.symbolColor,
          drawingConfig.symbolName
        );
    }
  };

  const doToolbarAction = (action: ToolbarAction) => {
    onToolbarAction(action);
  };

  return (
    <DiagramContext.Provider
      value={{
        toolbarMode,
        selectedCanvasObject,
        drawingConfig,
        menu,
        latestCreatedObjectId,
        canvasRegions,
        setCanvasRegions,
        setLatestCreatedObjectId,
        setSelectedCanvasObject,
        setToolbarMode,
        setDrawingConfig,
        getPistachioCanvasMode,
        doToolbarAction,
        setMenu,
      }}
    >
      {children}
    </DiagramContext.Provider>
  );
};

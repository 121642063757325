import React from 'react';
import { useController } from 'react-hook-form';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { ResultTableSelectConfig } from '@config/ResultTable/ResultTable';
import { Text } from '@webMolecules/Text/Text';
import { t } from '@webInterfaces/I18n';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { getDisplayedOptionLabel } from '../FindingsTable/Shared';

interface ResultTableCellSelectProps extends ResultTableSharedProps {
  config: ResultTableSelectConfig;
  disabled?: boolean;
  confidenceThreshold?: number;
}

export const ResultTableCellSelect: React.FC<ResultTableCellSelectProps> = ({
  path,
  config,
  disabled,
}) => {
  const { theme } = React.useContext(ResultTableContext);
  const { field } = useController({
    name: path ?? '',
  });
  const fieldValue = disabled ? '' : field.value;
  const { options } = config;

  const onChange = (value: string) => {
    field.onChange(value);
  };

  if (theme == ResultTableStyle.preview) {
    if (fieldValue) {
      const option = options.find(option => option.value === fieldValue);
      return <Text type="body2">{t(option?.previewI18NKey || '')}</Text>;
    } else {
      return null;
    }
  }
  if (!options) {
    return null;
  }
  const arrayOption = Array.from(options);
  const selectOptions = arrayOption?.map(option => {
    return {
      value: option.value,
      label: getDisplayedOptionLabel(
        option.value,
        t(option.editableI18NKey),
        config.scoreConfig
      ),
    };
  });

  return (
    <MenuSelect
      data-testid="select-item"
      fluid
      // @TODO Select needs a loading state
      // loading={isAffected}
      disabled={disabled}
      value={fieldValue}
      onChange={onChange}
      options={selectOptions}
      placeholder={'-'}
      size="small"
    />
  );
};

import Maybe from '@monads/Maybe';
import { useAuthenticationMode } from '@interfaces/Authentication';

export const getReportURL = (generatedPDF: Maybe<string>) => {
  const { isAdminDomain } = useAuthenticationMode();

  const pdfUrl = generatedPDF ? generatedPDF.lift() : undefined;

  if (pdfUrl && isAdminDomain) {
    return pdfUrl.split('/').slice(0, -1).join('/') + '/report-redacted.pdf';
  }

  return pdfUrl;
};

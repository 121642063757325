import React from 'react';
import { t, tPrint } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { titleCase } from '@helpers/string';

interface DialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  triggerBtnText: string;
}

export const MissingSizeDialog: React.FC<DialogProps> = props => {
  const { triggerBtnText } = props;
  return (
    <DialogConfirm
      {...props}
      intent="neutral"
      labelOk={t('pages.generic.modal.size.warning.button')}
    >
      <Box display="flex" alignItems="center" marginBottom="m">
        <Icon name="alert-triangle" color="var(--ds-color-warning-500)" />
        <Text type="display2" display="block" marginLeft="s">
          {t('pages.generic.modal.size.warning.title')}
        </Text>
      </Box>

      <Text>
        {t('pages.generic.modal.size.warning.body1')}
        <br />
        <br />
        {tPrint(
          'pages.generic.modal.size.warning.body2',
          titleCase(triggerBtnText)
        )}
      </Text>
    </DialogConfirm>
  );
};

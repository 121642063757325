import React, { useState } from 'react';
import { Prompt, Redirect } from 'react-router-dom';

interface RouteLeavingGuardProps {
  when: boolean;
  renderDialog: (props: {
    open: boolean;
    confirm: () => void;
    cancel: () => void;
  }) => React.ReactNode;
}

export const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  when,
  renderDialog,
}) => {
  const [nextLocation, setNextLocation] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);

  const confirm = () => {
    setConfirmedNavigation(true);
    setOpen(false);
  };

  const cancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Prompt
        when={when}
        message={location => {
          if (!confirmedNavigation) {
            setNextLocation(location.pathname);
            setOpen(true);
            return false;
          }
          return true;
        }}
      />
      {renderDialog({ open, confirm, cancel })}
      {confirmedNavigation && nextLocation && <Redirect to={nextLocation} />}
    </>
  );
};

import { BreastFinding } from '@entities/Finding';
import { AnyUnit, VolumeUnit } from '@entities/Unit';
import { BiradsScore } from '@entities/Grading';
import {
  BreastFindingArchitecture,
  BreastFindingCalcification,
  BreastFindingEchoPattern,
  BreastFindingElasticity,
  BreastFindingMargin,
  BreastFindingOrientation,
  BreastFindingPosition,
  BreastFindingPosteriorFeature,
  BreastFindingShape,
  BreastFindingSkinChanges,
  BreastFindingSpecialCase,
  BreastFindingType,
  BreastFindingVascularity,
} from '@entities/Characteristics';
import {
  ResultTableRadioConfig,
  ResultTableSelectOptions,
  is1DFindingSizeBreast,
  is3DFindingSizeBreast,
  isBiradsScore,
  isNotSimplifiedBreastReportingWorkflow,
  isSimplifiedBreastReportingWorkflow,
} from './ResultTable';
import { FindingConfig } from './Shared';

const bodySideConfig: ResultTableSelectOptions = [
  {
    value: 'right',
    editableI18NKey: 'config_key.result_table.breast_finding.options.right',
    previewI18NKey: 'config_key.result_table.breast_finding.options.right',
  },
  {
    value: 'left',
    editableI18NKey: 'config_key.result_table.breast_finding.options.left',
    previewI18NKey: 'config_key.result_table.breast_finding.options.left',
  },
];

const positionConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingPosition.Breast,
    editableI18NKey: 'config_key.result_table.breast_finding.options.breast',
    previewI18NKey: 'config_key.result_table.breast_finding.options.breast',
  },
  {
    value: BreastFindingPosition.Nipple,
    editableI18NKey: 'config_key.result_table.breast_finding.options.nipple',
    previewI18NKey: 'config_key.result_table.breast_finding.options.nipple',
  },
  {
    value: BreastFindingPosition.Axilla,
    editableI18NKey: 'config_key.result_table.breast_finding.options.axilla',
    previewI18NKey: 'config_key.result_table.breast_finding.options.axilla',
  },
];

const shapeConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingShape.Oval,
    editableI18NKey: 'config_key.result_table.breast_finding.options.oval',
    previewI18NKey: 'config_key.result_table.breast_finding.options.oval',
  },
  {
    value: BreastFindingShape.Round,
    editableI18NKey: 'config_key.result_table.breast_finding.options.round',
    previewI18NKey: 'config_key.result_table.breast_finding.options.round',
  },
  {
    value: BreastFindingShape.Irregular,
    editableI18NKey: 'config_key.result_table.breast_finding.options.irregular',
    previewI18NKey: 'config_key.result_table.breast_finding.options.irregular',
  },
];

const orientationConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingOrientation.Parallel,
    editableI18NKey: 'config_key.result_table.breast_finding.options.parallel',
    previewI18NKey: 'config_key.result_table.breast_finding.options.parallel',
  },
  {
    value: BreastFindingOrientation.NotParallel,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.not_parallel',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.not_parallel',
  },
];

const vascularityConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingVascularity.VascularityAbsent,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.vascularity_absent',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.vascularity_absent',
  },
  {
    value: BreastFindingVascularity.InternalVascularity,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.internal_vascularity',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.internal_vascularity',
  },
  {
    value: BreastFindingVascularity.VesselsInRim,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.vessels_in_rim',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.vessels_in_rim',
  },
];

const elasticityConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingElasticity.Soft,
    editableI18NKey: 'config_key.result_table.breast_finding.options.soft',
    previewI18NKey: 'config_key.result_table.breast_finding.options.soft',
  },
  {
    value: BreastFindingElasticity.Intermediate,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.intermediate',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.intermediate',
  },
  {
    value: BreastFindingElasticity.Hard,
    editableI18NKey: 'config_key.result_table.breast_finding.options.hard',
    previewI18NKey: 'config_key.result_table.breast_finding.options.hard',
  },
];

const skinChanges: ResultTableSelectOptions = [
  {
    value: BreastFindingSkinChanges.SkinThickening,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.skin_thickening',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.skin_thickening',
  },
  {
    value: BreastFindingSkinChanges.SkinRetraction,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.skin_retraction',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.skin_retraction',
  },
];

const breastFindingArchitectureConfig: ResultTableRadioConfig = {
  columns: 1,
  options: [
    {
      value: BreastFindingArchitecture.ArchitecturalDistortion,
      editableI18NKey:
        'config_key.result_table.breast_finding.options.architectural_distortion',
      previewI18NKey:
        'config_key.result_table.breast_finding.options.architectural_distortion',
    },
    {
      value: BreastFindingArchitecture.DuctChanges,
      editableI18NKey:
        'config_key.result_table.breast_finding.options.duct_changes',
      previewI18NKey:
        'config_key.result_table.breast_finding.options.duct_changes',
    },
    {
      value: BreastFindingArchitecture.Edema,
      editableI18NKey: 'config_key.result_table.breast_finding.options.edema',
      previewI18NKey: 'config_key.result_table.breast_finding.options.edema',
    },
  ],
};

const breastFindingTypeConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingType.Cyst,
    editableI18NKey: 'config_key.result_table.breast_finding.options.cyst',
    previewI18NKey: 'config_key.result_table.breast_finding.options.cyst',
  },
  {
    value: BreastFindingType.Mixed,
    editableI18NKey: 'config_key.result_table.breast_finding.options.mixed',
    previewI18NKey: 'config_key.result_table.breast_finding.options.mixed',
  },
  {
    value: BreastFindingType.Lesion,
    editableI18NKey: 'config_key.result_table.breast_finding.options.lesion',
    previewI18NKey: 'config_key.result_table.breast_finding.options.lesion',
  },
];

const specialCasesConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingSpecialCase.SimpleCyst,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.simple_cyst',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.simple_cyst',
  },
  {
    value: BreastFindingSpecialCase.ClusteredMicrocysts,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.clustered_microcysts',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.clustered_microcysts',
  },
  {
    value: BreastFindingSpecialCase.ComplicatedCyst,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.complicated_cyst',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.complicated_cyst',
  },
  {
    value: BreastFindingSpecialCase.MassInOrOnSkin,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.mass_in_or_on_skin',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.mass_in_or_on_skin',
  },
  {
    value: BreastFindingSpecialCase.ForeignBody,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.foreign_body',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.foreign_body',
  },
  {
    value: BreastFindingSpecialCase.IntramammaryLymphNode,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.intramammary_lymph_node',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.intramammary_lymph_node',
  },
  {
    value: BreastFindingSpecialCase.AxillaryLymphNode,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.axillary_lymph_node',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.axillary_lymph_node',
  },
  {
    value: BreastFindingSpecialCase.VascularAbnormalities,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.vascular_abnormalities',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.vascular_abnormalities',
  },
  {
    value: BreastFindingSpecialCase.PostsurgicalFluid,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.postsurgical_fluid',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.postsurgical_fluid',
  },
  {
    value: BreastFindingSpecialCase.FatNecrosis,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.fat_necrosis',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.fat_necrosis',
  },
];

const marginConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingMargin.Circumscribed,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.circumscribed',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.circumscribed',
  },
  {
    value: BreastFindingMargin.Indistinct,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.indistinct',
    previewI18NKey: 'config_key.result_table.breast_finding.options.indistinct',
  },
  {
    value: BreastFindingMargin.Angular,
    editableI18NKey: 'config_key.result_table.breast_finding.options.angular',
    previewI18NKey: 'config_key.result_table.breast_finding.options.angular',
  },
  {
    value: BreastFindingMargin.Microlobulated,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.microlobulated',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.microlobulated',
  },
  {
    value: BreastFindingMargin.Spiculated,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.spiculated',
    previewI18NKey: 'config_key.result_table.breast_finding.options.spiculated',
  },
];

const echoPatternConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingEchoPattern.Anechoic,
    editableI18NKey: 'config_key.result_table.breast_finding.options.anechoic',
    previewI18NKey: 'config_key.result_table.breast_finding.options.anechoic',
  },
  {
    value: BreastFindingEchoPattern.Hyperechoic,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.hyperechoic',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.hyperechoic',
  },
  {
    value: BreastFindingEchoPattern.ComplexCysticAndSolid,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.complex_cystic_and_solid',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.complex_cystic_and_solid',
  },
  {
    value: BreastFindingEchoPattern.Hypoechoic,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.hypoechoic',
    previewI18NKey: 'config_key.result_table.breast_finding.options.hypoechoic',
  },
  {
    value: BreastFindingEchoPattern.Isoechoic,
    editableI18NKey: 'config_key.result_table.breast_finding.options.isoechoic',
    previewI18NKey: 'config_key.result_table.breast_finding.options.isoechoic',
  },
  {
    value: BreastFindingEchoPattern.Heterogeneous,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.heterogeneous',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.heterogeneous',
  },
];

const posteriorFeaturesConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingPosteriorFeature.NoPosteriorFeatures,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.no_posterior_features',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.no_posterior_features',
  },
  {
    value: BreastFindingPosteriorFeature.Enhancement,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.enhancement',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.enhancement',
  },
  {
    value: BreastFindingPosteriorFeature.Shadowing,
    editableI18NKey: 'config_key.result_table.breast_finding.options.shadowing',
    previewI18NKey: 'config_key.result_table.breast_finding.options.shadowing',
  },
  {
    value: BreastFindingPosteriorFeature.CombinedPattern,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.combined_pattern',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.combined_pattern',
  },
];

const calcificationsConfig: ResultTableSelectOptions = [
  {
    value: BreastFindingCalcification.InMass,
    editableI18NKey: 'config_key.result_table.breast_finding.options.in_mass',
    previewI18NKey: 'config_key.result_table.breast_finding.options.in_mass',
  },
  {
    value: BreastFindingCalcification.OutsideMass,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.outside_mass',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.outside_mass',
  },
  {
    value: BreastFindingCalcification.Intraductal,
    editableI18NKey:
      'config_key.result_table.breast_finding.options.intraductal_calcifications',
    previewI18NKey:
      'config_key.result_table.breast_finding.options.intraductal_calcifications',
  },
];

const biradsScoreConfig: ResultTableSelectOptions = [
  {
    value: BiradsScore.BIRADS_0,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_0',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_0',
  },
  {
    value: BiradsScore.BIRADS_1,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_1',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_1',
  },
  {
    value: BiradsScore.BIRADS_2,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_2',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_2',
  },
  {
    value: BiradsScore.BIRADS_3,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_3',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_3',
  },
  {
    value: BiradsScore.BIRADS_4A,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_4a',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_4a',
  },
  {
    value: BiradsScore.BIRADS_4B,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_4b',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_4b',
  },
  {
    value: BiradsScore.BIRADS_4C,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_4c',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_4c',
  },
  {
    value: BiradsScore.BIRADS_5,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_5',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_5',
  },
  {
    value: BiradsScore.BIRADS_6,
    editableI18NKey: 'config_key.result_table.breast_finding.options.birads_6',
    previewI18NKey: 'config_key.result_table.breast_finding.options.birads_6',
  },
];

export const getBreastFindingDetailedConfig = (
  sizeUnit: AnyUnit
): FindingConfig<BreastFinding> => {
  return {
    sections: [
      {
        index: 0,
        title: '',
        separator: false,
        fields: [
          {
            path: 'included',
            key: 'findingInclude',
            input: 'findingInclude',
            label: 'pages.report.findings.label.include_in_report',
          },
          {
            path: 'characteristics.side',
            input: 'select',
            key: 'bodySide',
            inputConfig: { options: bodySideConfig },
            label: 'config_key.result_table.breast_finding.body_side.label',
          },
          {
            path: 'characteristics.position',
            input: 'select',
            key: 'finding-position',
            inputConfig: { options: positionConfig },
            label: 'config_key.result_table.breast_finding.position.label',
          },
          {
            path: 'characteristics.clockface',
            input: 'clockface',
            key: 'clock-position',
            label: 'config_key.result_table.breast_finding.clock.label',
            isDisabled: finding =>
              finding.characteristics.position !== BreastFindingPosition.Breast,
          },
          {
            path: 'characteristics.distanceFromNipple',
            input: 'float',
            key: 'distance',
            label: 'config_key.result_table.breast_finding.dist.label',
            width: '100px',
            unit: sizeUnit,
            unitPosition: 'inline',
            isDisabled: finding =>
              finding.characteristics.position !== BreastFindingPosition.Breast,
          },
          {
            path: 'sizes.max',
            input: 'float',
            key: 'size',
            label: 'config_key.result_table.finding.column.size',
            width: '100px',
            unit: sizeUnit,
            unitPosition: 'inline',
            featureEnabled: is1DFindingSizeBreast,
            inputConfig: {
              showSizeWarning: true,
            },
          },
          {
            path: 'sizes',
            input: 'dimensions',
            key: 'finding-size',
            label: 'config_key.result_table.finding.column.size',
            width: '100px',
            unit: sizeUnit,
            unitPosition: 'inline',
            featureEnabled: is3DFindingSizeBreast,
          },
          {
            path: 'sizes.volume',
            input: 'float',
            key: 'volume',
            label: 'config_key.result_table.finding.column.volume',
            width: '100px',
            unit: VolumeUnit.ML,
            unitPosition: 'inline',
            featureEnabled: is3DFindingSizeBreast,
            inputConfig: { readOnly: true },
          },
          {
            path: 'characteristics.biradsScore',
            key: 'birads-score',
            input: 'select',
            label: 'config_key.result_table.breast_finding.birads_score.label',
            inputConfig: { options: biradsScoreConfig },
            featureEnabled: isBiradsScore,
          },
          {
            path: 'characteristics.type',
            key: 'finding-type',
            input: 'select',
            inputConfig: { options: breastFindingTypeConfig },
            label: 'config_key.result_table.breast_finding.type.label',
            separator: true,
            featureEnabled: isSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.specialCase',
            key: 'special-cases',
            input: 'select',
            inputConfig: { options: specialCasesConfig },
            label: 'config_key.result_table.breast_finding.special_cases.label',
            note: 'config_key.result_table.breast_finding.special_cases.note',
            showNote: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.shape',
            key: 'shape',
            input: 'select',
            inputConfig: { options: shapeConfig },
            label: 'config_key.result_table.breast_finding.shape.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.orientation',
            key: 'orientation',
            input: 'select',
            inputConfig: { options: orientationConfig },
            label: 'config_key.result_table.breast_finding.orientation.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.margin',
            key: 'margin',
            input: 'select',
            inputConfig: { options: marginConfig },
            label: 'config_key.result_table.breast_finding.margin.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.echoPattern',
            key: 'echo-pattern',
            input: 'select',
            inputConfig: { options: echoPatternConfig },
            label: 'config_key.result_table.breast_finding.echo_pattern.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.posteriorFeatures',
            key: 'posterior-features',
            input: 'select',
            inputConfig: { options: posteriorFeaturesConfig },
            label:
              'config_key.result_table.breast_finding.posterior_feature.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.calcifications',
            key: 'calcifications',
            input: 'select',
            inputConfig: { options: calcificationsConfig },
            label: 'config_key.result_table.breast_finding.calcification.label',
            isDisabled: isBreastSpecialCases,
            separatorLabel:
              'config_key.result_table.breast_finding.associated_feature.separator_label',
            separator: true,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.vascularity',
            key: 'vascularity',
            input: 'select',
            inputConfig: { options: vascularityConfig },
            label: 'config_key.result_table.breast_finding.vascularity.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.elasticity',
            key: 'elasticity',
            input: 'select',
            inputConfig: { options: elasticityConfig },
            label: 'config_key.result_table.breast_finding.elasticity.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.skinChanges',
            key: 'skin_changes',
            input: 'select',
            inputConfig: { options: skinChanges },
            label: 'config_key.result_table.breast_finding.skin_changes.label',
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'characteristics.architecture',
            key: 'architecture',
            input: 'check',
            inputConfig: breastFindingArchitectureConfig,
            separator: true,
            isDisabled: isBreastSpecialCases,
            featureEnabled: isNotSimplifiedBreastReportingWorkflow,
            showPill: true,
          },
          {
            path: 'notes',
            key: 'breast_associated_notes',
            label:
              'config_key.result_table.breast_finding.breast_associated_notes.label',
            input: 'textArea',
            width: '100%',
            placeholder:
              'config_key.result_table.breast_associated_notes.textarea.placeholder',
            isDebounce: false,
            maxLength: 150,
          },
        ],
      },
    ],
  };
};

export const isBreastSpecialCases = (finding: BreastFinding) => {
  return !!finding.characteristics.specialCase;
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { cnames } from '@helpers/cnames';
import styles from './progressBar.scss';

export interface ProgressBarProps {
  percentage?: number;
  intent?: 'neutral' | 'information' | 'success' | 'error';
}

export const ProgressBar = ({
  percentage = 0,
  intent = 'neutral',
}: ProgressBarProps) => {
  const inputClassNames = cnames(styles.inner, styles[intent]);
  return (
    <Box className={styles.outer}>
      <Box
        className={inputClassNames}
        style={{ transform: `translateX(${-100 + percentage}%` }}
      />
    </Box>
  );
};

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import { LineItem } from '@webMolecules/LineItem/LineItem';
import { TextMuted as CellTextMuted } from '@webMolecules/LineItem/Cell/Text';
import { Message } from '@interfaces/Message';
import { FilterConfigurationLineItem } from '@interfaces/Admin/FilterConfigurationLineItem';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import { t } from '@webInterfaces/I18n';
import * as RouteHelper from '@helpers/routes';

export interface FilterConfigurationProps {
  loading: boolean;
  messages: Message[];
  filterConfigurationLineItems: FilterConfigurationLineItem[];
  onDestroyFilterConfiguration: (id: string) => void;
}

export const FilterConfiguration: React.FC<FilterConfigurationProps> = ({
  loading,
  filterConfigurationLineItems,
  onDestroyFilterConfiguration,
}) => (
  <>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="xl"
    >
      <Box display="flex" alignItems="center">
        <Text type="display1" display="block" marginRight="m">
          {t('pages.settings.admin.workspaces.title')}
        </Text>
        {loading && <LoadingIcon />}
      </Box>
      <RouteLink
        to={RouteHelper.adminWorkspaces('new')}
        component={props => (
          <Button
            intent="primary"
            strong
            iconAfter={<Icon name="plus" />}
            circle
            size="small"
            {...props}
          />
        )}
      />
    </Box>
    <Box data-testid="admin-workspace-list">
      <Schedule
        columns={[
          {
            label: t(
              'pages.settings.admin.workspaces.list.heading.display_name'
            ),
            width: 'minmax(200px, 1fr)',
          },
          {
            label: t(
              'pages.settings.admin.workspaces.list.heading.description'
            ),
            width: 'minmax(300px, 1fr)',
          },
          {
            label: t(
              'pages.settings.admin.workspaces.list.heading.amount_of_rules'
            ),
            width: 'minmax(100px, 3fr)',
          },
        ]}
        hasContextMenu={true}
        items={filterConfigurationLineItems.map(item => (
          <LineItem
            href={RouteHelper.adminWorkspaces(item.id)}
            key={item.id}
            contextMenu={[
              {
                label: t(
                  'pages.settings.admin.workspaces.list.item.context_menu.remove'
                ),
                icon: <Icon name="trash" />,
                onClick: () => {
                  onDestroyFilterConfiguration(item.id);
                },
              },
            ]}
          >
            <CellTextMuted>{item.name}</CellTextMuted>
            <CellTextMuted>{item.description}</CellTextMuted>
            <CellTextMuted>{item.propertiesCount}</CellTextMuted>
          </LineItem>
        ))}
      />
    </Box>
  </>
);

export default FilterConfiguration;

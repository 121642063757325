import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Drawer } from '@webMolecules/Drawer/Drawer';
import { t } from '@webInterfaces/I18n';
import { FindingSelectPanel } from '@webOrganisms/FindingSelectPanel/FindingSelectPanel';
import { selectFindingTableConfig } from '@selectors/SessionSelectors';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { selectFindings } from '@selectors/FindingSelectors';
import { FindingFilterType } from '@config/ResultTable/FindingTable';
import { FindingSortType } from '@entities/InstitutionConfiguration';
import styles from './findingFilterBar.scss';

export const FindingFilterBar: React.FC = ({}) => {
  const [selectMode, setSetSelectMode] = useState(false);
  const findings = useSelector(selectFindings);

  const findingTableConfig = useSelector(selectFindingTableConfig);

  const findingFilters = findingTableConfig?.findingFilters ?? [];
  const findingSortOptions = findingTableConfig?.sortOptions ?? [];

  const dispatchFilterFindings = useDispatcher(findingUseCase.FilterFindings);
  const dispatchSortFindings = useDispatcher(findingUseCase.SortFindings);

  const includedIds = findings.filter(f => f.included).map(f => f.id);

  const totalCount = findings.length;

  const filterOptions = findingFilters.map(({ filterType, labelKey }) => ({
    label: t(labelKey),
    value: filterType,
  }));

  return (
    <>
      <Box
        display="flex"
        className={styles.container}
        justifyContent="start"
        alignItems="center"
        gap="l"
        onClick={e => e.stopPropagation()}
      >
        <Box>
          <Text type="display5">{t('pages.study.finding.filter.total')}</Text>
          <Text type="body2">&nbsp;({totalCount}):</Text>
        </Box>

        <Box
          data-testid="finding-label"
          display="flex"
          alignItems="center"
          gap="xs"
          className={styles.labelColumn}
        >
          <Text type="display6">{t('pages.study.finding.filter.label')}</Text>
          <Box className={styles.select}>
            <MenuSelect
              data-testid="finding-filter-select"
              options={filterOptions}
              size="small"
              placeholder="Select"
              placeholderDisabled
              value=""
              onChange={value => {
                if (value === FindingFilterType.IncludeSelected) {
                  setSetSelectMode(true);
                } else {
                  dispatchFilterFindings({
                    filterType: value as FindingFilterType,
                  });
                }
              }}
            />
          </Box>
          <Text type="display6" marginLeft="l">
            {t('pages.study.finding.sort.label')}
          </Text>
          <Box className={styles.select}>
            <MenuSelect
              options={findingSortOptions.map(option => ({
                label: t(option.labelKey),
                value: option.sortType,
              }))}
              size="small"
              placeholder="Select"
              placeholderDisabled
              value=""
              onChange={value => {
                dispatchSortFindings({ sortType: value as FindingSortType });
              }}
            />
          </Box>
        </Box>
        {/* {workflow == Workflow.Radiologist && (
          <Box display="flex">
            <UndoRedoTool iconOnly={true} />
          </Box>
        )} */}
      </Box>
      <Drawer isOpen={selectMode} width="44vw">
        {selectMode && (
          <FindingSelectPanel
            initialSelectedIds={includedIds}
            findings={findings}
            handleClose={() => setSetSelectMode(false)}
          />
        )}
      </Drawer>
    </>
  );
};

export default FindingFilterBar;

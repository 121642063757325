import dayjs from 'dayjs';

export const decode = (
  str: string,
  format: null | string = 'YYYYMMDD'
): null | Date => {
  if (str.match(/^[0-9]{8,12}$/)) {
    str = str.substring(0, 8);
  }

  const date = dayjs(str, format ? format : undefined);
  if (date.isValid() && date.isBefore(new Date())) {
    return date.toDate();
  } else if (format) {
    return decode(str, null);
  }
  return null;
};

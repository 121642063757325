export enum BatchStatus {
  Ready,
  Pending,
  Error,
}

export interface StudyBatchStatusItem {
  batchId: string;
  status: BatchStatus;
  analyses: {
    analysisId: string;
    sessionIds: string[];
  }[];
  filesTotal: number;
  filesProcessed: number;
  filesFailed: number;
}

import React from 'react';
import {
  ResultTableConfigColumn,
  ResultTableToggleConfig,
  ResultTableConfigRow,
  ResultTableNumberConfig,
  ResultTableSizeConfig,
  ResultTableSelectConfig,
} from '@config/ResultTable/ResultTable';
import { Box } from '@webMolecules/Box/Box';
import { getUnitI18nKey } from '@entities/Unit';
import { ResultTableSharedProps } from './ResultTableSharedProps';
import { ResultTableCellLabel } from './Cells/Label';
import { ResultTableCellNumber } from './Cells/Number';
import { ResultTableCellString } from './Cells/String';
import { ResultTableCellSelect } from './Cells/Select';
import { ResultTableCellTextArea } from './Cells/TextArea';
import { ResultTableCellToggleGroup } from './Cells/ToggleGroup';
import { ResultTableCellCheck } from './Cells/CheckboxGroup';
import { ResultTableCellRadioGroup } from './Cells/RadioGroup';
import { ResultTableCellFindingSize } from './Cells/FindingSize';
import { ResultTableCellClockface } from './Cells/Clockface';
import { ResultTableCellScore } from './Cells/Score';
import { FindingIncludeSwitch } from './Cells/FindingIncludeSwitch';
import { ResultTableCellFindingRecommendation } from './Cells/FindingRecommendation';

export interface ResultTableCellProps extends ResultTableSharedProps {
  config: ResultTableConfigColumn;
  rowConfig: ResultTableConfigRow;
  disabled?: boolean;
  onToggle?(oldValues: string[], newValues: string[]): string[];
}

export const ResultTableCell: React.FC<ResultTableCellProps> = ({
  config,
  rowConfig,
  disabled,
  onToggle,
  ...sharedProps
}) => {
  const unitI18NKey = config.unit
    ? config.unitPosition === 'inline'
      ? getUnitI18nKey(config.unit)
      : undefined
    : undefined;

  switch (config.input) {
    case 'label':
      return (
        <ResultTableCellLabel
          displayI18NKey={rowConfig.displayI18NKey}
          footnote={rowConfig.unit}
          {...sharedProps}
        />
      );
    case 'float':
    case 'integer':
      return (
        <ResultTableCellNumber
          type={config.input}
          config={config.inputConfig as ResultTableNumberConfig}
          disabled={disabled}
          unit={config.unit || rowConfig.unit}
          unitI18NKey={unitI18NKey}
          {...sharedProps}
        />
      );
    case 'string':
      return (
        <ResultTableCellString
          disabled={disabled}
          readOnly={config.readOnly}
          {...sharedProps}
        />
      );
    case 'textArea':
      return (
        <ResultTableCellTextArea
          maxLength={config.maxLength}
          isDebounce={config.isDebounce}
          disabled={disabled}
          {...sharedProps}
        />
      );
    case 'select':
      return (
        <ResultTableCellSelect
          config={config.inputConfig as ResultTableSelectConfig}
          disabled={disabled}
          confidenceThreshold={config.confidenceThreshold}
          {...sharedProps}
        />
      );
    case 'toggleGroup':
      return (
        <ResultTableCellToggleGroup
          toggleKey={config.key}
          toggleConfig={config.inputConfig as ResultTableToggleConfig}
          disabled={disabled}
          {...sharedProps}
        />
      );
    case 'check':
      return (
        <ResultTableCellCheck
          checkboxKey={config.key}
          checkboxConfig={config.inputConfig as ResultTableToggleConfig}
          disabled={disabled}
          onToggle={onToggle}
          {...sharedProps}
        />
      );
    case 'radio':
      return (
        <ResultTableCellRadioGroup
          radioKey={config.key}
          radioConfig={config.inputConfig as ResultTableToggleConfig}
          disabled={disabled}
          {...sharedProps}
        />
      );
    case 'dimensions':
      return (
        <ResultTableCellFindingSize
          config={config.inputConfig as ResultTableSizeConfig}
          disabled={disabled}
          unit={config.unit || rowConfig.unit}
          unitI18NKey={unitI18NKey}
          {...sharedProps}
        />
      );
    case 'clockface':
      return <ResultTableCellClockface disabled={disabled} {...sharedProps} />;
    case 'score':
      return <ResultTableCellScore {...sharedProps} />;
    case 'findingInclude':
      return <FindingIncludeSwitch {...sharedProps} />;

    case 'findingRecommendation':
      return <ResultTableCellFindingRecommendation {...sharedProps} />;
    case 'spacer':
      return <Box></Box>;
    default:
      return <Box>?</Box>;
  }
};

export interface I18n {
  locale: string;
  available: string[];
  translations: Translations;
}

export type Translations = Map<string, string>;

export const initial = (): I18n => ({
  locale: 'en-AU',
  available: [],
  translations: new Map(),
});

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { signOut } from '@webViewInterfaces/Pages/General';
import { downloadEncryptedPdf } from '@webViewInterfaces/UseCase/Session';
import { Profile } from '@interfaces/Profile';
import { toApp } from '@interfaces/Root';
import {
  toSearch,
  toWorklist,
  toPACS,
  toProfile,
  toAppSettings,
} from '@interfaces/App';
import { ApiStatus } from '@interfaces/Status';
import { SearchResultLineItemData } from '@webOrganisms/SearchResultLineItem/SearchResultLineItem';
import { SessionStatus } from '@interfaces/SessionLineItem';
import {
  listNextSearchResults,
  setSessionStatus,
} from '@webViewInterfaces/UseCase/Search';
import {
  PACSStudyLineItem,
  PACSSystemStatus,
} from '@interfaces/PACSStudyLineItem';
import * as RouteHelper from '@helpers/routes';
import { updateLocation } from '@webViewInterfaces/UseCase/Location';
import { studyTypeToDescription } from '@interfaces/StudyType';
import { WorklistLineItem } from '@entities/Worklist';
import { FeatureFlags, getFeatureFlags } from '@entities/FeatureFlag';
import { getReportURL } from './WorklistHelper';

export type Search = SearchProps & SearchDispatchers;

export interface SearchProps {
  me: Profile;
  regulatoryVersion: string;
  regulatoryVersionModifiedDate: string;
  loading: boolean;
  searchQuery: string;
  totalAvailableItems: number;
  pacsStudyLineItems: PACSStudyLineItem[];
  pacsSystemStatus: PACSSystemStatus;
  amountOfResults: number;
  searchResult: SearchResultLineItemData[];
  featureFlags: FeatureFlags;
}

export interface SearchDispatchers {
  dispatchOnSignOut: () => Dispatch<Actions>;
  dispatchSetSessionStatus: (
    analysisId: string,
    sessionId: string,
    sessionStatus: SessionStatus,
    featureFlags: FeatureFlags
  ) => Dispatch<Actions>;
  dispatchDownloadEncryptedPdf: (
    encryptedPdfUrl: string,
    fileName: string
  ) => Dispatch<Actions>;
  dispatchOpenStudy: (
    analysisId: string,
    sessionId: string
  ) => Dispatch<Actions>;
  dispatchLoadNext: (featureFlags: FeatureFlags) => Dispatch<Actions>;
}

export const dispatchers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any
) => ({
  dispatchOnSignOut: () => dispatch(signOut()),
  dispatchSetSessionStatus: (
    analysisId: string,
    sessionId: string,
    sessionStatus: SessionStatus,
    featureFlags: FeatureFlags
  ) =>
    dispatch(
      setSessionStatus(analysisId, sessionId, sessionStatus, featureFlags)
    ),
  dispatchDownloadEncryptedPdf: (encryptedPdfUrl: string, fileName: string) =>
    dispatch(downloadEncryptedPdf(encryptedPdfUrl, fileName)),
  dispatchOpenStudy: (analysisId: string, sessionId: string) =>
    dispatch(updateLocation(RouteHelper.study(analysisId, sessionId))),
  dispatchLoadNext: (featureFlags: FeatureFlags) =>
    dispatch(listNextSearchResults(featureFlags)),
});

export const fromState = (state: State): SearchProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const { query, status, searchResultItems, totalAvailableItems } =
    toSearch(app);
  const { profiles } = toWorklist(app);
  const pacs = toPACS(app);
  const userProfiles = profiles.second();
  const me = toProfile(app);
  const appSettings = toAppSettings(app);
  const regulatoryVersion = appSettings.regulatoryVersion.lift() || '';
  const regulatoryVersionModifiedDate =
    appSettings.regulatoryVersionModifiedDate.lift() || '';
  const loading = status === ApiStatus.Busy;
  const searchResult = searchResultItems.map(
    mapSearchResultLineItem(userProfiles)
  );
  const pacsStudyLineItems = pacs.pacsStudyLineItems.second();
  const pacsSystemStatus = pacs.pacsSystemStatus;

  const featureFlags = getFeatureFlags();

  return {
    me,
    regulatoryVersion,
    regulatoryVersionModifiedDate,
    loading,
    amountOfResults: searchResult.length,
    totalAvailableItems,
    searchQuery: query,
    pacsStudyLineItems,
    pacsSystemStatus,
    searchResult,
    featureFlags,
  };
};

const mapSearchResultLineItem =
  (profiles: Profile[]) =>
  (searchResultItem: WorklistLineItem): SearchResultLineItemData => {
    const patientName = searchResultItem.decryptedPhi?.patient_name;

    const userProfiles =
      profiles.length > 0
        ? profiles
            .filter(profile =>
              searchResultItem.collaborators.includes(profile.id)
            )
            .sort(a => (a.id === searchResultItem.userId ? -1 : 1))
            .map(profile => {
              return {
                color: profile.swatchColour,
                name: `${profile.firstName} ${profile.lastName}`,
                initial: `${profile.firstName}`,
              };
            })
        : [
            {
              color: 'var(--ds-background-color-alt-200)',
              name: '',
              initial: '',
            },
          ];
    const studyTypeDescription = studyTypeToDescription(
      searchResultItem.studyType
    );

    return {
      analysisId: searchResultItem.analysisId,
      accessionNumber: searchResultItem.accessionNumber,
      studyTypeDescription: studyTypeDescription,
      date: searchResultItem.date ? searchResultItem.date : undefined,
      pdfUrl: getReportURL(searchResultItem.generatedPDF),
      userProfiles,
      sessionId: searchResultItem.id,
      userId: searchResultItem.userId,
      patientName: patientName,
      status: searchResultItem.status,
      hasDecryptedPhi: searchResultItem.decrypted,
      completedDate: searchResultItem.completedDate,
    };
  };

import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Box } from '@webMolecules/Box/Box';
import { Icon } from '@webMolecules/Icon/Icon';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Schedule } from '@webMolecules/Schedule/Schedule';
import { LineItem } from '@webMolecules/LineItem/LineItem';
import { TextMuted as CellTextMuted } from '@webMolecules/LineItem/Cell/Text';
import { Message } from '@interfaces/Message';
import {
  InstitutionLineItem,
  institutionStatusToString,
  InstitutionStatus,
} from '@interfaces/Admin/InstitutionLineItem';
import { RouteLink } from '@webOrganisms/RouteLink/RouteLink';
import * as RouteHelper from '@helpers/routes';
import { AWS_REGION_DIRECTORY } from '@contracts/Admin/Institutions';
import { t } from '@webInterfaces/I18n';
dayjs.extend(relativeTime);

export interface InstitutionsProps {
  loading: boolean;
  messages: Message[];
  institutionLineItems: InstitutionLineItem[];
}

export const Institutions: React.FC<InstitutionsProps> = ({
  loading,
  institutionLineItems,
}) => (
  <>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="xl"
    >
      <Box display="flex" alignItems="center">
        <Text type="display1" display="block" marginRight="m">
          Institutions
        </Text>
        {loading && <LoadingIcon />}
      </Box>
      <RouteLink
        to={RouteHelper.adminInstitutions('new')}
        component={props => (
          <Button
            intent="primary"
            strong
            iconAfter={<Icon name="plus" />}
            circle
            size="small"
            {...props}
          />
        )}
      />
    </Box>
    <Box>
      <Schedule
        columns={[
          {
            label: t('pages.settings.institution.heading.institution_id'),
            width: '300px',
          },
          {
            label: t('pages.settings.institution.heading.region'),
          },
          {
            label: t('pages.settings.institution.heading.status'),
          },
        ]}
        hasContextMenu={true}
        items={institutionLineItems.map(item => (
          <LineItem
            href={
              item.status === InstitutionStatus.Deployed
                ? RouteHelper.adminInstitutions(item.id)
                : undefined
            }
            key={item.id}
          >
            <CellTextMuted>{item.id}</CellTextMuted>
            <CellTextMuted>
              {AWS_REGION_DIRECTORY[item.region] || item.region}
            </CellTextMuted>
            <CellTextMuted>
              {institutionStatusToString(item.status)}
            </CellTextMuted>
          </LineItem>
        ))}
      />
    </Box>
  </>
);

export default Institutions;

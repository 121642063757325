import React from 'react';
import { useController } from 'react-hook-form';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import ResultTableContext, {
  ResultTableStyle,
} from '@webOrganisms/ResultTable/ResultTableContext';
import { Text } from '@webMolecules/Text/Text';
import { Box } from '@webMolecules/Box/Box';
import { Clockface } from '@entities/Finding';
import { isNotBlank } from '@helpers/string';
import { ResultTableSharedProps } from '../ResultTableSharedProps';
import { formatClockface } from '../FindingsTable/BreastFindingHelper';

export interface ResultTableCellClockfaceProps extends ResultTableSharedProps {
  disabled?: boolean;
}

const hourOptions = [
  { label: '12', value: '12' },
  { label: '01', value: '1' },
  { label: '02', value: '2' },
  { label: '03', value: '3' },
  { label: '04', value: '4' },
  { label: '05', value: '5' },
  { label: '06', value: '6' },
  { label: '07', value: '7' },
  { label: '08', value: '8' },
  { label: '09', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
];
const minuteOptions = [
  { label: '00', value: '0' },
  { label: '30', value: '30' },
];

export const ResultTableCellClockface: React.FC<
  ResultTableCellClockfaceProps
> = ({ path, disabled }) => {
  const { theme } = React.useContext(ResultTableContext);
  const { field } = useController({
    name: path ?? '',
  });
  const clockfaceValue = getClockface(field.value);

  const onChange = (value: string, changedField: keyof Clockface) => {
    const newValue = isNotBlank(value) ? value : null;
    if (newValue == clockfaceValue?.[changedField]) {
      return;
    }

    field.onChange({
      ...(clockfaceValue ?? { hour: null, minute: null }),
      [changedField]: newValue,
    });
  };

  if (theme == ResultTableStyle.preview) {
    return (
      clockfaceValue && (
        <Text type="body2" data-testid="result-table-number-preview">
          {formatClockface(clockfaceValue)}
        </Text>
      )
    );
  }

  return (
    <>
      <Box
        display="inline-block"
        style={{ minWidth: '50px', maxWidth: '100px' }}
        data-testid="clockface-hour-test-id"
      >
        <MenuSelect
          fluid
          disabled={disabled}
          value={clockfaceValue?.hour ?? undefined}
          onChange={value => onChange(value, 'hour')}
          options={hourOptions}
          placeholder="-"
          size="small"
        />
      </Box>
      <Box display="inline-block" paddingX="s" paddingTop="xxs">
        :
      </Box>
      <Box
        display="inline-block"
        style={{ minWidth: '50px', maxWidth: '100px' }}
        data-testid="clockface-minute-test-id"
      >
        <MenuSelect
          disabled={disabled}
          value={clockfaceValue?.minute ?? undefined}
          onChange={value => onChange(value, 'minute')}
          options={minuteOptions}
          placeholder="-"
          size="small"
        />
      </Box>
    </>
  );
};

function getClockface(object: unknown): Clockface | null {
  if (object == null || typeof object !== 'object') {
    return null;
  }
  let hour: string | null = null;
  if (
    'hour' in object &&
    typeof object.hour === 'string' &&
    isNotBlank(object.hour)
  ) {
    // If zero, set to 12
    hour = object.hour === '0' ? '12' : object.hour;
  }
  let minute: string | null = null;
  if (
    'minute' in object &&
    typeof object.minute === 'string' &&
    isNotBlank(object.minute)
  ) {
    minute = object.minute;
  }
  return { hour, minute };
}

import { fabric } from 'fabric';
import DarkThemeColors from '@webAtoms/Theme/theme.dark.scss';
import PrintThemeColors from '@webAtoms/Theme/theme.print.scss';
import LightThemeColors from '@webAtoms/Theme/theme.light.scss';

export enum ColourPaletteName {
  active = 'colour_palette_name---active',
  display = 'colour_palette_name---display',
  light = 'colour_palette_name---light',
}

export interface ColourPalette {
  name: ColourPaletteName;
  TEMPLATE_VESSEL_COLOUR: string;
  TEMPLATE_VESSEL_CAP_COLOUR: string;
  TEMPLATE_BODY_COLOUR: string;
  STENT_COLOUR: string;
  COLLATERAL_VESSEL_COLOUR: string;
  COLLATERAL_WALL_COLOUR: string;
  TRANSVERSE_OUTLINE_COLOUR: string;
  FREEHAND_BRUSH_COLOUR: string;
  FREEHAND_BRUSH_COLOUR_SECONDARY: string;
  FREEHAND_ERASER_COLOUR: string;
  CUSTOM_TEXT_COLOUR: string;
  FREEHAND_BRUSH_RED_COLOUR: string;
  FREEHAND_BRUSH_GREEN_COLOUR: string;

  SYMBOL_PRIMARY_NEUTRAL_COLOUR: string;
  SYMBOL_PRIMARY_RED_COLOUR: string;
  SYMBOL_PRIMARY_GREEN_COLOUR: string;
  SYMBOL_SECONDARY_NEUTRAL_COLOUR: string;
  SYMBOL_SECONDARY_RED_COLOUR: string;
  SYMBOL_SECONDARY_GREEN_COLOUR: string;

  OBJECT_DEFAULT_COLOUR: string;
  OBJECT_SELECTED_COLOUR: string;
  OBJECT_HOVER_COLOUR: string;
  OBJECT_UNFILLED_COLOUR: string;
  OBJECT_OUTLINE_COLOUR: string;

  SMALL_PARTS_BREAST_NODULE_LIGHT_COLOUR: string;

  OBJECT_CORNER_COLOUR: string;
  BRUSH_CURSOR_STROKE_COLOUR: string;
  TEMPLATE_BACKGROUND: string;

  LABEL_TEXT_COLOUR: string;
  LABEL_BACKGROUND_COLOUR: string;

  COMPETENCE_BG_COLOUR: string;
  COMPETENCE_FG_COLOUR: string;
  INCOMPETENCE_BG_COLOUR: string;
  INCOMPETENCE_FG_COLOUR: string;

  NODULE_SEVERITY_1000: string;
  NODULE_SEVERITY_900: string;
  NODULE_SEVERITY_700: string;
  NODULE_SEVERITY_500: string;
  NODULE_SEVERITY_300: string;
  NODULE_SEVERITY_100: string;
  NODULE_SEVERITY_000: string;
  PRIMARY_COLOR: string;

  NODULE_INDEX_LABEL_BG: string;
  NODULE_INDEX_LABEL_MUTED_BG: string;
  NODULE_INDEX_LABEL_TEXT: string;
  NODULE_MUTED: string;
  PREVIOUS_CANVAS_BG_COLOUR: string;
}

export const userColourPaletteActive: ColourPalette = {
  name: ColourPaletteName.active,
  TEMPLATE_VESSEL_COLOUR: DarkThemeColors.templateBase,
  COLLATERAL_VESSEL_COLOUR: DarkThemeColors.templateBase,
  TRANSVERSE_OUTLINE_COLOUR: DarkThemeColors.templateBase,
  TEMPLATE_VESSEL_CAP_COLOUR: DarkThemeColors.templateBaseCap,
  TEMPLATE_BODY_COLOUR: DarkThemeColors.templateBody,

  BRUSH_CURSOR_STROKE_COLOUR: DarkThemeColors.white,
  STENT_COLOUR: DarkThemeColors.white,
  CUSTOM_TEXT_COLOUR: DarkThemeColors.white,

  COLLATERAL_WALL_COLOUR: DarkThemeColors.background,
  FREEHAND_ERASER_COLOUR: DarkThemeColors.background,
  TEMPLATE_BACKGROUND: DarkThemeColors.background,

  FREEHAND_BRUSH_COLOUR: DarkThemeColors.objectDefault,
  FREEHAND_BRUSH_COLOUR_SECONDARY: DarkThemeColors.objectOpacity50,
  OBJECT_DEFAULT_COLOUR: DarkThemeColors.objectDefault,
  OBJECT_SELECTED_COLOUR: DarkThemeColors.objectSelected,
  OBJECT_HOVER_COLOUR: DarkThemeColors.objectHovered,
  OBJECT_CORNER_COLOUR: DarkThemeColors.information500,
  OBJECT_UNFILLED_COLOUR: DarkThemeColors.background,
  OBJECT_OUTLINE_COLOUR: DarkThemeColors.objectDefault,

  SMALL_PARTS_BREAST_NODULE_LIGHT_COLOUR: DarkThemeColors.templateSecondary,

  LABEL_TEXT_COLOUR: DarkThemeColors.white,
  NODULE_SEVERITY_1000: DarkThemeColors.severity1000,
  NODULE_SEVERITY_900: DarkThemeColors.severity900,
  NODULE_SEVERITY_700: DarkThemeColors.severity700,
  NODULE_SEVERITY_500: DarkThemeColors.severity500,
  NODULE_SEVERITY_300: DarkThemeColors.severity300,
  NODULE_SEVERITY_100: DarkThemeColors.severity100,
  NODULE_SEVERITY_000: DarkThemeColors.severity000,
  LABEL_BACKGROUND_COLOUR: PrintThemeColors.templateBase,

  COMPETENCE_BG_COLOUR: DarkThemeColors.darkGreen,
  COMPETENCE_FG_COLOUR: DarkThemeColors.lightGreen,
  INCOMPETENCE_BG_COLOUR: DarkThemeColors.darkRed,
  INCOMPETENCE_FG_COLOUR: DarkThemeColors.lightRed,

  FREEHAND_BRUSH_RED_COLOUR: DarkThemeColors.darkRed,
  FREEHAND_BRUSH_GREEN_COLOUR: DarkThemeColors.darkGreen,

  SYMBOL_PRIMARY_NEUTRAL_COLOUR: DarkThemeColors.objectSelected,
  SYMBOL_PRIMARY_RED_COLOUR: DarkThemeColors.lightRed,
  SYMBOL_PRIMARY_GREEN_COLOUR: DarkThemeColors.lightGreen,
  SYMBOL_SECONDARY_NEUTRAL_COLOUR: DarkThemeColors.fillNeutral,
  SYMBOL_SECONDARY_RED_COLOUR: DarkThemeColors.fillRed,
  SYMBOL_SECONDARY_GREEN_COLOUR: DarkThemeColors.fillGreen,

  PRIMARY_COLOR: DarkThemeColors.primaryColor,
  NODULE_INDEX_LABEL_BG: DarkThemeColors.information500,
  NODULE_INDEX_LABEL_MUTED_BG: DarkThemeColors.neutral400,
  NODULE_INDEX_LABEL_TEXT: DarkThemeColors.white,
  NODULE_MUTED: DarkThemeColors.templateBody,
  PREVIOUS_CANVAS_BG_COLOUR: DarkThemeColors.background,
};

export const userColourPaletteDisplay: ColourPalette = {
  name: ColourPaletteName.display,
  TEMPLATE_VESSEL_COLOUR: PrintThemeColors.black,
  TEMPLATE_VESSEL_CAP_COLOUR: PrintThemeColors.black,
  TEMPLATE_BODY_COLOUR: PrintThemeColors.white,
  COLLATERAL_VESSEL_COLOUR: PrintThemeColors.black,
  COLLATERAL_WALL_COLOUR: PrintThemeColors.white,
  TRANSVERSE_OUTLINE_COLOUR: PrintThemeColors.black,
  FREEHAND_BRUSH_COLOUR: PrintThemeColors.black,
  FREEHAND_BRUSH_COLOUR_SECONDARY: PrintThemeColors.blackOpacity50,
  OBJECT_DEFAULT_COLOUR: PrintThemeColors.black,
  CUSTOM_TEXT_COLOUR: PrintThemeColors.black,
  OBJECT_SELECTED_COLOUR: PrintThemeColors.black,
  OBJECT_HOVER_COLOUR: PrintThemeColors.black,
  OBJECT_CORNER_COLOUR: PrintThemeColors.black,
  OBJECT_UNFILLED_COLOUR: PrintThemeColors.white,
  OBJECT_OUTLINE_COLOUR: PrintThemeColors.black,

  SMALL_PARTS_BREAST_NODULE_LIGHT_COLOUR: PrintThemeColors.templateBase,

  BRUSH_CURSOR_STROKE_COLOUR: PrintThemeColors.white,
  FREEHAND_ERASER_COLOUR: PrintThemeColors.white,
  TEMPLATE_BACKGROUND: PrintThemeColors.white,
  NODULE_SEVERITY_1000: PrintThemeColors.severity1000,
  NODULE_SEVERITY_900: PrintThemeColors.severity900,
  NODULE_SEVERITY_700: PrintThemeColors.severity700,
  NODULE_SEVERITY_500: PrintThemeColors.severity500,
  NODULE_SEVERITY_300: PrintThemeColors.severity300,
  NODULE_SEVERITY_100: PrintThemeColors.severity100,
  NODULE_SEVERITY_000: PrintThemeColors.severity000,

  STENT_COLOUR: PrintThemeColors.stent,

  LABEL_TEXT_COLOUR: PrintThemeColors.white,
  LABEL_BACKGROUND_COLOUR: PrintThemeColors.black,
  COMPETENCE_BG_COLOUR: PrintThemeColors.darkGreen,
  COMPETENCE_FG_COLOUR: PrintThemeColors.lightGreen,
  INCOMPETENCE_BG_COLOUR: PrintThemeColors.darkRed,
  INCOMPETENCE_FG_COLOUR: PrintThemeColors.lightRed,

  FREEHAND_BRUSH_RED_COLOUR: PrintThemeColors.darkRed,
  FREEHAND_BRUSH_GREEN_COLOUR: PrintThemeColors.darkGreen,

  SYMBOL_PRIMARY_NEUTRAL_COLOUR: PrintThemeColors.objectSelected,
  SYMBOL_PRIMARY_RED_COLOUR: PrintThemeColors.lightRed,
  SYMBOL_PRIMARY_GREEN_COLOUR: PrintThemeColors.lightGreen,
  SYMBOL_SECONDARY_NEUTRAL_COLOUR: PrintThemeColors.fillNeutral,
  SYMBOL_SECONDARY_RED_COLOUR: PrintThemeColors.fillRed,
  SYMBOL_SECONDARY_GREEN_COLOUR: PrintThemeColors.fillGreen,

  PRIMARY_COLOR: PrintThemeColors.primaryColor,
  NODULE_INDEX_LABEL_BG: PrintThemeColors.information500,
  NODULE_INDEX_LABEL_MUTED_BG: PrintThemeColors.neutral300,
  NODULE_INDEX_LABEL_TEXT: DarkThemeColors.white,
  NODULE_MUTED: DarkThemeColors.templateBody,
  PREVIOUS_CANVAS_BG_COLOUR: PrintThemeColors.background,
};

export const userColourPaletteLight: ColourPalette = {
  ...userColourPaletteDisplay,
  name: ColourPaletteName.light,
  TEMPLATE_VESSEL_COLOUR: LightThemeColors.neutral500,

  NODULE_SEVERITY_900: LightThemeColors.severity900,
  NODULE_SEVERITY_700: LightThemeColors.severity700,
  NODULE_SEVERITY_500: LightThemeColors.severity500,
  NODULE_SEVERITY_300: LightThemeColors.severity300,
  NODULE_SEVERITY_100: LightThemeColors.severity100,

  NODULE_MUTED: LightThemeColors.neutral100,
  NODULE_INDEX_LABEL_MUTED_BG: LightThemeColors.neutral300,
  PREVIOUS_CANVAS_BG_COLOUR: LightThemeColors.neutral50,
};

export const decideStrokeColorByTheme = (
  stroke: string | undefined,
  canvasColorPalette: ColourPalette
) => {
  const strokeColour = new fabric.Color(stroke).toHexa();

  const freehandBrushColorSecondary = new fabric.Color(
    DarkThemeColors.objectOpacity50
  ).toHexa();
  const eraserBrushColor = new fabric.Color(
    DarkThemeColors.background
  ).toHexa();
  const darkRedBrushColor = new fabric.Color(DarkThemeColors.darkRed).toHexa();
  const darkGreenBrushColor = new fabric.Color(
    DarkThemeColors.darkGreen
  ).toHexa();

  switch (strokeColour) {
    case freehandBrushColorSecondary:
      return canvasColorPalette.FREEHAND_BRUSH_COLOUR_SECONDARY;
    case eraserBrushColor:
      return canvasColorPalette.FREEHAND_ERASER_COLOUR;
    case darkRedBrushColor:
      return canvasColorPalette.FREEHAND_BRUSH_RED_COLOUR;
    case darkGreenBrushColor:
      return canvasColorPalette.FREEHAND_BRUSH_GREEN_COLOUR;
    default:
      return canvasColorPalette.name === ColourPaletteName.active
        ? stroke // keep the original stroke color
        : canvasColorPalette.FREEHAND_BRUSH_COLOUR; // use the print theme color
  }
};

import { Action } from 'redux';
import Maybe, { just, nothing } from '@monads/Maybe';
import { I18n } from '@entities/I18n';
import { Authentication } from '@entities/Authentication';
import { Root } from '@entities/Root';
import { App } from '@entities/App';
import { Message } from '@interfaces/Message';
import { addCriticalErrorNotification } from '@interfaces/Notifications';

export const toAuth = (root: Root): Authentication => root.auth;

export const toI18n = (root: Root): I18n => root.i18n;

export const toApp = (root: Root): Maybe<App> => root.app;

export const toMessages = (root: Root): Message[] => root.messages;

export const toLatestMessage = (root: Root): undefined | Message => {
  const messages = [...toMessages(root)];
  return messages.shift();
};

export const effectErrorToMaybeNotification = (
  label: string
): Maybe<Action> => {
  const actions = [
    'effect-session-generate-full-pdf',
    'effect-session-set-initial-layout',
    'effect-session-study-bytes-create-or-update-study-byte',
    'effect-session-update-available-tabs',
    'effect-session-update-configuration',
    'effect-session-update-metadata',
    'effect-update-finding',
  ];

  if (actions.some(action => label.includes(action))) {
    return just(addCriticalErrorNotification());
  } else {
    return nothing();
  }
};

import React, { useState } from 'react';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { Grid } from '@webMolecules/Grid/Grid';
import { Image } from '@webMolecules/Image/Image';
import { t, tPrint } from '@webInterfaces/I18n';
import { Switch } from '@webMolecules/Switch/Switch';
import { Slider } from '@webMolecules/Slider/Slider';
import { useImageContext } from '@hooks/useImageContext';
import { cnames } from '@helpers/cnames';
import styles from './comparisonUtility.scss';

interface InlineDicomImageGridProps {
  dicomIds: string[];
  showControls?: boolean;
}

export const InlineDicomImageGrid: React.FC<InlineDicomImageGridProps> = ({
  dicomIds,
  showControls,
}) => {
  const { getImage } = useImageContext();
  const [gridSize, setGridSize] = useState(2);
  const [showTopImages, setShowTopImages] = useState(true);

  const filteredDicomIds = showTopImages ? dicomIds.slice(0, 4) : dicomIds;

  return (
    <Box>
      {showControls && (
        <Box display="flex" alignItems="center" gap="xxs">
          <Text display="block" muted type="body2" noShrink>
            {tPrint('pages.study.comparison.images.label', dicomIds.length)}
          </Text>
          <Box>
            <Slider
              disabled={false}
              min={1}
              max={2}
              defaultValue={1}
              fluid
              step={1}
              onChange={value => {
                setGridSize(3 - value);
              }}
            />
          </Box>
          <Box flex="1" display="flex" justifyContent="flex-end">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
              paddingY="s"
            >
              <Switch
                disabled={dicomIds.length <= 4}
                align="right"
                checked={showTopImages}
                onChange={checked => setShowTopImages(checked)}
                label={t('pages.study.comparison.top_images.label')}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        className={getClassNameFromGridState(
          !!showControls,
          gridSize,
          filteredDicomIds.length
        )}
        scrollable="y"
      >
        <Grid columns={gridSize} condensed>
          {filteredDicomIds.map(dicomId => {
            const imageSrc = getImage(dicomId);
            return <Image key={dicomId} src={imageSrc} fluid />;
          })}
        </Grid>
      </Box>
    </Box>
  );
};

function getClassNameFromGridState(
  showControls: boolean,
  gridSize: number,
  items: number
) {
  if (!showControls) return styles.inlineDicomImageGrid;

  return cnames(styles.inlineDicomImageGrid, {
    [styles.halfHeight]: gridSize === 2 && items > 0,
    [styles.fullHeight]: (gridSize === 1 && items > 0) || items > 2,
  });
}

import React from 'react';
import { cnames } from '@helpers/cnames';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { BiradsScore, TiRadsGrading } from '@entities/Grading';
import { NoduleGradingMap, getGradingColorStyle } from '@config/NoduleGrading';
import { ChangeLabel } from '@webMolecules/ChangeLabel/ChangeLabel';
import styles from './gradingLabel.scss';

interface GradingLabelProps {
  grading?: TiRadsGrading | BiradsScore;
  hideText?: boolean;
  horizontal?: boolean;
}

export const GradingLabel: React.FC<GradingLabelProps> = ({
  grading,
  hideText,
  horizontal,
}) => {
  if (!grading) {
    return <Box className={cnames(styles.grading)}></Box>;
  }

  const { prefix, value } = NoduleGradingMap[grading];

  const gradingText = horizontal ? (
    `${prefix}${value}`
  ) : (
    <>
      {prefix[0]}
      <br />
      {prefix[1]}
      <br />
      {value}
    </>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={cnames(styles.grading)}
      style={getGradingColorStyle(grading)}
    >
      {!hideText && (
        <>
          <Text display="block" type="display6">
            {gradingText}
          </Text>
        </>
      )}
    </Box>
  );
};

interface GradingChangeLabelProps {
  previousGrading: TiRadsGrading | BiradsScore;
  currentGrading: TiRadsGrading | BiradsScore;
}

export const GradingChangeLabel: React.FC<GradingChangeLabelProps> = ({
  previousGrading,
  currentGrading,
}) => {
  const previousConfig = NoduleGradingMap[previousGrading];
  const currentConfig = NoduleGradingMap[currentGrading];
  return (
    <ChangeLabel
      previousValue={previousConfig.severity}
      currentValue={currentConfig.severity}
      type="absolute"
      label={currentConfig.prefix}
      previousDisplayValue={previousConfig.value}
      currentDisplayValue={currentConfig.value}
    />
  );
};

import { Dispatch } from 'redux';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { upload } from '@webViewInterfaces/UseCase/AnalysisUploads';
import { toAnalysisUploads } from '@interfaces/App';
import { AnalysisUploadsLineItem } from '@interfaces/AnalysisUploadsLineItem';
import { toApp } from '@interfaces/Root';

export type Uploads = UploadsProps & UploadsDispatchers;

export interface UploadsProps {
  analysisUploadsLineItems: AnalysisUploadsLineItem[];
}

export interface UploadsDispatchers {
  dispatchUpload: (fileList: File[]) => Dispatch<Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchers = (dispatch: any) => ({
  dispatchUpload: (fileList: File[]) => dispatch(upload(fileList)),
});

export const fromState = (state: State): UploadsProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  return {
    analysisUploadsLineItems: toAnalysisUploads(app).list,
  };
};

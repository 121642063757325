import React from 'react';
import { connect } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Worklist/Worklist';
import * as StoryLayout from '@webPages/Worklist/Layout';
import * as StoryWorklist from '@webPages/Worklist/Worklist';
import { SessionStatus } from '@interfaces/SessionLineItem';
import { getFeatureFlags } from '@entities/FeatureFlag';
import Sidebar from './Sidebar';

const Worklist: React.FC<ViewModel.Worklist> = ({
  me,
  regulatoryVersion,
  regulatoryVersionModifiedDate,
  searchQuery,
  activeFilters,
  loading,
  worklistLineItems,
  pacsStudyLineItems,
  pacsSystemStatus,
  totalAvailableItems,
  dispatchOnSignOut,
  dispatchUpload,
  dispatchLoadNext,
  dispatchSetSessionStatus,
  dispatchOpenStudy,
  dispatchDownloadEncryptedPdf,
  dispatchMarkAsBookmarked,
  failedToLoadWorklist,
  customWorkspaces,
  dispatchUpdateSessionAssignedWorkspaces,
}) => {
  const featureFlags = getFeatureFlags();

  const onUpdateSessionAssignedWorkspaces = (
    sessionId: string,
    workspaces: string[]
  ) => {
    dispatchUpdateSessionAssignedWorkspaces(sessionId, workspaces);
  };

  return (
    <StoryLayout.Layout
      me={me}
      regulatoryVersion={regulatoryVersion}
      regulatoryVersionModifiedDate={regulatoryVersionModifiedDate}
      searchQuery={searchQuery}
      pacsStudyLineItems={pacsStudyLineItems}
      pacsSystemStatus={pacsSystemStatus}
      sidebar={<Sidebar />}
      onSignOut={dispatchOnSignOut}
    >
      <StoryWorklist.Worklist
        activeFilters={activeFilters}
        loading={loading}
        worklistLineItems={worklistLineItems}
        totalAvailableItems={totalAvailableItems}
        onUpload={dispatchUpload}
        onLoadNext={dispatchLoadNext}
        onSetAsNew={(analysisId, sessionId) =>
          dispatchSetSessionStatus(analysisId, sessionId, SessionStatus.New)
        }
        onOpenStudy={dispatchOpenStudy}
        downloadPdf={dispatchDownloadEncryptedPdf}
        failedToLoadWorklist={failedToLoadWorklist}
        setBookmarked={dispatchMarkAsBookmarked}
        isBookmarkEnabled={featureFlags.bookmarks}
        customWorkspaces={customWorkspaces}
        isCustomWorkspacesEnabled={featureFlags.customWorkspaces}
        updateSessionAssignedWorkspaces={onUpdateSessionAssignedWorkspaces}
      />
    </StoryLayout.Layout>
  );
};

export default connect(ViewModel.fromState, ViewModel.dispatchers)(Worklist);

import { Dispatch } from 'redux';
import { Actions } from '@webInterfaces/StoreTypes';
import { signOut } from '@webViewInterfaces/Pages/General';

export type Layout = LayoutDispatchers;

export interface LayoutDispatchers {
  dispatchOnSignOut: () => Dispatch<Actions>;
}

export const dispatchers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any
) => ({
  dispatchOnSignOut: () => dispatch(signOut()),
});

import React from 'react';
import { Box } from '@webMolecules/Box/Box';
import { ExpandButton } from '@webOrganisms/ExpandButton/ExpandButton';
import { Icon } from '@webMolecules/Icon/Icon';
import { IndexLabel } from '@webOrganisms/IndexLabel/IndexLabel';
import { cnames } from '@helpers/cnames';
import styles from './comparison.scss';

export interface ComparisonButtonProps {
  previousConnectorActive: boolean;
  currentConnectorActive: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
  index?: number;
}

export const ComparisonButton = ({
  isOpen,
  previousConnectorActive,
  currentConnectorActive,
  toggleOpen,
  index,
}: ComparisonButtonProps) => {
  const isPreviousConnectorActive =
    previousConnectorActive && index !== undefined;
  // @todo both has he value -- blue
  const connectorStylePrevious = cnames(styles.connector, {
    [styles.active]: isPreviousConnectorActive,
    [styles.hidden]: !previousConnectorActive && index !== undefined,
  });
  const connectorStyleCurrent = cnames(styles.connector, {
    [styles.active]: currentConnectorActive,
    [styles.hidden]: index === undefined,
  });

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="center">
      <Box display="flex" alignItems="center">
        <Box className={connectorStylePrevious}></Box>
        <Box className={styles.expandButton}>
          <ExpandButton
            isPair={isPreviousConnectorActive && currentConnectorActive}
            type="vertical"
            onClick={toggleOpen}
            iconAfter={
              <Icon
                size="extraSmall"
                name={isOpen ? 'collapse' : 'expand'}
                color={
                  isOpen
                    ? 'var(--ds-color-neutral-800)'
                    : 'var(--ds-color-neutral-300)'
                }
              />
            }
            isOpen={isOpen}
          >
            <IndexLabel
              active={!index && isOpen}
              intent={index ? 'information' : 'blank'}
              index={index}
              borderRadius="all"
            />
          </ExpandButton>
        </Box>

        <Box className={connectorStyleCurrent}></Box>
      </Box>
    </Box>
  );
};

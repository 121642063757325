import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { Box } from '@webMolecules/Box/Box';
import { Text } from '@webMolecules/Text/Text';
import { eventFindingActionCreatedFinding } from '@webInterfaces/Analytics';
import { findingInteractionUseCase } from '@useCases/FindingInteraction';
import { useDispatcher } from '@helpers/useDispatcher';
import { selectStudyType } from '@selectors/SessionSelectors';
import styles from './DetailedFindingsTable.scss';

const CreateFinding: React.FC = () => {
  const studyType = useSelector(selectStudyType);
  const createFinding = useDispatcher(findingInteractionUseCase.Create);

  const handleClick = () => {
    eventFindingActionCreatedFinding();

    createFinding({
      studyType,
    });
  };

  return (
    <Box
      element="button"
      onClick={handleClick}
      className={styles.addFindingBtn}
      padding="m"
      display="flex"
      justifyContent="center"
      flex="1"
      gap="xs"
      data-testid="create-finding"
    >
      <Icon name="plus-circle" />
      <Text type="display5">{t('findings_table.create_finding_button')}</Text>
    </Box>
  );
};

export default CreateFinding;
